import c from 'classnames'
import * as React from 'react'
import { View } from '~/components/View'

interface Props {
    children?: any
    className?: string
    hasFixedActionBar?: boolean
    isInset?: boolean
}

export class ContentView extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <View className={this.getClassName()}>{children}</View>
    }

    private getClassName() {
        const { className, hasFixedActionBar, isInset } = this.props

        return c(
            'tt-ContentView',
            {
                'tt-ContentView--has-fixed-action-bar': hasFixedActionBar,
                'tt-ContentView--inset': isInset,
            },
            className
        )
    }
}
