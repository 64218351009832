import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { gql } from '@apollo/client'
import moment from 'moment'
import { View } from '~/components/View'
import Header from '~/components/Header'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import ReadableDate from '~/components/ReadableDate'
import Fetcher from '~/utils/Fetcher'
import { CrumbLabel } from '~/components/Label/CrumbLabel'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { FinalExam } from '~/types/FinalExam'

interface Props extends RouteComponentProps<{ id: string }, {}> {
    children: any
}

const GET_FINAL_EXAM_QUERY = gql`
    query finalExam($byId: MongoID) {
        finalExams(byId: $byId) {
            _id
            date
            part
            level
            attempt
            result
            nextAttempt {
                _id
                date
                attempt
            }
            user {
                _id
                profile {
                    name
                }
            }
            submitter
            createdAt
            createdByUser {
                _id
                profile {
                    name
                }
            }
            logs {
                _id
                type
                typeData {
                    previousDate
                    invoice {
                        _id
                    }
                    duoDeclaration {
                        _id
                    }
                    previousExamPart
                    duoFinalExamResult {
                        file {
                            fileName
                        }
                    }
                }
                createdAt
                createdByUser {
                    _id
                    profile {
                        name
                    }
                }
            }
            canceledAt
            invoice {
                _id
                isCredit
                invoiceNumber
                isForDUO
                hasSignatureForDUO
                status
                openStatus
                paymentStatus
                isDescendantInstallment
                isFullyCredited
                isDownloadable
                invoiceSentAt
                invoiceNumber
                isGroupParticipationInvoice
                isFinalExamInvoice
                isForDUO
                isExpired
                daysExpired
            }
            appointmentFile {
                _id
                fileName
                isPDF
            }
        }
    }
`

export default class DetailView extends React.Component<Props> {
    private finalExamFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.finalExamFetcher = new Fetcher({
            query: GET_FINAL_EXAM_QUERY,
            onChange: () => this.forceUpdate(),
            variables: { byId: props.params.id },
        })
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.params.id !== prevProps.params.id) {
            this.finalExamFetcher.refetch({
                byId: this.props.params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeComponentProps } = this.props
        const refetch = this.refetchData
        const { data, loading } = this.finalExamFetcher
        const { finalExams } = data
        const finalExam = finalExams && finalExams[0]

        return (
            <RouteView
                crumbLabel={finalExam && this.getCrumbLabel(finalExam)}
                crumbLabelString={finalExam && this.getCrumbLabelString(finalExam)}
                routeProps={routeComponentProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />
                </Header>
                <View>{!loading && finalExam && React.cloneElement(children, { finalExam, refetch })}</View>
            </RouteView>
        )
    }

    public getCrumbLabel(finalExam: FinalExam) {
        return (
            <React.Fragment>
                <ReadableDate date={finalExam.date} /> ({finalExam.user.profile.name})
                {!!finalExam.canceledAt && <CrumbLabel>Geannuleerd</CrumbLabel>}
            </React.Fragment>
        )
    }

    public getCrumbLabelString(finalExam: FinalExam) {
        const m = moment(finalExam.date).utc()

        return `${m.format('DD-MM-YYYY')} (${finalExam.user.profile.name})`
    }

    private refetchData = () => {
        this.finalExamFetcher.refetch()
    }
}
