import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import Input from '~/components/Input'
import FileInput from '~/components/FileInput'
import { get } from 'lodash'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import { ExamDownloader } from '~/components/ExamDownloader'
import { Ability } from '~/types/Exam'

interface Props {
    abilityName: 'converse' | 'talk' | 'write' | 'listen' | 'read'
    errors: any
    defaultAbility: Ability
    baseName: string
    typeSelectedIsAlpha?: boolean
}

interface State {
    isScoreSelected: boolean
    existingDocuments: any[] | null
}

export class ExamsAbilityFieldGroup extends React.Component<Props, State> {
    public state: State = {
        isScoreSelected: false,
        existingDocuments: null,
    }

    private fieldTitle: string

    constructor(props: Props) {
        super(props)

        this.transformTitles()
    }

    public componentDidMount() {
        const { defaultAbility } = this.props

        const typeOfRating = get(defaultAbility, 'typeOfRating')
        const files = get(defaultAbility, 'documentFiles')

        if (typeOfRating === 'score') {
            this.setState({ isScoreSelected: true })
        }

        if (files) {
            this.setState({ existingDocuments: files })
        }
    }

    public render() {
        const { defaultAbility, errors, typeSelectedIsAlpha, baseName, abilityName } = this.props
        const { isScoreSelected, existingDocuments } = this.state

        const alphaWriteFieldGroup = typeSelectedIsAlpha && abilityName === 'write'
        const alphaReadFieldGroup = typeSelectedIsAlpha && abilityName === 'read'

        const documentFieldTitle = existingDocuments && existingDocuments.length >= 2 ? `Documenten` : `Document`

        return (
            <FieldGroup title={this.fieldTitle} className={`tt-ExamsAbilityFieldGroup`}>
                <Field title={'Type beoordeling'} errors={errors}>
                    {alphaWriteFieldGroup && `Voldoende/onvoldoende`}
                    {alphaReadFieldGroup && `Score`}

                    {!alphaWriteFieldGroup && !alphaReadFieldGroup && (
                        <MultiInput type="radio">
                            <RadioButton
                                name={`${baseName}.typeOfRating`}
                                value={'level'}
                                onClick={() => this.setState({ isScoreSelected: false })}
                                defaultChecked={get(defaultAbility, 'typeOfRating') === 'level'}
                            >
                                Niveau
                            </RadioButton>
                            <RadioButton
                                name={`${baseName}.typeOfRating`}
                                value={'score'}
                                onClick={() => this.setState({ isScoreSelected: true })}
                                defaultChecked={get(defaultAbility, 'typeOfRating') === 'score'}
                            >
                                Score
                            </RadioButton>
                        </MultiInput>
                    )}
                </Field>
                {((!alphaWriteFieldGroup && isScoreSelected) || alphaReadFieldGroup) && (
                    <FieldGroup isInsetGroup={true}>
                        <Field isLabel title={'Voldoende vanaf'} errors={errors}>
                            <Input
                                name={`${baseName}.minimumScoreToPass`}
                                type={`number`}
                                defaultValue={get(defaultAbility, `minimumScoreToPass`)}
                                placeholder={`Aantal punten`}
                                step={0.1}
                                min={0}
                            />
                        </Field>
                        <Field isLabel title={'Maximaal te behalen punten'} errors={errors}>
                            <Input
                                name={`${baseName}.maximumScore`}
                                type={`number`}
                                defaultValue={get(defaultAbility, `maximumScore`)}
                                placeholder={`Aantal punten`}
                                step={0.1}
                                min={0}
                            />
                        </Field>
                    </FieldGroup>
                )}
                <Field title={documentFieldTitle} errors={errors}>
                    {existingDocuments && existingDocuments.length > 0 && (
                        <List className={`tt-ExamsAbilityFieldGroup__document-list`}>{this.renderDocumentValue()}</List>
                    )}
                    <FileInput name={`${baseName}.documentFiles.files`} accept={`application/pdf`} multiple={true}>
                        PDF uploaden
                    </FileInput>
                </Field>
            </FieldGroup>
        )
    }

    private renderDocumentValue = () => {
        const { baseName } = this.props
        const { existingDocuments } = this.state

        if (!existingDocuments) {
            return
        }

        return existingDocuments.map((file, i) => {
            const _id = get(file, '_id')
            const fileName = get(file, 'fileName')
            const fileMimeType = get(file, 'fileMimeType')

            return (
                <ListItem className={`tt-ExamsAbilityFieldGroup__document-listitem`} key={`file-${_id}`}>
                    <ExamDownloader
                        fileName={fileName}
                        fileId={_id}
                        mimeType={fileMimeType}
                        onRemove={() => this.removeIdFromList(_id)}
                    />
                    <input type={`hidden`} name={`${baseName}.documentFiles.existingFileIds[${i}]`} value={_id} />
                </ListItem>
            )
        })
    }

    private removeIdFromList = (idToRemove: string) => {
        const { existingDocuments } = this.state

        if (existingDocuments) {
            const idIndex = existingDocuments
                .map((ed, i) => (ed._id === idToRemove ? i : -1))
                .filter(indice => indice !== -1)[0]

            if (idIndex > -1) {
                const mutableIds = [...existingDocuments]
                mutableIds.splice(idIndex, 1)
                this.setState({ existingDocuments: mutableIds })
            }
        }
    }

    private transformTitles = () => {
        const { abilityName } = this.props

        if (abilityName === 'converse') {
            this.fieldTitle = 'Gespreksvaardigheid'
        } else if (abilityName === 'talk') {
            this.fieldTitle = 'Spreken'
        } else if (abilityName === 'write') {
            this.fieldTitle = 'Schrijven'
        } else if (abilityName === 'listen') {
            this.fieldTitle = 'Luisteren'
        } else if (abilityName === 'read') {
            this.fieldTitle = 'Lezen'
        }
    }
}
