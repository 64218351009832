import * as React from 'react'

import './CrumbLabel.scss'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
}

export class CrumbLabel extends React.Component<Props, {}> {
    private bem = new BEM('CrumbLabel')

    public render() {
        const { children, className } = this.props

        return <span className={this.bem.getClassName(className)}>{children}</span>
    }
}
