import { gql } from '@apollo/client'

export const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`
export const RESET_COGNITO_TOTP_MFA_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_resetCognitoTotpMfa(userId: $userId) {
            _id
        }
    }
`
