import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindAll, times, range, isNumber } from 'lodash'

import { Select, Option, MultiInput } from '~/components'

export default class PrecisionDateInput extends Component {
    static propTypes = {
        defaultValue: PropTypes.shape({
            date: PropTypes.date,
            precision: PropTypes.string,
        }),
        name: PropTypes.string.isRequired,
        fromYear: PropTypes.number,
        isDisabled: PropTypes.bool,
    }

    YEAR_OPTIONS = range(this.props.fromYear || 1900, new Date().getFullYear() + 41)
    MONTH_OPTIONS = moment.months()

    constructor(props) {
        super(props)

        this.state = {
            selectedDay: null,
            selectedMonth: null,
            selectedYear: null,
        }

        const { date, precision } = props.defaultValue || {}

        const mo = date && moment(date)
        if (mo) {
            if (['DAY'].includes(precision)) {
                this.state.selectedDay = mo.date()
            }

            if (['DAY', 'MONTH'].includes(precision)) {
                this.state.selectedMonth = mo.month()
            }

            if (['DAY', 'MONTH', 'YEAR'].includes(precision)) {
                this.state.selectedYear = mo.year()
            }
        }

        bindAll(this, ['onDayChange', 'onMonthChange', 'onYearChange'])
    }

    /**
     * Get the current precision based on the selected values
     *
     * @returns {string}
     */
    getCurrentPrecision() {
        const { selectedDay, selectedMonth, selectedYear } = this.state

        if (isNumber(selectedYear)) {
            if (isNumber(selectedMonth)) {
                if (isNumber(selectedDay)) {
                    return 'DAY'
                } else {
                    return 'MONTH'
                }
            } else {
                return 'YEAR'
            }
        } else {
            return null
        }
    }

    /**
     * Get the current day options based on the selected year and month
     *
     * @returns {number}
     */
    getDayOptions() {
        const { selectedMonth, selectedYear } = this.state

        const days = selectedYear && selectedMonth ? moment().year(selectedYear).month(selectedMonth).daysInMonth() : 31

        return times(days).map(d => d + 1)
    }

    onDayChange(event) {
        const {
            currentTarget: { value },
        } = event
        const day = parseInt(value, 10)

        this.setState({
            selectedDay: !Number.isNaN(day) ? day : null,
        })
    }

    onMonthChange(event) {
        const {
            currentTarget: { value },
        } = event
        const month = parseInt(value, 10)

        this.setState({
            selectedMonth: !Number.isNaN(month) ? month : null,
        })
    }

    onYearChange(event) {
        const {
            currentTarget: { value },
        } = event
        const year = parseInt(value, 10)

        this.setState({
            selectedYear: !Number.isNaN(year) ? year : null,
        })
    }

    getSelectedDate() {
        const precision = this.getCurrentPrecision()
        if (!precision) {
            return null
        }

        const { selectedDay, selectedMonth, selectedYear } = this.state

        const m = moment()
        m.year(selectedYear)

        if (precision === 'YEAR') {
            return m.toDate()
        }

        m.month(selectedMonth)

        if (precision === 'MONTH') {
            return m.toDate()
        }

        m.date(selectedDay)
        return m.toDate()
    }

    render() {
        const { name, isDisabled } = this.props
        const { selectedDay, selectedMonth, selectedYear } = this.state
        const selectedDate = this.getSelectedDate()
        const selectedPrecision = this.getCurrentPrecision()

        return (
            <div>
                <MultiInput type={`precision-date`}>
                    <Select
                        onChange={this.onDayChange}
                        value={selectedDay}
                        name={`selectedDay`}
                        isDisabled={isDisabled}
                    >
                        <Option value={''}>Dag</Option>
                        {this.getDayOptions().map((day, index) => (
                            <Option key={index} value={day}>
                                {day}
                            </Option>
                        ))}
                    </Select>

                    <Select
                        onChange={this.onMonthChange}
                        value={selectedMonth}
                        name={`selectedMonth`}
                        isDisabled={isDisabled}
                    >
                        <Option value={''}>Maand</Option>
                        {this.MONTH_OPTIONS.map((month, index) => (
                            <Option key={index} value={index}>
                                {month}
                            </Option>
                        ))}
                    </Select>

                    <Select
                        onChange={this.onYearChange}
                        value={selectedYear}
                        isDisabled={isDisabled}
                        name={`selectedYear`}
                    >
                        <Option value={''}>Jaar</Option>
                        {this.YEAR_OPTIONS.map((year, index) => (
                            <Option key={index} value={year}>
                                {year}
                            </Option>
                        ))}
                    </Select>
                </MultiInput>
                <input
                    type="hidden"
                    name={`${name}.date`}
                    value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
                />
                <input type="hidden" name={`${name}.precision`} value={selectedPrecision || ''} />
            </div>
        )
    }
}
