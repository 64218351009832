import React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { EnvironmentLabel } from '~/components'
import { View } from '~/components/View'
import { NotificationManager } from '~/components/Notifications/NotificationManager'
import historyStore from '~/services/historyStore'
import WelcomeModal from '~/modals/WelcomeModal'
import { fetchCurrentUser, getCurrentUser } from '~/services/session'
import App from '~/views/App'
import { User } from '~/types/User'
import classnames from 'classnames'
import { ErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'

export const ScrollElementContext = React.createContext<HTMLElement | Window>(window)

interface Props extends RouteComponentProps<{}, {}> {}

interface State {
    currentUserLoading: boolean
    mainNavigationVisible: boolean
    user?: User
}

enum ScrollDirection {
    Up = 'Up',
    Down = 'Down',
}

const SCROLL_NAVIGATION_HIDE_THRESHOLD_UP = 20
const SCROLL_NAVIGATION_HIDE_THRESHOLD_DOWN = 200

export default class RootView extends React.Component<Props, State> {
    public state: State = {
        currentUserLoading: true,
        mainNavigationVisible: true,
    }

    private scrollPosition: number = window.pageYOffset
    private scrollDirection: ScrollDirection = ScrollDirection.Down
    private scrollDistanceInCurrentDirection = 0

    constructor(props: Props) {
        super(props)

        const routeRequiresUser = this.getRouteRequiresUser(props.routes)
        fetchCurrentUser({
            preventRedirectWhenUnauthenticated: true,
        })
            .then(user => {
                this.setState({ currentUserLoading: false, user })

                if (routeRequiresUser && !user) {
                    throw new Error('User not found')
                }
            })
            .catch(() => {
                if (routeRequiresUser) {
                    const intent = browserHistory.createPath(location)
                    browserHistory.replace('/login')
                    historyStore.set('intent', intent)
                }

                this.setState({ currentUserLoading: false })
            })
    }

    public componentWillMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    public componentWillUpdate(nextProps: Props) {
        const oldRouteRequiresUser = this.getRouteRequiresUser(this.props.routes)
        const newRouteRequiresUser = this.getRouteRequiresUser(nextProps.routes)

        if (!oldRouteRequiresUser && newRouteRequiresUser) {
            this.setState({
                user: getCurrentUser(),
            })
        }
    }

    public render() {
        const { children, routes } = this.props

        return (
            <ErrorBoundary routeProps={this.props}>
                <View
                    className={classnames('tt-RootView', {
                        'tt-RootView--hide-main-nav': !this.state.mainNavigationVisible,
                    })}
                >
                    <ScrollElementContext.Provider value={window}>
                        {this.isTestEnvironment() && <EnvironmentLabel label="Testomgeving" />}

                        <NotificationManager />
                        <WelcomeModal />
                        {this.shouldRenderSubRoutes(routes) && children}
                    </ScrollElementContext.Provider>
                </View>
            </ErrorBoundary>
        )
    }

    private isTestEnvironment() {
        return window.location.hostname !== 'mijntoptaal.nl'
    }

    private getRouteRequiresUser(routes: Props['routes']) {
        return routes[1].component === App
    }

    private shouldRenderSubRoutes(routes: Props['routes']) {
        if (!this.getRouteRequiresUser(routes)) {
            return true
        }

        if (!this.state.currentUserLoading && this.state.user) {
            return true
        }

        return false
    }

    private handleScroll = () => {
        const currentScrollPos = window.pageYOffset

        const newScrollDirection: ScrollDirection =
            this.scrollPosition > currentScrollPos ? ScrollDirection.Up : ScrollDirection.Down

        if (newScrollDirection !== this.scrollDirection) {
            this.scrollDirection = newScrollDirection
            this.scrollDistanceInCurrentDirection = 0
        } else {
            const distanceSincePreviousHandler = Math.abs(currentScrollPos - this.scrollPosition)
            this.scrollDistanceInCurrentDirection += distanceSincePreviousHandler
        }

        this.scrollPosition = currentScrollPos

        if (this.scrollPosition === 0) {
            this.setState({
                mainNavigationVisible: true,
            })
        }

        if (this.scrollDirection === ScrollDirection.Up) {
            if (
                this.scrollDistanceInCurrentDirection > SCROLL_NAVIGATION_HIDE_THRESHOLD_UP &&
                !this.state.mainNavigationVisible
            ) {
                this.setState({
                    mainNavigationVisible: true,
                })
            }
        } else {
            if (
                this.scrollDistanceInCurrentDirection > SCROLL_NAVIGATION_HIDE_THRESHOLD_DOWN &&
                this.state.mainNavigationVisible
            ) {
                this.setState({
                    mainNavigationVisible: false,
                })
            }
        }
    }
}
