import './Note.scss'

import c from 'classnames'
import * as React from 'react'
import { gql } from '@apollo/client'
import ReadableDate from '~/components/ReadableDate'
import { Link, CenterModal } from '~/components'
import { Mutator } from '~/utils'
import { Note } from '~/types/Note'
import { ModalManager } from '~/components/ModalManager'
import { EditNoteForm } from '~/components/notes/EditNoteForm'

interface Props {
    className?: string
    note: Note
    onEditNote: () => void
    onRemoveNote: () => void
}

interface State {
    isScrollable: boolean
}

const REMOVE_NOTES_MUTATION = gql`
    mutation _($noteId: MongoID!) {
        notes_delete(noteId: $noteId) {
            _id
        }
    }
`

export class NoteComponent extends React.Component<Props, State> {
    public state: State = {
        isScrollable: false,
    }

    private removeNoteMutation: Mutator
    private contentElement: HTMLDivElement | null

    constructor(props: Props) {
        super(props)

        this.removeNoteMutation = new Mutator({
            mutation: REMOVE_NOTES_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public componentDidMount() {
        if (this.contentElement) {
            this.setState({
                isScrollable: this.contentElement.scrollHeight > this.contentElement.clientHeight,
            })
        }
    }

    public render() {
        const { note, onEditNote } = this.props

        return (
            <article className={this.getClassName()}>
                <div
                    className="tt-Note__content"
                    ref={ref => {
                        this.contentElement = ref
                    }}
                >
                    <p>{note.text}</p>
                </div>
                <footer>
                    <ReadableDate date={note.createdAt} showTime />
                    <span className={'tt-Note__footer-separator'} />
                    {note.createdByUser && note.createdByUser.profile.name}
                    <ModalManager
                        render={openModal => (
                            <Link onClick={openModal} className="tt-Note__edit-button">
                                Bewerken
                            </Link>
                        )}
                        getModal={closeModal => {
                            return (
                                <CenterModal small onClose={closeModal} title={`Notitie bewerken`}>
                                    <EditNoteForm
                                        note={note}
                                        onSubmitSuccess={() => {
                                            closeModal()
                                            onEditNote()
                                        }}
                                        onCancel={closeModal}
                                        removeNote={() => {
                                            this.removeNote(note)
                                        }}
                                    />
                                </CenterModal>
                            )
                        }}
                    />
                </footer>
            </article>
        )
    }

    private removeNote = async (note: Note) => {
        const { onRemoveNote } = this.props

        await this.removeNoteMutation.mutate({
            noteId: note._id,
        })

        onRemoveNote()
    }

    private getClassName(): string {
        const { className } = this.props
        const { isScrollable } = this.state

        return c(
            'tt-Note',
            {
                'tt-Note--is-scrollable': isScrollable,
            },
            className
        )
    }
}
