import './Column.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isHorizontalCentered?: boolean
}

export class Column extends React.Component<Props> {
    private bem = new BEM('Column', () => ({
        'horizontal-center': this.props.isHorizontalCentered,
    }))

    public render() {
        const { className, children } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
