import React from 'react'
import { InflowMoment } from '~/types/InflowMoments'
import { gql } from '@apollo/client'
import { Fetcher, Filter, removeDuplicateDocuments } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InflowUserForm, InflowMomentUserRoleType } from './InflowUserForm'
import { FilterFieldCollection, Field, ActionBar, Button, Search, Icon } from '~/components'
import { TagPicker } from '~/components/TagPicker'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    inflowMoment: InflowMoment
    refetch: () => void
    closeModal: () => void
}

const STATIC_USERS_FILTER = {
    roles: ['INTAKER'],
    filterActive: true,
}

const START = 40
const INCREASE = 40

const GET_USERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int) {
        users(filters: $filters, limit: $limit, skip: $skip, sortBy: "profile.firstName", sortDir: "ASC") {
            _id
            profile {
                name
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            roles
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`

interface State {
    limit: number
    skip: number
}

export class InflowUserIntakersForm extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
    }

    private userFetcher: Fetcher
    private paginator: Paginator
    private filter: Filter
    private filterSuggestionsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.filter = new Filter({
            useHistory: false,
            allowedKeys: ['searchText', 'filterByCityNames', 'filterActive'],
            onChange: (filters: any) => {
                this.userFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_USERS_FILTER,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        })

        this.userFetcher = new Fetcher({
            query: GET_USERS_QUERY,
            variables: {
                limit,
                skip,
                filters: {
                    ...STATIC_USERS_FILTER,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { inflowMoment, refetch, closeModal } = this.props
        const { data } = this.userFetcher
        const { users = [] } = data

        return (
            <InflowUserForm
                userRoleType={InflowMomentUserRoleType.intaker}
                title={'Toetsdocenten toevoegen'}
                inflowMoment={inflowMoment}
                refetch={refetch}
                closeModal={closeModal}
                selectedUsers={inflowMoment.intakerUsers || []}
                userFetcher={this.userFetcher}
                userPaginator={this.paginator}
                renderActionBar={this.renderActionBar}
                users={users}
            />
        )
    }

    private renderActionBar = () => {
        const { loading: loadingUsers } = this.userFetcher
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities = [] } = filterParams

        return (
            <ActionBar
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => {
                    return (
                        <List horizontal>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`filter`} />}
                                    onClick={() => toggleDrawer()}
                                    isActive={drawerActive}
                                >
                                    Filteropties
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                            </ListItem>
                        </List>
                    )
                }}
                getDrawer={() => {
                    const addressOptions = addresses_usedCities.map((city: string) => ({ value: city, label: city }))

                    return (
                        <FilterFieldCollection>
                            <Field isLabel title={`Plaats`} style={`compact`}>
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`filterByCityNames`}
                                    options={addressOptions}
                                    placeholder={`Selecteer plaats`}
                                />
                            </Field>
                        </FilterFieldCollection>
                    )
                }}
            />
        )
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.userFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    private onFilterTagPicker = (values: any[], tagPicker: any) => {
        this.filter.applyFromTagPicker(tagPicker)
    }
}
