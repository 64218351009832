import * as React from 'react'

import { ClassValue, BEM } from '~/services/BEMService'
import Modal from '~/components/Modal'

interface Props {
    className?: ClassValue
    onClose?: () => void
    loading?: boolean
    enableEasyClose?: boolean
}

export default class DrawerModal extends React.Component<Props> {
    private bem = new BEM('DrawerModal', () => ({
        'is-loading': this.props.loading,
    }))

    public render() {
        const { children, className, onClose, enableEasyClose } = this.props

        return (
            <Modal onClose={onClose} enableEasyClose={enableEasyClose}>
                <div className={this.bem.getClassName(className)}>
                    <div className={this.bem.getElement('content')}>{children}</div>
                </div>
            </Modal>
        )
    }
}
