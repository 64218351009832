import { gql } from '@apollo/client'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

const GET_COURSEMATERIALS_QUERY = gql`
    query _($_id: MongoID) {
        courseMaterials(byId: $_id) {
            _id
            name
            amount
        }
    }
`

interface Params {
    id: string
}

interface Props extends RouteComponentProps<Params, {}> {
    children: any
}

export default class DetailView extends React.Component<Props> {
    private courseMaterialFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.courseMaterialFetcher = new Fetcher({
            query: GET_COURSEMATERIALS_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate({ params: prevParams }: any) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.courseMaterialFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.courseMaterialFetcher
        const courseMaterial = data && data.courseMaterials && data.courseMaterials[0]

        return (
            <RouteView crumbLabel={courseMaterial && courseMaterial.name} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                {courseMaterial && React.cloneElement(children, { courseMaterial, refetch })}
            </RouteView>
        )
    }
}
