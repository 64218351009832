import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { ProcessView } from '~/views/App/Common/ProcessView'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

interface Props extends RouteComponentProps<{}, {}> {}

export default class FinalExamsView extends React.Component<Props> {
    public render() {
        const { children, ...routeProps } = this.props

        return (
            <BreadCrumbProvider>
                <ProcessView crumbLabel={'Examens'} routeProps={routeProps}>
                    {children}
                </ProcessView>
            </BreadCrumbProvider>
        )
    }
}
