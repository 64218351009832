import './AttendanceCell.scss'

import c from 'classnames'
import * as React from 'react'

import { TableCell } from '~/components/TableCell'

interface Props {
    className?: string
}

export class AttendanceCell extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <TableCell className={this.getClassName()}>{children}</TableCell>
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-AttendanceCell', className)
    }
}
