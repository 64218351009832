import * as React from 'react'
import c from 'classnames'

import { MultiInput } from '~/components'

interface Props {
    className?: string
}

export class DefaultAmountMultiInput extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <MultiInput className={this.getClassName()}>{children}</MultiInput>
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-DefaultAmountMultiInput', className)
    }
}
