import { Mutator, toast, transformFormFields } from '~/utils'
import { Button, Form, FieldCollection, Field, DatePicker } from '~/components'
import React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { LearnerEnrollmentType } from '~/types/User'
import { translateType } from '~/shared/utils'
import { gql } from '@apollo/client'
import { FormFields } from '~/components/Form'

interface Props {
    userId: string
    onSubmitSuccess?: () => void
    onCancel?: () => void
}

export class CreateSignupForm extends React.Component<Props> {
    private mutator: Mutator

    constructor(props: Props) {
        super(props)

        this.mutator = new Mutator({
            mutation: CREATE_USER_LEARNER_SIGNUP_DATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render(): React.ReactNode {
        return (
            <Form onSubmit={(event, fields) => this.onSubmit(fields)}>
                <FieldCollection style={`modal`} errors={this.mutator.errors}>
                    <FieldGroup>
                        <Field isLabel={true} title={'Type aanmelding'} errors={this.mutator.errors}>
                            <TagPicker
                                name={`userLearnerSignup.enrollmentType`}
                                multi={false}
                                isClearable={true}
                                placeholder={'Selecteer een type...'}
                                options={[LearnerEnrollmentType.Intake, LearnerEnrollmentType.Assessment].map(
                                    option => ({
                                        value: option,
                                        label: translateType('enrollmentType', option),
                                    })
                                )}
                            />
                        </Field>
                        <Field isLabel={true} title={'Aangemeld op'} errors={this.mutator.errors}>
                            <DatePicker hasIcon={true} name={`userLearnerSignup.signupDate`} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={this.props.onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={this.mutator.loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private async onSubmit(fields: FormFields) {
        const data = await this.mutator.mutate({
            userLearnerSignup: {
                userId: this.props.userId,
                ...transformFormFields(fields.userLearnerSignup),
            },
        })

        if (data && data.userLearnerSignups_create) {
            toast.success(`Aanmelding is geregistreerd`)

            if (this.props.onSubmitSuccess) {
                this.props.onSubmitSuccess()
            }
        }
    }
}

const CREATE_USER_LEARNER_SIGNUP_DATE_MUTATION = gql`
    mutation _($userLearnerSignup: UserLearnerSignupInputType!) {
        userLearnerSignups_create(userLearnerSignup: $userLearnerSignup) {
            _id
        }
    }
`
