import { Exam, ExamAbilityDictionary } from './Exam'

export interface InflowModule {
    _id: string
    name: string
    type: InflowModuleType
    exams?: InflowModuleExam[]
    examMaxTimeMinutes?: number
    examStartIntervalMinutes?: number
    maxStudentsPerStart?: number
    checkAdviceReportNeeded?: boolean
    isLockedForEdit: boolean
    isLockedForDelete: boolean
}

export enum InflowModuleType {
    intake = 'intake',
    levelDetermination = 'levelDetermination',
}

export interface InflowModuleExam {
    abilities: ExamAbilityDictionary
    examId?: string
    exam?: Exam
}
