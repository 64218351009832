import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class Placeholder extends React.Component<Props> {
    private bem = new BEM('Placeholder')

    public render() {
        const { children, className } = this.props

        return <em className={this.bem.getClassName(className)}>{children}</em>
    }
}
