import React, { Component } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { TableRow } from '~/components'
import { Table } from '~/components/Table'

export default class EdisaImportTable extends Component {
    static propTypes = {
        children: PropTypes.any,
    }

    render() {
        const { children, ...props } = this.props

        return (
            <Table {...props} className="tt-EdisaImportTable">
                {children}
            </Table>
        )
    }
}

export class EdisaImportTableRow extends Component {
    static propTypes = {
        children: PropTypes.any,
        importHasFailed: PropTypes.bool,
        intakeHasBeenReset: PropTypes.bool,
    }

    getClassName() {
        const { importHasFailed, intakeHasBeenReset } = this.props

        return c('tt-EdisaImportTableRow', {
            'tt-EdisaImportTableRow--has-failed': importHasFailed,
            'tt-EdisaImportTableRow--has-reset-intake': intakeHasBeenReset,
        })
    }

    render() {
        const { children, ...props } = this.props

        return (
            <TableRow {...props} className={this.getClassName()}>
                {children}
            </TableRow>
        )
    }
}
