import React from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<{}, {}> {}

export default class TeachersView extends React.Component<Props> {
    public render() {
        const { children, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Externe docenten'} routeProps={routeProps}>
                {children}
            </RouteView>
        )
    }
}
