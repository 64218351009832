import * as React from 'react'

import { TagPicker, TagPickerProps } from '~/components/TagPicker'
import translateType from '~/shared/utils/translateType'
import { LearnerLevelWithAlpha } from '~/types/LearnerLevel'

interface Props extends TagPickerProps {}

export class LearnerLevelWithAlphaInput extends React.Component<Props> {
    public render() {
        return (
            <TagPicker
                {...this.props}
                multi={false}
                options={Object.values(LearnerLevelWithAlpha).map(level => ({
                    label: translateType('level', level),
                    value: level,
                }))}
            />
        )
    }
}
