import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, DateTimePicker, Field, FieldCollection, Form } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { Fetcher, Mutator, transformFormFields } from '~/utils'

export default class AddGroupLessonForm extends Component {
    static propTypes = {
        groupId: PropTypes.string.isRequired,
        onSubmitSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.lessonsMutator = new Mutator({
            mutation: ADD_LESSONS_MUTATION,
            reactComponentToUpdate: this,
        })

        this.teacherFetcher = new Fetcher({
            query: GET_TEACHERS_QUERY,
            variables: {
                filters: {
                    filterById: props.groupId,
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    onSubmit = async (event, fields) => {
        const { onSubmitSuccess, groupId } = this.props

        const res = await this.lessonsMutator.mutate({
            lesson: {
                groupId,
                ...transformFormFields(fields.lesson),
            },
        })

        if (res) {
            onSubmitSuccess(res.lessons_create._id)
        }
    }

    render() {
        const { onCancel } = this.props
        const { loading: mutatorIsLoading, errors } = this.lessonsMutator
        const { loading: fetcherIsLoading, data } = this.teacherFetcher
        const group = get(data, 'groups[0]') || {}
        const teacherOptions = group.teacherUsers || []

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field errors={errors} title={`Lesdatum`}>
                            <DateTimePicker name={`lesson.date`} />
                        </Field>
                        <Field title={`Docent`} errors={errors}>
                            {teacherOptions && (
                                <TagPicker
                                    name="lesson.teacherUserId"
                                    multi={false}
                                    disabled={fetcherIsLoading}
                                    options={teacherOptions.map(t => ({
                                        value: t.user._id,
                                        label: t.user.profile.name,
                                    }))}
                                    placeholder={'Zoek een docent'}
                                />
                            )}
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button isLoading={mutatorIsLoading} type={`submit`}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const ADD_LESSONS_MUTATION = gql`
    mutation _($lesson: LessonInputType!) {
        lessons_create(lesson: $lesson) {
            _id
        }
    }
`

const GET_TEACHERS_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            teacherUsers(filterRemoved: false) {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`
