import { gql } from '@apollo/client'
import { compact, get, times, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Option, Select, Subtle } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditGroupScheduleForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
        onFormCancel: PropTypes.func,
        type: PropTypes.string,
    }

    constructor(props) {
        super(props)

        this.editGroupMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = async (event, fields) => {
        const { onSubmitSuccess, group } = this.props
        const weekDaysAmount = (get(group, 'generalWeekLessonDates') || []).length

        if (!group) {
            return
        }

        const padIdsWithNullsAccordingToWeekDayIndexIfNessesary = ids =>
            ids ? ids.map(id => (id === 'false' ? null : id)) : times(weekDaysAmount, () => null)

        const data = await this.editGroupMutator.mutate({
            group: {
                _id: group._id,
                ...transformFormFields(fields.group, {
                    generalWeekLessonTeacherUserIds: {
                        value: padIdsWithNullsAccordingToWeekDayIndexIfNessesary,
                    },
                    generalWeekLessonRoomIds: {
                        value: padIdsWithNullsAccordingToWeekDayIndexIfNessesary,
                    },
                }),
            },
        })

        if (data) {
            onSubmitSuccess()
        }
    }

    onCancel = () => {
        if (this.props.onFormCancel) {
            this.props.onFormCancel()
        }
    }

    getTimeLabel(weekDay, { dayFormat } = {}) {
        const { group } = this.props
        const module = get(group, 'module')

        const timeFrom = moment(weekDay)
        const timeUntil = module && module.lessonDuration && moment(weekDay).add(module.lessonDuration, 'minute')
        return `${moment(weekDay).format(dayFormat || 'dddd')} ${timeFrom.format('HH:mm')}${
            timeUntil ? ` - ${timeUntil.format('HH:mm')}` : ''
        }`
    }

    render() {
        const { loading: isMutationLoading, errors } = this.editGroupMutator

        const { group } = this.props

        // groupTeachers are groupuserstypes and schedule teachers are user types; we need to make groupTeachers
        // equal to scheduleTeachers by flattening the object to the user property.
        const groupTeachers = (get(group, 'teacherUsers') || []).map(groupUser => {
            return groupUser.user
        })
        const scheduleTeachers = get(group, 'generalWeekLessonTeacherUsers') || []
        const scheduleRooms = get(group, 'generalWeekLessonRooms') || []
        const weekDays = get(group, 'generalWeekLessonDates')
        const startDateIndex = get(group, 'startDateIndex')

        const teachers = compact(uniqBy(groupTeachers.concat(scheduleTeachers), '_id'))
        const rooms = get(group, 'rooms') || []

        const areThereTeachersToSelect = teachers.length > 0
        const areThereRoomsToSelect = rooms.length > 0

        return (
            <Form type={this.props.type || null} onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    {!weekDays || !weekDays.length ? (
                        <FieldGroup isForm>
                            <Subtle>Er zijn nog geen les dagen toegevoegd</Subtle>
                        </FieldGroup>
                    ) : (
                        <FieldGroup isForm>
                            <Field isLabel title={'Startdag'} errors={errors}>
                                <Select name={`group.startDateIndex`} defaultValue={startDateIndex}>
                                    <Option value={''}>Selecteer een startdag</Option>
                                    {weekDays.length &&
                                        weekDays.map((weekDay, index) => {
                                            const label = this.getTimeLabel(weekDay)

                                            return (
                                                <Option key={index} value={index}>
                                                    {label}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </Field>

                            {weekDays.length &&
                                weekDays.map((weekDay, weekIndex) => {
                                    const fieldTitle = `${this.getTimeLabel(weekDay, { dayFormat: 'dd' })}`

                                    return (
                                        <Field key={weekIndex} isLabel title={fieldTitle} errors={errors}>
                                            <Select
                                                name={`group.generalWeekLessonTeacherUserIds[${weekIndex}]`}
                                                defaultValue={
                                                    scheduleTeachers[weekIndex]
                                                        ? scheduleTeachers[weekIndex]._id
                                                        : false
                                                }
                                                isReadonly={!areThereTeachersToSelect}
                                            >
                                                <Option value={false}>
                                                    {!areThereTeachersToSelect
                                                        ? 'Er zijn (nog) geen docenten toegevoegd'
                                                        : 'Selecteer een docent'}
                                                </Option>
                                                {teachers.length &&
                                                    teachers.map(teacher => {
                                                        return (
                                                            <Option key={teacher._id} value={teacher._id}>
                                                                {teacher.profile.name}
                                                            </Option>
                                                        )
                                                    })}
                                            </Select>
                                            <Select
                                                name={`group.generalWeekLessonRoomIds[${weekIndex}]`}
                                                defaultValue={
                                                    scheduleRooms[weekIndex] ? scheduleRooms[weekIndex]._id : false
                                                }
                                                isReadonly={!areThereRoomsToSelect}
                                            >
                                                <Option value={false}>
                                                    {!areThereRoomsToSelect
                                                        ? 'Er zijn (nog) geen lesruimtes toegevoegd'
                                                        : 'Selecteer een lesruimte'}
                                                </Option>
                                                {rooms.length &&
                                                    rooms.map(r => {
                                                        return (
                                                            <Option key={r._id} value={r._id}>
                                                                {r.name}
                                                            </Option>
                                                        )
                                                    })}
                                            </Select>
                                        </Field>
                                    )
                                })}
                        </FieldGroup>
                    )}
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={this.onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={isMutationLoading}
                                    isDisabled={!weekDays || !weekDays.length}
                                >
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`
