import React from 'react'
const moment = require('moment')
import { Mutator, transformFormFields } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { InflowMoment } from '~/types/InflowMoments'
import { Form, FieldCollection, Button, Field, DatePicker, Input, MultiInput } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'

interface Props {
    onClose: () => void
    inflowMoment?: InflowMoment
    refetch: () => void
}

interface State {}

export class InflowDateForm extends React.Component<Props, State> {
    public state: State = {
        roomsOrganized: this.props.inflowMoment && this.props.inflowMoment.locationRoomsOrganized ? true : false,
        locationHasRooms: false,
    }

    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose, inflowMoment } = this.props
        const { loading, errors } = this.inflowMomentMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`} errors={errors}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={'Dag'} errors={errors}>
                            <DatePicker
                                name={'inflowMoment.date'}
                                defaultValue={inflowMoment && inflowMoment.dateRange && inflowMoment.dateRange.from}
                            />
                        </Field>
                        <Field isLabel={true} title={'Tijd vanaf'} errors={errors}>
                            <MultiInput type={'select-range'}>
                                <Input
                                    name={`inflowMoment.dateRange.from`}
                                    type={'time'}
                                    // required={true}
                                    defaultValue={
                                        inflowMoment &&
                                        inflowMoment.dateRange &&
                                        inflowMoment.dateRange.from &&
                                        this.getTimeFromDate(inflowMoment.dateRange.from)
                                    }
                                />
                                <label>tot</label>
                                <Input
                                    name={`inflowMoment.dateRange.to`}
                                    type={'time'}
                                    // required={true}
                                    defaultValue={
                                        inflowMoment &&
                                        inflowMoment.dateRange &&
                                        inflowMoment.dateRange.to &&
                                        this.getTimeFromDate(inflowMoment.dateRange.to)
                                    }
                                />
                            </MultiInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onClose}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { onClose, refetch, inflowMoment } = this.props

        const from = new Date(`${fields.inflowMoment.date}T${fields.inflowMoment.dateRange.from}`)
        const to = new Date(`${fields.inflowMoment.date}T${fields.inflowMoment.dateRange.to}`)

        const result = await this.inflowMomentMutator.mutate({
            inflowMoment: transformFormFields(
                {
                    _id: inflowMoment ? inflowMoment._id : undefined,
                    dateRange: {
                        from,
                        to,
                    },
                },
                {}
            ),
        })

        if (result) {
            onClose()

            if (refetch) {
                refetch()
            }
        }
    }

    private getTimeFromDate(date: Date) {
        return moment(date).format('HH:mm')
    }
}
