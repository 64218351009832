import './ObligedCitizenshipEducationEndDateExtension.scss'
import c from 'classnames'
import * as React from 'react'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Button, PdfModal } from '~/components'
import CenterModal from '~/components/CenterModal'
import {
    ObligedCitizenshipEducationEndDateExtensionForm,
    ObligedCitizenshipEducationEndDateExtensionFormInputFields,
} from '~/forms/ObligedCitizenshipEducationEndDateExtensionForm'
import Bold from '~/components/Bold'
import Subtle from '~/components/Subtle'
import ReadableDate from '~/components/ReadableDate'
import { ObligedCitizenshipEducationEndDateExtensionType } from '~/types/User'
import downloadFile from '~/utils/downloadFile'

interface Props {
    className?: string
    defaultValue: ObligedCitizenshipEducationEndDateExtensionType
    minimumExtensionDate?: string | null
    name: string
    onEditExtension: (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => void
    onRemoveExtension: () => void
    isLastExtension: boolean
}

interface State {
    dateInputValue?: string
    descriptionInputValue?: string | null
}

export class ObligedCitizenshipEducationEndDateExtension extends React.Component<Props, State> {
    public state: State = {
        dateInputValue: this.props.defaultValue.date,
        descriptionInputValue: this.props.defaultValue.description,
    }

    private dateInputRef: HTMLInputElement | null
    private descriptionInputRef: HTMLInputElement | null

    public render() {
        const { name, defaultValue, onRemoveExtension } = this.props
        const { dateInputValue, descriptionInputValue } = this.state
        const { createdByUser, createdAt } = defaultValue

        return (
            <ListItem className={this.getClassName()}>
                {!!defaultValue && !!defaultValue._id && (
                    <input name={`${name}._id`} type={`hidden`} value={defaultValue._id} />
                )}
                <Bold className={`tt-ObligedCitizenshipEducationEndDateExtension__title`}>
                    <span className={`tt-ObligedCitizenshipEducationEndDateExtension__date`}>
                        Datum verlengd naar <ReadableDate date={dateInputValue} />
                    </span>
                    {!!dateInputValue && (
                        <input
                            name={`${name}.date`}
                            type={`hidden`}
                            value={dateInputValue}
                            ref={el => (this.dateInputRef = el)}
                        />
                    )}
                </Bold>
                <span className={`tt-ObligedCitizenshipEducationEndDateExtension__description`}>
                    {descriptionInputValue}
                    {!!descriptionInputValue && (
                        <input
                            name={`${name}.description`}
                            type={`hidden`}
                            value={descriptionInputValue}
                            ref={el => (this.descriptionInputRef = el)}
                        />
                    )}
                </span>
                {this.renderDocumentFile()}
                <div className={`tt-ObligedCitizenshipEducationEndDateExtension__footer`}>
                    <ModalManager
                        render={openModal => (
                            <Button small={true} onClick={openModal}>
                                Bewerken
                            </Button>
                        )}
                        getModal={closeModal => (
                            <CenterModal title={`Inburgeringsplicht verlengen`} onClose={closeModal}>
                                <ObligedCitizenshipEducationEndDateExtensionForm
                                    defaultValue={defaultValue}
                                    onCancel={closeModal}
                                    onEditDate={fields => this.onEditDate(fields)}
                                    onRemoveExtension={onRemoveExtension}
                                    onSubmit={closeModal}
                                />
                            </CenterModal>
                        )}
                    />
                    {!!createdByUser && (
                        <Subtle>
                            Door {createdByUser.profile.name} op <ReadableDate date={createdAt} />
                        </Subtle>
                    )}
                </div>
            </ListItem>
        )
    }

    private onEditDate = (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => {
        const { onEditExtension } = this.props

        if (fields && this.dateInputRef && this.descriptionInputRef) {
            this.setState({
                dateInputValue: fields.date,
                descriptionInputValue: fields.description,
            })

            onEditExtension(fields)
        }
    }

    private renderDocumentFile = (): React.ReactNode | null => {
        const { defaultValue } = this.props
        const { document, fileToUploadName } = defaultValue

        if (fileToUploadName && fileToUploadName.length > 0) {
            return fileToUploadName
        }

        if (!document) {
            return null
        }

        if (document.isPDF && document.fileName) {
            const fileName = document.fileName

            return (
                <ModalManager
                    render={openModal => (
                        <Button linkStyle={`default`} onClick={openModal}>
                            {document.fileName}
                        </Button>
                    )}
                    getModal={closeModal => (
                        <PdfModal
                            title={fileName}
                            fileName={fileName}
                            getFileId={() => document._id}
                            onClose={closeModal}
                        />
                    )}
                />
            )
        } else if (document.hasOwnProperty('isPDF')) {
            return (
                <Button linkStyle={`default`} onClick={() => downloadFile(document._id, document.fileName as string)}>
                    {document.fileName}
                </Button>
            )
        }

        return null
    }

    private getClassName() {
        const { isLastExtension, className } = this.props

        return c(
            'tt-ObligedCitizenshipEducationEndDateExtension',
            {
                'tt-ObligedCitizenshipEducationEndDateExtension--is-last-extension': isLastExtension,
            },
            className
        )
    }
}
