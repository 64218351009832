import { gql } from '@apollo/client'
import { compact, get } from 'lodash'
import React from 'react'

import {
    ActionBar,
    Button,
    // CenterModal,
    CheckBox,
    Field,
    FilterFieldCollection,
    Header,
    Icon,
    Link,
    MultiInput,
    Search,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    DrawerModal,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
// import { ImportLearnersForm } from '~/forms'
import { downloadFile, Fetcher, Filter, Mutator, removeDuplicateDocuments, Sorter, toast } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { NewLearnerForm } from '~/components/users/Learner/NewLearnerForm'
import { LearnerRowExpansion } from '~/components/User/LearnerRowExpansion/LearnerRowExpansion'
import { User } from '~/types/User'
import { RouteComponentProps } from 'react-router'
import { Group } from '~/types/Group'
import { Organization } from '~/types/Organization'
import { Module } from '~/types/Module'
import { Location } from '~/types/Location'
import { ImportLearnersModal } from '~/components/users/Learner/ImportLearnersModal'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.firstName'
const DEFAULT_SORT_DIR = 'ASC'

const STATIC_LEARNER_USERS_FILTER = {
    roles: ['LEARNER'],
}

interface Props extends RouteComponentProps<{}, {}> {
    user?: User
}

interface State {
    sortDir: string
    sortBy: string
}

export default class MasterView extends React.Component<Props, State> {
    public state: State = {
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private filter: Filter
    private learnersFetcher: Fetcher
    private paginator: Paginator
    private filterSuggestionsFetcher: Fetcher
    private createExportMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterByGroupIds',
                // 'filterByProjectIds', // filter bestaat nog niet
                // 'filterByProgramIds', // filter bestaat nog niet
                'filterByAdviceModuleIds', // levert slechte performance op
                'filterByPreferredLocationIds',
                'filterByOrganizationIds',
                'filterLearnerActive',
                'filterLearnerStatuses',
            ],
            onChange: (filters: any[]) => {
                this.learnersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_LEARNER_USERS_FILTER,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: (filters: any) => {
                        const activeStates = []

                        if (filters.filterIsActive) {
                            activeStates.push(true)
                        }

                        if (filters.filterIsInactive) {
                            activeStates.push(false)
                        }

                        return {
                            filterLearnerActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerActive'],
                    parseFromHistory: (historyFilters: any): any => {
                        if ('filterLearnerActive' in historyFilters) {
                            if (historyFilters.filterLearnerActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterIsRegistered', 'filterIsUnassigned', 'filterIsAssigned'],
                    transform: (filters: any) => {
                        const statuses = []

                        if (filters.filterIsRegistered) {
                            statuses.push('REGISTERED')
                        }

                        if (filters.filterIsUnassigned) {
                            statuses.push('UNASSIGNED')
                        }

                        if (filters.filterIsAssigned) {
                            statuses.push('ASSIGNED')
                        }

                        return {
                            filterLearnerStatuses: statuses.length > 0 && statuses.length < 3 ? statuses : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerStatuses'],
                    parseFromHistory: (historyFilters: any) => {
                        const statuses = historyFilters.filterLearnerStatuses || []

                        return {
                            filterIsRegistered: statuses.indexOf('REGISTERED'),
                            filterIsUnassigned: statuses.indexOf('UNASSIGNED'),
                            filterIsAssigned: statuses.indexOf('ASSIGNED'),
                        }
                    },
                },
            ],
            defaultFilterState: { filterIsActive: true },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.learnersFetcher = new Fetcher({
            query: GET_LEARNERS_QUERY,

            variables: {
                take: START,
                skip: 0,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_LEARNER_USERS_FILTER,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            preventInitialFetch: true,
            onChange: () => this.forceUpdate(),
        })

        this.createExportMutator = new Mutator({
            mutation: CREATE_LEARNERS_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    public onFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.filter.applyFromInputEvent(event as any)
    }

    public onFilterTagPicker = (values: any, tagPicker: TagPicker) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    public loadMore = (skip: number, take: number, callback: (options?: { finished?: boolean }) => void) => {
        this.learnersFetcher.fetchMore({
            variables: { take, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.paginatedUsers.hasNextPage === false,
                })

                return {
                    paginatedUsers: {
                        ...prevData.paginatedUsers,
                        nodes: removeDuplicateDocuments([
                            ...(prevData.paginatedUsers.nodes || []),
                            ...moreData.paginatedUsers.nodes,
                        ]),
                    },
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.learnersFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    public exportLearners = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        const { sortDir, sortBy } = this.state

        this.createExportMutator
            .mutate({
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_LEARNER_USERS_FILTER,
                    ...this.filter.getFilters(),
                },
                columns: [
                    'Name',
                    'Bsn',
                    'Email',
                    'PhoneNumber',
                    'Country',
                    'Street',
                    'Number',
                    'NumberAddition',
                    'Zipcode',
                    'City',
                    'State',
                    'AddressLine1',
                    'AddressLine2',
                    'EnrollmentDate',
                    'FileNumbers',
                    'TwinFieldCode',
                ],
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'users_createExportFile')

                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'kandidaten-export.xlsx')
                        } else {
                            toast.error('Geen kandidaten gevonden om te exporteren')
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                    }
                }
            })
    }

    public render() {
        const { loading } = this.learnersFetcher

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>{this.renderActionBar()}</Wrap>
                    <TableWrap>{this.renderTable()}</TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    public renderActionBar() {
        const { data } = this.learnersFetcher
        const paginatedUsers = data && data.paginatedUsers
        const totalCount = paginatedUsers && paginatedUsers.totalCount
        const hasFilters = this.filter.hasFilters({
            excludeKeys: ['searchText'],
            excludeKeyValuePairs: [{ key: 'filterLearnerActive', value: true }],
        })

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={this.renderActionbarButtons}
                getDrawer={this.renderActionBarDrawer}
                amountOfResults={totalCount}
            />
        )
    }

    public renderActionbarButtons = (toggleDrawer: () => void, drawerActive: boolean) => {
        const { loading } = this.learnersFetcher
        const { loading: loadingExport } = this.createExportMutator

        const filters = this.filter.getFilters()
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['searchText'] })

        return (
            <List horizontal>
                <ListItem>
                    <Button
                        leftIcon={<Icon name={`filter`} />}
                        onClick={() => toggleDrawer()}
                        hasNotification={hasFilters}
                        isActive={drawerActive}
                    >
                        Filteropties
                    </Button>
                </ListItem>
                <ListItem>
                    <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.searchText} />
                </ListItem>
                <ListItem right>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={`edit`}>
                                Kandidaat toevoegen
                            </Button>
                        )}
                        getModal={closeModal => (
                            <DrawerModal onClose={closeModal}>
                                <NewLearnerForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                            </DrawerModal>
                        )}
                    />
                </ListItem>
                <ListItem right>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} leftIcon={<Icon name={`upload`} />}>
                                {' '}
                                Import
                            </Button>
                        )}
                        getModal={closeModal => (
                            <ImportLearnersModal
                                closeModal={closeModal}
                                onSuccess={() => {
                                    this.learnersFetcher.refetch()
                                    closeModal()
                                }}
                            />
                        )}
                    />
                </ListItem>
                <ListItem right>
                    <Button
                        leftIcon={<Icon name={`download`} />}
                        onClick={this.exportLearners}
                        isLoading={loadingExport}
                        confirm={{
                            title: 'Exporteren',
                            message: 'Je staat op het punt om een XLS export te maken. Weet je het zeker?',
                            execute: {
                                buttonType: 'submit',
                                title: 'Export maken',
                            },
                        }}
                    >
                        Export van huidige selectie
                    </Button>
                </ListItem>
            </List>
        )
    }

    public renderActionBarDrawer = () => {
        const { data: filterParams, loading } = this.filterSuggestionsFetcher

        if (!this.filterSuggestionsFetcher.hasFetched) {
            ;(this.filterSuggestionsFetcher as any).fetch()
        }

        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()

        const { addresses_usedCities, groups, organizations, modules, locations } = filterParams

        const addressOptions =
            addresses_usedCities && addresses_usedCities.map((city: string) => ({ value: city, label: city }))
        const groupOptions = groups && groups.map((group: Group) => ({ value: group._id, label: group.name }))
        const organizationOptions =
            organizations &&
            organizations.map((organization: Organization) => ({ value: organization._id, label: organization.name }))
        const moduleOptions =
            modules && modules.map((adviceModule: Module) => ({ value: adviceModule._id, label: adviceModule.name }))
        const locationOptions =
            locations &&
            locations.map((preferredLocation: Location) => ({
                value: preferredLocation._id,
                label: preferredLocation.name,
            }))

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Organisatie`} style={`compact`}>
                    {organizationOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByOrganizationIds"
                            defaultValue={filters.filterByOrganizationIds}
                            options={organizationOptions}
                            placeholder="Selecteer organisatie"
                            isLoading={loading}
                        />
                    )}
                </Field>
                <Field isLabel title={`Plaats`} style={`compact`}>
                    {addressOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByCityNames"
                            defaultValue={filters.filterByCityNames}
                            options={addressOptions}
                            placeholder="Selecteer plaats"
                            isLoading={loading}
                        />
                    )}
                </Field>
                <Field isLabel title={`Groep`} style={`compact`}>
                    {groupOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByGroupIds"
                            defaultValue={filters.filterByGroupIds}
                            options={groupOptions}
                            placeholder="Selecteer groep"
                            isLoading={loading}
                        />
                    )}
                </Field>
                <Field isLabel title={`Advies-lesmodule`} style={`compact`}>
                    {moduleOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByAdviceModuleIds`}
                            defaultValue={filters.filterByAdviceModuleIds}
                            options={moduleOptions}
                            isLoading={loading}
                        />
                    )}
                </Field>
                <Field isLabel title={`Voorkeurslocatie`} style={`compact`}>
                    {locationOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByPreferredLocationIds`}
                            defaultValue={filters.filterByPreferredLocationIds}
                            options={locationOptions}
                            placeholder={`Selecteer voorkeurslocatie`}
                            isLoading={loading}
                        />
                    )}
                </Field>
                <Field title="Status" style="compact">
                    <MultiInput type="checkbox">
                        <CheckBox
                            name="filterIsRegistered"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsRegistered}
                        >
                            Aangemeld
                        </CheckBox>
                        <CheckBox
                            name="filterIsUnassigned"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsUnassigned}
                        >
                            Niet ingedeeld
                        </CheckBox>
                        <CheckBox
                            name="filterIsAssigned"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsAssigned}
                        >
                            Ingedeeld
                        </CheckBox>
                    </MultiInput>
                    <MultiInput type="checkbox">
                        <CheckBox
                            name="filterIsActive"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsActive}
                        >
                            Actief
                        </CheckBox>
                        <CheckBox
                            name="filterIsInactive"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsInactive}
                        >
                            Non-actief
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    public renderTable() {
        const { loading, data } = this.learnersFetcher
        const paginatedUsers = data && data.paginatedUsers
        const { nodes: users = [] } = paginatedUsers || {}

        if (loading) {
            return (
                <Wrap full>
                    <Spinner />
                </Wrap>
            )
        }

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                            Naam
                        </TableHeaderItem>
                        <TableHeaderItem>Plaats</TableHeaderItem>
                        <TableHeaderItem />
                        {/* expansion button */}
                    </TableHeader>
                    {users.length > 0 ? (
                        this.renderLearnersRows(users)
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Subtle>Er zijn geen kandidaten gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    public renderLearnersRows(users: User[]) {
        return users.map(user => (
            <TableRow
                key={user._id}
                getExpansion={() => (
                    <TableCell colSpan={3}>
                        <LearnerRowExpansion userId={user._id} />
                    </TableCell>
                )}
            >
                <TableCell>
                    <Link route={`/users/learners/${user._id}`}>
                        {compact([
                            user.profile.firstName,
                            `${user.profile.givenName ? `(${user.profile.givenName})` : ''}`,
                            user.profile.fullSurName,
                        ]).join(' ')}
                    </Link>
                </TableCell>
                <TableCell>{get(user, 'profile.redundantAddressCity')}</TableCell>
            </TableRow>
        ))
    }
}

const GET_LEARNERS_QUERY = gql`
    query _($take: Int, $skip: Int, $sortBy: String, $sortDir: String, $filters: UsersFilterInputType) {
        paginatedUsers(take: $take, skip: $skip, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            totalCount
            hasNextPage
            nodes {
                _id
                email
                lastActivityAt
                profile {
                    firstName
                    fullSurName
                    givenName
                    redundantAddressCity
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
        organizations(sortBy: "name") {
            _id
            name
        }
        groups(sortBy: "name") {
            _id
            name
        }
        modules(sortBy: "name") {
            _id
            name
        }
        locations(sortBy: "name") {
            _id
            name
        }
    }
`

const CREATE_LEARNERS_EXPORT_FILE_MUTATION = gql`
    mutation _($sortBy: String, $sortDir: String, $filters: UsersFilterInputType, $columns: [String!]) {
        users_createExportFile(sortDir: $sortDir, sortBy: $sortBy, filters: $filters, columns: $columns) {
            fileId
        }
    }
`
