import * as React from 'react'

import { SubNav } from '~/components/SubNav'
import { SubNavLink } from '~/components/SubNavLink'
import { View } from '~/components/View'
import { Translator } from '~/services/i18n'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

interface Props {}

export default class StatisticsView extends React.Component<Props> {
    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.translator = new Translator({
            namespace: 'App.Statistics',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { t } = this.translator
        const { children } = this.props

        return (
            <View>
                <SubNav>
                    <SubNavLink route={`/statistics/email-bounces`}>{t('navigation.buttons.email-bounces')}</SubNavLink>
                </SubNav>
                <BreadCrumbProvider>{children}</BreadCrumbProvider>
            </View>
        )
    }
}
