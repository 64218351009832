import { gql } from '@apollo/client'
import { get, isNumber, merge } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Field,
    FieldCollection,
    Link,
    ReadablePhoneNumbers,
    Spinner,
    Timeline,
    TimelineInfo,
    TimelineWrap,
} from '~/components'
import { getCurrentUser } from '~/services/session'
import { Fetcher } from '~/utils'
import AddressFieldGroup from '~/components/AddressFieldGroup'
import { AddressFieldsFragment } from '~/types/Address'

export default class UserTimeline extends Component {
    static propTypes = {
        timelineEventOwnerType: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        hideFieldsForGroupTeacher: PropTypes.bool,
    }

    static defaultProps = {
        hideFieldsForGroupTeacher: false,
    }

    constructor(props) {
        super(props)

        this.userInfoFetcher = new Fetcher({
            query: USER_INFORMATION_QUERY,
            variables: {
                usersFilters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    user: get(data, 'users[0]'),
                }
            },
        })

        this.timelineEventsFetcher = new Fetcher({
            query: TIMELINE_EVENTS_QUERY,
            variables: {
                usersFilters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
            transformData(data) {
                const user = get(data, 'users[0]')

                return {
                    timelineEvents: (get(user, 'profile.timelineEvents') || []).map(timelineEvent =>
                        merge(
                            {
                                logTypeData: { invoice: { user } },
                            },
                            timelineEvent
                        )
                    ),
                }
            },
        })
    }

    render() {
        const { userId, timelineEventOwnerType } = this.props
        const {
            loading: userInfoLoading,
            data: { user },
        } = this.userInfoFetcher
        const {
            loading: timelineEventsLoading,
            data: { timelineEvents },
            refetch,
        } = this.timelineEventsFetcher
        const targetChoices = this.getTargetChoices()

        return (
            <TimelineWrap>
                <Timeline
                    ownerType={timelineEventOwnerType}
                    ownerTypeDataUserId={userId}
                    timelineEvents={timelineEvents}
                    isLoading={timelineEventsLoading}
                    refetch={refetch}
                    targetChoices={targetChoices}
                />

                <TimelineInfo>
                    {userInfoLoading && <Spinner />}
                    {!userInfoLoading && user && this.renderUserInfo()}
                </TimelineInfo>
            </TimelineWrap>
        )
    }

    renderUserInfo() {
        const { hideFieldsForGroupTeacher } = this.props
        const {
            data: { user },
        } = this.userInfoFetcher

        const phoneNumbers = get(user, 'profile.phoneNumbers') || []
        const email = get(user, 'email')
        const totalAttendanceRate = get(user, 'learner.totalAttendanceRate')

        return (
            <FieldCollection>
                <Field isCompact title={`Telefoonnummer`}>
                    <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                </Field>
                <Field isCompact title={`E-mailadres`}>
                    {email && (
                        <Link isNative href={`mailto:${email}`}>
                            {email}
                        </Link>
                    )}
                </Field>
                {!hideFieldsForGroupTeacher && (
                    <div>
                        <AddressFieldGroup user={user} />
                    </div>
                )}
                {user.isLearner && (
                    <Field isCompact title={`Totale aanwezigheid`}>
                        {(isNumber(totalAttendanceRate) && `${Math.round(totalAttendanceRate * 100)}%`) || '-'}
                    </Field>
                )}
            </FieldCollection>
        )
    }

    getTargetChoices() {
        const { timelineEventOwnerType } = this.props

        if (timelineEventOwnerType === 'LEARNER_USER') {
            const user = getCurrentUser()

            if (user.isEmployee) {
                return [
                    {
                        keys: ['GROUP_TEACHER', 'LEARNER_INTAKER'],
                        label: 'Zichtbaar voor docenten',
                        getIsDisabled: timelineEvent =>
                            timelineEvent.createdByUser && timelineEvent.createdByUser.isExternalTeacher,
                    },
                    {
                        keys: ['RELATED_ORGANIZATION_CONTACT'],
                        label: 'Zichtbaar voor organisaties',
                        getIsDisabled: () => false,
                    },
                ]
            }
        }
    }
}

const USER_INFORMATION_QUERY = gql`
    query _($usersFilters: UsersFilterInputType) {
        users(filters: $usersFilters) {
            _id
            email
            isLearner
            learner {
                totalAttendanceRate
            }
            profile {
                address {
                    ...AddressFieldsFragment
                }
                visitAddress {
                    ...AddressFieldsFragment
                }
                phoneNumbers {
                    _id
                    country
                    internationalPhoneNumber
                    info
                }
            }
        }
    }
    ${AddressFieldsFragment}
`

const TIMELINE_EVENTS_QUERY = gql`
    query _($usersFilters: UsersFilterInputType) {
        users(filters: $usersFilters) {
            _id
            profile {
                name
                timelineEvents {
                    _id
                    date
                    type
                    typeData {
                        body
                    }
                    owner {
                        targets
                    }
                    createdByUser {
                        _id
                        isExternalTeacher
                        profile {
                            name
                        }
                    }
                    isLog
                    logType
                    logTypeData {
                        invoice {
                            _id
                            isCredit
                            invoiceNumber
                            isDownloadable
                        }
                    }
                }
            }
        }
    }
`
