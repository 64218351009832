import * as React from 'react'

import { DefinitiveInflowMomentsTable } from '~/components/InflowMoment/DefinitiveInflowMomentsTable'
import { getCurrentUser } from '~/services/session'
import { DefinitiveOrganizationInflowMomentsTable } from '~/components/InflowMoment/DefinitiveOrganizationInflowMomentsTable'

export class DefinitiveInflowMomentsMasterView extends React.Component {
    public render() {
        if (this.userIsOrganizationContactOrIntaker()) {
            return <DefinitiveOrganizationInflowMomentsTable />
        }

        return <DefinitiveInflowMomentsTable />
    }

    private userIsOrganizationContactOrIntaker(): boolean {
        const currentUser = getCurrentUser()

        if (!currentUser) {
            return false
        }

        return (currentUser.isOrganizationContact || currentUser.isIntaker) && !currentUser.isEmployee
    }
}
