import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class Header extends React.Component<Props> {
    private bem = new BEM('Header')

    public render() {
        const { className, children } = this.props

        return <header className={this.bem.getClassName(className)}>{children}</header>
    }
}
