import { gql } from '@apollo/client'
import * as React from 'react'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { Mutator, toast } from '~/utils'
import { PasswordInput } from '~/components/PasswordInput/PasswordInput'
import { ErrorLabel } from '~/components/ErrorLabel/ErrorLabel'
import { Translator } from '~/services/i18n'
import { Auth } from 'aws-amplify'

const CHANGE_PASSWORD_MUTATION = gql`
    mutation _($currentPassword: String, $password: String, $passwordConfirmation: String) {
        users_change_password(
            currentPassword: $currentPassword
            password: $password
            passwordConfirmation: $passwordConfirmation
        ) {
            _id
        }
    }
`

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
}

interface State {
    passwordScore: number
    password: string | null
    passwordConfirm: string | null
}

export class ChangePasswordForm extends React.Component<Props, State> {
    public state: State = {
        passwordScore: 0,
        password: null,
        passwordConfirm: null,
    }

    private userMutator: Mutator
    private translator = new Translator({
        namespace: 'App.Users.Employees.ChangePasswordForm',
        subscribe: this,
    })

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: CHANGE_PASSWORD_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.userMutator
        const { onCancel } = this.props
        const { password } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`} className={`tt-FieldCollection--password-form`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Huidig wachtwoord`} errors={errors}>
                            <Input
                                name={`currentPassword`}
                                type={`password`}
                                placeholder={`Wachtwoord`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field
                            isLabel={true}
                            className={password ? 'tt-Field--has-password-hint' : ''}
                            title={`Nieuw wachtwoord`}
                            errors={errors}
                        >
                            <PasswordInput
                                name={`password`}
                                placeholder={`Gebruik hoofdletters en cijfers`}
                                onChangePassword={password => this.setState({ password })}
                                onStrengthChange={(passwordScore: number) => this.setState({ passwordScore })}
                            />
                            <ErrorLabel>{this.getErrorLabelForPassword()}</ErrorLabel>
                        </Field>
                        <Field isLabel={true} title={`Herhaal nieuw wachtwoord`} errors={errors}>
                            <Input
                                name={`passwordConfirmation`}
                                type={`password`}
                                placeholder={`Nogmaals`}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({ passwordConfirm: event.target.value })
                                }
                            />
                            <ErrorLabel>{this.getErrorLabelForPasswordConfirm()}</ErrorLabel>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading} isDisabled={!this.canSubmitForm()}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        if (!this.canSubmitForm()) {
            return
        }

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, fields.currentPassword, fields.password)
            })
            .then(data => {
                console.log(data)
                toast.success('Wachtwoord is gewijzigd')
                onSubmitSuccess()
            })
            .catch(err => {
                console.log(err)
                if (onSubmitFailed) {
                    onSubmitFailed()
                }
            })
    }

    private getErrorLabelForPassword() {
        const { password, passwordConfirm, passwordScore } = this.state
        const { t } = this.translator

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 4) {
            return t(`passwordNotStrongEnough`)
        }

        return null
    }

    private getErrorLabelForPasswordConfirm() {
        const { password, passwordConfirm, passwordScore } = this.state
        const { t } = this.translator

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 4) {
            return null
        }

        if (password !== passwordConfirm) {
            return t(`passwordDoesNotMatch`)
        }

        return null
    }

    private canSubmitForm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return false
        }

        if (password !== passwordConfirm) {
            return false
        }

        if (passwordScore < 4) {
            return false
        }

        return true
    }
}
