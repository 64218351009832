import { gql } from '@apollo/client'
import { get, sortBy, groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, Component } from 'react'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import {
    Button,
    CenterModal,
    DrawerModal,
    Highlight,
    Link,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Wrap } from '~/components/Wrap'
import { TableView } from '~/components/TableView'
import { ChangeAttendanceForm } from '~/forms'
import { EditLessonForm } from '~/forms/EditLessonForm'
import { translateType } from '~/shared/utils'
import { Fetcher } from '~/utils'

export default class DetailView extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            lessonId: PropTypes.string.isRequired,
        }).isRequired,
    }

    static sortLessonUsers = (a, b) => {
        if (!a.learnerUser || !b.learnerUser) return 0
        if (a.learnerUser.profile.name < b.learnerUser.profile.name) return -1
        if (a.learnerUser.profile.name > b.learnerUser.profile.name) return 1
        return 0
    }

    constructor(props) {
        super(props)

        const { id: groupId, lessonId } = props.params

        this.lessonsFetcher = new Fetcher({
            query: GET_GROUP_LESSON_QUERY,
            variables: {
                lessonId,
                filters: {
                    filterById: groupId,
                },
                filterPlannedAbilityExamsByGroupId: groupId,
            },

            onChange: () => this.forceUpdate(),
            transformData: data => ({
                ...data,
                groups: [
                    {
                        ...data.groups[0],
                        lessons: data.groups[0].lessons.map(lesson => ({
                            ...lesson,
                            lessonUsers: sortBy(lesson.lessonUsers, lu => lu.learnerUser.profile.name),
                        })),
                    },
                ],
            }),
        })
    }

    onLessonEdit(closeModal) {
        this.lessonsFetcher.refetch()
        closeModal()
    }

    getLessonUsersCount() {
        const { data } = this.lessonsFetcher
        const lessonUsers = get(data, 'groups[0].lessons[0].lessonUsers') || []

        return lessonUsers.length
    }

    getPresentLessonUsersCount() {
        const { data } = this.lessonsFetcher
        const lessonUsers = get(data, 'groups[0].lessons[0].lessonUsers') || []

        return lessonUsers.filter(lu => lu.attendance === 'PRESENT').length
    }

    render() {
        const { ...routeProps } = this.props
        const { data, loading } = this.lessonsFetcher

        const group = get(data, 'groups[0]')
        const lesson = get(group, 'lessons[0]')

        return (
            <RouteView crumbLabel={lesson && this.getLessonName(lesson)} routeProps={routeProps} isLoading={loading}>
                {this.renderViewContent()}
            </RouteView>
        )
    }

    getLessonName(lesson) {
        return `Les ${lesson ? lesson.order + 1 : ''}`
    }

    renderViewContent() {
        const { data, loading } = this.lessonsFetcher
        if (loading) return null

        const group = get(data, 'groups[0]')
        const lesson = get(group, 'lessons[0]')

        if (!lesson) {
            return (
                <ContentView>
                    <Wrap full>
                        <Subtle>Les niet gevonden.</Subtle>
                    </Wrap>
                </ContentView>
            )
        }

        return (
            <ContentView>
                <Wrap full>{this.renderLessonInfo(group, lesson)}</Wrap>
                <TableView>
                    <TableWrap>{this.renderAttendanceTable(lesson)}</TableWrap>
                </TableView>
                <Wrap full>{!group.endedAt && this.renderAttendanceFooter(group, lesson)}</Wrap>
            </ContentView>
        )
    }

    renderLessonInfo(group, lesson) {
        const teacherUser = (group.teacherUsers || []).map(tu => tu.user).find(u => u._id === lesson.teacherUserId)

        return (
            <Highlight>
                <List horizontal>
                    <ListItem>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Lesdatum</SimpleTableCell>
                                <SimpleTableCell>
                                    {lesson.date && <ReadableDate date={lesson.date} showTime showDayOfWeek />}
                                </SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Locatie</SimpleTableCell>
                                {lesson.location && !lesson.room && (
                                    <SimpleTableCell>
                                        <Link route={`/properties/locations/${lesson.location._id}`}>
                                            {lesson.location.name}
                                        </Link>
                                    </SimpleTableCell>
                                )}
                                {lesson.room && (
                                    <SimpleTableCell>
                                        <Link
                                            route={`/properties/locations/${lesson.room.location._id}/rooms/${lesson.room._id}`}
                                        >
                                            {lesson.room.name}
                                        </Link>{' '}
                                        te{' '}
                                        <Link route={`/properties/locations/${lesson.room.location._id}`}>
                                            {lesson.room.location.name}
                                        </Link>
                                    </SimpleTableCell>
                                )}
                                {!lesson.location && !lesson.room && <SimpleTableCell>-</SimpleTableCell>}
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Docent</SimpleTableCell>
                                <SimpleTableCell>
                                    {(teacherUser && (
                                        <Link route={`/users/teachers/${teacherUser._id}`}>
                                            {get(teacherUser, 'profile.name')}
                                        </Link>
                                    )) ||
                                        '-'}
                                </SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Aantal kandidaten</SimpleTableCell>
                                <SimpleTableCell>{this.getLessonUsersCount()}</SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Aanwezig</SimpleTableCell>
                                <SimpleTableCell>{this.getPresentLessonUsersCount()}</SimpleTableCell>
                            </SimpleTableRow>
                            {!!lesson && this.renderSpecialActivity(lesson)}
                        </SimpleTable>
                    </ListItem>
                    {!group.endedAt && (
                        <ListItem right>
                            <ModalManager
                                render={openModal => <Button onClick={openModal}>Bewerken</Button>}
                                getModal={closeModal => {
                                    return (
                                        <CenterModal onClose={closeModal} title={`Les bewerken`}>
                                            <EditLessonForm
                                                group={group}
                                                lesson={lesson}
                                                onSubmitSuccess={() => this.onLessonEdit(closeModal)}
                                                onCancel={closeModal}
                                            />
                                        </CenterModal>
                                    )
                                }}
                            />
                        </ListItem>
                    )}
                </List>
            </Highlight>
        )
    }

    renderAttendanceTable(lesson) {
        const { loading } = this.lessonsFetcher
        const { lessonUsers } = lesson

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                    <TableHeaderItem>Presentie</TableHeaderItem>
                    <TableHeaderItem>Uitgevoerd door</TableHeaderItem>
                    <TableHeaderItem>Datum</TableHeaderItem>
                </TableHeader>
                {loading && this.renderLoadingStateRow()}
                {!loading && lessonUsers.length === 0 && this.renderEmptyStateRow()}
                {!loading && lessonUsers.length > 0 && this.renderLessonUserRows(lessonUsers)}
            </Table>
        )
    }

    renderEmptyStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={4}>
                    <Subtle>Deze les bevat geen deelnemers.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    renderLoadingStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={4} isLoading />
            </TableRow>
        )
    }

    renderLessonUserRows(lessonUsers) {
        return lessonUsers.map(lessonUser => {
            const { learnerUser, reasonOfAbsence, attendanceNote } = lessonUser

            const showExpansion = !!(attendanceNote || reasonOfAbsence)

            return (
                <TableRow
                    key={learnerUser._id}
                    isExpansionSeamless={showExpansion}
                    isAlwaysExpanded={showExpansion}
                    getExpansion={
                        showExpansion
                            ? () => (
                                  <>
                                      <TableCell />
                                      <TableCell>{translateType('lessonReasonOfAbsence', reasonOfAbsence)}</TableCell>
                                      <TableCell colSpan={2}>{attendanceNote}</TableCell>
                                  </>
                              )
                            : undefined
                    }
                >
                    <TableCell>
                        <Link route={`/users/learners/${learnerUser._id}`}>{get(learnerUser, 'profile.name')}</Link>
                    </TableCell>
                    <TableCell>
                        {lessonUser.attendance && translateType('lessonAttenance', lessonUser.attendance)}
                    </TableCell>
                    <TableCell>
                        {lessonUser.attendanceSetByUser && (
                            <Link route={`/users/teachers/${lessonUser.attendanceSetByUser._id}`}>
                                {get(lessonUser.attendanceSetByUser, 'profile.name')}
                            </Link>
                        )}
                    </TableCell>
                    <TableCell>
                        {lessonUser.attendanceSetAt && (
                            <ReadableDate showTime={true} date={new Date(lessonUser.attendanceSetAt)} />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderAttendanceFooter(group, lesson) {
        const { lessonUsers } = lesson

        return (
            <List horizontal>
                <ListItem right>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type="edit" isDisabled={lessonUsers.length === 0}>
                                Presentie bewerken
                            </Button>
                        )}
                        getModal={closeModal => {
                            return (
                                <DrawerModal onClose={closeModal}>
                                    <ChangeAttendanceForm
                                        getRouteForUserId={userId => `/users/learners/${userId}`}
                                        group={group}
                                        lesson={lesson}
                                        onDone={() => this.onLessonEdit(closeModal)}
                                    />
                                </DrawerModal>
                            )
                        }}
                    />
                </ListItem>
            </List>
        )
    }

    renderSpecialActivity = lesson => {
        return (
            <SimpleTableRow>
                <SimpleTableCell isBold>Bijzondere activiteit</SimpleTableCell>
                {lesson.specialActivity && this.getReadableSpecialActivity(lesson)}
            </SimpleTableRow>
        )
    }

    getReadableSpecialActivity = lesson => {
        const { specialActivity, plannedAbilityExams } = lesson

        return (
            <SimpleTableCell>
                <span>
                    {translateType('lessonSpecialActivity', specialActivity)}
                    {specialActivity === 'exam' && plannedAbilityExams.length > 0 && (
                        <React.Fragment> {this.renderExams(plannedAbilityExams)}</React.Fragment>
                    )}
                </span>
            </SimpleTableCell>
        )
    }

    renderExams(plannedAbilityExams) {
        return this.groupByExam(plannedAbilityExams).map((plannedAbilityExamGroup, i, arr) => {
            return (
                <Fragment key={i}>
                    <Link route={`/properties/exams/${plannedAbilityExamGroup.exam._id}`}>
                        {plannedAbilityExamGroup.exam.name}
                    </Link>{' '}
                    <Subtle>{this.getTranslatedAbilities(plannedAbilityExamGroup.examAbilities)}</Subtle>
                    {arr.length > i + 1 && ', '}
                </Fragment>
            )
        })
    }

    getTranslatedAbilities = examAbilities => {
        const names = examAbilities.map(examAbility => {
            return translateType('examAbility', examAbility)
        })

        if (names) {
            const abilitiesLength = names.length

            if (abilitiesLength > 2) {
                const arrayWithoutLastItem = names.slice(0, -1)
                const lastArrayItem = names[abilitiesLength - 1]

                return `${arrayWithoutLastItem.join(', ')} en ${lastArrayItem}`
            }

            if (abilitiesLength > 0) {
                return names.join(' en ')
            }
        }

        return null
    }

    /**
     * Group the planned ability exams by exam
     *
     * @param {PlannedAbilityExam[]} plannedAbilityExams
     */
    groupByExam(plannedAbilityExams) {
        const plannedAbilityExamsByExamId = groupBy(
            plannedAbilityExams,
            plannedAbilityExam => plannedAbilityExam.exam._id
        )

        return Object.keys(plannedAbilityExamsByExamId).map(examId => {
            const groupedPlannedAbilityExams = plannedAbilityExamsByExamId[examId]
            const exam = groupedPlannedAbilityExams[0].exam

            return {
                exam,
                examAbilities: groupedPlannedAbilityExams.map(plannedAbilityExam => plannedAbilityExam.examAbility),
            }
        })
    }
}

const GET_GROUP_LESSON_QUERY = gql`
    query _($filters: GroupsFilterInputType, $lessonId: MongoID, $filterPlannedAbilityExamsByGroupId: MongoID) {
        groups(filters: $filters) {
            _id
            endedAt
            module {
                _id
                exams {
                    _id
                    exam {
                        _id
                        name
                        plannedAbilityExams(filterByGroupId: $filterPlannedAbilityExamsByGroupId) {
                            _id
                            examAbility
                            lesson {
                                _id
                                order
                            }
                        }
                    }
                    abilities {
                        converse
                        listen
                        read
                        talk
                        write
                    }
                }
            }
            teacherUsers {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
            lessons(byId: $lessonId) {
                _id
                date
                order
                teacherUserId
                specialActivity
                location {
                    _id
                    name
                }
                room {
                    _id
                    name
                    location {
                        _id
                        name
                    }
                }
                plannedAbilityExams {
                    _id
                    exam {
                        _id
                        name
                    }
                    examAbility
                }
                hasResults
                lessonUsers {
                    _id
                    learnerUser {
                        _id
                        profile {
                            name
                        }
                    }
                    attendance
                    reasonOfAbsence
                    attendanceNote
                    attendanceSetByUser {
                        _id
                        profile {
                            name
                        }
                    }
                    attendanceSetAt
                }
            }
        }
    }
`
