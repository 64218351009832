import React, { Component } from 'react'

import { Header } from '~/components'
import { TeacherAvailability } from '~/implementations'
import { getCurrentUser } from '~/services/session'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

export default class ExternalTeacherAvailabilityView extends Component {
    render() {
        const { ...routeProps } = this.props
        const currentUser = getCurrentUser()

        return (
            <BreadCrumbProvider>
                <RouteView crumbLabel={'Beschikbaarheid'} routeProps={routeProps} isTab={true}>
                    <Header>
                        <BreadCrumbs />
                    </Header>
                    {currentUser && <TeacherAvailability teacherUserId={currentUser._id} />}
                </RouteView>
            </BreadCrumbProvider>
        )
    }
}
