import c from 'classnames'
import getFormData from 'get-form-data'
import { set, isArrayLike } from 'lodash'
import React from 'react'
import { isNode } from '~/utils/dom'

export interface FormFields {
    [key: string]: any
}

interface Props {
    onSubmit?: (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => void
    className?: string
    loading?: string
    onChange?: () => void
    id?: string
}

export default class Form extends React.Component<Props> {
    private formRef = React.createRef<HTMLFormElement>()

    public render() {
        const { children, className: extraClassNames, loading, onChange, id } = this.props
        const className = c('tt-Form', extraClassNames, {})
        return (
            <form ref={this.formRef} onSubmit={this.onSubmit} className={className} onChange={onChange} id={id}>
                {!loading && children}
            </form>
        )
    }

    public clear() {
        if (this.formRef.current) {
            this.formRef.current.reset()
        }
    }

    public getFormValues(): FormFields {
        if (!this.formRef.current) {
            return {}
        }

        const formData = getFormData(this.formRef.current)

        const values = {}

        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                const value = formData[key]
                const elementOrElements = this.formRef.current.elements[key]
                const element = isNode(elementOrElements) ? elementOrElements : elementOrElements[0]

                if (!element.name) {
                    continue
                }

                const parsedValue = this.parseValue(element, value)

                set(values, key, parsedValue)
            }
        }

        return values
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const { onSubmit } = this.props

        if (onSubmit) {
            event.preventDefault()
            event.stopPropagation()

            onSubmit(event, this.getFormValues())
        }
    }

    /**
     * Parse a form value given an element and serialized value
     */
    private parseValue(elementOrElements: HTMLInputElement | HTMLInputElement[], value: any): any {
        const element: HTMLInputElement = isArrayLike(elementOrElements) ? elementOrElements[0] : elementOrElements

        // Parse checkbox input
        if (element.type === 'checkbox') {
            if (Array.isArray(value)) {
                return value
            }

            return element.checked
        }

        if (element.type === 'radio') {
            if (value === 'true') {
                return true
            }

            if (value === 'false') {
                return false
            }

            return value
        }

        // Parse number input
        if (element.type === 'number') {
            return value && value.trim() !== '' ? +value : null
        }

        // Parse any empty input
        if (value === '') {
            return null
        }

        return value
    }
}
