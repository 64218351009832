import './VariableMultiFieldGroup.scss'
import c from 'classnames'
import { times } from 'lodash'
import * as React from 'react'

import { Button, Field, Icon } from '~/components'

interface Props {
    addButtonLabel?: string
    baseAmount?: number
    className?: string
    isInsetGroup?: boolean
    limit?: number | boolean
    isDisabled?: boolean
    renderFieldGroup: (context: { iteration: number; renderRemoveButton?: () => React.ReactNode }) => void
}

interface State {
    amountOfInputs: number
}

export class VariableMultiFieldGroup extends React.Component<Props, State> {
    public static defaultProps = {
        baseAmount: 1,
        limit: false,
        addButtonLabel: 'Toevoegen',
        isInsetGroup: true,
    }

    public state: State = {
        amountOfInputs: 1,
    }

    constructor(props: Props) {
        super(props)

        this.state = { amountOfInputs: this.props.baseAmount || 1 }
    }

    public render() {
        const { renderFieldGroup, addButtonLabel, isDisabled, className: inheritedClassNames } = this.props
        const { amountOfInputs } = this.state

        return (
            <div className={c('tt-VariableMultiFieldGroup', inheritedClassNames)}>
                {times(amountOfInputs, index => {
                    const isLastFieldGroup = index + 1 === amountOfInputs
                    const isFirstFieldGroup = index === 0

                    return (
                        <React.Fragment key={index}>
                            {/* FieldGroup Inputs */}
                            {renderFieldGroup({
                                iteration: index,
                                renderRemoveButton:
                                    (isLastFieldGroup &&
                                        !isFirstFieldGroup &&
                                        (() => (
                                            <Button
                                                type={`button`}
                                                onClick={() => this.onRemove()}
                                                leftIcon={<Icon name={`trash`} />}
                                                className={`tt-VariableMultiFieldGroup__remove-button`}
                                                isDisabled={isDisabled}
                                            />
                                        ))) ||
                                    undefined,
                            })}

                            {isLastFieldGroup && (
                                <Field title={true}>
                                    <Button
                                        type={`button`}
                                        linkStyle={`default`}
                                        onClick={() => this.onAdd()}
                                        isDisabled={isDisabled}
                                        leftIcon={<Icon name={`plus-bold`} />}
                                    >
                                        {addButtonLabel}
                                    </Button>
                                </Field>
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    private onAdd = () => {
        const { limit } = this.props
        const { amountOfInputs } = this.state

        if (limit !== false && limit !== undefined && amountOfInputs >= limit) {
            return
        }

        this.setState({
            amountOfInputs: amountOfInputs + 1,
        })
    }

    private onRemove = () => {
        const { amountOfInputs } = this.state

        if (amountOfInputs === 0) {
            return
        }

        this.setState({
            amountOfInputs: amountOfInputs - 1,
        })
    }
}
