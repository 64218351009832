import { gql } from '@apollo/client'
import { get } from 'lodash'
import is from 'powercheck'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { ActionBar, Button, Field, FieldCollection, Header, Icon, PdfModal } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Mutator, toast } from '~/utils'

import { FieldGroups, GroupAddButtons, LessonsSchedule } from './partials'

export default class DetailView extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    state = {
        groupValidationErrors: [],
        groupIsValid: false,
    }

    constructor(props) {
        super(props)

        this.groupEditMutator = new Mutator({
            mutation: GROUP_EDIT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.groupMakeFinalMutator = new Mutator({
            mutation: MAKE_GROUP_FINAL_MUTATION,
            reactComponentToUpdate: this,
        })

        this.removeUsersMutator = new Mutator({
            mutation: REMOVE_USERS_MUTATION,
            reactComponentToUpdate: this,
        })

        this.groupValidateMutator = new Mutator({
            mutation: GROUP_VALIDATE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.groupDeleteMutator = new Mutator({
            mutation: DELETE_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })

        this.groupFetcher = new Fetcher({
            query: GET_GROUP_QUERY,
            variables: {
                filters: {
                    filterById: props.params.id,
                    filterByConcepts: true,
                },
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.validateConceptGroup()
            },
        })

        this.createLessonTimeTableFileMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_TIMETABLE_FILE,
            reactComponentToUpdate: this,
        })
    }

    validateConceptGroup() {
        const { params } = this.props

        this.groupValidateMutator
            .mutate({
                groupId: params.id,
            })
            .then(res => {
                if (res) {
                    this.setState({
                        groupValidationErrors: res.groups_validate.errors || [],
                        groupIsValid: res.groups_validate.success || false,
                    })
                } else {
                    this.setState({
                        groupValidationErrors: [],
                        groupIsValid: false,
                    })
                }
            })
    }

    deleteGroup = async () => {
        const { data } = this.groupFetcher
        const group = get(data, 'groups[0]')

        if (!group) {
            return
        }

        const res = await this.groupDeleteMutator.mutate({
            _id: group._id,
        })

        if (res) {
            toast.success(`Groep "${group.name}" is verwijderd.`)
            browserHistory.push(`/groups/concept`)
        }
    }

    makeGroupFinal = async () => {
        const { data } = this.groupFetcher
        const group = get(data, 'groups[0]')

        this.groupMakeFinalMutator
            .mutate({
                _id: group._id,
            })
            .then(res => {
                if (res) {
                    browserHistory.push(`/groups/${group._id}`)
                }
            })
    }

    /**
     * Ehether the information for the key is set (in the group)
     *
     * @param {key}
     * @returns {Boolean}
     */
    isKeySet(key) {
        const { data } = this.groupFetcher
        const group = get(data, 'groups[0]')
        if (!group) return false

        return !!{
            Name: () => true,
            Module: () => group.module,
            Learners: () => group.learnerUsers && group.learnerUsers.length > 0,
            LessonDates: () => group.generalWeekLessonDates && group.generalWeekLessonDates.length > 0,
            StartWeekDate: () => group.startWeekDate,
            Location: () => group.location,
            Teachers: () => group.teacherUsers && group.teacherUsers.length > 0,
            Employees: () => group.employeeUsers && group.employeeUsers.length > 0,
            LessonTeachers: () =>
                (is(group.startDateIndex, Number) &&
                    group.generalWeekLessonDates &&
                    group.generalWeekLessonDates[group.startDateIndex]) ||
                (group.generalWeekLessonTeacherUsers && group.generalWeekLessonTeacherUsers.length > 0),
            DateBlockers: () => group.dateBlockers && group.dateBlockers.length > 0,
        }[key]()
    }

    /**
     * Ehether the information for the key is set (in the group)
     *
     * @param {key}
     * @returns {Boolean}
     */
    getValidationErrorsForKey(key) {
        const { groupIsValid, groupValidationErrors } = this.state

        if (groupIsValid) return []
        const FieldGroupComponent = FieldGroups[key]

        const errors = groupValidationErrors.filter(error => {
            return FieldGroupComponent.RELATED_ERROR_KEYS.includes(error.key)
        })

        return errors
    }

    getLessonTimetableFileId = async () => {
        const { data } = this.groupFetcher
        const group = get(data, 'groups[0]')

        const res = await this.createLessonTimeTableFileMutator.mutate({
            groupId: group._id,
        })

        if (res && res.users_createLessonTimetableFile) {
            return res.users_createLessonTimetableFile.fileId
        }
    }

    render() {
        const { ...routeComponentProps } = this.props
        const { data, loading } = this.groupFetcher
        const group = get(data, 'groups[0]')

        return (
            <RouteView
                crumbLabel={group && this.getGroupName(group)}
                routeProps={routeComponentProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />
                </Header>
                {!loading && group && this.renderGroupView()}
            </RouteView>
        )
    }

    getGroupName(group) {
        return group.name.trim() || 'Naamloos'
    }

    renderGroupView() {
        const { data, refetch } = this.groupFetcher
        const { loading: groupDeleteLoading } = this.groupDeleteMutator
        const { groupIsValid } = this.state
        const group = get(data, 'groups[0]')
        const timetableLessons = get(group, 'timetableLessons') || []

        const { setKeys, unsetKeys } = [
            'Name',
            'Module',
            'Learners',
            'LessonDates',
            'StartWeekDate',
            'Location',
            'Teachers',
            'Employees',
            'LessonTeachers',
            'DateBlockers',
        ].reduce(
            (o, key) => {
                if (this.isKeySet(key)) o.setKeys.push(key)
                else o.unsetKeys.push(key)
                return o
            },
            { setKeys: [], unsetKeys: [] }
        )

        return (
            <View>
                <ContentView>
                    <Wrap>
                        <FieldCollection>
                            {this.renderFormFieldGroups(setKeys)}
                            {group && unsetKeys.length > 0 && (
                                <FieldGroup title=" ">
                                    <Field>
                                        <GroupAddButtons
                                            group={group}
                                            refetchGroup={refetch}
                                            showButtonsOfKeys={unsetKeys}
                                        />
                                    </Field>
                                </FieldGroup>
                            )}
                            {timetableLessons.length > 0 && (
                                <FieldGroup title="Lessen">
                                    <Field>
                                        <ModalManager
                                            render={openModal => (
                                                <Button
                                                    leftIcon={<Icon name={`eye`} />}
                                                    onClick={openModal}
                                                    confirm={{
                                                        title: 'Conceptrooster',
                                                        message: `Let op. Dit rooster is conceptueel. Het is niet de bedoeling dat deze verstuurd wordt naar de kandidaten. Het definitieve rooster kun je downloaden zodra de groep definitief is gemaakt.`,
                                                        execute: {
                                                            title: 'Bekijken',
                                                        },
                                                    }}
                                                >
                                                    Conceptrooster
                                                </Button>
                                            )}
                                            getModal={closeModal => (
                                                <PdfModal
                                                    title={`Conceptrooster van ${group.name}`}
                                                    fileName={`${group.name}_concept-les-rooster.pdf`}
                                                    getFileId={this.getLessonTimetableFileId}
                                                    onClose={closeModal}
                                                />
                                            )}
                                        />
                                    </Field>
                                    <Field>
                                        <LessonsSchedule group={group} />
                                    </Field>
                                </FieldGroup>
                            )}
                            <FieldCollectionFooter>
                                <ActionBar
                                    getButtons={() => {
                                        return (
                                            <List horizontal>
                                                <ListItem>
                                                    <Button
                                                        onClick={this.deleteGroup}
                                                        isLoading={groupDeleteLoading}
                                                        linkStyle={['default', 'danger']}
                                                        confirm={{
                                                            title: 'Verwijderen',
                                                            message: `Je staat op het punt om de groep "${group.name}" te verwijderen. Weet je het zeker?`,
                                                            execute: {
                                                                buttonType: 'danger',
                                                                title: 'Verwijderen',
                                                            },
                                                        }}
                                                    >
                                                        Verwijderen
                                                    </Button>
                                                </ListItem>
                                                <ListItem right>
                                                    <Button
                                                        onClick={this.makeGroupFinal}
                                                        isDisabled={!groupIsValid}
                                                        leftIcon={<Icon name={`flag`} />}
                                                        confirm={{
                                                            title: 'Groep starten',
                                                            message: `Je staat op het punt om de groep ${group.name} te starten. Weet u zeker dat alles goed is ingevuld?`,
                                                            execute: {
                                                                buttonType: 'submit',
                                                                title: 'Bevestigen',
                                                                buttonCountdown: 6,
                                                            },
                                                        }}
                                                    >
                                                        Groep Starten
                                                    </Button>
                                                </ListItem>
                                            </List>
                                        )
                                    }}
                                />
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Wrap>
                </ContentView>
            </View>
        )
    }

    renderFormFieldGroups(setKeys) {
        const { data, refetch } = this.groupFetcher
        const group = get(data, 'groups[0]')

        return setKeys
            .map(key => ({ key, FieldGroupComponent: FieldGroups[key] }))
            .map(({ key, FieldGroupComponent }) => (
                <FieldGroupComponent
                    key={key}
                    group={group}
                    refetchGroup={refetch}
                    groupEditMutator={this.groupEditMutator}
                    removeUsersMutator={this.removeUsersMutator}
                    validationErrors={this.getValidationErrorsForKey(key)}
                />
            ))
    }
}

const GET_GROUP_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            name
            startWeekDate
            isConcept
            dateFrom
            dateTo
            project {
                _id
                name
                programs {
                    _id
                    name
                }
            }
            program {
                _id
                name
            }
            module {
                _id
                name
                lessonDuration
                amountOfWeeklyLessons
                amountOfTotalLessons
                courseMaterials {
                    _id
                    name
                    amount
                }
            }
            learnerUsers {
                _id
                user {
                    ...userFields
                }
            }
            teacherUsers {
                _id
                user {
                    ...userFields
                }
            }
            employeeUsers {
                _id
                user {
                    ...userFields
                    roles(filterMutable: true)
                }
            }
            location {
                _id
                name
            }
            rooms {
                _id
                name
            }
            generalWeekLessonDates
            generalWeekLessonTeacherUsers {
                _id
                profile {
                    name
                }
            }
            generalWeekLessonRooms {
                _id
                name
            }
            startDateIndex
            lessons {
                _id
                date
                teacherUser {
                    _id
                    profile {
                        name
                    }
                }
            }
            timetableLessons {
                generalWeekLessonDateIndex
                weekIndex
                lesson {
                    _id
                    order
                    date
                }
            }
            localizedExtraLearnerInformation {
                nl
                en
            }
            dateBlockers {
                from
                to
            }
        }
    }

    fragment userFields on UserType {
        _id
        profile {
            name
        }
    }
`

const REMOVE_USERS_MUTATION = gql`
    mutation _($groupId: MongoID!, $groupUsersToRemove: [RemovedGroupUserInputType!]!) {
        groups_changeConceptUsers(groupId: $groupId, groupUsersToRemove: $groupUsersToRemove) {
            _id
        }
    }
`

const GROUP_EDIT_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`

const GROUP_VALIDATE_MUTATION = gql`
    mutation _($groupId: MongoID!) {
        groups_validate(groupId: $groupId) {
            success
            errors {
                key
                stringifiedInfo
            }
        }
    }
`

const DELETE_GROUP_MUTATION = gql`
    mutation _($_id: MongoID!) {
        groups_delete(groupId: $_id) {
            _id
        }
    }
`

const CREATE_LEARNERS_LESSON_TIMETABLE_FILE = gql`
    mutation _($groupId: MongoID!) {
        users_createLessonTimetableFile(groupId: $groupId) {
            fileId
        }
    }
`

const MAKE_GROUP_FINAL_MUTATION = gql`
    mutation _($_id: MongoID!) {
        groups_makeFinal(groupId: $_id) {
            _id
        }
    }
`
