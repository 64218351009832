import * as React from 'react'

export const isReactNodeEmpty = (reactNode: React.ReactNode): boolean => {
    if (typeof reactNode === 'string') {
        return reactNode.trim().length === 0
    }

    if (Array.isArray(reactNode)) {
        if (reactNode.length === 0) {
            return true
        }

        return reactNode.every(isReactNodeEmpty)
    }

    return !reactNode
}
