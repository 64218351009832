import { gql } from '@apollo/client'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Mutator, downloadFile, toast } from '~/utils'
import { Link, DrawerModal, Button, Icon } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { ConceptGroupLearnersForm } from '~/forms'

export default class GroupLearnersFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        removeUsersMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = [
        'user-learner-role-mismatch',
        'user-learner-required',
        'user-learner-inactive',
        'user-learner-missing-advice-module',
    ]

    constructor(props) {
        super(props)

        this.learnerUsersExportMutator = new Mutator({
            mutation: EXPORT_USERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    removeLearners = async () => {
        const { group, refetchGroup, removeUsersMutator } = this.props
        const { learnerUsers } = group

        const res = await removeUsersMutator.mutate({
            groupId: group._id,
            groupUsersToRemove: (learnerUsers || []).map(learnerUser => ({
                userId: learnerUser.user._id,
                role: 'LEARNER',
            })),
        })

        if (res) {
            refetchGroup()
        }
    }

    render() {
        const { validationErrors } = this.props
        const { loading: isLearnersExportLoading } = this.learnerUsersExportMutator

        return (
            <GroupFieldGroup
                title="Kandidaten"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeLearners}
                removeMessage={`Je staat op het punt om alle kandidaten uit deze groep te verwijderen. Weet je het zeker?`}
            >
                {this.renderLearnersList()}
                <Button
                    leftIcon={<Icon name={`download`} />}
                    onClick={this.exportLearnerUsers}
                    isLoading={isLearnersExportLoading}
                    small
                >
                    Uitgebreid overzicht
                </Button>
            </GroupFieldGroup>
        )
    }

    renderLearnersList() {
        const { group } = this.props
        const { learnerUsers } = group

        return (
            <ul>
                {learnerUsers.map(learnerUser => (
                    <li key={learnerUser.user._id}>
                        <Link route={`/users/learners/` + learnerUser.user._id}>{learnerUser.user.profile.name}</Link>
                    </li>
                ))}
            </ul>
        )
    }

    getEditModal = closeModal => {
        const { group, refetchGroup } = this.props
        const onSuccess = () => {
            closeModal()
            refetchGroup()
        }

        const onCancel = () => {
            closeModal()
        }

        return (
            <DrawerModal onClose={closeModal}>
                <ConceptGroupLearnersForm onSuccess={onSuccess} onCancel={onCancel} group={group} />
            </DrawerModal>
        )
    }

    exportLearnerUsers = async () => {
        const { group } = this.props

        const res = await this.learnerUsersExportMutator.mutate({
            filters: {
                filterByGroupIds: [group._id],
                filterByRoleInGroup: 'LEARNER',
            },
            sortBy: 'profile.surName',
            sortDir: 'ASC',
            columns: [
                'FirstName',
                'SurName',
                'Gender',
                'Email',
                'PhoneNumber',
                'DateOfBirth',
                'CityOfBirth',
                'CountryOfBirth',
                'CountryOfOrigin',
                'CountriesOfNationality',
                'Learnability',
                'HighestEducationLocal',
                'HighestEducationLocalYear',
                'HighestEducationCountryOfOrigin',
                'HighestEducationCountryOfOriginYear',
                'IntakeGradesReading',
                'IntakeGradesWriting',
                'IntakeGradesListening',
                'IntakeGradesTalking',
                'IntakeGradesConversations',
                'EnglishAsSupportLanguage',
                'Notes',
                'Groups',
                'NativeLanguages',
                'HomeLanguages',
                'OtherLanguages',
                'PreferredLocations',
                'WriteLevel',
                'WriteDate',
                'ReadLevel',
                'ReadDate',
                'ListenLevel',
                'ListenDate',
                'TalkLevel',
                'TalkDate',
                'ConverseLevel',
                'ConverseDate',
            ],
        })

        if (res && res.users_createExportFile) {
            const { fileId } = res.users_createExportFile
            if (fileId) {
                downloadFile(fileId, `${group.name}_kandidaten.xlsx`)
            } else {
                toast.error('Geen kandidaten gevonden om te exporteren')
            }
        }
    }
}

const EXPORT_USERS_MUTATION = gql`
    mutation _($sortBy: String, $sortDir: String, $filters: UsersFilterInputType, $columns: [String!]) {
        users_createExportFile(filters: $filters, sortBy: $sortBy, sortDir: $sortDir, columns: $columns) {
            fileId
        }
    }
`
