import * as React from 'react'
import { Field, FieldCollection } from '~/components'
import { HeaderTitle } from '~/components/Chrome/Header/HeaderTitle/HeaderTitle'
import { FieldBlock } from '~/components/FieldBlock'
import { FieldGroup } from '~/components/FieldGroup'

import { View } from '~/components/View'

interface Props {}

export default class TotpOnboardingModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    public componentWillUnmount() {}

    public render() {
        return (
            <View>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>
                            <p>
                                MijnTopTaal bevat veel persoonsgevoelige informatie. Om de data te beschermen, hebben we
                                ‘two-factor authentication’ toegepast. Je logt in door middel van een combinatie van
                                e-mailadres, wachtwoord en een code. Deze code vind je in de Google Authenticator app.{' '}
                            </p>
                        </Field>
                        <FieldBlock isWithoutBorder={true} isForm={true}>
                            <Field
                                imageUrl={`/static/images/totp-onboarding-mobile.png`}
                                hasGreyBackground={true}
                                title={``}
                            >
                                <HeaderTitle title={`1. Download de Google Authenticator app op je smartphone.`} />
                            </Field>
                        </FieldBlock>
                        <FieldBlock isWithoutBorder={true} isForm={true}>
                            <Field
                                imageUrl={`/static/images/totp-onboarding-mobile-qr-code.png`}
                                hasGreyBackground={true}
                                title={``}
                            >
                                <HeaderTitle
                                    title={`2. Op het inlogscherm van MijnTopTaal vind je een QR-code. Scan deze met de Google Authenticator app. De app weet nu dat jij het bent. Deze handeling doe je eens per 30 dagen.`}
                                />
                            </Field>
                        </FieldBlock>
                        <FieldBlock isWithoutBorder={true} isForm={true}>
                            <Field hasGreyBackground={true} title={` `}>
                                <HeaderTitle title={`3. Vul de 6 cijferige validatie code in.`} />
                                <p>
                                    In de app verschijnt een 6-cijferige validatiecode. Dit is je beveiligingstoken. Vul
                                    deze code in op het inlogscherm van MijnTopTaal. Bij de volgende keer dat je inlogt,
                                    geeft de app een nieuwe code.
                                </p>
                            </Field>
                        </FieldBlock>
                        <Field hasGreyBackground={true}>
                            <HeaderTitle title={`Heb je vragen of ervaar je problemen met Google Authenticator?`} />
                            <p>Neem dan voor hulp contact op met TopTaal op 088-8545000.</p>
                        </Field>
                    </FieldGroup>
                </FieldCollection>
            </View>
        )
    }
}
