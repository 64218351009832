import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Link } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { PdfModal } from '~/components/PdfModal'
import { Strikethrough } from '~/components/Strikethrough/Strikethrough'
import { Mutator } from '~/utils'
import { InvoiceStatus } from '~/types/Invoice'

// import { openWindowWithHTML } from '~/utils/openWindowWithHTML'

export default class InvoiceLink extends Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        this.createLearnerInvoiceMutator = new Mutator({
            mutation: CREATE_LEARNERS_INVOICE_FILE_MUTATION,
        })
    }

    getFileId = async () => {
        const { invoice } = this.props

        const data = await this.createLearnerInvoiceMutator.mutate({
            invoiceId: invoice._id,
        })

        if (data && data.invoices_generatePdf) {
            const res = data.invoices_generatePdf
            return res.fileId
        }
    }

    render() {
        return <div className={`tt-InvoiceLink`}>{this.renderContent()}</div>
    }

    renderContent() {
        const { invoice } = this.props

        if (!invoice.isDownloadable) {
            if (invoice.status === InvoiceStatus.Canceled) {
                return <Strikethrough>{this.renderName()}</Strikethrough>
            } else {
                return this.renderName()
            }
        }

        return (
            <ModalManager
                render={openModal => {
                    return <Link onClick={openModal}>{this.renderName()}</Link>
                }}
                getModal={onClose => {
                    return (
                        <PdfModal
                            title={this.renderName()}
                            fileName={this.getFileName()}
                            getFileId={this.getFileId}
                            onClose={onClose}
                        />
                    )
                }}
            />
        )
    }

    renderName() {
        const { invoice } = this.props

        return invoice.invoiceNumber ? invoice.invoiceNumber : this.renderConceptName()
    }

    renderModalTitle() {
        const { invoice } = this.props

        return invoice.invoiceNumber ? `Factuur ${invoice.invoiceNumber}` : this.renderConceptName()
    }

    renderConceptName() {
        const { invoice } = this.props

        return invoice.isCredit ? 'Concept creditnota' : 'Conceptfactuur'
    }

    getFileName() {
        const { invoice } = this.props

        return `${invoice.invoiceNumber || 'concept'}-factuur.pdf`
    }
}

const CREATE_LEARNERS_INVOICE_FILE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_generatePdf(invoiceId: $invoiceId) {
            fileId
            #html
        }
    }
`
