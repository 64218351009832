import React, { Component } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { TableRow } from '~/components'
import { Table } from '~/components/Table'

export default class GroupLearnersSelectionTable extends Component {
    static propTypes = {
        children: PropTypes.any,
    }

    render() {
        const { children, ...props } = this.props

        return (
            <Table {...props} hasAutoLayout className="tt-GroupLearnersSelectionTable">
                {children}
            </Table>
        )
    }
}

export class GroupLearnersSelectionTableRow extends Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
    }

    getClassName() {
        const { className } = this.props
        return c('tt-GroupLearnersSelectionTableRow', {}, className)
    }

    render() {
        const { children, ...props } = this.props

        return (
            <TableRow {...props} className={this.getClassName()}>
                {children}
            </TableRow>
        )
    }
}
