import * as EventEmitter from 'events'

export type SubscriptionListener<TPayload = void> = (payload: TPayload) => void

/**
 * Use this Subscriber to initialize a simple pub-subber
 *
 * @example
 *     // Service
 *     const subscriber = new Subscriber<string>()
 *     ...
 *     subscriber.publish('bark')
 *
 *     // Usage
 *     const callbackHandler = sound => {
 *         console.log(sound)
 *     }
 *     subscriber.subscribe(callbackHandler)
 *     ...
 *
 *     // Unsubscribe a specific handler
 *     subscriber.unsubscriber(callbackHandler)
 *
 *     // Unsubscribe all handlers
 *     subscriber.unsubscribeAll()
 *
 */
export class Subscriber<TPayload = any> {
    private event = Symbol('event')
    private eventEmitter = new EventEmitter()

    public subscribe(callback: SubscriptionListener<TPayload>) {
        this.eventEmitter.addListener(this.event, callback)
    }

    public unsubscribe(callback: SubscriptionListener<TPayload>) {
        this.eventEmitter.removeListener(this.event, callback)
    }

    public unsubscribeAll() {
        this.eventEmitter.removeAllListeners(this.event)
    }

    public publish(payload?: TPayload) {
        return this.eventEmitter.emit(this.event, payload)
    }
}
