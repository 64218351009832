import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { AddLearnerParticipantToGroupButton } from '~/components/Group/Participants/Buttons/AddLearnerParticipantToGroupButton'
import { AddTeacherParticipantToGroupButton } from '~/components/Group/Participants/Buttons/AddTeacherParticipantToGroupButton'
import { AddEmployeeParticipantToGroupButton } from '~/components/Group/Participants/Buttons/AddEmployeeParticipantToGroupButton'
import { ExportGroupLearnerParticipantsButton } from '~/components/Group/Participants/Buttons/ExportGroupLearnerParticipantsButton'
import {
    GroupLearnerParticipantsDataProvider,
    GroupLearnerParticipantsDataConsumer,
} from '~/components/Group/Participants/Queries/GroupLearnerParticipantsDataContext'
import {
    GroupTeacherParticipantsDataProvider,
    GroupTeacherParticipantsDataConsumer,
} from '~/components/Group/Participants/Queries/GroupTeacherParticipantsDataContext'
import {
    GroupEmployeeParticipantsDataProvider,
    GroupEmployeeParticipantsDataConsumer,
} from '~/components/Group/Participants/Queries/GroupEmployeeParticipantsDataContext'
import { GroupLearnerParticipantsTable } from '~/components/Group/Participants/Tables/GroupLearnerParticipantsTable'
import { GroupTeacherParticipantsTable } from '~/components/Group/Participants/Tables/GroupTeacherParticipantsTable'
import { GroupEmployeeParticipantsTable } from '~/components/Group/Participants/Tables/GroupEmployeeParticipantsTable'
import { Group } from '~/types/Group'
import { getCurrentUser } from '~/services/session'

interface Props {
    group: Group
}

export class GroupsStartedDetailParticipantsView extends React.Component<Props> {
    private groupId: string
    private groupName: string
    private showAddLearnerButton: boolean
    private showExportLearnersButton: boolean
    private showAddTeacherButton: boolean
    private showAddEmployeeButton: boolean

    constructor(props: Props) {
        super(props)

        this.groupId = props.group._id
        this.groupName = props.group.name!
        const groupHasEnded = !!props.group.endedAt
        const currentUser = getCurrentUser()

        this.showAddLearnerButton = !groupHasEnded && currentUser.isEmployee
        this.showAddTeacherButton = !groupHasEnded && currentUser.isEmployee
        this.showAddEmployeeButton = !groupHasEnded && currentUser.isEmployee
        this.showExportLearnersButton = currentUser.isEmployee
    }

    public render() {
        return (
            <ContentView>
                <TableWrap>
                    <GroupLearnerParticipantsDataProvider groupId={this.groupId}>
                        <TableView title="Kandidaten" buttons={this.renderLearnerButtons()}>
                            {this.renderLearnerTable()}
                        </TableView>
                    </GroupLearnerParticipantsDataProvider>
                </TableWrap>
                <TableWrap>
                    <GroupTeacherParticipantsDataProvider groupId={this.groupId}>
                        <TableView title="Docenten" buttons={this.renderTeacherButtons()}>
                            {this.renderTeacherTable()}
                        </TableView>
                    </GroupTeacherParticipantsDataProvider>
                </TableWrap>
                <TableWrap>
                    <GroupEmployeeParticipantsDataProvider groupId={this.groupId}>
                        <TableView title="Medewerkers" buttons={this.renderEmployeeButtons()}>
                            {this.renderEmployeeTable()}
                        </TableView>
                    </GroupEmployeeParticipantsDataProvider>
                </TableWrap>
            </ContentView>
        )
    }

    private renderLearnerButtons() {
        return (
            <List horizontal>
                {this.showExportLearnersButton && (
                    <ListItem>
                        <ExportGroupLearnerParticipantsButton groupId={this.groupId} groupName={this.groupName} />
                    </ListItem>
                )}
                {this.showAddLearnerButton && (
                    <ListItem>
                        <GroupLearnerParticipantsDataConsumer>
                            {context => (
                                <AddLearnerParticipantToGroupButton
                                    groupId={this.groupId}
                                    onParticipantAdded={() => {
                                        context!.refetch()
                                    }}
                                />
                            )}
                        </GroupLearnerParticipantsDataConsumer>
                    </ListItem>
                )}
            </List>
        )
    }

    private renderTeacherButtons() {
        return (
            <List horizontal>
                {this.showAddTeacherButton && (
                    <ListItem>
                        <GroupTeacherParticipantsDataConsumer>
                            {context => (
                                <AddTeacherParticipantToGroupButton
                                    groupId={this.groupId}
                                    onParticipantAdded={() => {
                                        context!.refetch()
                                    }}
                                />
                            )}
                        </GroupTeacherParticipantsDataConsumer>
                    </ListItem>
                )}
            </List>
        )
    }

    private renderEmployeeButtons() {
        return (
            <List horizontal>
                {this.showAddEmployeeButton && (
                    <ListItem>
                        <GroupEmployeeParticipantsDataConsumer>
                            {context => (
                                <AddEmployeeParticipantToGroupButton
                                    groupId={this.groupId}
                                    onParticipantAdded={() => {
                                        context!.refetch()
                                    }}
                                />
                            )}
                        </GroupEmployeeParticipantsDataConsumer>
                    </ListItem>
                )}
            </List>
        )
    }

    private renderLearnerTable() {
        return (
            <GroupLearnerParticipantsDataConsumer>
                {context => (
                    <GroupLearnerParticipantsTable
                        groupId={this.groupId}
                        groupName={this.groupName}
                        learnerUsers={context!.learnerUsers}
                        loadingLearnerUsers={context!.loading}
                        refetchLearnerUsers={context!.refetch}
                    />
                )}
            </GroupLearnerParticipantsDataConsumer>
        )
    }

    private renderTeacherTable() {
        return (
            <GroupTeacherParticipantsDataConsumer>
                {context => (
                    <GroupTeacherParticipantsTable
                        groupId={this.groupId}
                        groupName={this.groupName}
                        teacherUsers={context!.teacherUsers}
                        loadingTeacherUsers={context!.loading}
                        refetchTeacherUsers={context!.refetch}
                    />
                )}
            </GroupTeacherParticipantsDataConsumer>
        )
    }

    private renderEmployeeTable() {
        return (
            <GroupEmployeeParticipantsDataConsumer>
                {context => (
                    <GroupEmployeeParticipantsTable
                        groupId={this.groupId}
                        groupName={this.groupName}
                        employeeUsers={context!.employeeUsers}
                        loadingEmployeeUsers={context!.loading}
                        refetchEmployeeUsers={context!.refetch}
                    />
                )}
            </GroupEmployeeParticipantsDataConsumer>
        )
    }
}
