import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class Highlight extends React.Component<Props> {
    private bem: BEM<object>

    public render() {
        const { children, className } = this.props
        this.bem = new BEM('Highlight')

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
