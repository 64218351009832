import { gql } from '@apollo/client'
import { get, find } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ReadableDate, Spinner, Subtle, TableHeaderItem, TableRow, TableWrap, ToolTip } from '~/components'
import { ContentView } from '~/components/ContentView'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { AttendanceTable } from '~/components/tables/AttendanceTable/AttendanceTable'
import { AttendanceTableHeader } from '~/components/tables/AttendanceTable/AttendanceTableHeader'
import { AttendanceUserName } from '~/components/tables/AttendanceTable/AttendanceUserName'
import { AttendanceTotalHeaderRow } from '~/components/tables/AttendanceTable/AttendanceTotalHeaderRow'
import { AttendanceTotalCell } from '~/components/tables/AttendanceTable/AttendanceTotalCell'
import { AttendanceCell } from '~/components/tables/AttendanceTable/AttendanceCell'
import { AttendanceDot } from '~/components/tables/AttendanceTable/AttendanceDot'
import { AttendanceRotatedTableHeaderItem } from '~/components/tables/AttendanceTable/AttendanceRotatedTableHeaderItem'
import { AttendanceTableHeaderItem } from '~/components/tables/AttendanceTable/AttendanceTableHeaderItem'
import { Fetcher } from '~/utils'
import { Placement } from '~/components/ToolTip'

export default class AttendanceView extends Component {
    static propTypes = {
        group: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.participantsFetcher = new Fetcher({
            query: GET_GROUP_PARTICIPANTS_QUERY,
            variables: {
                filters: {
                    filterById: props.group._id,
                },
            },

            onChange: () => this.forceUpdate(),
            transformData: data => {
                const [group] = get(data, 'groups') || []
                const learnerUsers = get(group, 'learnerUsers') || []

                return {
                    groupUsers: learnerUsers.slice().sort(this.sortGroupUsers),
                    lessons: get(group, 'lessons') || [],
                }
            },
        })

        this.state = {
            showRotated: true,
            isSmall: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
        this.addTableEventlistener()

        this.table = document.querySelector('.tt-HorizontalScrollView')
        this.horScrollView = document.querySelector('.tt-HorizontalScrollView')
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        this.table.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        const { group } = this.props
        const { data, loading } = this.participantsFetcher

        if (loading) {
            return (
                <ContentView>
                    <TableWrap>
                        <TableView>
                            <Spinner />
                        </TableView>
                    </TableWrap>
                </ContentView>
            )
        }

        const groupUsers = data.groupUsers || []
        const lessons = data.lessons || []

        if (groupUsers.length === 0 || lessons.length === 0) {
            return (
                <ContentView>
                    <TableWrap>
                        <TableView>
                            <Subtle>
                                {groupUsers.length === 0
                                    ? 'Geen deelnemers gevonden'
                                    : lessons.length === 0
                                    ? 'Geen lessen gevonden'
                                    : null}
                            </Subtle>
                        </TableView>
                    </TableWrap>
                </ContentView>
            )
        }

        const biggestNameLength = this.getBiggestNameLength()
        this.setTableWidth()
        return (
            <ContentView>
                <TableWrap>
                    <TableView>
                        <AttendanceTable>
                            <AttendanceTableHeader hidden={!this.state.showRotated}>
                                <TableHeaderItem isSticky={true} isSmall={this.state.isSmall} />
                                {groupUsers.map((groupUser, i) => (
                                    <AttendanceRotatedTableHeaderItem reservedHeight={biggestNameLength * 6} key={i}>
                                        <AttendanceUserName
                                            user={groupUser.user}
                                            group={group}
                                            isRemoved={
                                                !groupUser.isNowEnrolled &&
                                                !groupUser.hasLearnerFinalEnrollment &&
                                                !groupUser.hasLearnerFutureEnrollment
                                            }
                                        />
                                    </AttendanceRotatedTableHeaderItem>
                                ))}
                                <TableHeaderItem width={'150px'} />
                                <TableHeaderItem style={{ width: '100%' }} />
                            </AttendanceTableHeader>
                            <AttendanceTableHeader fixed={true} hidden={this.state.showRotated}>
                                <TableHeaderItem isSticky={true} isSmall={this.state.isSmall} />
                                {groupUsers.map((groupUser, i) => {
                                    const user = groupUser.user.profile.name
                                    return (
                                        <AttendanceTableHeaderItem key={i}>
                                            {/* mocht van overbryaned */}
                                            <ToolTip text={user} placement={Placement.topLeft}>
                                                <AttendanceUserName
                                                    user={groupUser.user}
                                                    group={group}
                                                    isRemoved={
                                                        !groupUser.isNowEnrolled &&
                                                        !groupUser.hasLearnerFinalEnrollment &&
                                                        !groupUser.hasLearnerFutureEnrollment
                                                    }
                                                />
                                            </ToolTip>
                                        </AttendanceTableHeaderItem>
                                    )
                                })}
                            </AttendanceTableHeader>
                            <tbody>
                                <AttendanceTotalHeaderRow>
                                    {/* Wat is smicky? Vraag bryan en rickL */}
                                    <TableCell
                                        isHeader={true}
                                        isSticky={true}
                                        isSmall={this.state.isSmall}
                                        hideWhenSmicky={this.state.isSmall}
                                    >
                                        {/* overRicked */}
                                        {!this.state.isSmall ? 'Groepsaanwezigheid' : 'Groeps...'}
                                    </TableCell>
                                    {groupUsers.map((groupUser, i) => (
                                        <AttendanceTotalCell key={i}>
                                            {`${Math.round(groupUser.learnerAttendanceRate * 100)}%`}
                                        </AttendanceTotalCell>
                                    ))}
                                    <TableCell colSpan={2} />
                                </AttendanceTotalHeaderRow>
                                {lessons.map((lesson, i) => (
                                    <TableRow key={i}>
                                        <TableCell isHeader={true} isSticky={true} isSmall={this.state.isSmall}>
                                            Les {lesson.order + 1}
                                            {!this.state.isSmall && (
                                                <Subtle>
                                                    (<ReadableDate date={lesson.date} format="dd DD-MM-YYYY" />)
                                                </Subtle>
                                            )}
                                        </TableCell>
                                        {groupUsers.map((groupUser, i) => {
                                            const lessonUser = find(lesson.lessonUsers, {
                                                learnerUserId: groupUser.user._id,
                                            })

                                            return (
                                                <AttendanceCell key={i}>
                                                    {lessonUser && lessonUser.attendance ? (
                                                        <AttendanceDot
                                                            attendance={lessonUser.attendance}
                                                            reasonOfAbsence={lessonUser.reasonOfAbsence}
                                                            attendanceNote={lessonUser.attendanceNote}
                                                        />
                                                    ) : (
                                                        <AttendanceDot />
                                                    )}
                                                </AttendanceCell>
                                            )
                                        })}
                                        <TableCell colSpan={2} />
                                    </TableRow>
                                ))}
                            </tbody>
                        </AttendanceTable>
                    </TableView>
                </TableWrap>
            </ContentView>
        )
    }

    addTableEventlistener = () => {
        const table = document.querySelector('.tt-HorizontalScrollView')
        table.addEventListener('scroll', this.handleScroll)
    }

    setTableWidth = () => {
        const rotatedTableHead = document.querySelector('.tt-AttendanceTableHeader')
        const tableHead = document.querySelector('.tt-AttendanceTableHeader--fixed')
        if (rotatedTableHead && tableHead) {
            tableHead.style.width = rotatedTableHead.offsetWidth + 'px'
        }
    }

    handleScroll = () => {
        const currentScrollPos = window.pageYOffset
        const nameHeight = this.getBiggestNameLength() * 5.5
        const currentXpos = this.horScrollView.scrollLeft

        if (!this.attendanceTableHeader) {
            this.attendanceTableHeader = document.querySelector('.tt-AttendanceTableHeader--fixed')
        }

        if (this.attendanceTableHeader) {
            this.attendanceTableHeader.style.transform = 'translateX(-' + currentXpos + 'px)'
        }

        if (currentXpos > 141) {
            if (this.state.isSmall === false) {
                this.setState({
                    isSmall: true,
                })
            }
        }

        if (currentXpos < 139) {
            if (this.state.isSmall === true) {
                this.setState({
                    isSmall: false,
                })
            }
        }

        if (currentScrollPos > nameHeight) {
            if (this.state.showRotated === true) {
                this.setState({
                    showRotated: false,
                })
            }
        }

        if (currentScrollPos < nameHeight) {
            if (this.state.showRotated === false) {
                this.setState({
                    showRotated: true,
                })
            }
        }
    }

    getBiggestNameLength() {
        const { data } = this.participantsFetcher
        const groupUsers = data.groupUsers || []

        const biggestNameLength = Math.max(...groupUsers.map(gu => gu.user.profile.name.length))

        return biggestNameLength
    }

    sortGroupUsers = (a, b) => {
        if (!a.user || !b.user) return 0

        const aIsRemoved = !a.isNowEnrolled && !a.hasLearnerFinalEnrollment
        const bIsRemoved = !b.isNowEnrolled && !b.hasLearnerFinalEnrollment

        if (aIsRemoved && !bIsRemoved) return 1
        if (!aIsRemoved && bIsRemoved) return -1
        if (!aIsRemoved === !bIsRemoved) {
            if (a.user.profile.name < b.user.profile.name) return -1
            if (a.user.profile.name > b.user.profile.name) return 1
        }

        return 0
    }
}

const GET_GROUP_PARTICIPANTS_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            lessons {
                _id
                date
                order
                lessonUsers {
                    _id
                    learnerUserId
                    attendance
                    reasonOfAbsence
                    attendanceNote
                }
            }
            learnerUsers {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
                hasLearnerFutureEnrollment
                learnerAttendanceRate
                isNowEnrolled
                hasLearnerFinalEnrollment
            }
        }
    }
`
