import * as React from 'react'
import { View } from '~/components/View'
import { getCurrentUser } from '~/services/session'

interface Props {
    children: any
    group?: any
    refetch?: () => void
}

export default class LessonsView extends React.Component<Props> {
    public render() {
        const { group, children, refetch } = this.props

        const user = getCurrentUser()

        if (user.isAdmin === true || user.isEmployee === true || user.isExternalTeacher === true) {
            return <View>{React.cloneElement(children, { group, refetch })}</View>
        }
        return null
    }
}
