import './DividerDot.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export class DividerDot extends React.Component<Props> {
    private bem = new BEM('DividerDot')

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)} />
    }
}
