import { gql } from '@apollo/client'
import { AddressFieldsFragment } from '~/types/Address'

export const GET_CURRENT_USER_QUERY = gql`
    query _ {
        user {
            _id
            email
            roles(filterMutable: true)
            invitedAt
            lastActivityAt
            profile {
                firstName
                initials
                name
                surNamePrefix
                surName
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                address {
                    ...AddressFieldsFragment
                }
                notes
            }
            teacher {
                paymentAddress {
                    ...AddressFieldsFragment
                }
                paymentIban
                hasOwnCompany
                companyName
                companyCocNumber
                companyVatNumber
                isNT2Certified
                isActive
            }
        }
    }
    ${AddressFieldsFragment}
`
