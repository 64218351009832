import React from 'react'

import { ClassValue } from '~/services/BEMService'
import { GroupInviteTemplate } from '~/types/groupInviteTemplate'
import { downloadFile } from '~/utils'
import { Button, FieldCollection, Icon, TableHeader, TableHeaderItem, TableRow, TableWrap } from '..'
import { Row } from '../Core/Layout/Row'
import { FieldCollectionFooter } from '../FieldCollectionFooter'
import { FieldGroup } from '../FieldGroup'
import { List } from '../List'
import { ListItem } from '../ListItem'
import { Table } from '../Table'
import { TableCell } from '../TableCell'

interface Props {
    className?: ClassValue
    groupInviteTemplate?: GroupInviteTemplate
    onPrev?: () => void
    onNext?: () => void
}

interface State {
    downloadedIds: string[]
}

export class GroupInviteAttatchmentsModalContent extends React.Component<Props, State> {
    public state: State = {
        downloadedIds: [],
    }

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { groupInviteTemplate, onPrev, onNext } = this.props
        const { downloadedIds } = this.state

        const attachements = (groupInviteTemplate && groupInviteTemplate.attachments) || []

        return (
            <FieldCollection style={`modal`}>
                <FieldGroup>
                    Download hieronder de benodigde bijlagen. Vergeet deze niet mee te sturen met de brief.
                    <TableWrap>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem>Bijlage</TableHeaderItem>
                                <TableHeaderItem />
                            </TableHeader>
                            <tbody>
                                {attachements.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={2}>Geen bijlagen gevonden</TableCell>
                                    </TableRow>
                                )}
                                {attachements.map(attatchment => {
                                    const wasDownloaded = downloadedIds.includes(attatchment._id)

                                    return (
                                        <TableRow key={`${attatchment._id}-group-invite-attatchment`}>
                                            <TableCell>{attatchment.fileName}</TableCell>
                                            <TableCell>
                                                <Row horizontalEnd={true}>
                                                    <Button
                                                        onClick={() =>
                                                            this.onDownloadClick(attatchment._id, attatchment.fileName)
                                                        }
                                                        small={true}
                                                        leftIcon={
                                                            wasDownloaded ? (
                                                                <Icon name={'status_done'} color={'#009060'} />
                                                            ) : (
                                                                <Icon name={'download'} />
                                                            )
                                                        }
                                                    >
                                                        {wasDownloaded ? 'Gedownload' : 'Download'}
                                                    </Button>
                                                </Row>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </TableWrap>
                </FieldGroup>
                <FieldCollectionFooter>
                    <List horizontal>
                        <ListItem>
                            <Button onClick={onPrev}>Vorige</Button>
                        </ListItem>
                        <ListItem right>
                            <Button styleOverride={'submit'} onClick={onNext}>
                                Brief downloaden
                            </Button>
                        </ListItem>
                    </List>
                </FieldCollectionFooter>
            </FieldCollection>
        )
    }

    private onDownloadClick = (id: string, fileName: string) => {
        const { downloadedIds } = this.state
        const wasDownloaded = downloadedIds.includes(id)

        downloadFile(id, fileName)

        if (!wasDownloaded) {
            this.setState({
                downloadedIds: [...downloadedIds, id],
            })
        }
    }
}
