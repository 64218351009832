import React from 'react'
import { addScrollListener, removeScrollListener } from '~/utils/windowScroll'
import { Wrap } from '~/components/Wrap'
import { BEM } from '~/services/BEMService'

interface Props {
    isActiveByDefault?: boolean
    fixed?: boolean
    className?: string
    getButtons?: (toggleDrawer: () => void, drawerOpen?: boolean) => JSX.Element
    getDrawer?: () => JSX.Element
    isWide?: boolean
    amountOfResults?: number
}

interface State {
    drawerOpen?: boolean
    reachedBottom: boolean
}

export default class ActionBar extends React.Component<Props, State> {
    public static amountOfMountedActionBars = 0

    public state: State = {
        drawerOpen: this.props.isActiveByDefault,
        reachedBottom: false,
    }

    private bem = new BEM('ActionBar', () => ({
        'no-shadow': this.state.reachedBottom,
        fixed: this.props.fixed,
        'is-drawer-open': this.state.drawerOpen,
    }))

    public componentDidMount() {
        const { fixed } = this.props
        ActionBar.amountOfMountedActionBars++

        if (fixed) {
            addScrollListener('bottom', this.onReachedBottom)
            addScrollListener('all', this.onScrolling)
            document.body.classList.add('tt-Body--has-fixed-action-bar')
        }
    }

    public componentWillUnmount() {
        const { fixed } = this.props
        ActionBar.amountOfMountedActionBars--

        if (fixed) {
            removeScrollListener('bottom', this.onReachedBottom)
            removeScrollListener('all', this.onScrolling)

            if (ActionBar.amountOfMountedActionBars === 0) {
                document.body.classList.remove('tt-Body--has-fixed-action-bar')
            }
        }
    }

    public onScrolling = () => {
        const { reachedBottom } = this.state

        if (reachedBottom) {
            this.setState({ reachedBottom: false })
        }
    }

    public onReachedBottom = () => {
        const { reachedBottom } = this.state

        if (!reachedBottom) {
            this.setState({ reachedBottom: true })
        }
    }

    public toggleDrawer = () => {
        const { drawerOpen } = this.state

        this.setState({ drawerOpen: !drawerOpen })
    }

    public render() {
        const { className, getButtons, getDrawer, fixed: isFixed, isWide, amountOfResults } = this.props
        const { drawerOpen } = this.state

        const Comp = isFixed ? (isWide ? 'div' : Wrap) : 'div'

        return (
            <div className={this.bem.getClassName(className)}>
                <Comp>
                    <div className={this.bem.getElement('buttons')}>
                        {getButtons && getButtons(this.toggleDrawer, drawerOpen)}
                    </div>
                    {getDrawer && drawerOpen && (
                        <div className={this.bem.getElement('drawer')}>
                            {getDrawer()}
                            {typeof amountOfResults === 'number' && (
                                <div className={this.bem.getElement('amount-of-results')}>
                                    {amountOfResults} resultaten
                                </div>
                            )}
                        </div>
                    )}
                </Comp>
            </div>
        )
    }
}
