import * as React from 'react'
import { get } from 'lodash'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { getCurrentUser } from '~/services/session'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import { GroupUser } from '~/types/User'
import { GroupParticipantInvoiceStatuses } from '~/components/Group/GroupParticipantInvoiceStatuses'
import { GroupParticipantName } from '~/components/Group/Participants/Misc/GroupParticipantName'
import { GroupParticipantContactInfo } from '~/components/Group/Participants/Misc/GroupParticipantContactInfo'
import { GroupParticipantContractStatuses } from '~/components/Group/GroupParticipantContractStatuses'
import { GroupLearnerParticipantsTableRowExpansion } from '~/components/Group/Participants/Tables/GroupLearnerParticipantsTableRowExpansion/GroupLearnerParticipantsTableRowExpansion'

interface Props {
    groupId: string
    groupName: string
    learnerUsers: GroupUser[]
    loadingLearnerUsers: boolean
    refetchLearnerUsers: () => void
}

export class GroupLearnerParticipantsTable extends React.Component<Props> {
    public render() {
        const { learnerUsers, loadingLearnerUsers } = this.props
        const currentUser = getCurrentUser()

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                    {currentUser.isEmployee && <TableHeaderItem>Woonplaats</TableHeaderItem>}
                    <TableHeaderItem>Contact</TableHeaderItem>
                    <TableHeaderItem>Factuurstatus</TableHeaderItem>
                    <TableHeaderItem>Contractstatus</TableHeaderItem>
                    <TableHeaderItem />
                    {/* expansion button */}
                </TableHeader>
                {loadingLearnerUsers && this.renderLoadingTableBody()}
                {!loadingLearnerUsers && learnerUsers.length === 0 && this.renderEmptyTableBody()}
                {!loadingLearnerUsers && learnerUsers.length > 0 && this.renderUsersTableBody()}
            </Table>
        )
    }

    private getAmountOfColumns() {
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee) {
            return 6 // employees see the extra 'Woonplaats' column
        }

        return 5
    }

    private renderLoadingTableBody() {
        return (
            <TableRow>
                <TableCell colSpan={this.getAmountOfColumns()} isLoading={true} />
            </TableRow>
        )
    }

    private renderEmptyTableBody() {
        return (
            <TableRow>
                <TableCell colSpan={this.getAmountOfColumns()}>
                    <Subtle>Er zijn nog geen kandidaten toegevoegd.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    private renderUsersTableBody() {
        const { groupId, groupName, learnerUsers, refetchLearnerUsers } = this.props

        const currentUser = getCurrentUser()

        return learnerUsers.map(groupUser => {
            const { user } = groupUser

            const contracts = (user && user.learner && user.learner.contracts) || []
            const city = get(user, 'profile.address.nl.extraInfo.city')
            const email = user && user.email
            const phoneNumber = user && user.profile && user.profile.phoneNumbers && user.profile.phoneNumbers[0]

            return (
                <TableRow
                    key={groupUser._id}
                    getExpansion={() => (
                        <TableCell colSpan={this.getAmountOfColumns()}>
                            <GroupLearnerParticipantsTableRowExpansion
                                userId={user!._id}
                                userName={user!.profile.name!}
                                enrollments={groupUser.enrollments!}
                                hasLearnerFinalEnrollment={groupUser.hasLearnerFinalEnrollment!}
                                hasAttendancesAfterLastEnrollment={groupUser.hasAttendancesAfterLastEnrollment!}
                                hasInvoicesAfterLastEnrollment={groupUser.hasInvoicesAfterLastEnrollment!}
                                groupId={groupId}
                                groupName={groupName}
                                refetchLearnerParticipants={() => {
                                    refetchLearnerUsers()
                                }}
                            />
                        </TableCell>
                    )}
                >
                    <TableCell>
                        <GroupParticipantName
                            groupUser={groupUser}
                            linkToGroupId={(currentUser.isExternalTeacher && groupId) || undefined}
                        />
                    </TableCell>
                    {currentUser.isEmployee && <TableCell>{city}</TableCell>}
                    <TableCell>
                        <GroupParticipantContactInfo email={email} phoneNumber={phoneNumber} />
                    </TableCell>
                    <TableCell className={`tt-TableCell--inline-statuses`}>
                        <GroupParticipantInvoiceStatuses groupUser={groupUser} groupId={groupId} />
                    </TableCell>
                    <TableCell className={`tt-TableCell--inline-statuses`}>
                        {!groupUser.hasSignedContractForEveryLessonInGroup && (
                            <GroupParticipantContractStatuses contracts={contracts} />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }
}
