import * as React from 'react'
import { PlannedAbilityExam, ExamType, ExamAbility, ExamRatingType, Ability } from '~/types/Exam'
import DrawerModal from '~/components/DrawerModal'
import { GroupExamPassedNotPassedForm } from '~/forms/GroupExams/GroupExamPassedNotPassedForm'
import { GroupExamScoreForm } from '~/forms/GroupExams/GroupExamScoreForm'
import { GroupExamLevelForm } from '~/forms/GroupExams/GroupExamLevelForm'

interface Props {
    onSubmitSuccess: () => void
    onCancel: () => void
    plannedAbilityExam: PlannedAbilityExam
    examAbilityDetails: Ability
}

export class UpdateExamResultsModal extends React.Component<Props> {
    public render() {
        const { onSubmitSuccess, onCancel, plannedAbilityExam, examAbilityDetails } = this.props
        const examResultType = this.getResultType()

        if (!examResultType) {
            return null
        }

        return (
            <DrawerModal onClose={onCancel}>
                {examResultType === ExamRatingType.PassedNotPassed && (
                    <GroupExamPassedNotPassedForm
                        onCancel={onCancel}
                        onSubmitSuccess={onSubmitSuccess}
                        plannedAbilityExam={plannedAbilityExam}
                        examAbilityDetails={examAbilityDetails}
                    />
                )}

                {examResultType === ExamRatingType.Score && (
                    <GroupExamScoreForm
                        onCancel={onCancel}
                        onSubmitSuccess={onSubmitSuccess}
                        plannedAbilityExam={plannedAbilityExam}
                        examAbilityDetails={examAbilityDetails}
                    />
                )}

                {examResultType === ExamRatingType.Level && (
                    <GroupExamLevelForm
                        onCancel={onCancel}
                        onSubmitSuccess={onSubmitSuccess}
                        plannedAbilityExam={plannedAbilityExam}
                        examAbilityDetails={examAbilityDetails}
                    />
                )}
            </DrawerModal>
        )
    }

    private getResultType(): ExamRatingType | void {
        const { plannedAbilityExam } = this.props
        const { examAbility, exam } = plannedAbilityExam

        const examType = exam && exam.type
        const typeOfRating =
            plannedAbilityExam &&
            plannedAbilityExam.exam &&
            plannedAbilityExam.exam[examAbility] &&
            plannedAbilityExam.exam[examAbility].typeOfRating

        switch (examType) {
            case ExamType.Alpha:
                switch (examAbility) {
                    case ExamAbility.Read:
                        return ExamRatingType.Score
                    case ExamAbility.Write:
                        return ExamRatingType.PassedNotPassed
                    default:
                        return
                }
            case ExamType.Language:
                switch (typeOfRating) {
                    case 'score':
                        return ExamRatingType.Score
                    case 'level':
                        return ExamRatingType.Level
                    default:
                        return
                }
            default:
                return
        }
    }
}
