import * as React from 'react'
import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
}

export class MainNav extends React.Component<Props> {
    private bem = new BEM('MainNav')

    public render() {
        const { children, className } = this.props

        return (
            <nav className={this.bem.getClassName(className)}>
                <List horizontal={true}>{children}</List>
            </nav>
        )
    }
}
