import * as React from 'react'
import { gql } from '@apollo/client'
import CenterModal from '~/components/CenterModal'
import { ModalManager } from '~/components/ModalManager'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { ValidationError } from '~/components/ValidationError'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Mutator from '~/utils/Mutator'
import { toast } from '~/utils'
import ReadableDate from '~/components/ReadableDate'
import Bold from '~/components/Bold'
import Paragraph from '~/components/Paragraph'

interface Props {
    render: (openModal: () => void) => React.ReactNode
    userId: string
    groupId: string
    userName: string
    lastEnrollmentDate: Date
    hasAttendancesAfterLastEnrollment: boolean
    hasInvoicesAfterLastEnrollment: boolean
    onUndoEnrollment?: () => void
}

export class GroupLearnerParticipantUndoEnrollmentModalManager extends React.Component<Props> {
    private undoEnrollmentMutator = new Mutator({
        mutation: UNDO_ENROLLMENT_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const {
            render,
            userName,
            lastEnrollmentDate,
            hasAttendancesAfterLastEnrollment,
            hasInvoicesAfterLastEnrollment,
        } = this.props
        const { loading: isLoadingUndoEnrollment } = this.undoEnrollmentMutator

        const hasErrorOrWarning = hasAttendancesAfterLastEnrollment || hasInvoicesAfterLastEnrollment

        const hasError = hasAttendancesAfterLastEnrollment

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <CenterModal
                        small
                        title={'Laatste inschrijving ongedaan maken'}
                        onClose={closeModal}
                        enableEasyClose
                    >
                        <FieldCollection style={`modal`}>
                            <FieldGroup>
                                <Field>
                                    <Paragraph>
                                        Je staat op het punt om de laatste inschrijving (
                                        <ReadableDate date={lastEnrollmentDate} />) van <Bold>{userName}</Bold> ongedaan
                                        te maken.
                                    </Paragraph>
                                </Field>
                                {hasErrorOrWarning && (
                                    <Field>
                                        {hasAttendancesAfterLastEnrollment && (
                                            <ValidationError
                                                text={'Er is al presentie ingevuld na de laatste inschrijving.'}
                                            />
                                        )}
                                        {hasInvoicesAfterLastEnrollment && (
                                            <ValidationError
                                                isWarning={true}
                                                text={
                                                    'Waarschuwing: er zijn al een aantal lessen gefactureerd na de laatste inschrijving.'
                                                }
                                            />
                                        )}
                                    </Field>
                                )}
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button onClick={closeModal}>Annuleren</Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button
                                            type="submit"
                                            onClick={event => this.undoEnrollment(event, closeModal)}
                                            isLoading={isLoadingUndoEnrollment}
                                            isDisabled={hasError}
                                        >
                                            Bevestigen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </CenterModal>
                )}
            />
        )
    }

    private undoEnrollment = async (event: React.FormEvent<HTMLButtonElement>, closeModal: () => void) => {
        const { userId, userName, groupId, onUndoEnrollment } = this.props

        const result = await this.undoEnrollmentMutator.mutate({
            userId: userId,
            groupId: groupId,
        })

        if (result && result.groups_undoEnrollment) {
            closeModal()
            toast.success(`Laatste inschrijving van "${userName}" is succesvol ongedaan gemaakt`)

            if (onUndoEnrollment) {
                onUndoEnrollment()
            }
        }
    }
}

const UNDO_ENROLLMENT_MUTATION = gql`
    mutation groups_undoEnrollment($groupId: MongoID, $userId: MongoID) {
        groups_undoEnrollment(groupId: $groupId, userId: $userId) {
            _id
        }
    }
`
