import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Link, ReadableDate, Subtle, TableHeader, TableHeaderItem, TableRow, TableWrap } from '~/components'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Fetcher, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'dateTo'
const DEFAULT_SORT_DIR = 'DESC'

export default class ExternalTeacherGroupsTable extends Component {
    static propTypes = {
        currentUser: PropTypes.object,
    }

    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.groupsFetcher = new Fetcher({
            query: GET_GROUPS_QUERY,

            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    filterByTeacherUserIds: [props.currentUser._id],
                    filterByConcepts: false,
                },
                nextLessonForTeacherUserId: props.currentUser._id,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.groupsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.groups.length === 0,
                })

                return {
                    groups: removeDuplicateDocuments([...(prevData.groups || []), ...moreData.groups]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.groupsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    render() {
        const { paginator } = this
        const { loading } = this.groupsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                {this.renderTable()}
            </InfiniteScroll>
        )
    }

    renderTable() {
        const { data, loading } = this.groupsFetcher
        const { groups = [] } = data
        const { sorter } = this

        return (
            <TableWrap>
                <TableView>
                    <Table>
                        <TableHeader>
                            <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                                Naam
                            </TableHeaderItem>
                            <TableHeaderItem>Eerstvolgende les</TableHeaderItem>
                            <TableHeaderItem>Locatie</TableHeaderItem>
                            <TableHeaderItem>Lesmodule</TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`dateFrom`}>
                                Startdatum
                            </TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`dateTo`}>
                                Einddatum
                            </TableHeaderItem>
                        </TableHeader>
                        {loading ? (
                            <TableRow key={`loading`}>
                                <TableCell colSpan={6} isLoading />
                            </TableRow>
                        ) : groups.length > 0 ? (
                            this.renderGroupRows(groups)
                        ) : (
                            <TableRow key={`emptyresult`}>
                                <TableCell colSpan={6}>
                                    <Subtle>Er zijn geen groepen gevonden.</Subtle>
                                </TableCell>
                            </TableRow>
                        )}
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    renderGroupRows(groups) {
        return groups.map(group => {
            const nextLesson = get(group, 'lessons[0]')

            return (
                <TableRow key={group._id}>
                    <TableCell>
                        <Link route={`/groups/${group._id}/lessons`}>
                            {group.name && group.name.trim() ? group.name : 'Geen naam'}
                        </Link>
                    </TableCell>
                    <TableCell>{(nextLesson && <ReadableDate date={new Date(nextLesson.date)} />) || '-'}</TableCell>
                    <TableCell>{(group.location && group.location.name) || ''}</TableCell>
                    <TableCell>{group.module && group.module.name}</TableCell>
                    <TableCell>{group.dateFrom && <ReadableDate date={new Date(group.dateFrom)} />}</TableCell>
                    <TableCell>{group.dateTo && <ReadableDate date={new Date(group.dateTo)} />}</TableCell>
                </TableRow>
            )
        })
    }
}

const GET_GROUPS_QUERY = gql`
    query _(
        $skip: Int
        $limit: Int
        $sortBy: String
        $sortDir: String
        $filters: GroupsFilterInputType
        $nextLessonForTeacherUserId: MongoID
    ) {
        groups(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            name
            dateFrom
            dateTo
            module {
                _id
                name
            }
            location {
                _id
                name
            }
            lessons(nextLessonForTeacherUserId: $nextLessonForTeacherUserId) {
                _id
                date
            }
        }
    }
`
