import './BrickTableCell.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { TableCell } from '~/components/TableCell'

interface Props {
    className?: ClassValue
}

export class BrickTableCell extends React.Component<Props> {
    private bem = new BEM('BrickTableCell')

    public render() {
        const { className, children } = this.props

        return (
            <TableCell className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('content')}>{children}</div>
            </TableCell>
        )
    }
}
