export default function translateLanguage(languageCode: string): string | undefined {
    const language = LANGUAGES.find(language => language.code === languageCode)

    if (language) {
        return language.dutchName
    }

    return undefined
}

/* eslint-disable */
export const LANGUAGES = [
    {
        code: 'aar',
        dutchName: 'Afar',
    },
    {
        code: 'abk',
        dutchName: 'Abchazisch',
    },
    {
        code: 'ave',
        dutchName: 'Avestisch',
    },
    {
        code: 'afr',
        dutchName: 'Afrikaans',
    },
    {
        code: 'aka',
        dutchName: 'Akan',
    },
    {
        code: 'amh',
        dutchName: 'Amhaars',
    },
    {
        code: 'arg',
        dutchName: 'Aragonees',
    },
    {
        code: 'ara',
        dutchName: 'Arabisch',
    },
    {
        code: 'asm',
        dutchName: 'Assamees',
    },
    {
        code: 'ava',
        dutchName: 'Avaars',
    },
    {
        code: 'aym',
        dutchName: 'Aymara',
    },
    {
        code: 'aze',
        dutchName: 'Azerbeidzjaans',
    },
    {
        code: 'ace',
        dutchName: 'Atjehs',
    },
    {
        code: 'ach',
        dutchName: 'Akoli',
    },
    {
        code: 'ada',
        dutchName: 'Adangme',
    },
    {
        code: 'ady',
        dutchName: 'Adygees',
    },
    {
        code: 'afa',
        dutchName: 'Afro-Aziatische talen',
    },
    {
        code: 'afh',
        dutchName: 'Afrihili',
    },
    {
        code: 'ain',
        dutchName: 'Ainu',
    },
    {
        code: 'akk',
        dutchName: 'Akkadisch',
    },
    {
        code: 'ale',
        dutchName: 'Aleoets',
    },
    {
        code: 'alg',
        dutchName: 'Algonkische talen',
    },
    {
        code: 'alt',
        dutchName: 'Zuid-Altaïsch',
    },
    {
        code: 'ang',
        dutchName: 'Oudengels (ca. 450-1100)',
    },
    {
        code: 'anp',
        dutchName: 'Angika',
    },
    {
        code: 'apa',
        dutchName: 'Na-Denétalen',
    },
    {
        code: 'arc',
        dutchName: 'Aramees',
    },
    {
        code: 'arn',
        dutchName: 'Mapudungun (Araukaans)',
    },
    {
        code: 'arp',
        dutchName: 'Arapaho',
    },
    {
        code: 'art',
        dutchName: 'Kunsttalen',
    },
    {
        code: 'arw',
        dutchName: 'Arawak',
    },
    {
        code: 'ast',
        dutchName: 'Asturisch',
    },
    {
        code: 'ath',
        dutchName: 'Athabaskische talen',
    },
    {
        code: 'aus',
        dutchName: 'Australische talen',
    },
    {
        code: 'awa',
        dutchName: 'Awadhi',
    },
    {
        code: 'bak',
        dutchName: 'Basjkiers',
    },
    {
        code: 'bel',
        dutchName: 'Wit-Russisch (Belarussisch)',
    },
    {
        code: 'bul',
        dutchName: 'Bulgaars',
    },
    {
        code: 'bih',
        dutchName: 'Bihari',
    },
    {
        code: 'bis',
        dutchName: 'Bislama',
    },
    {
        code: 'bam',
        dutchName: 'Bambara',
    },
    {
        code: 'ben',
        dutchName: 'Bengaals',
    },
    {
        code: 'bod',
        dutchName: 'Tibetaans',
    },
    {
        code: 'bre',
        dutchName: 'Bretons',
    },
    {
        code: 'bos',
        dutchName: 'Bosnisch',
    },
    {
        code: 'bad',
        dutchName: 'Bandatalen (Centraal-Afrika)',
    },
    {
        code: 'bai',
        dutchName: 'Bamileke',
    },
    {
        code: 'ban',
        dutchName: 'Balinees',
    },
    {
        code: 'bas',
        dutchName: 'Basa',
    },
    {
        code: 'bat',
        dutchName: 'Baltische talen',
    },
    {
        code: 'bej',
        dutchName: 'Beja; Bedawi',
    },
    {
        code: 'bem',
        dutchName: 'Bemba',
    },
    {
        code: 'ber',
        dutchName: 'Berber',
    },
    {
        code: 'bho',
        dutchName: 'Bhojpuri',
    },
    {
        code: 'bik',
        dutchName: 'Bicol',
    },
    {
        code: 'bin',
        dutchName: 'Edo (Bini)',
    },
    {
        code: 'bla',
        dutchName: 'Siksika (Blackfoot)',
    },
    {
        code: 'bnt',
        dutchName: 'Bantoetalen',
    },
    {
        code: 'bra',
        dutchName: 'Braj',
    },
    {
        code: 'btk',
        dutchName: 'Bataktalen',
    },
    {
        code: 'bua',
        dutchName: 'Boerjatisch',
    },
    {
        code: 'bug',
        dutchName: 'Buginees',
    },
    {
        code: 'byn',
        dutchName: 'Blin; Bilin',
    },
    {
        code: 'cat',
        dutchName: 'Catalaans',
    },
    {
        code: 'che',
        dutchName: 'Tsjetsjeens',
    },
    {
        code: 'cha',
        dutchName: 'Chamorro',
    },
    {
        code: 'cos',
        dutchName: 'Corsicaans',
    },
    {
        code: 'cre',
        dutchName: 'Cree',
    },
    {
        code: 'ces',
        dutchName: 'Tsjechisch',
    },
    {
        code: 'chu',
        dutchName: 'Kerkslavisch',
    },
    {
        code: 'chv',
        dutchName: 'Tsjoevasjisch',
    },
    {
        code: 'cym',
        dutchName: 'Welsh',
    },
    {
        code: 'cad',
        dutchName: 'Caddo',
    },
    {
        code: 'cai',
        dutchName: 'Midden-Amerikaanse Indiaanse talen',
    },
    {
        code: 'car',
        dutchName: 'Caribische talen',
    },
    {
        code: 'cau',
        dutchName: 'Kaukasische talen',
    },
    {
        code: 'ceb',
        dutchName: 'Cebuano',
    },
    {
        code: 'cel',
        dutchName: 'Keltische talen',
    },
    {
        code: 'chb',
        dutchName: 'Chibcha',
    },
    {
        code: 'chg',
        dutchName: 'Chagatai (Tsjagatai)',
    },
    {
        code: 'chk',
        dutchName: 'Chuukees',
    },
    {
        code: 'chm',
        dutchName: 'Mari',
    },
    {
        code: 'chn',
        dutchName: 'Chinook',
    },
    {
        code: 'cho',
        dutchName: 'Choctaw',
    },
    {
        code: 'chp',
        dutchName: 'Chipewyan',
    },
    {
        code: 'chr',
        dutchName: 'Cherokee',
    },
    {
        code: 'chy',
        dutchName: 'Cheyenne',
    },
    {
        code: 'cmc',
        dutchName: 'Chamische talen',
    },
    {
        code: 'cop',
        dutchName: 'Koptisch',
    },
    {
        code: 'cpe',
        dutchName: 'Engelse creoolse talen',
    },
    {
        code: 'cpf',
        dutchName: 'Franse creoolse talen (overige)',
    },
    {
        code: 'cpp',
        dutchName: 'Portugese creoolse talen (overige)',
    },
    {
        code: 'crh',
        dutchName: 'Krim-Tataars',
    },
    {
        code: 'crp',
        dutchName: 'Creools en Pidgin (overige)',
    },
    {
        code: 'csb',
        dutchName: 'Kasjoebisch',
    },
    {
        code: 'cus',
        dutchName: 'Koesjitische talen (overige)',
    },
    {
        code: 'dan',
        dutchName: 'Deens',
    },
    {
        code: 'deu',
        dutchName: 'Duits',
    },
    {
        code: 'div',
        dutchName: 'Divehi',
    },
    {
        code: 'dzo',
        dutchName: 'Dzongkha',
    },
    {
        code: 'dak',
        dutchName: 'Dakota',
    },
    {
        code: 'dar',
        dutchName: 'Darginisch (Dargwa)',
    },
    {
        code: 'day',
        dutchName: 'Dajaktalen',
    },
    {
        code: 'del',
        dutchName: 'Unami (Delaware)',
    },
    {
        code: 'den',
        dutchName: 'Slavey',
    },
    {
        code: 'dgr',
        dutchName: 'Dogrib',
    },
    {
        code: 'din',
        dutchName: 'Dinka (Thuongjang)',
    },
    {
        code: 'doi',
        dutchName: 'Dogri',
    },
    {
        code: 'dra',
        dutchName: 'Dravidische talen (overige)',
    },
    {
        code: 'dsb',
        dutchName: 'Nedersorbisch',
    },
    {
        code: 'dua',
        dutchName: 'Duala',
    },
    {
        code: 'dum',
        dutchName: 'Middelnederlands (ca.1050-1350)',
    },
    {
        code: 'dyu',
        dutchName: 'Dioula',
    },
    {
        code: 'ewe',
        dutchName: 'Ewe',
    },
    {
        code: 'ell',
        dutchName: 'Grieks',
    },
    {
        code: 'eng',
        dutchName: 'Engels',
    },
    {
        code: 'epo',
        dutchName: 'Esperanto',
    },
    {
        code: 'spa',
        dutchName: 'Spaans',
    },
    {
        code: 'est',
        dutchName: 'Estisch',
    },
    {
        code: 'eus',
        dutchName: 'Baskisch',
    },
    {
        code: 'efi',
        dutchName: 'Efik',
    },
    {
        code: 'egy',
        dutchName: 'Oud-Egyptisch',
    },
    {
        code: 'eka',
        dutchName: 'Ekajuk',
    },
    {
        code: 'elx',
        dutchName: 'Elamitisch',
    },
    {
        code: 'enm',
        dutchName: 'Middelengels (1100-1500)',
    },
    {
        code: 'ewo',
        dutchName: 'Ewondo',
    },
    {
        code: 'fas',
        dutchName: 'Perzisch (Farsi)',
    },
    {
        code: 'ful',
        dutchName: 'Fula',
    },
    {
        code: 'fin',
        dutchName: 'Fins',
    },
    {
        code: 'fij',
        dutchName: 'Fijisch',
    },
    {
        code: 'fao',
        dutchName: 'Faeröers',
    },
    {
        code: 'fra',
        dutchName: 'Frans',
    },
    {
        code: 'fry',
        dutchName: 'Westerlauwers Fries',
    },
    {
        code: 'fan',
        dutchName: 'Fang',
    },
    {
        code: 'fat',
        dutchName: 'Fante',
    },
    {
        code: 'fil',
        dutchName: 'Filipijns',
    },
    {
        code: 'fiu',
        dutchName: 'Fins-Oegrische talen (overige)',
    },
    {
        code: 'fon',
        dutchName: 'Fon',
    },
    {
        code: 'frm',
        dutchName: 'Middelfrans (ca.1400-1600)',
    },
    {
        code: 'fro',
        dutchName: 'Oudfrans (842-ca.1400)',
    },
    {
        code: 'frr',
        dutchName: 'Noord-Fries',
    },
    {
        code: 'frs',
        dutchName: 'Oosterlauwers Fries',
    },
    {
        code: 'fur',
        dutchName: 'Friulisch',
    },
    {
        code: 'gle',
        dutchName: 'Iers',
    },
    {
        code: 'gla',
        dutchName: 'Schots-Gaelisch',
    },
    {
        code: 'glg',
        dutchName: 'Galicisch',
    },
    {
        code: 'grn',
        dutchName: 'Guaraní',
    },
    {
        code: 'guj',
        dutchName: 'Gujarati',
    },
    {
        code: 'glv',
        dutchName: 'Manx-Gaelisch',
    },
    {
        code: 'gaa',
        dutchName: 'Ga',
    },
    {
        code: 'gay',
        dutchName: 'Gajo',
    },
    {
        code: 'gba',
        dutchName: 'Gbaya',
    },
    {
        code: 'gem',
        dutchName: 'Germaanse talen',
    },
    {
        code: 'gez',
        dutchName: "Ge'ez",
    },
    {
        code: 'gil',
        dutchName: 'Kiribatisch (Gilbertees)',
    },
    {
        code: 'gmh',
        dutchName: 'Middelhoogduits (ca.1050-1500)',
    },
    {
        code: 'goh',
        dutchName: 'Oudhoogduits (ca.750-1050)',
    },
    {
        code: 'gon',
        dutchName: 'Gondi',
    },
    {
        code: 'gor',
        dutchName: 'Gorontalo',
    },
    {
        code: 'got',
        dutchName: 'Gotisch',
    },
    {
        code: 'grb',
        dutchName: 'Grebo',
    },
    {
        code: 'grc',
        dutchName: 'Oudgrieks (tot 1453)',
    },
    {
        code: 'gsw',
        dutchName: 'Zwitserduits',
    },
    {
        code: 'gwi',
        dutchName: 'Gwich’in',
    },
    {
        code: 'hau',
        dutchName: 'Hausa',
    },
    {
        code: 'heb',
        dutchName: 'Hebreeuws',
    },
    {
        code: 'hin',
        dutchName: 'Hindi',
    },
    {
        code: 'hmo',
        dutchName: 'Hiri Motu',
    },
    {
        code: 'hrv',
        dutchName: 'Kroatisch',
    },
    {
        code: 'hat',
        dutchName: 'Haïtiaans Creools',
    },
    {
        code: 'hun',
        dutchName: 'Hongaars',
    },
    {
        code: 'arm/hye',
        dutchName: 'Armeens',
    },
    {
        code: 'her',
        dutchName: 'Herero',
    },
    {
        code: 'hai',
        dutchName: 'Haida',
    },
    {
        code: 'haw',
        dutchName: 'Hawaïaans',
    },
    {
        code: 'hil',
        dutchName: 'Hiligaynon',
    },
    {
        code: 'him',
        dutchName: 'Himachali',
    },
    {
        code: 'hit',
        dutchName: 'Hettitisch',
    },
    {
        code: 'hmn',
        dutchName: 'Hmong',
    },
    {
        code: 'hsb',
        dutchName: 'Oppersorbisch',
    },
    {
        code: 'hup',
        dutchName: 'Hupa',
    },
    {
        code: 'ina',
        dutchName: 'Interlingua',
    },
    {
        code: 'ind',
        dutchName: 'Indonesisch',
    },
    {
        code: 'ile',
        dutchName: 'Interlingue',
    },
    {
        code: 'ibo',
        dutchName: 'Igbo',
    },
    {
        code: 'iii',
        dutchName: 'Yi',
    },
    {
        code: 'ipk',
        dutchName: 'Inupiak',
    },
    {
        code: 'ido',
        dutchName: 'Ido',
    },
    {
        code: 'isl',
        dutchName: 'IJslands',
    },
    {
        code: 'ita',
        dutchName: 'Italiaans',
    },
    {
        code: 'iku',
        dutchName: 'Inuktitut',
    },
    {
        code: 'iba',
        dutchName: 'Iban',
    },
    {
        code: 'ijo',
        dutchName: 'Ijawtalen',
    },
    {
        code: 'ilo',
        dutchName: 'Ilokano',
    },
    {
        code: 'inc',
        dutchName: 'Indo-Arische talen',
    },
    {
        code: 'ine',
        dutchName: 'Indo-Europese talen',
    },
    {
        code: 'inh',
        dutchName: 'Ingoesjetisch',
    },
    {
        code: 'ira',
        dutchName: 'Iraanse talen',
    },
    {
        code: 'iro',
        dutchName: 'Irokese talen',
    },
    {
        code: 'jpn',
        dutchName: 'Japans',
    },
    {
        code: 'jav',
        dutchName: 'Javaans',
    },
    {
        code: 'jbo',
        dutchName: 'Lojban',
    },
    {
        code: 'jpr',
        dutchName: 'Judeo-Perzisch',
    },
    {
        code: 'jrb',
        dutchName: 'Judeo-Arabisch',
    },
    {
        code: 'kat',
        dutchName: 'Georgisch',
    },
    {
        code: 'kon',
        dutchName: 'Kongo',
    },
    {
        code: 'kik',
        dutchName: 'Gikuyu',
    },
    {
        code: 'kua',
        dutchName: 'Kwanyama',
    },
    {
        code: 'kaz',
        dutchName: 'Kazachs',
    },
    {
        code: 'kal',
        dutchName: 'Groenlands',
    },
    {
        code: 'khm',
        dutchName: 'Khmer, Cambodjaans',
    },
    {
        code: 'kan',
        dutchName: 'Kannada (Kanarees, Kanara)',
    },
    {
        code: 'kor',
        dutchName: 'Koreaans',
    },
    {
        code: 'kau',
        dutchName: 'Kanuri',
    },
    {
        code: 'kas',
        dutchName: 'Kasjmiri',
    },
    {
        code: 'kur',
        dutchName: 'Koerdisch',
    },
    {
        code: 'kom',
        dutchName: 'Zurjeens (Komi)',
    },
    {
        code: 'cor',
        dutchName: 'Cornisch',
    },
    {
        code: 'kir',
        dutchName: 'Kirgizisch',
    },
    {
        code: 'kaa',
        dutchName: 'Karakalpaks',
    },
    {
        code: 'kab',
        dutchName: 'Kabylisch',
    },
    {
        code: 'kac',
        dutchName: 'Kachin (Jingpho)',
    },
    {
        code: 'kam',
        dutchName: 'Kamba',
    },
    {
        code: 'kar',
        dutchName: 'Karen',
    },
    {
        code: 'kaw',
        dutchName: 'Kawi',
    },
    {
        code: 'kbd',
        dutchName: 'Kabardisch',
    },
    {
        code: 'kha',
        dutchName: 'Khasi',
    },
    {
        code: 'khi',
        dutchName: 'Khoisantalen',
    },
    {
        code: 'kho',
        dutchName: 'Hotanees',
    },
    {
        code: 'kmb',
        dutchName: 'Kimbundu',
    },
    {
        code: 'kok',
        dutchName: 'Konkani',
    },
    {
        code: 'kos',
        dutchName: 'Kosraeaans',
    },
    {
        code: 'kpe',
        dutchName: 'Kpelle',
    },
    {
        code: 'krc',
        dutchName: 'Karatsjaj-Balkarisch',
    },
    {
        code: 'krl',
        dutchName: 'Karelisch',
    },
    {
        code: 'kro',
        dutchName: 'Krutalen',
    },
    {
        code: 'kru',
        dutchName: 'Kurukh',
    },
    {
        code: 'kum',
        dutchName: 'Kumyk',
    },
    {
        code: 'kut',
        dutchName: 'Kutenai',
    },
    {
        code: 'lat',
        dutchName: 'Latijn',
    },
    {
        code: 'ltz',
        dutchName: 'Luxemburgs',
    },
    {
        code: 'lug',
        dutchName: 'Luganda',
    },
    {
        code: 'lim',
        dutchName: 'Limburgs',
    },
    {
        code: 'lin',
        dutchName: 'Lingala',
    },
    {
        code: 'lao',
        dutchName: 'Laotiaans',
    },
    {
        code: 'lit',
        dutchName: 'Litouws',
    },
    {
        code: 'lub',
        dutchName: 'Luba-Katanga',
    },
    {
        code: 'lav',
        dutchName: 'Lets',
    },
    {
        code: 'lad',
        dutchName: 'Ladino',
    },
    {
        code: 'lah',
        dutchName: 'Lahnda',
    },
    {
        code: 'lam',
        dutchName: 'Lamba',
    },
    {
        code: 'lez',
        dutchName: 'Lezgisch',
    },
    {
        code: 'lol',
        dutchName: 'Mongo',
    },
    {
        code: 'loz',
        dutchName: 'Lozi',
    },
    {
        code: 'lua',
        dutchName: 'Luba-Lulua',
    },
    {
        code: 'lui',
        dutchName: 'Luiseno',
    },
    {
        code: 'lun',
        dutchName: 'Lunda',
    },
    {
        code: 'luo',
        dutchName: 'Luo (Kenia en Tanzania)',
    },
    {
        code: 'lus',
        dutchName: 'Lushai',
    },
    {
        code: 'mlg',
        dutchName: 'Plateaumalagasi',
    },
    {
        code: 'mah',
        dutchName: 'Marshallees',
    },
    {
        code: 'mri',
        dutchName: 'Maori',
    },
    {
        code: 'mkd',
        dutchName: 'Macedonisch',
    },
    {
        code: 'mal',
        dutchName: 'Malayalam',
    },
    {
        code: 'mon',
        dutchName: 'Mongools',
    },
    {
        code: 'mol',
        dutchName: 'Moldavisch',
    },
    {
        code: 'mar',
        dutchName: 'Marathi',
    },
    {
        code: 'msa',
        dutchName: 'Maleis',
    },
    {
        code: 'mlt',
        dutchName: 'Maltees',
    },
    {
        code: 'mya',
        dutchName: 'Birmaans, Birmees',
    },
    {
        code: 'mad',
        dutchName: 'Madoerees',
    },
    {
        code: 'mag',
        dutchName: 'Magahi',
    },
    {
        code: 'mai',
        dutchName: 'Maithili',
    },
    {
        code: 'mak',
        dutchName: 'Makassaars',
    },
    {
        code: 'man',
        dutchName: 'Mandinka',
    },
    {
        code: 'map',
        dutchName: 'Austronesische talen',
    },
    {
        code: 'mas',
        dutchName: 'Masai',
    },
    {
        code: 'mdf',
        dutchName: 'Moksha',
    },
    {
        code: 'mdr',
        dutchName: 'Mandar',
    },
    {
        code: 'men',
        dutchName: 'Mende',
    },
    {
        code: 'mga',
        dutchName: 'Middel-iers (900-1200)',
    },
    {
        code: 'mic',
        dutchName: 'Micmac',
    },
    {
        code: 'min',
        dutchName: 'Minangkabau',
    },
    {
        code: 'mis',
        dutchName: 'niet gecodeerde talen',
    },
    {
        code: 'mkh',
        dutchName: 'Mon-Khmertalen',
    },
    {
        code: 'mnc',
        dutchName: 'Mantsjoe',
    },
    {
        code: 'mni',
        dutchName: 'Meitei (Manipuri)',
    },
    {
        code: 'mno',
        dutchName: 'Manobotalen',
    },
    {
        code: 'moh',
        dutchName: 'Mohawk',
    },
    {
        code: 'mos',
        dutchName: 'Mossi',
    },
    {
        code: 'mul',
        dutchName: 'meerdere talen',
    },
    {
        code: 'mun',
        dutchName: 'Mundatalen',
    },
    {
        code: 'mus',
        dutchName: 'Muskogitalen',
    },
    {
        code: 'mwl',
        dutchName: 'Mirandees',
    },
    {
        code: 'mwr',
        dutchName: 'Marwari',
    },
    {
        code: 'myn',
        dutchName: 'Mayatalen',
    },
    {
        code: 'myv',
        dutchName: 'Erzja',
    },
    {
        code: 'nau',
        dutchName: 'Nauruaans',
    },
    {
        code: 'nob',
        dutchName: 'Bokmål-Noors',
    },
    {
        code: 'nde',
        dutchName: 'Noord-Ndebele',
    },
    {
        code: 'nep',
        dutchName: 'Nepalees',
    },
    {
        code: 'ndo',
        dutchName: 'Ndonga',
    },
    {
        code: 'nld',
        dutchName: 'Nederlands',
    },
    {
        code: 'nno',
        dutchName: 'Nynorsk (Nieuw-Noors)',
    },
    {
        code: 'nor',
        dutchName: 'Noors',
    },
    {
        code: 'nbl',
        dutchName: 'Zuid-Ndebele',
    },
    {
        code: 'nav',
        dutchName: 'Navajo',
    },
    {
        code: 'nya',
        dutchName: 'Nyanja',
    },
    {
        code: 'nah',
        dutchName: 'Nahuatl',
    },
    {
        code: 'nai',
        dutchName: 'Noord-Amerikaans Indiaanse talen',
    },
    {
        code: 'nap',
        dutchName: 'Napolitaans',
    },
    {
        code: 'nds',
        dutchName: 'Nederduits',
    },
    {
        code: 'new',
        dutchName: 'Nepalbhasa (Newari)',
    },
    {
        code: 'nia',
        dutchName: 'Nias',
    },
    {
        code: 'nic',
        dutchName: 'Niger-Congotalen (overige)',
    },
    {
        code: 'niu',
        dutchName: 'Niueaans',
    },
    {
        code: 'nog',
        dutchName: 'Nogai',
    },
    {
        code: 'non',
        dutchName: 'Oudnoords',
    },
    {
        code: 'nqo',
        dutchName: "N'ko",
    },
    {
        code: 'nso',
        dutchName: 'Pedi (Sepedi, Noord-Sothotisch)',
    },
    {
        code: 'nub',
        dutchName: 'Nubisch',
    },
    {
        code: 'nwc',
        dutchName: 'Klassiek Newari (Klassiek Nepalbhasa)',
    },
    {
        code: 'nym',
        dutchName: 'Nyamwezi',
    },
    {
        code: 'nyn',
        dutchName: 'Nyankole',
    },
    {
        code: 'nyo',
        dutchName: 'Nyoro',
    },
    {
        code: 'nzi',
        dutchName: 'Nzima',
    },
    {
        code: 'oci',
        dutchName: 'Occitaans',
    },
    {
        code: 'oji',
        dutchName: 'Ojibweg',
    },
    {
        code: 'orm',
        dutchName: 'Afaan Oromo',
    },
    {
        code: 'ori',
        dutchName: 'Odia (Oriya)',
    },
    {
        code: 'oss',
        dutchName: 'Ossetisch',
    },
    {
        code: 'osa',
        dutchName: 'Osage',
    },
    {
        code: 'ota',
        dutchName: 'Osmaans (Ottomaans) (1500-1928)',
    },
    {
        code: 'oto',
        dutchName: 'Otomi',
    },
    {
        code: 'pan',
        dutchName: 'Punjabi',
    },
    {
        code: 'pli',
        dutchName: 'Pali',
    },
    {
        code: 'pol',
        dutchName: 'Pools',
    },
    {
        code: 'pus',
        dutchName: 'Pasjtoe',
    },
    {
        code: 'por',
        dutchName: 'Portugees',
    },
    {
        code: 'paa',
        dutchName: 'Papoeatalen (overige)',
    },
    {
        code: 'pag',
        dutchName: 'Pangasinan',
    },
    {
        code: 'pal',
        dutchName: 'Pahlavi',
    },
    {
        code: 'pam',
        dutchName: 'Pampanga',
    },
    {
        code: 'pap',
        dutchName: 'Papiaments',
    },
    {
        code: 'pau',
        dutchName: 'Palaus',
    },
    {
        code: 'peo',
        dutchName: 'Oudperzisch (ca.600-400 BC)',
    },
    {
        code: 'phi',
        dutchName: 'Filipijns (overige)',
    },
    {
        code: 'phn',
        dutchName: 'Fenicisch',
    },
    {
        code: 'pon',
        dutchName: 'Pohnpeiaans',
    },
    {
        code: 'pra',
        dutchName: 'Prakrit',
    },
    {
        code: 'pro',
        dutchName: 'Oudprovençaals (tot 1500); Oud-occitaans (tot 1500)',
    },
    {
        code: 'prs',
        dutchName: 'Dari',
    },
    {
        code: 'que',
        dutchName: 'Quechua',
    },
    {
        code: 'roh',
        dutchName: 'Reto-Romaans',
    },
    {
        code: 'run',
        dutchName: 'Kirundi',
    },
    {
        code: 'ron',
        dutchName: 'Roemeens',
    },
    {
        code: 'rus',
        dutchName: 'Russisch',
    },
    {
        code: 'kin',
        dutchName: 'Kinyarwanda',
    },
    {
        code: 'raj',
        dutchName: 'Rajasthani',
    },
    {
        code: 'rap',
        dutchName: 'Rapa Nui',
    },
    {
        code: 'rar',
        dutchName: 'Rarotongaans',
    },
    {
        code: 'roa',
        dutchName: 'Romaanse talen',
    },
    {
        code: 'rom',
        dutchName: 'Romani',
    },
    {
        code: 'rup',
        dutchName: 'Aroemeens',
    },
    {
        code: 'san',
        dutchName: 'Sanskriet',
    },
    {
        code: 'srd',
        dutchName: 'Sardijns',
    },
    {
        code: 'snd',
        dutchName: 'Sindhi',
    },
    {
        code: 'sme',
        dutchName: 'Noord-Samisch (Noord-Laps)',
    },
    {
        code: 'sag',
        dutchName: 'Sango',
    },
    {
        code: 'hbs',
        dutchName: 'Servo-Kroatisch',
    },
    {
        code: 'sin',
        dutchName: 'Singalees',
    },
    {
        code: 'slo/slk',
        dutchName: 'Slowaaks',
    },
    {
        code: 'slv',
        dutchName: 'Sloveens',
    },
    {
        code: 'smo',
        dutchName: 'Samoaans',
    },
    {
        code: 'sna',
        dutchName: 'Shona',
    },
    {
        code: 'som',
        dutchName: 'Somalisch',
    },
    {
        code: 'sqi',
        dutchName: 'Albanees',
    },
    {
        code: 'srp',
        dutchName: 'Servisch',
    },
    {
        code: 'ssw',
        dutchName: 'Swazi',
    },
    {
        code: 'sot',
        dutchName: 'Zuid-Sotho',
    },
    {
        code: 'sun',
        dutchName: 'Soendanees',
    },
    {
        code: 'swe',
        dutchName: 'Zweeds',
    },
    {
        code: 'swa',
        dutchName: 'Swahili',
    },
    {
        code: 'sad',
        dutchName: 'Sandawe',
    },
    {
        code: 'sah',
        dutchName: 'Jakoets',
    },
    {
        code: 'sai',
        dutchName: 'Zuid-Amerikaanse Indiaanse talen',
    },
    {
        code: 'sal',
        dutchName: 'Salishtalen',
    },
    {
        code: 'sam',
        dutchName: 'Samaritaans',
    },
    {
        code: 'sas',
        dutchName: 'Sasak',
    },
    {
        code: 'sat',
        dutchName: 'Santali',
    },
    {
        code: 'scn',
        dutchName: 'Siciliaans',
    },
    {
        code: 'sco',
        dutchName: 'Schots',
    },
    {
        code: 'sel',
        dutchName: 'Selkoeps',
    },
    {
        code: 'sem',
        dutchName: 'Semitische talen',
    },
    {
        code: 'sga',
        dutchName: 'Oud-iers (tot 900)',
    },
    {
        code: 'sgn',
        dutchName: 'Gebarentalen',
    },
    {
        code: 'shn',
        dutchName: 'Shan',
    },
    {
        code: 'sid',
        dutchName: 'Sidamo',
    },
    {
        code: 'sio',
        dutchName: 'Sioux-Catawbatalen',
    },
    {
        code: 'sit',
        dutchName: 'Sino-Tibetaanse talen',
    },
    {
        code: 'sla',
        dutchName: 'Slavische talen',
    },
    {
        code: 'sma',
        dutchName: 'Zuid-Samisch',
    },
    {
        code: 'smi',
        dutchName: 'Samisch (Laps)',
    },
    {
        code: 'smj',
        dutchName: 'Lule-Sami',
    },
    {
        code: 'smn',
        dutchName: 'Inari-Sami',
    },
    {
        code: 'sms',
        dutchName: 'Skolt-Sami',
    },
    {
        code: 'snk',
        dutchName: 'Soninke',
    },
    {
        code: 'sog',
        dutchName: 'Sogdisch',
    },
    {
        code: 'son',
        dutchName: 'Songhai',
    },
    {
        code: 'srn',
        dutchName: 'Surinaams',
    },
    {
        code: 'srr',
        dutchName: 'Sérères',
    },
    {
        code: 'ssa',
        dutchName: 'Nilo-Saharaanse talen',
    },
    {
        code: 'suk',
        dutchName: 'Sukuma',
    },
    {
        code: 'sus',
        dutchName: 'Susu',
    },
    {
        code: 'sux',
        dutchName: 'Soemerisch',
    },
    {
        code: 'syc',
        dutchName: 'Klassiek Syrisch',
    },
    {
        code: 'syr',
        dutchName: 'Syrisch',
    },
    {
        code: 'tam',
        dutchName: 'Tamil',
    },
    {
        code: 'tel',
        dutchName: 'Telugu, Teloegoe',
    },
    {
        code: 'tgk',
        dutchName: 'Tadzjieks',
    },
    {
        code: 'tha',
        dutchName: 'Thai',
    },
    {
        code: 'tir',
        dutchName: 'Tigrinya',
    },
    {
        code: 'tuk',
        dutchName: 'Turkmeens',
    },
    {
        code: 'tgl',
        dutchName: 'Tagalog',
    },
    {
        code: 'tsn',
        dutchName: 'Tswana',
    },
    {
        code: 'ton',
        dutchName: 'Tongaans, Tonga',
    },
    {
        code: 'tur',
        dutchName: 'Turks',
    },
    {
        code: 'tso',
        dutchName: 'Tsonga',
    },
    {
        code: 'tat',
        dutchName: 'Tataars',
    },
    {
        code: 'twi',
        dutchName: 'Twi',
    },
    {
        code: 'tah',
        dutchName: 'Tahitiaans',
    },
    {
        code: 'tai',
        dutchName: 'Taitalen',
    },
    {
        code: 'tem',
        dutchName: 'Timne',
    },
    {
        code: 'ter',
        dutchName: 'Tereno',
    },
    {
        code: 'tet',
        dutchName: 'Tetun',
    },
    {
        code: 'tig',
        dutchName: 'Tigre',
    },
    {
        code: 'tiv',
        dutchName: 'Tiv',
    },
    {
        code: 'tkl',
        dutchName: 'Tokelaus',
    },
    {
        code: 'tlh',
        dutchName: 'Klingon',
    },
    {
        code: 'tli',
        dutchName: 'Tlingit',
    },
    {
        code: 'tmh',
        dutchName: 'Tamashek',
    },
    {
        code: 'tog',
        dutchName: 'Tonga (Nyasa)',
    },
    {
        code: 'tpi',
        dutchName: 'Tok Pisin',
    },
    {
        code: 'tsi',
        dutchName: 'Tsimshiantalen',
    },
    {
        code: 'tum',
        dutchName: 'ChiTumbuka',
    },
    {
        code: 'tup',
        dutchName: 'Tupi-talen',
    },
    {
        code: 'tut',
        dutchName: 'Altaïsche talen',
    },
    {
        code: 'tvl',
        dutchName: 'Tuvaluaans',
    },
    {
        code: 'tyv',
        dutchName: 'Toevaans',
    },
    {
        code: 'uig',
        dutchName: 'Oeigoers',
    },
    {
        code: 'ukr',
        dutchName: 'Oekraïens',
    },
    {
        code: 'urd',
        dutchName: 'Urdu',
    },
    {
        code: 'uzb',
        dutchName: 'Oezbeeks',
    },
    {
        code: 'udm',
        dutchName: 'Oedmoerts',
    },
    {
        code: 'uga',
        dutchName: 'Ugaritisch',
    },
    {
        code: 'umb',
        dutchName: 'Umbundu',
    },
    {
        code: 'und',
        dutchName: 'onbepaald',
    },
    {
        code: 'ven',
        dutchName: 'Venda',
    },
    {
        code: 'vie',
        dutchName: 'Vietnamees',
    },
    {
        code: 'vol',
        dutchName: 'Volapük, Volapuk',
    },
    {
        code: 'vai',
        dutchName: 'Vai',
    },
    {
        code: 'vot',
        dutchName: 'Wotisch',
    },
    {
        code: 'wln',
        dutchName: 'Waals',
    },
    {
        code: 'wol',
        dutchName: 'Wolof',
    },
    {
        code: 'wak',
        dutchName: 'Wakashtalen',
    },
    {
        code: 'wal',
        dutchName: 'Walamo',
    },
    {
        code: 'war',
        dutchName: 'Waray-Waray',
    },
    {
        code: 'was',
        dutchName: 'Washo',
    },
    {
        code: 'wen',
        dutchName: 'Sorbische talen',
    },
    {
        code: 'xho',
        dutchName: 'Xhosa',
    },
    {
        code: 'xal',
        dutchName: 'Kalmuks',
    },
    {
        code: 'yid',
        dutchName: 'Jiddisch',
    },
    {
        code: 'yor',
        dutchName: 'Yoruba',
    },
    {
        code: 'yao',
        dutchName: 'Yao',
    },
    {
        code: 'yap',
        dutchName: 'Yapees',
    },
    {
        code: 'ypk',
        dutchName: 'Yupiktalen',
    },
    {
        code: 'zha',
        dutchName: 'Zhuang, Tsjoeang',
    },
    {
        code: 'zho',
        dutchName: 'Chinese talen',
    },
    {
        code: 'zul',
        dutchName: 'Zoeloe',
    },
    {
        code: 'zap',
        dutchName: 'Zapoteeks',
    },
    {
        code: 'zbl',
        dutchName: 'Blissymbolen',
    },
    {
        code: 'zen',
        dutchName: 'Zenaga',
    },
    {
        code: 'znd',
        dutchName: 'Zande',
    },
    {
        code: 'zun',
        dutchName: 'Zuni',
    },
    {
        code: 'zxx',
        dutchName: 'geen taalkundige inhoud',
    },
    {
        code: 'zza',
        dutchName: 'Zazaki (Dimili; Dimli; Kirdki; Kirmanjki; Zaza)',
    },
]
