import * as React from 'react'

import { Header, PageTabs, PageTab, Link } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { EmployeeGroupsTable, ExternalTeacherGroupsTable } from '~/implementations'
import { getCurrentUser } from '~/services/session'
import { OrganizationContactGroupsOverview } from '~/components/groups/overviews/OrganizationContactGroupsOverview'
import { View } from '~/components/View'
import { ContentView } from '~/components/ContentView'

interface Props {}

export default class MasterView extends React.Component<Props> {
    public render() {
        const currentUser = getCurrentUser()

        return (
            <View>
                <Header>
                    <BreadCrumbs />
                    {currentUser && currentUser.isEmployee && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/groups/concept`}>
                                    Concept
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/groups`}>
                                    Definitief
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                <ContentView>
                    {currentUser && currentUser.isEmployee && <EmployeeGroupsTable />}

                    {currentUser && currentUser.isExternalTeacher && (
                        <ExternalTeacherGroupsTable currentUser={currentUser} />
                    )}

                    {currentUser && currentUser.isOrganizationContact && (
                        <OrganizationContactGroupsOverview currentUser={currentUser} />
                    )}
                </ContentView>
            </View>
        )
    }
}
