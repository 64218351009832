import * as React from 'react'
import { gql } from '@apollo/client'
const moment = require('moment')
import CenterModal from '~/components/CenterModal'
import { ModalManager } from '~/components/ModalManager'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Mutator from '~/utils/Mutator'
import { toast, Fetcher, transformFormFields } from '~/utils'
import { GroupUserRole, GroupUserRemovedReason } from '~/types/User'
import Form from '~/components/Form'
import Paragraph from '~/components/Paragraph'
import Bold from '~/components/Bold'
import DateTimePicker from '~/components/DateTimePicker'
import { ValidationError } from '~/components/ValidationError'
import { TagPicker } from '~/components/TagPicker'
import { sortedGroupParticipationDisenrollmentReasons } from '~/utils/sortedGroupParticipationDisenrollmentReasons'
import translateType from '~/shared/utils/translateType'
import TextArea from '~/components/TextArea'
import { Select, Option } from '~/components'
import { SelectModuleFormField } from '~/forms'

interface Props {
    render: (openModal: () => void) => React.ReactNode
    userId: string
    userName: string
    lastEnrollmentDate: Date
    groupId: string
    groupName: string
    onDisenroll?: () => void
}

interface State {
    date: Date | null
    hasAttendancesAfterDate?: boolean
    changeLessonModule?: string | null
}

interface FormFields {
    date: Date | null
    removedReason: GroupUserRemovedReason | null
    removedInfo: string | null
    followupAction: GroupUserDisenrollmentFollowupActionEnum
    newAdviceModule: NewAdviceModule | null
}

interface NewAdviceModule {
    moduleId: string
    programId: string
    projectId: string
}

export enum GroupUserDisenrollmentFollowupActionEnum {
    NoAction = 'NoAction',
    DeactiveLearnerUser = 'DeactiveLearnerUser',
    ChangeLearnerUserAdviceModule = 'ChangeLearnerUserAdviceModule',
}

enum GroupUserDisenrollmentFollowupActionTranslation {
    NoAction = 'Geen vervolgactie',
    DeactiveLearnerUser = 'Kandidaat op non-actief zetten',
    ChangeLearnerUserAdviceModule = 'Adviesmodule wijzigen',
}

export class GroupLearnerParticipantDisenrollModalManager extends React.Component<Props, State> {
    private defaultDate = new Date()

    private disenrollMutator = new Mutator({
        mutation: DISENROLL_MUTATION,
        reactComponentToUpdate: this,
    })

    private hasAttendancesAfterDateFetcher = new Fetcher({
        query: USER_HAS_ATTENDANCES_AFTER_DATE,
        preventInitialFetch: true,
        onChange: () => this.forceUpdate(),
        variables: {
            afterDate: this.defaultDate,
            groupFilters: { filterById: this.props.groupId },
            userId: this.props.userId,
        },
    })

    constructor(props: Props) {
        super(props)

        this.state = {
            date: this.defaultDate,
            hasAttendancesAfterDate: undefined,
        } as State

        this.hasAttendancesAfterDate(this.defaultDate).then(hasAttendancesAfterDate => {
            this.setState({ hasAttendancesAfterDate })
        })
    }

    public render() {
        const { render, userName, groupName, lastEnrollmentDate } = this.props
        const { hasAttendancesAfterDate, changeLessonModule } = this.state
        const { loading: isLoadingDisenroll, errors } = this.disenrollMutator

        const hasInvoicesAfterDate = false // TODO_GU_FRONTEND

        const hasError = hasAttendancesAfterDate || this.isDateBeforeLastEnrollmentDate()

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <CenterModal medium title={'Uitschrijven uit groep'} onClose={closeModal} enableEasyClose>
                        <Form onSubmit={this.onSubmit.bind(this, closeModal)}>
                            <FieldCollection style={`modal`}>
                                <FieldGroup isForm>
                                    <Field>
                                        <Paragraph>
                                            {/* tslint:disable-next-line:max-line-length */}
                                            Je staat op het punt om <Bold>{userName}</Bold> uit te schrijven uit de
                                            groep <Bold>{groupName}</Bold>.
                                        </Paragraph>
                                    </Field>
                                    <Field title={`Uitschrijfdatum`} errors={errors}>
                                        <DateTimePicker
                                            name={`date`}
                                            defaultValue={this.defaultDate}
                                            onChange={this.updateDate}
                                        />
                                        {hasAttendancesAfterDate && (
                                            <ValidationError text={`Na deze datum is al presentie ingevuld.`} />
                                        )}
                                        {this.isDateBeforeLastEnrollmentDate() && (
                                            <ValidationError
                                                text={`De datum moet na de inschrijfdatum (${moment(
                                                    lastEnrollmentDate
                                                ).format('LL')}) liggen.`}
                                            />
                                        )}
                                        {hasInvoicesAfterDate && (
                                            <ValidationError
                                                isWarning={true}
                                                text={
                                                    'Waarschuwing: er zijn al een aantal lessen gefactureerd na de aangegeven datum.'
                                                }
                                            />
                                        )}
                                    </Field>
                                    <Field title={`Reden uitschrijving`} errors={errors}>
                                        <TagPicker
                                            multi={false}
                                            options={sortedGroupParticipationDisenrollmentReasons.map(o => ({
                                                value: o,
                                                label: translateType('groupParticipationDisenrollmentReason', o),
                                            }))}
                                            name={`removedReason`}
                                            placeholder={`Selecteer een reden`}
                                        />
                                    </Field>
                                    <Field title={`Toelichting`} errors={errors}>
                                        <TextArea name={`removedInfo`} />
                                    </Field>

                                    <Field title={`Vervolgactie`} errors={errors}>
                                        {/* tslint:disable-next-line: max-line-length */}
                                        <Select
                                            name={'followupAction'}
                                            placeholder={'Selecteer een vervolgactie'}
                                            onChange={({
                                                nativeEvent: {
                                                    target: { value },
                                                },
                                            }: any) => this.setState({ changeLessonModule: value })}
                                        >
                                            {[
                                                GroupUserDisenrollmentFollowupActionEnum.NoAction,
                                                GroupUserDisenrollmentFollowupActionEnum.DeactiveLearnerUser,
                                                GroupUserDisenrollmentFollowupActionEnum.ChangeLearnerUserAdviceModule,
                                            ].map(action => (
                                                // TODO: Add key and re-enable react/jsx-key rule
                                                // eslint-disable-next-line react/jsx-key
                                                <Option value={action}>
                                                    {GroupUserDisenrollmentFollowupActionTranslation[action]}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Field>

                                    {/* tslint:disable-next-line:max-line-length */}
                                    {changeLessonModule ===
                                        GroupUserDisenrollmentFollowupActionEnum.ChangeLearnerUserAdviceModule && (
                                        <Field>
                                            <SelectModuleFormField
                                                title={`Nieuwe advies-lesmodule`}
                                                moduleIdName={`newAdviceModule.moduleId`}
                                                projectIdName={`newAdviceModule.projectId`}
                                                programIdName={`newAdviceModule.programId`}
                                                errors={errors}
                                            />
                                        </Field>
                                    )}
                                </FieldGroup>
                                <FieldCollectionFooter>
                                    <List horizontal>
                                        <ListItem right>
                                            <Button onClick={closeModal}>Annuleren</Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button type="submit" isLoading={isLoadingDisenroll} isDisabled={hasError}>
                                                Bevestigen
                                            </Button>
                                        </ListItem>
                                    </List>
                                </FieldCollectionFooter>
                            </FieldCollection>
                        </Form>
                    </CenterModal>
                )}
            />
        )
    }

    private onSubmit = async (closeModal: () => void, event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { userId, userName, groupId, onDisenroll } = this.props

        const result = await this.disenrollMutator.mutate({
            ...transformFormFields(fields, {}),
            role: GroupUserRole.Learner,
            groupId,
            userId,
        })

        if (result && result.groups_disenrollUser) {
            closeModal()
            toast.success(`"${userName}" is succesvol uitgeschreven`)

            if (onDisenroll) {
                onDisenroll()
            }
        }
    }

    private updateDate = async (date: Date | null) => {
        this.setState({
            date,
            hasAttendancesAfterDate: date ? await this.hasAttendancesAfterDate(date) : undefined,
        })
    }

    private async hasAttendancesAfterDate(date: Date): Promise<boolean | undefined> {
        if (!date || isNaN(date.getTime())) {
            return undefined
        }

        const result = await this.hasAttendancesAfterDateFetcher.refetch({
            silent: true,
            afterDate: date,
        } as any)

        const hasAttendances =
            !!result &&
            result.groups &&
            result.groups[0] &&
            result.groups[0].learnerUsers &&
            result.groups[0].learnerUsers[0] &&
            result.groups[0].learnerUsers[0].hasAttendances

        return hasAttendances
    }

    private isDateBeforeLastEnrollmentDate(): boolean {
        const { date } = this.state
        const { lastEnrollmentDate } = this.props

        if (!date) {
            return false
        }

        return date.getTime() <= new Date(lastEnrollmentDate).getTime()
    }
}

const DISENROLL_MUTATION = gql`
    mutation groups_disenrollUser(
        $groupId: MongoID
        $userId: MongoID
        $date: Date
        $removedReason: GroupUserRemovedReasonEnum
        $removedInfo: String
        $followupAction: GroupUserDisenrollmentFollowupActionEnum
        $newAdviceModule: GroupUserDisenrollmentNewAdviceModuleInputType
        $role: GroupUserRoleEnum
    ) {
        groups_disenrollUser(
            groupId: $groupId
            userId: $userId
            date: $date
            removedReason: $removedReason
            removedInfo: $removedInfo
            followupAction: $followupAction
            newAdviceModule: $newAdviceModule
            role: $role
        ) {
            _id
        }
    }
`

const USER_HAS_ATTENDANCES_AFTER_DATE = gql`
    query _($groupFilters: GroupsFilterInputType, $userId: MongoID!, $afterDate: Date!) {
        groups(filters: $groupFilters) {
            learnerUsers(filterByUserIds: [$userId]) {
                hasAttendances(afterDate: $afterDate)
            }
        }
    }
`
