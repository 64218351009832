import * as React from 'react'
import { VariableMultiFieldGroup } from '~/components/VariableMultiFieldGroup/VariableMultiFieldGroup'
import { Organization } from '~/types/Organization'
import { UserLearnerOrganization } from '~/types/User'
import { LearnerOrganizationFields } from '~/components/users/Learner/LearnerOrganizationFields'

interface Props {
    defaultValues?: UserLearnerOrganization[]
    errors?: any
    hasPrivatePayment?: boolean
    hideFieldsForOrganizationContacts?: boolean
    loading?: boolean
    organizations?: Organization[]
    isDisabled?: boolean
}

export class LearnerOrganizationsInput extends React.Component<Props> {
    public render() {
        const { defaultValues, isDisabled, ...restProps } = this.props
        return (
            <VariableMultiFieldGroup
                addButtonLabel={`Extra organisatie`}
                baseAmount={(defaultValues && defaultValues.length) || 1}
                isInsetGroup={false}
                isDisabled={isDisabled}
                renderFieldGroup={({ iteration, renderRemoveButton }) => {
                    const defaultValue = defaultValues && defaultValues[iteration]

                    return (
                        <React.Fragment key={iteration}>
                            <LearnerOrganizationFields
                                index={iteration}
                                defaultValue={defaultValue}
                                isDisabled={isDisabled}
                                renderRemoveButton={renderRemoveButton}
                                {...restProps}
                            />
                        </React.Fragment>
                    )
                }}
            />
        )
    }
}
