import c from 'classnames'
import { gql } from '@apollo/client'
import { bindAll, set } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, CenterModal, Field, FieldCollection, Form, Paragraph, Spinner, TimelineInput } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { LogTimelineMessage, UserTimelineMessage } from '~/implementations'
import { Translator } from '~/services/i18n'
import { getCurrentUser } from '~/services/session'
import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'

export default class Timeline extends Component {
    static propTypes = {
        ownerType: PropTypes.oneOf(['LEARNER_USER', 'TEACHER_USER', 'ORGANIZATION']).isRequired,
        ownerTypeDataUserId: PropTypes.string,
        ownerTypeDataOrganizationId: PropTypes.string,
        timelineEvents: PropTypes.arrayOf(PropTypes.object),
        isLoading: PropTypes.bool,
        refetch: PropTypes.func.isRequired,
        targetChoices: PropTypes.array,
        className: PropTypes.string,
    }

    static defaultProps = {
        timelineEvents: [],
    }

    constructor(props) {
        super(props)

        bindAll(this, ['createTimelineEvent', 'updateTimelineEvent', 'removeTimelineEvent'])

        this.createTimelineEventMutator = new Mutator({
            mutation: ADD_TIMELINE_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.editTimelineEventMutator = new Mutator({
            mutation: EDIT_TIMELINE_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteTimelineEventMutator = new Mutator({
            mutation: DELETE_TIMELINE_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.translator = new Translator({
            namespace: 'App.Users.Common.Detail.Timeline',
            subscribe: this,
        })
    }

    componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    getTransformedFormFields(timelineEventFields) {
        return transformFormFields(timelineEventFields, {
            owner: {
                fields: v =>
                    transformFormFields(v, {
                        targets: transformLib.split(),
                    }),
            },
        })
    }

    createTimelineEvent(event, fields) {
        const { ownerType, ownerTypeDataUserId, ownerTypeDataOrganizationId, refetch } = this.props

        const { form } = this.refs

        const timelineEventInput = this.getTransformedFormFields(fields.timelineEvent)
        set(timelineEventInput, 'owner.type', ownerType)

        if (['LEARNER_USER', 'TEACHER_USER'].includes(ownerType)) {
            set(timelineEventInput, 'owner.typeData.userId', ownerTypeDataUserId)
        }

        if (['ORGANIZATION'].includes(ownerType)) {
            set(timelineEventInput, 'owner.typeData.organizationId', ownerTypeDataOrganizationId)
        }

        this.createTimelineEventMutator
            .mutate({
                timelineEvent: timelineEventInput,
            })
            .then(res => {
                if (res) {
                    refetch()
                    if (form) {
                        form.clear()
                    }
                }
            })
    }

    updateTimelineEvent(fields, eventId, closeModal) {
        const { refetch } = this.props

        this.editTimelineEventMutator
            .mutate({
                timelineEvent: {
                    _id: eventId,
                    ...this.getTransformedFormFields(fields.timelineEvent),
                },
            })
            .then(res => {
                if (res) {
                    closeModal()
                    refetch()
                }
            })
    }

    removeTimelineEvent(eventId, closeModal) {
        const { refetch } = this.props

        this.deleteTimelineEventMutator
            .mutate({
                timelineEventId: eventId,
            })
            .then(res => {
                if (res) {
                    closeModal()
                    refetch()
                }
            })
    }

    getClassName() {
        const { className } = this.props
        return c('tt-Timeline', className)
    }

    /**
     * Take an educated guess, but be optimistic
     *
     * @param {Object} timelineEvent
     * @returns {Boolean}
     */
    isTimelineEventEditable(timelineEvent) {
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee || currentUser.isAdmin) {
            return true
        }

        if (currentUser.isExternalTeacher || currentUser.isOrganizationContact) {
            return timelineEvent.createdByUser._id === currentUser._id
        }

        return false
    }

    render() {
        const { targetChoices, isLoading, timelineEvents } = this.props
        const { loading: isCreateLoading, errors } = this.createTimelineEventMutator

        const { t } = this.translator

        const isEmpty = timelineEvents.length === 0
        const isInitiallyLoading = isLoading && isEmpty

        return (
            <div className={this.getClassName()}>
                <Form onSubmit={this.createTimelineEvent} ref={'form'}>
                    <FieldCollection>
                        <FieldGroup>
                            <Field errors={errors}>
                                <TimelineInput
                                    errors={errors}
                                    autoFocus
                                    autoUpdate
                                    canSubmit
                                    targetChoices={targetChoices}
                                    isLoading={isCreateLoading}
                                />
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Form>
                {isInitiallyLoading && <Spinner />}
                {!isInitiallyLoading && isEmpty && <Paragraph>{t('placeholder')}</Paragraph>}
                {!isInitiallyLoading && !isEmpty && this.renderTimelineEvents(timelineEvents)}
            </div>
        )
    }

    renderTimelineEvents(timelineEvents) {
        const { targetChoices } = this.props
        const { loading: isUpdateLoading, errors } = this.editTimelineEventMutator
        const { loading: isDeleteLoading } = this.deleteTimelineEventMutator

        return timelineEvents.map((timelineEvent, i) => {
            if (timelineEvent.isLog) {
                return <LogTimelineMessage key={i} timelineEvent={timelineEvent} />
            } else {
                return (
                    <ModalManager
                        key={i}
                        render={openModal => (
                            <UserTimelineMessage
                                onEdit={openModal}
                                isEditable={this.isTimelineEventEditable(timelineEvent)}
                                timelineEvent={timelineEvent}
                            />
                        )}
                        getModal={closeModal => {
                            return (
                                <CenterModal onClose={closeModal} title={`Bewerken`}>
                                    <Form
                                        onSubmit={(e, fields) =>
                                            this.updateTimelineEvent(fields, timelineEvent._id, closeModal)
                                        }
                                    >
                                        <FieldCollection style={`modal`}>
                                            <FieldGroup isForm>
                                                <Field errors={errors}>
                                                    <TimelineInput
                                                        defaultTimelineEvent={timelineEvent}
                                                        targetChoices={targetChoices}
                                                    />
                                                </Field>
                                            </FieldGroup>
                                            <FieldCollectionFooter>
                                                <List horizontal>
                                                    <ListItem>
                                                        <Button
                                                            isLoading={isDeleteLoading}
                                                            linkStyle={['default', 'danger']}
                                                            onClick={() =>
                                                                this.removeTimelineEvent(timelineEvent._id, closeModal)
                                                            }
                                                        >
                                                            Verwijderen
                                                        </Button>
                                                    </ListItem>
                                                    <ListItem right>
                                                        <Button onClick={closeModal}>Annuleren</Button>
                                                    </ListItem>
                                                    <ListItem right>
                                                        <Button type={`submit`} isLoading={isUpdateLoading}>
                                                            Opslaan
                                                        </Button>
                                                    </ListItem>
                                                </List>
                                            </FieldCollectionFooter>
                                        </FieldCollection>
                                    </Form>
                                </CenterModal>
                            )
                        }}
                    />
                )
            }
        })
    }
}

const ADD_TIMELINE_EVENT_MUTATION = gql`
    mutation _($timelineEvent: TimelineEventInputType!) {
        timelineEvents_create(timelineEvent: $timelineEvent) {
            _id
        }
    }
`

const EDIT_TIMELINE_EVENT_MUTATION = gql`
    mutation _($timelineEvent: TimelineEventInputType!) {
        timelineEvents_edit(timelineEvent: $timelineEvent) {
            _id
        }
    }
`

const DELETE_TIMELINE_EVENT_MUTATION = gql`
    mutation _($timelineEventId: MongoID!) {
        timelineEvents_delete(timelineEventId: $timelineEventId) {
            _id
        }
    }
`
