import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { Mutator } from '~/utils'
import { Link, CenterModal } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { EditGroupLocationForm } from '~/forms'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { SimpleTableRow } from '~/components/SimpleTableRow'

import { reactJoin } from '~/shared/utils'

export default class GroupLocationFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        groupEditMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = ['location-required', 'room-required']

    constructor(props) {
        super(props)
        bindAll(this, ['removeLocation', 'getEditModal'])
    }

    async removeLocation() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                nulls: ['locationId', 'roomIds'],
            },
        })

        refetchGroup()
    }

    render() {
        const { group, validationErrors } = this.props
        const { location, rooms } = group

        return (
            <GroupFieldGroup
                title="Locatie"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeLocation}
                removeMessage={`Je staat op het punt de locatie uit deze groep te verwijderen. Weet je het zeker?`}
            >
                {!rooms.length && <Link route={`/properties/locations/${location._id}`}>{location.name}</Link>}
                {rooms.length > 0 && (
                    <span>
                        <Link route={`/properties/locations/${location._id}/`}>{location.name}</Link>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell>Lesruimte(s):</SimpleTableCell>
                                <SimpleTableCell>
                                    {reactJoin(
                                        rooms.map(room => {
                                            return (
                                                <Link
                                                    key={room._id}
                                                    route={`/properties/locations/${location._id}/rooms/${room._id}`}
                                                >
                                                    {room.name}
                                                </Link>
                                            )
                                        })
                                    )}
                                </SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </span>
                )}
            </GroupFieldGroup>
        )
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Locatie bewerken`}>
                <EditGroupLocationForm
                    onSubmitSuccess={closeModal}
                    onCancel={closeModal}
                    refetch={refetchGroup}
                    group={group}
                />
            </CenterModal>
        )
    }
}
