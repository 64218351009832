import './GroupExamsModalTable.scss'

import c from 'classnames'
import * as React from 'react'

import { Table } from '~/components/Table'

interface Props {
    className?: string
    type: 'score' | 'result' | 'level'
}

export class GroupExamsModalTable extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <Table className={this.getClassName()}>{children}</Table>
    }

    private getClassName() {
        const { className, type } = this.props

        return c(
            'tt-GroupExamsModalTable',
            {
                'tt-GroupExamsModalTable--type-is-score': type === 'score',
                'tt-GroupExamsModalTable--type-is-result': type === 'result',
                'tt-GroupExamsModalTable--type-is-level': type === 'level',
            },
            className
        )
    }
}
