import c from 'classnames'
import { bindAll, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Option } from '~/components'
import { oneOrManyOfTypes } from '~/utils/componentValidators'

// WARNING
// Deprecated. Please use <TagPicker> instead.
export default class Select extends Component {
    static propTypes = {
        value: PropTypes.any,
        defaultValue: PropTypes.any,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        className: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        isDisabled: PropTypes.bool,
        isReadonly: PropTypes.bool,
        hasError: PropTypes.bool,
        children: oneOrManyOfTypes.bind(null, [Option]),
        errorKey: PropTypes.string,
    }

    constructor(props) {
        super(props)

        const { value, defaultValue } = props

        this.state = {
            hasValue: this.hasValue(value) || this.hasValue(defaultValue),
        }

        bindAll(this, ['onFocus', 'onBlur', 'onChange'])
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                hasValue: this.hasValue(nextProps.value),
            })
        }
    }

    hasValue(value) {
        return !isUndefined(value) && value !== null
    }

    onFocus(event) {
        const { onFocus } = this.props
        if (onFocus) onFocus(event)
    }

    onBlur(event) {
        const { onBlur } = this.props
        if (onBlur) onBlur(event)
    }

    onChange(event) {
        const { onChange } = this.props

        this.setState({
            hasValue: !!event.target.value,
        })

        if (onChange) onChange(event)
    }

    render() {
        const {
            children,
            className: extraClassNames,
            name,
            placeholder,
            isDisabled,
            isReadonly,
            hasError,
            defaultValue,
            value,
            errorKey: _errorKey,
        } = this.props
        const { hasValue } = this.state
        const className = c('tt-Select', extraClassNames, {
            'tt-Select--has-value': hasValue,
            'tt-Select--is-disabled': isDisabled,
            'tt-Select--is-readonly': isReadonly,
            'tt-Select--has-error': hasError,
        })

        const errorKey = _errorKey || name

        return (
            <select
                value={value !== null ? value : ''}
                className={className}
                name={name}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                defaultValue={defaultValue}
                readOnly={isReadonly}
                disabled={isDisabled}
                data-errorkey={errorKey}
            >
                {placeholder && <Option value={''}>{placeholder}</Option>}
                {children}
            </select>
        )
    }
}
