import React, { Component } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { get, bindAll } from 'lodash'

import { TextArea, Button, Input, Icon } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

/**
 * This input can currently only be used as controlled input
 */
export default class TimelineMessageInput extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        typeName: PropTypes.string,
        typeValue: PropTypes.any,
        messageName: PropTypes.string,
        messageValue: PropTypes.any,
        types: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any,
                label: PropTypes.string,
                icon: PropTypes.any,
                color: PropTypes.string,
            })
        ),
    }

    static defaultProps = {
        autoFocus: false,
        defaultValue: () => {},
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onChangeMessage'])
    }

    onChangeMessage(event) {
        const messageValue = get(event, 'target.value') || ''
        const { onChange, typeValue } = this.props

        onChange(typeValue, messageValue)
    }

    changeType(typeValue) {
        const { onChange, messageValue } = this.props

        onChange(typeValue, messageValue)
    }

    getClassName() {
        const { className } = this.props
        return c('tt-TimelineMessageInput', className)
    }

    render() {
        const { messageName, types, typeName, placeholder, autoFocus, messageValue, typeValue } = this.props

        return (
            <div className={this.getClassName()}>
                {types && this.renderButtons()}
                <TextArea
                    ref="textArea"
                    autoFocus={autoFocus}
                    value={messageValue}
                    className={`tt-TimelineMessageInput__textarea`}
                    placeholder={placeholder}
                    name={messageName}
                    onChange={this.onChangeMessage}
                />
                {types && <Input type={`hidden`} value={typeValue || ''} name={typeName} />}
            </div>
        )
    }

    renderButtons() {
        const { types = [], typeValue } = this.props

        return (
            <div className={'tt-TimelineMessageInput__buttons__wrapper'}>
                <div className={'tt-TimelineMessageInput__buttons__wrapper-fix'}>
                    <div className={'tt-TimelineMessageInput__buttons__scroller'}>
                        <List className={`tt-TimelineMessageInput__buttons`} horizontal>
                            {types.map(({ value, label, icon, color }, index) => {
                                const buttonClassName = c('tt-TimelineMessageInput__buttons__button', {
                                    'tt-TimelineMessageInput__buttons__button--is-active': typeValue === value,
                                })
                                return (
                                    <ListItem key={index}>
                                        <Button
                                            leftIcon={<Icon color={color} name={icon} />}
                                            className={buttonClassName}
                                            onClick={() => this.changeType(value)}
                                        >
                                            {label}
                                        </Button>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                </div>
            </div>
        )
    }
}
