import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import TextArea from '~/components/TextArea'
import PrecisionDateInput from '~/components/PrecisionDateInput'
import RadioButton from '~/components/RadioButton'
import { translateType, types } from '~/shared/utils'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { get, isEmpty } from 'lodash'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { languageOptions, LanguageOptionsData } from '~/utils/languageOptions'

interface Props {
    user: User
    errors?: GraphQLErrors
    forNewIntake?: boolean
}

interface State {
    showVocalInteractionAdditionalFields: boolean
    learnerSocialStatus: string | null
    isCitizenshipEducationObliged: boolean
    showLiteracyLevelAdditionalFields: boolean
    obligedCitizenshipEducationEndDateExtensionFiles: any[]
}

export class UserLearnerBackgroundInformationFieldGroup extends React.Component<Props, State> {
    public state: State = {
        // tslint:disable-next-line:max-line-length
        showVocalInteractionAdditionalFields: this.props.forNewIntake
            ? false
            : get(this.props.user, 'learner.currentOccupationVocalInteraction') === 'Ja',
        learnerSocialStatus: this.props.forNewIntake ? null : get(this.props.user, 'learner.socialStatus'),
        isCitizenshipEducationObliged: !!get(this.props.user, 'learner.isCitizenshipEducationObliged'),
        showLiteracyLevelAdditionalFields: this.props.forNewIntake
            ? false
            : get(this.props.user, 'learner.workLiteracy') === 'Ja',
        obligedCitizenshipEducationEndDateExtensionFiles: [],
    }

    public onLearnerSocialStatusChange(option: TagPickerOption) {
        const value = option ? option.value : null

        this.setState({
            learnerSocialStatus: value,
        })
    }

    public onLiteracyLevelChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === 'Ja') {
            this.setState({ showLiteracyLevelAdditionalFields: true })
        } else {
            this.setState({ showLiteracyLevelAdditionalFields: false })
        }
    }

    public onVocalInteractionChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === 'Ja') {
            this.setState({ showVocalInteractionAdditionalFields: true })
        } else {
            this.setState({ showVocalInteractionAdditionalFields: false })
        }
    }

    public render() {
        const { errors, user, forNewIntake } = this.props

        const immigratedAt = get(user, 'learner.immigratedAt')
        const currentOccupationStartDate = get(user, 'learner.currentOccupationStartDate')

        return (
            <FieldGroup title={'Achtergrondinformatie'} isForm={true}>
                <LockedFieldWrapper disableLock={!immigratedAt}>
                    {isLocked => (
                        <Field isLabel={true} title={`In Nederland sinds`} errors={errors}>
                            <PrecisionDateInput
                                isDisabled={isLocked}
                                name={`user.learner.immigratedAt`}
                                defaultValue={immigratedAt}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, 'learner.nativeLanguages')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Moedertaal(en)`} errors={errors}>
                            <TagPicker<LanguageOptionsData>
                                name={`user.learner.nativeLanguages`}
                                options={languageOptions}
                                placeholder={'Selecteer een of meerdere talen'}
                                defaultValue={get(user, `learner.nativeLanguages`)}
                                isDisabled={isLocked}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={isEmpty(get(user, `learner.otherLanguages`))}>
                    {isLocked => (
                        <Field isLabel={true} title={`Overige talen`} errors={errors}>
                            <TagPicker<LanguageOptionsData>
                                name={`user.learner.otherLanguages`}
                                options={languageOptions}
                                isDisabled={isLocked}
                                placeholder={'Selecteer een taal'}
                                defaultValue={get(user, `learner.otherLanguages`)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, `learner.englishAsSupportLanguage`)}>
                    {isLocked => (
                        <Field title={`Engels als steuntaal mogelijk?`} errors={errors}>
                            <MultiInput type="radio">
                                {['Ja', 'Nee'].map((label, i) => (
                                    <RadioButton
                                        key={i}
                                        isDisabled={isLocked}
                                        name={`user.learner.englishAsSupportLanguage`}
                                        value={label}
                                        defaultChecked={get(user, 'learner.englishAsSupportLanguage') === label}
                                    >
                                        {label}
                                    </RadioButton>
                                ))}
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={isEmpty(get(user, `learner.homeLanguages`))}>
                    {isLocked => (
                        <Field isLabel={true} title={`Voertaal(en) thuis`} errors={errors}>
                            <TagPicker<LanguageOptionsData>
                                name={`user.learner.homeLanguages`}
                                options={languageOptions}
                                isDisabled={isLocked}
                                placeholder={'Selecteer een of meerdere talen'}
                                defaultValue={get(user, `learner.homeLanguages`)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, `learner.workExperienceCountryOfOrigin`)}>
                    {isLocked => (
                        <Field isLabel title={`Werkervaring herkomstland`} errors={errors}>
                            <TextArea
                                name={`user.learner.workExperienceCountryOfOrigin`}
                                isDisabled={isLocked}
                                defaultValue={get(user, 'learner.workExperienceCountryOfOrigin')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, `learner.workExperienceLocal`)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Werkervaring Nederland (verleden)`} errors={errors}>
                            <TextArea
                                name={`user.learner.workExperienceLocal`}
                                isDisabled={isLocked}
                                defaultValue={get(user, 'learner.workExperienceLocal')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, `learner.socialStatus`)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Maatschappelijke status`} errors={errors}>
                            <TagPicker
                                name={`user.learner.socialStatus`}
                                multi={false}
                                isDisabled={isLocked}
                                options={Object.keys(types.learnerSocialStatus).map(key => ({
                                    value: key,
                                    label: translateType('learnerSocialStatus', key),
                                }))}
                                placeholder={'Selecteer een status'}
                                defaultValue={forNewIntake ? undefined : this.state.learnerSocialStatus}
                                onChange={option => this.onLearnerSocialStatusChange(option)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {this.state.learnerSocialStatus === 'OTHER' && (
                    <LockedFieldWrapper disableLock={forNewIntake || !get(user, `learner.socialStatusExtraInfo`)}>
                        {isLocked => (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={`Toelichting maatschappelijke status`} errors={errors}>
                                    <TextArea
                                        isDisabled={isLocked}
                                        name={`user.learner.socialStatusExtraInfo`}
                                        defaultValue={
                                            forNewIntake ? undefined : get(user, 'learner.socialStatusExtraInfo')
                                        }
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </LockedFieldWrapper>
                )}
                {this.state.learnerSocialStatus === 'WORKING' && (
                    <LockedFieldWrapper disableLock={forNewIntake || this.isWorkingFieldGroupLockDisabled(user)}>
                        {isLocked => (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={`Startdatum huidig werk`} errors={errors}>
                                    <PrecisionDateInput
                                        isDisabled={isLocked}
                                        name={`user.learner.currentOccupationStartDate`}
                                        defaultValue={forNewIntake ? undefined : currentOccupationStartDate}
                                    />
                                </Field>
                                <Field isLabel={true} title={`Huidig werk functie`} errors={errors}>
                                    <Input
                                        name={`user.learner.currentOccupationFunction`}
                                        type="text"
                                        isDisabled={isLocked}
                                        defaultValue={
                                            forNewIntake ? undefined : get(user, 'learner.currentOccupationFunction')
                                        }
                                    />
                                </Field>
                                <Field isLabel={true} title={`Taken/toelichting huidig werk`} errors={errors}>
                                    <TextArea
                                        name={`user.learner.currentOccupationResponsibilities`}
                                        isDisabled={isLocked}
                                        defaultValue={
                                            forNewIntake
                                                ? undefined
                                                : get(user, 'learner.currentOccupationResponsibilities')
                                        }
                                    />
                                </Field>
                                <Field isLabel={true} title={`Voertaal(en) huidig werk`} errors={errors}>
                                    <TagPicker<LanguageOptionsData>
                                        name={`user.learner.currentOccupationMainLanguages`}
                                        options={languageOptions}
                                        isDisabled={isLocked}
                                        placeholder={'Selecteer een of meerdere talen'}
                                        defaultValue={
                                            forNewIntake
                                                ? undefined
                                                : get(user, `learner.currentOccupationMainLanguages`)
                                        }
                                    />
                                </Field>

                                <Field title={`Nederlands lezen/schrijven op het werk`} errors={errors}>
                                    <MultiInput type="radio">
                                        {['Ja', 'Nee'].map((label, i) => (
                                            <RadioButton
                                                key={i}
                                                name={`user.learner.workLiteracy`}
                                                value={label}
                                                isDisabled={isLocked}
                                                defaultChecked={
                                                    forNewIntake
                                                        ? undefined
                                                        : get(user, 'learner.workLiteracy') === label
                                                }
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    this.onLiteracyLevelChange(event)
                                                }
                                            >
                                                {label}
                                            </RadioButton>
                                        ))}
                                    </MultiInput>
                                </Field>
                                {this.state.showLiteracyLevelAdditionalFields && (
                                    <div>
                                        <Field title={`Hoe gaat dat?`} errors={errors}>
                                            <MultiInput type="radio">
                                                {['Goed', 'Voldoende', 'Matig', 'Slecht'].map((label, i) => (
                                                    <RadioButton
                                                        key={i}
                                                        isDisabled={isLocked}
                                                        name={`user.learner.workLiteracyLevel`}
                                                        value={label}
                                                        defaultChecked={
                                                            forNewIntake
                                                                ? undefined
                                                                : get(user, 'learner.workLiteracyLevel') === label
                                                        }
                                                    >
                                                        {label}
                                                    </RadioButton>
                                                ))}
                                            </MultiInput>
                                        </Field>
                                        <Field isLabel={true} title={`Schriftelijke taken toelichting`} errors={errors}>
                                            <TextArea
                                                isDisabled={isLocked}
                                                name={`user.learner.workLiteracyInfo`}
                                                defaultValue={
                                                    forNewIntake ? undefined : get(user, 'learner.workLiteracyInfo')
                                                }
                                            />
                                        </Field>
                                    </div>
                                )}
                                <Field title={`Mondeling contact met leidingevende/collega's`} errors={errors}>
                                    <MultiInput type="radio">
                                        {['Ja', 'Nee'].map((label, i) => (
                                            <RadioButton
                                                key={i}
                                                isDisabled={isLocked}
                                                name={`user.learner.currentOccupationVocalInteraction`}
                                                value={label}
                                                defaultChecked={
                                                    forNewIntake
                                                        ? undefined
                                                        : get(user, 'learner.currentOccupationVocalInteraction') ===
                                                          label
                                                }
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    this.onVocalInteractionChange(event)
                                                }
                                            >
                                                {label}
                                            </RadioButton>
                                        ))}
                                    </MultiInput>
                                </Field>
                                {this.state.showVocalInteractionAdditionalFields && (
                                    <div>
                                        <Field title={`Hoe gaat dat?`} errors={errors}>
                                            <MultiInput type="radio">
                                                {['Goed', 'Voldoende', 'Matig', 'Slecht'].map((label, i) => (
                                                    <RadioButton
                                                        isDisabled={isLocked}
                                                        key={i}
                                                        name={`user.learner.currentOccupationVocalInteractionQuality`}
                                                        value={label}
                                                        defaultChecked={
                                                            // tslint:disable-next-line:max-line-length
                                                            forNewIntake
                                                                ? undefined
                                                                : get(
                                                                      user,
                                                                      'learner.currentOccupationVocalInteractionQuality'
                                                                  ) === label
                                                        }
                                                    >
                                                        {label}
                                                    </RadioButton>
                                                ))}
                                            </MultiInput>
                                        </Field>
                                        <Field isLabel={true} title={`Mondeling contact toelichting`} errors={errors}>
                                            <TextArea
                                                name={`user.learner.currentOccupationVocalInteractionInfo`}
                                                isDisabled={isLocked}
                                                defaultValue={
                                                    forNewIntake
                                                        ? undefined
                                                        : get(user, 'learner.currentOccupationVocalInteractionInfo')
                                                }
                                            />
                                        </Field>
                                    </div>
                                )}
                            </FieldGroup>
                        )}
                    </LockedFieldWrapper>
                )}
                <LockedFieldWrapper
                    disableLock={forNewIntake || !get(user, 'learner.familyCompositionLanguageExposure')}
                >
                    {isLocked => (
                        <Field title={`Nederlands taalcontact`} errors={errors}>
                            <MultiInput type="radio">
                                {['Veel', 'Voldoende', 'Matig', 'Geen'].map((label, i) => (
                                    <RadioButton
                                        key={i}
                                        name={`user.learner.familyCompositionLanguageExposure`}
                                        value={label}
                                        isDisabled={isLocked}
                                        defaultChecked={
                                            forNewIntake
                                                ? undefined
                                                : get(user, 'learner.familyCompositionLanguageExposure') === label
                                        }
                                    >
                                        {label}
                                    </RadioButton>
                                ))}
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }

    private isWorkingFieldGroupLockDisabled(user: User) {
        return (
            !get(user, 'learner.currentOccupationStartDate') &&
            !get(user, 'learner.currentOccupationFunction') &&
            !get(user, 'learner.currentOccupationResponsibilities') &&
            !get(user, `learner.currentOccupationMainLanguages`) &&
            !get(user, 'learner.workLiteracy') &&
            !get(user, 'learner.workLiteracyLevel') &&
            !get(user, 'learner.workLiteracyInfo') &&
            !get(user, 'learner.currentOccupationVocalInteraction') &&
            !get(user, 'learner.currentOccupationVocalInteractionQuality') &&
            !get(user, 'learner.currentOccupationVocalInteractionInfo')
        )
    }
}
