import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll, get } from 'lodash'
import { Mutator } from '~/utils'
import { translateType } from '~/shared/utils'
import { Link, DrawerModal, Subtle } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { ConceptGroupEmployeesForm } from '~/forms'

export default class GroupEmployeesFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        removeUsersMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = ['user-employee-required', 'user-employee-role-mismatch', 'user-employee-inactive']

    constructor(props) {
        super(props)
        bindAll(this, ['removeEmployees', 'getEditModal'])
    }

    async removeEmployees() {
        const { group, refetchGroup, removeUsersMutator } = this.props
        const { employeeUsers } = group

        const res = await removeUsersMutator.mutate({
            groupId: group._id,
            groupUsersToRemove: (employeeUsers || []).map(employeeUser => ({
                userId: employeeUser.user._id,
                role: 'EMPLOYEE',
            })),
        })

        if (res) {
            refetchGroup()
        }
    }

    render() {
        const { validationErrors } = this.props

        return (
            <GroupFieldGroup
                title={`TopTaal medewerkers`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeEmployees}
                removeMessage={`Je staat op het punt om alle TopTaal medewerkers uit deze groep te verwijderen. Weet je het zeker?`}
            >
                {this.renderEmployeesList()}
            </GroupFieldGroup>
        )
    }

    renderEmployeesList() {
        const { group } = this.props
        const { employeeUsers } = group

        return (
            <ul>
                {employeeUsers.map(employeeUser => (
                    <li key={employeeUser.user._id}>
                        <Link route={`/users/employees/` + employeeUser.user._id}>
                            {employeeUser.user.profile.name}
                        </Link>{' '}
                        <Subtle>
                            {(get(employeeUser, 'user.roles') || [])
                                .map(role => translateType('mutableRole', role))
                                .join(', ')}
                        </Subtle>
                    </li>
                ))}
            </ul>
        )
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                ,
                <ConceptGroupEmployeesForm
                    onSuccess={() => {
                        closeModal()
                        refetchGroup()
                    }}
                    onCancel={closeModal}
                    group={group}
                />
            </DrawerModal>
        )
    }
}
