import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import {
    ActionBar,
    Button,
    CenterModal,
    Icon,
    Link,
    PdfModal,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { CounterIcon } from '~/components/CounterIcon'
import { GroupSendInvitesModal } from '~/components/Group/GroupSendInvitesModal'
import { Label } from '~/components/Label/Label'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { AddGroupLessonForm } from '~/forms'
import translateType from '~/shared/utils/translateType'
import { Fetcher, Mutator } from '~/utils'
export default class EmployeeLessonsTable extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetch: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        const { _id } = props.group

        this.groupFetcher = new Fetcher({
            query: GET_GROUP_QUERY,
            variables: {
                filters: {
                    filterById: _id,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.removeLessonMutator = new Mutator({
            mutation: GET_REMOVE_LESSON_MUTATION,
            reactComponentToUpdate: this,
        })

        this.createLessonAttendanceFileMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_ATTENDANCE_FILE,
            reactComponentToUpdate: this,
        })

        this.createLessonTimeTableFileMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_TIMETABLE_FILE,
            reactComponentToUpdate: this,
        })
    }

    onRemoveLesson = lessonId => {
        this.removeLessonMutator.mutate({ lessonId }).then(res => {
            if (res) {
                this.groupFetcher.refetch()
            }
        })
    }

    getLessonTimetableFileId = async () => {
        const data = await this.createLessonTimeTableFileMutator.mutate({
            groupId: this.props.group._id,
        })

        if (data && data.users_createLessonTimetableFile) {
            return data.users_createLessonTimetableFile.fileId
        }
    }

    getLessonAttendanceFileId = async () => {
        const data = await this.createLessonAttendanceFileMutator.mutate({
            groupId: this.props.group._id,
        })

        if (data && data.users_createLessonAttendanceFile) {
            return data.users_createLessonAttendanceFile.fileId
        }
    }

    groupIsEnded = () => {
        const { data } = this.groupFetcher
        return !!get(data, 'groups[0].endedAt')
    }

    render() {
        return (
            <View>
                <Wrap full>{!this.groupIsEnded() && this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderLessonsTable()}</TableWrap>
            </View>
        )
    }

    renderActionBar() {
        const { group, refetch } = this.props

        const { data } = this.groupFetcher
        const extendedGroup = get(data, 'groups[0]')
        const isEndAllowed = !!(extendedGroup && extendedGroup.isEndAllowed)
        const canExportLetters = group.timetableLessons && group.timetableLessons.length > 0

        const amountOfInvitesLeft = get(data, 'groups[0].amountOfUnsentLessonInvites') || null

        return (
            <ActionBar
                getButtons={() => {
                    return (
                        <List horizontal>
                            <ListItem left>
                                <ModalManager
                                    render={openModal => {
                                        return (
                                            <Button leftIcon={<Icon name={`eye`} />} onClick={openModal}>
                                                Rooster PDF
                                            </Button>
                                        )
                                    }}
                                    getModal={onClose => {
                                        return (
                                            <PdfModal
                                                title={`Rooster van ${this.props.group.name}`}
                                                fileName={`${this.props.group.name}_les-rooster.pdf`}
                                                getFileId={this.getLessonTimetableFileId}
                                                onClose={onClose}
                                            />
                                        )
                                    }}
                                />
                            </ListItem>
                            <ListItem left>
                                <ModalManager
                                    render={openModal => {
                                        return (
                                            <Button leftIcon={<Icon name={`eye`} />} onClick={openModal}>
                                                Presentielijst PDF
                                            </Button>
                                        )
                                    }}
                                    getModal={onClose => {
                                        return (
                                            <PdfModal
                                                title={`Presentielijst van ${this.props.group.name}`}
                                                fileName={`${this.props.group.name}_les-presentie.pdf`}
                                                getFileId={this.getLessonAttendanceFileId}
                                                onClose={onClose}
                                            />
                                        )
                                    }}
                                />
                            </ListItem>
                            <ListItem left>
                                {group && (
                                    <ModalManager
                                        render={openModal => (
                                            <Button
                                                leftIcon={<Icon name={`mail`} />}
                                                rightIcon={
                                                    amountOfInvitesLeft && <CounterIcon amount={amountOfInvitesLeft} />
                                                }
                                                isDisabled={!canExportLetters}
                                                onClick={openModal}
                                            >
                                                Lesuitnodiging
                                            </Button>
                                        )}
                                        getModal={closeModal => {
                                            return (
                                                <GroupSendInvitesModal
                                                    closeModal={closeModal}
                                                    group={group}
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        this.props.refetch()
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                )}
                            </ListItem>

                            {/* <ListItem right>
                                <ModalManager
                                    render={openModal => (
                                        <Button styleOverride="submit" onClick={openModal} isDisabled={!isEndAllowed}>
                                            Groep afronden
                                        </Button>
                                    )}
                                    getModal={closeModal => {
                                        return (
                                            <DrawerModal onClose={closeModal} title="Groep afronden">
                                                <EndGroupForm
                                                    groupId={group._id}
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        refetch()
                                                    }}
                                                    onFormCancel={closeModal}
                                                />
                                            </DrawerModal>
                                        )
                                    }}
                                />
                            </ListItem> */}
                            <ListItem right>
                                <ModalManager
                                    render={openModal => (
                                        <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                                            Les toevoegen
                                        </Button>
                                    )}
                                    getModal={closeModal => {
                                        return (
                                            <CenterModal onClose={closeModal} title={`Les toevoegen`}>
                                                <AddGroupLessonForm
                                                    groupId={group._id}
                                                    onSubmitSuccess={lessonId =>
                                                        browserHistory.push(`/groups/${group._id}/lessons/${lessonId}`)
                                                    }
                                                    onCancel={closeModal}
                                                />
                                            </CenterModal>
                                        )
                                    }}
                                />
                            </ListItem>
                        </List>
                    )
                }}
            />
        )
    }

    renderLessonsTable() {
        const { data, loading } = this.groupFetcher
        const lessons = get(data, 'groups[0].lessons', [])
        const isEmpty = lessons.length === 0

        return (
            <TableView>
                <Table hasAutoLayout>
                    <TableHeader>
                        <TableHeaderItem sorter={this.sorter} sortBy={`name`} width="20%">
                            Naam
                        </TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`date`}>
                            Datum
                        </TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`date`}>
                            Locatie
                        </TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`teacher`}>
                            Docent
                        </TableHeaderItem>
                        <TableHeaderItem />
                    </TableHeader>
                    {loading && this.renderLoadingStateRow()}
                    {!loading && isEmpty && this.renderEmptyStateRow()}
                    {!loading && !isEmpty && this.renderLessonRows(lessons)}
                </Table>
            </TableView>
        )
    }

    renderLoadingStateRow() {
        return (
            <TableRow key={`loading`}>
                <TableCell colSpan={7} isLoading />
            </TableRow>
        )
    }

    renderEmptyStateRow() {
        return (
            <TableRow key={`emptyresult`}>
                <TableCell colSpan={7}>
                    <Subtle>Er zijn geen lessen gevonden.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    renderLessonRows(lessons) {
        const isEnded = this.groupIsEnded()

        return lessons.map(lesson => {
            return (
                <TableRow key={lesson._id}>
                    <TableCell hasInlineIcon>
                        <Link route={`/groups/${this.props.group._id}/lessons/${lesson._id}`}>
                            Les {lesson.order + 1}
                        </Link>
                        {lesson.specialActivity && (
                            <Label>{translateType('lessonSpecialActivity', lesson.specialActivity)}</Label>
                        )}
                        {lesson.areAllAttendancesSet && <Icon name={`status_done`} color={'#2bc572'} />}
                    </TableCell>
                    <TableCell>{lesson.date && <ReadableDate date={lesson.date} showTime showDayOfWeek />}</TableCell>
                    <TableCell>
                        {lesson.location && !lesson.room && (
                            <Link route={`/properties/locations/${get(lesson.location, '_id')}`}>
                                {get(lesson.location, 'name')}
                            </Link>
                        )}
                        {lesson.room && (
                            <Link
                                route={`/properties/locations/${get(lesson.room.location, '_id')}/rooms/${
                                    lesson.room._id
                                }`}
                            >
                                {lesson.room.name} te {get(lesson.room.location, 'name')}
                            </Link>
                        )}
                    </TableCell>
                    <TableCell>
                        {lesson.teacherUser && (
                            <Link route={`/users/teachers/${lesson.teacherUser._id}`}>
                                {get(lesson.teacherUser, 'profile.name')}
                            </Link>
                        )}
                    </TableCell>
                    <TableCell>
                        {!isEnded && (
                            <Button
                                className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                                type={`in-row`}
                                onClick={() => this.onRemoveLesson(lesson._id)}
                                style={{ float: 'right' }}
                                leftIcon={<Icon name={`trash`} />}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: `Je staat op het punt om "Les ${
                                        lesson.order + 1
                                    }" te verwijderen uit de groep "${
                                        this.props.group.name
                                    }". Hiermee gaan ook alle mogelijk aangegeven presenties en toetsresultaten in deze les verloren! Alle opvolgende lessen schuiven hiermee 1 plek omhoog.`,
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_GROUP_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            endedAt
            isEndAllowed
            amountOfUnsentLessonInvites
            lessons {
                _id
                date
                order
                areAllAttendancesSet
                specialActivity
                location {
                    _id
                    name
                }
                room {
                    _id
                    name
                    location {
                        _id
                        name
                    }
                }
                teacherUser {
                    _id
                    profile {
                        name
                    }
                }
            }
            latestInvites {
                _id
                user {
                    _id
                }
                date
                type
                groupInviteTemplate {
                    _id
                    name
                }
                invitedByUser {
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const GET_REMOVE_LESSON_MUTATION = gql`
    mutation _($lessonId: MongoID!) {
        lessons_delete(lessonId: $lessonId) {
            _id
        }
    }
`

const CREATE_LEARNERS_LESSON_ATTENDANCE_FILE = gql`
    mutation _($groupId: MongoID!) {
        users_createLessonAttendanceFile(groupId: $groupId) {
            fileId
        }
    }
`

const CREATE_LEARNERS_LESSON_TIMETABLE_FILE = gql`
    mutation _($groupId: MongoID!) {
        users_createLessonTimetableFile(groupId: $groupId) {
            fileId
        }
    }
`
