import * as React from 'react'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { LearnerContractLog, Contract } from '~/types/Contracts'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import Subtle from '~/components/Subtle'
import ReadableDate from '~/components/ReadableDate'
import { LearnerContractLogTypesMap } from '~/components/users/Learner/logs'

interface Props {
    logs: LearnerContractLog[]
    contract: Contract
}

export class LearnerContractsLogList extends React.Component<Props> {
    public render() {
        const { logs, contract } = this.props

        return (
            <SimpleTable>
                {logs.map((log, i) => {
                    const LogComponent = LearnerContractLogTypesMap[log.type]

                    // Hidden for the time being
                    if (log.type === 'contractSigned' || log.type === 'contractSignatureChanged') {
                        return null
                    }

                    return (
                        <SimpleTableRow key={i}>
                            <SimpleTableCell shouldPreventWrap={true}>
                                <Subtle>
                                    <ReadableDate date={log.createdAt} format={`DD-MM-YYYY`} />
                                </Subtle>
                            </SimpleTableCell>
                            <SimpleTableCell>
                                <LogComponent log={log} contract={contract} />
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )
                })}
            </SimpleTable>
        )
    }
}
