import React from 'react'
import { ValidationErrorType, InflowMomentFieldGroup } from './InflowMomentFieldGroup'
import { InflowMoment } from '~/types/InflowMoments'
import { Link, DrawerModal, Field } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import { InflowUserIntakerEmployeeForm } from '../Forms/InflowUserIntakerEmployeeForm'
import { Mutator } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { ValidationError } from '~/components/ValidationError'
import { getCurrentUser } from '~/services/session'
import Subtle from '~/components/Subtle'
import Emphasis from '~/components/Emphasis'

interface Props {
    validationErrors?: ValidationErrorType[]
    inflowMoment: InflowMoment
    refetch: () => void
    errors?: string[]
    hideRemove?: boolean
}

interface State {}

export class InflowMomentEmployeeUsersGroup extends React.Component<Props, State> {
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const currentUser = getCurrentUser()
        const { isOrganizationContact, isExternalTeacher } = currentUser
        const { validationErrors, inflowMoment, errors, hideRemove } = this.props

        const DynamicComponent = !isOrganizationContact && !isExternalTeacher ? Link : 'span'
        const { employeeUsers } = inflowMoment

        return (
            <InflowMomentFieldGroup
                title={`Medewerkers`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                inflowMoment={inflowMoment}
                onRemove={this.onRemove}
                removeMessage={
                    'Je staat op het punt de toetsmedewerker uit dit toetsmoment te verwijderen. Weet je het zeker?'
                }
                hideRemove={hideRemove}
            >
                <Field>
                    {employeeUsers && employeeUsers.length > 0 ? (
                        <List>
                            {employeeUsers.map(user => (
                                <ListItem key={user._id}>
                                    <DynamicComponent route={getUserDetailRoute(user)}>
                                        {user.profile.name}
                                    </DynamicComponent>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        this.renderEmptyState()
                    )}
                    {errors && errors.includes('all-employees-should-be-active') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`Niet alle geselecteerde toetsmedewerkers zijn actief.`}
                        />
                    )}
                </Field>
            </InflowMomentFieldGroup>
        )
    }

    private renderEmptyState = () => {
        return (
            <Subtle>
                <Emphasis>Geen</Emphasis>
            </Subtle>
        )
    }

    private getEditModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <InflowUserIntakerEmployeeForm inflowMoment={inflowMoment} refetch={refetch} closeModal={closeModal} />
            </DrawerModal>
        )
    }

    private onRemove = () => {
        const { refetch, inflowMoment } = this.props

        this.inflowMomentMutator
            .mutate({
                inflowMoment: {
                    _id: inflowMoment._id,
                    nulls: ['employeeUserIds'],
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                }
            })
    }
}
