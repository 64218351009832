import React from 'react'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { Field, MultiInput, Input } from '~/components'
import { TagPicker } from '~/components/TagPicker'
import { Address } from '~/types/User'
import { countryOptions } from '~/utils/countryOptions'

interface Props {
    className?: string
    errors: GraphQLErrors | undefined
    isLocked?: boolean
    location?: Address
    withoutCountry?: boolean
    title: string
    name: string
}

interface State {
    country?: string
    errored?: boolean
    zipcode?: string
    number?: string | number
    numberAddition?: string | undefined
    isEdited?: boolean
}

export class LocationFieldsInputGroup extends React.PureComponent<Props, State> {
    public state: State = {
        country: this.props.location && this.props.location.country ? this.props.location.country : 'NL',
        errored: true,
        isEdited: false,
        zipcode: this.props.location && this.props.location.nl ? this.props.location.nl.zipcode : undefined,
        number: this.props.location && this.props.location.nl ? this.props.location.nl.number : undefined,
        numberAddition:
            this.props.location && this.props.location.nl ? this.props.location.nl.numberAddition : undefined,
    }

    public render() {
        const { errors, isLocked, location, withoutCountry, title, name } = this.props
        const { country, errored } = this.state
        const isNetherlands = country === 'NL'
        const locationDataNL = location && location.nl

        return (
            <React.Fragment>
                <Field title={title} errors={errors}>
                    {!withoutCountry && (
                        <TagPicker
                            name={`${name}.country`}
                            multi={false}
                            options={countryOptions}
                            isDisabled={isLocked}
                            placeholder="Selecteer een land"
                            defaultValue={location && location.country !== null ? location.country : 'NL'}
                            onChange={country =>
                                this.setState({ country: country !== null ? country.value : undefined })
                            }
                        />
                    )}

                    {(withoutCountry || isNetherlands) && (
                        <>
                            <Input
                                name={`${name}.country`}
                                type="hidden"
                                isDisabled={true}
                                placeholder="Land"
                                defaultValue={'NL'}
                            />
                            <MultiInput type="address">
                                <Input
                                    name={`${name}.nl.zipcode`}
                                    type="text"
                                    isDisabled={isLocked}
                                    placeholder="Postcode"
                                    defaultValue={(locationDataNL && locationDataNL.zipcode) || null}
                                    onBlur={value =>
                                        this.setState({ zipcode: value.currentTarget.value, isEdited: true })
                                    }
                                />
                                <Input
                                    name={`${name}.nl.number`}
                                    type="number"
                                    isDisabled={isLocked}
                                    placeholder="Nummer"
                                    defaultValue={(locationDataNL && locationDataNL.number) || null}
                                    onBlur={value =>
                                        this.setState({ number: value.currentTarget.value, isEdited: true })
                                    }
                                />
                                <Input
                                    name={`${name}.nl.numberAddition`}
                                    type="text"
                                    isDisabled={isLocked}
                                    placeholder="Toev"
                                    defaultValue={(locationDataNL && locationDataNL.numberAddition) || null}
                                    onBlur={value =>
                                        this.setState({ numberAddition: value.currentTarget.value, isEdited: true })
                                    }
                                />
                            </MultiInput>
                        </>
                    )}

                    {/* TODO: enable this when postcodeAPI is implemented */}

                    {/* {this.showLocationBar(isNetherlands)} */}

                    {country && !withoutCountry && !isNetherlands && (
                        <>
                            <Input
                                name={`${name}.other.addressLine1`}
                                type="text"
                                isDisabled={isLocked}
                                defaultValue={(location && location.other && location.other.addressLine1) || null}
                                placeholder="Adresregel 1"
                            />
                            <Input
                                name={`${name}.other.addressLine2`}
                                type="text"
                                isDisabled={isLocked}
                                defaultValue={(location && location.other && location.other.addressLine2) || null}
                                placeholder="Adresregel 2"
                            />
                        </>
                    )}
                </Field>
                {errored && isNetherlands && (
                    <Field title={'Extra adresinformatie'} errors={errors}>
                        <>
                            <Input
                                name={`${name}.nl.manualExtraInfo.street`}
                                type="text"
                                isDisabled={isLocked}
                                placeholder="Straatnaam"
                                defaultValue={
                                    (locationDataNL &&
                                        locationDataNL.manualExtraInfo &&
                                        locationDataNL.manualExtraInfo.street) ||
                                    null
                                }
                            />
                            <Input
                                name={`${name}.nl.manualExtraInfo.city`}
                                type="text"
                                isDisabled={isLocked}
                                placeholder="Woonplaats"
                                defaultValue={
                                    (locationDataNL &&
                                        locationDataNL.manualExtraInfo &&
                                        locationDataNL.manualExtraInfo.city) ||
                                    null
                                }
                            />
                            <Input
                                name={`${name}.nl.manualExtraInfo.municipality`}
                                type="text"
                                isDisabled={isLocked}
                                placeholder="Gemeente"
                                defaultValue={
                                    (locationDataNL &&
                                        locationDataNL.manualExtraInfo &&
                                        locationDataNL.manualExtraInfo.municipality) ||
                                    null
                                }
                            />
                        </>
                    </Field>
                )}
            </React.Fragment>
        )
    }

    // private showLocationBar(isNetherlands: boolean | undefined): any {
    //     const { withoutCountry, name } = this.props
    //     const { zipcode, number: locationNumber, numberAddition } = this.state

    //     if (!zipcode || !locationNumber) {
    //         return null
    //     }

    //     if ((withoutCountry || isNetherlands) && zipcode && locationNumber) {
    //         return <LocationFieldsInputContainer name={name} zipcode={zipcode} number={locationNumber} numberAddition={numberAddition}/>
    //     }

    //     return null
    // }
}
