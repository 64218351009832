import React from 'react'
import { Button } from '~/components'
import { CenterView } from '~/components/CenterView'
import Icon from './Icon'
import Modal from './Modal'
import c from 'classnames'

export interface CenterModalProps {
    className?: string
    onClose?: () => void
    title?: any
    loading?: boolean
    small?: boolean
    medium?: boolean
    enableEasyClose?: boolean
}

export default class CenterModal extends React.Component<CenterModalProps> {
    public render() {
        const {
            children,
            className: extraClassNames,
            title,
            onClose,
            loading,
            small,
            medium,
            enableEasyClose,
        } = this.props

        const className = c('tt-CenterModal', extraClassNames, {
            'tt-CenterModal--is-loading': loading,
            'tt-CenterModal--is-small-modal': small,
            'tt-CenterModal--is-medium-modal': medium,
        })

        return (
            <Modal onClose={onClose} enableEasyClose={enableEasyClose}>
                <CenterView className={className}>
                    <div className={`tt-CenterModal__window`}>
                        <div className={`tt-CenterModal__window__bar`}>
                            {title && <h3 className={`tt-CenterModal__window__bar__title`}>{title}</h3>}
                            {onClose && (
                                <Button
                                    className={`tt-CenterModal__window__bar__button`}
                                    onClick={this.onClick}
                                    leftIcon={<Icon name={`times`} />}
                                />
                            )}
                        </div>
                        <div className={`tt-CenterModal__window__content`}>{children}</div>
                    </div>
                </CenterView>
            </Modal>
        )
    }

    private onClick = () => {
        const { onClose } = this.props

        if (onClose) {
            onClose()
        }
    }
}
