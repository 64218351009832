import './InviteTextViewer.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ButtonGroup } from '~/components/ButtonGroup'
import { Button } from '~/components'
import { InviteTextVariableLabel } from '../InviteTextVariableLabel/InviteTextVariableLabel'

interface Props {
    className?: ClassValue
    text?: string
    textEnglish?: string
    hideSwitch?: boolean
    showBorder?: boolean
    textVariables?: { label: string; value: string }[]
}

interface State {
    englishActive: boolean
}

export class InviteTextViewer extends React.Component<Props, State> {
    public state: State = {
        englishActive: this.props.text ? false : true,
    }

    private bem = new BEM('InviteTextViewer', () => ({
        border: this.props.showBorder,
    }))

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { className, text, textEnglish, hideSwitch } = this.props
        const { englishActive } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                {!hideSwitch && (
                    <ButtonGroup className={this.bem.getElement('language-switch')} joined={true}>
                        {(this.props.text || this.props.textEnglish) && (
                            <>
                                <Button
                                    type="button"
                                    styleOverride={englishActive ? 'looks-disabled' : undefined}
                                    onClick={this.onLanguageButtonClick}
                                >
                                    Nederlands
                                </Button>
                                <Button
                                    type="button"
                                    styleOverride={!englishActive ? 'looks-disabled' : undefined}
                                    onClick={this.onLanguageButtonClick}
                                >
                                    Engels
                                </Button>
                            </>
                        )}
                    </ButtonGroup>
                )}
                <div className={this.bem.getElement('text')}>{this.renderText(englishActive ? textEnglish : text)}</div>
            </div>
        )
    }

    private onLanguageButtonClick = () => {
        this.setState({
            englishActive: !this.state.englishActive,
        })
    }

    private renderText(text?: string) {
        const { textVariables } = this.props

        if (!text) {
            return ''
        }

        if (!textVariables) {
            return text
        }

        const textKeys = text.match(/[^[\]]+(?=])/g)

        if (!textKeys) {
            return text
        }

        // Convert the lines so the regex won't remove linebreaks and spaces
        const textLines = text.trim().replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')

        const splitText = textLines.split(/\s*\[([^\]]*)]\s*/).filter(Boolean)

        return splitText.map((value: string, i: number) => {
            const convertedValue = value.replace(/<br ?\/?>/g, '\n').replace(/&nbsp;/g, ' ')
            const textVariable = textVariables.find(variable => variable.value === convertedValue)

            if (textVariable) {
                return (
                    <InviteTextVariableLabel className={this.bem.getElement('variable')} key={`${i}-text-key`}>
                        {textVariable.label}
                    </InviteTextVariableLabel>
                )
            }

            if (textKeys.includes(convertedValue)) {
                return <span key={`${i}-text-key`}>[{convertedValue}]</span>
            }

            return <span key={`${i}-text-key`}>{convertedValue}</span>
        })
    }
}
