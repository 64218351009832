import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { TagPicker } from '../TagPicker'
import { gql } from '@apollo/client'
import { Fetcher } from '~/utils'
import { Organization } from '~/types/Organization'

const GET_ORGANIZATIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`

interface Props {
    className?: ClassValue
    name: string
    defaultValue?: string
}

interface State {}

export class OrganizationsTagPicker extends React.Component<Props, State> {
    private bem = new BEM('OrganizationsTagPicker')

    private organizationsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.organizationsFetcher = new Fetcher({
            query: GET_ORGANIZATIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { className, name, defaultValue } = this.props
        const { data } = this.organizationsFetcher
        const { organizations = [] } = data

        const options = organizations.map((Organization: Organization) => ({
            value: Organization._id,
            label: Organization.name,
        }))

        return (
            <TagPicker
                className={this.bem.getClassName(className)}
                name={name}
                options={options}
                defaultValue={defaultValue}
                multi={false}
            />
        )
    }
}
