import React from 'react'

import { InflowMoment } from '~/types/InflowMoments'
import { List } from '../List'
import { ListItem } from '../ListItem'
import { ConceptInflowMomentKeys } from '~/views/App/InflowMoments/Concepts/Detail/Data'
import { ModalManager } from '../ModalManager'
import { Button, Icon, CenterModal, DrawerModal } from '..'
import { InflowLocationForm } from './Forms/InflowLocationForm'
import { InflowUserIntakersForm } from './Forms/InflowUserIntakersForm'
import { InflowUserIntakerEmployeeForm } from './Forms/InflowUserIntakerEmployeeForm'
import { InflowDateForm } from './Forms/InflowDateForm'

interface Props {
    inflowMoment: InflowMoment
    refetchInflowMoment: () => void
    showButtonsOfKeys: ConceptInflowMomentKeys[]
}

interface State {}

export class InflowMomentAddButtons extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { showButtonsOfKeys, inflowMoment } = this.props

        return (
            <List horizontal={true} forButtons={true}>
                {inflowMoment &&
                    showButtonsOfKeys.map(key => {
                        return <ListItem key={key}>{this[`get${key}Button`]()}</ListItem>
                    })}
            </List>
        )
    }

    public getLocationButton() {
        const { inflowMoment, refetchInflowMoment } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Locatie
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Locatie toevoegen`}>
                            <InflowLocationForm
                                inflowMoment={inflowMoment}
                                onClose={closeModal}
                                refetch={refetchInflowMoment}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }

    public getDateRangeButton() {
        const { inflowMoment, refetchInflowMoment } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Datum
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Datum toevoegen`}>
                            <InflowDateForm
                                inflowMoment={inflowMoment}
                                refetch={refetchInflowMoment}
                                onClose={closeModal}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }

    public getEmployeeUsersButton() {
        const { inflowMoment, refetchInflowMoment } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Toetsmedewerkers
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <InflowUserIntakerEmployeeForm
                                inflowMoment={inflowMoment}
                                refetch={refetchInflowMoment}
                                closeModal={closeModal}
                            />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    public getIntakerUsersButton() {
        const { inflowMoment, refetchInflowMoment } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Toetsdocenten
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <InflowUserIntakersForm
                                inflowMoment={inflowMoment}
                                refetch={refetchInflowMoment}
                                closeModal={closeModal}
                            />
                        </DrawerModal>
                    )
                }}
            />
        )
    }
}
