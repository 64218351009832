import './VariableMultiFieldBlock.scss'
import c from 'classnames'
import * as React from 'react'

import { Field } from '~/components'
import { times } from 'lodash'
import { VariableMultiFieldBlockAddButton } from './VariableMultiFieldBlockAddButton'

interface Props {
    baseAmount: number
    title?: string
    isDisabled?: boolean
    renderFieldGroup: (index: number, remove: () => void, initialindex: number) => React.ReactNode
    addButtonLabel?: string
    className?: string
    limit?: number | boolean
    isInset: boolean
    onAddAction?: () => void
    onRemoveAction?: (indexToRemove: number) => void
}

interface State {
    initialIndices: number[]
}

export class VariableMultiFieldBlock extends React.Component<Props, State> {
    public static defaultProps = {
        baseAmount: 1,
        limit: false,
        addButtonLabel: 'Toevoegen',
        isInset: false,
    }

    public state: State = {
        initialIndices: times(this.props.baseAmount),
    }

    private lastUsedInitialIndex: number = this.props.baseAmount - 1

    public render() {
        const { renderFieldGroup, addButtonLabel, isDisabled } = this.props
        const { initialIndices } = this.state
        return (
            <div className={this.getClassName()}>
                {initialIndices.map((initialIndex, index) => {
                    const remove = () => this.onRemove(index)

                    return <div key={initialIndex}>{renderFieldGroup(index, remove, initialIndex)}</div>
                })}
                <Field title={false}>
                    <VariableMultiFieldBlockAddButton
                        addButtonLabel={addButtonLabel}
                        onAdd={() => this.onAdd()}
                        isDisabled={isDisabled}
                    />
                </Field>
            </div>
        )
    }

    private onAdd = () => {
        const { onAddAction } = this.props

        this.setState(prevState => ({
            initialIndices: [...prevState.initialIndices, ++this.lastUsedInitialIndex],
        }))

        if (onAddAction) {
            onAddAction()
        }
    }

    private onRemove = (indexToRemove: number) => {
        const { onRemoveAction } = this.props
        const { initialIndices } = this.state

        if (initialIndices.length === 0) {
            return
        }

        this.setState({
            initialIndices: initialIndices.filter((initialIndex, index) => index !== indexToRemove),
        })

        if (onRemoveAction) {
            onRemoveAction(indexToRemove)
        }
    }

    private getClassName = () => {
        const { className, isInset } = this.props

        return c('tt-VariableMultiFieldBlock', className, isInset && 'tt-VariableMultiFieldBlock--is-inset')
    }
}
