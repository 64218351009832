import { set, get, isPlainObject } from 'lodash'
const check = require('powercheck/Throw')

class HistoryStore {
    /**
     * Set a value to remember in the current state
     *
     * @param {String} key
     * @param {*} value
     */
    set(key: any, value: any) {
        check(key, String)
        const state = this._getState()

        // If the value is an object with only `undefined` values
        // set the value to `undefined`.
        if (isPlainObject(value) && this._isObjectPracticallyEmpty(value)) {
            set(state, key, undefined)
        } else {
            set(state, key, value)
        }

        this._setState(state)
    }

    /**
     * Get a value from the state
     *
     * @param {String} key
     * @param {*} value
     */
    get(key: any) {
        check(key, String)

        const state = this._getState()
        return get(state, key)
    }

    /**
     * @private
     */
    _getState() {
        return window.history.state || {}
    }

    /**
     * @private
     */
    _setState(state: any) {
        return window.history.replaceState(state, '')
    }

    /**
     * @private
     */
    _isObjectPracticallyEmpty(obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any }) {
        for (const key in obj) {
            if (!obj.hasOwnProperty(key)) continue

            if (typeof obj[key] !== 'undefined') {
                return false
            }
        }

        return true
    }
}

/**
 * Utility to remember information in the current browser state.
 *
 * @instance
 */
export default new HistoryStore()
