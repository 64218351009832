export class GraphQLErrors {
    constructor(errors) {
        this._errors = errors || []
    }

    getErrors() {
        return this._errors
    }

    hasErrors() {
        return this._errors.length > 0
    }

    hasUnconsumedErrors() {
        return this.getUnconsumedErrors().length > 0
    }

    getErrorsFromNamespace(namespace) {
        const errors = this._errors
            .filter(e => !!e.field)
            .map(e => ({
                ...e,
                fieldParts: e.field.split('.'),
            }))
            .filter(e => e.fieldParts[0] === namespace)
            .map(e => ({
                field: e.fieldParts.splice(1).join('.'),
                message: e.message,
            }))

        // Return new instance
        return new GraphQLErrors(errors)
    }

    consumeErrorsForField(field) {
        const consumedErrors = []

        for (const error of this._errors) {
            if (error.field === field) {
                error.isConsumed = true
                consumedErrors.push(error)
            }
        }

        return consumedErrors
    }

    getUnconsumedErrors() {
        return this._errors.filter(e => !e.isConsumed)
    }
}
