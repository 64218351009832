import './HeaderTitle.scss'

import * as React from 'react'
import { BEM } from '~/services/BEMService'
import Spinner from '~/components/Spinner'

interface Props {
    title: React.ReactNode
    isLoading?: boolean
}

export class HeaderTitle extends React.Component<Props> {
    private bem = new BEM('HeaderTitle', () => ({
        'is-loading': this.props.isLoading,
    }))

    public render() {
        const { title, isLoading } = this.props

        return (
            <h2 className={this.bem.getClassName()}>
                {!isLoading && title}
                {isLoading && <Spinner size="small" color="#2D3740" />}
            </h2>
        )
    }
}
