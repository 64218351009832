import './LearnerGroupParticipationHistoryDisenrollReason.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import translateType from '~/shared/utils/translateType'

interface Props {
    reason: string
    info?: string
    className?: string
}

export class LearnerGroupParticipationHistoryDisenrollReason extends React.PureComponent<Props> {
    private bem = new BEM('LearnerGroupParticipationHistoryDisenrollReason')

    public render() {
        const { reason, info, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <p className={this.bem.getElement('reason')}>
                    {translateType('groupParticipationDisenrollmentReason', reason)}
                </p>
                {!!info && <p>{info}</p>}
            </div>
        )
    }
}
