import React from 'react'
import { compact } from 'lodash'
import { translateCountry } from '~/utils'
import { Address } from '~/types/User'

interface Props {
    address?: Address
    showCountry?: boolean
}

export default class ReadableAddress extends React.Component<Props> {
    public static defaultProps = {
        showCountry: false,
    }

    public render() {
        const { address, showCountry } = this.props

        if (!address) {
            return <span />
        }

        const countryCode = address.country

        if (address.country === 'NL' && address.nl) {
            // TODO: set addresstype when postcode API is implemented
            const { nl } = address
            const addressType = nl.extraInfo
            if (!addressType) {
                return <span />
            }
            const street = addressType.street !== null ? addressType.street : ''
            const streetNumber = nl.number !== null ? nl.number : ''
            const streetNumberAddition = nl.numberAddition !== null ? nl.numberAddition : ''
            const zipcode = nl.zipcode !== null ? nl.zipcode : ''
            const city = addressType.city !== null ? addressType.city : ''

            return (
                <span>
                    {compact([
                        // tslint:disable-next-line: max-line-length
                        `${street} ${streetNumber}${streetNumberAddition ? `-${streetNumberAddition}` : ''}`,
                        zipcode,
                        city,
                        showCountry && countryCode && `${translateCountry(countryCode)}`,
                    ]).join(', ')}
                </span>
            )
        } else {
            const addressOther = address.other
            if (!addressOther) {
                return <span />
            }

            const address1 = addressOther.addressLine1
            const address2 = addressOther.addressLine2
            return (
                <span>
                    {compact([
                        address1,
                        address2,
                        showCountry && countryCode && `${translateCountry(countryCode)}`,
                    ]).join(', ')}
                </span>
            )
        }
    }
}
