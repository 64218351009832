import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindAll } from 'lodash'
import { Mutator } from '~/utils'
import { DrawerModal, Subtle } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { SelectStartWeekForm } from '~/forms'

export default class GroupStartWeekDateFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        groupEditMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = ['startweek-required']

    constructor(props) {
        super(props)
        bindAll(this, ['removeStartWeekDate', 'getEditModal'])
    }

    async removeStartWeekDate() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                nulls: ['startWeekDate'],
            },
        })

        refetchGroup()
    }

    render() {
        const { group, validationErrors } = this.props
        const { startWeekDate } = group

        const endDate = group.dateTo && moment(group.dateTo)
        const startWeekMoment = moment(startWeekDate).utc()

        return (
            <GroupFieldGroup
                title="Startweek"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeStartWeekDate}
                removeMessage={`Je staat op het punt de startweek uit deze groep te verwijderen. Weet je het zeker?`}
            >
                Week {startWeekMoment.week()} ({startWeekMoment.startOf('week').format('DD-MM-YYYY')} -{' '}
                {startWeekMoment.endOf('week').format('DD-MM-YYYY')})<br />
                {endDate && (
                    <Subtle>
                        Groep zal eindigen in week {endDate.week()}, {endDate.year()}
                    </Subtle>
                )}
            </GroupFieldGroup>
        )
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <SelectStartWeekForm closeModal={closeModal} refetch={refetchGroup} group={group} />
            </DrawerModal>
        )
    }
}
