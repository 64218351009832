import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { InvoiceLink } from '~/implementations'

import {
    Button,
    CenterModal,
    Currency,
    FieldCollection,
    Field,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    ReadableDate,
    Link,
} from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'

export default class ShowDUOResponseDetailsModal extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        title: PropTypes.string,
        invoicesProcessedCount: PropTypes.number,
        invoicesNeedActionCount: PropTypes.number,
        invoicesNeedActionResults: PropTypes.any,
    }

    render() {
        const { onClose, title, invoicesProcessedCount, invoicesNeedActionCount, invoicesNeedActionResults } =
            this.props

        const totalCount = invoicesProcessedCount + invoicesNeedActionCount

        return (
            <CenterModal onClose={onClose} title={title} className={`tt-DUOResponseDetailsModal`}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>
                            {`Er zijn ${totalCount} facturen, waarvan ${invoicesNeedActionCount} facturen actie vereisen. Deze zijn te zien in de volgende tabel.`}
                        </Field>
                        <TableView>
                            <Table hasAutoLayout={true}>
                                <TableHeader>
                                    <TableHeaderItem>Factuurnummer</TableHeaderItem>
                                    <TableHeaderItem>Factuurdatum</TableHeaderItem>
                                    <TableHeaderItem>Debiteur</TableHeaderItem>
                                    <TableHeaderItem containsNumber>Bedrag</TableHeaderItem>
                                    <TableHeaderItem>Fout</TableHeaderItem>
                                </TableHeader>
                                {invoicesNeedActionResults.length > 0 ? (
                                    this.renderDUOResponsesRows(invoicesNeedActionResults)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={5}>
                                            <Subtle>Er zijn geen foutieve responses.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right={true}>
                                <Button onClick={onClose}>Sluiten</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </CenterModal>
        )
    }

    transformInvoiceResponse = response => {
        const DUOCodes = {
            // Legacy stuff in comments
            // F000: 'Recordindelingfoutief',
            // F001: 'BOW nummer is niet bekend',
            // F002: 'BS nummer onjuist',
            // F003: 'Geboortedatum foutief',
            // F004: 'Geslacht foutief',
            // F005: 'Factuurdatum foutief',
            // F006: 'Factuurbedrag foutief',
            // F007: 'Factuurnummer niet bekend',
            // F008: 'Restitutiebedrag groter dan factuurbedrag',
            // F009: 'BS nummer nietbekend in ISI',
            // F011: 'Persoon is niet leengerechtigd',
            // F012: 'Persoon heeft geen lening',
            // F013: 'Factuurbedrag in kwartaal hoger dan e1250',
            // F015: 'Geen leenruimte meer',
            // F016: 'Wijziging van reeds betaaldefactuur niet toegestaan',
            // F999: 'Origineel factuurnummer ontbreekt bij restitutie',
            // S019: 'In afwachting van DigID goedkeuring',
            // S020: 'Factuur gewijzigd',
            // S021: 'Oninbaar',
            // S022: 'Restitutie verwerkt',
            // S023: 'Factuur is vrijgegeven voor betaling',
            // S024: 'Factuur is (gedeeltelijk) betaald',
            // S025: 'Lening aanvraag nog in behandeling',
            // S026: 'Verrekend op originele factuur',
            F000: 'Facturen niet conform afgesproken formaat',
            F001: 'BoW nummer is niet bekend',
            F027: 'BoW nummer wijkt af van het bestand',
            F002: 'BSN onjuist',
            F026: 'Soort cursus onjuist',
            F028: 'Inschrijving bij factuur onbekend',
            F003: 'Geboortedatum foutief',
            F004: 'Geslacht foutief',
            F009: 'Combinatie BSN, geboortedatum en geslacht onjuist',
            F025: 'Factuurnummer ongeldig',
            F005: 'Factuurdatum foutief',
            F029: 'Factuurdatum mag niet in de toekomst liggen',
            F030: 'Periode is niet afgerond',
            F031: 'Factuurperiode foutief',
            F032: 'Factuurperiode ligt buiten de contractperiode',
            F033: 'Factuurperiode ligt buiten de contractperiode',
            F006: 'Factuurbedrag foutief',
            F034: 'Bedrag cursuskosten foutief',
            F035: 'Bedrag lesmateriaal foutief',
            F036: 'Bedrag examenkosten foutief',
            F037: 'Factuur is niet gespecificeerd',
            F038: 'Factuurbedrag foutief',
            F039: 'Aantal cursusuren onjuist',
            F040: 'Cursuskosten te hoog bij uurtarief',
            F041: 'Origineel factuurnummer ongeldig',
            F042: 'Origineel factuurnummer ontbreekt bij restitutie',
            F013: 'Factuurbedrag in kwartaal hoger dan € 1250',
            F043: 'Totaalbedrag cursuskosten inschrijving overschreden',
            F044: 'Contracturen overschreden',
            F045: 'Totaalbedrag materiaalkosten inschrijving overschreden',
            F046: 'Aantal cursusuren niet gevuld',
            F007: 'Factuurnummer is niet bekend',
            F008: 'Restitutiebedrag groter dan factuurbedrag',
            F018: 'Restitutie op niet betaalde factuur is niet toegestaan',
            F016: 'Wijziging van reeds betaalde factuur niet toegestaan',
            F017: 'Intrekken reeds betaalde factuur niet toegestaan',
            F047: 'Factuurnummer eerder of dubbel aangeleverd',
            F011: 'Persoon is niet leengerechtigd',
            F012: 'Persoon heeft geen lening',
            F014: 'Indieningstermijn is verstreken',
            F015: 'Geen leenruimte meer',
            F048: '<Reden weigering door klant>',
            F999: '<Reden weigering factuur>',
        }

        return get(DUOCodes, response) || 'Onbekende fout'
    }

    renderDUOResponsesRows(invoicesNeedActionResults) {
        return invoicesNeedActionResults.map((result, i) => {
            const invoice = result.invoice

            return (
                <TableRow key={i}>
                    <TableCell>
                        <InvoiceLink invoice={invoice} />
                    </TableCell>
                    <TableCell>
                        <ReadableDate format={`DD/MM/YYYY`} date={invoice.invoiceDate} />
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/learners/${invoice.user._id}/invoicing`}>
                            {get(invoice.user.profile, 'name')}
                        </Link>
                    </TableCell>
                    <TableCell containsNumber>
                        <Currency amount={invoice.isCredit ? -invoice.amount : invoice.amount} />
                    </TableCell>
                    <TableCell>{this.transformInvoiceResponse(result.response)}</TableCell>
                </TableRow>
            )
        })
    }
}
