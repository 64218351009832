import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerProfileData } from '~/implementations'

export default class DataView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        currentUser: PropTypes.object,
        refetch: PropTypes.func,
        isViewedByTeacherOfUser: PropTypes.bool,
    }

    render() {
        const { userId } = this.props

        return (
            <ContentView hasFixedActionBar>
                <LearnerProfileData learnerUserId={userId} />
            </ContentView>
        )
    }
}
