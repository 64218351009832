import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindAll, capitalize } from 'lodash'
import is from 'powercheck'
import { Mutator } from '~/utils'
import { CenterModal, Subtle } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { EditGroupScheduleForm } from '~/forms'

export default class GroupLessonTeachersFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        groupEditMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = [
        'startdate-required',
        'general-week-lesson-teachers-required',
        'general-week-lesson-teachers-must-be-part-of-group',
    ]

    constructor(props) {
        super(props)
        bindAll(this, ['removeGeneralWeekSchedule', 'getEditModal'])
    }

    async removeGeneralWeekSchedule() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                nulls: ['generalWeekLessonTeacherUserIds', 'startDateIndex'],
            },
        })

        refetchGroup()
    }

    render() {
        const { validationErrors } = this.props
        const datesList = this.getLessonDatesList()

        return (
            <GroupFieldGroup
                title="Roostering"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeGeneralWeekSchedule}
                removeMessage={`Je staat op het punt de roostering uit deze groep te verwijderen. Weet je het zeker?`}
            >
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Startdag</SimpleTableCell>
                        <SimpleTableCell>{this.getStartDate() || <Subtle>Nog niet ingesteld</Subtle>}</SimpleTableCell>
                    </SimpleTableRow>
                    {datesList &&
                        datesList.map(({ label, value }, index) => (
                            <SimpleTableRow key={index}>
                                <SimpleTableCell isBold>{label}</SimpleTableCell>
                                <SimpleTableCell>{value}</SimpleTableCell>
                            </SimpleTableRow>
                        ))}
                </SimpleTable>
            </GroupFieldGroup>
        )
    }

    getStartDate() {
        const { group } = this.props
        const { startDateIndex, generalWeekLessonDates } = group
        if (!is(startDateIndex, Number) || !generalWeekLessonDates || !generalWeekLessonDates[startDateIndex]) {
            return
        }

        return capitalize(moment(generalWeekLessonDates[startDateIndex]).format('dddd HH:mm'))
    }

    getLessonDatesList() {
        const { group } = this.props
        const { generalWeekLessonDates } = group

        if (!generalWeekLessonDates || generalWeekLessonDates.length === 0) {
            return
        }

        const dates = generalWeekLessonDates.map(d => new Date(d))

        return dates.map((date, index) => ({
            label: this.renderLessonDate(date),
            value: (
                <span>
                    {this.getTeacherName(index) || <Subtle>Nog geen docent toegewezen</Subtle>}
                    <br />
                    {this.getRoomName(index) || <Subtle>Nog geen lesruimte toegewezen</Subtle>}
                </span>
            ),
        }))
    }

    renderLessonDate(date) {
        const { group } = this.props
        const { module } = group

        const fromMoment = moment(date)
        const toMoment = module && module.lessonDuration && moment(date).add(module.lessonDuration, 'minutes')

        return `${fromMoment.format('dd HH:mm').toLowerCase()}${toMoment ? ` - ${toMoment.format('HH:mm')}` : ``}`
    }

    getTeacherName(index) {
        const { group } = this.props
        const { generalWeekLessonTeacherUsers } = group
        const teacherUser = generalWeekLessonTeacherUsers && generalWeekLessonTeacherUsers[index]
        return teacherUser && teacherUser.profile.name
    }

    getRoomName(index) {
        const { group } = this.props
        const { generalWeekLessonRooms } = group
        const room = generalWeekLessonRooms && generalWeekLessonRooms[index]
        return room && `Lesruimte: ${room.name}`
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Roostering bewerken`}>
                <EditGroupScheduleForm
                    type={`modal`}
                    onSubmitSuccess={() => {
                        closeModal()
                        refetchGroup()
                    }}
                    onFormCancel={closeModal}
                    group={group}
                />
            </CenterModal>
        )
    }
}
