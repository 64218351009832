import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import DatePicker from '~/components/DatePicker'
import Input from '~/components/Input'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components'
import FileInput from '~/components/FileInput'
const moment = require('moment')

export interface ObligedCitizenshipEducationEndDateExtensionFormInputFields {
    date: string
    description?: string | null
    document?: any
}

export interface DefaultObligedCitizenshipEducationEndDateExtensionFormValue {
    date?: string | null
    description?: string | null
    document?: any
    fileToUploadName?: string | null
}

interface Props {
    defaultValue?: DefaultObligedCitizenshipEducationEndDateExtensionFormValue
    minimumExtensionDate?: string | null
    onAddDate?: (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => void
    onCancel: () => void
    onEditDate?: (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => void
    onRemoveExtension?: () => void
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export class ObligedCitizenshipEducationEndDateExtensionForm extends React.Component<Props> {
    public render() {
        const { defaultValue, minimumExtensionDate } = this.props

        const minimumExtensionDatePlusOne = moment(minimumExtensionDate).add(1, 'days').toISOString()

        const defaultDateValue = defaultValue ? defaultValue.date : null

        const defaultDescriptionValue = defaultValue ? defaultValue.description : null

        const defaultFileNameValue = defaultValue
            ? defaultValue.fileToUploadName
                ? defaultValue.fileToUploadName
                : defaultValue.document && defaultValue.document.fileName
                ? defaultValue.document.fileName
                : null
            : null

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Nieuwe einddatum`}>
                            <DatePicker
                                name={`date`}
                                defaultValue={defaultDateValue}
                                min={minimumExtensionDatePlusOne}
                            />
                        </Field>
                        <Field isLabel={true} title={'Toelichting'}>
                            <Input
                                name={`description`}
                                defaultValue={defaultDescriptionValue}
                                type={`text`}
                                placeholder={`Voer een toelichting in voor de verlenging`}
                            />
                        </Field>
                        <Field isLabel={true} title={`Upload bestand`}>
                            <FileInput
                                name={`document`}
                                accept={`application/pdf`}
                                multiple={false}
                                defaultSelectedFileName={defaultFileNameValue}
                            >
                                Kies bestand
                            </FileInput>
                        </Field>
                    </FieldGroup>
                    {this.renderFieldCollectionFooter()}
                </FieldCollection>
            </Form>
        )
    }

    private renderFieldCollectionFooter = (): React.ReactNode => {
        const { onCancel, onEditDate, onRemoveExtension } = this.props
        const typeIsEdit = !!onEditDate

        return (
            <FieldCollectionFooter>
                <List horizontal={true}>
                    {typeIsEdit && (
                        <ListItem>
                            <Button linkStyle={`danger`} onClick={onRemoveExtension}>
                                Verwijderen
                            </Button>
                        </ListItem>
                    )}
                    <ListItem right={true}>
                        <Button onClick={onCancel}>Annuleren</Button>
                    </ListItem>
                    <ListItem right={true}>
                        <Button type={`submit`} styleOverride={`edit`}>
                            {!typeIsEdit && 'Toevoegen'}
                            {typeIsEdit && 'Bewerken'}
                        </Button>
                    </ListItem>
                </List>
            </FieldCollectionFooter>
        )
    }

    private onSubmit = (
        event: React.FormEvent<HTMLFormElement>,
        fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields
    ) => {
        const { onAddDate, onSubmit, onEditDate } = this.props

        onSubmit(event)

        if (onAddDate) {
            onAddDate(fields)
        }

        if (onEditDate) {
            onEditDate(fields)
        }
    }
}
