const check = require('powercheck/Throw')

interface sortArgumentsInterface {
    sortBy: any
    onSort: any
}

export default class Sorter {
    _sortEndHooks: any = []
    private _onSort: any
    private _sortObj: { sortBy: any; sortDir: string | undefined }

    constructor(args: sortArgumentsInterface) {
        check(args.onSort, Function)

        this._onSort = args.onSort
        this._sortObj = {
            sortBy: args.sortBy,
            sortDir: args.sortBy ? 'ASC' : undefined,
        }
    }

    sort(sortBy: any) {
        let sortDir = 'ASC'

        if (this._sortObj.sortBy === sortBy && this._sortObj.sortDir === 'ASC') {
            sortDir = 'DESC'
        }

        this._sortObj = { sortBy, sortDir }
        this._onSort(this._sortObj)

        this._sortEndHooks.forEach((hook: any) => hook(this._sortObj))
    }

    getDefaultOrder(checkSortBy: any) {
        const { sortBy, sortDir } = this._sortObj
        if (sortBy === checkSortBy) return sortDir
        return undefined
    }

    onSortEnd(hook: any) {
        this._sortEndHooks.push(hook)
    }

    offSortEnd(hook: any) {
        const index = this._sortEndHooks.indexOf(hook)
        this._sortEndHooks.splice(index, 1)
    }

    getCurrentSortBy() {
        return this._sortObj.sortBy
    }
}
