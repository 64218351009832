import * as React from 'react'

import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'

interface Props {
    children: any
    className?: string
}

export class SubNav extends React.Component<Props> {
    private bem = new BEM('SubNav')

    public render() {
        const { children, className } = this.props

        return (
            <div className={this.bem.getElement('wrapper')}>
                {/* <div className={this.bem.getElement('placeholder')}>
                    &nbsp;
                </div> */}
                <List className={this.bem.getClassName(className)}>{children}</List>
            </div>
        )
    }
}
