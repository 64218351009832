import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{}, {}> {}

interface State {
    settingIgnoreOrganizationContactModal: boolean
}

export default class ExternalOrganizationContactLearnersView extends React.Component<Props, State> {
    public state: State = {
        settingIgnoreOrganizationContactModal: false,
    }

    public render() {
        const { children, ...routeProps } = this.props

        return (
            <BreadCrumbProvider>
                <RouteView crumbLabel={'Kandidaten'} routeProps={routeProps}>
                    {children}
                </RouteView>
            </BreadCrumbProvider>
        )
    }
}
