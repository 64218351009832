import { gql } from '@apollo/client'
import React from 'react'
import { LessonInvitesForm } from '~/forms/LessonInvitesForm'
import { Group } from '~/types/Group'
import { GroupInviteTemplate } from '~/types/groupInviteTemplate'
import { Mutator, toast } from '~/utils'

import { CenterModal, PdfModal } from '..'
import { GroupSelectInvitesForm } from './Forms/GroupSelectInviteForm'
import { GroupInviteAttatchmentsModalContent } from './GroupInviteAttatchmentsModalContent'

interface Props {
    closeModal: () => void
    group: Group
    onSubmitSuccess: () => void
}

interface State {
    activeModalIndex: number
    groupInviteTemplate?: GroupInviteTemplate
    freeText?: string
    freeTextEnglish?: string
    manualInviteIds?: string[]
    emailInviteIds?: string[]
    pdfFile?: {
        fileId: string
        fileName: string
        onClose: () => void
    }
    isSubmitting: boolean
}

export class GroupSendInvitesModal extends React.Component<Props, State> {
    public state: State = {
        activeModalIndex: 0,
        isSubmitting: false,
    }

    private inviteUsersByEmailMutator: Mutator
    private inviteUsersManuallyMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inviteUsersByEmailMutator = new Mutator({
            mutation: SEND_LEARNERS_LESSON_INVITE_EMAIL,
            reactComponentToUpdate: this,
        })

        this.inviteUsersManuallyMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_INVITE_LETTER_FILE,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { closeModal, group } = this.props
        const { activeModalIndex, pdfFile } = this.state

        if (activeModalIndex === 3 && pdfFile) {
            return (
                <PdfModal
                    title={`Intake-uitnodigingen voor ${group.module && group.module.name}`}
                    fileName={pdfFile.fileName}
                    getFileId={() => pdfFile.fileId}
                    onClose={pdfFile.onClose}
                />
            )
        }

        return (
            <CenterModal title={'Lesuitnodiging'} onClose={closeModal} enableEasyClose>
                {this.renderModalContent()}
            </CenterModal>
        )
    }

    private renderModalContent() {
        const { closeModal, group } = this.props
        const {
            activeModalIndex,
            groupInviteTemplate,
            freeText,
            freeTextEnglish,
            isSubmitting,
            manualInviteIds,
            emailInviteIds,
        } = this.state

        if (activeModalIndex === 0) {
            return (
                <GroupSelectInvitesForm
                    onCancel={closeModal}
                    onNext={this.onSelectInviteNext}
                    group={group}
                    defaultGroupInviteTemplate={groupInviteTemplate}
                    defaultFreeText={freeText}
                    defaultFreeTextEnglish={freeTextEnglish}
                />
            )
        }

        if (activeModalIndex === 1) {
            return (
                <LessonInvitesForm
                    group={group}
                    onPrevious={() => this.setState({ activeModalIndex: 2 })}
                    onNext={this.onGroupInvitesSubmit}
                    loading={isSubmitting}
                    manualInviteIds={manualInviteIds}
                    emailInviteIds={emailInviteIds}
                    groupInviteTemplate={groupInviteTemplate}
                />
            )
        }

        if (activeModalIndex === 2) {
            return (
                <GroupInviteAttatchmentsModalContent
                    groupInviteTemplate={groupInviteTemplate}
                    onPrev={() => this.setState({ activeModalIndex: 1 })}
                    onNext={this.sendInvites}
                />
            )
        }

        return null
    }

    private onSelectInviteNext = (
        groupInviteTemplate: GroupInviteTemplate,
        freeText?: string,
        freeTextEnglish?: string
    ) => {
        this.setState({
            groupInviteTemplate,
            freeText,
            freeTextEnglish,
            activeModalIndex: 1,
        })
    }

    private onGroupInvitesSubmit = (manualIds: string[], emailIds: string[]) => {
        const { groupInviteTemplate } = this.state

        const hasAttatchments =
            groupInviteTemplate && groupInviteTemplate.attachments && groupInviteTemplate.attachments.length > 0
        const showAttatchmentModal = manualIds.length > 0 && hasAttatchments

        this.setState(
            {
                manualInviteIds: manualIds,
                emailInviteIds: emailIds,
                activeModalIndex: showAttatchmentModal ? 2 : 1,
            },
            () => {
                if (!showAttatchmentModal) {
                    this.sendInvites()
                }
            }
        )
    }

    private sendInvites = async () => {
        const { groupInviteTemplate, freeText, freeTextEnglish, manualInviteIds = [], emailInviteIds = [] } = this.state
        const { closeModal, onSubmitSuccess, group } = this.props

        if (!groupInviteTemplate) {
            toast.error('Er is geen template geselecteerd')
            return
        }

        this.setState({ isSubmitting: true })

        try {
            // Send the email
            if (emailInviteIds.length > 0) {
                const success = await this.inviteUsersByEmailMutator.mutate({
                    groupId: group._id,
                    forGroupUserIds: emailInviteIds,
                    groupInviteTemplateId: groupInviteTemplate._id,
                    freeText,
                    freeTextEnglish,
                })

                if (success) {
                    toast.success('De e-mails zijn verstuurd')
                } else {
                    toast.success('Er zijn mogelijk e-mails mislukt')
                    this.setState({ isSubmitting: false })

                    return
                }
            }

            // Open the PDF viewer
            if (manualInviteIds.length > 0) {
                const manualInvitesData = await this.inviteUsersManuallyMutator.mutate({
                    groupId: group._id,
                    forGroupUserIds: manualInviteIds,
                    groupInviteTemplateId: groupInviteTemplate._id,
                    freeText,
                    freeTextEnglish,
                })

                if (manualInvitesData) {
                    if (
                        manualInvitesData.users_createLessonInviteFile &&
                        manualInvitesData.users_createLessonInviteFile.fileId
                    ) {
                        const fileName =
                            manualInvitesData.users_createLessonInviteFile.fileName || 'groepsuinodiging.pdf'
                        this.setState({
                            pdfFile: {
                                fileId: manualInvitesData.users_createLessonInviteFile.fileId,
                                fileName,
                                onClose: () => {
                                    closeModal()
                                    onSubmitSuccess()
                                    this.setState({ pdfFile: undefined })
                                },
                            },
                            activeModalIndex: 3,
                        })
                    } else {
                        closeModal()
                        onSubmitSuccess()
                    }
                }
            }
        } catch (err) {
            this.setState({ isSubmitting: false })
            throw err
        }

        this.setState({ isSubmitting: false })
    }
}

const CREATE_LEARNERS_LESSON_INVITE_LETTER_FILE = gql`
    mutation _(
        $groupId: MongoID!
        $forGroupUserIds: [MongoID]!
        $groupInviteTemplateId: MongoID!
        $freeText: String
        $freeTextEnglish: String
    ) {
        users_createLessonInviteFile(
            groupId: $groupId
            forGroupUserIds: $forGroupUserIds
            groupInviteTemplateId: $groupInviteTemplateId
            freeText: $freeText
            freeTextEnglish: $freeTextEnglish
        ) {
            fileId
            fileName
        }
    }
`

const SEND_LEARNERS_LESSON_INVITE_EMAIL = gql`
    mutation _(
        $groupId: MongoID!
        $forGroupUserIds: [MongoID]!
        $groupInviteTemplateId: MongoID!
        $freeText: String
        $freeTextEnglish: String
    ) {
        users_sendLessonInvites(
            groupId: $groupId
            forGroupUserIds: $forGroupUserIds
            groupInviteTemplateId: $groupInviteTemplateId
            freeText: $freeText
            freeTextEnglish: $freeTextEnglish
        )
    }
`
