import * as React from 'react'
import Link from '~/components/Link'
import ReadablePhoneNumbers from '~/components/ReadablePhoneNumbers'
import Hint from '~/components/Hint'
import { PhoneNumber } from '~/types/User'

interface Props {
    email?: string
    phoneNumber?: PhoneNumber
}

export class GroupParticipantContactInfo extends React.Component<Props> {
    public render() {
        const { email, phoneNumber } = this.props

        return (
            <React.Fragment>
                {email && (
                    <Link isNative href={`mailto:${email}`}>
                        {email}
                    </Link>
                )}
                {phoneNumber && (
                    <Hint isWrapper={true}>
                        <ReadablePhoneNumbers phoneNumbers={[phoneNumber]} />
                    </Hint>
                )}
            </React.Fragment>
        )
    }
}
