import { gql } from '@apollo/client'
import { bindAll, compact, get } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    DatePicker,
    Field,
    FilterFieldCollection,
    Form,
    Icon,
    Link,
    MultiInput,
    ReadableDateFromNow,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { Wrap } from '~/components/Wrap'
import { View } from '~/components/View'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TagPicker } from '~/components/TagPicker'
import { ListItem } from '~/components/ListItem'
import { CreateGroupForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter, Mutator, downloadFile, toast } from '~/utils'
import { TableView } from '~/components/TableView'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { Paginator } from '~/utils/Paginator'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

const STATIC_GROUPS_FILTER = {
    filterByConcepts: true,
}

export default class ConceptGroupsTable extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onFilterTagPicker', 'onFilter'])

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByTextSearch',
                'filterByProjectIds',
                'filterByLocationIds',
                'filterByTeacherUserIds',
                'filterByModuleIds',
                'filterByProgramIds',
                'filterByEmployeeUserIds',
                'filterByLearnerOrganizationIds',
                'filterByStartDateTo',
                'filterByStartDateFrom',
                'filterByEndDateTo',
                'filterByEndDateFrom',
                'filterByConcepts',
            ],
            onChange: filters => {
                this.groupsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_GROUPS_FILTER,
                        ...filters,
                    },
                })
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.groupsFetcher = new Fetcher({
            query: GET_GROUPS_QUERY,

            variables: {
                filters: {
                    ...STATIC_GROUPS_FILTER,
                    ...this.filter.getFilters(),
                },
                limit,
                skip,
                sortDir,
                sortBy,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            variables: {
                teacherUsersFilters: {
                    roles: ['TEACHER'],
                    filterTeacherActive: true,
                },
                employeeUsersFilters: {
                    roles: ['EMPLOYEE'],
                },
            },
            preventInitialFetch: true,
            onChange: () => this.forceUpdate(),
        })

        this.createExportMutator = new Mutator({
            mutation: CREATE_CANDITATE_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    loadMore = (skip, limit, callback) => {
        this.groupsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.groups.length === 0,
                })

                return {
                    groups: removeDuplicateDocuments([...(prevData.groups || []), ...moreData.groups]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.groupsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('filterByTextSearch', searchText)
    }

    render() {
        const { paginator } = this
        const { loading } = this.groupsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Wrap full>{this.renderActionbar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    renderActionbar() {
        const { loading } = this.groupsFetcher
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['filterByTextSearch'] })
        const filters = this.filter.getFilters()
        const { loading: loadingExport } = this.createExportMutator

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer, drawerActive) => (
                    <List horizontal>
                        <ListItem>
                            <Button leftIcon={<Icon name={`filter`} />} onClick={toggleDrawer} isActive={drawerActive}>
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search
                                isLoading={loading}
                                onSearch={this.onSearch}
                                defaultValue={filters.filterByTextSearch}
                            />
                        </ListItem>
                        <ListItem right>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} type={`edit`}>
                                        Groep toevoegen
                                    </Button>
                                )}
                                getModal={closeModal => {
                                    return (
                                        <CenterModal onClose={closeModal} title={`Groep toevoegen`}>
                                            <CreateGroupForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                                        </CenterModal>
                                    )
                                }}
                            />
                        </ListItem>
                        <ListItem right>
                            <Button
                                onClick={this.onCandidateExportClick}
                                leftIcon={<Icon name={`download`} />}
                                isLoading={loadingExport}
                            >
                                Kandidatenexport
                            </Button>
                        </ListItem>
                    </List>
                )}
                getDrawer={this.renderFilters}
            />
        )
    }

    renderTable() {
        const { data, loading } = this.groupsFetcher
        const { groups = [] } = data
        const { sorter } = this

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                            Naam
                        </TableHeaderItem>
                        <TableHeaderItem sorter={sorter} sortBy={`dateFrom`}>
                            Start over
                        </TableHeaderItem>
                        <TableHeaderItem>Lesdag en tijd</TableHeaderItem>
                        <TableHeaderItem>Locatie</TableHeaderItem>
                        <TableHeaderItem>Kandidaten</TableHeaderItem>
                        <TableHeaderItem>Docent(en)</TableHeaderItem>
                        <TableHeaderItem>Lesmodule</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={7} isLoading />
                        </TableRow>
                    ) : groups.length > 0 ? (
                        this.renderGroupRows(groups)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={7}>
                                <Subtle>Er zijn geen groepen gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    renderGroupRows(groups) {
        return groups.map(group => {
            const generalWeekLessonDates = group.generalWeekLessonDates
            const lessonDays = generalWeekLessonDates
                ? generalWeekLessonDates
                      .map(date => {
                          return moment(date).format('dd HH:mm')
                      })
                      .join(', ')
                : null

            const startDate = group.dateFrom && moment(group.dateFrom)

            return (
                <TableRow key={group._id}>
                    <TableCell>
                        <Link route={`/groups/concept/${group._id}`}>
                            {group.name && group.name.trim() ? group.name : 'Geen naam'}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {startDate && (
                            <ReadableDateFromNow
                                warning={moment().add(21, 'day').toDate()}
                                date={startDate.toString()}
                            />
                        )}
                    </TableCell>
                    <TableCell>{lessonDays}</TableCell>
                    <TableCell>{(group.location && group.location.name) || ''}</TableCell>
                    <TableCell>{group.learnerUsersCount > 0 && group.learnerUsersCount}</TableCell>
                    <TableCell>
                        {compact((group.teacherUsers || []).map(tu => get(tu, 'user.profile.name'))).join(', ')}
                    </TableCell>
                    <TableCell>
                        {group.module && (
                            <Link route={`/properties/modules/${group.module._id}`}>{group.module.name}</Link>
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderFilters = () => {
        if (!this.filterSuggestionsFetcher.hasFetched) {
            this.filterSuggestionsFetcher.fetch()
        }

        const { loading, data: filterParams } = this.filterSuggestionsFetcher
        const filters = this.filter.getFilters()
        const { teachers, projects, locations, programs, modules, employees, organizations } = filterParams

        const teacherOptions = teachers && teachers.map(user => ({ value: user._id, label: user.profile.name }))
        const employeeOptions = employees && employees.map(user => ({ value: user._id, label: user.profile.name }))
        const locationOptions = locations && locations.map(location => ({ value: location._id, label: location.name }))
        const projectOptions = projects && projects.map(project => ({ value: project._id, label: project.name }))
        const programOptions = programs && programs.map(program => ({ value: program._id, label: program.name }))
        const moduleOptions = modules && modules.map(module => ({ value: module._id, label: module.name }))
        const organizationOptions =
            organizations && organizations.map(organization => ({ value: organization._id, label: organization.name }))

        return (
            <Form ref="filterForm">
                <FilterFieldCollection>
                    <Field isLabel title={`Docent`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByTeacherUserIds`}
                            defaultValue={filters.filterByTeacherUserIds}
                            options={teacherOptions}
                            placeholder={`Selecteer docenten`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Locatie`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByLocationIds`}
                            defaultValue={filters.filterByLocationIds}
                            options={locationOptions}
                            placeholder={`Selecteer locaties`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Project`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByProjectIds`}
                            defaultValue={filters.filterByProjectIds}
                            options={projectOptions}
                            placeholder={`Selecteer projecten`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Opleiding`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByProgramIds`}
                            defaultValue={filters.filterByProgramIds}
                            options={programOptions}
                            placeholder={`Selecteer opleidingen`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Lesmodule`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByModuleIds`}
                            defaultValue={filters.filterByModuleIds}
                            options={moduleOptions}
                            placeholder={`Selecteer lesmodules`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Toptaal medewerker`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByEmployeeUserIds`}
                            defaultValue={filters.filterByEmployeeUserIds}
                            options={employeeOptions}
                            placeholder={`Selecteer medewerkers`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Organisatie`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnerOrganizationIds"
                            defaultValue={filters.filterByLearnerOrganizationIds}
                            options={organizationOptions}
                            placeholder="Selecteer organisatie"
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Startdatum`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByStartDateFrom'}
                                defaultValue={filters.filterByStartDateFrom}
                            />
                            <label>t/m</label>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByStartDateTo'}
                                defaultValue={filters.filterByStartDateTo}
                            />
                        </MultiInput>
                    </Field>
                    <Field isLabel title={`Einddatum`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByEndDateFrom'}
                                defaultValue={filters.filterByEndDateFrom}
                            />
                            <label>t/m</label>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByEndDateTo'}
                                defaultValue={filters.filterByEndDateTo}
                            />
                        </MultiInput>
                    </Field>
                </FilterFieldCollection>
            </Form>
        )
    }

    onCandidateExportClick = () => {
        this.createExportMutator
            .mutate({
                filters: this.filter.getFilters(),
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'conteptGroups_createUsersExportFile')

                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'concept-groepen-gebruikers-export.xlsx')
                        } else {
                            toast.error('Geen gebruikers gevonden om te exporteren')
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                    }
                } else {
                    toast.error('XLS downloaden mislukt')
                }
            })
    }
}

const GET_GROUPS_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: GroupsFilterInputType) {
        groups: groups(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            name
            dateFrom
            dateTo
            module {
                _id
                name
            }
            location {
                _id
                name
            }
            learnerUsersCount
            generalWeekLessonDates
            teacherUsers {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _($teacherUsersFilters: UsersFilterInputType, $employeeUsersFilters: UsersFilterInputType) {
        teachers: users(sortBy: "profile.firstName", filters: $teacherUsersFilters) {
            _id
            profile {
                name
            }
        }
        employees: users(sortBy: "profile.firstName", filters: $employeeUsersFilters) {
            _id
            profile {
                name
            }
        }
        locations: locations(sortBy: "name") {
            _id
            name
        }
        programs: programs(sortBy: "name") {
            _id
            name
        }
        projects: projects(sortBy: "name") {
            _id
            name
        }
        modules: modules(sortBy: "name") {
            _id
            name
        }
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`
// TODO: fill the mutation
const CREATE_CANDITATE_EXPORT_FILE_MUTATION = gql`
    mutation _($filters: GroupsFilterInputType) {
        conteptGroups_createUsersExportFile(filters: $filters) {
            fileId
        }
    }
`
