import './ValueActionFieldItem.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    value?: string
    renderActions: () => JSX.Element
}

export class ValueActionFieldItem extends React.Component<Props> {
    private bem = new BEM('ValueActionFieldItem')

    public render() {
        const { className, value, renderActions } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <span className={this.bem.getElement('value')}>{value}</span>
                <div className={this.bem.getElement('actions')}>{renderActions()}</div>
            </div>
        )
    }
}
