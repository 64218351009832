import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Emphasis, Field } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { translateType, types } from '~/shared/utils'
import { GraphQLErrors } from '~/utils/GraphQLErrors'

import { ProjectArticleCodeInput } from './ProjectArticleCodeInput'

export class ProjectArticleCodeFields extends Component {
    static propTypes = {
        errors: PropTypes.instanceOf(GraphQLErrors),
        articleCodes: PropTypes.arrayOf(
            PropTypes.shape({
                paymentType: PropTypes.string,
                articleCodeType: PropTypes.string,
                articleCode: PropTypes.string,
            }).isRequired
        ).isRequired,
        name: PropTypes.string,
        isEditable: PropTypes.bool,
        onChange: PropTypes.func,
    }

    getArticleCodesObject() {
        const { articleCodes } = this.props

        return articleCodes.reduce(
            (output, articleCodeObject, i) => ({
                ...output,
                [articleCodeObject.paymentType]: {
                    ...(output[articleCodeObject.paymentType] || {}),
                    [articleCodeObject.articleCodeType]: {
                        code: articleCodeObject.articleCode,
                        originalIndex: i,
                    },
                },
            }),
            {}
        )
    }

    onChange = event => {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        const { isEditable, errors } = this.props

        return Object.keys(types.projectArticlePaymentType).map((paymentType, index) => (
            <Field key={index} title={translateType('projectArticlePaymentType', paymentType)} errors={errors}>
                <SimpleTable>
                    {Object.keys(types.projectArticleCodeType()).map((articleCodeType, _index) => (
                        <SimpleTableRow key={_index}>
                            <SimpleTableCell>
                                <Emphasis>{translateType('projectArticleCodeType', articleCodeType)}:</Emphasis>
                            </SimpleTableCell>
                            <SimpleTableCell>
                                {isEditable
                                    ? this.renderInput(paymentType, articleCodeType)
                                    : this.renderValue(paymentType, articleCodeType)}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    ))}
                </SimpleTable>
            </Field>
        ))
    }

    renderInput(paymentType, articleCodeType) {
        const { name, errors } = this.props
        const articleCodesObject = this.getArticleCodesObject()

        const { code = '', originalIndex } =
            (articleCodesObject[paymentType] && articleCodesObject[paymentType][articleCodeType]) || {}

        return (
            <ProjectArticleCodeInput
                name={`${name}${name ? '.' : ''}${paymentType}.${articleCodeType}`}
                errorKey={`${name}[${originalIndex}].articleCode`}
                defaultValue={code}
                onChange={this.onChange}
                hidePossibleErrors={errors.hasErrors()}
            />
        )
    }

    renderValue(paymentType, articleCodeType) {
        const articleCodesObject = this.getArticleCodesObject()

        return (
            (articleCodesObject[paymentType] &&
                articleCodesObject[paymentType][articleCodeType] &&
                articleCodesObject[paymentType][articleCodeType].code) ||
            '-'
        )
    }
}
