import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import Spinner from '~/components/Spinner'
import { FinalExamsPlannedData } from '~/components/FinalExams/FinalExamsPlannedData'
import { FinalExam } from '~/types/FinalExam'
import { getCurrentUser } from '~/services/session'

interface Props {
    finalExam: FinalExam
    refetch: () => void
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { finalExam } = this.props
        const currentUser = getCurrentUser()

        if (!currentUser) {
            return (
                <ContentView>
                    <Spinner />
                </ContentView>
            )
        }

        return (
            <ContentView>
                <FinalExamsPlannedData refetchData={this.refetchData} finalExam={finalExam} />
            </ContentView>
        )
    }

    private refetchData = () => {
        this.props.refetch()
    }
}
