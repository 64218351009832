import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { getCurrentUser } from '~/services/session'

interface Props extends RouteComponentProps<{}, {}> {}

export class DefinitiveInflowMomentsView extends React.Component<Props> {
    public render() {
        const { children, ...routeComponentProps } = this.props
        const currentUser = getCurrentUser()

        return (
            <RouteView
                crumbLabel={'Definitief'}
                routeProps={routeComponentProps}
                isTab={true}
                disableCrumb={!currentUser || !currentUser.isEmployee}
            >
                {children}
            </RouteView>
        )
    }
}
