import React from 'react'

import {
    Form,
    TableRow,
    TableHeader,
    CheckBox,
    TableHeaderItem,
    Link,
    Subtle,
    Icon,
    PdfModal,
    FieldCollection,
    Button,
    TableWrap,
    Field,
} from '~/components'
import { User } from '~/types/User'
import { TableCell } from '~/components/TableCell'
import { Table } from '~/components/Table'
import { InflowMoment, InflowMomentInviteType } from '~/types/InflowMoments'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TableView } from '~/components/TableView'
import { FieldGroup } from '~/components/FieldGroup'
import { InviteTextVariableLabel } from '~/components/InviteText/InviteTextVariableLabel/InviteTextVariableLabel'
import { InflowInviteTemplate } from '~/types/InflowInviteTemplate'
import { InflowMomentPreviousInviteLabel } from '../InflowMomentPreviousInviteLabel'

interface Props {
    onPrev: () => void
    onNext: (manualIds: string[], emailIds: string[]) => void
    users: User[]
    inflowMoment: InflowMoment
    inflowInviteTemplate?: InflowInviteTemplate
    loading: boolean
    manualInviteIds?: string[]
    emailInviteIds?: string[]
}

interface State {
    manualInviteIds: string[]
    emailInviteIds: string[]
    pdfFile?: {
        fileId: string
        fileName: string
        onClose: () => void
    }
}

export class InflowMomentInvitesForm extends React.Component<Props, State> {
    public state: State = {
        manualInviteIds: this.props.manualInviteIds || [],
        emailInviteIds: this.props.emailInviteIds || [],
    }

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { onPrev, users, inflowMoment, inflowInviteTemplate, loading } = this.props
        const canToggleAllCheckBoxes = users.some(user => !!user.emailForCommunication)
        const { pdfFile } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                {pdfFile && (
                    <PdfModal
                        title={`Intake-uitnodigingen voor ${inflowMoment.inflowModule.name}`}
                        fileName={pdfFile.fileName}
                        getFileId={() => pdfFile.fileId}
                        onClose={pdfFile.onClose}
                    />
                )}
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field isDark={true} title={'Uitnodigingsbrief'}>
                            {inflowInviteTemplate && (
                                <InviteTextVariableLabel>{inflowInviteTemplate.name}</InviteTextVariableLabel>
                            )}
                        </Field>
                    </FieldGroup>
                    <TableWrap>
                        <TableView title={`Kandidaten`}>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem>Naam</TableHeaderItem>
                                    <TableHeaderItem
                                        renderCheckBox={() => (
                                            <CheckBox name={`manualInviteIds`} onChange={this.onToggleAllCheckBoxes} />
                                        )}
                                    >
                                        Handmatig
                                    </TableHeaderItem>
                                    <TableHeaderItem
                                        renderCheckBox={() => (
                                            <CheckBox
                                                name={`emailInviteIds`}
                                                onChange={this.onToggleAllCheckBoxes}
                                                isDisabled={!canToggleAllCheckBoxes}
                                            />
                                        )}
                                    >
                                        E-mail
                                    </TableHeaderItem>
                                </TableHeader>
                                {users.map(this.renderTableRow)}
                            </Table>
                        </TableView>
                    </TableWrap>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem>
                                <Button onClick={onPrev}>Vorige</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type="submit" isLoading={loading}>
                                    Verstuur intake-uitnodiging
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderTableRow = (user: User) => {
        const { inflowMoment } = this.props
        const { manualInviteIds, emailInviteIds } = this.state
        const invites = inflowMoment.latestInvites
        const userInvites = invites && invites.filter(invite => invite.user._id === user._id)
        const manualInvite = userInvites && userInvites.find(invite => invite.type === InflowMomentInviteType.Manual)
        const emailInvite = userInvites && userInvites.find(invite => invite.type === InflowMomentInviteType.Email)

        return (
            <TableRow key={user._id}>
                <TableCell>
                    <Link
                        route={`/users/learners/${user._id}`}
                        rightIcon={
                            (!userInvites || userInvites.length <= 0) && (
                                <Icon name={'status_attention'} color={'#e85156'} />
                            )
                        }
                    >
                        {`${user.profile.name} `}
                    </Link>
                </TableCell>
                <TableCell>
                    <CheckBox
                        onClick={() => this.updateManualIds(user._id)}
                        checked={manualInviteIds.includes(user._id)}
                    >
                        {manualInvite && (
                            <InflowMomentPreviousInviteLabel
                                templateName={
                                    manualInvite.inflowInviteTemplate && manualInvite.inflowInviteTemplate.name
                                }
                                invitedAt={manualInvite.date}
                                invitedBy={manualInvite.invitedByUser && manualInvite.invitedByUser.profile.name}
                            />
                        )}
                    </CheckBox>
                </TableCell>
                <TableCell>
                    {!user.emailForCommunication ? (
                        <Subtle>Gebruiker heeft geen e-mailadres</Subtle>
                    ) : (
                        <CheckBox
                            onClick={() => this.updateEmailIds(user._id)}
                            checked={emailInviteIds.includes(user._id)}
                            isDisabled={!user.emailForCommunication}
                        >
                            {emailInvite && (
                                <InflowMomentPreviousInviteLabel
                                    templateName={
                                        emailInvite.inflowInviteTemplate && emailInvite.inflowInviteTemplate.name
                                    }
                                    invitedAt={emailInvite.date}
                                    invitedBy={emailInvite.invitedByUser && emailInvite.invitedByUser.profile.name}
                                    forEmail={true}
                                />
                            )}
                        </CheckBox>
                    )}
                </TableCell>
            </TableRow>
        )
    }

    private updateManualIds = (userId: string) => {
        const { manualInviteIds } = this.state

        this.setState({
            manualInviteIds: this.updateUserIdArray(userId, manualInviteIds),
        })
    }

    private updateEmailIds = (userId: string) => {
        const { emailInviteIds } = this.state

        this.setState({
            emailInviteIds: this.updateUserIdArray(userId, emailInviteIds),
        })
    }

    private updateUserIdArray(userId: string, userIdArray: string[]) {
        if (userIdArray.indexOf(userId) >= 0) {
            return userIdArray.filter(id => id !== userId)
        }

        return [...userIdArray, userId]
    }

    private onToggleAllCheckBoxes = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { users } = this.props
        const property = event.target.name
        const nextStateUsers =
            property === 'emailInviteIds' ? users.filter(user => !!user.emailForCommunication) : users

        const nextStateUserIds = nextStateUsers.map(user => user._id)

        this.setState({ [property]: event.target.checked ? nextStateUserIds : [] } as any)
    }

    private onSubmit = async () => {
        const { onNext } = this.props
        const { manualInviteIds, emailInviteIds } = this.state

        onNext(manualInviteIds, emailInviteIds)
    }
}
