import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerProfileData } from '~/implementations'

export default class ExternalTeacherLearnerDetailDataView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        groupId: PropTypes.string,
    }

    render() {
        const { userId, groupId } = this.props

        return (
            <ContentView>
                <LearnerProfileData
                    learnerUserId={userId}
                    getRouteForUserId={userId => `/groups/${groupId}/learners/${userId}`}
                    hideFieldsForGroupTeacher
                />
            </ContentView>
        )
    }
}
