import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import moment from 'moment'

import { Link, Emphasis } from '~/components'
import { translateType } from '~/shared/utils'

// TODO: add typing
export class InvoiceDescriptionText extends Component {
    static propTypes = {
        className: PropTypes.string,
        invoice: PropTypes.object.isRequired,
    }

    render() {
        const { invoice } = this.props

        if (invoice.isCredit) {
            return (
                <Fragment>
                    Creditnota voor
                    {` `}
                    {invoice.debitInvoice.isGroupParticipationInvoice && invoice.debitInvoice.group && (
                        <Link route={`/groups/${invoice.debitInvoice.group._id}`}>
                            {invoice.debitInvoice.group.name}
                        </Link>
                    )}
                    {invoice.debitInvoice.isGroupParticipationInvoice && !invoice.debitInvoice.group && (
                        <Emphasis>verwijderde groep</Emphasis>
                    )}
                    {!invoice.debitInvoice.isGroupParticipationInvoice && invoice.debitInvoice.invoiceNumber}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    {!invoice.isGroupParticipationInvoice && translateType('invoiceDescription', invoice.description)}
                    {invoice.isGroupParticipationInvoice && `Participatie aan `}
                    {invoice.isGroupParticipationInvoice && !invoice.group && <Emphasis>verwijderde groep</Emphasis>}
                    {invoice.isFinalExamInvoice &&
                        (invoice.finalExam
                            ? `Examen ${translateType('finalExamPart', invoice.finalExam.part)} op ${moment(
                                  invoice.finalExam.date
                              ).format('DD-MM-YYYY')}`
                            : `Examen`)}
                    {!invoice.isGroupParticipationInvoice && !invoice.isFinalExamInvoice && invoice.group && (
                        <Fragment>{` voor `}</Fragment>
                    )}
                    {invoice.group && !invoice.isFinalExamInvoice && (
                        <Link route={`/groups/${invoice.group._id}`}>{invoice.group.name}</Link>
                    )}
                </Fragment>
            )
        }
    }
}
