import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getCurrentUser } from '~/services/session'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

export default class ContactsView extends Component {
    static propTypes = {
        children: PropTypes.any,
    }

    render() {
        const { children, ...routeProps } = this.props
        const user = getCurrentUser()

        if (user.isAdmin === true || user.isEmployee === true) {
            return (
                <RouteView crumbLabel={'Organisatie contactpersonen'} routeProps={routeProps}>
                    {children}
                </RouteView>
            )
        }
        return null
    }
}
