import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { TagPicker, TagPickerOptions, TagPickerOption } from '~/components/TagPicker'
import { get, isNumber, isEmpty } from 'lodash'
import { LearnerLevelWithAlphaInput } from '~/components/LearnerLevelWithAlphaInput'
import { LearnerLevelInput } from '~/components/LearnerLevelInput'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import RadioButton from '~/components/RadioButton'
import { SelectModuleFormField } from '~/forms'
import TextArea from '~/components/TextArea'
import { times, includes } from 'lodash'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { Location } from '~/types/Location'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'

const OTHER_INTAKE_GOAL_OPTION = 'Anders ...'
const INTAKE_GOAL_OPTIONS = [
    'Oriënteren en participeren op/aan activiteiten in de buurt',
    'Werk vinden',
    'Huidige baan behouden',
    'Zakelijke communicatie / financiële zelfredzaamheid vergroten',
    'Eigen onderneming starten',
    'Opleiding MBO-niveau',
    'Opleiding HBO/WO-niveau',
    'Inburgeren',
    'Naturaliseren',
    'Voor het dagelijks leven',
    OTHER_INTAKE_GOAL_OPTION,
].map(x => ({ label: x, value: x }))

interface Props {
    user: User
    errors?: GraphQLErrors
    locations: Location[]
    forNewIntake?: boolean
}

interface State {
    showIntakeLiteracyNativeLanguageField: boolean
    showIntakeGoalsExtraInfoField: boolean
}

export class UserLearnerIntakeResultsFieldGroup extends React.Component<Props, State> {
    public state: State = {
        showIntakeLiteracyNativeLanguageField: this.props.forNewIntake
            ? false
            : includes(get(this.props.user, 'learner.intakeLiteracy') || [], ['Nee', 'Twijfel']),
        showIntakeGoalsExtraInfoField: this.props.forNewIntake
            ? false
            : (get(this.props.user, 'learner.intakeGoals') || []).includes(OTHER_INTAKE_GOAL_OPTION),
    }

    public onIntakeLiteracyChange = (tagPickerOption: TagPickerOption | null) => {
        if (!tagPickerOption) {
            return this.setState({ showIntakeLiteracyNativeLanguageField: false })
        }

        const { value } = tagPickerOption

        if (['Nee', 'Twijfel'].includes(value)) {
            this.setState({ showIntakeLiteracyNativeLanguageField: true })
        } else {
            this.setState({ showIntakeLiteracyNativeLanguageField: false })
        }
    }

    public onIntakeGoalChange = (options: TagPickerOptions) => {
        if (options.find(({ value }) => value === OTHER_INTAKE_GOAL_OPTION)) {
            this.setState({ showIntakeGoalsExtraInfoField: true })
        } else if (this.state.showIntakeGoalsExtraInfoField) {
            this.setState({ showIntakeGoalsExtraInfoField: false })
        }
    }

    public render() {
        const { user, errors, locations, forNewIntake } = this.props
        const preferredLocations = get(user, `learner.preferredLocations`)
        const preferredLocationIds = preferredLocations ? preferredLocations.map(({ _id }: Location) => _id) : null

        return (
            <FieldGroup title={`Intakeresultaten`} isForm={true}>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeLiteracy')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Gealfabetiseerd?`} errors={errors}>
                            <TagPicker
                                name={`user.learner.intakeLiteracy`}
                                placeholder={`Selecteer`}
                                isDisabled={isLocked}
                                multi={false}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeLiteracy')}
                                options={['Ja', 'Nee', 'Twijfel'].map(l => ({ label: l, value: l }))}
                                onChange={this.onIntakeLiteracyChange}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {this.state.showIntakeLiteracyNativeLanguageField && (
                    <LockedFieldWrapper
                        disableLock={forNewIntake || !get(user, 'learner.intakeLiteracyNativeLanguage')}
                    >
                        {isLocked => (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={`Gealfabetiseerd in eigen taal?`} errors={errors}>
                                    <TagPicker
                                        name={`user.learner.intakeLiteracyNativeLanguage`}
                                        placeholder={`Selecteer`}
                                        multi={false}
                                        isDisabled={isLocked}
                                        options={['Ja', 'Nee', 'Twijfel'].map(l => ({ label: l, value: l }))}
                                        defaultValue={get(user, 'learner.intakeLiteracyNativeLanguage')}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </LockedFieldWrapper>
                )}
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGrades.levelConversations')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Gespreksvaardigheid niveau`} errors={errors}>
                            <LearnerLevelInput
                                name={`user.learner.intakeGrades.levelConversations`}
                                placeholder={`Selecteer`}
                                isDisabled={isLocked}
                                defaultValue={
                                    forNewIntake ? undefined : get(user, 'learner.intakeGrades.levelConversations')
                                }
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGrades.levelTalking')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Spreken niveau`} errors={errors}>
                            <LearnerLevelInput
                                name={`user.learner.intakeGrades.levelTalking`}
                                isDisabled={isLocked}
                                placeholder={`Selecteer`}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeGrades.levelTalking')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGrades.levelWriting')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Schrijven niveau`} errors={errors}>
                            <LearnerLevelWithAlphaInput
                                name={`user.learner.intakeGrades.levelWriting`}
                                isDisabled={isLocked}
                                placeholder={`Selecteer`}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeGrades.levelWriting')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGrades.levelListening')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Luisteren niveau`} errors={errors}>
                            <LearnerLevelInput
                                name={`user.learner.intakeGrades.levelListening`}
                                placeholder={`Selecteer`}
                                isDisabled={isLocked}
                                defaultValue={
                                    forNewIntake ? undefined : get(user, 'learner.intakeGrades.levelListening')
                                }
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGrades.levelReading')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Lezen niveau`} errors={errors}>
                            <LearnerLevelWithAlphaInput
                                name={`user.learner.intakeGrades.levelReading`}
                                placeholder={`Selecteer`}
                                isDisabled={isLocked}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeGrades.levelReading')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {/* tslint:disable-next-line:max-line-length */}
                <LockedFieldWrapper
                    disableLock={
                        forNewIntake ||
                        (!isNumber(get(user, 'learner.intakeReadingExamScore')) &&
                            !isNumber(get(user, 'learner.intakeReadingExamScoreTotal')))
                    }
                >
                    {isLocked => (
                        <Field title={`Score leestoets goed/totaal`} errors={errors}>
                            <MultiInput type={`half`}>
                                <Input
                                    name={`user.learner.intakeReadingExamScore`}
                                    type={`number`}
                                    placeholder={`Goed`}
                                    isDisabled={isLocked}
                                    defaultValue={
                                        forNewIntake ? undefined : get(user, 'learner.intakeReadingExamScore')
                                    }
                                />
                                <Input
                                    name={`user.learner.intakeReadingExamScoreTotal`}
                                    type={`number`}
                                    placeholder={`Totaal`}
                                    isDisabled={isLocked}
                                    defaultValue={
                                        forNewIntake ? undefined : get(user, 'learner.intakeReadingExamScoreTotal')
                                    }
                                />
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!isNumber(get(user, 'learner.intakeGradesLearnability'))}>
                    {isLocked => (
                        <Field isLabel={true} title={`Leerbaarheid score`} errors={errors}>
                            <TagPicker
                                name={`user.learner.intakeGradesLearnability`}
                                defaultValue={parseInt(get(user, 'learner.intakeGradesLearnability'), 10)}
                                placeholder={`Selecteer`}
                                multi={false}
                                isDisabled={isLocked}
                                options={times(26, n => ({ value: n, label: n }))}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeMotivation')}>
                    {isLocked => (
                        <Field title={`Motivatie`} errors={errors}>
                            <MultiInput type="radio">
                                {['Goed', 'Matig', 'Slecht'].map((skill, i) => (
                                    <RadioButton
                                        key={i}
                                        name={`user.learner.intakeMotivation`}
                                        value={skill}
                                        isDisabled={isLocked}
                                        defaultChecked={
                                            forNewIntake ? undefined : get(user, 'learner.intakeMotivation') === skill
                                        }
                                    >
                                        {skill}
                                    </RadioButton>
                                ))}
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, `learner.intakeGoals`)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Doel taalcursus`} errors={errors}>
                            <TagPicker
                                name={`user.learner.intakeGoals`}
                                options={INTAKE_GOAL_OPTIONS}
                                isDisabled={isLocked}
                                onChange={this.onIntakeGoalChange}
                                placeholder={'Kies een of meerdere doelen'}
                                defaultValue={forNewIntake ? undefined : get(user, `learner.intakeGoals`)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {this.state.showIntakeGoalsExtraInfoField && (
                    <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeOtherGoal')}>
                        {isLocked => (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={`Anders, namelijk ...`} errors={errors}>
                                    <TextArea
                                        name={`user.learner.intakeOtherGoal`}
                                        isDisabled={isLocked}
                                        defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeOtherGoal')}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </LockedFieldWrapper>
                )}
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeGoalsAdditionalInfo')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Toelichting doel taalcursus`} errors={errors}>
                            <TextArea
                                name={`user.learner.intakeGoalsAdditionalInfo`}
                                isDisabled={isLocked}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeGoalsAdditionalInfo')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {/* tslint:disable-next-line:max-line-length */}
                <LockedFieldWrapper
                    disableLock={
                        forNewIntake ||
                        (!get(user, 'learner.adviceModuleFilterByProgramId') &&
                            !get(user, 'learner.adviceModuleFilterByProjectId') &&
                            !get(user, 'learner.adviceModule'))
                    }
                >
                    {isLocked => (
                        <SelectModuleFormField
                            title={`Advies-lesmodule`}
                            moduleIdName={`user.learner.adviceModuleId`}
                            projectIdName={`user.learner.adviceModuleFilterByProjectId`}
                            programIdName={`user.learner.adviceModuleFilterByProgramId`}
                            defaultModule={forNewIntake ? undefined : get(user, 'learner.adviceModule')}
                            defaultFilterByProjectId={
                                forNewIntake ? undefined : get(user, 'learner.adviceModuleFilterByProjectId')
                            }
                            isDisabled={isLocked}
                            defaultFilterByProgramId={
                                forNewIntake ? undefined : get(user, 'learner.adviceModuleFilterByProgramId')
                            }
                            errors={errors}
                        />
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.adviceModuleInfo')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Toelichting advies-lesmodule`} errors={errors}>
                            <TextArea
                                name={`user.learner.adviceModuleInfo`}
                                isDisabled={isLocked}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.adviceModuleInfo')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || isEmpty(preferredLocationIds)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Voorkeurslocatie(s)`} errors={errors}>
                            <TagPicker
                                name={`user.learner.preferredLocationIds`}
                                options={locations.map(l => ({ label: l.name, value: l._id }))}
                                placeholder={'Kies een of meerdere voorkeurs locaties'}
                                defaultValue={forNewIntake ? undefined : preferredLocationIds}
                                isDisabled={isLocked}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.intakeResultsInfo')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Toelichting intakeresultaten`} errors={errors}>
                            <TextArea
                                name={`user.learner.intakeResultsInfo`}
                                isDisabled={isLocked}
                                defaultValue={forNewIntake ? undefined : get(user, 'learner.intakeResultsInfo')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }
}
