import { get } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, PdfModal } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { Exam, ExamUploadedDocumentFile, Ability, ExamAbility, ExamType, ExamRatingType } from '~/types/Exam'
import { translateType } from '~/shared/utils'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    exam: Exam
    params: any
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { exam } = this.props

        if (!exam) {
            return null
        }

        const readableExamType =
            exam.type === ExamType.Alpha ? 'Alfatoets' : exam.type === ExamType.Language ? 'Taaltoets' : null

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title="Gegevens">
                            <Field title={`Naam`}>{exam.name}</Field>
                            {exam.type !== ExamType.Alpha && (
                                <Field title={`Niveau`}>{exam.level ? translateType('level', exam.level) : ''}</Field>
                            )}
                            <Field title={`Soort`}>{readableExamType}</Field>
                        </FieldGroup>
                        {this.renderAbilities()}
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button
                                        onClick={() => browserHistory.push(`/properties/exams/${exam._id}/edit`)}
                                        type={`edit`}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    private renderAbilities = () => {
        const { exam } = this.props

        return Object.values(ExamAbility)
            .filter(ability => {
                return (
                    exam.type === ExamType.Language ||
                    (exam.type === ExamType.Alpha && [ExamAbility.Read, ExamAbility.Write].includes(ability))
                )
            })
            .map((abilityName, i) => {
                const ability = exam[abilityName]

                return <React.Fragment key={i}>{this.renderAbility(ability, abilityName)}</React.Fragment>
            })
    }

    private renderAbility = (ability: Ability, abilityName: ExamAbility) => {
        const { typeOfRating, minimumScoreToPass, maximumScore } = ability

        const files = get(ability, 'documentFiles')
        const documentFieldTitle = files && files.length ? `Documenten` : `Document`

        return (
            <FieldGroup title={translateType('examAbility', abilityName)}>
                <Field title={`Type beoordeling`}>{translateType('examRating', typeOfRating)}</Field>
                {typeOfRating === ExamRatingType.Score && (
                    <React.Fragment>
                        <Field title={`Voldoende vanaf`}>{minimumScoreToPass}</Field>
                        <Field title={`Maximaal te behalen punten`}>{maximumScore}</Field>
                    </React.Fragment>
                )}
                <Field title={documentFieldTitle}>{files && <List>{this.renderDocumentValue(files)}</List>}</Field>
            </FieldGroup>
        )
    }

    private renderDocumentValue = (files: ExamUploadedDocumentFile[]) => {
        return files.map(file => {
            const _id = get(file, '_id')
            const fileName = get(file, 'fileName')

            return (
                <ListItem key={`file-${_id}`}>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} linkStyle={`default`}>
                                {fileName}
                            </Button>
                        )}
                        getModal={closeModal => (
                            <PdfModal title={fileName} fileName={fileName} getFileId={() => _id} onClose={closeModal} />
                        )}
                    />
                </ListItem>
            )
        })
    }
}
