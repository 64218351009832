import * as React from 'react'
import Field from '~/components/Field'
import { TagPicker, TagPickerChangeHandler } from '~/components/TagPicker'
import { Organization } from '~/types/Organization'
import { FieldGroup } from '~/components/FieldGroup'
import { UserLearnerOrganization } from '~/types/User'
import Input from '~/components/Input'
import FieldWithButton from '~/components/Core/Field/FieldWithButton/FieldWithButton'

interface Props {
    defaultValue?: UserLearnerOrganization
    errors?: any
    hasPrivatePayment?: boolean
    hideFieldsForOrganizationContacts?: boolean
    index: number
    loading?: boolean
    organizations?: Organization[]
    isDisabled?: boolean
    renderRemoveButton?: () => React.ReactNode
}

interface State {
    selectedOrganizationId?: string
}

export class LearnerOrganizationFields extends React.Component<Props, State> {
    public state: State = {
        selectedOrganizationId:
            this.props.defaultValue && this.props.defaultValue.organization && this.props.defaultValue.organization._id,
    }

    public render() {
        const {
            errors,
            hasPrivatePayment,
            hideFieldsForOrganizationContacts,
            index,
            loading,
            isDisabled,
            organizations,
            renderRemoveButton,
        } = this.props
        const { selectedOrganizationId } = this.state
        const isFirstField = index === 0
        const isRequired = isFirstField && !hasPrivatePayment

        if (!organizations) {
            return null
        }

        const organizationOptions = organizations.map(o => ({ value: o._id, label: o.name }))
        const selectedOrganization = organizations.filter(o => o._id === selectedOrganizationId)[0]

        return (
            <React.Fragment>
                {!hideFieldsForOrganizationContacts && (
                    <Field title={`Organisatie`} errors={errors} isRequired={isRequired}>
                        {!loading && (
                            <React.Fragment>
                                <FieldWithButton renderButton={renderRemoveButton}>
                                    <TagPicker
                                        name={`user.learner.organizations[${index}].organizationId`}
                                        placeholder={`Organisatie`}
                                        value={selectedOrganizationId}
                                        onChange={this.onChange}
                                        multi={false}
                                        isDisabled={isDisabled}
                                        options={organizationOptions}
                                        hasError={errors}
                                    />
                                </FieldWithButton>
                                <span data-errorkey={'user.learner.organizations'} />
                            </React.Fragment>
                        )}
                    </Field>
                )}
                {selectedOrganization &&
                    (hideFieldsForOrganizationContacts ? (
                        this.renderContactUserField(selectedOrganization)
                    ) : (
                        <FieldGroup isInsetGroup={true}>{this.renderContactUserField(selectedOrganization)}</FieldGroup>
                    ))}
            </React.Fragment>
        )
    }

    private renderContactUserField = (selectedOrganization: Organization) => {
        const { errors, loading, index, defaultValue, isDisabled } = this.props

        const selectedOrganizationContactUsers = selectedOrganization.contactUsers
        const selectedOrganizationUsersOptions =
            selectedOrganizationContactUsers &&
            selectedOrganizationContactUsers.map(cu => ({ value: cu._id, label: cu.profile.name }))

        return (
            <React.Fragment>
                <Field title={`Contactpersoon`} errors={errors}>
                    {!loading && (
                        <TagPicker
                            name={`user.learner.organizations[${index}].organizationContactUserId`}
                            placeholder={`Bij ${selectedOrganization.name}`}
                            defaultValue={
                                defaultValue &&
                                defaultValue.organizationContactUser &&
                                defaultValue.organizationContactUser._id
                            }
                            multi={false}
                            isDisabled={isDisabled}
                            options={selectedOrganizationUsersOptions}
                        />
                    )}
                </Field>
                <Field title={`Dossiernummer`} errors={errors}>
                    <Input
                        name={`user.learner.organizations[${index}].organizationFileNumber`}
                        type="text"
                        isDisabled={isDisabled}
                        defaultValue={defaultValue && defaultValue.organizationFileNumber}
                    />
                </Field>
            </React.Fragment>
        )
    }

    private onChange: TagPickerChangeHandler = option => {
        this.setState({
            selectedOrganizationId: option ? option.value : undefined,
        })
    }
}
