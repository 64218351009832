import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ExportsCardLists } from '~/components/Exports/ExportsCardLists'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{}, {}> {}

export default class ExportsAllView extends React.Component<Props> {
    public render() {
        const { ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Exports'} routeProps={routeProps}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full={true}>
                        <ExportsCardLists />
                    </Wrap>
                </ContentView>
            </RouteView>
        )
    }
}
