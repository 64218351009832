import React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'
import { Header, Paragraph, Button } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Wrap } from '~/components/Wrap'
import { Article } from '~/components/Article'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

interface Props extends RouteComponentProps<{}, {}> {}

export default class NotFoundView extends React.Component<Props> {
    public render() {
        const { ...routeProps } = this.props

        return (
            <BreadCrumbProvider>
                <RouteView crumbLabel={'Oops! Pagina niet gevonden.'} routeProps={routeProps}>
                    <Header>
                        <BreadCrumbs />
                    </Header>
                    <ContentView>
                        <Wrap>
                            <Article>
                                <Paragraph>De pagina die je zoekt is niet gevonden.</Paragraph>
                                <Paragraph>
                                    <Button onClick={this.goToHome}>Naar het begin</Button>
                                </Paragraph>
                            </Article>
                        </Wrap>
                    </ContentView>
                </RouteView>
            </BreadCrumbProvider>
        )
    }

    private goToHome = () => {
        browserHistory.push('/')
    }
}
