import c from 'classnames'
import * as React from 'react'

interface Props {
    className?: string
    small?: boolean
}

export class CenterView extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return (
            <div className={this.getClassName()}>
                <div className="tt-CenterView__vertical-center">
                    <div className="tt-CenterView__horizontal-center">{children}</div>
                </div>
            </div>
        )
    }

    private getClassName = () => {
        const { small, className } = this.props

        return c(
            'tt-CenterView',
            {
                'tt-CenterView--small': small,
            },
            className
        )
    }
}
