import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, CalendarEventTimeFieldGroup, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import transformFormFields from '~/utils/transformFormFields'

export default class CreateRoomUnavailabilityEventForm extends Component {
    static propTypes = {
        defaultValues: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
            allDay: PropTypes.boolean,
        }),
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        errors: PropTypes.instanceOf(GraphQLErrors),
        loading: PropTypes.bool,
    }

    onSubmit = (event, fields) => {
        this.props.onSubmit({
            ...transformFormFields(fields.event),
        })
    }

    render() {
        const { onCancel, errors, loading, defaultValues } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Titel`} errors={errors}>
                            <Input name={`event.title`} type={`text`} placeholder={`Titel`} autoFocus={true} />
                        </Field>
                    </FieldGroup>
                    <CalendarEventTimeFieldGroup
                        enableRepetition={true}
                        errors={errors}
                        defaultValues={{
                            startDate: defaultValues.startDate,
                            endDate: defaultValues.endDate,
                            allDay: defaultValues.allDay,
                        }}
                    />
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}
