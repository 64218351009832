import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'

interface Props {
    children?: any
    className?: string
    hasInlineEdit?: boolean
    highlight?: boolean
    isCompact?: boolean
    isForm?: boolean
    isInsetGroup?: boolean
    noNextField?: boolean
    onRemove?: () => void
    separateFields?: boolean
    singleField?: boolean
    title?: string
    isLastFieldInModal?: boolean
}

export class FieldGroup extends React.Component<Props> {
    private bem = new BEM('FieldGroup', () => ({
        'has-inline-edit': this.props.hasInlineEdit,
        'is-compact': this.props.isCompact,
        'is-form': this.props.isForm || this.props.isInsetGroup,
        'is-highlighted-group': this.props.highlight,
        'is-inset-group': this.props.isInsetGroup,
        'is-removable': !!this.props.onRemove,
        'is-last-field-in-modal': this.props.isLastFieldInModal,
        'no-next-field': this.props.noNextField,
        'separate-fields': this.props.separateFields,
        'single-field': this.props.singleField,
    }))

    public render() {
        const { children, className, title, onRemove } = this.props

        const WrapperComponent = this.getWrapperComponent()
        const TitleComponent = this.getTitleComponent()

        return (
            <div className={this.bem.getClassName(className)}>
                <WrapperComponent className={this.bem.getElement('wrapper')}>
                    {!!title && (
                        <span className={this.bem.getElement('title')}>
                            <TitleComponent>{title}</TitleComponent>
                        </span>
                    )}
                    <div className={this.bem.getElement('list')}>{children}</div>
                    {!!onRemove && this.renderRemoveButton()}
                </WrapperComponent>
            </div>
        )
    }

    private renderRemoveButton = () => {
        const { onRemove } = this.props

        if (!onRemove) {
            return null
        }

        return (
            <Button
                className={this.bem.getElement('remove-button')}
                leftIcon={<Icon name={`trash`} />}
                onClick={() => onRemove()}
                type={`button`}
            />
        )
    }

    private getWrapperComponent = () => {
        const { isForm, isInsetGroup } = this.props

        if (isForm || isInsetGroup) {
            return `fieldset`
        }

        return `div`
    }

    private getTitleComponent = () => {
        const { isForm } = this.props

        if (isForm) {
            return `legend`
        }

        return `h3`
    }
}
