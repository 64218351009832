import * as React from 'react'
import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'
import {
    BreadCrumbContext,
    TBreadCrumbContext,
    BreadCrumb,
} from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { Crumb } from '~/components/Crumb'
import Link from '~/components/Link'
import { HeaderTitle } from '~/components/Chrome/Header/HeaderTitle/HeaderTitle'

interface Props {
    className?: string
}

interface BEMMofidierParams {
    hasLeadingBreadCrumbs: boolean
}

interface BreadCrumbsAndTitle {
    title: BreadCrumb
    breadCrumbs: BreadCrumb[]
}

export class BreadCrumbs extends React.Component<Props> {
    private bem = new BEM<BEMMofidierParams>('BreadCrumbs', ({ hasLeadingBreadCrumbs }) => ({
        'has-crumbs': hasLeadingBreadCrumbs,
    }))

    public render() {
        return <BreadCrumbContext.Consumer>{this.renderBreadCrumbsChildren}</BreadCrumbContext.Consumer>
    }

    private renderBreadCrumbsChildren = (breadCrumbsContext?: TBreadCrumbContext) => {
        const { className } = this.props

        if (!breadCrumbsContext) {
            // tslint:disable-next-line:no-console
            console.warn('BreadCrumbContext consumer defined, but no BreadCrumbContext provider is found')

            return
        }

        const { breadCrumbs: allBreadCrumbs } = breadCrumbsContext

        if (!allBreadCrumbs || allBreadCrumbs.length === 0) {
            return
        }

        const { title, breadCrumbs } = this.getBreadCrumbsAndTitle(allBreadCrumbs)

        if (!title) {
            return
        }

        return (
            <nav className={this.bem.getClassName(className, { hasLeadingBreadCrumbs: breadCrumbs.length > 0 })}>
                <List horizontal={true}>
                    {breadCrumbs.map(breadCrumb => (
                        <React.Fragment key={breadCrumb.labelString + breadCrumb.path}>
                            <Crumb isLoading={breadCrumb.isLoading}>
                                <Link route={breadCrumb.path}>{breadCrumb.labelString}</Link>
                            </Crumb>
                        </React.Fragment>
                    ))}
                </List>
                <HeaderTitle title={title.label} isLoading={title.isLoading} />
            </nav>
        )
    }

    private getBreadCrumbsAndTitle = (breadCrumbs: BreadCrumb[]): BreadCrumbsAndTitle => {
        const lastBreadCrumb = breadCrumbs[breadCrumbs.length - 1]

        if (lastBreadCrumb.showOnlyInLeadingCrumb) {
            return {
                title: breadCrumbs[breadCrumbs.length - 2],
                breadCrumbs: breadCrumbs.slice(0, breadCrumbs.length - 2),
            }
        }

        return {
            title: lastBreadCrumb,
            breadCrumbs: breadCrumbs.slice(0, breadCrumbs.length - 1),
        }
    }
}
