import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { TimelineMessage } from '~/components'
import { InvoiceLink } from '~/implementations'

export class LogTimelineMessage extends Component {
    static propTypes = {
        timelineEvent: PropTypes.object.isRequired,
        children: PropTypes.any,
        className: PropTypes.string,
    }

    render() {
        const { timelineEvent } = this.props

        const invoice = get(timelineEvent, 'logTypeData.invoice')

        const logTypeMap = invoice && {
            INVOICE_PAYMENT_PAID: () => (
                <div>
                    Betaling voor factuur <InvoiceLink invoice={invoice} /> voldaan.
                </div>
            ),
            INVOICE_REMINDER_SENT: () => (
                <div>
                    Herinneringsmail verstuurd voor factuur <InvoiceLink invoice={invoice} />.
                </div>
            ),
            INVOICE_SENT: () => (
                <div>
                    Factuur <InvoiceLink invoice={invoice} /> verstuurd.
                </div>
            ),
        }

        const bodyContentToRender = timelineEvent.logType && logTypeMap[timelineEvent.logType]()
        const author = 'Automatisch gegenereerd'

        if (bodyContentToRender) {
            return (
                <TimelineMessage
                    bodyContentToRender={bodyContentToRender}
                    timelineEvent={timelineEvent}
                    isEditable={false}
                    author={author}
                />
            )
        }
    }
}
