import './AttendanceDot.scss'

import * as React from 'react'

import { Icon, ToolTip } from '~/components'
import { translateType } from '~/shared/utils'
import { LessonReasonOfAbsence, LessonAttendance } from '~/types/Lesson'
import { BEM } from '~/services/BEMService'
import { getCurrentUser } from '~/services/session'
import { UserRole } from '~/types/User'

interface Props {
    className?: string
    attendance?: string
    reasonOfAbsence?: LessonReasonOfAbsence
    attendanceNote?: string
}

export class AttendanceDot extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceDot', () => ({
        placeholder: !this.props.attendance,
    }))

    public render() {
        const { className, attendance } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {attendance && <ToolTip text={this.getTooltipText()}>{this.getIcon()}</ToolTip>}
            </div>
        )
    }

    private getIcon(): React.ReactNode {
        const { attendance } = this.props

        if (attendance === LessonAttendance.Present) {
            return <Icon name="status_done" color="#2bc572" />
        }

        if (attendance === LessonAttendance.AbsentWithReason) {
            return <Icon name="status_partly" color="#e77d57" />
        }

        if (attendance === LessonAttendance.AbsentWithoutReason) {
            return <Icon name="status_error" color="#e7545a" />
        }

        return null
    }

    private getTooltipText() {
        const { attendance, reasonOfAbsence, attendanceNote } = this.props
        const user = getCurrentUser()
        const userRoles = user.roles
        let userIsOrganisation = false
        let reason = ''
        let note = ''
        let attendanceText = ''
        if (user && userRoles) {
            userIsOrganisation = userRoles.includes(UserRole.OrganizationContact)
            const hasReasonOfAbsence =
                reasonOfAbsence &&
                (attendance === LessonAttendance.AbsentWithReason ||
                    attendance === LessonAttendance.AbsentWithoutReason)

            attendanceText = translateType('lessonAttenance', attendance)
            reason = hasReasonOfAbsence ? `(${translateType('lessonReasonOfAbsence', reasonOfAbsence)})` : ''
            note = attendanceNote ? attendanceNote : ''
        }

        return (
            <div className={this.bem.getElement('overlay')}>
                <div className={this.bem.getElement('reason')}>
                    {attendanceText} {reason}
                </div>
                {!userIsOrganisation && <div className={this.bem.getElement('note')}>{note}</div>}
            </div>
        )
    }
}
