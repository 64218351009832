import * as React from 'react'
import { PageTab } from '~/components'

interface Props {
    counter?: number
}

export class IconPageTab extends React.Component<Props, {}> {
    public render() {
        const { children, counter } = this.props

        return (
            <PageTab className="tt-PageTab--is-icon">
                {children}
                {!!counter && <i className="tt-PageTab__counter">{counter}</i>}
            </PageTab>
        )
    }
}
