// tslint:disable:max-line-length
import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, Field } from '~/components'
import { gql } from '@apollo/client'
import { Mutator } from '~/utils'

const DUOBOW_CONTRACTS_LEANER_REPORTS_GENERATE_MUTATION = gql`
    mutation DUOBOWContractsLeanerReports_generate {
        exports_enrollment_contracts_duo_bow {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
}

export class DUOBOWEnrollmentContractsExportForm extends React.Component<Props> {
    private DUOBOWContractsLearnerReportsMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.DUOBOWContractsLearnerReportsMutator = new Mutator({
            mutation: DUOBOW_CONTRACTS_LEANER_REPORTS_GENERATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { loading } = this.DUOBOWContractsLearnerReportsMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field>
                            Lijst van nieuwe en gewijzigde contracten voor gegevensuitwisseling met DUO, die voldoet aan
                            de eisen van september 2023.
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button countdown={5} styleOverride={`edit`} type={`submit`} isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess } = this.props

        const result = (await this.DUOBOWContractsLearnerReportsMutator.mutate()) as any

        if (result && result.exports_enrollment_contracts_duo_bow) {
            const { _id, fileName } = result.exports_enrollment_contracts_duo_bow.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
