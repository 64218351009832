import * as React from 'react'
import Field from '~/components/Field'
import DatePicker from '~/components/DatePicker'
import { FileUploadInput } from '~/components/FileUploadInput/FileUploadInput'

interface Props {
    defaultCertificateFiles?: any[]
    defaultDateValue?: Date
    errors?: any
}

export class LessonCompetenceCertificateFieldGroup extends React.Component<Props> {
    public render() {
        const { defaultCertificateFiles, defaultDateValue, errors } = this.props

        const baseName = `user.teacher`

        return (
            <React.Fragment>
                <Field title={`Certificeringsdatum`} errors={errors}>
                    <DatePicker defaultValue={defaultDateValue} name={`${baseName}.lessonCompetenceDate`} />
                </Field>
                <Field title={`Certificaat`} errors={errors}>
                    <FileUploadInput
                        accept={`application/pdf,image/*`}
                        defaultFiles={defaultCertificateFiles}
                        name={`${baseName}.lessonCompetenceCertificates`}
                        multi={true}
                    >
                        Kies bestand
                    </FileUploadInput>
                </Field>
            </React.Fragment>
        )
    }
}
