import * as React from 'react'
import { Props as RouteViewProps, RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Translator } from '~/services/i18n'
import { SubNavLink } from '~/components/SubNavLink'
import { SubNav } from '~/components/SubNav'
import { getCurrentUser } from '~/services/session'

interface Props extends RouteViewProps {}

export class ProcessView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App.Process',
        subscribe: this,
    })

    public render() {
        const { children, ...routeViewProps } = this.props
        const { t } = this.translator
        const currentUser = getCurrentUser()

        return (
            <RouteView {...routeViewProps}>
                <SubNav>
                    {(currentUser.isEmployee || currentUser.isIntaker || currentUser.isOrganizationContact) && (
                        <SubNavLink route={`/inflow-moments`}>{t('navigation.buttons.inflow-moments')}</SubNavLink>
                    )}
                    {(currentUser.isEmployee || currentUser.isExternalTeacher || currentUser.isOrganizationContact) && (
                        <SubNavLink route={`/groups`}>{t('navigation.buttons.groups')}</SubNavLink>
                    )}
                    {currentUser.isEmployee && (
                        <SubNavLink route={`/final-exams`}>{t('navigation.buttons.final-exams')}</SubNavLink>
                    )}
                </SubNav>
                {children}
            </RouteView>
        )
    }
}
