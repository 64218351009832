import React from 'react'
import { RouteComponentProps } from 'react-router'
import { ContentView } from '~/components/ContentView'

import { Module } from '~/types/Module'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    module: Module
    children: any
}

interface State {}

export class AppPropertiesModulesInvites extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { children, module } = this.props

        return <ContentView>{module && React.cloneElement(children, { module })}</ContentView>
    }
}
