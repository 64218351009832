import React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import { InflowMoment } from '~/types/InflowMoments'
import { Field, Button, Icon } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { ValidationError } from '~/components/ValidationError'
import { translateGroupValidationError } from '~/utils'
import { getCurrentUser } from '~/services/session'

export interface ValidationErrorType {
    key: string
    stringifiedInfo: string
}

interface Props {
    children: any
    title: string
    inflowMoment?: InflowMoment
    getEditModal?: (closeModal: () => void) => React.ReactNode
    validationErrors?: ValidationErrorType[]
    seperateFields?: boolean
    removeMessage?: string
    onRemove?: () => void
    hideRemove?: boolean
}

interface State {}

export class InflowMomentFieldGroup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    public render() {
        const currentUser = getCurrentUser()
        const { children, title, getEditModal, validationErrors, seperateFields, onRemove, hideRemove } = this.props

        return (
            <FieldGroup hasInlineEdit={true} title={title} separateFields={seperateFields}>
                {children}
                {(validationErrors || []).map(this.renderValidationError)}
                <Field inlineEdit={true}>
                    {!hideRemove && onRemove && this.renderRemoveButton()}
                    {currentUser.isEmployee && getEditModal && this.renderEditButton()}
                </Field>
            </FieldGroup>
        )
    }

    private renderValidationError = (validationError: ValidationErrorType, i: number) => {
        const { key, stringifiedInfo } = validationError

        let info = {}
        try {
            info = JSON.parse(stringifiedInfo)
            // TODO: We catch but then ignore any errors here. Add error logging or add comment why we ignore these errors.
            // eslint-disable-next-line no-empty
        } catch (err) {}

        return <ValidationError key={i} text={translateGroupValidationError(key, info)} />
    }

    private renderEditButton = () => {
        const { getEditModal } = this.props

        return (
            <ModalManager
                render={openModal => <Button onClick={openModal}>Bewerken</Button>}
                getModal={closeModal => !!getEditModal && getEditModal(closeModal)}
            />
        )
    }

    private renderRemoveButton = () => {
        const { onRemove, removeMessage } = this.props

        return (
            <Button
                type={`in-row`}
                onClick={onRemove}
                leftIcon={<Icon name={`trash`} />}
                confirm={{
                    title: 'Verwijderen',
                    message: removeMessage || '',
                    execute: {
                        buttonType: 'danger',
                        title: 'Verwijderen',
                    },
                }}
            />
        )
    }
}
