import React from 'react'

import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

export default content => {
    if (!content || !Array.isArray(content)) {
        return null
    }

    return (
        <List>
            {content.map((item, key, array) => {
                if (key === array.length - 1) {
                    return <ListItem key={key}>{item}</ListItem>
                }

                return <ListItem key={key}>{item}</ListItem>
            })}
        </List>
    )
}
