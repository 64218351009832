import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import RadioButton from '~/components/RadioButton'
import PhoneInput from '~/components/PhoneInput'
import { ValidationError } from '~/components/ValidationError'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { get, isEmpty, isNumber } from 'lodash'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'

interface Props {
    errors?: GraphQLErrors
    user: User
    hideFieldsForGroupTeacher?: boolean
    hideFieldsForOrganizationContacts?: boolean
}

interface State {
    useAddressAsVisitAddress: boolean
}

export class UserLearnerContactDetailsFieldGroup extends React.Component<Props, State> {
    public state: State = {
        useAddressAsVisitAddress: !get(this.props.user, 'profile.visitAddress'),
    }

    public render() {
        const { errors, user, hideFieldsForOrganizationContacts, hideFieldsForGroupTeacher } = this.props
        const { useAddressAsVisitAddress } = this.state

        return (
            <FieldGroup title="Contactgegevens" isForm={true}>
                {!hideFieldsForGroupTeacher && (
                    <React.Fragment>
                        <LockedFieldWrapper disableLock={this.isLockDisabledForAddress(user)}>
                            {isLocked => (
                                <LocationFieldsInputGroup
                                    title={'Adres'}
                                    errors={errors}
                                    isLocked={isLocked}
                                    name={'user.profile.address'}
                                    location={user.profile.address}
                                />
                            )}
                        </LockedFieldWrapper>
                        {!hideFieldsForOrganizationContacts && (
                            <React.Fragment>
                                <LockedFieldWrapper disableLock={this.isLockDisabledForVisitingAddress(user)}>
                                    {isLocked => (
                                        <React.Fragment>
                                            <Field title="Bezoekadres is hetzelfde">
                                                <MultiInput type="radio">
                                                    <RadioButton
                                                        name="addressAsVisitingAddress"
                                                        isDisabled={isLocked}
                                                        onClick={() =>
                                                            this.setState({ useAddressAsVisitAddress: true })
                                                        }
                                                        defaultChecked={useAddressAsVisitAddress}
                                                    >
                                                        Ja
                                                    </RadioButton>
                                                    <RadioButton
                                                        name="addressAsVisitingAddress"
                                                        isDisabled={isLocked}
                                                        onClick={() =>
                                                            this.setState({ useAddressAsVisitAddress: false })
                                                        }
                                                        defaultChecked={!useAddressAsVisitAddress}
                                                    >
                                                        Nee
                                                    </RadioButton>
                                                </MultiInput>
                                            </Field>
                                            {!useAddressAsVisitAddress && (
                                                <FieldGroup isInsetGroup={true}>
                                                    <LocationFieldsInputGroup
                                                        title={'Bezoekadres'}
                                                        errors={errors}
                                                        name={'user.profile.visitAddress'}
                                                        isLocked={false}
                                                        location={user.profile.visitAddress}
                                                        withoutCountry={false}
                                                    />
                                                </FieldGroup>
                                            )}
                                            {useAddressAsVisitAddress && (
                                                <input type="hidden" name="user.profile.visitAddress" />
                                            )}
                                        </React.Fragment>
                                    )}
                                </LockedFieldWrapper>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {hideFieldsForOrganizationContacts && (
                    <Field>
                        <ValidationError
                            isWarning={true}
                            fullWidth={true}
                            // tslint:disable-next-line:max-line-length
                            text={`In het geval van een incompany training is het alleen toegestaan contactgegevens (telefoonnummer, e-mailadres en adres) met ons te delen na toestemming van de kandidaat zelf`}
                        />
                    </Field>
                )}
                <LockedFieldWrapper disableLock={isEmpty(user.profile.phoneNumbers)}>
                    {isLocked => (
                        <Field title="Telefoonnummer" errors={errors}>
                            <VariableMultiInput
                                defaultAmount={get(user, `profile.phoneNumbers.length`)}
                                isDisabled={isLocked}
                                getNewInput={i => (
                                    <PhoneInput
                                        key={i}
                                        isDisabled={isLocked}
                                        name={`user.profile.phoneNumbers[${i}]`}
                                        defaultValue={get(user, `profile.phoneNumbers[${i}]`)}
                                    />
                                )}
                                addButtonLabel="Extra veld"
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!user.email}>
                    {isLocked => (
                        <Field isLabel={true} title="E-mailadres" errors={errors}>
                            <Input type="email" name="user.email" isDisabled={isLocked} defaultValue={user.email} />
                        </Field>
                    )}
                </LockedFieldWrapper>
                {!hideFieldsForOrganizationContacts && (
                    <LockedFieldWrapper disableLock={!user.profile.alternativeEmail}>
                        {isLocked => (
                            <Field isLabel={true} title="Secundair e-mailadres" errors={errors}>
                                <Input
                                    type="email"
                                    name="user.profile.alternativeEmail"
                                    isDisabled={isLocked}
                                    defaultValue={user.profile.alternativeEmail}
                                />
                            </Field>
                        )}
                    </LockedFieldWrapper>
                )}
            </FieldGroup>
        )
    }

    private isLockDisabledForAddress(user: User) {
        return (
            !get(user, `profile.address.street`) &&
            !isNumber(get(user, `profile.address.number`)) &&
            !get(user, `profile.address.numberAddition`) &&
            !get(user, `profile.address.zipcode`) &&
            !get(user, 'profile.address.nl.extraInfo.city') &&
            !get(user, `profile.address.country`)
        )
    }

    private isLockDisabledForVisitingAddress(user: User) {
        return (
            !get(user, `profile.visitAddress.street`) &&
            !isNumber(get(user, `profile.visitAddress.number`)) &&
            !get(user, `profile.visitAddress.numberAddition`) &&
            !get(user, 'profile.visitAddress.nl.extraInfo.city') &&
            !get(user, `profile.visitAddress.country`)
        )
    }
}
