import ApolloClient from 'apollo-client'
import url from 'url'

import env from '~/services/env'

import { getSession, renewIfExpirationDateIsSoon } from './session'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

const uploadLink = createUploadLink({
    uri: url.resolve(env.serverBase, '/api/graphql'),
    credentials: 'same-origin',
    // // this is needed to not trigger csrf protection on the server
    headers: { 'Apollo-Require-Preflight': 'true' },
})

const authLink = new ApolloLink((operation, forward) => {
    const session = getSession()
    if (session) {
        const authorization = session ? `Bearer ${session.token}` : ''
        operation.setContext({
            headers: {
                authorization,
            },
        })
        renewIfExpirationDateIsSoon().catch(console.error)
    }

    return forward(operation)
})

const link = ApolloLink.from([authLink, uploadLink])

export default new ApolloClient({
    link: link as any,
    cache: new InMemoryCache() as any,
})
