function getStandardPlaceholderForType(type: string | undefined) {
    switch (type) {
        case 'email':
            return 'domain@example.com'
        case 'password':
            return 'Minimaal 6 karakters'
    }

    return ''
}

export { getStandardPlaceholderForType }
