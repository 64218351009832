import React from 'react'
import { BEM } from '~/services/BEMService'
import { CheckBox } from '.'
import { InputProps } from './Input'

interface Props extends InputProps {
    isHorizontal?: boolean
    renderInput: () => React.ReactNode
    alwaysShowInput?: boolean
    name: string
}

interface State {
    checked: boolean
}

export default class CheckboxWithOptionalInput extends React.Component<Props, State> {
    public state: State = {
        checked: this.props.defaultChecked === true,
    }

    private bem = new BEM('CheckboxWithOptionalInput')

    public render() {
        if (typeof this.props.checked === 'boolean') {
            // tslint:disable-next-line:no-console
            console.warn(
                'CheckboxWithOptionalInput lacks support to be a controlled from outside using the "checked" prop'
            )
        }

        const { className, children, renderInput, alwaysShowInput, ...inputProps } = this.props

        const { checked } = this.state

        return (
            <div className={this.bem.getClassName(className)}>
                <CheckBox
                    {...inputProps}
                    onClick={() => {
                        this.setState({ checked: !this.state.checked })
                    }}
                >
                    {children}
                </CheckBox>
                {(alwaysShowInput || checked) && renderInput()}
            </div>
        )
    }
}
