import {
    ContactCreatedByGroupLog,
    SignatureUploadedLog,
    ContractEnded,
    contractSignatureChanged,
    ContractMadeFinalByLog,
} from '~/components/users/Learner/logs/LearnerContractsLogTypes'

export const LearnerContractLogTypesMap = {
    contractCreated: ContactCreatedByGroupLog,
    contractSigned: SignatureUploadedLog,
    contractTerminated: ContractEnded,
    contractSignatureChanged: contractSignatureChanged,
    contractMadeFinal: ContractMadeFinalByLog,
}
