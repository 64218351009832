import React, { cloneElement } from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'
import { ContentView } from '~/components/ContentView'

interface Props extends RouteComponentProps<{}, {}> {
    userId: string
    refetch: () => void
    children: any
}

export default class ProcessView extends React.Component<Props> {
    public render() {
        const { children, userId, refetch, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Traject'} routeProps={routeProps}>
                <ContentView>{cloneElement(children, { userId, refetch })}</ContentView>
            </RouteView>
        )
    }
}
