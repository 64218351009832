import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import { UnitInput, unitType } from '~/components/UnitInput'
import Hint from '~/components/Hint'
import Input from '~/components/Input'
import Spinner from '~/components/Spinner'
import CheckBox from '~/components/CheckBox'
import { get, isNumber, isBoolean } from 'lodash'
import Link from '~/components/Link'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'
import { PaymentByType } from '../UserLearnerAddInvoicingForm'
import { translateType } from '~/shared/utils'
import { LearnerProfileEditForm } from '~/implementations/LearnerProfileEditForm'
import { UserTypeOfLearnerTypesEnum } from '~/generated/graphql'

interface Props {
    errors?: GraphQLErrors
    user: User
    hasPrivatePayment?: boolean
    paymentBy?: PaymentByType
    changePaymentBy: (value: PaymentByType) => void
    privatePaymentIsDUO?: boolean
    privatePaymentDUOHasApprovedFund?: boolean
    useAddressAsPrivatePaymentAddress?: boolean
    twinfieldCustomerCode?: string
    doesNotExistInTwinfield?: boolean
    lookupTwinfieldCustomerLoading?: boolean
    lookupTwinfieldCustomerExists?: boolean
    lookupTwinfieldCustomerName?: string
    setDoesNotExistInTwinfield: () => void
    onChangeDoesNotExistInTwinfield: (event: React.ChangeEvent<HTMLInputElement>) => void
    onChangeTwinfieldCustomerCode: (event: React.ChangeEvent<HTMLInputElement>) => void
    changePrivatePaymentIsDUO: (value: boolean) => void
    changePrivatePaymentDUOHasApprovedFund: (value: boolean) => void
    changeUseAddressAsPrivatePaymentAddress: (value: boolean) => void
    privatePaymentCannotChange?: boolean
    typeOfLearner?: UserTypeOfLearnerTypesEnum
}

export class UserLearnerPaymentDetailsFieldGroup extends React.Component<Props> {
    public render() {
        const {
            errors,
            user,
            paymentBy,
            changePaymentBy,
            privatePaymentIsDUO,
            privatePaymentDUOHasApprovedFund,
            useAddressAsPrivatePaymentAddress,
            twinfieldCustomerCode,
            doesNotExistInTwinfield,
            lookupTwinfieldCustomerLoading,
            lookupTwinfieldCustomerExists,
            lookupTwinfieldCustomerName,
            onChangeTwinfieldCustomerCode,
            setDoesNotExistInTwinfield,
            onChangeDoesNotExistInTwinfield,
            changePrivatePaymentIsDUO,
            changePrivatePaymentDUOHasApprovedFund,
            changeUseAddressAsPrivatePaymentAddress,
            privatePaymentCannotChange,
            typeOfLearner,
        } = this.props

        const canTwinfieldCustomerCodeChange = !!get(user, 'learner.canTwinfieldCustomerCodeChange')
        const TopLevelFieldWrapperComponent = privatePaymentCannotChange ? React.Fragment : React.Fragment
        const SubFieldWrapperComponent = privatePaymentCannotChange ? FieldGroup : React.Fragment
        const SubFieldWrapperComponentProps = privatePaymentCannotChange ? { isInsetGroup: true } : {}
        const paymentByOptions = LearnerProfileEditForm.getPaymentByOptionsForIntegrationLawType(typeOfLearner) || []
        const isIntegrationCourse =
            typeOfLearner &&
            [UserTypeOfLearnerTypesEnum.Integration2013, UserTypeOfLearnerTypesEnum.Integration2021].includes(
                typeOfLearner
            )

        return (
            <FieldGroup {...(privatePaymentCannotChange ? {} : { title: 'Betalingsgegevens' })} isForm={true}>
                <LockedFieldWrapper disableLock={this.isPrivatePaymentFieldLockDisabled()}>
                    {isLocked => (
                        <React.Fragment>
                            {!privatePaymentCannotChange && (
                                <Field title="Betaling" errors={errors}>
                                    <MultiInput type="radio">
                                        {paymentByOptions.map(paymentByOption => (
                                            <RadioButton
                                                key={paymentByOption}
                                                name="user.learner.paymentBy"
                                                value={paymentByOption}
                                                isDisabled={isLocked}
                                                checked={paymentBy === paymentByOption}
                                                onClick={() => changePaymentBy(paymentByOption)}
                                            >
                                                {translateType('paymentBy', paymentByOption)}
                                            </RadioButton>
                                        ))}
                                    </MultiInput>
                                </Field>
                            )}
                            {isIntegrationCourse === true && paymentBy && paymentBy !== PaymentByType.Organization && (
                                <TopLevelFieldWrapperComponent>
                                    <Field title="Type betaler" errors={errors}>
                                        <MultiInput type="radio">
                                            <RadioButton
                                                name="user.learner.privatePaymentIsDUO"
                                                value={false}
                                                isDisabled={isLocked}
                                                defaultChecked={!privatePaymentIsDUO}
                                                onClick={() => changePrivatePaymentIsDUO(false)}
                                            >
                                                Zelfbetaler
                                            </RadioButton>
                                            <RadioButton
                                                name="user.learner.privatePaymentIsDUO"
                                                value={true}
                                                isDisabled={isLocked}
                                                defaultChecked={privatePaymentIsDUO}
                                                onClick={() => changePrivatePaymentIsDUO(true)}
                                            >
                                                DUO
                                            </RadioButton>
                                        </MultiInput>
                                    </Field>
                                    <SubFieldWrapperComponent {...(SubFieldWrapperComponentProps as any)}>
                                        {privatePaymentIsDUO && (
                                            <Field title="DUO lening goedgekeurd" errors={errors}>
                                                <MultiInput type="radio">
                                                    <RadioButton
                                                        name="user.learner.privatePaymentDUOHasApprovedFund"
                                                        value={true}
                                                        isDisabled={isLocked}
                                                        defaultChecked={privatePaymentDUOHasApprovedFund}
                                                        onClick={() => changePrivatePaymentDUOHasApprovedFund(true)}
                                                    >
                                                        Ja
                                                    </RadioButton>
                                                    <RadioButton
                                                        name="user.learner.privatePaymentDUOHasApprovedFund"
                                                        value={false}
                                                        isDisabled={isLocked}
                                                        defaultChecked={!privatePaymentDUOHasApprovedFund}
                                                        onClick={() => changePrivatePaymentDUOHasApprovedFund(false)}
                                                    >
                                                        Nee
                                                    </RadioButton>
                                                </MultiInput>
                                            </Field>
                                        )}
                                        {privatePaymentIsDUO && privatePaymentDUOHasApprovedFund && (
                                            <React.Fragment>
                                                <Field title="Leenruimte bij aanmelding" errors={errors}>
                                                    <UnitInput
                                                        name={`user.learner.privatePaymentDUOInitialRemainingFund`}
                                                        defaultValue={get(
                                                            user,
                                                            `learner.privatePaymentDUOInitialRemainingFund`
                                                        )}
                                                        isDisabled={isLocked}
                                                        unitType={unitType.euro}
                                                        isReadonly={
                                                            !get(
                                                                user,
                                                                `learner.canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser`
                                                            )
                                                        }
                                                    />
                                                    {!get(
                                                        user,
                                                        `learner.canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser`
                                                    ) && (
                                                        <Hint>
                                                            Dit veld kan niet meer worden aangepast.{` `}
                                                            Mutaties kunnen worden ingevoerd op de Facturatie tab van de
                                                            kandidaat.
                                                        </Hint>
                                                    )}
                                                </Field>
                                            </React.Fragment>
                                        )}
                                        {(!privatePaymentIsDUO || privatePaymentDUOHasApprovedFund) && (
                                            <React.Fragment>
                                                <Field title="Debiteurnummer (bestaande kandidaat)" errors={errors}>
                                                    <Input
                                                        name="user.learner.twinfieldCustomerCode"
                                                        type="text"
                                                        isDisabled={isLocked}
                                                        value={twinfieldCustomerCode || ''}
                                                        onChange={onChangeTwinfieldCustomerCode}
                                                        placeholder={
                                                            doesNotExistInTwinfield
                                                                ? `Debiteurnummer wordt automatisch gegenereerd`
                                                                : `Debiteurnummer`
                                                        }
                                                        isReadonly={
                                                            doesNotExistInTwinfield || !canTwinfieldCustomerCodeChange
                                                        }
                                                    />
                                                    {this.hasTwinfieldCustomerCode() && (
                                                        <React.Fragment>
                                                            {lookupTwinfieldCustomerLoading && (
                                                                <Spinner size="small" label="Aan het verifiëren..." />
                                                            )}
                                                            {!lookupTwinfieldCustomerLoading && (
                                                                <React.Fragment>
                                                                    {lookupTwinfieldCustomerExists && (
                                                                        <Hint>
                                                                            Naam in Twinfield:{' '}
                                                                            {lookupTwinfieldCustomerName}
                                                                        </Hint>
                                                                    )}
                                                                    {!lookupTwinfieldCustomerExists && (
                                                                        <Hint>
                                                                            Debiteur niet gevonden,{` `}
                                                                            <Link onClick={setDoesNotExistInTwinfield}>
                                                                                nieuwe debiteur aanmaken
                                                                            </Link>
                                                                            .
                                                                        </Hint>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </Field>
                                                {canTwinfieldCustomerCodeChange && (
                                                    <Field title="&nbsp;" errors={errors}>
                                                        <React.Fragment>
                                                            <CheckBox
                                                                name="user.learner.doesNotExistInTwinfield"
                                                                onChange={onChangeDoesNotExistInTwinfield}
                                                                checked={doesNotExistInTwinfield || false}
                                                                isDisabled={isLocked}
                                                                isReadonly={!canTwinfieldCustomerCodeChange}
                                                                isHorizontal={true}
                                                            >
                                                                Deze kandidaat is nieuw bij TopTaal en heeft nog geen
                                                                factuurgeschiedenis.{` `}
                                                                Maak een debiteurennummer aan in Twinfield.
                                                            </CheckBox>
                                                            {doesNotExistInTwinfield && (
                                                                <Hint>
                                                                    Er wordt een debiteur in Twinfield aangemaakt zodra
                                                                    je op Opslaan klikt.{` `}
                                                                    Het debiteurnummer zal automatisch worden ingevuld.
                                                                </Hint>
                                                            )}
                                                        </React.Fragment>
                                                    </Field>
                                                )}
                                                {!canTwinfieldCustomerCodeChange && (
                                                    <Field title="&nbsp;">
                                                        <Hint>
                                                            Dit veld kan niet meer worden aangepast,{` `}
                                                            omdat er al facturen zijn verzonden op basis van dit
                                                            debiteurnummer.
                                                        </Hint>
                                                    </Field>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {!privatePaymentIsDUO && (
                                            <React.Fragment>
                                                <Field title="Adresgegevens als factuuradres gebruiken">
                                                    <MultiInput type="radio">
                                                        <RadioButton
                                                            name="addressAsPrivatePaymentAddress"
                                                            value={true}
                                                            isDisabled={isLocked}
                                                            defaultChecked={useAddressAsPrivatePaymentAddress}
                                                            onClick={() =>
                                                                changeUseAddressAsPrivatePaymentAddress(true)
                                                            }
                                                        >
                                                            Ja
                                                        </RadioButton>
                                                        <RadioButton
                                                            name="addressAsPrivatePaymentAddress"
                                                            value={false}
                                                            isDisabled={isLocked}
                                                            defaultChecked={!useAddressAsPrivatePaymentAddress}
                                                            onClick={() =>
                                                                changeUseAddressAsPrivatePaymentAddress(false)
                                                            }
                                                        >
                                                            Nee
                                                        </RadioButton>
                                                    </MultiInput>
                                                </Field>
                                                {!useAddressAsPrivatePaymentAddress && (
                                                    <LocationFieldsInputGroup
                                                        title={'Adres'}
                                                        errors={errors}
                                                        name={'user.learner.privatePaymentAddress'}
                                                        isLocked={isLocked}
                                                        location={user.learner && user.learner.privatePaymentAddress}
                                                    />
                                                )}
                                                {useAddressAsPrivatePaymentAddress && (
                                                    <input type="hidden" name="user.learner.privatePaymentAddress" />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </SubFieldWrapperComponent>
                                </TopLevelFieldWrapperComponent>
                            )}
                        </React.Fragment>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }

    private hasTwinfieldCustomerCode() {
        const { twinfieldCustomerCode } = this.props

        return !!(twinfieldCustomerCode || '').trim()
    }

    private isPrivatePaymentFieldLockDisabled() {
        const { user, typeOfLearner } = this.props

        return (
            !isBoolean(get(user, 'learner.hasPrivatePayment')) &&
            !typeOfLearner &&
            !get(user, 'learner.twinfieldCustomer') &&
            !get(user, `learner.privatePaymentAddress.street`) &&
            !isNumber(get(user, `learner.privatePaymentAddress.number`)) &&
            !get(user, `learner.privatePaymentAddress.numberAddition`) &&
            !get(user, `learner.privatePaymentAddress.zipcode`) &&
            !get(user, 'learner.privatePaymentAddress.nl.extraInfo.city') &&
            !get(user, `learner.privatePaymentAddress.country`)
        )
    }
}
