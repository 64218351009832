import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import Bold from '~/components/Bold'
import FileInput from '~/components/FileInput'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { Button } from '~/components'
import { ListItem } from '~/components/ListItem'
import { gql } from '@apollo/client'
import { Mutator, toast } from '~/utils'
import { FinalExamSubmitters, FinalExam } from '~/types/FinalExam'
import translateType from '~/shared/utils/translateType'

interface Props {
    onClose: () => void
    finalExam: FinalExam
    onSubmitSuccess: () => void
}

const CANCEL_FINAL_EXAM = gql`
    mutation _($finalExamId: MongoID, $file: UploadedFile) {
        finalExams_cancel(finalExamId: $finalExamId, file: $file)
    }
`

export class FinalExamIsCancelledModalForm extends React.Component<Props> {
    private formRef: any
    private cancelFinalExamMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.cancelFinalExamMutator = new Mutator({
            mutation: CANCEL_FINAL_EXAM,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose, finalExam } = this.props

        return (
            <Form ref={el => (this.formRef = el)}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field>
                            Je staat op het punt om het examen{' '}
                            <Bold>
                                {translateType('finalExamPart', finalExam.part)}
                                {finalExam.level ? ` (${finalExam.level})` : ''}
                            </Bold>{' '}
                            van <Bold>{finalExam.user.profile.name}</Bold> te verwijderen. De kandidaat zal een e-mail
                            ontvangen dat dit examen niet meer door zal gaan.
                        </Field>
                    </FieldGroup>
                    {finalExam.submitter === FinalExamSubmitters.TopTaal && (
                        <FieldGroup isInsetGroup={true} isForm={true} noNextField={true}>
                            <Field title={`Upload annuleringsbevestiging`}>
                                <FileInput name={`file`} multiple={false} accept={`application/pdf`}>
                                    Kies bestand
                                </FileInput>
                            </Field>
                        </FieldGroup>
                    )}
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleer
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button
                                    onClick={() => this.onSubmit(undefined, this.formRef.getFormValues())}
                                    confirm={{
                                        title: 'Verwijderen',
                                        message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                        execute: {
                                            buttonType: 'danger',
                                            title: 'Annulering doorgeven',
                                        },
                                    }}
                                    type={`submit`}
                                    shouldPreventSubmit={true}
                                    styleOverride={`danger`}
                                >
                                    Annulering doorgeven
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { finalExam, onSubmitSuccess } = this.props
        const data = await this.cancelFinalExamMutator.mutate({
            finalExamId: finalExam._id,
            file: fields.file,
        })

        if (data && data.finalExams_cancel === true) {
            toast.success(`Annulering is doorgegeven`)
            onSubmitSuccess()
        }
    }
}
