import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, find } from 'lodash'
import c from 'classnames'

import { callingCodeOptions } from '~/utils/callingCodeOptions'
import PrefixedInput from './PrefixedInput'
import MultiInput from './MultiInput'
import Input from './Input'

export default class PhoneInput extends Component {
    static propTypes = {
        name: PropTypes.string,
        defaultValue: PropTypes.shape({
            phoneNumber: PropTypes.string,
            country: PropTypes.string,
            info: PropTypes.string,
        }),
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
    }

    getClassName() {
        const { className } = this.props
        return c('tt-PhoneInput', className)
    }

    render() {
        const { name, defaultValue, className, isDisabled } = this.props

        return (
            <MultiInput type={`phone`} className={this.getClassName()}>
                <PrefixedInput
                    type={`tel`}
                    placeholder={`Telefoonnummer`}
                    prefixPlaceholder={`Land`}
                    className={className}
                    name={`${name}.phoneNumber`}
                    defaultValue={get(defaultValue, 'phoneNumber')}
                    isDisabled={isDisabled}
                    prefixName={`${name}.country`}
                    prefixOptions={callingCodeOptions}
                    prefixDefaultValue={get(defaultValue, 'country') || `NL`}
                    prefixDefaultSelectionOnInputChange={find(callingCodeOptions, { value: 'NL' }).value}
                />
                <Input
                    type="text"
                    name={`${name}.info`}
                    defaultValue={get(defaultValue, 'info')}
                    isDisabled={isDisabled}
                    placeholder="Toelichting"
                />
            </MultiInput>
        )
    }
}
