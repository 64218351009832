import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { bindAll, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import en from '~/languages/en.i18n.json'
import nl from '~/languages/nl.i18n.json'

// configuration
i18next.use(LanguageDetector).init({
    // set language to 'nl' by default, this overrides the languagedetector
    // when we have proper language integration, we should remove this setting
    lng: 'nl',
    resources: {
        en: { translation: en },
        nl: { translation: nl },
    },
    whitelist: ['nl', 'en'],
    nonExplicitWhitelist: true,
    preload: ['nl', 'en'],
    parseMissingKeyHandler: key => `${key}`,
})

// debug
window.i18next = i18next

// translate('hello-world') => "Hello world!"
export function translate(...args) {
    return i18next.t(...args)
}

// changeLanguage('en')
export function changeLanguage(...args) {
    return i18next.changeLanguage(...args)
}

// getCurrentLanguage() => "nl"
export function getCurrentLanguage() {
    return i18next.language
}

export function getTranslateComponent({ namespace } = {}) {
    return class T extends Component {
        static propTypes = {
            i18n: PropTypes.any,
        }

        constructor(props) {
            super(props)

            this.translator = new Translator({
                namespace: namespace,
                subscribe: this,
            })
        }

        componentWillUnmount() {
            this.translator.unsubscribe(this)
        }

        render() {
            return <span>{this.translator.t(this.props.i18n)}</span>
        }
    }
}

// import { Translator, translate } from '~/services/i18n'
// const translator = new Translator({ namespace: 'App.Users.Employees.Detail' });
// const { t } = translator;
// t('actionbar.buttons.edit'); is the same as translate('App.Users.Employees.Detail.actionbar.buttons.edit');
export class Translator {
    constructor({ namespace, subscribe: instance }) {
        bindAll(this, ['t', '_updateSubscriptions', 'subscribe', 'unsubscribe'])

        this._namespace = namespace
        if (instance) this.subscribe(instance)
    }

    _subscriptions = []

    _updateSubscriptions() {
        this._subscriptions.forEach(i => i.forceUpdate())
    }

    // translator.subscribe(componentInstance);
    subscribe(instance) {
        this._subscriptions.push(instance)
        if (!isEmpty(this._subscriptions)) {
            i18next.on('languageChanged', this._updateSubscriptions)
        }
    }

    // translator.unsubscribe(componentInstance);
    unsubscribe(instance) {
        const index = this._subscriptions.indexOf(instance)
        this._subscriptions.splice(index, 1)
        if (isEmpty(this._subscriptions)) {
            i18next.off('languageChanged', this._updateSubscriptions)
        }
    }

    t(key, ...args) {
        return i18next.t(`${this._namespace}.${key}`, ...args)
    }
}

export default i18next
