import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get, capitalize } from 'lodash'

import { Subtle } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

export default class LessonsSchedule extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
    }

    render() {
        const { group } = this.props
        const generalWeekLessonDates = get(group, 'generalWeekLessonDates') || []
        const lessonDuration = get(group, 'module.lessonDuration')
        const generalWeekLessonTeacherUsers = get(group, 'generalWeekLessonTeacherUsers') || []
        const generalWeekLessonRooms = get(group, 'generalWeekLessonRooms') || []
        const timetableLessons = get(group, 'timetableLessons')
        const startDateIndex = timetableLessons[0].generalWeekLessonDateIndex

        return (
            generalWeekLessonDates.length > 0 && (
                <div className={`tt-LessonsSchedule`}>
                    <List horizontal textLeft>
                        {generalWeekLessonDates.map((weekLessonDate, index) => {
                            const lessons = timetableLessons.filter(
                                ({ generalWeekLessonDateIndex }) => generalWeekLessonDateIndex === index
                            )
                            const teacherName =
                                (generalWeekLessonTeacherUsers[index] &&
                                    get(generalWeekLessonTeacherUsers[index], 'profile.name')) ||
                                ''
                            const roomName = get(generalWeekLessonRooms, `${index}.name`) || ''
                            const fromMoment = moment(weekLessonDate)
                            const toMoment = lessonDuration && moment(weekLessonDate).add(lessonDuration, 'minutes')
                            const date = `${capitalize(fromMoment.format('dddd HH:mm'))}${
                                toMoment ? ` - ${toMoment.format('HH:mm')}` : ``
                            }`

                            if (index < startDateIndex) {
                                lessons.unshift({ isPaddedLesson: true })
                            }

                            return (
                                <ListItem key={index}>
                                    <p className={`tt-LessonsSchedule__LessonDate`}>{date}</p>

                                    {teacherName && (
                                        <p>
                                            <Subtle>{teacherName}</Subtle>
                                        </p>
                                    )}
                                    {roomName && (
                                        <p>
                                            <Subtle>Lesruimte: {roomName}</Subtle>
                                        </p>
                                    )}
                                    {!teacherName && <br />}
                                    {!roomName && <br />}

                                    <ul>
                                        {lessons.length > 0 ? (
                                            lessons.map(({ lesson, isPaddedLesson }, index) => {
                                                if (isPaddedLesson) {
                                                    return <li key={index}>&nbsp;</li>
                                                }

                                                if (!lesson) {
                                                    return (
                                                        <li key={index}>
                                                            <Subtle>Lesdag kan niet doorgaan</Subtle>
                                                        </li>
                                                    )
                                                }

                                                return (
                                                    <li key={index}>
                                                        Les {lesson.order + 1}:{' '}
                                                        {moment(lesson.date).format('DD-MM-YYYY')}
                                                    </li>
                                                )
                                            })
                                        ) : (
                                            <li>
                                                <Subtle>Er zijn geen lessen</Subtle>
                                            </li>
                                        )}
                                    </ul>
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            )
        )
    }
}
