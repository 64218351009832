import React from 'react'
import Link from '~/components/Link'
import { LearnerContractLog, Contract } from '~/types/Contracts'
import ReadableDate from '~/components/ReadableDate'

interface LearnerContractsLogProps {
    log: LearnerContractLog
    contract: Contract
}

export const ContactCreatedByGroupLog: React.SFC<LearnerContractsLogProps> = ({
    log: {
        typeData: { group },
    },
}) => {
    if (!group) {
        return null
    }

    return (
        <span>
            Contract aangemaakt via de groep
            <Link route={`/groups/${group._id}/info`}> {group.name} </Link>
        </span>
    )
}

export const ContractMadeFinalByLog: React.SFC<LearnerContractsLogProps> = ({ log: { createdByUser } }) => {
    if (!createdByUser) {
        return null
    }

    return (
        <span>
            Contract definitief gemaakt door
            <Link route={`/users/learners/${createdByUser._id}`}> {createdByUser.profile.name} </Link>
        </span>
    )
}

export const SignatureUploadedLog: React.SFC<LearnerContractsLogProps> = ({ log: { createdByUser } }) => {
    if (!createdByUser) {
        return null
    }

    return (
        <span>
            Handtekening geüpload door
            <Link route={`/users/learners/${createdByUser._id}`}> {createdByUser.profile.name} </Link>
        </span>
    )
}

export const contractSignatureChanged: React.SFC<LearnerContractsLogProps> = ({ log: { createdByUser } }) => {
    if (!createdByUser) {
        return null
    }

    return (
        <span>
            Handtekening gewijzigd door
            <Link route={`/users/learners/${createdByUser._id}`}> {createdByUser.profile.name} </Link>
        </span>
    )
}

export const ContractEnded: React.SFC<LearnerContractsLogProps> = ({ log: { createdByUser }, contract }) => {
    if (!createdByUser || !contract) {
        return null
    }

    return (
        <span>
            Contract beëindigd door
            <Link route={`/users/learners/${createdByUser._id}`}> {createdByUser.profile.name} </Link>
            per <ReadableDate date={contract.terminatedAt} format={'DD-MM-YYYY'} />
        </span>
    )
}
