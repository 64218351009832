import React from 'react'
import { InflowMomentFieldGroup, ValidationErrorType } from './InflowMomentFieldGroup'
import { DrawerModal, Link, Field } from '~/components'
import { InflowMoment } from '~/types/InflowMoments'
import { InflowUserIntakersForm } from '../Forms/InflowUserIntakersForm'
import { ListItem } from '~/components/ListItem'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import { List } from '~/components/List'
import { Mutator } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { ValidationError } from '~/components/ValidationError'
import { getCurrentUser } from '~/services/session'
import Subtle from '~/components/Subtle'
import Emphasis from '~/components/Emphasis'

interface Props {
    validationErrors?: ValidationErrorType[]
    inflowMoment: InflowMoment
    refetch: () => void
    errors?: string[]
    hideRemove?: boolean
}

interface State {}

export class InflowMomentIntakerUsersGroup extends React.Component<Props, State> {
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const currentUser = getCurrentUser()
        const { isOrganizationContact, isExternalTeacher } = currentUser
        const { validationErrors, inflowMoment, errors, hideRemove } = this.props

        const DynamicComponent = !isOrganizationContact && !isExternalTeacher ? Link : 'span'
        const { intakerUsers } = inflowMoment

        return (
            <InflowMomentFieldGroup
                title={`Toetsdocenten`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                inflowMoment={inflowMoment}
                onRemove={this.onRemove}
                removeMessage={
                    'Je staat op het punt de toetsdocenten uit dit toetsmoment te verwijderen. Weet je het zeker?'
                }
                hideRemove={hideRemove}
            >
                <Field>
                    {intakerUsers && intakerUsers.length > 0 ? (
                        <List>
                            {intakerUsers.map(user => (
                                <ListItem key={user._id}>
                                    <DynamicComponent route={getUserDetailRoute(user)}>
                                        {user.profile.name}
                                    </DynamicComponent>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        this.renderEmptyState()
                    )}
                    {errors && errors.includes('all-teachers-should-be-active-and-intaker') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`Niet alle geselecteerde toetsdocenten hebben de intaker functie, of de toetsdocenten zijn non-actief.`}
                        />
                    )}
                </Field>
            </InflowMomentFieldGroup>
        )
    }

    private renderEmptyState = () => {
        return (
            <Subtle>
                <Emphasis>Geen</Emphasis>
            </Subtle>
        )
    }

    private getEditModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <InflowUserIntakersForm inflowMoment={inflowMoment} refetch={refetch} closeModal={closeModal} />
            </DrawerModal>
        )
    }

    private onRemove = () => {
        const { refetch, inflowMoment } = this.props

        this.inflowMomentMutator
            .mutate({
                inflowMoment: {
                    _id: inflowMoment._id,
                    nulls: ['intakerUserIds'],
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                }
            })
    }
}
