import React from 'react'
import { getStandardPlaceholderForType } from '~/utils/standardFieldPlaceholders'
import { ClassValue, BEM } from '~/services/BEMService'

export type InputValuePropType = string | string[] | undefined | number | null | boolean | Date

export interface InputProps {
    autoFocus?: boolean
    checked?: boolean
    className?: ClassValue
    defaultChecked?: boolean
    defaultValue?: InputValuePropType
    errorKey?: string
    hasError?: boolean
    isDisabled?: boolean
    isReadonly?: boolean
    max?: React.InputHTMLAttributes<HTMLInputElement>['max']
    min?: React.InputHTMLAttributes<HTMLInputElement>['min']
    name?: string
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    placeholder?: string
    step?: React.InputHTMLAttributes<HTMLInputElement>['step']
    style?: React.HtmlHTMLAttributes<HTMLInputElement>['style']
    type?: string
    value?: InputValuePropType
    accept?: string
    multiple?: boolean
    required?: boolean
    hasIcon?: boolean
}

export default class Input extends React.Component<InputProps> {
    private bem = new BEM('Input', () => ({
        'is-disabled': this.props.isDisabled,
        'is-readonly': this.props.isReadonly,
        'has-error': this.props.hasError,
    }))

    public render() {
        const {
            type,
            name,
            value,
            style,
            checked,
            className,
            defaultValue,
            defaultChecked,
            autoFocus,
            step,
            min,
            max,
            accept,
            errorKey: _errorKey,
            multiple,
            isDisabled,
            isReadonly,
        } = this.props

        const errorKey = _errorKey || name

        return (
            <input
                // TODO: Fix this issue and re-enable the no-string-refs rule
                // eslint-disable-next-line react/no-string-refs
                ref="input"
                className={this.bem.getClassName(className)}
                type={type}
                name={name}
                step={step}
                min={min}
                max={max}
                placeholder={this.getPlaceholder()}
                value={value as any}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onClick={this.onClick}
                style={style}
                checked={checked}
                defaultChecked={defaultChecked}
                defaultValue={defaultValue as any}
                autoFocus={autoFocus}
                onInvalid={this.onInvalid}
                accept={accept}
                data-errorkey={errorKey}
                multiple={multiple}
                disabled={isDisabled}
                readOnly={isReadonly}
            />
        )
    }

    private onInvalid = (event: React.FormEvent<HTMLInputElement>) => {
        const inputTopPos = (event.target as HTMLInputElement).getBoundingClientRect().top
        const bodyTopPos = document.body.getBoundingClientRect().top
        const offset = bodyTopPos - inputTopPos
        window.scrollBy(0, offset)
    }

    private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const { onFocus } = this.props

        if (onFocus) {
            onFocus(event)
        }
    }

    private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { onBlur } = this.props

        if (onBlur) {
            onBlur(event)
        }
    }

    private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { onKeyDown } = this.props

        if (onKeyDown) {
            onKeyDown(event)
        }
    }

    private onClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const { onClick } = this.props

        if (onClick) {
            onClick(event)
        }
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props

        if (onChange) {
            onChange(event)
        }
    }

    private getPlaceholder = () => {
        const { placeholder, type } = this.props

        if (!placeholder) {
            return getStandardPlaceholderForType(type)
        }

        return placeholder
    }
}
