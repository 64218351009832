import React from 'react'
import { TeacherAvailability } from '~/implementations'
import { RouteComponentProps } from 'react-router'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {}

export default class AvailabilityView extends React.Component<Props> {
    public render() {
        const { params } = this.props

        return <TeacherAvailability teacherUserId={params.id} />
    }
}
