import React from 'react'
import { ClassValue } from '~/services/BEMService'
import { Field, ReadableAddress, Placeholder, ReadableAddressCountry } from '.'
import { User } from '~/types/User'

interface Props {
    className?: ClassValue
    user: User
}

export default class AddressFieldGroup extends React.Component<Props> {
    public render() {
        const { user } = this.props
        const { profile } = user
        const visitAddress = profile && profile.visitAddress

        return (
            <>
                <Field title="Adres">
                    <ReadableAddress address={profile.address} />
                </Field>
                <Field title="Land">
                    <ReadableAddressCountry address={profile.address} />
                </Field>
                {user.isLearner && (
                    <Field title="Bezoekadres">
                        {visitAddress ? (
                            <ReadableAddress address={visitAddress} />
                        ) : (
                            <Placeholder>Zelfde als woonadres</Placeholder>
                        )}
                    </Field>
                )}
                {user.isLearner && visitAddress && (
                    <Field title="Land van bezoekadres">
                        <ReadableAddressCountry address={visitAddress} />
                    </Field>
                )}
            </>
        )
    }
}
