import './NoteTextArea.scss'
import c from 'classnames'
import * as React from 'react'
import TextArea from '~/components/TextArea'

interface Props {
    className?: string
    name: string
    defaultValue?: string
}

export class NoteTextArea extends React.Component<Props, {}> {
    public render() {
        const { name, defaultValue } = this.props

        return (
            <TextArea
                className={this.getClassName()}
                placeholder="Maak hier je notitie..."
                name={name}
                defaultValue={defaultValue}
            />
        )
    }

    private getClassName(): string {
        const { className } = this.props

        return c('tt-NoteTextArea', className)
    }
}
