import * as React from 'react'
import { ListItem } from '~/components/ListItem'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    right?: boolean
}

export class MainNavItem extends React.Component<Props> {
    private bem = new BEM('MainNavItem', () => ({
        right: this.props.right,
    }))

    public render() {
        const { children, right, className } = this.props

        return (
            <ListItem className={this.bem.getClassName(className)} right={right}>
                {children}
            </ListItem>
        )
    }
}
