import React from 'react'

import { isString } from 'lodash'

export default content => {
    if (!content || !isString(content)) {
        return null
    }

    return content
        .trim()
        .split('\n')
        .map((item, key, array) => {
            // Trim any starting and ending spaces, or tabs
            item = item.trim()

            if (!item) {
                item = <br />
            }

            if (key === array.length - 1) {
                return <span key={key}>{item}</span>
            }

            return (
                <span style={{ display: 'block' }} key={key}>
                    {item}
                </span>
            )
        })
}
