import React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { gql } from '@apollo/client'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import { Fetcher, Mutator } from '~/utils'
import { InflowModule } from '~/types/InflowModule'
import { InflowMoment } from '~/types/InflowMoments'
import { User } from '~/types/User'
import { ValueActionField } from '~/components/Core/Form/ValueActionField/ValueActionField'
import { ValueActionFieldItem } from '~/components/Core/Form/ValueActionField/ValueActionFieldItem'
import { ValidationError } from '../ValidationError'
import { flatten, uniqBy } from 'lodash'
import { Organization } from '~/types/Organization'
import { browserHistory } from 'react-router'
const moment = require('moment')

const GET_INFLOW_MODULES_QUERY = gql`
    query _ {
        inflowModules(filterByLocked: true, filterByInUse: true) {
            _id
            name
        }
    }
`

const GET_INFLOW_MOMENT_QUERY = gql`
    query _($filters: InflowMomentFilterInputType) {
        inflowMoments(filters: $filters) {
            _id
            location {
                _id
                name
            }
            dateRange {
                from
            }
            amountOfSlotsPerTimeslot
            timeslots {
                _id
                startTime
                endTime
                maxUsers
                timeslotUsers {
                    _id
                    user {
                        _id
                    }
                }
            }
        }
    }
`

const ADD_USERS_TO_INFLOW_MOMENT_TIMESLOTS_MUTATION = gql`
    mutation _($inflowMomentId: MongoID, $inflowMomentTimeslots: [InflowMomentTimeslotInputType]) {
        inflowMomentTimeslots_multiple_changeUsers(
            inflowMomentId: $inflowMomentId
            inflowMomentTimeslots: $inflowMomentTimeslots
        )
    }
`

interface Props {
    onCancel: () => void
    selectedUsers: User[]
}

interface TimeslotForInflowMoment {
    _id: string
    startTime: Date
    maxSlots: number
    usedByUserIds: string[]
}

interface State {
    selectedInflowModuleId?: string
    selectedInflowMomentId?: string
    timeslotsForInflowMoment: TimeslotForInflowMoment[]
    filterInflowMomentByOrganizationIds: string[]
}

export class AddCandidatesToInflowMomentForm extends React.Component<Props, State> {
    public state: State = {
        selectedInflowModuleId: undefined,
        selectedInflowMomentId: undefined,
        timeslotsForInflowMoment: [],
        filterInflowMomentByOrganizationIds: [],
    }

    private inflowModulesFetcher: Fetcher
    private inflowMomentFetcher: Fetcher
    private addToInflowMomentTimeslotsMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowModulesFetcher = new Fetcher({
            query: GET_INFLOW_MODULES_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.inflowMomentFetcher = new Fetcher({
            query: GET_INFLOW_MOMENT_QUERY,
            preventInitialFetch: true,
            onChange: () => this.forceUpdate(),
        })

        this.addToInflowMomentTimeslotsMutator = new Mutator({
            mutation: ADD_USERS_TO_INFLOW_MOMENT_TIMESLOTS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel } = this.props
        const { selectedInflowModuleId } = this.state
        const isMutating = false

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        {this.renderInflowModuleField()}
                        {!!selectedInflowModuleId && this.renderInflowMomentField()}
                    </FieldGroup>
                    <FieldGroup isForm={true} isInsetGroup={true} isLastFieldInModal={true}>
                        {this.renderUserActionFields()}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel} shouldPreventSubmit={true}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button isLoading={isMutating} type={`submit`}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderInflowModuleField = () => {
        const { data, loading: isFetching } = this.inflowModulesFetcher
        const { inflowModules = [] } = data
        const inflowModuleOptions = inflowModules.map((inflowModule: InflowModule) => ({
            value: inflowModule._id,
            label: inflowModule.name,
        }))

        return (
            <Field isLabel={true} title={`Instroommodule`}>
                <TagPicker
                    multi={false}
                    isLoading={isFetching}
                    options={inflowModuleOptions}
                    placeholder={`Selecteer module`}
                    onChange={this.onChangeInflowModule}
                />
            </Field>
        )
    }

    private renderInflowMomentField = () => {
        const { data: inflowMomentData, loading } = this.inflowMomentFetcher
        const { inflowMoments } = inflowMomentData

        if (loading) {
            return null
        }

        if (!inflowMoments || inflowMoments.length === 0) {
            return (
                <Field>
                    <ValidationError
                        isWarning={true}
                        text={'Er zijn geen actieve toetsmomenten voor deze module gevonden'}
                    />
                </Field>
            )
        }

        const inflowMomentOptions = inflowMoments.map((inflowMoment: InflowMoment) => ({
            value: inflowMoment._id,
            label: `${moment(inflowMoment.dateRange.from).format('dd DD-MM-YYYY')} - ${inflowMoment.location.name}`,
        }))

        return (
            <FieldGroup isInsetGroup={true}>
                <Field title={`Datum en locatie`}>
                    <TagPicker
                        multi={false}
                        isLoading={loading}
                        name={`inflowMomentId`}
                        placeholder={`Selecteer datum en locatie`}
                        options={inflowMomentOptions}
                        onChange={this.onChangeInflowMoment}
                    />
                </Field>
            </FieldGroup>
        )
    }

    private renderUserActionFields = () => {
        const { selectedUsers } = this.props
        const { timeslotsForInflowMoment, selectedInflowMomentId } = this.state

        return (
            <ValueActionField>
                {selectedUsers &&
                    selectedUsers.map((user, index) => {
                        const timeslotOptions =
                            timeslotsForInflowMoment &&
                            timeslotsForInflowMoment
                                .filter(
                                    ({ usedByUserIds, maxSlots }) =>
                                        usedByUserIds.length < maxSlots || usedByUserIds.includes(user._id)
                                )
                                .map(timeslot => ({
                                    value: timeslot._id,
                                    label: moment(timeslot.startTime).format(`HH:mm`),
                                }))

                        return (
                            <React.Fragment key={index}>
                                <input type={`hidden`} name={`timeslotUsers[${index}].userId`} value={user._id} />
                                <ValueActionFieldItem
                                    key={user._id}
                                    value={user.profile.name}
                                    renderActions={() => (
                                        <TagPicker
                                            multi={false}
                                            name={`timeslotUsers[${index}].timeslot`}
                                            placeholder={`Tijd`}
                                            options={timeslotOptions}
                                            onChange={this.onChangeTimeslot(user)}
                                            isDisabled={!selectedInflowMomentId}
                                        />
                                    )}
                                />
                            </React.Fragment>
                        )
                    })}
            </ValueActionField>
        )
    }

    private onSubmit = () => {
        const { selectedInflowMomentId, timeslotsForInflowMoment } = this.state
        const { onCancel } = this.props

        const timeSlots = timeslotsForInflowMoment
            .map(timeSlot => {
                if (timeSlot.usedByUserIds.length <= 0) {
                    return
                }

                return {
                    inflowMomentTimeslotId: timeSlot._id,
                    userIds: timeSlot.usedByUserIds,
                }
            })
            .filter(timeSlot => !!timeSlot)

        this.addToInflowMomentTimeslotsMutator
            .mutate({
                inflowMomentId: selectedInflowMomentId,
                inflowMomentTimeslots: timeSlots,
            })
            .then(data => {
                if (data && data.inflowMomentTimeslots_multiple_changeUsers) {
                    onCancel()
                    browserHistory.push(`/inflow-moments/definitive/${selectedInflowMomentId}`)
                }
            })
    }

    private onChangeInflowModule = async (option?: TagPickerOption) => {
        const selectedInflowModuleId = option && option.value
        const organizationsIds = this.getOrganizationIds()

        this.setState({ selectedInflowModuleId })

        if (selectedInflowModuleId) {
            const inflowMomentFilters = {
                filters: {
                    filterByInflowModule: selectedInflowModuleId,
                    filterByDateFrom: new Date(),
                    filterByConcepts: false,
                    filterBySlotsAvailable: true,
                },
            } as { [key: string]: any }

            if (organizationsIds.length > 0) {
                inflowMomentFilters.filters = {
                    ...inflowMomentFilters.filters,
                    filterByOrganizationsAndIncludeNulls: organizationsIds,
                }
            } else {
                inflowMomentFilters.filters = {
                    ...inflowMomentFilters.filters,
                    filterByNoOrganization: true,
                }
            }

            await this.inflowMomentFetcher.fetch({
                ...inflowMomentFilters,
            })
        } else {
            this.inflowMomentFetcher.clear()
        }
    }

    private onChangeInflowMoment = (option?: TagPickerOption) => {
        this.setState({ selectedInflowMomentId: option && option.value }, () => {
            const { selectedInflowMomentId } = this.state
            const { data } = this.inflowMomentFetcher
            const { inflowMoments = [] } = data

            if (inflowMoments.length > 0 && selectedInflowMomentId) {
                const selectedInflowMoment = inflowMoments.find(
                    ({ _id }: InflowMoment) => _id === selectedInflowMomentId
                )

                if (!selectedInflowMoment) {
                    return
                }

                const { timeslots, amountOfSlotsPerTimeslot } = selectedInflowMoment as InflowMoment

                this.setState({
                    timeslotsForInflowMoment: timeslots.map(({ _id, startTime, timeslotUsers }) => ({
                        _id,
                        startTime,
                        maxSlots: amountOfSlotsPerTimeslot,
                        usedByUserIds: timeslotUsers.map(timeslotUser => timeslotUser.user._id),
                    })),
                })
            }
        })
    }

    private onChangeTimeslot =
        ({ _id: userId }: User) =>
        (option?: TagPickerOption) => {
            const updatedTimeslotId = option && option.value

            const { timeslotsForInflowMoment } = this.state
            const timeslotUserIsIn = timeslotsForInflowMoment.find(({ usedByUserIds }) =>
                usedByUserIds.includes(userId)
            )

            if (timeslotUserIsIn) {
                this.removeUserFromTimeslot(userId, timeslotUserIsIn._id)
            }

            this.addUserToTimeslot(userId, updatedTimeslotId)
        }

    private addUserToTimeslot = (userId: string, updatedTimeslotId?: string) => {
        const { timeslotsForInflowMoment } = this.state

        if (!updatedTimeslotId) {
            return
        }

        this.setState({
            timeslotsForInflowMoment: timeslotsForInflowMoment.map(timeslot => {
                if (timeslot._id === updatedTimeslotId) {
                    return {
                        ...timeslot,
                        usedByUserIds: [...timeslot.usedByUserIds, userId],
                    }
                }

                return timeslot
            }),
        })
    }

    private removeUserFromTimeslot = (userId: string, timeslotIdToRemoveFrom: string) => {
        const { timeslotsForInflowMoment } = this.state

        this.setState({
            timeslotsForInflowMoment: timeslotsForInflowMoment.map(timeslot => {
                if (timeslot._id === timeslotIdToRemoveFrom) {
                    const usedByUserIds = timeslot.usedByUserIds
                    const indexToRemove = usedByUserIds.findIndex(usedByUserId => usedByUserId === userId)

                    usedByUserIds.splice(indexToRemove, 1)

                    return {
                        ...timeslot,
                        usedByUserIds,
                    }
                }

                return timeslot
            }),
        })
    }

    private getOrganizationIds = () => {
        const { selectedUsers } = this.props

        let organizations = flatten(selectedUsers.map(user => (user.learner && user.learner.organizations) || []))
            .map(organization => organization.organization)
            .filter(organization => !!organization) as Organization[]

        organizations = uniqBy(organizations, 'organization._id')

        const organizationForAllUsers = organizations.filter(organization => {
            if (!organization) {
                return false
            }

            return selectedUsers.every(user => {
                if (!user.learner || !user.learner.organizations) {
                    return false
                }

                return !!user.learner.organizations.find(userLearnerOrganization => {
                    if (!userLearnerOrganization.organization || !organization) {
                        return false
                    }

                    return userLearnerOrganization.organization._id === organization._id
                })
            })
        })

        return organizationForAllUsers.map(organization => organization._id)
    }
}
