import './ObligedCitizenshipEducationEndDatesInput.scss'
import * as React from 'react'
import c from 'classnames'
import { ObligedCitizenshipEducationEndDateExtension } from '~/components/users/ObligedCitizenshipEducationEndDateExtension'
import DatePicker from '~/components/DatePicker'
import { Button } from '~/components'
import CenterModal from '~/components/CenterModal'
import {
    ObligedCitizenshipEducationEndDateExtensionForm,
    ObligedCitizenshipEducationEndDateExtensionFormInputFields,
} from '~/forms/ObligedCitizenshipEducationEndDateExtensionForm'
import { ObligedCitizenshipEducationEndDateExtensionType } from '~/types/User'
import { List } from '~/components/List'
import { ModalManager } from '~/components/ModalManager'

interface Props {
    className?: string
    defaultEndDateValue?: string | null
    defaultExtensionValue?: ObligedCitizenshipEducationEndDateExtensionType[] | null
    onAddObligedCitizenshipEducationEndDateExtension: (index: number, document?: any) => void
    onEditObligedCitizenshipEducationEndDateExtension: (i: number, document?: any) => void
    onRemoveObligedCitizenshipEducationEndDateExtension: (i: number) => void
    name: string
    isDisabled?: boolean
}

interface State {
    endDateExtensions: any[]
    canExtendEndDate: boolean
    baseEndDate?: string | null
}

export class ObligedCitizenshipEducationEndDatesInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        const { defaultEndDateValue, defaultExtensionValue } = this.props

        this.state = {
            endDateExtensions: defaultExtensionValue ? [...defaultExtensionValue] : [],
            canExtendEndDate: !!defaultEndDateValue,
            baseEndDate: defaultEndDateValue,
        }
    }

    public render() {
        return (
            <div className={this.getClassName()}>
                {this.renderOriginalDateField()}
                <List>{this.renderExtenions()}</List>
            </div>
        )
    }

    private getMinimumDateByIndex = (i: number) => {
        const { baseEndDate, endDateExtensions } = this.state
        const endDates = [baseEndDate, ...endDateExtensions.map(endDateExtension => endDateExtension.date)]

        return endDates[i]
    }

    private renderOriginalDateField = (): React.ReactNode => {
        const { defaultEndDateValue, isDisabled } = this.props
        const { canExtendEndDate, endDateExtensions } = this.state

        return (
            <div className={`tt-ObligedCitizenshipEducationEndDatesInput__original-date`}>
                <DatePicker
                    name={`user.learner.obligedCitizenshipEducationInitialEndDate`}
                    defaultValue={defaultEndDateValue}
                    isReadonly={endDateExtensions.length > 0}
                    isDisabled={isDisabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({ baseEndDate: event.target.value })
                    }
                    min={new Date(2000, 1, 1).toISOString()}
                />
                {canExtendEndDate && (
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} isDisabled={isDisabled}>
                                Inburgering verlengen
                            </Button>
                        )}
                        getModal={closeModal => (
                            <CenterModal title={`Inburgeringsplicht verlengen`} onClose={closeModal}>
                                <ObligedCitizenshipEducationEndDateExtensionForm
                                    minimumExtensionDate={this.getMinimumDateByIndex(endDateExtensions.length)}
                                    onAddDate={fields => this.onAddDate(fields)}
                                    onCancel={closeModal}
                                    onSubmit={closeModal}
                                />
                            </CenterModal>
                        )}
                    />
                )}
            </div>
        )
    }

    private renderExtenions = (): React.ReactNode[] | null => {
        const { name } = this.props
        const { endDateExtensions } = this.state

        if (!endDateExtensions) {
            return null
        }

        return endDateExtensions.map(
            (endDateExtension, i): React.ReactNode => (
                <ObligedCitizenshipEducationEndDateExtension
                    defaultValue={endDateExtension}
                    key={i + endDateExtension.date}
                    minimumExtensionDate={this.getMinimumDateByIndex(i)}
                    name={`${name}[${i}]`}
                    onEditExtension={fields => this.onEditExtension(i, fields)}
                    onRemoveExtension={() => this.onRemoveExtension(i)}
                    isLastExtension={endDateExtensions.length - 1 === i}
                />
            )
        )
    }

    private onAddDate = (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => {
        const { onAddObligedCitizenshipEducationEndDateExtension } = this.props
        const index = this.state.endDateExtensions.length

        this.setState((prevState: State) => ({
            endDateExtensions: [...prevState.endDateExtensions, this.getNewEndDateExtension(fields)],
        }))

        onAddObligedCitizenshipEducationEndDateExtension(index, fields.document)
    }

    private onEditExtension = (i: number, fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => {
        const { onEditObligedCitizenshipEducationEndDateExtension } = this.props

        this.setState((prevState: State) => {
            const { endDateExtensions } = prevState

            endDateExtensions[i] = {
                ...endDateExtensions[i],
                date: new Date(fields.date).toISOString(),
            }

            return {
                endDateExtensions: endDateExtensions.sort((a: any, b: any) => {
                    return new Date(a.date).getTime() - new Date(b.date).getTime()
                }),
            }
        })

        onEditObligedCitizenshipEducationEndDateExtension(i, fields.document)
    }

    private onRemoveExtension = (i: number) => {
        const { onRemoveObligedCitizenshipEducationEndDateExtension } = this.props
        const { endDateExtensions } = this.state
        endDateExtensions.splice(i, 1)

        this.setState({ endDateExtensions })

        onRemoveObligedCitizenshipEducationEndDateExtension(i)
    }

    private getNewEndDateExtension = (fields: ObligedCitizenshipEducationEndDateExtensionFormInputFields) => {
        const { date, description, document } = fields

        return {
            date,
            description,
            fileToUploadName: document && document.name,
        }
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-ObligedCitizenshipEducationEndDatesInput', {}, className)
    }
}
