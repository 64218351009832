import { Mutator, toast, transformFormFields } from '~/utils'
import { Button, Form, FieldCollection, Field, DatePicker } from '~/components'
import React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { LearnerEnrollmentType } from '~/types/User'
import { translateType } from '~/shared/utils'
import { gql } from '@apollo/client'
import { FormFields } from '~/components/Form'
import { UserLearnerSignupType } from '~/generated/graphql'

interface Props {
    defaultSignUp: UserLearnerSignupType
    onSubmitSuccess?: () => void
    onCancel?: () => void
    onDeleteSuccess?: () => void
}

export class EditSignupForm extends React.Component<Props> {
    private editMutator: Mutator
    private deleteMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.editMutator = new Mutator({
            mutation: EDIT_USER_LEARNER_SIGNUP_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteMutator = new Mutator({
            mutation: REMOVE_USER_LEARNER_SIGNUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render(): React.ReactNode {
        return (
            <Form onSubmit={(event, fields) => this.onSubmit(fields)}>
                <FieldCollection style={`modal`} errors={this.editMutator.errors}>
                    <FieldGroup>
                        <Field isLabel={true} title={'Type aanmelding'} errors={this.editMutator.errors}>
                            <TagPicker
                                name={`userLearnerSignup.enrollmentType`}
                                multi={false}
                                isClearable={true}
                                placeholder={'Selecteer een type...'}
                                options={[LearnerEnrollmentType.Intake, LearnerEnrollmentType.Assessment].map(
                                    option => ({
                                        value: option,
                                        label: translateType('enrollmentType', option),
                                    })
                                )}
                                defaultValue={{
                                    value: this.props.defaultSignUp.enrollmentType,
                                    label: translateType('enrollmentType', this.props.defaultSignUp.enrollmentType),
                                }}
                            />
                        </Field>
                        <Field isLabel={true} title={'Aangemeld op'} errors={this.editMutator.errors}>
                            <DatePicker
                                hasIcon={true}
                                name={`userLearnerSignup.signupDate`}
                                defaultValue={new Date(this.props.defaultSignUp.signupDate)}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={this.props.onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={this.editMutator.loading || this.deleteMutator.loading}
                                >
                                    Opslaan
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Button
                                    onClick={() => this.requestDelete()}
                                    type={`danger`}
                                    isLoading={this.editMutator.loading || this.deleteMutator.loading}
                                    confirm={{
                                        title: 'Verwijderen',
                                        message: 'Weet je zeker dat je de aanmelding wilt verwijderen?',
                                        execute: {
                                            buttonType: 'danger',
                                            title: 'Verwijderen',
                                        },
                                    }}
                                >
                                    Verwijderen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private async onSubmit(fields: FormFields) {
        const data = await this.editMutator.mutate({
            userLearnerSignup: {
                _id: this.props.defaultSignUp._id,
                ...transformFormFields(fields.userLearnerSignup),
            },
        })

        if (data && data.userLearnerSignups_edit) {
            toast.success(`Aanmelding is geregistreerd`)

            if (this.props.onSubmitSuccess) {
                this.props.onSubmitSuccess()
            }
        }
    }

    private async requestDelete() {
        const data = await this.deleteMutator.mutate({
            userLearnerSignupId: this.props.defaultSignUp._id,
        })

        if (data) {
            toast.success(`Aanmelding verwijderd`)
            if (this.props.onDeleteSuccess) {
                this.props.onDeleteSuccess()
            }
        }
    }
}

const EDIT_USER_LEARNER_SIGNUP_MUTATION = gql`
    mutation _($userLearnerSignup: UserLearnerSignupInputType!) {
        userLearnerSignups_edit(userLearnerSignup: $userLearnerSignup) {
            _id
        }
    }
`

const REMOVE_USER_LEARNER_SIGNUP_MUTATION = gql`
    mutation _($userLearnerSignupId: MongoID!) {
        userLearnerSignups_delete(userLearnerSignupId: $userLearnerSignupId) {
            _id
        }
    }
`
