import * as React from 'react'
import c from 'classnames'
import { Button, PdfModal, Icon } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { getFileUrl } from '~/utils'

interface Props {
    className?: string
    onRemove?: () => void
    fileId: string
    fileName: string
    mimeType: string
}

interface State {
    isDownloadLoading: boolean
}

export class ExamDownloader extends React.Component<Props, State> {
    public state: State = {
        isDownloadLoading: false,
    }

    public componentDidMount() {
        const { mimeType, fileId, fileName } = this.props

        if (mimeType !== 'application/pdf') {
            getFileUrl(fileId, fileName)
        }
    }
    public render() {
        return (
            <div className={this.getClassName()}>
                <div className={`tt-ExamDownloader__modal-manager-container`}>{this.renderFileAction()}</div>
                {this.renderRemoveButton()}
            </div>
        )
    }

    private renderRemoveButton = () => {
        const { onRemove } = this.props

        if (!onRemove) {
            return null
        }

        return (
            <Button linkStyle={`default`} onClick={onRemove} className={`tt-ExamDownloader__download-button`}>
                <Icon name={`remove`} color={`#314F93`} />
            </Button>
        )
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-ExamDownloader', {}, className)
    }

    private renderFileAction = () => {
        const { children, fileName, fileId, mimeType } = this.props
        const { isDownloadLoading } = this.state

        if (mimeType === 'application/pdf') {
            return (
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} linkStyle={`default`}>
                            {children || fileName}
                        </Button>
                    )}
                    getModal={closeModal => (
                        <PdfModal title={fileName} fileName={fileName} getFileId={() => fileId} onClose={closeModal} />
                    )}
                />
            )
        }

        return (
            <Button onClick={this.triggerDownload} linkStyle={`default`} isLoading={isDownloadLoading}>
                {children || fileName}
            </Button>
        )
    }

    private triggerDownload = async () => {
        this.setState({ isDownloadLoading: true })
        const fileId = this.props.fileId
        const url = await getFileUrl(fileId, this.props.fileName, { asAttachment: true })
        if (url) {
            location.href = url
        }
        this.setState({ isDownloadLoading: false })
    }
}
