import React from 'react'
import { debounce } from 'lodash'
import { scrollToElement } from '~/utils'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { ClassValue, BEM } from '~/services/BEMService'

type FieldCollectionStyleType = 'cover' | 'modal'

interface Props {
    errors?: GraphQLErrors
    className?: ClassValue
    style?: FieldCollectionStyleType
}

export default class FieldCollection extends React.Component<Props> {
    private bem = new BEM('FieldCollection', () => ({
        cover: this.props.style === 'cover',
        modal: this.props.style === 'modal',
    }))

    constructor(props: Props) {
        super(props)

        this.onError = debounce(this.onError, 500, {
            leading: true,
            trailing: false,
        })
    }

    public componentDidUpdate(prevProps: Props) {
        const { errors: prevErrors } = prevProps
        const { errors } = this.props

        if (errors && errors !== prevErrors) {
            const errorFields = errors
                .getErrors()
                .filter((e: any) => !!e.field)
                .map((e: any) => e.field)

            if (errorFields && errorFields.length > 0) {
                setTimeout(() => {
                    this.onError(errorFields)
                })
            }
        }
    }

    public render() {
        const { children, className } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }

    private onError = (errorFields: any[]) => {
        const selectors = errorFields.map((field: string) => `[name='${field}']`).join(', ')

        if (selectors) {
            const elementsWithError = window.document.querySelectorAll(selectors)

            if (elementsWithError.length > 0) {
                scrollToElement({
                    element: elementsWithError[0],
                    getScroller: () => window.document.querySelectorAll('html, body'),
                })
            }
        }
    }
}
