import { types } from '.'

/**
 * Translate a type to a human readable message
 */
export default function translateType(namespace: any, type: any, info?: any) {
    let namespacedTypes = types[namespace]

    if (typeof namespacedTypes === 'function') {
        namespacedTypes = namespacedTypes()
    }

    if (!namespacedTypes || !namespacedTypes[type]) {
        return type
    }

    return namespacedTypes[type](info)
}
