import './CandidateProgressStatusCell.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { BrickTableCell } from '~/components/Table/BrickTableCell/BrickTableCell'
import Icon from '~/components/Icon'
import { ResultStatus } from '~/types/Exam'
import ToolTip from '~/components/ToolTip'

interface Props {
    className?: ClassValue
    status: ResultStatus
}

export class CandidateProgressStatusCell extends React.Component<Props> {
    private bem = new BEM('CandidateProgressStatusCell', () => ({
        'for-exempt': this.props.status === ResultStatus.Exempt,
        'for-no-show': this.props.status === ResultStatus.NoShow,
    }))

    public render() {
        const { className, status } = this.props

        return (
            <BrickTableCell className={this.bem.getClassName(className)}>
                <ToolTip text={this.getTooltipTextForResultStatus()}>
                    <Icon name={status === ResultStatus.Exempt ? 'star' : 'minus'} />
                </ToolTip>
            </BrickTableCell>
        )
    }

    private getTooltipTextForResultStatus(): string {
        switch (this.props.status) {
            case ResultStatus.Exempt:
                return 'Vrijgesteld'
            case ResultStatus.NoShow:
                return 'Niet verschenen'
            default:
                return ''
        }
    }
}
