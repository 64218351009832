import { gql } from '@apollo/client'
import { get } from 'lodash'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Fetcher } from '~/utils'
import { Wrap } from '~/components/Wrap'
import { RouteComponentProps } from 'react-router'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export default class DetailView extends Component<Props, {}> {
    private moduleFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.moduleFetcher = new Fetcher({
            query: GET_MODULE_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate(preIterationVariables: any) {
        const { params } = this.props

        if (preIterationVariables.params.id !== params.id) {
            this.moduleFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.moduleFetcher
        const module = get(data, 'modules[0]')

        return (
            <RouteView crumbLabel={get(module, 'name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {module && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/properties/modules/${module._id}`}>
                                    Gegevens
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/properties/modules/${module._id}/invites/`}>
                                    Uitnodigingsbrieven
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                {module ? cloneElement(children, { module, refetch }) : this.renderFallback()}
            </RouteView>
        )
    }

    private renderFallback() {
        return (
            <ContentView>
                <Wrap>Er is een fout opgetreden tijdens het ophalen van de module</Wrap>
            </ContentView>
        )
    }
}

const GET_MODULE_QUERY = gql`
    query _($_id: MongoID) {
        modules(byId: $_id) {
            _id
            name
            isLiteracyCourse
            isIntegrationCourse
            startLevel
            endLevel
            isFinalExamTraining
            hasFinalReview
            isActive
            lessonDuration
            amountOfWeeklyLessons
            isUsedInNonConceptGroups
            amountOfTotalLessons
            capacity
            projectType
            integrationType
            courseMaterials {
                _id
                name
            }
            exams {
                examId
                exam {
                    _id
                    name
                }
                abilities {
                    converse
                    listen
                    read
                    talk
                    write
                }
            }
            locationProperties {
                _id
                name
            }
            usedByProgram
        }
    }
`
