import './AttendanceTable.scss'

import * as React from 'react'
import { Table } from '~/components/Table'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
}

export class AttendanceTable extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceTable')

    public render() {
        const { children, className } = this.props

        return <Table className={this.bem.getClassName(className)}>{children}</Table>
    }
}
