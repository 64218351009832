import { registerLocale, getName, getAlpha2Codes } from 'i18n-iso-countries'

registerLocale(require('i18n-iso-countries/langs/en.json'))
registerLocale(require('i18n-iso-countries/langs/nl.json'))

/**
 * Add extra countries or override existing countries,
 * because available countries can be a sensitive topic for people.
 *
 * WARNING: Use <originalcode>_<description> as alpha2 and alpha3 codes, in order to make sure it won't conflict with any existing ISO country.
 */
const COUNTRY_OVERRIDES = [
    {
        alpha2: 'CN_TIBET',
        alpha3: 'CHN_TIBET',
        names: {
            nl: 'Tibet',
            en: 'Tibet',
        },
    },
]

function _getAlpha2Codes(): { [alpha2Key: string]: string } {
    const alpha2Codes = getAlpha2Codes()

    for (const extraCountry of COUNTRY_OVERRIDES) {
        alpha2Codes[extraCountry.alpha2] = extraCountry.alpha2
    }

    return alpha2Codes
}

/**
 * @param countryCode  Alpha2 or Alpha3 or Numeric
 * @param lang         ISO 639-1 format string
 */
export function _getName(countryCode: string, lang: string): string {
    const countryOverride = COUNTRY_OVERRIDES.find(c => {
        return c.alpha2.toLowerCase() === countryCode.toLowerCase()
    })

    if (countryOverride) {
        return countryOverride.names[lang]
    }

    return getName(countryCode, lang)
}

export { _getAlpha2Codes as getAlpha2Codes, _getName as getName }
