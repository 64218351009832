import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import DatePicker from '~/components/DatePicker'
import Input from '~/components/Input'
import { TagPicker } from '~/components/TagPicker'
import { get, isEmpty } from 'lodash'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { countryOptions } from '~/utils/countryOptions'

interface Props {
    hideFieldsForGroupTeacher?: boolean
    hideFieldsForOrganizationContacts?: boolean
    user: User
    errors?: GraphQLErrors
    bsnVerified?: boolean
    onChangeBSNVerifiedCheckbox: (value: boolean) => void
    onChangeBSNInput: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export class UserLearnerPersonalDetailsFieldGroup extends React.Component<Props> {
    public render() {
        const { errors, user } = this.props
        const dateOfBirth = get(user, 'profile.dateOfBirth')

        const nationalityOptions = countryOptions.slice()
        nationalityOptions.unshift({ value: 'none', label: 'Geen', data: { match: 'Geen' } })

        const hasNoCountryOfNationality = get(user, `profile.hasNoCountryOfNationality`)
        const countriesOfNationality = !hasNoCountryOfNationality ? get(user, `profile.countriesOfNationality`) : 'none'

        return (
            <FieldGroup title={`Persoonlijke gegevens`} isForm={true}>
                <LockedFieldWrapper disableLock={isEmpty(get(user, `profile.countriesOfNationality`))}>
                    {isLocked => (
                        <Field isLabel={true} title={`Nationaliteit(en)`} errors={errors}>
                            <TagPicker
                                name={`user.profile.countriesOfNationality`}
                                options={nationalityOptions}
                                isDisabled={isLocked}
                                placeholder={'Selecteer een of meerdere landen'}
                                defaultValue={countriesOfNationality}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, 'profile.dateOfBirth')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Geboortedatum`} errors={errors}>
                            <DatePicker
                                name={`user.profile.dateOfBirth`}
                                defaultValue={dateOfBirth}
                                isDisabled={isLocked}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper
                    disableLock={!get(user, 'profile.cityOfBirth') && !get(user, `profile.countryOfBirth`)}
                >
                    {isLocked => (
                        <React.Fragment>
                            <Field isLabel={true} title={`Geboorteplaats`} errors={errors}>
                                <Input
                                    name={`user.profile.cityOfBirth`}
                                    type="text"
                                    isDisabled={isLocked}
                                    defaultValue={get(user, 'profile.cityOfBirth')}
                                />
                            </Field>
                            <Field isLabel={true} title={`Geboorteland`} errors={errors}>
                                <TagPicker
                                    name={`user.profile.countryOfBirth`}
                                    multi={false}
                                    isDisabled={isLocked}
                                    options={countryOptions}
                                    placeholder={'Selecteer een land'}
                                    defaultValue={get(user, `profile.countryOfBirth`)}
                                />
                            </Field>
                        </React.Fragment>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, `profile.countryOfOrigin`)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Land van herkomst`} errors={errors}>
                            <TagPicker
                                name={`user.profile.countryOfOrigin`}
                                multi={false}
                                options={countryOptions}
                                placeholder={'Selecteer een land'}
                                isDisabled={isLocked}
                                defaultValue={get(user, `profile.countryOfOrigin`)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }
}
