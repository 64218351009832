import moment from 'moment'

import { formatCurrency } from '~/utils/formatCurrency'
import { parseNestedGraphQLError } from '~/utils/parseNestedGraphQLError'
import translateType from '~/shared/utils/translateType'

export default {
    generic: {
        required: () => `Dit veld is verplicht.`,
        'invalid-id': () => `Kan document niet vinden.`,
        'length-min': info => `Dit veld mag niet kleiner zijn dan ${info.min} karakters.`,
        'length-max': info => `Dit veld mag niet groter zijn dan ${info.max} karakters.`,
        'array-length-min': info => `Dit veld moet minimaal ${info.min} waardes bevatten.`,
        'array-length-max': info => `Dit veld mag maximaal ${info.max} waardes bevatten.`,
        'date-min': info => `Deze datum moet na ${moment(info.min).format('llll')} liggen.`,
        'date-max': info => `Deze datum moet voor ${moment(info.max).format('llll')} liggen.`,
        'date-min-same': info => `Deze datum moet op of na ${moment(info.min).format('llll')} liggen.`,
        'date-max-same': info => `Deze datum moet op of voor ${moment(info.max).format('llll')} liggen.`,
        'already-exists': () => `Deze waarde komt al voor.`,
        'should-exist': () => `Deze waarde bestaat niet.`,
        'entity-should-exist': () => `Kan document niet vinden.`,
        'no-duplicates-allowed': () => `Alle opgegeven waarden mogen maar eenmaal voorkomen.`,
        'should-be-one-of-values': () => `Deze waarde is ongeldig.`,
        'should-be-none-of-values': () => `Deze waarde bestaat al.`,
        'should-be-one-of-ids': () => `Deze waarde is ongeldig.`,
        'should-be-none-of-ids': () => `Deze waarde bestaat al.`,
        'should-be-id': () => `Deze waarde is ongeldig.`,
        'should-equal-value': info =>
            info.value !== null && typeof info.value !== 'undefined'
                ? `Deze waarde komt niet overeen met ${info.value}.`
                : `Deze waarde mag niet meer ingevuld worden.`,
        'should-not-change': () => `Deze waarde mag niet meer veranderen.`,
        min: info => `Dit getal moet minimaal ${info.min} zijn.`,
        max: info => `Dit getal mag maximaal ${info.max} zijn.`,
        email: () => `Dit is geen geldig e-mailadres.`,
        'password-requirements': () => `Het wachtwoord moet minimaal 6 karakters bevatten.`,
        'passwords-must-match': () => `Dit wachtwoord komt niet overeen met het eerste wachtwoord.`,
        unauthorized: () => `Geen permissie.`,
        'export-is-empty': () => `Geen resultaten voor de geselecteerde filters.`,
        'file-is-locked': () => `Dit document kan niet verwijderd worden.`,
        'phonenumber-invalid': () => `Telefoonnummer is ongeldig.`,
        'file-type-not-allowed': info =>
            `Bestandstype mag alleen van de volgende waardes zijn: ${info.value.join(', ')}.`,
        genericField: () => `Deze waarde is incorrect.`,
        generic: () => `Er is iets misgegaan.`,
        'invalid-zipcode': () => `Dit is geen geldige postcode.`,
        'invalid-url': () => `Dit is geen geldige URL.`,
        'invoice-locked-is-no-draft': () => `Deze factuur is al verstuurd en kan niet meer worden aangepast.`,
        'invoice-locked-is-installment-descendant': () =>
            `Van termijnfacturen kan alleen de hoofd-factuur worden aangepast`,
        'twinfield-no-access-token-set': () => `Er is geen token ingesteld voor Twinfield.`,
        'twinfield-customer-not-found': info => `Debiteur met nummer "${info.code}" niet gevonden in Twinfield.`,
        'twinfield-invoice-not-found': () => `De factuur is niet gevonden in Twinfield.`,
        'twinfield-transaction-not-found': () => `De boeking is niet gevonden in Twinfield.`,
        'twinfield-article-not-found': info => `Het artikel "${info.articleCode}" is niet gevonden in Twinfield.`,
        'twinfield-article-vatcode-incorrect': info =>
            `Het artikel "${info.articleCode}" in Twinfield heeft de verkeerde BTW-code. Dit moet "BTW 0%" zijn`,
        'twinfield-article-units-price-cannot-be-changed': info =>
            `De prijs kan niet worden aangepast van het artikel "${info.articleCode}" in Twinfield.`,
        'twinfield-article-percentage-is-enabled': info =>
            `Het artikel "${info.articleCode}" in Twinfield is een percentage-artikel.`,
        'twinfield-article-is-inactive': info => `Het artikel "${info.articleCode}" in Twinfield is inactief.`,
        'twinfield-article-subarticle-wrong-code': info =>
            `Het artikel "${info.articleCode}" in Twinfield heeft de verkeerde subcode.`,
        'invalid-filetype': info =>
            `Dit bestandstype is niet ondersteund. Het moet voldoen aan type: "${info.fileType}"`,
        'could-not-parse-file': () => `Kon het CSV bestand niet uitlezen`,
        'no-differences-allowed': () => `Verschillende varianten zijn niet toegestaan`,
        'twinfield-incorrect-oauth': () => `Fout in koppeling met Twinfield`,
        'twinfield-customer-code-cannot-change': () => `Debiteurnummer kan niet meer wijzigen`,
        'empty-export': () => `Export is leeg`,
        'contract-type-does-not-exist-anymore': info =>
            `Contract kan niet gegenereerd worden, want contract type "${info.contractType}" bestaat niet meer`,
    },
    users_import: {
        generic: {
            'duplicate-users-in-import': info =>
                `De kandidaten met dossiernummer "${info.users}" bestaan reeds in deze organisatie. Gehele import is geannuleerd. Probeer het opnieuw.`,
            'users-missing-required-fields': info =>
                `De kandidaten "${info.users}" missen verplichte velden. Gehele import is geannuleerd. Probeer het opnieuw.`,
            'users-wrong-format-fields': info =>
                `De kandidaten "${info.users}" hebben foutieve velden. Gehele import is geannuleerd. Probeer het opnieuw.`,
            'users-with-wrong-enrollment-type': info =>
                `De kandidaten "${info.users}" hebben geen geldig type aanmelding. Gehele import is geannuleerd. Probeer het opnieuw.`,
            'wrong-column-count-for-selected-file': () =>
                `Het bestand bevat het verkeerde aantal kolommen. Gebruik het voorbeeldbestand als basis.`,
        },
    },
    users_createLessonInviteFile: {
        generic: {
            'no-learners': () => `Er zijn geen kandidaten toegevoegd aan deze groep.`,
            'no-teachers': () => `Er zijn geen docenten toegevoegd aan deze groep.`,
            'no-employees': () => `Er is geen projectmedewerker of projectleider toegevoegd aan deze groep.`,
            'no-lessons': () => `Er zijn geen lessen toegevoegd aan deze groep.`,
            'no-module': () => `Geen lesmodule is toegewezen aan de groep.`,
            'lesson-invite-not-possible': () =>
                `Deze groep is niet aangemaakt via conceptgroepen en heeft geen weekrooster. Brief export is niet mogelijk.`,
            'lesson-invite-no-users': () => `Er zijn geen gebruikers geselecteerd voor de brief download.`,
        },
    },
    users_sendLessonInvites: {
        generic: {
            'no-learners': () => `Er zijn geen kandidaten toegevoegd aan deze groep.`,
            'no-teachers': () => `Er zijn geen docenten toegevoegd aan deze groep.`,
            'no-employees': () => `Er is geen projectmedewerker of projectleider toegevoegd aan deze groep.`,
            'no-lessons': () => `Er zijn geen lessen toegevoegd aan deze groep.`,
            'no-module': () => `Geen lesmodule is toegewezen aan de groep.`,
            'lesson-invite-not-possible': () =>
                `Deze groep is niet aangemaakt via conceptgroepen en heeft geen weekrooster. Brief export is niet mogelijk.`,
            'lesson-invite-no-users': () => `Er zijn geen gebruikers geselecteerd voor de brief download.`,
        },
    },
    users_create: {
        'user.email': {
            'already-exists': info => `Er bestaat al een gebruiker met dit e-mailadres (${info.user}).`,
        },
        'user.learner.privatePaymentDUOInitialRemainingFund': {
            min: info => `Dit bedrag moet minimaal ${formatCurrency(info.min)} zijn.`,
            max: info => `Dit bedrag mag maximaal ${formatCurrency(info.max)} zijn.`,
        },
        'user.learner.organizationContactUserIdBasedOnContextOrganization': {
            'argument-not-allowed': () => 'Er is iets mis gegaan.',
        },
        'user.learner.organizations[].organizationFileNumber': {
            'organizationFileNumber-already-exists': () =>
                'Dossiernummer wordt al gebruikt voor iemand in deze organisatie.',
        },
        'user.learner.organizationFileNumberBasedOnContextOrganization': {
            'organizationFileNumber-already-exists': () =>
                'Dossiernummer wordt al gebruikt voor iemand in deze organisatie.',
        },
        'user.learner.integrationLawType': {
            'integration-law-required-with-integration-course-setting': () => 'Selecteer een inburgeringswet',
        },
        'user.learner.paymentBy': {
            'payment-type-not-compatible-with-integration-law': () =>
                'Deze betalingssoort is ongeldig in combinatie met de geselecteerde inburgeringswet',
            'payment-type-not-compatible-with-integration-course-setting': () =>
                'Deze betalingssoort is ongeldig in combinatie met de geselecteerde inburgeringscursus instelling',
        },
        'user.learner.enrollmentType': {
            required: () => 'Wanneer je een datum van aanmelding hebt opgegeven, is de type aanmelding verplicht.',
        },
        'user.learner.enrollmentDate': {
            required: () => 'Wanneer je een type aanmelding hebt opgegeven, is de datum van aanmelding verplicht.',
        },
    },
    users_edit: {
        'user.email': {
            'already-exists': info =>
                `Er bestaat al een gebruiker met dit e-mailadres (${info.user}). Je kunt het e-mailadres eventueel invullen bij "Secundair e-mailadres".`,
        },
        'user.learner.organizationId': {
            required: () => `Dit veld is verplicht als de betaling niet particulier is.`,
        },
        'user.profile.bsn': {
            'already-exists': info => `Er bestaat al een gebruiker met dit BSN (${info.user}).`,
        },
        'user.learner.privatePaymentDUOInitialRemainingFund': {
            min: info => `Dit bedrag moet minimaal ${formatCurrency(info.min)} zijn`,
            max: info => `Dit bedrag mag maximaal ${formatCurrency(info.max)} zijn.`,
        },
        'user.learner.organizations[].organizationFileNumber': {
            'organizationFileNumber-already-exists': () =>
                'Dossiernummer wordt al gebruikt voor iemand in deze organisatie.',
        },
        'user.learner.organizationFileNumberBasedOnContextOrganization': {
            'organizationFileNumber-already-exists': () =>
                'Dossiernummer wordt al gebruikt voor iemand in deze organisatie.',
        },
        'user.learner.integrationLawType': {
            'integration-law-required-with-integration-course-setting': () => 'Selecteer een inburgeringswet',
        },
        'user.learner.paymentBy': {
            'payment-type-not-compatible-with-integration-law': () =>
                'Deze betalingssoort is ongeldig in combinatie met de geselecteerde inburgeringswet',
            'payment-type-not-compatible-with-integration-course-setting': () =>
                'Deze betalingssoort is ongeldig in combinatie met de geselecteerde inburgeringscursus instelling',
        },
        'user.learner.privatePaymentIsDUO': {
            'private-payment-type-cannot-be-set-when-payment-is-not-private': () =>
                `Type betaler kan niet worden ingesteld als de betaler niet particulier is`,
        },
        'user.learner.privatePaymentDUOInitialRemainingFund': {
            'private-payment-duo-approval-cannot-be-set-when-payment-type-is-not-duo': () =>
                `DUO goedkeuring kan niet worden ingesteld als de betalingsinstelling niet 'DUO' is`,
        },
        'user.learner.privatePaymentDUOInitialRemainingFund': {
            'private-payment-duo-fund-cannot-be-set-when-payment-type-is-not-duo': () =>
                `DUO leenruimte kan niet worden ingesteld als de betalingsinstelling niet 'DUO' is`,
        },
    },
    users_change_password: {
        token: {
            required: () => `Deze link is ongeldig.`,
            'token-expired': () => `Deze link is verlopen.`,
        },
    },
    users_changeEmail: {
        newEmail: {
            'not-allowed': () => `Je kunt dit e-mailadres niet wijzigen.`,
        },
    },
    sessions_login: {
        generic: {
            'bad-credentials': () => `De inloggegevens zijn onjuist.`,
            unauthorized: () => `Je hebt geen permissie om in te loggen.`,
        },
    },
    locations_create: {
        'location.name': {
            'already-exists': () => `Deze locatie bestaat al.`,
        },
    },
    locations_edit: {
        'location.name': {
            'already-exists': () => `Deze locatie bestaat al.`,
        },
    },
    locations_delete: {
        generic: {
            'location-used-in-groups': info =>
                `Deze locatie wordt gebruikt in groepen, waaronder in "${info.groups[0].name}".`,
        },
    },
    modules_create: {
        'module.name': {
            'already-exists': () => `Deze lesmodule bestaat al.`,
        },
        'module.exams': {
            'exam-ability-not-available-in-exam': info =>
                `De vaardigheid "${translateType('examAbility', info.ability)}" is niet beschikbaar in examen "${
                    info.examName
                }".`,
        },
        'module.isFinalExamTraining': {
            'exam-training-not-possible': () =>
                `Examentraining is alleen mogelijk in het geval van inburgering en wanneer de lesmodule niet voor alfabetisering is.`,
        },
        'module.hasFinalReview': {
            'final-review-not-possible': () => `Eindgesprek is alleen mogelijk in het geval van inburgering.`,
        },
    },
    modules_edit: {
        'module.name': {
            'already-exists': () => `Deze lesmodule bestaat al.`,
            'should-equal-value': info =>
                `Deze lesmodule wordt gebruikt in een groep. Deze waarde moet gelijk blijven aan "${info.value}".`,
        },
        'module.lessonDuration': {
            'should-equal-value': info =>
                `Deze lesmodule wordt gebruikt in een groep. Deze waarde moet gelijk blijven aan "${info.value}".`,
        },
        'module.amountOfWeeklyLessons': {
            'should-equal-value': info =>
                `Deze lesmodule wordt gebruikt in een groep. Deze waarde moet gelijk blijven aan "${info.value}".`,
        },
        'module.amountOfTotalLessons': {
            'should-equal-value': info =>
                `Deze lesmodule wordt gebruikt in een groep. Deze waarde moet gelijk blijven aan "${info.value}".`,
        },
        'module.exams': {
            'exam-is-locked-in-module': info =>
                `Het examen "${info.examName}" kan niet van de lesmodule verwijderd worden, omdat deze al is gepland in één of meerdere groepslessen.`,
            'exam-ability-is-locked-in-module': info =>
                `De vaardigheid "${translateType('examAbility', info.ability)}" van examen "${
                    info.examName
                }" kan niet van de lesmodule verwijderd worden, omdat deze al is gepland in één of meerdere groepslessen.`,
            'exam-ability-not-available-in-exam': info =>
                `De vaardigheid "${translateType('examAbility', info.ability)}" is niet beschikbaar in examen "${
                    info.examName
                }".`,
        },
        'module.exams[]': {
            'at-least-one-exam-ability-is-required-in-module': info =>
                `Selecteer op zijn minst één vaardigheid voor examen "${info.examName}".`,
        },
        'module.isFinalExamTraining': {
            'exam-training-not-possible': () =>
                `Examentraining is alleen mogelijk in het geval van inburgering en wanneer de lesmodule niet voor alfabetisering is.`,
        },
        'module.hasFinalReview': {
            'final-review-not-possible': () => `Eindgesprek is alleen mogelijk in het geval van inburgering.`,
        },
    },
    modules_delete: {
        generic: {
            'module-used-in-learner-users': info =>
                `Deze lesmodule wordt gebruikt bij kandidaten als advies-lesmodule, waaronder bij "${info.users[0].name}".`,
            'module-used-in-groups': info =>
                `Deze lesmodule wordt gebruikt in groepen, waaronder in "${info.groups[0].name}".`,
            'module-used-in-programs': info =>
                `Deze lesmodule wordt gebruikt in opleidingen, waaronder in "${info.programs[0].name}".`,
        },
    },
    organizations_create: {
        'organization.name': {
            'already-exists': () => `Deze organisatie bestaat al.`,
        },
    },
    organizations_edit: {
        'organization.name': {
            'already-exists': () => `Deze organisatie bestaat al.`,
        },
    },
    organizations_delete: {
        generic: {
            'organization-used-in-learner-users': info =>
                `Deze organisatie wordt gebruikt bij kandidaten, waaronder bij "${info.users[0].name}".`,
        },
    },
    programs_create: {
        'program.name': {
            'already-exists': () => `Deze opleiding bestaat al.`,
        },
    },
    programs_edit: {
        'program.name': {
            'already-exists': () => `Deze opleiding bestaat al.`,
        },
    },
    programs_delete: {
        generic: {
            'program-used-in-projects': info =>
                `Deze opleiding wordt gebruikt in projecten, waaronder in "${info.projects[0].name}".`,
            'program-used-in-groups': info =>
                `Deze opleiding wordt gebruikt in groepen, waaronder in "${info.groups[0].name}".`,
        },
    },
    projects_create: {
        'project.name': {
            'already-exists': () => `Dit project bestaat al.`,
        },
    },
    projects_edit: {
        'project.name': {
            'already-exists': () => `Dit project bestaat al.`,
        },
        'project.articleCodes[].articleCode': {
            'length-max': () => `Twinfield beperkt de lengte tot 16 tekens`,
        },
        'project.projectType': {
            'project-type-must-be-organization-when-invoicing-settings-exist': () =>
                `Type project moet Organisatie zijn wanneer er facturatie-instellingen bestaan.`,
        },
        'project.programIds': {
            'cannot-remove-program-id-when-used-by-invoicing-setting': info =>
                `Opleiding "${info.programName}" mag niet verwijderd worden van het project zolang er facturatie-instellingen aan gekoppeld zijn.`,
        },
    },
    projects_delete: {
        generic: {
            'project-used-in-organizations': info =>
                `Dit project wordt gebruikt in organisaties, waaronder in "${info.organizations[0].name}".`,
            'project-used-in-groups': info =>
                `Dit project wordt gebruikt in groepen, waaronder in "${info.groups[0].name}".`,
            'project-has-invoicing-settings': info =>
                `Dit project heeft nog facturatie-instellingen: waaronder "${info.projectInvoicingSettings[0].name}".`,
        },
    },
    courseMaterials_create: {
        'courseMaterial.name': {
            'already-exists': () => `Dit lesmateriaal bestaat al.`,
        },
    },
    courseMaterials_edit: {
        'courseMaterial.name': {
            'already-exists': () => `Dit lesmateriaal bestaat al.`,
        },
    },
    courseMaterials_delete: {
        generic: {
            'courseMaterial-used-in-modules': info =>
                `Dit lesmateriaal wordt gebruikt in lesmodules, waaronder in "${info.modules[0].name}".`,
        },
    },
    exams_create: {
        'exam.name': {
            'already-exists': () => `Deze eindtoets bestaat al.`,
        },
    },
    exams_edit: {
        'exam.name': {
            'already-exists': () => `Deze eindtoets bestaat al.`,
        },
    },
    exams_delete: {
        generic: {
            'exam-used-in-modules': info =>
                `Deze eindtoets wordt gebruikt in lesmodules, waaronder in "${info.modules[0].name}".`,
        },
    },
    locationProperties_create: {
        'locationProperty.name': {
            'already-exists': () => `Dit locatiekenmerk bestaat al.`,
        },
    },
    locationProperties_edit: {
        'locationProperty.name': {
            'already-exists': () => `Dit locatiekenmerk bestaat al.`,
        },
    },
    locationProperties_delete: {
        generic: {
            'locationProperty-used-in-modules': info =>
                `Deze locatie-eis wordt gebruikt in lesmodules, waaronder in "${info.modules[0].name}".`,
            'locationProperty-used-in-rooms': info =>
                `Deze locatie-eis wordt gebruikt in lesruimten, waaronder in "${info.rooms[0].name}" van de locatie "${info.rooms[0].location}".`,
        },
    },
    users_addDocuments: {
        'documents[].type': {
            'should-be-one-of-values': () => `Ongeldige documentsoort`,
        },
    },
    users_sendInvite: {
        userId: {
            'entity-should-exist': () => `Kan de gebruiker niet vinden.`,
            'user-has-no-email': () => `Deze gebruiker heeft geen e-mailadres.`,
        },
    },
    groups_changeConceptUsers: {
        generic: {
            'user-has-no-intake': info =>
                `De kandidaat "${info.user}" heeft nog geen advies-lesmodule en heeft nog nooit in een groep gezeten.`,
            'learner-is-inactive': info => `De kandidaat "${info.user}" is inactief.`,
            'teacher-is-inactive': info => `De docent "${info.user}" is inactief.`,
            'employee-is-inactive': info => `De medewerker "${info.user}" is inactief.`,
            'teacher-is-used-in-future-lesson': info =>
                `De docent "${info.user}" is toegewezen aan een toekomstige les: "Les ${info.lessonIndex + 1}".`,
        },
        'groupUsersToAdd[]': {
            'user-already-added-to-group': info => `De gebruiker "${info.user}" is al toegevoegd aan de groep`,
        },
        'groupUsersToRemove[]': {
            'user-not-in-group': info => `De gebruiker "${info.user}" zit niet in de groep`,
            'user-already-removed-from-group': info => `De gebruiker "${info.user}" is al verwijderd uit de groep`,
        },
    },
    groups_create: {
        'group.generalWeekLessonTeacherUserIds[]': {
            'entity-should-exist': () => `Deze docent zit niet in deze groep.`,
        },
    },
    groups_edit: {
        'group.generalWeekLessonTeacherUserIds[]': {
            'entity-should-exist': () => `Deze docent zit niet in deze groep.`,
        },
        'group._id': {
            'group-has-ended': () => `Deze groep is beëindigd.`,
        },
    },
    groups_delete: {
        generic: {
            'group-has-attendances': () =>
                `Deze groep heeft ingevulde presenties, en kan hierom niet verwijderd worden.`,
        },
    },
    groups_end: {
        generic: {
            'group-end-not-allowed': info => {
                switch (info.reason) {
                    case 'date':
                        return 'Het is nog niet toegestaan om de groep te beëindigen, omdat de laatste les nog moet plaatsvinden.'
                    case 'attendances':
                        return 'Het is nog niet toegestaan om de groep te beëindigen, omdat nog niet alle presenties zijn ingevuld.'
                }
            },
        },
        'removedGroupUsers[].userId': {
            required: ({ user }) => `De gebruiker ${user} is ongeldig.`,
            'should-exist': ({ user }) => `De gebruiker ${user} is ongeldig.`,
        },
        'removedGroupUsers[].status': {
            required: ({ user }) => `De gebruiker ${user} heeft geen status geselecteerd.`,
            'should-be-one-of-values': ({ user }) => `De gebruiker ${user} heeft geen status geselecteerd.`,
        },
        'removedGroupUsers[].adviceModuleId': {
            required: ({ user }) => `Selecteer een advies-lesmodule voor gebruiker ${user}.`,
            'should-exist': ({ user }) => `De advies lesmodule voor gebruiker ${user} bestaat niet.`,
        },
        'removedGroupUsers[].removedReason': {
            'should-be-one-of-values': ({ user }) => `De gebruiker ${user} heeft geen geldige afgerond status.`,
            required: ({ user }) => `De gebruiker ${user} heeft geen afgerond status.`,
        },
        'removedGroupUsers[].role': {
            'should-be-one-of-values': ({ user }) => `De gebruiker ${user} is geen curists.`,
            required: ({ user }) => `De gebruiker ${user} is geen curists.`,
        },
    },
    lessons_create: {
        generic: {
            'group-does-not-contain-module': () => `Deze groep bevat geen lesmodule.`,
            'module-duration-is-not-set': () => `Er is (nog) geen lesduur ingesteld in de lesmodule van deze groep.`,
        },
        'lesson.plannedAbilityExams': {
            'lesson-special-activity-must-be-exam': () =>
                `Bijzondere activiteit moet "Toets" zijn om examens in te kunnen plannen op deze les.`,
            'no-differences-allowed': () =>
                `Er mag per les maar één toets afgenomen worden. Binnen die toets zijn wel meerdere onderdelen mogelijk.`,
        },
        'lesson.plannedAbilityExams[].examAbility': {
            'exam-not-available-in-group': info => `Het examen "${info.examName}" is niet beschikbaar in deze groep`,
            'lesson-exam-already-exists': info =>
                `Het examen "${info.examName}" wordt al gegeven in Les ${info.lessonOrder + 1}`,
        },
    },
    lessons_edit: {
        generic: {
            'group-does-not-contain-module': () => `Deze groep bevat geen lesmodule.`,
            'module-duration-is-not-set': () => `Er is (nog) geen lesduur ingesteld in de lesmodule van deze groep.`,
            'teacher-not-in-group': info => `De docent "${info.user}" maakt geen deel (meer) uit van deze groep.`,
        },
        'lesson.plannedAbilityExams': {
            'lesson-special-activity-must-be-exam': () =>
                `Bijzondere activiteit moet "Toets" zijn om examens in te kunnen plannen op deze les.`,
            'no-differences-allowed': () =>
                `Er mag per les maar één toets afgenomen worden. Binnen die toets zijn wel meerdere onderdelen mogelijk.`,
        },
        'lesson.plannedAbilityExams[]': {
            'exam-locked-by-results': info =>
                `Het examen "${info.examName}" mag niet van de les gehaald worden, omdat er al resultaten zijn ingevoerd`,
        },
        'lesson.plannedAbilityExams[].examAbility': {
            'exam-not-available-in-group': info => `Het examen "${info.examName}" is niet beschikbaar in deze groep`,
            'lesson-exam-already-exists': info =>
                `Het examen "${info.examName}" wordt al gegeven in Les ${info.lessonOrder + 1}`,
        },
    },
    lessons_setPresence: {
        generic: {
            'entity-should-exist': () => `Presentie invoeren mislukt voor deze kandidaat. Probeer het opnieuw.`,
        },
        'lessonUser.lessonId': {
            'group-is-concept': () =>
                `De groep is nog in concept-fase, waardoor er nog geen presentie aangegeven kan worden voor de deelnemers.`,
        },
    },
    rooms_delete: {
        generic: {
            'room-used-in-lessons': () => `Deze ruimte wordt gebruikt in één of meerdere lessen.`,
        },
    },
    // users_importFromEdisaFile: {
    //     'edisaImport.file': {
    //         'invalid-xls': () => `Ongeldig Edisa Excel document`,
    //         'last-row-mismatch': (info) => `Regel ${info.excelRowNumber} van de vorige import komt niet overeen met regel ${info.excelRowNumber} van deze import.`,
    //         'last-row-not-found': (info) => `Regel ${info.excelRowNumber} van de vorige import kan niet gevonden worden in deze import.`,
    //     },
    // },
    users_createLessonTimetableFile: {
        generic: {
            'all-lessons-must-have-a-date': () => `Niet alle lessen hebben een datum`,
            'all-lessons-must-have-a-location': () => `Niet alle lessen hebben een locatie`,
        },
    },
    users_createLessonAttendanceFile: {
        generic: {
            'all-lessons-must-have-a-date': () => `Niet alle lessen hebben een datum`,
            'group-must-contain-learners': () => `Deze groep bevat geen kandidaten`,
        },
    },
    timelineEvents_create: {
        generic: {
            'locked-is-log': () => `Systeemlogs kunnen niet worden aangepast`,
            'owner-target-may-not-be-removed': () => `Ongeldige zichtbaarheid`,
        },
    },
    timelineEvents_edit: {
        generic: {
            'locked-is-log': () => `Systeemlogs kunnen niet worden aangepast`,
            'owner-target-may-not-be-removed': () => `Ongeldige zichtbaarheid`,
        },
    },
    timelineEvents_delete: {
        generic: {
            'locked-is-log': () => `Systeemlogs kunnen niet worden verwijderd`,
        },
    },
    invoices_createDebit: {
        generic: {
            'group-missing-start-date': () =>
                `Er kan geen factuur aangemaakt worden, omdat de groep geen startdatum heeft`,
            'invoice-may-not-be-credited': () => `Er kan (nog) geen creditfactuur worden aangemaakt op deze factuur`,
            'user-not-in-any-group': () => `Kandidaat zit niet in een groep`,
            'cannot-create-invoice-with-zero-amount': () => `Kan geen factuur maken voor €0`,
            'no-contracts-found': () =>
                'Deze gebruiker heeft geen contracten voor deze periode. Factuur kan niet worden aangemaakt.',
        },
        'invoice.isGroupParticipationInvoice': {
            'group-invoice-not-activatable': info =>
                `Groepsfacturen activeren is alleen mogelijk voor groepen die een startdatum na ${moment(
                    info.threshold
                ).format('L')} hebben.`,
        },
        'invoice.amount': {
            min: info => `Dit moet minimaal ${formatCurrency(info.min)} zijn.`,
            max: info => `Dit mag maximaal ${formatCurrency(info.max)} zijn.`,
            'user-has-insufficient-duo-fund': () => `De kandidaat heeft onvoldoende leenruimte voor dit bedrag`,
        },
        'invoice.installments': {
            'group-installments-not-allowed-is-no-group': () =>
                `Alleen groepsparticipatie facturen mogen in termijnen betaald worden.`,
            'group-installments-not-allowed-is-duo': () =>
                `Alleen zelfbetalende kandidaten mogen in termijnen betalen.`,
            'group-installments-not-allowed-min-amount': info =>
                `Alleen facturen boven ${formatCurrency(info.minAmount)} mogen in termijnen betaald worden.`,
        },
        'invoice.userId': {
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
        },
        'invoice.lessonIds[]': {
            'lesson-not-activatable': () => `Eén of meerdere geselecteerde lessen zijn (nog) niet factureerbaar`,
        },
    },
    invoices_createCredit: {
        generic: {
            'invoice-may-not-be-credited': () => `Er kan (nog) geen creditfactuur worden aangemaakt op deze factuur`,
            'user-not-in-any-group': () => `Kandidaat zit niet in een groep`,
            'cannot-create-invoice-with-zero-amount': () => `Kan geen creditnota maken voor €0`,
        },
        'invoice.amount': {
            min: info => `Dit moet minimaal ${formatCurrency(info.min)} zijn.`,
            max: info => `Dit mag maximaal ${formatCurrency(info.max)} zijn.`,
            'user-has-insufficient-duo-fund': () => `De kandidaat heeft onvoldoende leenruimte voor dit bedrag`,
        },
        'invoice.userId': {
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
        },
    },
    invoices_createGroupParticipationCreditInvoice: {
        generic: {
            'invoice-may-not-be-credited': () => `Er kan (nog) geen creditfactuur worden aangemaakt op deze factuur`,
            'user-not-in-any-group': () => `Kandidaat zit niet in een groep`,
            'cannot-create-invoice-with-zero-amount': () => `Kan geen creditnota maken voor €0`,
            'lessonuser-not-found': () => 'Inschrijving voor deze gebruiker voor deze les niet gevonden.',
            'lesson-already-credited': () => 'Les is reeds gecrediteerd.',
            'lessonuser-credit-not-allowed': () => 'Crediteren les niet toegestaan.',
        },
        'invoice.isGroupParticipationInvoice': {
            'group-invoice-not-activatable': info =>
                `Groepsfacturen activeren is alleen mogelijk voor groepen die een startdatum na ${moment(
                    info.threshold
                ).format('L')} hebben.`,
        },
        'invoice.userId': {
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
        },
    },
    invoices_deleteDebit: {
        generic: {
            'invoice-is-final': () => `Factuur is definitief en kan niet worden verwijderd`,
        },
    },
    invoices_deleteCredit: {
        generic: {
            'invoice-is-final': () => `Factuur is definitief en kan niet worden verwijderd`,
        },
    },
    invoices_cancel: {
        generic: {
            'invoice-may-not-be-canceled': () =>
                `Factuur mag alleen verscheurd worden als de factuur als 'concept' is aangemaakt in Twinfield`,
        },
    },
    invoices_makeFinal: {
        generic: {
            'invoice-already-made-final': () => `De factuur is al verzonden.`,
            'invoice-incorrect-amount': () => `Het factuurbedrag is incorrect.`,
            'invoice-missing-twinfield-customer-code': () => `De Twinfield debiteur is onbekend.`,
            'invoice-missing-article-code': () =>
                `Er kan geen artikelcode bepaald worden voor deze factuur. Controleer de instellingen op het gerelateerde project.`,
            'invoice-incorrect-article-code': info =>
                `De artikelcode is incorrect. ${parseNestedGraphQLError(info.articleCodeValidationError)}`,
            'invoice-could-not-be-send': () => `De factuur kan om onbekende reden niet definitief gemaakt worden.`,
            'invoice-too-large-credited-amount': info =>
                `Het creditbedrag ligt te hoog. Deze kan niet meer zijn dan € ${info.limit}.`,
            'user-has-no-email': () => `Deze gebruiker heeft geen e-mailadres.`,
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
            'invoice-missing-learner-signature-for-duo': () =>
                `Deze factuur heeft nog een handtekening van de kandidaat nodig.`,
            'user-has-no-valid-address': () => `Deze gebruiker heeft een onvolledig adres`,
            'user-has-no-valid-bsn': () => `Deze gebruiker heeft een ongeldig BS-nummer`,
            'user-has-no-valid-name': () => `Deze gebruiker heeft een ongeldige naam`,
        },
    },
    invoices_uploadSignatureForDuo: {
        generic: {
            'invoice-could-not-be-made-final': () =>
                `De factuur kan om onbekende reden niet definitief gemaakt worden in Twinfield.`,
        },
    },
    invoices_createDebit: {
        generic: {
            'no-contracts-found-for-new-invoice': () =>
                `Factuur kan niet worden aangemaakt. Geen passend contract gevonden.`,
        },
    },
    invoices_generatePdf: {
        generic: {
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
            'no-contracts-found-for-new-invoice': () =>
                `Factuur kan niet worden aangemaakt. Geen passend contract gevonden.`,
            'no-contracts-found-for-existing-invoice': info =>
                `Factuur ${info.invoiceNumber} kan niet worden gegenereerd. Geen passend contract gevonden.`,
        },
    },
    invoices_createExportFile: {
        generic: {
            'user-may-not-have-invoices': () =>
                `Deze gebruiker kan geen nieuwe facturen (meer) ontvangen, omdat zijn profiel ongeschikt is. Dit kan betekenen dat het geen particuliere betaler is, dat de Twinfield debiteur niet is ingevuld, of een andere reden.`,
            'no-contracts-found-for-existing-invoice': info =>
                `Factuur ${info.invoiceNumber} kan niet worden gegenereerd. Geen passend contract gevonden.`,
        },
    },
    projects_create: {
        'project.articleCodes[].articleCode': {
            'length-max': () => `Twinfield beperkt de lengte tot 16 tekens`,
        },
    },
    DUODeclarations_create: {
        'DUODeclaration.amount': {
            'max-total-duo-fund': () => `De kandidaat heeft onvoldoende leenruimte voor dit bedrag`,
            'max-quarter-duo-fund': info => `In dit kwartaal kan nog maar ${formatCurrency(info.max)} besteed worden`,
        },
    },
    DUODeclarations_edit: {
        generic: {
            'updates-not-allowed': () =>
                `Updates zijn niet toegestaan. Je kunt de declaratie verwijderen en opnieuw aanmaken.`,
        },
    },
    DUOResponse_addDocument: {
        generic: {
            'duo-invoice-batch-response-row-has-no-learner-user': bsn => `Geen kandidaat gevonden met BSN ${bsn}`,
        },
    },
    exports_participated_hours_duo: {
        generic: {
            'generate-participated-hours-bsn-not-found': bsnValues => `BSN(s) ${bsnValues.join(', ')} niet gevonden`,
        },
    },
    finalExams_cancel: {
        generic: {
            'final-exam-has-unresolved-invoices': () => 'Examen heeft openstaande facturen.',
            'can-not-cancel-toptaal-exam-without-document': () =>
                'Document is verplicht voor annuleren intern gepland examen.',
            'can-not-cancel-final-exam-with-result': () => 'Kan examen met resultaat niet annuleren',
            'can-not-cancel-canceled-final-exam': () => 'Dit examen is al geannuleerd',
            'can-not-cancel-final-exam-in-past': () => 'Een examen in het verleden kan niet geannuleerd worden',
        },
    },
    finalExams_insertMultiple: {
        generic: {
            'file-required-for-toptaal-submitter': () => 'Afspraak als PDF is verplicht voor intern gepland examen.',
            'date-must-be-in-future': () => 'Datum moet in de toekomst zijn',
            'not-enough-remaining-fund': () => 'Gebruiker heeft niet genoeg budget',
            'final-exam-already-exists': () => 'Dit examen bestaat al',
        },
    },
    finalExams_update: {
        generic: {
            'too-late-to-change-date': () =>
                'De datum van een examen kan uiterlijk 14 dagen voor de aanvang van het examen worden aangepast',
            'file-required-when-submitter-is-toptaal': () => 'Afspraak PDF is verplicht voor intern gepland examen',
            'final-exam-already-exists': () => 'Dit examen bestaat al',
            'final-exam-has-results': () => 'Dit examen kan niet meer aangepast worden',
        },
    },
    inflowMomentTimeslots_add: {
        generic: {
            'inflowmoment-must-be-final': () => 'Het instroommoment moet definitief zijn.',
            'timeslot-cannot-have-new-timeslots': () =>
                `Dit instroommoment kan geen nieuwe tijdslots krijgen, omdat het interval tussen startmomenten 0 is. Om meer kanididaten toe te laten, kun je het 'aantal kandidaten per startmoment per toetsdocent' verhogen.`,
        },
    },
    inflowMomentTimeslots_changeUsers: {
        generic: {
            'user-already-booked-in-another-timeslot-in-this-inflowmoment': () =>
                'Gebuiker is als ingepland in een ander tijdslot op dit instroommoment.',
            'users-must-be-part-of-inflow-moment-organization': () =>
                'Gebruiker moet lid zijn van de organisatie van dit instroommoment.',
            'max-users-reached-for-timeslot': () => 'Maximum gebruikers bereikt voor dit tijdslot.',
        },
    },
    inflowMoments_edit: {
        generic: {
            'array-length-min': info => `Er moeten minimaal ${info.min} toetsdocenten geslecteerd zijn.`,
        },
    },
    inflowMoments_delete: {
        generic: {
            'can-not-delete-used-inflowmoment': () => 'Instroommomenten met kandidaten kunnen niet worden verwijderd.',
        },
    },
    contracts_terminate: {
        generic: {
            'contract-must-be-signed': () => 'Contract is niet ondertekend.',
            'contract-is-already-terminated': () => 'Contract is al beëindigd.',
            'termination-date-must-be-before-end-date': () => 'Beëindigingsdatum moet voor de de vervaldatum zijn.',
            'termination-date-must-be-after-start-date': () => 'Beëindigingsdatum moet na de startdatum zijn.',
        },
    },
    inflowModules_create: {
        'inflowModule.exams[].abilities': {
            'length-min': () => `Selecteer minstens één vaardigheid`,
        },
    },
    inflowModules_edit: {
        'inflowModule.exams[].abilities': {
            'length-min': () => `Selecteer minstens één vaardigheid`,
        },
    },
    result_enterMultiple: {
        generic: {
            'exam-ability-result-already-exists': () =>
                `Er bestaat al een toetsresultaat voor deze kandidaat voor deze toets`,
        },
    },
    adviceReports_create: {
        generic: {
            'entity-should-exist': () => `Combinatie tussen het tijdslot en de kandidaat is niet gevonden.`,
            'new-advice-report-cannot-be-created': () =>
                `Gebruiker is niet verschenen. Er kan geen adviesrapport gemaakt worden.`,
        },
    },
    adviceReports_edit: {
        generic: {
            'cannot-edit-advice-report-after-manual-release': () =>
                `Adviesrapport moet eerst worden ingetrokken voordat deze kan worden bewerkt.`,
        },
    },
    adviceReports_delete: {
        generic: {
            'cannot-delete-advice-report-after-manual-release': () =>
                `Adviesrapport moet eerst worden ingetrokken voordat deze kan worden verwijderd.`,
        },
    },
    groups_enrollUser: {
        generic: {
            'user-is-already-enrolled': () => `Deze deelnemer zit al in de groep`,
        },
    },
    groups_undoEnrollment: {
        generic: {
            'attendances-are-set': () => `Deze deelnemer heeft al ingevoerde presentie na de aangegeven datum.`,
        },
    },
    groups_disenrollUser: {
        generic: {
            'attendances-are-set': () => `Deze deelnemer heeft al ingevoerde presentie na de aangegeven datum.`,
        },
    },
    userLearnerSignups_create: {
        'userLearnerSignup.signupDate': {
            'already-exists': () => `Er is al een aanmelding op deze dag.`,
        },
    },
    userLearnerSignups_edit: {
        'userLearnerSignup.signupDate': {
            'already-exists': () => `Er is al een aanmelding op deze dag.`,
        },
    },
}
