import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import Icon from './Icon'

interface Props {
    text: string
    className?: ClassValue
    isWarning?: boolean
    fullWidth?: boolean
}

export class ValidationError extends React.Component<Props> {
    private bem = new BEM('ValidationError', () => ({
        'is-warning': this.props.isWarning,
        'full-width': this.props.fullWidth,
    }))

    public render() {
        const { text, className, isWarning } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <p>
                    <Icon name={isWarning ? `info` : `status_attention`} /> {text}
                </p>
            </div>
        )
    }
}
