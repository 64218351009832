import * as React from 'react'
import { RouteComponentProps, browserHistory } from 'react-router'
import { ProcessView } from '~/views/App/Common/ProcessView'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { getCurrentUser } from '~/services/session'
import { View } from '~/components/View'
import Header from '~/components/Header'
import PageTabs from '~/components/PageTabs'
import PageTab from '~/components/PageTab'
import Link from '~/components/Link'
import { ContentView } from '~/components/ContentView'
import { BreadCrumbs } from '~/components/BreadCrumbs'

interface Props extends RouteComponentProps<{}, {}> {}

export class InflowMomentsView extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
        const user = getCurrentUser()

        if (user && user.isExternalTeacher && !user.isIntaker) {
            // quick fix to give non-intaker external teacher alternate starting route
            browserHistory.replace('/groups')
        }
    }

    public render() {
        const { children, ...routeComponentProps } = this.props
        const tabs = this.getTabsForCurrentUser()

        return (
            <BreadCrumbProvider>
                <ProcessView crumbLabel={'Instroom'} routeProps={routeComponentProps}>
                    <View>
                        <Header>
                            <BreadCrumbs />
                            {tabs.length > 0 && (
                                <PageTabs>
                                    {tabs.map((tab, i) => (
                                        <PageTab key={i}>
                                            <Link onlyActiveOnIndex={true} route={tab.route}>
                                                {tab.label}
                                            </Link>
                                        </PageTab>
                                    ))}
                                </PageTabs>
                            )}
                        </Header>
                        <ContentView>{children}</ContentView>
                    </View>
                </ProcessView>
            </BreadCrumbProvider>
        )
    }

    private getTabsForCurrentUser() {
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee) {
            return [
                {
                    route: '/inflow-moments/candidates',
                    label: 'Kandidaten',
                },
                {
                    route: '/inflow-moments/concept',
                    label: 'Concept',
                },
                {
                    route: '/inflow-moments/definitive',
                    label: 'Definitief',
                },
            ]
        }

        if (currentUser.isOrganizationContact) {
            return [
                {
                    route: '/inflow-moments/candidates',
                    label: 'Kandidaten',
                },
                {
                    route: '/inflow-moments/definitive',
                    label: 'Planning',
                },
            ]
        }

        return []
    }
}
