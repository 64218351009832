import { gql } from '@apollo/client'
import React from 'react'
import { browserHistory } from 'react-router'
import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Mutator, transformFormFields } from '~/utils'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
    moduleId: string
}

interface CreateGroupModuleInviteFields {
    groupInviteTemplate: {
        name: string
    }
}

export class CreateGroupInviteTemplateForm extends React.Component<Props> {
    private groupInviteTemplateMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.groupInviteTemplateMutator = new Mutator({
            mutation: CREATE_GROUP_INVITE_TEMPLATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.groupInviteTemplateMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Naam`} errors={errors}>
                            <Input
                                name={`groupInviteTemplate.name`}
                                type={`text`}
                                placeholder={`Naam van uitnodigingsbrief`}
                                autoFocus={true}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: CreateGroupModuleInviteFields) => {
        const { onSubmitSuccess, onSubmitFailed, moduleId } = this.props

        const data = await this.groupInviteTemplateMutator.mutate({
            data: transformFormFields(
                {
                    ...fields.groupInviteTemplate,
                    moduleId,
                },
                {}
            ),
        })

        if (data && data.groupInviteTemplates_create && data.groupInviteTemplates_create._id) {
            onSubmitSuccess()
            browserHistory.push(`/properties/modules/${moduleId}/invites/${data.groupInviteTemplates_create._id}/edit`)
        } else {
            if (onSubmitFailed) {
                onSubmitFailed()
            }
        }
    }
}

const CREATE_GROUP_INVITE_TEMPLATE_MUTATION = gql`
    mutation ($data: GroupInviteTemnplateInputType!) {
        groupInviteTemplates_create(groupInviteTemplate: $data) {
            _id
            module {
                _id
                name
            }
            name
            updatedAt
            nl {
                content
            }
            en {
                content
            }
            attachments {
                _id
                fileName
            }
        }
    }
`
