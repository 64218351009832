import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, ActionBar } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { InflowModule } from '~/types/InflowModule'
import { FileUploadInput } from '~/components/FileUploadInput/FileUploadInput'
import { InviteTextInput } from '~/components/InviteText/InviteTextInput/InviteTextInput'
import { InflowInviteTemplate, inflowInviteTemplateTextVariables } from '~/types/InflowInviteTemplate'
import { gql } from '@apollo/client'
import { Mutator, parseGraphQLError, toast, transformFormFields } from '~/utils'
import apolloClient from '~/services/apolloClient'

interface RouteParams {
    id: string
    inviteId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
    inflowInviteTemplate: InflowInviteTemplate
    refetch: () => void
}
export class AppPropertiesInflowModulesInvitesDetailEdit extends React.Component<Props> {
    private inflowModulesInviteTemplateMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowModulesInviteTemplateMutator = new Mutator({
            mutation: EDIT_INFLOW_INVITE_TEMPLATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { inflowModule, inflowInviteTemplate } = this.props
        const { errors, loading } = this.inflowModulesInviteTemplateMutator

        return (
            <Wrap>
                <Form onSubmit={this.onSubmit}>
                    <FieldCollection>
                        <FieldGroup title={`Gegevens`} isForm={true}>
                            <Field isLabel={true} title={'Naam uitnodigingsbrief'} errors={errors}>
                                <Input
                                    name={`inflowInviteTemplate.name`}
                                    type={`text`}
                                    placeholder={`Naam`}
                                    defaultValue={inflowInviteTemplate.name}
                                />
                            </Field>
                        </FieldGroup>
                        <FieldGroup title={'Inhoud'} isForm={true}>
                            {/* Don't add label to prevent button weirdness */}
                            <Field title={'Content placeholders'} errors={errors}>
                                <InviteTextInput
                                    name="inflowInviteTemplate.nl.content"
                                    nameEnglish="inflowInviteTemplate.en.content"
                                    defaultText={inflowInviteTemplate.nl && inflowInviteTemplate.nl.content}
                                    defaultTextEnglish={inflowInviteTemplate.en && inflowInviteTemplate.en.content}
                                    textVariables={inflowInviteTemplateTextVariables}
                                />
                            </Field>
                        </FieldGroup>
                        <FieldGroup title={`Bijlagen`} isForm={true}>
                            <Field isLabel={true} title={'Bestanden'} errors={errors}>
                                <FileUploadInput
                                    name={`inflowInviteTemplate.attachments`}
                                    accept={`application/pdf,image/*`}
                                    multi={true}
                                    defaultFiles={inflowInviteTemplate.attachments}
                                >
                                    Kies bestand
                                </FileUploadInput>
                            </Field>
                        </FieldGroup>
                        <ActionBar
                            getButtons={() => (
                                <List horizontal>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(
                                                    `/properties/inflow/${inflowModule._id}/invites/${inflowInviteTemplate._id}`
                                                )
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            )}
                            fixed={true}
                            isWide={true}
                        />
                    </FieldCollection>
                </Form>
            </Wrap>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { params, refetch, inflowModule, inflowInviteTemplate } = this.props
        const data = await this.inflowModulesInviteTemplateMutator.mutate({
            data: {
                _id: params.inviteId,
                ...transformFormFields(fields.inflowInviteTemplate),
            },
        })

        if (data) {
            refetch()
            browserHistory.push(`/properties/inflow/${inflowModule._id}/invites/${inflowInviteTemplate._id}`)
        }
    }

    private onDelete = async () => {
        const { inflowInviteTemplate, inflowModule } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_INFLOW_INVITE_TEMPLATE_MUTATION,
                variables: {
                    _id: inflowInviteTemplate._id,
                },
            })

            browserHistory.push(`/properties/inflow/${inflowModule._id}/invites`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'inflowInviteTemplates_delete' })
            toast.error(`${message}`)
        }
    }
}

const EDIT_INFLOW_INVITE_TEMPLATE_MUTATION = gql`
    mutation _($data: InflowInviteTemnplateInputType!) {
        inflowInviteTemplates_edit(inflowInviteTemplate: $data) {
            _id
            inflowModule {
                _id
                name
            }
            name
            updatedAt
            nl {
                content
            }
            en {
                content
            }
            attachments {
                _id
                fileName
            }
        }
    }
`

const DELETE_INFLOW_INVITE_TEMPLATE_MUTATION = gql`
    mutation _($_id: MongoID!) {
        inflowInviteTemplates_delete(inflowInviteTemplateId: $_id) {
            _id
        }
    }
`
