import * as React from 'react'

import { List } from '~/components/List'
import { User } from '~/types/User'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    onClose?: () => void
    user?: User
}

interface State {
    open: boolean
}

export class MobileNav extends React.Component<Props, State> {
    public state: State = {
        open: false,
    }

    private bem = new BEM('MobileNav', () => ({
        employee: this.props.user && this.props.user.isEmployee,
    }))

    public render() {
        const { children, className, onClose } = this.props

        return (
            <nav className={this.bem.getClassName(className)}>
                <List>{children}</List>
                <div className={this.bem.getElement('close')} onClick={onClose} />
            </nav>
        )
    }
}
