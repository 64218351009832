import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import Input from '~/components/Input'
import TextArea from '~/components/TextArea'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import { bindAll, get, isBoolean } from 'lodash'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { UserSharePanteiaTypesEnum } from '~/generated/graphql'

interface Props {
    errors?: GraphQLErrors
    user: User
    sharePanteia?: UserSharePanteiaTypesEnum
    onChangeSharePanteia: (value: UserSharePanteiaTypesEnum | undefined) => void
}

export class UserLearnerOtherFieldGroup extends React.Component<Props> {
    constructor(props: Props) {
        super(props)

        bindAll(this, ['onSharePanteiaClick'])
    }

    private onSharePanteiaClick(t: React.MouseEvent<HTMLInputElement>) {
        const { onChangeSharePanteia, sharePanteia } = this.props
        if (t.currentTarget.value === sharePanteia) {
            onChangeSharePanteia(UserSharePanteiaTypesEnum.Unset)
        } else {
            onChangeSharePanteia(t.currentTarget.value as UserSharePanteiaTypesEnum)
        }
    }

    public render() {
        const { errors, user, sharePanteia } = this.props

        return (
            <FieldGroup title={`Overige`} isForm={true}>
                <LockedFieldWrapper disableLock={!get(user, 'learner.howDoYouKnowTopTaal')}>
                    {isLocked => (
                        <Field isLabel title={`Hoe ben je bij TopTaal gekomen?`} errors={errors}>
                            <Input
                                name={`user.learner.howDoYouKnowTopTaal`}
                                isDisabled={isLocked}
                                type="text"
                                defaultValue={get(user, 'learner.howDoYouKnowTopTaal')}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!user.profile.notes}>
                    {isLocked => (
                        <Field isLabel={true} title={`Notities`} errors={errors}>
                            <TextArea
                                name={`user.profile.notes`}
                                isDisabled={isLocked}
                                defaultValue={user.profile.notes}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!isBoolean(get(user, 'learner.isActive'))}>
                    {isLocked => (
                        <Field title={'Status'} errors={errors}>
                            <MultiInput type="radio">
                                <RadioButton
                                    name={`user.learner.isActive`}
                                    value={true}
                                    isDisabled={isLocked}
                                    defaultChecked={!!get(user, 'learner.isActive')}
                                >
                                    Actief
                                </RadioButton>
                                <RadioButton
                                    name={`user.learner.isActive`}
                                    value={false}
                                    isDisabled={isLocked}
                                    defaultChecked={!get(user, 'learner.isActive')}
                                >
                                    Non-actief
                                </RadioButton>
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                <Field title={'Toestemming gegevensdeling met Panteia'} errors={errors}>
                    <MultiInput type="radio">
                        <RadioButton
                            name={`user.learner.sharePanteia`}
                            value={UserSharePanteiaTypesEnum.Ja}
                            defaultChecked={sharePanteia === UserSharePanteiaTypesEnum.Ja || false}
                            onClick={this.onSharePanteiaClick}
                            checked={sharePanteia === UserSharePanteiaTypesEnum.Ja}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            name={`user.learner.sharePanteia`}
                            value={UserSharePanteiaTypesEnum.Nee}
                            defaultChecked={sharePanteia === UserSharePanteiaTypesEnum.Nee || false}
                            onClick={this.onSharePanteiaClick}
                            checked={sharePanteia === UserSharePanteiaTypesEnum.Nee}
                        >
                            Nee
                        </RadioButton>
                        <RadioButton
                            name={`user.learner.sharePanteia`}
                            value={UserSharePanteiaTypesEnum.Onbekend}
                            defaultChecked={sharePanteia === UserSharePanteiaTypesEnum.Onbekend || false}
                            onClick={this.onSharePanteiaClick}
                            checked={sharePanteia === UserSharePanteiaTypesEnum.Onbekend}
                        >
                            Onbekend
                        </RadioButton>
                    </MultiInput>
                </Field>
            </FieldGroup>
        )
    }
}
