import c from 'classnames'
import * as React from 'react'

export type TNotificationType = 'info' | 'success' | 'warning' | 'error'
export interface TNotification {
    id: string
    type: TNotificationType
    message: string
    timeout: number
    dismiss: () => void
    clickHandler?: () => void
}

interface Props {
    className?: string
    type: TNotificationType
    onClick?: () => void
}

export class Notification extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return (
            <button className={this.getClassNames()} onClick={this.onClick}>
                {children}
            </button>
        )
    }

    private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        if (this.props.onClick) {
            this.props.onClick()
        }
    }

    private getClassNames() {
        const { className, type, onClick } = this.props

        return c(
            'tt-Notification',
            `tt-Notification--${type}`,
            {
                'tt-Notification--is-clickable': !!onClick,
            },
            className
        )
    }
}
