import React from 'react'
import { ListItem } from '~/components/ListItem'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class PageTab extends React.Component<Props> {
    private bem = new BEM('PageTab')

    public render() {
        const { children, className } = this.props

        return <ListItem className={this.bem.getClassName(className)}>{children}</ListItem>
    }
}
