import React from 'react'

import { ClassValue } from '~/services/BEMService'
import CenterModal from '../CenterModal'
import Form from '../Form'
import Field from '../Field'
import { FieldCollection, MultiInput, RadioButton, Button } from '..'
import { FieldGroup } from '../FieldGroup'
import { Fetcher, Mutator, transformFormFields } from '~/utils'
import { gql } from '@apollo/client'
import { TagPicker } from '../TagPicker'
import { InflowModule } from '~/types/InflowModule'
import { FieldCollectionFooter } from '../FieldCollectionFooter'
import { List } from '../List'
import { ListItem } from '../ListItem'
import { OrganizationsTagPicker } from '../Organization/OrganizationsTagPicker'
import { browserHistory } from 'react-router'

const GET_INFLOW_MODULES_QUERY = gql`
    query _ {
        inflowModules(sortBy: "name") {
            _id
            name
        }
    }
`

const CREATE_INFLOW_MOMENT_MUTATION = gql`
    mutation _($inflowMoment: InflowMomentInputType!) {
        inflowMoments_create(inflowMoment: $inflowMoment) {
            _id
        }
    }
`

interface Props {
    className?: ClassValue
    onClose: () => void
}

interface State {
    forOrganization: boolean
}

export class CreateInflowMomentModal extends React.Component<Props, State> {
    public state: State = {
        forOrganization: false,
    }

    private inflowModulesFetcher: Fetcher
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowModulesFetcher = new Fetcher({
            query: GET_INFLOW_MODULES_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.inflowMomentMutator = new Mutator({
            mutation: CREATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { data } = this.inflowModulesFetcher
        const { loading } = this.inflowMomentMutator
        const { inflowModules = [] } = data

        const { forOrganization } = this.state

        const inflowOptions = inflowModules.map((inflowModule: InflowModule) => ({
            value: inflowModule._id,
            label: inflowModule.name,
        }))

        return (
            <CenterModal onClose={onClose} title={'Toetsmoment aanmaken'}>
                <Form onSubmit={this.onSubmit}>
                    <FieldCollection style={`modal`}>
                        <FieldGroup isForm>
                            <Field isLabel={true} title={'Instroommodule'}>
                                <TagPicker multi={false} name={`inflowMoment.inflowModuleId`} options={inflowOptions} />
                            </Field>
                            <Field isLabel={true} title={'Voor een organisatie'}>
                                <MultiInput type="radio">
                                    <RadioButton
                                        name={'inflowMoment.isForOrganization'}
                                        value={true}
                                        onClick={() => this.setState({ forOrganization: true })}
                                    >
                                        Ja, namelijk;
                                    </RadioButton>
                                    <RadioButton
                                        name={'inflowMoment.isForOrganization'}
                                        value={false}
                                        onClick={() => this.setState({ forOrganization: false })}
                                    >
                                        Nee
                                    </RadioButton>
                                </MultiInput>
                            </Field>
                            {forOrganization && (
                                <FieldGroup isInsetGroup={true}>
                                    <Field isLabel={true} title={'Organisatie'}>
                                        <OrganizationsTagPicker name={'inflowMoment.organizationId'} />
                                    </Field>
                                </FieldGroup>
                            )}
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button onClick={onClose}>Annuleren</Button>
                                </ListItem>
                                <ListItem right>
                                    <Button type={`submit`} isLoading={loading}>
                                        Aanmaken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Form>
            </CenterModal>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { onClose } = this.props

        const result = await this.inflowMomentMutator.mutate({
            inflowMoment: transformFormFields(fields.inflowMoment, {}),
        })

        if (result) {
            const id = result.inflowMoments_create && result.inflowMoments_create._id

            onClose()
            browserHistory.push(`/inflow-moments/concept/${id}`)
        }
    }
}
