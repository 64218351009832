import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  MongoID: { input: any; output: any; }
  UploadedFile: { input: any; output: any; }
};

export type AbilityLevelType = {
  __typename?: 'AbilityLevelType';
  accomplishedAt?: Maybe<Scalars['Date']['output']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
};

export type ActivatableGroupParticipationInvoicesType = {
  __typename?: 'ActivatableGroupParticipationInvoicesType';
  contract?: Maybe<ContractType>;
  group?: Maybe<GroupType>;
  lessonCost?: Maybe<Scalars['Float']['output']>;
  lessonCount?: Maybe<Scalars['Int']['output']>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  month?: Maybe<Scalars['Int']['output']>;
  totalCost?: Maybe<Scalars['Float']['output']>;
};

export type AddedGroupUserInputType = {
  addedAt?: InputMaybe<Scalars['Date']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type AddressInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  nl?: InputMaybe<AddressNlInputType>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  other?: InputMaybe<AddressOtherInputType>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<PhoneNumberInputType>>>;
};

export type AddressNlExtraInfoInputType = {
  city?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type AddressNlExtraInfoType = {
  __typename?: 'AddressNLExtraInfoType';
  city?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressNlInputType = {
  manualExtraInfo?: InputMaybe<AddressNlExtraInfoInputType>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  number?: InputMaybe<Scalars['Int']['input']>;
  numberAddition?: InputMaybe<Scalars['String']['input']>;
  validatedExtraInfo?: InputMaybe<AddressNlExtraInfoInputType>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type AddressNlType = {
  __typename?: 'AddressNLType';
  extraInfo?: Maybe<AddressNlExtraInfoType>;
  manualExtraInfo?: Maybe<AddressNlExtraInfoType>;
  number?: Maybe<Scalars['Int']['output']>;
  numberAddition?: Maybe<Scalars['String']['output']>;
  validatedExtraInfo?: Maybe<AddressNlExtraInfoType>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressOtherInputType = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AddressOtherType = {
  __typename?: 'AddressOtherType';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  _id: Scalars['MongoID']['output'];
  /** @deprecated The new address structure should be used */
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<AddressNlType>;
  /** @deprecated The new address structure should be used */
  number?: Maybe<Scalars['Int']['output']>;
  /** @deprecated The new address structure should be used */
  numberAddition?: Maybe<Scalars['String']['output']>;
  other?: Maybe<AddressOtherType>;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  /** @deprecated The new address structure should be used */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated The new address structure should be used */
  street?: Maybe<Scalars['String']['output']>;
  /** @deprecated The new address structure should be used */
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AdviceReportInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  learnerUserId?: InputMaybe<Scalars['MongoID']['input']>;
  user?: InputMaybe<UserInputType>;
};

export type AdviceReportType = {
  __typename?: 'AdviceReportType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  file?: Maybe<ExportResponseType>;
  inflowMoment?: Maybe<InflowMomentType>;
  intakerUser?: Maybe<UserType>;
  isManuallyReleased?: Maybe<Scalars['Boolean']['output']>;
  learnerUser?: Maybe<UserType>;
  levelConversations?: Maybe<LearnerLevelEnum>;
  levelListening?: Maybe<LearnerLevelEnum>;
  levelReading?: Maybe<LearnerLevelWithAlphaEnum>;
  levelTalking?: Maybe<LearnerLevelEnum>;
  levelWriting?: Maybe<LearnerLevelWithAlphaEnum>;
  releasedAt?: Maybe<Scalars['Date']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum ArticleCodesTypeEnum {
  Exams = 'exams',
  GroupParticipation = 'groupParticipation',
  LessonMaterials = 'lessonMaterials'
}

export type CalendarEventInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  repetition?: InputMaybe<CalendarEventRepetitionInputType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeData?: InputMaybe<CalendarEventTypeDataInputType>;
};

export type CalendarEventRepetitionInputType = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CalendarEventType = {
  __typename?: 'CalendarEventType';
  _id: Scalars['MongoID']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  endWeekDay?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  startWeekDay?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CalendarEventTypeDataInputType = {
  learnerUserId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roomId?: InputMaybe<Scalars['MongoID']['input']>;
  teacherUserId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum ChargeForLessonReasonOfAbsenceEnum {
  Exam = 'EXAM',
  None = 'NONE',
  Other = 'OTHER',
  OtherIntegrationActivity = 'OTHER_INTEGRATION_ACTIVITY',
  PrivateReasons = 'PRIVATE_REASONS',
  Sick = 'SICK',
  TransportIssues = 'TRANSPORT_ISSUES',
  Vacation = 'VACATION',
  WorkStudy = 'WORK_STUDY'
}

export type ContractLogType = {
  __typename?: 'ContractLogType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  type?: Maybe<ContractLogTypeEnum>;
  typeData?: Maybe<ContractLogTypeDataType>;
};

export type ContractLogTypeDataType = {
  __typename?: 'ContractLogTypeDataType';
  group?: Maybe<GroupType>;
  groupId?: Maybe<Scalars['MongoID']['output']>;
};

export enum ContractLogTypeEnum {
  ContractCreated = 'contractCreated',
  ContractMadeFinal = 'contractMadeFinal',
  ContractSignatureChanged = 'contractSignatureChanged',
  ContractSigned = 'contractSigned',
  ContractTerminated = 'contractTerminated'
}

export type ContractType = {
  __typename?: 'ContractType';
  _id: Scalars['MongoID']['output'];
  contract?: Maybe<ExportResponseType>;
  contractNumber?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  isFinal?: Maybe<Scalars['Boolean']['output']>;
  isSigned?: Maybe<Scalars['Boolean']['output']>;
  logs?: Maybe<Array<Maybe<ContractLogType>>>;
  signedContract?: Maybe<ExportResponseType>;
  terminatedAt?: Maybe<Scalars['Date']['output']>;
  terminatedReason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ContractTypeEnum>;
  user?: Maybe<UserType>;
};

export enum ContractTypeEnum {
  Alfa = 'Alfa',
  Alfa150min = 'Alfa150min',
  Alfa180min = 'Alfa180min',
  DuoPaidStex = 'DuoPaidSTEX',
  Ona = 'ONA',
  RouteB1 = 'RouteB1',
  RouteZ = 'RouteZ',
  Stex = 'STEX',
  Standard = 'Standard',
  Standard150min = 'Standard150min',
  Standard180min = 'Standard180min',
  StatusHolders = 'StatusHolders'
}

export type CourseMaterialInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CourseMaterialType = {
  __typename?: 'CourseMaterialType';
  _id: Scalars['MongoID']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Note: manually inserted DUO declarations can only be external. Internal DUO declarations are automatically synced with Twinfield */
export type DuoDeclarationInputType = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type DuoDeclarationType = {
  __typename?: 'DUODeclarationType';
  _id: Scalars['MongoID']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  finalExam?: Maybe<FinalExamType>;
  finalExamId?: Maybe<Scalars['MongoID']['output']>;
  hasBeenImported?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  isFinalExamDeclaration?: Maybe<Scalars['Boolean']['output']>;
};

export type DuoFinalExamResultErrorType = {
  __typename?: 'DUOFinalExamResultErrorType';
  DUOFinalExamResult?: Maybe<DuoFinalExamResultType>;
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  examAttempt?: Maybe<Scalars['Int']['output']>;
  examDate?: Maybe<Scalars['Date']['output']>;
  examLevel?: Maybe<FinalExamLevelsEnum>;
  examPart?: Maybe<FinalExamPartsEnum>;
  examResult?: Maybe<FinalExamResultsEnum>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<ExamResultImportRowErrorTypesEnum>;
  typeData?: Maybe<DuoFinalExamResultErrorTypeDataType>;
  user?: Maybe<UserType>;
};

export type DuoFinalExamResultErrorTypeDataType = {
  __typename?: 'DUOFinalExamResultErrorTypeDataType';
  bsn?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DuoFinalExamResultFileType = {
  __typename?: 'DUOFinalExamResultFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type DuoFinalExamResultType = {
  __typename?: 'DUOFinalExamResultType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  file?: Maybe<DuoFinalExamResultFileType>;
};

export type DuoInvoiceFileType = {
  __typename?: 'DUOInvoiceFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type DuoInvoiceType = {
  __typename?: 'DUOInvoiceType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  file?: Maybe<DuoInvoiceFileType>;
  fileId?: Maybe<Scalars['MongoID']['output']>;
  lastDownloadedAt?: Maybe<Scalars['Date']['output']>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']['output']>;
};

export type DuoLearnerReportFileType = {
  __typename?: 'DUOLearnerReportFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type DuoLearnerReportType = {
  __typename?: 'DUOLearnerReportType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  file?: Maybe<DuoLearnerReportFileType>;
  fileId?: Maybe<Scalars['MongoID']['output']>;
  lastDownloadedAt?: Maybe<Scalars['Date']['output']>;
  lastDownloadedByUser?: Maybe<UserType>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']['output']>;
};

export type DuoReponseInvoicesNeedActionResultType = {
  __typename?: 'DUOReponseInvoicesNeedActionResultType';
  invoice?: Maybe<InvoiceType>;
  invoiceAmountAccordingToResponse?: Maybe<Scalars['Float']['output']>;
  response?: Maybe<Scalars['String']['output']>;
};

export type DuoResponseFileType = {
  __typename?: 'DUOResponseFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type DuoResponseType = {
  __typename?: 'DUOResponseType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  file?: Maybe<DuoResponseFileType>;
  fileId?: Maybe<Scalars['MongoID']['output']>;
  invoicesNeedActionCount?: Maybe<Scalars['Int']['output']>;
  invoicesNeedActionResults?: Maybe<Array<Maybe<DuoReponseInvoicesNeedActionResultType>>>;
  invoicesProcessedCount?: Maybe<Scalars['Int']['output']>;
};

export type DateRangeInputType = {
  from: Scalars['Date']['input'];
  nulls?: InputMaybe<Array<Scalars['String']['input']>>;
  to: Scalars['Date']['input'];
};

export type DateRangeType = {
  __typename?: 'DateRangeType';
  from: Scalars['Date']['output'];
  to: Scalars['Date']['output'];
};

export type EdisaImportInputType = {
  alternativeStartingPoint?: InputMaybe<Scalars['Int']['input']>;
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EdisaImportResponseType = {
  __typename?: 'EdisaImportResponseType';
  rows?: Maybe<Array<EdisaImportRowResponseType>>;
};

export type EdisaImportRowResponseType = {
  __typename?: 'EdisaImportRowResponseType';
  bsn?: Maybe<Scalars['String']['output']>;
  edisaNumber?: Maybe<Scalars['String']['output']>;
  excelRowNumber?: Maybe<Scalars['Int']['output']>;
  hasFailed?: Maybe<Scalars['Boolean']['output']>;
  isExisting?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['MongoID']['output']>;
};

export type EdisaImportRowType = {
  __typename?: 'EdisaImportRowType';
  edisaNumber?: Maybe<Scalars['String']['output']>;
  excelRowNumber?: Maybe<Scalars['Int']['output']>;
  hasFailed?: Maybe<Scalars['Boolean']['output']>;
  learnerName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['MongoID']['output']>;
};

export type EdisaImportType = {
  __typename?: 'EdisaImportType';
  _id: Scalars['MongoID']['output'];
  importedAt?: Maybe<Scalars['Date']['output']>;
  importedByUser?: Maybe<UserType>;
  lastRow?: Maybe<EdisaImportRowType>;
};

export type EmailBounceType = {
  __typename?: 'EmailBounceType';
  date?: Maybe<Scalars['Date']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
};

export type EmployeeInputType = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ExamAbilityDocumentFileInputType = {
  existingFileIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['UploadedFile']['input']>>>;
};

export type ExamAbilityInputType = {
  documentFiles?: InputMaybe<ExamAbilityDocumentFileInputType>;
  maximumScore?: InputMaybe<Scalars['Float']['input']>;
  minimumScoreToPass?: InputMaybe<Scalars['Float']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeOfRating?: InputMaybe<ExamPartGradingTypeEnum>;
};

export type ExamAbilityType = {
  __typename?: 'ExamAbilityType';
  documentFiles?: Maybe<Array<Maybe<ExamDocumentFileType>>>;
  maximumScore?: Maybe<Scalars['Float']['output']>;
  minimumScoreToPass?: Maybe<Scalars['Float']['output']>;
  typeOfRating?: Maybe<ExamPartGradingTypeEnum>;
};

export enum ExamAbiltiesTypeEnum {
  Converse = 'converse',
  Listen = 'listen',
  Read = 'read',
  Talk = 'talk',
  Write = 'write'
}

export type ExamDocumentFileType = {
  __typename?: 'ExamDocumentFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type ExamInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  converse?: InputMaybe<ExamAbilityInputType>;
  level?: InputMaybe<ExamLevelEnum>;
  listen?: InputMaybe<ExamAbilityInputType>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  read?: InputMaybe<ExamAbilityInputType>;
  talk?: InputMaybe<ExamAbilityInputType>;
  type?: InputMaybe<ExamTypeEnum>;
  write?: InputMaybe<ExamAbilityInputType>;
};

export enum ExamLevelEnum {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  '1F' = '_1F',
  '2F' = '_2F',
  '3F' = '_3F',
  '4F' = '_4F'
}

export enum ExamPartGradingTypeEnum {
  Level = 'level',
  PassedNotPassed = 'passedNotPassed',
  Score = 'score'
}

export enum ExamResultImportRowErrorTypesEnum {
  ExamNotFound = 'ExamNotFound',
  ExternalFinalExamAlreadyExists = 'ExternalFinalExamAlreadyExists',
  UserNotFound = 'UserNotFound'
}

export type ExamType = {
  __typename?: 'ExamType';
  _id: Scalars['MongoID']['output'];
  converse?: Maybe<ExamAbilityType>;
  level?: Maybe<ExamLevelEnum>;
  listen?: Maybe<ExamAbilityType>;
  name?: Maybe<Scalars['String']['output']>;
  plannedAbilityExams?: Maybe<Array<Maybe<PlannedAbilityExamType>>>;
  read?: Maybe<ExamAbilityType>;
  talk?: Maybe<ExamAbilityType>;
  type?: Maybe<ExamTypeEnum>;
  write?: Maybe<ExamAbilityType>;
};


export type ExamTypePlannedAbilityExamsArgs = {
  filterByGroupId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum ExamTypeEnum {
  Alpha = 'alpha',
  Language = 'language'
}

export type ExplainFinalExamScoreType = {
  __typename?: 'ExplainFinalExamScoreType';
  budget?: Maybe<Scalars['Int']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  module?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
};

export type ExportFileType = {
  __typename?: 'ExportFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type ExportLastDatesType = {
  __typename?: 'ExportLastDatesType';
  DUOBOWEnrollmentContracts?: Maybe<Scalars['Date']['output']>;
  DUOLearnerReport?: Maybe<Scalars['Date']['output']>;
  DUOParticipatedHours?: Maybe<Scalars['Date']['output']>;
  EnrollmentCitizenship?: Maybe<Scalars['Date']['output']>;
  FinalexamAttempt?: Maybe<Scalars['Date']['output']>;
  OutflowCitizenship?: Maybe<Scalars['Date']['output']>;
  TeachersCitizenship?: Maybe<Scalars['Date']['output']>;
};

export type ExportResponseType = {
  __typename?: 'ExportResponseType';
  _id: Scalars['MongoID']['output'];
  fileId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['String']['output']>;
};

export type ExportType = {
  __typename?: 'ExportType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  file?: Maybe<ExportFileType>;
  fileId?: Maybe<Scalars['MongoID']['output']>;
  lastDownloadedAt?: Maybe<Scalars['Date']['output']>;
  lastDownloadedByUser?: Maybe<UserType>;
  lastDownloadedByUserId?: Maybe<Scalars['MongoID']['output']>;
  type?: Maybe<ExportTypeEnum>;
};

export enum ExportTypeEnum {
  DuobowContractEnrollment = 'DUOBOWContractEnrollment',
  DuoLearnerReport = 'DUOLearnerReport',
  DuoParticipatedHours = 'DUOParticipatedHours',
  EnrollmentCitizenship = 'EnrollmentCitizenship',
  FinalexamAttempt = 'FinalexamAttempt',
  OutflowCitizenship = 'OutflowCitizenship',
  TeachersCitizenship = 'TeachersCitizenship'
}

export type FileSessionInputType = {
  fileId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FileSessionType = {
  __typename?: 'FileSessionType';
  _id: Scalars['MongoID']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type FilesInputType = {
  existingFileIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  uploadedFiles?: InputMaybe<Array<InputMaybe<Scalars['UploadedFile']['input']>>>;
};

export type FinalExamAppointmentDocumentType = {
  __typename?: 'FinalExamAppointmentDocumentType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
};

export type FinalExamAttemptsInputType = {
  knm?: InputMaybe<Scalars['Int']['input']>;
  listen?: InputMaybe<Scalars['Int']['input']>;
  ona?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Int']['input']>;
  talk?: InputMaybe<Scalars['Int']['input']>;
  write?: InputMaybe<Scalars['Int']['input']>;
};

export enum FinalExamCombinedPartsAndLevelsEnum {
  Knm = 'knm',
  ListenA2 = 'listenA2',
  ListenB1 = 'listenB1',
  ListenB2 = 'listenB2',
  Ona = 'ona',
  ReadA2 = 'readA2',
  ReadB1 = 'readB1',
  ReadB2 = 'readB2',
  TalkA2 = 'talkA2',
  TalkB1 = 'talkB1',
  TalkB2 = 'talkB2',
  WriteA2 = 'writeA2',
  WriteB1 = 'writeB1',
  WriteB2 = 'writeB2'
}

export enum FinalExamEditTypesEnum {
  DateChanged = 'dateChanged',
  DateCorrected = 'dateCorrected',
  ExamCancelled = 'examCancelled',
  ExamPartCorrected = 'examPartCorrected',
  RemoveFinalExam = 'removeFinalExam',
  SubmitterChangedToExternal = 'submitterChangedToExternal',
  SubmitterChangedToToptaal = 'submitterChangedToToptaal'
}

export type FinalExamInputType = {
  attempt?: InputMaybe<Scalars['Int']['input']>;
  attempts?: InputMaybe<FinalExamAttemptsInputType>;
  date?: InputMaybe<Scalars['Date']['input']>;
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  finalExamParts?: InputMaybe<Array<InputMaybe<FinalExamPartInputType>>>;
  invoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  level?: InputMaybe<FinalExamLevelsEnum>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  part?: InputMaybe<FinalExamPartsEnum>;
  result?: InputMaybe<FinalExamResultsEnum>;
  submitter?: InputMaybe<FinalExamSubmittersEnum>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type FinalExamInsertReturnType = {
  __typename?: 'FinalExamInsertReturnType';
  noEmailKnown?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum FinalExamLevelsEnum {
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2'
}

export type FinalExamLogType = {
  __typename?: 'FinalExamLogType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  type?: Maybe<FinalExamLogTypeEnum>;
  typeData?: Maybe<FinalExamLogTypeDataType>;
};

export type FinalExamLogTypeDataType = {
  __typename?: 'FinalExamLogTypeDataType';
  duoDeclaration?: Maybe<DuoDeclarationType>;
  duoDeclarationId?: Maybe<Scalars['MongoID']['output']>;
  duoFinalExamResult?: Maybe<DuoFinalExamResultType>;
  duoFinalExamResultId?: Maybe<Scalars['MongoID']['output']>;
  invoice?: Maybe<InvoiceType>;
  invoiceId?: Maybe<Scalars['MongoID']['output']>;
  previousDate?: Maybe<Scalars['Date']['output']>;
  previousExamPart?: Maybe<FinalExamPartsEnum>;
};

export enum FinalExamLogTypeEnum {
  CreatedAsExemptByDuoFinalExamResultImport = 'createdAsExemptByDUOFinalExamResultImport',
  CreatedByDuoFinalExamResultImport = 'createdByDUOFinalExamResultImport',
  DateChanged = 'dateChanged',
  DateCorrected = 'dateCorrected',
  DuoDeclarationCreated = 'duoDeclarationCreated',
  DuoDeclarationRevoked = 'duoDeclarationRevoked',
  ExamCancelled = 'examCancelled',
  ExamCreated = 'examCreated',
  ExamPartCorrected = 'examPartCorrected',
  InvoiceCreated = 'invoiceCreated',
  InvoiceCredited = 'invoiceCredited',
  InvoiceSent = 'invoiceSent',
  ResultUpdatedFromDuoFinalExamResultImport = 'resultUpdatedFromDUOFinalExamResultImport',
  SubmitterChangedToExternal = 'submitterChangedToExternal',
  SubmitterChangedToToptaal = 'submitterChangedToToptaal',
  UncanceledByDuoFinalExamResultImport = 'uncanceledByDUOFinalExamResultImport'
}

export type FinalExamNeededType = {
  __typename?: 'FinalExamNeededType';
  budgetRemaining?: Maybe<Scalars['Float']['output']>;
  endOfIntegrationTerm?: Maybe<Scalars['Date']['output']>;
  explainScore?: Maybe<ExplainFinalExamScoreType>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  hoursPresent?: Maybe<Scalars['Int']['output']>;
  module?: Maybe<ModuleType>;
};


export type FinalExamNeededTypeFinalExamsArgs = {
  filters?: InputMaybe<FinalExamsFilterInputType>;
};

export type FinalExamPartInputType = {
  date?: InputMaybe<Scalars['Date']['input']>;
  partAndLevel?: InputMaybe<FinalExamCombinedPartsAndLevelsEnum>;
};

export enum FinalExamPartsEnum {
  Knm = 'knm',
  Listen = 'listen',
  Ona = 'ona',
  Read = 'read',
  Talk = 'talk',
  Write = 'write'
}

export enum FinalExamResultsEnum {
  Exempt = 'exempt',
  Failed = 'failed',
  NoShow = 'no_show',
  Passed = 'passed'
}

export enum FinalExamSubmittersEnum {
  External = 'external',
  Toptaal = 'toptaal'
}

export type FinalExamType = {
  __typename?: 'FinalExamType';
  _id: Scalars['MongoID']['output'];
  appointmentFile?: Maybe<FinalExamAppointmentDocumentType>;
  attempt?: Maybe<Scalars['Int']['output']>;
  canceledAt?: Maybe<Scalars['Date']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  createdByUserId?: Maybe<Scalars['MongoID']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  invoice?: Maybe<InvoiceType>;
  isInvoiceActivatable?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<FinalExamLevelsEnum>;
  logs?: Maybe<Array<Maybe<FinalExamLogType>>>;
  nextAttempt?: Maybe<FinalExamType>;
  part?: Maybe<FinalExamPartsEnum>;
  result?: Maybe<FinalExamResultsEnum>;
  submitter?: Maybe<FinalExamSubmittersEnum>;
  user?: Maybe<UserType>;
  userId?: Maybe<Scalars['MongoID']['output']>;
};

export type FinalExamsFilterInputType = {
  byUserId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByExamIsNotCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  filterByExameDateFrom?: InputMaybe<Scalars['Date']['input']>;
  filterByExameDateTo?: InputMaybe<Scalars['Date']['input']>;
};

export type FinalExamsNeededFilterInputType = {
  filterByEndTerm?: InputMaybe<Scalars['Int']['input']>;
  filterByGroupEmployeeId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByOrganizationContactId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByOrganizationId?: InputMaybe<Scalars['MongoID']['input']>;
  searchByName?: InputMaybe<Scalars['String']['input']>;
};

export type FullDutchLocation = {
  __typename?: 'FullDutchLocation';
  city?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  numberAddition?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type GroupDocumentInputType = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupDocumentType = {
  __typename?: 'GroupDocumentType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
};

export type GroupInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  dateBlockers?: InputMaybe<Array<DateRangeInputType>>;
  generalWeekLessonDates?: InputMaybe<Array<Scalars['Date']['input']>>;
  generalWeekLessonRoomIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  generalWeekLessonTeacherUserIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  localizedExtraLearnerInformation?: InputMaybe<LocalizedExtraLearnerInformationInputType>;
  locationId?: InputMaybe<Scalars['MongoID']['input']>;
  moduleId?: InputMaybe<Scalars['MongoID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programId?: InputMaybe<Scalars['MongoID']['input']>;
  projectId?: InputMaybe<Scalars['MongoID']['input']>;
  roomIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  startDateIndex?: InputMaybe<Scalars['Int']['input']>;
  startWeekDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GroupInviteLogType = {
  __typename?: 'GroupInviteLogType';
  _id: Scalars['MongoID']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  groupInviteTemplate?: Maybe<GroupInviteTemplateType>;
  invitedByUser?: Maybe<UserType>;
  type?: Maybe<InflowMomentInviteType>;
  user?: Maybe<UserType>;
};

export type GroupInviteTemnplateInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<GroupInviteTemplateFileInputType>>>;
  en?: InputMaybe<GroupInviteTemplateTranslatableFieldsInputType>;
  moduleId?: InputMaybe<Scalars['MongoID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nl?: InputMaybe<GroupInviteTemplateTranslatableFieldsInputType>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupInviteTemplateAttachmentType = {
  __typename?: 'GroupInviteTemplateAttachmentType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type GroupInviteTemplateFileInputType = {
  existingFileIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  uploadedFiles?: InputMaybe<Array<InputMaybe<Scalars['UploadedFile']['input']>>>;
};

export type GroupInviteTemplateTranslatableFieldType = {
  __typename?: 'GroupInviteTemplateTranslatableFieldType';
  content?: Maybe<Scalars['String']['output']>;
};

export type GroupInviteTemplateTranslatableFieldsInputType = {
  content?: InputMaybe<Scalars['String']['input']>;
};

export type GroupInviteTemplateType = {
  __typename?: 'GroupInviteTemplateType';
  _id: Scalars['MongoID']['output'];
  attachments?: Maybe<Array<Maybe<GroupInviteTemplateAttachmentType>>>;
  en?: Maybe<GroupInviteTemplateTranslatableFieldType>;
  module?: Maybe<ModuleType>;
  name?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<GroupInviteTemplateTranslatableFieldType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type GroupTimetableLessonType = {
  __typename?: 'GroupTimetableLessonType';
  generalWeekLessonDateIndex?: Maybe<Scalars['Int']['output']>;
  lesson?: Maybe<LessonType>;
  weekIndex?: Maybe<Scalars['Int']['output']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  _id: Scalars['MongoID']['output'];
  amountOfUnsentLessonInvites?: Maybe<Scalars['Int']['output']>;
  canBeDeleted?: Maybe<Scalars['Boolean']['output']>;
  dateBlockers?: Maybe<Array<Maybe<DateRangeType>>>;
  dateFrom?: Maybe<Scalars['Date']['output']>;
  dateTo?: Maybe<Scalars['Date']['output']>;
  documents?: Maybe<Array<Maybe<GroupDocumentType>>>;
  employeeUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  endedAt?: Maybe<Scalars['Date']['output']>;
  generalWeekLessonDates?: Maybe<Array<Maybe<Scalars['Date']['output']>>>;
  generalWeekLessonRooms?: Maybe<Array<Maybe<RoomType>>>;
  generalWeekLessonTeacherUsers?: Maybe<Array<Maybe<UserType>>>;
  hasExamWhichRequiresAction?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsThatRequireSignatureOnContract?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsThatRequireSignatureOnInvoice?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsWithExpiredInvoices?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsWithInactiveInvoices?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsWithInvoicesThatAreDeniedByDUO?: Maybe<Scalars['Boolean']['output']>;
  hasParticipantsWithInvoicingDisabled?: Maybe<Scalars['Boolean']['output']>;
  isConcept?: Maybe<Scalars['Boolean']['output']>;
  isEndAllowed?: Maybe<Scalars['Boolean']['output']>;
  isLegacyGroup?: Maybe<Scalars['Boolean']['output']>;
  latestInvites?: Maybe<Array<Maybe<GroupInviteLogType>>>;
  learnerUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  learnerUsersCount?: Maybe<Scalars['Int']['output']>;
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  localizedExtraLearnerInformation?: Maybe<LocalizedExtraLearnerInformationType>;
  location?: Maybe<LocationType>;
  module?: Maybe<ModuleType>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<Maybe<NoteType>>>;
  notesCount?: Maybe<Scalars['Int']['output']>;
  program?: Maybe<ProgramType>;
  project?: Maybe<ProjectType>;
  rooms?: Maybe<Array<Maybe<RoomType>>>;
  startDateIndex?: Maybe<Scalars['Int']['output']>;
  startWeekDate?: Maybe<Scalars['Date']['output']>;
  teacherUsers?: Maybe<Array<Maybe<GroupUserType>>>;
  timetableLessons?: Maybe<Array<Maybe<GroupTimetableLessonType>>>;
};


export type GroupTypeDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type GroupTypeEmployeeUsersArgs = {
  filterByUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterRemoved?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GroupTypeLearnerUsersArgs = {
  filterByUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterRemoved?: InputMaybe<Scalars['Boolean']['input']>;
};


export type GroupTypeLearnerUsersCountArgs = {
  filterByUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
};


export type GroupTypeLessonsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByIsAvailableToPlanExamId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByTeacherId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextLessonForTeacherUserId?: InputMaybe<Scalars['MongoID']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupTypeTeacherUsersArgs = {
  filterByUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterRemoved?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupUserDisenrollmentFollowupActionEnum {
  ChangeLearnerUserAdviceModule = 'ChangeLearnerUserAdviceModule',
  DeactiveLearnerUser = 'DeactiveLearnerUser',
  NoAction = 'NoAction'
}

export type GroupUserDisenrollmentNewAdviceModuleInputType = {
  moduleId?: InputMaybe<Scalars['MongoID']['input']>;
  programId?: InputMaybe<Scalars['MongoID']['input']>;
  projectId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type GroupUserEnrollmentType = {
  __typename?: 'GroupUserEnrollmentType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']['output']>;
  removedInfo?: Maybe<Scalars['String']['output']>;
  removedReason?: Maybe<GroupUserRemovedReasonEnum>;
  role?: Maybe<GroupUserRoleEnum>;
  type?: Maybe<GroupUserEnrollmentTypeEnum>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedByUser?: Maybe<UserType>;
};

export enum GroupUserEnrollmentTypeEnum {
  Disenrollment = 'Disenrollment',
  Enrollment = 'Enrollment'
}

export enum GroupUserRemovedReasonEnum {
  AlmostSuccessfull = 'AlmostSuccessfull',
  EarlyDisenrollmentFoundWork = 'EarlyDisenrollmentFoundWork',
  EarlyDisenrollmentLeavingEmployer = 'EarlyDisenrollmentLeavingEmployer',
  EarlyDisenrollmentLevelUnsuitable = 'EarlyDisenrollmentLevelUnsuitable',
  EarlyDisenrollmentMedical = 'EarlyDisenrollmentMedical',
  EarlyDisenrollmentMotivation = 'EarlyDisenrollmentMotivation',
  EarlyDisenrollmentMovedHouse = 'EarlyDisenrollmentMovedHouse',
  EarlyDisenrollmentNotSatisfiedWithTopTaal = 'EarlyDisenrollmentNotSatisfiedWithTopTaal',
  EarlyDisenrollmentOtherPersonalReasons = 'EarlyDisenrollmentOtherPersonalReasons',
  EarlyDisenrollmentUnavailable = 'EarlyDisenrollmentUnavailable',
  EarlyDisenrollmentUnreachable = 'EarlyDisenrollmentUnreachable',
  EarlyDisenrollmentWentToOtherSchool = 'EarlyDisenrollmentWentToOtherSchool',
  ExemptionRoute = 'ExemptionRoute',
  FinishedSuccesful = 'FinishedSuccesful',
  FinishedUnsuccesful = 'FinishedUnsuccesful',
  OnHold = 'OnHold',
  Other = 'Other',
  ReturnBeforeStart = 'ReturnBeforeStart'
}

export enum GroupUserRoleEnum {
  Employee = 'EMPLOYEE',
  Learner = 'LEARNER',
  Teacher = 'TEACHER'
}

export type GroupUserType = {
  __typename?: 'GroupUserType';
  _id: Scalars['MongoID']['output'];
  enrollments?: Maybe<Array<Maybe<GroupUserEnrollmentType>>>;
  hasAttendances?: Maybe<Scalars['Boolean']['output']>;
  hasAttendancesAfterLastEnrollment?: Maybe<Scalars['Boolean']['output']>;
  hasFinishedGroup?: Maybe<Scalars['Boolean']['output']>;
  hasInvoicesAfterLastEnrollment?: Maybe<Scalars['Boolean']['output']>;
  hasLearnerFinalEnrollment?: Maybe<Scalars['Boolean']['output']>;
  hasLearnerFutureEnrollment?: Maybe<Scalars['Boolean']['output']>;
  hasSignedContractForEveryLessonInGroup?: Maybe<Scalars['Boolean']['output']>;
  isGroupParticipationInvoiceActivatable?: Maybe<Scalars['Boolean']['output']>;
  isNowEnrolled?: Maybe<Scalars['Boolean']['output']>;
  learnerAttendanceRate?: Maybe<Scalars['Float']['output']>;
  lessonInviteEmailSentAt?: Maybe<GroupInviteLogType>;
  lessonInvitePdfDownloadedAt?: Maybe<GroupInviteLogType>;
  role?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};


export type GroupUserTypeHasAttendancesArgs = {
  afterDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GroupsFilterInputType = {
  filterByConcepts?: InputMaybe<Scalars['Boolean']['input']>;
  filterByEmployeeUserIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByEndDateFrom?: InputMaybe<Scalars['Date']['input']>;
  filterByEndDateTo?: InputMaybe<Scalars['Date']['input']>;
  filterByHasInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  filterById?: InputMaybe<Scalars['MongoID']['input']>;
  filterByIsEnded?: InputMaybe<Scalars['Boolean']['input']>;
  filterByIsStarted?: InputMaybe<Scalars['Boolean']['input']>;
  filterByLearnerOrganizationIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByLocationIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByModuleIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByProgramIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByProjectIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByStartDateFrom?: InputMaybe<Scalars['Date']['input']>;
  filterByStartDateTo?: InputMaybe<Scalars['Date']['input']>;
  filterByTeacherUserIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByTextSearch?: InputMaybe<Scalars['String']['input']>;
};

export type InflowInviteTemnplateInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<InflowInviteTemplateFileInputType>>>;
  en?: InputMaybe<InflowInviteTemplateTranslatableFieldsInputType>;
  inflowModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nl?: InputMaybe<InflowInviteTemplateTranslatableFieldsInputType>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InflowInviteTemplateAttachmentType = {
  __typename?: 'InflowInviteTemplateAttachmentType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type InflowInviteTemplateFileInputType = {
  existingFileIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  uploadedFiles?: InputMaybe<Array<InputMaybe<Scalars['UploadedFile']['input']>>>;
};

export type InflowInviteTemplateTranslatableFieldType = {
  __typename?: 'InflowInviteTemplateTranslatableFieldType';
  content?: Maybe<Scalars['String']['output']>;
};

export type InflowInviteTemplateTranslatableFieldsInputType = {
  content?: InputMaybe<Scalars['String']['input']>;
};

export type InflowInviteTemplateType = {
  __typename?: 'InflowInviteTemplateType';
  _id: Scalars['MongoID']['output'];
  attachments?: Maybe<Array<Maybe<InflowInviteTemplateAttachmentType>>>;
  en?: Maybe<InflowInviteTemplateTranslatableFieldType>;
  inflowModule?: Maybe<InflowModuleType>;
  name?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<InflowInviteTemplateTranslatableFieldType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type InflowLevelsInputType = {
  levelConversations?: InputMaybe<LearnerLevelEnum>;
  levelListening?: InputMaybe<LearnerLevelEnum>;
  levelReading?: InputMaybe<LearnerLevelWithAlphaEnum>;
  levelTalking?: InputMaybe<LearnerLevelEnum>;
  levelWriting?: InputMaybe<LearnerLevelWithAlphaEnum>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InflowLevelsType = {
  __typename?: 'InflowLevelsType';
  levelConversations?: Maybe<Scalars['String']['output']>;
  levelListening?: Maybe<Scalars['String']['output']>;
  levelReading?: Maybe<Scalars['String']['output']>;
  levelTalking?: Maybe<Scalars['String']['output']>;
  levelWriting?: Maybe<Scalars['String']['output']>;
};

export type InflowModuleExamAbilitiesInputType = {
  converse?: InputMaybe<Scalars['Boolean']['input']>;
  listen?: InputMaybe<Scalars['Boolean']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  talk?: InputMaybe<Scalars['Boolean']['input']>;
  write?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InflowModuleExamAbilitiesType = {
  __typename?: 'InflowModuleExamAbilitiesType';
  converse?: Maybe<Scalars['Boolean']['output']>;
  listen?: Maybe<Scalars['Boolean']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  talk?: Maybe<Scalars['Boolean']['output']>;
  write?: Maybe<Scalars['Boolean']['output']>;
};

export type InflowModuleExamInputType = {
  abilities?: InputMaybe<InflowModuleExamAbilitiesInputType>;
  examId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InflowModuleExamType = {
  __typename?: 'InflowModuleExamType';
  abilities?: Maybe<InflowModuleExamAbilitiesType>;
  exam?: Maybe<ExamType>;
  examId?: Maybe<Scalars['MongoID']['output']>;
};

export type InflowModuleInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  checkAdviceReportNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  examMaxTimeMinutes?: InputMaybe<Scalars['Int']['input']>;
  examStartIntervalMinutes?: InputMaybe<Scalars['Int']['input']>;
  exams?: InputMaybe<Array<InflowModuleExamInputType>>;
  maxStudentsPerStart?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<InflowModuleTypeEnum>;
};

export type InflowModuleType = {
  __typename?: 'InflowModuleType';
  _id: Scalars['MongoID']['output'];
  checkAdviceReportNeeded?: Maybe<Scalars['Boolean']['output']>;
  examMaxTimeMinutes?: Maybe<Scalars['Int']['output']>;
  examStartIntervalMinutes?: Maybe<Scalars['Int']['output']>;
  exams?: Maybe<Array<Maybe<InflowModuleExamType>>>;
  isLockedForDelete?: Maybe<Scalars['Boolean']['output']>;
  isLockedForEdit?: Maybe<Scalars['Boolean']['output']>;
  maxStudentsPerStart?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<InflowModuleTypeEnum>;
};

export enum InflowModuleTypeEnum {
  Intake = 'intake',
  LevelDetermination = 'levelDetermination'
}

export type InflowMomentDateRangeInputType = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type InflowMomentDateRangeType = {
  __typename?: 'InflowMomentDateRangeType';
  from?: Maybe<Scalars['Date']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type InflowMomentFilterInputType = {
  filterByConcepts?: InputMaybe<Scalars['Boolean']['input']>;
  filterByCreatedBy?: InputMaybe<Scalars['MongoID']['input']>;
  filterByDateFrom?: InputMaybe<Scalars['Date']['input']>;
  filterByDateTo?: InputMaybe<Scalars['Date']['input']>;
  filterById?: InputMaybe<Scalars['MongoID']['input']>;
  filterByInflowModule?: InputMaybe<Scalars['MongoID']['input']>;
  filterByLocation?: InputMaybe<Scalars['MongoID']['input']>;
  filterByNoOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  filterByOrganization?: InputMaybe<Scalars['MongoID']['input']>;
  filterByOrganizations?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterByOrganizationsAndIncludeNulls?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  filterBySlotsAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InflowMomentInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  dateRange?: InputMaybe<InflowMomentDateRangeInputType>;
  employeeUserIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  inflowModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  intakerUserIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  isConcept?: InputMaybe<Scalars['Boolean']['input']>;
  isForOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['MongoID']['input']>;
  locationRoomsConversationIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  locationRoomsGeneralIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  locationRoomsOrganized?: InputMaybe<Scalars['Boolean']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type InflowMomentInviteInvitesInputType = {
  type?: InputMaybe<InflowMomentInviteType>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type InflowMomentInviteLogType = {
  __typename?: 'InflowMomentInviteLogType';
  _id: Scalars['MongoID']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  inflowInviteTemplate?: Maybe<InflowInviteTemplateType>;
  invitedByUser?: Maybe<UserType>;
  type?: Maybe<InflowMomentInviteType>;
  user?: Maybe<UserType>;
};

export enum InflowMomentInviteType {
  Email = 'Email',
  Manual = 'Manual'
}

export type InflowMomentProjectedTimeslotSlotType = {
  __typename?: 'InflowMomentProjectedTimeslotSlotType';
  duration?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['Date']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['Date']['output']>;
};

export type InflowMomentProjectedTimeslotType = {
  __typename?: 'InflowMomentProjectedTimeslotType';
  timeslots?: Maybe<Array<Maybe<InflowMomentProjectedTimeslotSlotType>>>;
  totalPlaces?: Maybe<Scalars['Int']['output']>;
};

export type InflowMomentTimeslotInputType = {
  inflowMomentTimeslotId?: InputMaybe<Scalars['MongoID']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type InflowMomentTimeslotType = {
  __typename?: 'InflowMomentTimeslotType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['Date']['output']>;
  inflowMoment?: Maybe<InflowMomentType>;
  maxUsers?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['Date']['output']>;
  timeslotUsers?: Maybe<Array<Maybe<InflowMomentTimeslotUserType>>>;
};

export enum InflowMomentTimeslotUserStatusEnum {
  NoShow = 'NoShow',
  Present = 'Present'
}

export type InflowMomentTimeslotUserType = {
  __typename?: 'InflowMomentTimeslotUserType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  status?: Maybe<InflowMomentTimeslotUserStatusEnum>;
  user?: Maybe<UserType>;
};

export type InflowMomentType = {
  __typename?: 'InflowMomentType';
  _id: Scalars['MongoID']['output'];
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  amountOfSlotsPerTimeslot?: Maybe<Scalars['Int']['output']>;
  canHaveNewTimeslots?: Maybe<Scalars['Boolean']['output']>;
  candidateCountTotal?: Maybe<Scalars['Int']['output']>;
  candidateCountUsed?: Maybe<Scalars['Int']['output']>;
  candidates?: Maybe<Array<Maybe<UserType>>>;
  candidatesInviteNeededCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  dateRange?: Maybe<InflowMomentDateRangeType>;
  employeeUsers?: Maybe<Array<Maybe<UserType>>>;
  hasStarted?: Maybe<Scalars['Boolean']['output']>;
  hasUnreleasedAdviceReports?: Maybe<Scalars['Boolean']['output']>;
  inflowModule?: Maybe<InflowModuleType>;
  intakerUsers?: Maybe<Array<Maybe<UserType>>>;
  isConcept?: Maybe<Scalars['Boolean']['output']>;
  isForOrganization?: Maybe<Scalars['Boolean']['output']>;
  latestInvites?: Maybe<Array<Maybe<InflowMomentInviteLogType>>>;
  location?: Maybe<LocationType>;
  locationRoomsConversation?: Maybe<Array<Maybe<RoomType>>>;
  locationRoomsGeneral?: Maybe<Array<Maybe<RoomType>>>;
  locationRoomsOrganized?: Maybe<Scalars['Boolean']['output']>;
  minimumTeachersNeeded?: Maybe<Scalars['Int']['output']>;
  organization?: Maybe<OrganizationType>;
  timeslots?: Maybe<Array<Maybe<InflowMomentTimeslotType>>>;
  timeslotsProjected?: Maybe<InflowMomentProjectedTimeslotType>;
};


export type InflowMomentTypeAdviceReportsArgs = {
  filterByLearnerUserId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type InfoType = {
  __typename?: 'InfoType';
  isTwinfieldTokenSet?: Maybe<Scalars['Boolean']['output']>;
};

export type InstructionVideoInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetRoles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InstructionVideoType = {
  __typename?: 'InstructionVideoType';
  _id: Scalars['MongoID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  targetRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum IntegrationTypeEnum {
  Alfa = 'Alfa',
  Ona = 'ONA',
  Standard = 'Standard',
  StateExam = 'StateExam'
}

export enum InvoiceDescriptionEnum {
  LessonMaterials = 'lessonMaterials'
}

export enum InvoiceFilterStatusEnum {
  CreditStatusCanceled = 'CreditStatusCanceled',
  DebitStatusCanceled = 'DebitStatusCanceled',
  OpenStatusNeedsSignatureForDuo = 'OpenStatusNeedsSignatureForDUO',
  OpenStatusRejectedByDuo = 'OpenStatusRejectedByDUO',
  OpenStatusWaitingForDuoApproval = 'OpenStatusWaitingForDUOApproval',
  OpenStatusWaitingForDuoExportBatch = 'OpenStatusWaitingForDUOExportBatch',
  OpenStatusWaitingForPayment = 'OpenStatusWaitingForPayment',
  PaymentStatusPaid = 'PaymentStatusPaid',
  StatusDraft = 'StatusDraft'
}

export type InvoiceInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** This field is required when the alternative amount is specified. */
  alternativeAmountDescription?: InputMaybe<Scalars['String']['input']>;
  /** This field is used to specify an alternative amount. */
  amount?: InputMaybe<Scalars['Float']['input']>;
  courseMaterialIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  creditDescription?: InputMaybe<Scalars['String']['input']>;
  debitInvoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  description?: InputMaybe<InvoiceDescriptionEnum>;
  finalExamId?: InputMaybe<Scalars['MongoID']['input']>;
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  installments?: InputMaybe<Scalars['Int']['input']>;
  isCredit?: InputMaybe<Scalars['Boolean']['input']>;
  isFinalExamInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isGroupParticipationInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  lessonIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type InvoiceLogType = {
  __typename?: 'InvoiceLogType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  triggeredByUser?: Maybe<UserType>;
  type?: Maybe<InvoiceLogTypeEnum>;
  typeData?: Maybe<InvoiceLogTypeDataType>;
};

export type InvoiceLogTypeDataNewOpenInvoiceStatusInfoType = {
  __typename?: 'InvoiceLogTypeDataNewOpenInvoiceStatusInfoType';
  responseByDUO?: Maybe<Scalars['String']['output']>;
};

export type InvoiceLogTypeDataType = {
  __typename?: 'InvoiceLogTypeDataType';
  newAmount?: Maybe<Scalars['Float']['output']>;
  newInvoicePaymentStatus?: Maybe<InvoicePaymentStatusEnum>;
  newInvoiceStatus?: Maybe<InvoiceStatusEnum>;
  newOpenInvoiceStatus?: Maybe<OpenInvoiceStatusEnum>;
  newOpenInvoiceStatusInfo?: Maybe<InvoiceLogTypeDataNewOpenInvoiceStatusInfoType>;
  previousAmount?: Maybe<Scalars['Float']['output']>;
  reminderCount?: Maybe<Scalars['Int']['output']>;
};

export enum InvoiceLogTypeEnum {
  ChangeInvoice = 'changeInvoice',
  ChangeInvoicePaymentStatus = 'changeInvoicePaymentStatus',
  ChangeInvoiceStatus = 'changeInvoiceStatus',
  ChangeOpenInvoiceStatus = 'changeOpenInvoiceStatus',
  CreateCreditInvoice = 'createCreditInvoice',
  CreateInvoice = 'createInvoice',
  MarkInvoiceAsFullyCredited = 'markInvoiceAsFullyCredited',
  MarkInvoiceAsPartlyCredited = 'markInvoiceAsPartlyCredited',
  SendInvoice = 'sendInvoice',
  SendInvoiceReminder = 'sendInvoiceReminder',
  SignatureAddedToInvoice = 'signatureAddedToInvoice',
  SignatureChangedForInvoice = 'signatureChangedForInvoice'
}

export enum InvoicePaymentStatusEnum {
  Open = 'Open',
  Paid = 'Paid'
}

export enum InvoicePrivatePaymentTypeEnum {
  Duo = 'duo',
  Self = 'self'
}

export enum InvoiceStatusEnum {
  Canceled = 'Canceled',
  Draft = 'Draft',
  Final = 'Final'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  _id: Scalars['MongoID']['output'];
  alternativeAmountDescription?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  conceptCreditedAmount?: Maybe<Scalars['Float']['output']>;
  contract?: Maybe<ContractType>;
  courseMaterials?: Maybe<Array<Maybe<Scalars['MongoID']['output']>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  creditDescription?: Maybe<Scalars['String']['output']>;
  creditableAmountPerLesson?: Maybe<Scalars['Float']['output']>;
  creditedAmount?: Maybe<Scalars['Float']['output']>;
  daysExpired: Scalars['Int']['output'];
  debitInvoice?: Maybe<InvoiceType>;
  descendantInstallmentInvoiceHasFailedToSend?: Maybe<Scalars['Boolean']['output']>;
  descendantInstallmentInvoices?: Maybe<Array<InvoiceType>>;
  description?: Maybe<InvoiceDescriptionEnum>;
  emailedAt?: Maybe<Scalars['Date']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  finalExam?: Maybe<FinalExamType>;
  group?: Maybe<GroupType>;
  hasMultipleInstallments: Scalars['Boolean']['output'];
  hasSignatureForDUO: Scalars['Boolean']['output'];
  installmentIndex?: Maybe<Scalars['Int']['output']>;
  installmentLeadingInvoice?: Maybe<InvoiceType>;
  installments?: Maybe<Scalars['Int']['output']>;
  installmentsSubTotal?: Maybe<Scalars['Float']['output']>;
  /** Get a sum of the amounts across all eventual installments */
  installmentsTotal?: Maybe<Scalars['Float']['output']>;
  invoiceDate?: Maybe<Scalars['Date']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoiceSentAt?: Maybe<Scalars['Date']['output']>;
  invoicedLessonUsers?: Maybe<Array<Maybe<InvoicedLessonUserType>>>;
  isCredit: Scalars['Boolean']['output'];
  isDescendantInstallment: Scalars['Boolean']['output'];
  isDownloadable: Scalars['Boolean']['output'];
  isEmailRequiredToSend: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  isFinalExamInvoice: Scalars['Boolean']['output'];
  isForDUO: Scalars['Boolean']['output'];
  isFullyCredited: Scalars['Boolean']['output'];
  isGroupParticipationInvoice: Scalars['Boolean']['output'];
  lessons?: Maybe<Array<Maybe<LessonType>>>;
  logs?: Maybe<Array<Maybe<InvoiceLogType>>>;
  mayBeCanceled: Scalars['Boolean']['output'];
  mayBeCredited: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  openStatus?: Maybe<OpenInvoiceStatusEnum>;
  openStatusChangedAt: Scalars['Date']['output'];
  paymentStatus?: Maybe<InvoicePaymentStatusEnum>;
  paymentStatusChangedAt: Scalars['Date']['output'];
  remainingConceptCreditableAmount?: Maybe<Scalars['Float']['output']>;
  signatureForDuoFile?: Maybe<SignatureFileType>;
  status: InvoiceStatusEnum;
  statusChangedAt: Scalars['Date']['output'];
  user?: Maybe<UserType>;
};

export enum InvoiceTypeEnum {
  FinalExam = 'FinalExam',
  GroupParticipation = 'GroupParticipation',
  LessonMaterials = 'LessonMaterials'
}

export type InvoicedLessonUserType = {
  __typename?: 'InvoicedLessonUserType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  group?: Maybe<GroupType>;
  lastDebitInvoiceIdForCreditableLessonUser?: Maybe<Scalars['MongoID']['output']>;
  lesson?: Maybe<LessonType>;
  lessonDate?: Maybe<Scalars['Date']['output']>;
  lessonId?: Maybe<Scalars['MongoID']['output']>;
  user?: Maybe<UserType>;
};

export type InvoicesFilterInputType = {
  byCredit?: InputMaybe<Scalars['Boolean']['input']>;
  byExpiredAtDateFrom?: InputMaybe<Scalars['Date']['input']>;
  byExpiredAtDateTo?: InputMaybe<Scalars['Date']['input']>;
  byFilterStatus?: InputMaybe<InvoiceFilterStatusEnum>;
  byGroupIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  byIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  byInvoiceDateFrom?: InputMaybe<Scalars['Date']['input']>;
  byInvoiceDateTo?: InputMaybe<Scalars['Date']['input']>;
  byInvoiceSentAtFrom?: InputMaybe<Scalars['Date']['input']>;
  byInvoiceSentAtTo?: InputMaybe<Scalars['Date']['input']>;
  byInvoiceTypes?: InputMaybe<Array<InvoiceTypeEnum>>;
  byIsDescendant?: InputMaybe<Scalars['Boolean']['input']>;
  byLocationIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  byOpenStatus?: InputMaybe<OpenInvoiceStatusEnum>;
  byPaymentStatus?: InputMaybe<InvoicePaymentStatusEnum>;
  byPrivatePaymentType?: InputMaybe<Array<InputMaybe<InvoicePrivatePaymentTypeEnum>>>;
  byProjectIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  byStatus?: InputMaybe<InvoiceStatusEnum>;
  byTextSearch?: InputMaybe<Scalars['String']['input']>;
  filterByGroupEmployeeUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
};

export type LatestAbilityLevelsType = {
  __typename?: 'LatestAbilityLevelsType';
  converse?: Maybe<AbilityLevelType>;
  listen?: Maybe<AbilityLevelType>;
  read?: Maybe<AbilityLevelType>;
  talk?: Maybe<AbilityLevelType>;
  write?: Maybe<AbilityLevelType>;
};

export enum LearnerEnrollmentTypeEnum {
  Assessment = 'assessment',
  Intake = 'intake'
}

export type LearnerInputType = {
  NT2?: InputMaybe<Array<InputMaybe<Nt2InputType>>>;
  NT2HasAttempted?: InputMaybe<Scalars['Boolean']['input']>;
  NT2Info?: InputMaybe<Scalars['String']['input']>;
  adviceModuleFilterByProgramId?: InputMaybe<Scalars['MongoID']['input']>;
  adviceModuleFilterByProjectId?: InputMaybe<Scalars['MongoID']['input']>;
  adviceModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  adviceModuleInfo?: InputMaybe<Scalars['String']['input']>;
  computerSkill?: InputMaybe<Scalars['String']['input']>;
  currentOccupationFunction?: InputMaybe<Scalars['String']['input']>;
  currentOccupationMainLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currentOccupationResponsibilities?: InputMaybe<Scalars['String']['input']>;
  currentOccupationStartDate?: InputMaybe<PrecisionDateInputType>;
  currentOccupationVocalInteraction?: InputMaybe<Scalars['String']['input']>;
  currentOccupationVocalInteractionInfo?: InputMaybe<Scalars['String']['input']>;
  currentOccupationVocalInteractionQuality?: InputMaybe<Scalars['String']['input']>;
  doesNotExistInTwinfield?: InputMaybe<Scalars['Boolean']['input']>;
  educationDesire?: InputMaybe<Scalars['String']['input']>;
  educationExtraInfo?: InputMaybe<Scalars['String']['input']>;
  englishAsSupportLanguage?: InputMaybe<Scalars['String']['input']>;
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>;
  enrollmentType?: InputMaybe<LearnerEnrollmentTypeEnum>;
  examDignityDate?: InputMaybe<Scalars['Date']['input']>;
  familyCompositionLanguageExposure?: InputMaybe<Scalars['String']['input']>;
  highestEducationCountryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  highestEducationCountryOfOriginYear?: InputMaybe<Scalars['Int']['input']>;
  highestEducationLocal?: InputMaybe<Scalars['String']['input']>;
  highestEducationLocalYear?: InputMaybe<Scalars['Int']['input']>;
  homeLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  howDoYouKnowTopTaal?: InputMaybe<Scalars['String']['input']>;
  immigratedAt?: InputMaybe<PrecisionDateInputType>;
  intakeGoals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intakeGoalsAdditionalInfo?: InputMaybe<Scalars['String']['input']>;
  intakeGrades?: InputMaybe<InflowLevelsInputType>;
  intakeGradesLearnability?: InputMaybe<Scalars['Int']['input']>;
  intakeLiteracy?: InputMaybe<Scalars['String']['input']>;
  intakeLiteracyNativeLanguage?: InputMaybe<Scalars['String']['input']>;
  intakeMotivation?: InputMaybe<Scalars['String']['input']>;
  intakeOtherGoal?: InputMaybe<Scalars['String']['input']>;
  intakeReadingExamScore?: InputMaybe<Scalars['Int']['input']>;
  intakeReadingExamScoreTotal?: InputMaybe<Scalars['Int']['input']>;
  intakeResultsInfo?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isCitizenshipEducationObliged?: InputMaybe<Scalars['Boolean']['input']>;
  learningRoute?: InputMaybe<UserLearningRouteTypesEnum>;
  nativeLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  obligedCitizenshipEducationCompletedAt?: InputMaybe<Scalars['Date']['input']>;
  obligedCitizenshipEducationEndDateExtensions?: InputMaybe<Array<InputMaybe<ObligedCitizenshipEducationEndDateExtensionInputType>>>;
  obligedCitizenshipEducationInitialEndDate?: InputMaybe<Scalars['Date']['input']>;
  obligedCitizenshipEducationStartDate?: InputMaybe<PrecisionDateInputType>;
  /** Set the organization contact-user when the editor (contextUser) is part of that organization. This field overwrites the organizations input field. */
  organizationContactUserIdBasedOnContextOrganization?: InputMaybe<Scalars['MongoID']['input']>;
  /** Set the learners organization fileNumber when the editor (contextUser) is part of that organization. This field overwrites the organizations input field. */
  organizationFileNumberBasedOnContextOrganization?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<InputMaybe<LearnerOrganizationInputType>>>;
  otherLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentBy?: InputMaybe<UserPaymentByTypeEnum>;
  preferredLocationIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  privatePaymentAddress?: InputMaybe<AddressInputType>;
  privatePaymentDUOHasApprovedFund?: InputMaybe<Scalars['Boolean']['input']>;
  privatePaymentDUOInitialRemainingFund?: InputMaybe<Scalars['Float']['input']>;
  privatePaymentIsDUO?: InputMaybe<Scalars['Boolean']['input']>;
  sharePanteia?: InputMaybe<UserSharePanteiaTypesEnum>;
  socialStatus?: InputMaybe<Scalars['String']['input']>;
  socialStatusExtraInfo?: InputMaybe<Scalars['String']['input']>;
  targetLevelPIP?: InputMaybe<UserTargetLevelTypesEnum>;
  twinfieldCustomerCode?: InputMaybe<Scalars['String']['input']>;
  typeOfLearner?: InputMaybe<UserTypeOfLearnerTypesEnum>;
  workExperienceCountryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  workExperienceLocal?: InputMaybe<Scalars['String']['input']>;
  workLiteracy?: InputMaybe<Scalars['String']['input']>;
  workLiteracyInfo?: InputMaybe<Scalars['String']['input']>;
  workLiteracyLevel?: InputMaybe<Scalars['String']['input']>;
};

export enum LearnerLevelEnum {
  A0 = 'A0',
  A1 = 'A1',
  A1Minus = 'A1Minus',
  A2 = 'A2',
  A2Minus = 'A2Minus',
  B1 = 'B1',
  B1Minus = 'B1Minus',
  B2 = 'B2',
  B2Minus = 'B2Minus',
  C1 = 'C1',
  C1Minus = 'C1Minus',
  Instroom = 'Instroom',
  '1F' = '_1F',
  '1FMinus' = '_1FMinus',
  '2F' = '_2F',
  '2FMinus' = '_2FMinus',
  '3F' = '_3F',
  '3FMinus' = '_3FMinus',
  '4F' = '_4F',
  '4FMinus' = '_4FMinus'
}

export enum LearnerLevelWithAlphaEnum {
  A0 = 'A0',
  A1 = 'A1',
  A1Minus = 'A1Minus',
  A2 = 'A2',
  A2Minus = 'A2Minus',
  Alpha = 'Alpha',
  B1 = 'B1',
  B1Minus = 'B1Minus',
  B2 = 'B2',
  B2Minus = 'B2Minus',
  C1 = 'C1',
  C1Minus = 'C1Minus',
  Instroom = 'Instroom',
  '1F' = '_1F',
  '1FMinus' = '_1FMinus',
  '2F' = '_2F',
  '2FMinus' = '_2FMinus',
  '3F' = '_3F',
  '3FMinus' = '_3FMinus',
  '4F' = '_4F',
  '4FMinus' = '_4FMinus'
}

export type LearnerOrganizationInputType = {
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationContactUserId?: InputMaybe<Scalars['MongoID']['input']>;
  organizationFileNumber?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type LegacyInflowLevelsType = {
  __typename?: 'LegacyInflowLevelsType';
  conversations?: Maybe<LearnerLevelEnum>;
  listening?: Maybe<LearnerLevelEnum>;
  reading?: Maybe<LearnerLevelWithAlphaEnum>;
  talking?: Maybe<LearnerLevelEnum>;
  writing?: Maybe<LearnerLevelWithAlphaEnum>;
};

export type LessonCompetenceCertificateFileType = {
  __typename?: 'LessonCompetenceCertificateFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type LessonInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  locationId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  plannedAbilityExams?: InputMaybe<Array<PlannedAbilityExamInputType>>;
  roomId?: InputMaybe<Scalars['MongoID']['input']>;
  specialActivity?: InputMaybe<LessonSpecialActivityEnum>;
  teacherUserId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum LessonReasonOfAbsenceEnum {
  Exam = 'EXAM',
  Other = 'OTHER',
  OtherIntegrationActivity = 'OTHER_INTEGRATION_ACTIVITY',
  PrivateReasons = 'PRIVATE_REASONS',
  Sick = 'SICK',
  TransportIssues = 'TRANSPORT_ISSUES',
  Vacation = 'VACATION',
  WorkStudy = 'WORK_STUDY'
}

export enum LessonSpecialActivityEnum {
  Assessment = 'assessment',
  Exam = 'exam',
  PortfolioDeadline = 'portfolioDeadline',
  ReportMoment = 'reportMoment'
}

export type LessonType = {
  __typename?: 'LessonType';
  _id: Scalars['MongoID']['output'];
  areAllAttendancesSet: Scalars['Boolean']['output'];
  date: Scalars['Date']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  group?: Maybe<GroupType>;
  hasResults?: Maybe<Scalars['Boolean']['output']>;
  lessonUsers?: Maybe<Array<Maybe<LessonUserType>>>;
  lessonUsersCount?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<LocationType>;
  order: Scalars['Int']['output'];
  plannedAbilityExams?: Maybe<Array<PlannedAbilityExamType>>;
  room?: Maybe<RoomType>;
  specialActivity?: Maybe<LessonSpecialActivityEnum>;
  teacherUser?: Maybe<UserType>;
  teacherUserId?: Maybe<Scalars['MongoID']['output']>;
};


export type LessonTypeLessonUsersArgs = {
  filterByAttendance?: InputMaybe<Scalars['String']['input']>;
  filterByLearnerUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
};


export type LessonTypeLessonUsersCountArgs = {
  filterByAttendance?: InputMaybe<Scalars['String']['input']>;
};

export type LessonUserInputType = {
  attendance?: InputMaybe<Scalars['String']['input']>;
  attendanceNote?: InputMaybe<Scalars['String']['input']>;
  learnerUserId: Scalars['MongoID']['input'];
  lessonId: Scalars['MongoID']['input'];
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reasonOfAbsence?: InputMaybe<LessonReasonOfAbsenceEnum>;
};

export type LessonUserType = {
  __typename?: 'LessonUserType';
  _id: Scalars['MongoID']['output'];
  attendance?: Maybe<Scalars['String']['output']>;
  attendanceNote?: Maybe<Scalars['String']['output']>;
  attendanceSetAt?: Maybe<Scalars['Date']['output']>;
  attendanceSetByUser?: Maybe<UserType>;
  isRemovedMember?: Maybe<Scalars['Boolean']['output']>;
  learnerUser?: Maybe<UserType>;
  learnerUserId?: Maybe<Scalars['MongoID']['output']>;
  lesson?: Maybe<LessonType>;
  lessonDate?: Maybe<Scalars['Date']['output']>;
  reasonOfAbsence?: Maybe<LessonReasonOfAbsenceEnum>;
};

export type LocalizedExtraLearnerInformationInputType = {
  en?: InputMaybe<Scalars['String']['input']>;
  nl?: InputMaybe<Scalars['String']['input']>;
};

export type LocalizedExtraLearnerInformationType = {
  __typename?: 'LocalizedExtraLearnerInformationType';
  en?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<Scalars['String']['output']>;
};

export type LocationInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  address?: InputMaybe<AddressInputType>;
  contactPersonName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  rentalDateFrom?: InputMaybe<Scalars['Date']['input']>;
  rentalDateTo?: InputMaybe<Scalars['Date']['input']>;
};

export type LocationPropertyInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LocationPropertyType = {
  __typename?: 'LocationPropertyType';
  _id: Scalars['MongoID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  _id: Scalars['MongoID']['output'];
  address?: Maybe<AddressType>;
  contactPersonName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  rentalDateFrom?: Maybe<Scalars['Date']['output']>;
  rentalDateTo?: Maybe<Scalars['Date']['output']>;
  rooms?: Maybe<Array<Maybe<RoomType>>>;
};


export type LocationTypeRoomsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type ModuleExamAbilitiesInputType = {
  converse?: InputMaybe<Scalars['Boolean']['input']>;
  listen?: InputMaybe<Scalars['Boolean']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  talk?: InputMaybe<Scalars['Boolean']['input']>;
  write?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModuleExamAbilitiesType = {
  __typename?: 'ModuleExamAbilitiesType';
  converse?: Maybe<Scalars['Boolean']['output']>;
  listen?: Maybe<Scalars['Boolean']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  talk?: Maybe<Scalars['Boolean']['output']>;
  write?: Maybe<Scalars['Boolean']['output']>;
};

export type ModuleExamInputType = {
  abilities?: InputMaybe<ModuleExamAbilitiesInputType>;
  examId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ModuleExamType = {
  __typename?: 'ModuleExamType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  abilities?: Maybe<ModuleExamAbilitiesType>;
  exam?: Maybe<ExamType>;
  examId?: Maybe<Scalars['MongoID']['output']>;
};

export type ModuleInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  amountOfTotalLessons?: InputMaybe<Scalars['Int']['input']>;
  amountOfWeeklyLessons?: InputMaybe<Scalars['Int']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  courseMaterialIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  endLevel?: InputMaybe<LearnerLevelWithAlphaEnum>;
  exams?: InputMaybe<Array<ModuleExamInputType>>;
  /** Bevat eindgesprek */
  hasFinalReview?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type inburgering */
  integrationType?: InputMaybe<IntegrationTypeEnum>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Inburgeringsexameen */
  isFinalExamTraining?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alfabetisering */
  isLiteracyCourse?: InputMaybe<Scalars['Boolean']['input']>;
  lessonDuration?: InputMaybe<Scalars['Int']['input']>;
  locationPropertyIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Type project */
  projectType?: InputMaybe<ProjectTypeEnum>;
  startLevel?: InputMaybe<LearnerLevelWithAlphaEnum>;
};

export type ModuleType = {
  __typename?: 'ModuleType';
  _id: Scalars['MongoID']['output'];
  amountOfTotalLessons?: Maybe<Scalars['Int']['output']>;
  amountOfWeeklyLessons?: Maybe<Scalars['Int']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  courseMaterials?: Maybe<Array<Maybe<CourseMaterialType>>>;
  endLevel?: Maybe<LearnerLevelEnum>;
  exams?: Maybe<Array<Maybe<ModuleExamType>>>;
  /** Bevat eindgesprek */
  hasFinalReview?: Maybe<Scalars['Boolean']['output']>;
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Inburgeringsexameen */
  isFinalExamTraining?: Maybe<Scalars['Boolean']['output']>;
  isIntegrationCourse?: Maybe<Scalars['Boolean']['output']>;
  /** Alfabetisering */
  isLiteracyCourse?: Maybe<Scalars['Boolean']['output']>;
  isTemplateModule?: Maybe<Scalars['Boolean']['output']>;
  isUsedInNonConceptGroups?: Maybe<Scalars['Boolean']['output']>;
  lessonDuration?: Maybe<Scalars['Int']['output']>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  name?: Maybe<Scalars['String']['output']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  startLevel?: Maybe<LearnerLevelEnum>;
  usedByProgram?: Maybe<Scalars['Boolean']['output']>;
};

export type ModulesFiltersInputType = {
  filterByIntegrationType?: InputMaybe<IntegrationTypeEnum>;
  filterByProjectType?: InputMaybe<ProjectTypeEnum>;
};

export type Nt2CertificateFileType = {
  __typename?: 'NT2CertificateFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
};

export type Nt2InputType = {
  courseMaterials?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LearnerLevelWithAlphaEnum>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type Nt2Type = {
  __typename?: 'NT2Type';
  courseMaterials?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  organizationName?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type NoteInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<NoteTypeEnum>;
  typeData?: InputMaybe<NoteTypeDataInputType>;
};

export type NoteType = {
  __typename?: 'NoteType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  text?: Maybe<Scalars['String']['output']>;
};

export type NoteTypeDataInputType = {
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum NoteTypeEnum {
  GroupNote = 'groupNote'
}

export type NotesFilterInputType = {
  filterByType?: InputMaybe<NoteTypeEnum>;
  filterByTypeData?: InputMaybe<NotesFilterTypeDataInputType>;
};

export type NotesFilterTypeDataInputType = {
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type ObligedCitizenshipEducationEndDateExtensionInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['UploadedFile']['input']>;
};

export type ObligedCitizenshipEducationEndDateExtensiontype = {
  __typename?: 'ObligedCitizenshipEducationEndDateExtensiontype';
  _id?: Maybe<Scalars['MongoID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<UserDocumentType>;
};

export enum OpenInvoiceStatusEnum {
  NeedsSignatureForDuo = 'NeedsSignatureForDUO',
  RejectedByDuo = 'RejectedByDUO',
  WaitingForDuoApproval = 'WaitingForDUOApproval',
  WaitingForDuoExportBatch = 'WaitingForDUOExportBatch',
  WaitingForPayment = 'WaitingForPayment'
}

export type OrganizationContactInputType = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type OrganizationDocumentInputType = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OrganizationDocumentType = {
  __typename?: 'OrganizationDocumentType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
};

export type OrganizationInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  address?: InputMaybe<AddressInputType>;
  billingAddress?: InputMaybe<AddressInputType>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  invoiceEmailRecipients?: InputMaybe<Array<InputMaybe<OrganizationInvoiceEmailRecipientInputType>>>;
  invoiceTexts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationCode?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<PhoneNumberInputType>;
  projectId?: InputMaybe<Scalars['MongoID']['input']>;
  requestNewTwinfieldCustomerCode?: InputMaybe<Scalars['Boolean']['input']>;
  twinfieldCustomerCode?: InputMaybe<Scalars['String']['input']>;
  vatExempt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationInvoiceEmailRecipientInputType = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OrganizationInvoiceEmailRecipientType = {
  __typename?: 'OrganizationInvoiceEmailRecipientType';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  _id: Scalars['MongoID']['output'];
  address?: Maybe<AddressType>;
  amountOfLearners?: Maybe<Scalars['Int']['output']>;
  billingAddress?: Maybe<AddressType>;
  canTwinfieldCustomerCodeChange?: Maybe<Scalars['Boolean']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactUsers?: Maybe<Array<Maybe<UserType>>>;
  documents?: Maybe<Array<Maybe<OrganizationDocumentType>>>;
  groups?: Maybe<Array<GroupType>>;
  invoiceEmailRecipients?: Maybe<Array<Maybe<OrganizationInvoiceEmailRecipientType>>>;
  invoiceTexts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  organizationCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<PhoneNumberType>;
  project?: Maybe<ProjectType>;
  timelineEvents?: Maybe<Array<TimelineEventType>>;
  twinfieldCustomer?: Maybe<TwinfieldCustomerType>;
  vatExempt?: Maybe<Scalars['Boolean']['output']>;
};


export type OrganizationTypeDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeTimelineEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedInvoiceType = {
  __typename?: 'PaginatedInvoiceType';
  count: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  nodes?: Maybe<Array<InvoiceType>>;
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginatedUserType = {
  __typename?: 'PaginatedUserType';
  count: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  nodes?: Maybe<Array<UserType>>;
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PhoneNumberInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PhoneNumberType = {
  __typename?: 'PhoneNumberType';
  _id: Scalars['MongoID']['output'];
  country?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['String']['output']>;
  internationalPhoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PlannedAbilityExamInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  examAbility?: InputMaybe<ExamAbiltiesTypeEnum>;
  examId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PlannedAbilityExamType = {
  __typename?: 'PlannedAbilityExamType';
  _id?: Maybe<Scalars['MongoID']['output']>;
  exam?: Maybe<ExamType>;
  examAbility?: Maybe<ExamAbiltiesTypeEnum>;
  examId?: Maybe<Scalars['MongoID']['output']>;
  group?: Maybe<GroupType>;
  lesson?: Maybe<LessonType>;
  results?: Maybe<Array<Maybe<ResultType>>>;
  resultsCompleted?: Maybe<Scalars['Boolean']['output']>;
};

export enum PrecisionDateEnum {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Second = 'SECOND',
  Year = 'YEAR'
}

export type PrecisionDateInputType = {
  date: Scalars['Date']['input'];
  precision: PrecisionDateEnum;
};

export type PrecisionDateType = {
  __typename?: 'PrecisionDateType';
  date: Scalars['Date']['output'];
  precision: PrecisionDateEnum;
};

export type ProcessItemType = AdviceReportType | FinalExamType | GroupType;

export type ProcessType = {
  __typename?: 'ProcessType';
  _id: Scalars['MongoID']['output'];
  data?: Maybe<ProcessItemType>;
  date?: Maybe<Scalars['Date']['output']>;
  type?: Maybe<ProcessTypesEnum>;
};

export enum ProcessTypesEnum {
  AdviceReport = 'AdviceReport',
  FinalExam = 'FinalExam',
  Group = 'Group'
}

export type ProfileInputType = {
  address?: InputMaybe<AddressInputType>;
  alternativeEmail?: InputMaybe<Scalars['String']['input']>;
  bsn?: InputMaybe<Scalars['String']['input']>;
  bsnVerified?: InputMaybe<Scalars['Boolean']['input']>;
  cityOfBirth?: InputMaybe<Scalars['String']['input']>;
  countriesOfNationality?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  countryOfOrigin?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  hasNoCountryOfNationality?: InputMaybe<Scalars['Boolean']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<PhoneNumberInputType>>>;
  surName?: InputMaybe<Scalars['String']['input']>;
  surNamePrefix?: InputMaybe<Scalars['String']['input']>;
  visitAddress?: InputMaybe<AddressInputType>;
};

export type PrognosisYearsRowDataType = {
  __typename?: 'PrognosisYearsRowDataType';
  amount?: Maybe<Scalars['Float']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
};

export type PrognosisYearsRowType = {
  __typename?: 'PrognosisYearsRowType';
  amount?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Array<Maybe<PrognosisYearsRowDataType>>>;
  twinfieldArticleCode: Scalars['String']['output'];
};

export type PrognosisYearsType = {
  __typename?: 'PrognosisYearsType';
  amount?: Maybe<Scalars['Float']['output']>;
  articles?: Maybe<Array<Maybe<PrognosisYearsRowType>>>;
  months?: Maybe<Array<Maybe<PrognosisYearsRowDataType>>>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ProgramInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** Type inburgering */
  integrationType?: InputMaybe<IntegrationTypeEnum>;
  moduleIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Type project */
  projectType?: InputMaybe<ProjectTypeEnum>;
};

export type ProgramType = {
  __typename?: 'ProgramType';
  _id: Scalars['MongoID']['output'];
  /** Type inburgering */
  integrationType?: Maybe<IntegrationTypeEnum>;
  modules?: Maybe<Array<Maybe<ModuleType>>>;
  name?: Maybe<Scalars['String']['output']>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
  usedByProject?: Maybe<Scalars['Boolean']['output']>;
};

export type ProgramsFiltersInputType = {
  filterByIntegrationType?: InputMaybe<IntegrationTypeEnum>;
  filterByProjectType?: InputMaybe<ProjectTypeEnum>;
};

export type ProjectArticleCodesInputType = {
  articleCode?: InputMaybe<Scalars['String']['input']>;
  articleCodeType?: InputMaybe<ArticleCodesTypeEnum>;
  paymentType?: InputMaybe<ProjectArticleCodesPaymentTypeEnum>;
};

export enum ProjectArticleCodesPaymentTypeEnum {
  Organization = 'organization',
  PrivateDuo = 'privateDuo',
  PrivateSelf = 'privateSelf'
}

export type ProjectArticleCodesType = {
  __typename?: 'ProjectArticleCodesType';
  articleCode?: Maybe<Scalars['String']['output']>;
  articleCodeType?: Maybe<ArticleCodesTypeEnum>;
  paymentType?: Maybe<ProjectArticleCodesPaymentTypeEnum>;
};

export enum ProjectChargeForLessonAttendanceTypeEnum {
  Any = 'Any',
  Present = 'Present',
  PresentAndAbsent = 'PresentAndAbsent'
}

export enum ProjectChargeForLessonsTypeEnum {
  AllLessons = 'AllLessons',
  OnlyEnrolledLessons = 'OnlyEnrolledLessons'
}

export type ProjectInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  articleCodes?: InputMaybe<Array<InputMaybe<ProjectArticleCodesInputType>>>;
  lessonInviteReplyTo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  /** Type project */
  projectType?: InputMaybe<ProjectTypeEnum>;
};

export enum ProjectInvoiceGranularityEnum {
  Group = 'Group',
  Learner = 'Learner'
}

export enum ProjectInvoiceTimingEnum {
  Periodical = 'Periodical',
  Variable = 'Variable'
}

export type ProjectInvoicingSettingFixedPriceSettingsInputType = {
  amount?: InputMaybe<Scalars['Float']['input']>;
};

export type ProjectInvoicingSettingFixedPriceSettingsType = {
  __typename?: 'ProjectInvoicingSettingFixedPriceSettingsType';
  amount?: Maybe<Scalars['Float']['output']>;
};

export type ProjectInvoicingSettingInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  fixedPriceSettings?: InputMaybe<ProjectInvoicingSettingFixedPriceSettingsInputType>;
  invoiceGranularity?: InputMaybe<ProjectInvoiceGranularityEnum>;
  invoiceTiming?: InputMaybe<ProjectInvoiceTimingEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  periodicalTimingSettings?: InputMaybe<ProjectInvoicingSettingPeriodicalTimingSettingsInputType>;
  priceType?: InputMaybe<ProjectPriceTypeEnum>;
  programIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  projectId?: InputMaybe<Scalars['MongoID']['input']>;
  variablePriceSettings?: InputMaybe<ProjectInvoicingSettingVariablePriceSettingsInputType>;
  variableTimingSettings?: InputMaybe<ProjectInvoicingSettingVariableTimingSettingsInputType>;
};

export type ProjectInvoicingSettingPeriodicalTimingSettingsInputType = {
  chargeForLessonAttendanceType?: InputMaybe<ProjectChargeForLessonAttendanceTypeEnum>;
  chargeForLessonReasonOfAbsences?: InputMaybe<Array<InputMaybe<ChargeForLessonReasonOfAbsenceEnum>>>;
  period?: InputMaybe<ProjectPeriodicalTimingPeriodEnum>;
};

export type ProjectInvoicingSettingPeriodicalTimingSettingsType = {
  __typename?: 'ProjectInvoicingSettingPeriodicalTimingSettingsType';
  chargeForLessonAttendanceType?: Maybe<ProjectChargeForLessonAttendanceTypeEnum>;
  chargeForLessonReasonOfAbsences?: Maybe<Array<Maybe<ChargeForLessonReasonOfAbsenceEnum>>>;
  period?: Maybe<ProjectPeriodicalTimingPeriodEnum>;
};

export type ProjectInvoicingSettingType = {
  __typename?: 'ProjectInvoicingSettingType';
  _id: Scalars['MongoID']['output'];
  fixedPriceSettings?: Maybe<ProjectInvoicingSettingFixedPriceSettingsType>;
  invoiceGranularity?: Maybe<ProjectInvoiceGranularityEnum>;
  invoiceTiming?: Maybe<ProjectInvoiceTimingEnum>;
  name?: Maybe<Scalars['String']['output']>;
  periodicalTimingSettings?: Maybe<ProjectInvoicingSettingPeriodicalTimingSettingsType>;
  priceType?: Maybe<ProjectPriceTypeEnum>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  project?: Maybe<Array<Maybe<ProgramType>>>;
  variablePriceSettings?: Maybe<ProjectInvoicingSettingVariablePriceSettingsType>;
  variableTimingSettings?: Maybe<ProjectInvoicingSettingVariableTimingSettingsType>;
};

export type ProjectInvoicingSettingVariablePriceSettingsAmountRangeInputType = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInvoicingSettingVariablePriceSettingsAmountRangeType = {
  __typename?: 'ProjectInvoicingSettingVariablePriceSettingsAmountRangeType';
  amount?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
};

export type ProjectInvoicingSettingVariablePriceSettingsInputType = {
  amountRanges?: InputMaybe<Array<InputMaybe<ProjectInvoicingSettingVariablePriceSettingsAmountRangeInputType>>>;
  variableBy?: InputMaybe<ProjectVariablePriceVariableByEnum>;
};

export type ProjectInvoicingSettingVariablePriceSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariablePriceSettingsType';
  amountRanges?: Maybe<Array<Maybe<ProjectInvoicingSettingVariablePriceSettingsAmountRangeType>>>;
  variableBy?: Maybe<ProjectVariablePriceVariableByEnum>;
};

export type ProjectInvoicingSettingVariableTimingConditionSettingsInputType = {
  condition?: InputMaybe<ProjectPeriodConditionEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectInvoicingSettingVariableTimingConditionSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingConditionSettingsType';
  condition?: Maybe<ProjectPeriodConditionEnum>;
  description?: Maybe<Scalars['String']['output']>;
  isChecked?: Maybe<Scalars['Boolean']['output']>;
};

export type ProjectInvoicingSettingVariableTimingPeriodInputType = {
  conditionSettings?: InputMaybe<Array<InputMaybe<ProjectInvoicingSettingVariableTimingConditionSettingsInputType>>>;
  percentageOfPassedLessons?: InputMaybe<Scalars['Float']['input']>;
  percentageOfPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type ProjectInvoicingSettingVariableTimingPeriodType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingPeriodType';
  conditionSettings?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingConditionSettingsType>>>;
  percentageOfPassedLessons?: Maybe<Scalars['Float']['output']>;
  percentageOfPrice?: Maybe<Scalars['Float']['output']>;
};

export type ProjectInvoicingSettingVariableTimingSettingsInputType = {
  chargeForLessonsType?: InputMaybe<ProjectChargeForLessonsTypeEnum>;
  periods?: InputMaybe<Array<InputMaybe<ProjectInvoicingSettingVariableTimingPeriodInputType>>>;
};

export type ProjectInvoicingSettingVariableTimingSettingsType = {
  __typename?: 'ProjectInvoicingSettingVariableTimingSettingsType';
  chargeForLessonsType?: Maybe<ProjectChargeForLessonsTypeEnum>;
  periods?: Maybe<Array<Maybe<ProjectInvoicingSettingVariableTimingPeriodType>>>;
};

export enum ProjectPeriodConditionEnum {
  AttendancePercentage = 'AttendancePercentage',
  DateOfDisenrollment = 'DateOfDisenrollment',
  Other = 'Other',
  ParticipationInExam = 'ParticipationInExam',
  PassedExam = 'PassedExam',
  ReceivedConfirmationOfMunicipality = 'ReceivedConfirmationOfMunicipality'
}

export enum ProjectPeriodicalTimingPeriodEnum {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly'
}

export enum ProjectPriceTypeEnum {
  Fixed = 'Fixed',
  Variable = 'Variable'
}

export type ProjectType = {
  __typename?: 'ProjectType';
  _id: Scalars['MongoID']['output'];
  articleCodes?: Maybe<Array<Maybe<ProjectArticleCodesType>>>;
  lessonInviteReplyTo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  projectInvoicingSettings?: Maybe<Array<Maybe<ProjectInvoicingSettingType>>>;
  /** Type project */
  projectType?: Maybe<ProjectTypeEnum>;
};


export type ProjectTypeProgramsArgs = {
  filterByAvailableForExistingInvoicingSettingId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByAvailableForNewInvoicingSetting?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProjectTypeProjectInvoicingSettingsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export enum ProjectTypeEnum {
  Integration = 'Integration',
  Organization = 'Organization',
  Other = 'Other'
}

export enum ProjectVariablePriceVariableByEnum {
  Hour = 'Hour',
  Learner = 'Learner'
}

export type RemovedGroupUserInputType = {
  adviceModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  removedInfo?: InputMaybe<Scalars['String']['input']>;
  removedReason?: InputMaybe<GroupUserRemovedReasonEnum>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type ResultDocumentFileType = {
  __typename?: 'ResultDocumentFileType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
};

export type ResultDocumentInputType = {
  existingFileIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['UploadedFile']['input']>>>;
};

export type ResultInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  documentFiles?: InputMaybe<Array<InputMaybe<ResultDocumentInputType>>>;
  learnerUserId?: InputMaybe<Scalars['MongoID']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  plannedAbilityExamId?: InputMaybe<Scalars['MongoID']['input']>;
  result?: InputMaybe<ResultResultInputType>;
};

export type ResultResultInputType = {
  absenceExplanation?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LearnerLevelWithAlphaEnum>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  passedNotPassed?: InputMaybe<Scalars['Boolean']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<ResultStatusEnum>;
};

export type ResultResultType = {
  __typename?: 'ResultResultType';
  absenceExplanation?: Maybe<Scalars['String']['output']>;
  level?: Maybe<LearnerLevelWithAlphaEnum>;
  passedNotPassed?: Maybe<Scalars['Boolean']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<ResultStatusEnum>;
};

export enum ResultStatusEnum {
  Exempt = 'exempt',
  NoShow = 'no_show',
  Participated = 'participated'
}

export type ResultType = {
  __typename?: 'ResultType';
  _id: Scalars['MongoID']['output'];
  exam?: Maybe<ExamType>;
  examAbility?: Maybe<Scalars['String']['output']>;
  examId?: Maybe<Scalars['MongoID']['output']>;
  files?: Maybe<Array<Maybe<ResultDocumentFileType>>>;
  learnerUser?: Maybe<UserType>;
  learnerUserId?: Maybe<Scalars['MongoID']['output']>;
  plannedAbilityExam?: Maybe<PlannedAbilityExamType>;
  plannedAbilityExamId?: Maybe<Scalars['MongoID']['output']>;
  result?: Maybe<ResultResultType>;
};

export type RevenueYearArticleType = {
  __typename?: 'RevenueYearArticleType';
  amount?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Array<Maybe<RevenueYearMonthType>>>;
  twinfieldArticleCode: Scalars['String']['output'];
};

export type RevenueYearMonthType = {
  __typename?: 'RevenueYearMonthType';
  amount?: Maybe<Scalars['Float']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
};

export type RevenueYearType = {
  __typename?: 'RevenueYearType';
  amount?: Maybe<Scalars['Float']['output']>;
  articles?: Maybe<Array<Maybe<RevenueYearArticleType>>>;
  months?: Maybe<Array<Maybe<RevenueYearMonthType>>>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type RoomInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['MongoID']['input']>;
  locationPropertyIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomType = {
  __typename?: 'RoomType';
  _id: Scalars['MongoID']['output'];
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  capacity?: Maybe<Scalars['Int']['output']>;
  inflowMoments?: Maybe<Array<InflowMomentType>>;
  lessons?: Maybe<Array<LessonType>>;
  location?: Maybe<LocationType>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  DUODeclarations_create?: Maybe<DuoDeclarationType>;
  DUODeclarations_delete?: Maybe<DuoDeclarationType>;
  DUODeclarations_edit?: Maybe<DuoDeclarationType>;
  DUOFinalExamResultErrors_resolve?: Maybe<Scalars['Boolean']['output']>;
  DUOInvoices_generate?: Maybe<DuoInvoiceType>;
  DUOLeanerReports_generate?: Maybe<DuoLearnerReportType>;
  DUOResponse_addDocument?: Maybe<DuoResponseType>;
  adviceReport_release?: Maybe<AdviceReportType>;
  adviceReport_revoke?: Maybe<AdviceReportType>;
  adviceReports_create?: Maybe<AdviceReportType>;
  adviceReports_delete?: Maybe<AdviceReportType>;
  adviceReports_edit?: Maybe<AdviceReportType>;
  adviceReports_generateFile?: Maybe<ExportResponseType>;
  calendarEvents_create?: Maybe<CalendarEventType>;
  calendarEvents_delete?: Maybe<CalendarEventType>;
  calendarEvents_edit?: Maybe<CalendarEventType>;
  conteptGroups_createUsersExportFile?: Maybe<ExportType>;
  contracts_makeFinal?: Maybe<ContractType>;
  contracts_refreshForUser?: Maybe<Scalars['Boolean']['output']>;
  contracts_terminate?: Maybe<ContractType>;
  contracts_uploadSignature?: Maybe<ContractType>;
  courseMaterials_create?: Maybe<CourseMaterialType>;
  courseMaterials_delete?: Maybe<CourseMaterialType>;
  courseMaterials_edit?: Maybe<CourseMaterialType>;
  employee_to_external_teacher?: Maybe<UserType>;
  exams_create?: Maybe<ExamType>;
  exams_delete?: Maybe<ExamType>;
  exams_edit?: Maybe<ExamType>;
  exports_audit_generate_finalExam_attempts?: Maybe<ExportType>;
  exports_audit_generate_teacher?: Maybe<ExportType>;
  exports_enrollment_contracts_duo_bow?: Maybe<ExportType>;
  exports_generate_citizenship?: Maybe<ExportType>;
  exports_generate_outflow?: Maybe<ExportType>;
  exports_participated_hours_duo?: Maybe<ExportType>;
  external_teacher_to_employee?: Maybe<UserType>;
  fileSessions_create?: Maybe<FileSessionType>;
  finalExams_cancel?: Maybe<Scalars['Boolean']['output']>;
  finalExams_insertMultiple?: Maybe<FinalExamInsertReturnType>;
  finalExams_update?: Maybe<Scalars['Boolean']['output']>;
  finalExams_uploadDUOResults?: Maybe<Scalars['Boolean']['output']>;
  groupInviteTemplates_create?: Maybe<GroupInviteTemplateType>;
  groupInviteTemplates_delete?: Maybe<GroupInviteTemplateType>;
  groupInviteTemplates_edit?: Maybe<GroupInviteTemplateType>;
  groups_addDocuments?: Maybe<GroupType>;
  groups_changeConceptUsers?: Maybe<GroupType>;
  groups_create?: Maybe<GroupType>;
  groups_createUsersExportFile?: Maybe<ExportResponseType>;
  groups_delete?: Maybe<GroupType>;
  groups_disenrollUser?: Maybe<GroupType>;
  groups_edit?: Maybe<GroupType>;
  groups_end?: Maybe<GroupType>;
  groups_enrollUser?: Maybe<GroupType>;
  groups_makeFinal?: Maybe<GroupType>;
  groups_removeDocuments?: Maybe<GroupType>;
  groups_undoDisenrollment?: Maybe<GroupType>;
  groups_undoEnrollment?: Maybe<GroupType>;
  groups_validate?: Maybe<ValidationResponseType>;
  inflowInviteTemplates_create?: Maybe<InflowInviteTemplateType>;
  inflowInviteTemplates_delete?: Maybe<InflowInviteTemplateType>;
  inflowInviteTemplates_edit?: Maybe<InflowInviteTemplateType>;
  inflowModules_create?: Maybe<InflowModuleType>;
  inflowModules_delete?: Maybe<InflowModuleType>;
  inflowModules_edit?: Maybe<InflowModuleType>;
  inflowMomentTimeslotUser_setNoShowStatus?: Maybe<InflowMomentTimeslotUserType>;
  inflowMomentTimeslotUser_unsetNoShowStatus?: Maybe<InflowMomentTimeslotUserType>;
  inflowMomentTimeslotUsers_createExportFile?: Maybe<ExportResponseType>;
  inflowMomentTimeslots_add?: Maybe<InflowMomentType>;
  inflowMomentTimeslots_changeUsers?: Maybe<InflowMomentTimeslotType>;
  inflowMomentTimeslots_multiple_changeUsers?: Maybe<Scalars['Boolean']['output']>;
  inflowMoment_sendInvites?: Maybe<ExportResponseType>;
  inflowMoments_create?: Maybe<InflowMomentType>;
  inflowMoments_delete?: Maybe<InflowMomentType>;
  inflowMoments_edit?: Maybe<InflowMomentType>;
  inflowMoments_makeFinal?: Maybe<InflowMomentType>;
  instructionVideos_create?: Maybe<InstructionVideoType>;
  instructionVideos_delete?: Maybe<InstructionVideoType>;
  instructionVideos_edit?: Maybe<InstructionVideoType>;
  invoices_cancel?: Maybe<InvoiceType>;
  invoices_createDebit?: Maybe<InvoiceType>;
  invoices_createExportFile?: Maybe<ExportResponseType>;
  invoices_createFinalExamCreditInvoice?: Maybe<InvoiceType>;
  invoices_createGroupParticipationCreditInvoice?: Maybe<InvoiceType>;
  invoices_createLessonMaterialsCreditInvoice?: Maybe<InvoiceType>;
  invoices_credit_reject?: Maybe<InvoiceType>;
  invoices_deleteCredit?: Maybe<InvoiceType>;
  invoices_deleteDebit?: Maybe<InvoiceType>;
  invoices_generatePdf?: Maybe<ExportResponseType>;
  invoices_makeFinal?: Maybe<InvoiceType>;
  invoices_uploadSignatureForDuo?: Maybe<InvoiceType>;
  lessons_create?: Maybe<LessonType>;
  lessons_delete?: Maybe<LessonType>;
  lessons_edit?: Maybe<LessonType>;
  lessons_setPresence?: Maybe<LessonType>;
  locationProperties_create?: Maybe<LocationPropertyType>;
  locationProperties_delete?: Maybe<LocationPropertyType>;
  locationProperties_edit?: Maybe<LocationPropertyType>;
  locations_create?: Maybe<LocationType>;
  locations_delete?: Maybe<LocationType>;
  locations_edit?: Maybe<LocationType>;
  modules_create?: Maybe<ModuleType>;
  modules_delete?: Maybe<ModuleType>;
  modules_edit?: Maybe<ModuleType>;
  notes_create?: Maybe<NoteType>;
  notes_delete?: Maybe<NoteType>;
  notes_edit?: Maybe<NoteType>;
  organizations_addDocuments?: Maybe<OrganizationType>;
  organizations_create?: Maybe<OrganizationType>;
  organizations_delete?: Maybe<OrganizationType>;
  organizations_edit?: Maybe<OrganizationType>;
  organizations_removeDocuments?: Maybe<OrganizationType>;
  programs_create?: Maybe<ProgramType>;
  programs_delete?: Maybe<ProgramType>;
  programs_edit?: Maybe<ProgramType>;
  projectInvoicingSettings_create?: Maybe<ProjectInvoicingSettingType>;
  projectInvoicingSettings_delete?: Maybe<Scalars['Boolean']['output']>;
  projectInvoicingSettings_edit?: Maybe<ProjectInvoicingSettingType>;
  projects_create?: Maybe<ProjectType>;
  projects_delete?: Maybe<ProjectType>;
  projects_edit?: Maybe<ProjectType>;
  result_enterMultiple?: Maybe<Scalars['Boolean']['output']>;
  results_create?: Maybe<ResultType>;
  results_delete?: Maybe<ResultType>;
  results_edit?: Maybe<ResultType>;
  rooms_create?: Maybe<RoomType>;
  rooms_delete?: Maybe<RoomType>;
  rooms_edit?: Maybe<RoomType>;
  sessions_login?: Maybe<SessionType>;
  sessions_logout?: Maybe<Scalars['Boolean']['output']>;
  sessions_renew?: Maybe<SessionType>;
  timelineEvents_create?: Maybe<TimelineEventType>;
  timelineEvents_delete?: Maybe<TimelineEventType>;
  timelineEvents_edit?: Maybe<TimelineEventType>;
  userLearnerHours_create?: Maybe<UserLearnerHourType>;
  userLearnerHours_delete?: Maybe<UserLearnerHourType>;
  userLearnerHours_edit?: Maybe<UserLearnerHourType>;
  userLearnerSignups_create?: Maybe<UserLearnerSignupType>;
  userLearnerSignups_delete?: Maybe<UserLearnerSignupType>;
  userLearnerSignups_edit?: Maybe<UserLearnerSignupType>;
  users_accept_organizationContactAgreement?: Maybe<Scalars['Boolean']['output']>;
  users_activateInvoicingByDUO?: Maybe<UserType>;
  users_addDocuments?: Maybe<UserType>;
  users_changeEmail?: Maybe<UserType>;
  users_changeStatus?: Maybe<UserType>;
  users_change_password?: Maybe<UserType>;
  users_create?: Maybe<UserType>;
  users_createExportFile?: Maybe<ExportResponseType>;
  users_createLessonAttendanceFile?: Maybe<ExportResponseType>;
  users_createLessonInviteFile?: Maybe<ExportResponseType>;
  users_createLessonTimetableFile?: Maybe<ExportResponseType>;
  users_delete?: Maybe<UserType>;
  users_edit?: Maybe<UserType>;
  users_forgot_password?: Maybe<Scalars['Boolean']['output']>;
  users_import?: Maybe<Array<Maybe<UserImportDuplicateReturnType>>>;
  users_importFromEdisaFile?: Maybe<EdisaImportResponseType>;
  users_inflowOnHoldRemove?: Maybe<Array<Maybe<UserType>>>;
  users_inflowOnHoldSet?: Maybe<Array<Maybe<UserType>>>;
  users_removeDocuments?: Maybe<UserType>;
  users_resetCognitoTotpMfa?: Maybe<UserType>;
  users_resetIntake?: Maybe<UserType>;
  users_selectContextUserAsIntaker?: Maybe<UserType>;
  users_sendAttendanceReminder?: Maybe<UserType>;
  users_sendInvite?: Maybe<UserType>;
  users_sendLessonInvites?: Maybe<Scalars['Boolean']['output']>;
  users_setCognitoSmsMfa?: Maybe<UserType>;
  users_setMfaFields?: Maybe<UserType>;
  users_updateEnrollmentData?: Maybe<UserType>;
  users_updateInternalDUODeclarations?: Maybe<UserType>;
};


export type RootMutationTypeDuoDeclarations_CreateArgs = {
  DUODeclaration: DuoDeclarationInputType;
};


export type RootMutationTypeDuoDeclarations_DeleteArgs = {
  DUODeclarationId: Scalars['MongoID']['input'];
};


export type RootMutationTypeDuoDeclarations_EditArgs = {
  DUODeclaration: DuoDeclarationInputType;
};


export type RootMutationTypeDuoFinalExamResultErrors_ResolveArgs = {
  DUOFinalExamResultErrorId?: InputMaybe<Scalars['MongoID']['input']>;
  submitter?: InputMaybe<FinalExamSubmittersEnum>;
};


export type RootMutationTypeDuoLeanerReports_GenerateArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type RootMutationTypeDuoResponse_AddDocumentArgs = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type RootMutationTypeAdviceReport_ReleaseArgs = {
  adviceReportId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeAdviceReport_RevokeArgs = {
  adviceReportId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeAdviceReports_CreateArgs = {
  adviceReport: AdviceReportInputType;
};


export type RootMutationTypeAdviceReports_DeleteArgs = {
  adviceReportId: Scalars['MongoID']['input'];
};


export type RootMutationTypeAdviceReports_EditArgs = {
  adviceReport: AdviceReportInputType;
};


export type RootMutationTypeAdviceReports_GenerateFileArgs = {
  adviceReportId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeCalendarEvents_CreateArgs = {
  calendarEvent: CalendarEventInputType;
};


export type RootMutationTypeCalendarEvents_DeleteArgs = {
  calendarEventId: Scalars['MongoID']['input'];
};


export type RootMutationTypeCalendarEvents_EditArgs = {
  calendarEvent: CalendarEventInputType;
};


export type RootMutationTypeConteptGroups_CreateUsersExportFileArgs = {
  filters?: InputMaybe<GroupsFilterInputType>;
};


export type RootMutationTypeContracts_MakeFinalArgs = {
  contractId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeContracts_RefreshForUserArgs = {
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeContracts_TerminateArgs = {
  contractId?: InputMaybe<Scalars['MongoID']['input']>;
  terminatedReason?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['Date']['input']>;
};


export type RootMutationTypeContracts_UploadSignatureArgs = {
  contractId?: InputMaybe<Scalars['MongoID']['input']>;
  signatureFile?: InputMaybe<Scalars['UploadedFile']['input']>;
};


export type RootMutationTypeCourseMaterials_CreateArgs = {
  courseMaterial: CourseMaterialInputType;
};


export type RootMutationTypeCourseMaterials_DeleteArgs = {
  courseMaterialId: Scalars['MongoID']['input'];
};


export type RootMutationTypeCourseMaterials_EditArgs = {
  courseMaterial: CourseMaterialInputType;
};


export type RootMutationTypeEmployee_To_External_TeacherArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeExams_CreateArgs = {
  exam: ExamInputType;
};


export type RootMutationTypeExams_DeleteArgs = {
  examId: Scalars['MongoID']['input'];
};


export type RootMutationTypeExams_EditArgs = {
  exam: ExamInputType;
};


export type RootMutationTypeExports_Audit_Generate_FinalExam_AttemptsArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type RootMutationTypeExports_Audit_Generate_TeacherArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type RootMutationTypeExports_Generate_CitizenshipArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type RootMutationTypeExports_Generate_OutflowArgs = {
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type RootMutationTypeExports_Participated_Hours_DuoArgs = {
  bsnInput?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeExternal_Teacher_To_EmployeeArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeFileSessions_CreateArgs = {
  fileSession: FileSessionInputType;
};


export type RootMutationTypeFinalExams_CancelArgs = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  finalExamId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeFinalExams_InsertMultipleArgs = {
  finalExams?: InputMaybe<FinalExamInputType>;
};


export type RootMutationTypeFinalExams_UpdateArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  finalExamId?: InputMaybe<Scalars['MongoID']['input']>;
  partAndLevel?: InputMaybe<FinalExamCombinedPartsAndLevelsEnum>;
  type?: InputMaybe<FinalExamEditTypesEnum>;
};


export type RootMutationTypeFinalExams_UploadDuoResultsArgs = {
  resultsFile?: InputMaybe<Scalars['UploadedFile']['input']>;
};


export type RootMutationTypeGroupInviteTemplates_CreateArgs = {
  groupInviteTemplate: GroupInviteTemnplateInputType;
};


export type RootMutationTypeGroupInviteTemplates_DeleteArgs = {
  groupInviteTemplateId: Scalars['MongoID']['input'];
};


export type RootMutationTypeGroupInviteTemplates_EditArgs = {
  groupInviteTemplate: GroupInviteTemnplateInputType;
};


export type RootMutationTypeGroups_AddDocumentsArgs = {
  documents?: InputMaybe<Array<InputMaybe<GroupDocumentInputType>>>;
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeGroups_ChangeConceptUsersArgs = {
  groupId: Scalars['MongoID']['input'];
  groupUsersToAdd?: InputMaybe<Array<AddedGroupUserInputType>>;
  groupUsersToRemove?: InputMaybe<Array<RemovedGroupUserInputType>>;
};


export type RootMutationTypeGroups_CreateArgs = {
  group: GroupInputType;
};


export type RootMutationTypeGroups_CreateUsersExportFileArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  filterUsersByGroupLearners?: InputMaybe<Scalars['Boolean']['input']>;
  filterUsersByGroupTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  groupsFilter?: InputMaybe<GroupsFilterInputType>;
};


export type RootMutationTypeGroups_DeleteArgs = {
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeGroups_DisenrollUserArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  followupAction?: InputMaybe<GroupUserDisenrollmentFollowupActionEnum>;
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  newAdviceModule?: InputMaybe<GroupUserDisenrollmentNewAdviceModuleInputType>;
  removedInfo?: InputMaybe<Scalars['String']['input']>;
  removedReason?: InputMaybe<GroupUserRemovedReasonEnum>;
  role?: InputMaybe<GroupUserRoleEnum>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeGroups_EditArgs = {
  group: GroupInputType;
};


export type RootMutationTypeGroups_EndArgs = {
  groupId: Scalars['MongoID']['input'];
  removedGroupUsers?: InputMaybe<Array<RemovedGroupUserInputType>>;
};


export type RootMutationTypeGroups_EnrollUserArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  role?: InputMaybe<GroupUserRoleEnum>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeGroups_MakeFinalArgs = {
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeGroups_RemoveDocumentsArgs = {
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeGroups_UndoDisenrollmentArgs = {
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeGroups_UndoEnrollmentArgs = {
  groupId?: InputMaybe<Scalars['MongoID']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeGroups_ValidateArgs = {
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInflowInviteTemplates_CreateArgs = {
  inflowInviteTemplate: InflowInviteTemnplateInputType;
};


export type RootMutationTypeInflowInviteTemplates_DeleteArgs = {
  inflowInviteTemplateId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInflowInviteTemplates_EditArgs = {
  inflowInviteTemplate: InflowInviteTemnplateInputType;
};


export type RootMutationTypeInflowModules_CreateArgs = {
  inflowModule: InflowModuleInputType;
};


export type RootMutationTypeInflowModules_DeleteArgs = {
  inflowModuleId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInflowModules_EditArgs = {
  inflowModule: InflowModuleInputType;
};


export type RootMutationTypeInflowMomentTimeslotUser_SetNoShowStatusArgs = {
  inflowMomentTimeslotId?: InputMaybe<Scalars['MongoID']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInflowMomentTimeslotUser_UnsetNoShowStatusArgs = {
  inflowMomentTimeslotId?: InputMaybe<Scalars['MongoID']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInflowMomentTimeslotUsers_CreateExportFileArgs = {
  filters?: InputMaybe<InflowMomentFilterInputType>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeInflowMomentTimeslots_AddArgs = {
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  insertType?: InputMaybe<InflowMomentTimeslotsAddType>;
};


export type RootMutationTypeInflowMomentTimeslots_ChangeUsersArgs = {
  inflowMomentTimeslotId?: InputMaybe<Scalars['MongoID']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};


export type RootMutationTypeInflowMomentTimeslots_Multiple_ChangeUsersArgs = {
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  inflowMomentTimeslots?: InputMaybe<Array<InputMaybe<InflowMomentTimeslotInputType>>>;
};


export type RootMutationTypeInflowMoment_SendInvitesArgs = {
  freeText?: InputMaybe<Scalars['String']['input']>;
  freeTextEnglish?: InputMaybe<Scalars['String']['input']>;
  inflowInviteTemplateId?: InputMaybe<Scalars['MongoID']['input']>;
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  invites?: InputMaybe<Array<InputMaybe<InflowMomentInviteInvitesInputType>>>;
};


export type RootMutationTypeInflowMoments_CreateArgs = {
  inflowMoment: InflowMomentInputType;
};


export type RootMutationTypeInflowMoments_DeleteArgs = {
  inflowMomentId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInflowMoments_EditArgs = {
  inflowMoment: InflowMomentInputType;
};


export type RootMutationTypeInflowMoments_MakeFinalArgs = {
  inflowMomentId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInstructionVideos_CreateArgs = {
  instructionVideo: InstructionVideoInputType;
};


export type RootMutationTypeInstructionVideos_DeleteArgs = {
  instructionVideoId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInstructionVideos_EditArgs = {
  instructionVideo: InstructionVideoInputType;
};


export type RootMutationTypeInvoices_CancelArgs = {
  invoiceId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInvoices_CreateDebitArgs = {
  invoice: InvoiceInputType;
};


export type RootMutationTypeInvoices_CreateExportFileArgs = {
  filters?: InputMaybe<InvoicesFilterInputType>;
};


export type RootMutationTypeInvoices_CreateFinalExamCreditInvoiceArgs = {
  alternativeAmountDescription?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  creditDescription?: InputMaybe<Scalars['String']['input']>;
  debitInvoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInvoices_CreateGroupParticipationCreditInvoiceArgs = {
  creditDescription?: InputMaybe<Scalars['String']['input']>;
  debitInvoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  lessonIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInvoices_CreateLessonMaterialsCreditInvoiceArgs = {
  alternativeAmountDescription?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  creditDescription?: InputMaybe<Scalars['String']['input']>;
  debitInvoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInvoices_Credit_RejectArgs = {
  invoiceId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeInvoices_DeleteCreditArgs = {
  invoiceId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInvoices_DeleteDebitArgs = {
  invoiceId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInvoices_GeneratePdfArgs = {
  invoiceId: Scalars['MongoID']['input'];
};


export type RootMutationTypeInvoices_MakeFinalArgs = {
  invoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  invoiceWillBeManuallySent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeInvoices_UploadSignatureForDuoArgs = {
  invoiceId?: InputMaybe<Scalars['MongoID']['input']>;
  signatureFile?: InputMaybe<Scalars['UploadedFile']['input']>;
};


export type RootMutationTypeLessons_CreateArgs = {
  lesson: LessonInputType;
};


export type RootMutationTypeLessons_DeleteArgs = {
  lessonId: Scalars['MongoID']['input'];
};


export type RootMutationTypeLessons_EditArgs = {
  lesson: LessonInputType;
};


export type RootMutationTypeLessons_SetPresenceArgs = {
  lessonUser: LessonUserInputType;
};


export type RootMutationTypeLocationProperties_CreateArgs = {
  locationProperty: LocationPropertyInputType;
};


export type RootMutationTypeLocationProperties_DeleteArgs = {
  locationPropertyId: Scalars['MongoID']['input'];
};


export type RootMutationTypeLocationProperties_EditArgs = {
  locationProperty: LocationPropertyInputType;
};


export type RootMutationTypeLocations_CreateArgs = {
  location: LocationInputType;
};


export type RootMutationTypeLocations_DeleteArgs = {
  locationId: Scalars['MongoID']['input'];
};


export type RootMutationTypeLocations_EditArgs = {
  location: LocationInputType;
};


export type RootMutationTypeModules_CreateArgs = {
  module: ModuleInputType;
};


export type RootMutationTypeModules_DeleteArgs = {
  moduleId: Scalars['MongoID']['input'];
};


export type RootMutationTypeModules_EditArgs = {
  module: ModuleInputType;
};


export type RootMutationTypeNotes_CreateArgs = {
  note: NoteInputType;
};


export type RootMutationTypeNotes_DeleteArgs = {
  noteId: Scalars['MongoID']['input'];
};


export type RootMutationTypeNotes_EditArgs = {
  note: NoteInputType;
};


export type RootMutationTypeOrganizations_AddDocumentsArgs = {
  documents?: InputMaybe<Array<InputMaybe<OrganizationDocumentInputType>>>;
  organizationId: Scalars['MongoID']['input'];
};


export type RootMutationTypeOrganizations_CreateArgs = {
  organization: OrganizationInputType;
};


export type RootMutationTypeOrganizations_DeleteArgs = {
  organizationId: Scalars['MongoID']['input'];
};


export type RootMutationTypeOrganizations_EditArgs = {
  organization: OrganizationInputType;
};


export type RootMutationTypeOrganizations_RemoveDocumentsArgs = {
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  organizationId: Scalars['MongoID']['input'];
};


export type RootMutationTypePrograms_CreateArgs = {
  program: ProgramInputType;
};


export type RootMutationTypePrograms_DeleteArgs = {
  programId: Scalars['MongoID']['input'];
};


export type RootMutationTypePrograms_EditArgs = {
  program: ProgramInputType;
};


export type RootMutationTypeProjectInvoicingSettings_CreateArgs = {
  projectInvoicingSetting?: InputMaybe<ProjectInvoicingSettingInputType>;
};


export type RootMutationTypeProjectInvoicingSettings_DeleteArgs = {
  projectInvoicingSettingId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeProjectInvoicingSettings_EditArgs = {
  projectInvoicingSetting?: InputMaybe<ProjectInvoicingSettingInputType>;
};


export type RootMutationTypeProjects_CreateArgs = {
  project: ProjectInputType;
};


export type RootMutationTypeProjects_DeleteArgs = {
  projectId: Scalars['MongoID']['input'];
};


export type RootMutationTypeProjects_EditArgs = {
  project: ProjectInputType;
};


export type RootMutationTypeResult_EnterMultipleArgs = {
  results?: InputMaybe<Array<InputMaybe<ResultInputType>>>;
};


export type RootMutationTypeResults_CreateArgs = {
  result: ResultInputType;
};


export type RootMutationTypeResults_DeleteArgs = {
  resultId: Scalars['MongoID']['input'];
};


export type RootMutationTypeResults_EditArgs = {
  result: ResultInputType;
};


export type RootMutationTypeRooms_CreateArgs = {
  room: RoomInputType;
};


export type RootMutationTypeRooms_DeleteArgs = {
  roomId: Scalars['MongoID']['input'];
};


export type RootMutationTypeRooms_EditArgs = {
  room: RoomInputType;
};


export type RootMutationTypeSessions_LoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  jwtToken?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeTimelineEvents_CreateArgs = {
  timelineEvent: TimelineEventInputType;
};


export type RootMutationTypeTimelineEvents_DeleteArgs = {
  timelineEventId: Scalars['MongoID']['input'];
};


export type RootMutationTypeTimelineEvents_EditArgs = {
  timelineEvent: TimelineEventInputType;
};


export type RootMutationTypeUserLearnerHours_CreateArgs = {
  userLearnerHour: UserLearnerHourInputType;
};


export type RootMutationTypeUserLearnerHours_DeleteArgs = {
  userLearnerHourId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUserLearnerHours_EditArgs = {
  userLearnerHour: UserLearnerHourInputType;
};


export type RootMutationTypeUserLearnerSignups_CreateArgs = {
  userLearnerSignup: UserLearnerSignupInputType;
};


export type RootMutationTypeUserLearnerSignups_DeleteArgs = {
  userLearnerSignupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUserLearnerSignups_EditArgs = {
  userLearnerSignup: UserLearnerSignupInputType;
};


export type RootMutationTypeUsers_ActivateInvoicingByDuoArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_AddDocumentsArgs = {
  documents?: InputMaybe<Array<InputMaybe<UserDocumentInputType>>>;
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_ChangeEmailArgs = {
  newEmail?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeUsers_ChangeStatusArgs = {
  userId?: InputMaybe<Scalars['MongoID']['input']>;
  userShouldBeDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUsers_Change_PasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeUsers_CreateArgs = {
  user: UserInputType;
};


export type RootMutationTypeUsers_CreateExportFileArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<UsersFilterInputType>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeUsers_CreateLessonAttendanceFileArgs = {
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_CreateLessonInviteFileArgs = {
  forGroupUserIds: Array<InputMaybe<Scalars['MongoID']['input']>>;
  freeText?: InputMaybe<Scalars['String']['input']>;
  freeTextEnglish?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['MongoID']['input'];
  groupInviteTemplateId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_CreateLessonTimetableFileArgs = {
  groupId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_DeleteArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_EditArgs = {
  user: UserInputType;
};


export type RootMutationTypeUsers_Forgot_PasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeUsers_ImportArgs = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeUsers_ImportFromEdisaFileArgs = {
  edisaImport: EdisaImportInputType;
};


export type RootMutationTypeUsers_InflowOnHoldRemoveArgs = {
  userIds: Array<InputMaybe<Scalars['MongoID']['input']>>;
};


export type RootMutationTypeUsers_InflowOnHoldSetArgs = {
  userIds: Array<InputMaybe<Scalars['MongoID']['input']>>;
};


export type RootMutationTypeUsers_RemoveDocumentsArgs = {
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_ResetCognitoTotpMfaArgs = {
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeUsers_ResetIntakeArgs = {
  learnerUserId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_SelectContextUserAsIntakerArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_SendAttendanceReminderArgs = {
  teacherUserId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_SendInviteArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_SendLessonInvitesArgs = {
  forGroupUserIds: Array<InputMaybe<Scalars['MongoID']['input']>>;
  freeText?: InputMaybe<Scalars['String']['input']>;
  freeTextEnglish?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['MongoID']['input'];
  groupInviteTemplateId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_SetCognitoSmsMfaArgs = {
  mobile?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeUsers_SetMfaFieldsArgs = {
  cognitoMobileNumber?: InputMaybe<Scalars['String']['input']>;
  cognitoUserId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type RootMutationTypeUsers_UpdateEnrollmentDataArgs = {
  enrollmentDate?: InputMaybe<Scalars['Date']['input']>;
  enrollmentType?: InputMaybe<LearnerEnrollmentTypeEnum>;
  learnerUserId: Scalars['MongoID']['input'];
};


export type RootMutationTypeUsers_UpdateInternalDuoDeclarationsArgs = {
  userId: Scalars['MongoID']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  DUOFinalExamResultErrors?: Maybe<Array<Maybe<DuoFinalExamResultErrorType>>>;
  DUOFinalExamResultErrors_count?: Maybe<Scalars['Int']['output']>;
  DUOFinalExamResultErrors_lastImport?: Maybe<Array<Maybe<DuoFinalExamResultErrorType>>>;
  DUOInvoices?: Maybe<Array<Maybe<DuoInvoiceType>>>;
  DUOInvoicesAvailable?: Maybe<Scalars['Int']['output']>;
  DUOLearnerReports?: Maybe<Array<Maybe<DuoLearnerReportType>>>;
  DUOResponses?: Maybe<Array<Maybe<DuoResponseType>>>;
  addresses_usedCities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  calendarEvents?: Maybe<Array<Maybe<CalendarEventType>>>;
  courseMaterials?: Maybe<Array<Maybe<CourseMaterialType>>>;
  edisaImports_lastImport?: Maybe<EdisaImportType>;
  exams?: Maybe<Array<Maybe<ExamType>>>;
  exports?: Maybe<Array<Maybe<ExportType>>>;
  exports_lastDates?: Maybe<ExportLastDatesType>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  fullDutchLocationByStreetAndZipcode?: Maybe<FullDutchLocation>;
  groupInviteTemplates?: Maybe<Array<Maybe<GroupInviteTemplateType>>>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  inflowInviteTemplates?: Maybe<Array<Maybe<InflowInviteTemplateType>>>;
  inflowModules?: Maybe<Array<Maybe<InflowModuleType>>>;
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
  inflowMoments_validate?: Maybe<ValidationResponseType>;
  info?: Maybe<InfoType>;
  instructionVideos?: Maybe<Array<Maybe<InstructionVideoType>>>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  locationProperties?: Maybe<Array<Maybe<LocationPropertyType>>>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  modules?: Maybe<Array<Maybe<ModuleType>>>;
  notes?: Maybe<Array<Maybe<NoteType>>>;
  organizations?: Maybe<Array<Maybe<OrganizationType>>>;
  paginatedInvoices?: Maybe<PaginatedInvoiceType>;
  paginatedUsers?: Maybe<PaginatedUserType>;
  plannedAbilityExams?: Maybe<Array<Maybe<PlannedAbilityExamType>>>;
  process?: Maybe<Array<Maybe<ProcessType>>>;
  prognosisYears?: Maybe<Array<Maybe<PrognosisYearsType>>>;
  programs?: Maybe<Array<Maybe<ProgramType>>>;
  projects?: Maybe<Array<Maybe<ProjectType>>>;
  revenueYears?: Maybe<Array<Maybe<RevenueYearType>>>;
  statistics?: Maybe<StatisticsType>;
  twinfield_article?: Maybe<TwinfieldArticleType>;
  twinfield_customer?: Maybe<TwinfieldCustomerType>;
  user?: Maybe<UserType>;
  users?: Maybe<Array<Maybe<UserType>>>;
};


export type RootQueryTypeDuoFinalExamResultErrorsArgs = {
  includeResolved?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeDuoFinalExamResultErrors_CountArgs = {
  includeResolved?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootQueryTypeDuoFinalExamResultErrors_LastImportArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeDuoInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeDuoLearnerReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeDuoResponsesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeAddresses_UsedCitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeAdviceReportsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  byInflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  byLearnerUserId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeCalendarEventsArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeCourseMaterialsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeExamsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeExportsArgs = {
  filterByDownloadedEnd?: InputMaybe<Scalars['Date']['input']>;
  filterByDownloadedStart?: InputMaybe<Scalars['Date']['input']>;
  filterByDownloadedUserId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByGeneratedEnd?: InputMaybe<Scalars['Date']['input']>;
  filterByGeneratedStart?: InputMaybe<Scalars['Date']['input']>;
  filterByType?: InputMaybe<ExportTypeEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFinalExamsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  byUserId?: InputMaybe<Scalars['MongoID']['input']>;
  filters?: InputMaybe<FinalExamsFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFullDutchLocationByStreetAndZipcodeArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
  numberAddition?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeGroupInviteTemplatesArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  moduleId?: InputMaybe<Scalars['MongoID']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeGroupsArgs = {
  filters?: InputMaybe<GroupsFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeInflowInviteTemplatesArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  inflowModuleId?: InputMaybe<Scalars['MongoID']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeInflowModulesArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByInUse?: InputMaybe<Scalars['Boolean']['input']>;
  filterByLocked?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeInflowMomentsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filters?: InputMaybe<InflowMomentFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeInflowMoments_ValidateArgs = {
  inflowMomentId: Scalars['MongoID']['input'];
};


export type RootQueryTypeInstructionVideosArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  byRole?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeInvoicesArgs = {
  filters?: InputMaybe<InvoicesFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeLocationPropertiesArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeLocationsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeModulesArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterByFinalExamTraining?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<ModulesFiltersInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeNotesArgs = {
  filters?: InputMaybe<NotesFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeOrganizationsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypePaginatedInvoicesArgs = {
  filters?: InputMaybe<InvoicesFilterInputType>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypePaginatedUsersArgs = {
  filters?: InputMaybe<UsersFilterInputType>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypePlannedAbilityExamsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByExamId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByGroupId?: InputMaybe<Scalars['MongoID']['input']>;
  filterByLessonId?: InputMaybe<Scalars['MongoID']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeProcessArgs = {
  userId: Scalars['MongoID']['input'];
};


export type RootQueryTypePrognosisYearsArgs = {
  years?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type RootQueryTypeProgramsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  filters?: InputMaybe<ProgramsFiltersInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeProjectsArgs = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeRevenueYearsArgs = {
  years: Array<Scalars['Int']['input']>;
};


export type RootQueryTypeTwinfield_ArticleArgs = {
  twinfieldArticleCode: Scalars['String']['input'];
};


export type RootQueryTypeTwinfield_CustomerArgs = {
  twinfieldCustomerCode: Scalars['String']['input'];
};


export type RootQueryTypeUsersArgs = {
  filters?: InputMaybe<UsersFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type SessionType = {
  __typename?: 'SessionType';
  _id: Scalars['MongoID']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  singleUse?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

export type SignatureFileType = {
  __typename?: 'SignatureFileType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
};

export type StatisticsType = {
  __typename?: 'StatisticsType';
  emailBounces?: Maybe<Array<EmailBounceType>>;
};

export type TeacherInputType = {
  NT2Certificates?: InputMaybe<Array<InputMaybe<FilesInputType>>>;
  NT2CertifiedDate?: InputMaybe<Scalars['Date']['input']>;
  companyCocNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyVatNumber?: InputMaybe<Scalars['String']['input']>;
  hasOwnCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isNT2Certified?: InputMaybe<Scalars['Boolean']['input']>;
  lessonCompetence?: InputMaybe<Scalars['Boolean']['input']>;
  lessonCompetenceCertificates?: InputMaybe<Array<InputMaybe<FilesInputType>>>;
  lessonCompetenceDate?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentAddress?: InputMaybe<AddressInputType>;
  paymentIban?: InputMaybe<Scalars['String']['input']>;
};

export type TimelineEventInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  owner?: InputMaybe<TimelineEventOwnerInputType>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeData?: InputMaybe<TimelineEventTypeDataInputType>;
};

export type TimelineEventLogTypeDataType = {
  __typename?: 'TimelineEventLogTypeDataType';
  invoice?: Maybe<InvoiceType>;
};

export enum TimelineEventLogTypeEnum {
  InvoicePaymentPaid = 'INVOICE_PAYMENT_PAID',
  InvoiceReminderSent = 'INVOICE_REMINDER_SENT',
  InvoiceSent = 'INVOICE_SENT'
}

export type TimelineEventOwnerInputType = {
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targets?: InputMaybe<Array<TimelineEventOwnerTargetEnum>>;
  type?: InputMaybe<TimelineEventOwnerTypeEnum>;
  typeData?: InputMaybe<TimelineEventOwnerTypeDataInputType>;
};

export enum TimelineEventOwnerTargetEnum {
  GroupTeacher = 'GROUP_TEACHER',
  LearnerIntaker = 'LEARNER_INTAKER',
  OrganizationContact = 'ORGANIZATION_CONTACT',
  RelatedOrganizationContact = 'RELATED_ORGANIZATION_CONTACT',
  Teacher = 'TEACHER'
}

export type TimelineEventOwnerType = {
  __typename?: 'TimelineEventOwnerType';
  targets?: Maybe<Array<TimelineEventOwnerTargetEnum>>;
  type?: Maybe<TimelineEventOwnerTypeEnum>;
};

export type TimelineEventOwnerTypeDataInputType = {
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId?: InputMaybe<Scalars['MongoID']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum TimelineEventOwnerTypeEnum {
  LearnerUser = 'LEARNER_USER',
  Organization = 'ORGANIZATION',
  TeacherUser = 'TEACHER_USER'
}

export type TimelineEventType = {
  __typename?: 'TimelineEventType';
  _id: Scalars['MongoID']['output'];
  createdByUser?: Maybe<UserType>;
  date?: Maybe<Scalars['Date']['output']>;
  isLog?: Maybe<Scalars['Boolean']['output']>;
  logType?: Maybe<TimelineEventLogTypeEnum>;
  logTypeData?: Maybe<TimelineEventLogTypeDataType>;
  owner?: Maybe<TimelineEventOwnerType>;
  type?: Maybe<Scalars['String']['output']>;
  typeData?: Maybe<TimelineEventTypeDataType>;
};

export type TimelineEventTypeDataInputType = {
  body?: InputMaybe<Scalars['String']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TimelineEventTypeDataType = {
  __typename?: 'TimelineEventTypeDataType';
  body?: Maybe<Scalars['String']['output']>;
};

export type TwinfieldArticleType = {
  __typename?: 'TwinfieldArticleType';
  KPL?: Maybe<Scalars['String']['output']>;
  PNL?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type TwinfieldCustomerType = {
  __typename?: 'TwinfieldCustomerType';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UserDocumentInputType = {
  file?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UserDocumentType = {
  __typename?: 'UserDocumentType';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UserEmployeeType = {
  __typename?: 'UserEmployeeType';
  groups?: Maybe<Array<Maybe<GroupType>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
};


export type UserEmployeeTypeGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type UserImportDuplicateReturnType = {
  __typename?: 'UserImportDuplicateReturnType';
  Aanmelding?: Maybe<Scalars['String']['output']>;
  Achternaam?: Maybe<Scalars['String']['output']>;
  Afgerond?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Geaccepteerd?: Maybe<Scalars['String']['output']>;
  Gestart?: Maybe<Scalars['String']['output']>;
  Huisnr?: Maybe<Scalars['String']['output']>;
  Klantnr?: Maybe<Scalars['String']['output']>;
  Plaats?: Maybe<Scalars['String']['output']>;
  Postcode?: Maybe<Scalars['String']['output']>;
  Straat?: Maybe<Scalars['String']['output']>;
  Telefoon?: Maybe<Scalars['String']['output']>;
  Tussenvoegsel?: Maybe<Scalars['String']['output']>;
  Voornaam?: Maybe<Scalars['String']['output']>;
};

export type UserInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeInputType>;
  learner?: InputMaybe<LearnerInputType>;
  mutableRoles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationContact?: InputMaybe<OrganizationContactInputType>;
  profile?: InputMaybe<ProfileInputType>;
  teacher?: InputMaybe<TeacherInputType>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UserIntakerType = {
  __typename?: 'UserIntakerType';
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
};

export enum UserIntegrationLawTypeEnum {
  Law2013 = 'law2013',
  Law2021 = 'law2021'
}

export type UserLearnerEdisaType = {
  __typename?: 'UserLearnerEdisaType';
  edisaData?: Maybe<Scalars['String']['output']>;
};

export type UserLearnerFinalExamsFilterInputType = {
  byActivatable?: InputMaybe<Scalars['Boolean']['input']>;
  byExempt?: InputMaybe<Scalars['Boolean']['input']>;
  byHasResult?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserLearnerHourDescriptionEnum {
  External = 'EXTERNAL',
  ELearning = 'E_LEARNING',
  Ona = 'ONA'
}

export type UserLearnerHourInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  amountOfHours?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<UserLearnerHourDescriptionEnum>;
  document?: InputMaybe<Scalars['UploadedFile']['input']>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UserLearnerHourType = {
  __typename?: 'UserLearnerHourType';
  _id: Scalars['MongoID']['output'];
  amountOfHours?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdByUser?: Maybe<UserType>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<UserLearnerHourTypeDocumentType>;
  userId?: Maybe<Scalars['MongoID']['output']>;
};

export type UserLearnerHourTypeDocumentType = {
  __typename?: 'UserLearnerHourTypeDocumentType';
  _id: Scalars['MongoID']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  isPDF: Scalars['Boolean']['output'];
};

export type UserLearnerHoursSummaryType = {
  __typename?: 'UserLearnerHoursSummaryType';
  hoursAlfa?: Maybe<Scalars['Int']['output']>;
  hoursCitizenship?: Maybe<Scalars['Int']['output']>;
  hoursCitizenshipOfWhichONA?: Maybe<Scalars['Int']['output']>;
  hoursOther?: Maybe<Scalars['Int']['output']>;
  hoursOtherOfWhichONA?: Maybe<Scalars['Int']['output']>;
  hoursTotal?: Maybe<Scalars['Int']['output']>;
};

export type UserLearnerOrganizationType = {
  __typename?: 'UserLearnerOrganizationType';
  organization?: Maybe<OrganizationType>;
  organizationContactUser?: Maybe<UserType>;
  organizationFileNumber?: Maybe<Scalars['String']['output']>;
};

export enum UserLearnerSignupEnrollmentTypeEnum {
  Assessment = 'assessment',
  Intake = 'intake'
}

export type UserLearnerSignupGraphqlType = {
  __typename?: 'UserLearnerSignupGraphqlType';
  _id: Scalars['MongoID']['output'];
  enrollmentType: UserLearnerSignupEnrollmentTypeEnum;
  signupDate: Scalars['Date']['output'];
};

export type UserLearnerSignupInputType = {
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  enrollmentType?: InputMaybe<UserLearnerSignupEnrollmentTypeEnum>;
  nulls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  signupDate?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['MongoID']['input']>;
};

export type UserLearnerSignupType = {
  __typename?: 'UserLearnerSignupType';
  _id: Scalars['MongoID']['output'];
  enrollmentType: UserLearnerSignupEnrollmentTypeEnum;
  signupDate: Scalars['Date']['output'];
};

export type UserLearnerType = {
  __typename?: 'UserLearnerType';
  DUODeclarations?: Maybe<Array<Maybe<DuoDeclarationType>>>;
  NT2?: Maybe<Array<Maybe<Nt2Type>>>;
  NT2HasAttempted?: Maybe<Scalars['Boolean']['output']>;
  NT2Info?: Maybe<Scalars['String']['output']>;
  activatableGroupParticipationInvoices?: Maybe<Array<Maybe<ActivatableGroupParticipationInvoicesType>>>;
  adviceModule?: Maybe<ModuleType>;
  adviceModuleFilterByProgramId?: Maybe<Scalars['MongoID']['output']>;
  adviceModuleFilterByProjectId?: Maybe<Scalars['MongoID']['output']>;
  adviceModuleInfo?: Maybe<Scalars['String']['output']>;
  adviceReports?: Maybe<Array<Maybe<AdviceReportType>>>;
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser?: Maybe<Scalars['Boolean']['output']>;
  canTwinfieldCustomerCodeChange?: Maybe<Scalars['Boolean']['output']>;
  computerSkill?: Maybe<Scalars['String']['output']>;
  contractCount?: Maybe<Scalars['Int']['output']>;
  contracts?: Maybe<Array<Maybe<ContractType>>>;
  currentOccupationFunction?: Maybe<Scalars['String']['output']>;
  currentOccupationMainLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  currentOccupationResponsibilities?: Maybe<Scalars['String']['output']>;
  currentOccupationStartDate?: Maybe<PrecisionDateType>;
  currentOccupationVocalInteraction?: Maybe<Scalars['String']['output']>;
  currentOccupationVocalInteractionInfo?: Maybe<Scalars['String']['output']>;
  currentOccupationVocalInteractionQuality?: Maybe<Scalars['String']['output']>;
  eLearningHours?: Maybe<Scalars['Int']['output']>;
  edisa?: Maybe<UserLearnerEdisaType>;
  educationDesire?: Maybe<Scalars['String']['output']>;
  educationExtraInfo?: Maybe<Scalars['String']['output']>;
  englishAsSupportLanguage?: Maybe<Scalars['String']['output']>;
  enrollmentDate?: Maybe<Scalars['Date']['output']>;
  enrollmentType?: Maybe<LearnerEnrollmentTypeEnum>;
  examDignityDate?: Maybe<Scalars['Date']['output']>;
  familyCompositionLanguageExposure?: Maybe<Scalars['String']['output']>;
  finalExamNeeded?: Maybe<FinalExamNeededType>;
  finalExams?: Maybe<Array<Maybe<FinalExamType>>>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  hasInflowMomentsAfterEnrollmentDate?: Maybe<Scalars['Boolean']['output']>;
  hasPrivatePayment?: Maybe<Scalars['Boolean']['output']>;
  highestEducationCountryOfOrigin?: Maybe<Scalars['String']['output']>;
  highestEducationCountryOfOriginYear?: Maybe<Scalars['Int']['output']>;
  highestEducationLocal?: Maybe<Scalars['String']['output']>;
  highestEducationLocalYear?: Maybe<Scalars['Int']['output']>;
  homeLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hours?: Maybe<Array<Maybe<UserLearnerHourType>>>;
  hoursPresent?: Maybe<Scalars['Int']['output']>;
  hoursSummary?: Maybe<UserLearnerHoursSummaryType>;
  howDoYouKnowTopTaal?: Maybe<Scalars['String']['output']>;
  immigratedAt?: Maybe<PrecisionDateType>;
  inflowMoments?: Maybe<Array<Maybe<InflowMomentType>>>;
  inflowMomentsNoShowStreak?: Maybe<Scalars['Int']['output']>;
  intakeDate?: Maybe<Scalars['Date']['output']>;
  intakeGoals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  intakeGoalsAdditionalInfo?: Maybe<Scalars['String']['output']>;
  intakeGrades?: Maybe<InflowLevelsType>;
  intakeGradesLearnability?: Maybe<Scalars['Int']['output']>;
  intakeLiteracy?: Maybe<Scalars['String']['output']>;
  intakeLiteracyNativeLanguage?: Maybe<Scalars['String']['output']>;
  intakeMotivation?: Maybe<Scalars['String']['output']>;
  intakeOtherGoal?: Maybe<Scalars['String']['output']>;
  intakeReadingExamScore?: Maybe<Scalars['Int']['output']>;
  intakeReadingExamScoreTotal?: Maybe<Scalars['Int']['output']>;
  intakeResultsInfo?: Maybe<Scalars['String']['output']>;
  integrationLawType?: Maybe<UserIntegrationLawTypeEnum>;
  invoices?: Maybe<Array<Maybe<InvoiceType>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAlreadyInInflowMoment?: Maybe<Scalars['Boolean']['output']>;
  isAvailableForInflow?: Maybe<Scalars['Boolean']['output']>;
  isCitizenshipEducationObliged?: Maybe<Scalars['Boolean']['output']>;
  isInflowOnHold?: Maybe<Scalars['Boolean']['output']>;
  isIntegrationCourse?: Maybe<Scalars['Boolean']['output']>;
  isInvoicingByDUOActive?: Maybe<Scalars['Boolean']['output']>;
  isWaitingForInflow?: Maybe<Scalars['Boolean']['output']>;
  latestAbilityLevels?: Maybe<LatestAbilityLevelsType>;
  latestInternalDUODeclarationsUpdateDate?: Maybe<Scalars['Date']['output']>;
  learningRoute?: Maybe<UserLearningRouteTypesEnum>;
  legacyIntakeGrades?: Maybe<LegacyInflowLevelsType>;
  nativeLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  obligedCitizenshipEducationCompletedAt?: Maybe<Scalars['Date']['output']>;
  obligedCitizenshipEducationCompletedByImport?: Maybe<Scalars['Boolean']['output']>;
  obligedCitizenshipEducationEndDate?: Maybe<Scalars['Date']['output']>;
  obligedCitizenshipEducationEndDateExtensions?: Maybe<Array<Maybe<ObligedCitizenshipEducationEndDateExtensiontype>>>;
  obligedCitizenshipEducationInitialEndDate?: Maybe<Scalars['Date']['output']>;
  obligedCitizenshipEducationStartDate?: Maybe<PrecisionDateType>;
  oldFileNumber?: Maybe<Scalars['String']['output']>;
  organizations?: Maybe<Array<Maybe<UserLearnerOrganizationType>>>;
  otherLanguages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  paymentBy?: Maybe<UserPaymentByTypeEnum>;
  preferredLocations?: Maybe<Array<Maybe<LocationType>>>;
  privatePaymentAddress?: Maybe<AddressType>;
  privatePaymentDUOHasApprovedFund?: Maybe<Scalars['Boolean']['output']>;
  privatePaymentDUOInitialRemainingFund?: Maybe<Scalars['Float']['output']>;
  privatePaymentDUORemainingLifeFund?: Maybe<Scalars['Float']['output']>;
  privatePaymentIsDUO?: Maybe<Scalars['Boolean']['output']>;
  sharePanteia?: Maybe<UserSharePanteiaTypesEnum>;
  signups: Array<UserLearnerSignupGraphqlType>;
  socialStatus?: Maybe<Scalars['String']['output']>;
  socialStatusExtraInfo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  targetLevelPIP?: Maybe<UserTargetLevelTypesEnum>;
  totalAttendanceRate?: Maybe<Scalars['Float']['output']>;
  twinfieldCustomer?: Maybe<TwinfieldCustomerType>;
  typeOfLearner?: Maybe<UserTypeOfLearnerTypesEnum>;
  workExperienceCountryOfOrigin?: Maybe<Scalars['String']['output']>;
  workExperienceLocal?: Maybe<Scalars['String']['output']>;
  workLiteracy?: Maybe<Scalars['String']['output']>;
  workLiteracyInfo?: Maybe<Scalars['String']['output']>;
  workLiteracyLevel?: Maybe<Scalars['String']['output']>;
};


export type UserLearnerTypeDuoDeclarationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type UserLearnerTypeContractsArgs = {
  filterByActiveAndNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  filterByUnsignedForGroupId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type UserLearnerTypeFinalExamsArgs = {
  filters?: InputMaybe<UserLearnerFinalExamsFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type UserLearnerTypeGroupsArgs = {
  filterByActivatable?: InputMaybe<Scalars['Boolean']['input']>;
  filterByActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterByGroupIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLearnerTypeHoursArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type UserLearnerTypeInvoicesArgs = {
  filters?: InputMaybe<InvoicesFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export enum UserLearningRouteTypesEnum {
  B1 = 'B1',
  B1Intensive = 'B1Intensive',
  Education = 'Education',
  Z = 'Z'
}

export type UserOrganizationContactType = {
  __typename?: 'UserOrganizationContactType';
  hasAcceptedAgreement?: Maybe<Scalars['Boolean']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<OrganizationType>;
};

export enum UserPaymentByTypeEnum {
  FamilyMigrant = 'FamilyMigrant',
  Organization = 'Organization',
  Private = 'Private',
  SelfReporter = 'SelfReporter'
}

export type UserProfileType = {
  __typename?: 'UserProfileType';
  address?: Maybe<AddressType>;
  /** @deprecated This has been replaced by countryOfBirth and cityOfBirth */
  addressOfBirth?: Maybe<AddressType>;
  alternativeEmail?: Maybe<Scalars['String']['output']>;
  bsn?: Maybe<Scalars['String']['output']>;
  bsnVerified?: Maybe<Scalars['Boolean']['output']>;
  cityOfBirth?: Maybe<Scalars['String']['output']>;
  cognitoUserId?: Maybe<Scalars['String']['output']>;
  countriesOfNationality?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  countryOfOrigin?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullSurName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  hasNoCountryOfNationality?: Maybe<Scalars['Boolean']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  isMfaConfigured?: Maybe<Scalars['Boolean']['output']>;
  mfaMobileNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAbbreviation?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  redundantAddressCity?: Maybe<Scalars['String']['output']>;
  surName?: Maybe<Scalars['String']['output']>;
  surNamePrefix?: Maybe<Scalars['String']['output']>;
  timelineEvents?: Maybe<Array<TimelineEventType>>;
  visitAddress?: Maybe<AddressType>;
};


export type UserProfileTypeTimelineEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserSharePanteiaTypesEnum {
  Ja = 'Ja',
  Nee = 'Nee',
  Onbekend = 'Onbekend',
  Unset = 'Unset'
}

export enum UserTargetLevelTypesEnum {
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2'
}

export type UserTeacherAvailability = {
  __typename?: 'UserTeacherAvailability';
  availabilityAfternoon: Availability;
  availabilityEvening: Availability;
  availabilityMorning: Availability;
  date: Scalars['String']['output'];
};

export type UserTeacherType = {
  __typename?: 'UserTeacherType';
  NT2Certificates?: Maybe<Array<Maybe<Nt2CertificateFileType>>>;
  NT2CertifiedDate?: Maybe<Scalars['Date']['output']>;
  availability?: Maybe<Array<Maybe<UserTeacherAvailability>>>;
  availabilityCalendarEvents?: Maybe<Array<CalendarEventType>>;
  companyCocNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyVatNumber?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Maybe<GroupType>>>;
  hasOwnCompany?: Maybe<Scalars['Boolean']['output']>;
  intakes?: Maybe<Array<UserType>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isNT2Certified?: Maybe<Scalars['Boolean']['output']>;
  lessonCompetence?: Maybe<Scalars['Boolean']['output']>;
  lessonCompetenceCertificates?: Maybe<Array<Maybe<LessonCompetenceCertificateFileType>>>;
  lessonCompetenceDate?: Maybe<Scalars['Date']['output']>;
  paymentAddress?: Maybe<AddressType>;
  paymentIban?: Maybe<Scalars['String']['output']>;
};


export type UserTeacherTypeAvailabilityArgs = {
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type UserTeacherTypeGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTeacherTypeIntakesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type UserType = {
  __typename?: 'UserType';
  _id: Scalars['MongoID']['output'];
  documents?: Maybe<Array<Maybe<UserDocumentType>>>;
  email?: Maybe<Scalars['String']['output']>;
  emailForCommunication?: Maybe<Scalars['String']['output']>;
  employee?: Maybe<UserEmployeeType>;
  hasExpiredInvoices?: Maybe<Scalars['Boolean']['output']>;
  hasInvoicingEnabled?: Maybe<Scalars['Boolean']['output']>;
  intaker?: Maybe<UserIntakerType>;
  invitedAt?: Maybe<Scalars['Date']['output']>;
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isEmployee?: Maybe<Scalars['Boolean']['output']>;
  isExternalTeacher?: Maybe<Scalars['Boolean']['output']>;
  isFinancial?: Maybe<Scalars['Boolean']['output']>;
  isIntaker?: Maybe<Scalars['Boolean']['output']>;
  isLearner?: Maybe<Scalars['Boolean']['output']>;
  isOrganizationContact?: Maybe<Scalars['Boolean']['output']>;
  lastActivityAt?: Maybe<Scalars['Date']['output']>;
  learner?: Maybe<UserLearnerType>;
  mayHaveNewInvoices?: Maybe<Scalars['Boolean']['output']>;
  organizationContact?: Maybe<UserOrganizationContactType>;
  passwordResetRequired?: Maybe<Scalars['Boolean']['output']>;
  profile?: Maybe<UserProfileType>;
  results?: Maybe<Array<Maybe<ResultType>>>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  teacher?: Maybe<UserTeacherType>;
};


export type UserTypeDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir?: InputMaybe<Scalars['String']['input']>;
};


export type UserTypeResultsArgs = {
  filterByPlannedAbilityExamId?: InputMaybe<Scalars['MongoID']['input']>;
};


export type UserTypeRolesArgs = {
  filterMutable?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserTypeOfLearnerTypesEnum {
  Integration2013 = 'Integration2013',
  Integration2021 = 'Integration2021',
  Other = 'Other',
  Webnt1 = 'WEBNT1',
  Webnt2 = 'WEBNT2'
}

export type UsersFilterInputType = {
  byId?: InputMaybe<Scalars['MongoID']['input']>;
  everyRoleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeUsersInInflowMomentInOtherSlots?: InputMaybe<ExcludeUsersInInflowMomentIdInputObject>;
  filterActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterByAdviceModuleIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByCityNames?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByContactOrganizationIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByEnrolledGroupIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByEnrollmentDateFrom?: InputMaybe<Scalars['Date']['input']>;
  filterByEnrollmentDateTo?: InputMaybe<Scalars['Date']['input']>;
  filterByEnrollmentTypeAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  filterByEnrollmentTypeIntake?: InputMaybe<Scalars['Boolean']['input']>;
  filterByFinalExamNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  filterByGroupIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByHasNoInflowMomentsAfterEnrollmentDate?: InputMaybe<Scalars['Boolean']['input']>;
  filterByIsAvailableForInflow?: InputMaybe<Scalars['Boolean']['input']>;
  filterByLearnabiltyMax?: InputMaybe<Scalars['Int']['input']>;
  filterByLearnabiltyMin?: InputMaybe<Scalars['Int']['input']>;
  filterByOneOfMutableRoles?: InputMaybe<Array<Scalars['String']['input']>>;
  filterByOrganizationContactUserIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByOrganizationIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByPreferredLocationIds?: InputMaybe<Array<Scalars['MongoID']['input']>>;
  filterByRoleInGroup?: InputMaybe<Scalars['String']['input']>;
  filterByTeacherHasOwnCompany?: InputMaybe<Scalars['Boolean']['input']>;
  filterEmployeeActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterLearnerActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterLearnerStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  filterOrganizationContactActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterTeacherActive?: InputMaybe<Scalars['Boolean']['input']>;
  filterTeacherLessonCompetence?: InputMaybe<Scalars['Boolean']['input']>;
  filterTeacherNT2Certification?: InputMaybe<Scalars['Boolean']['input']>;
  finalExamNeededFilters?: InputMaybe<FinalExamsNeededFilterInputType>;
  inflowMomentCandidateTableSort?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type ValidationErrorType = {
  __typename?: 'ValidationErrorType';
  key?: Maybe<Scalars['String']['output']>;
  stringifiedInfo?: Maybe<Scalars['String']['output']>;
};

export type ValidationResponseType = {
  __typename?: 'ValidationResponseType';
  errors?: Maybe<Array<Maybe<ValidationErrorType>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum Availability {
  Full = 'FULL',
  None = 'NONE',
  Partial = 'PARTIAL'
}

export type ExcludeUsersInInflowMomentIdInputObject = {
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
  inflowMomentTimeslotId?: InputMaybe<Scalars['MongoID']['input']>;
};

export enum InflowMomentTimeslotsAddType {
  After = 'after',
  Before = 'before'
}

export type AdviceReportsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  inflowMomentId?: InputMaybe<Scalars['MongoID']['input']>;
}>;


export type AdviceReportsQuery = { __typename?: 'RootQueryType', adviceReports?: Array<{ __typename?: 'AdviceReportType', _id: any, createdAt?: any | null, updatedAt?: any | null, releasedAt?: any | null, levelReading?: LearnerLevelWithAlphaEnum | null, levelWriting?: LearnerLevelWithAlphaEnum | null, levelListening?: LearnerLevelEnum | null, levelTalking?: LearnerLevelEnum | null, levelConversations?: LearnerLevelEnum | null, file?: { __typename?: 'ExportResponseType', fileId?: string | null } | null, learnerUser?: { __typename?: 'UserType', _id: any, profile?: { __typename?: 'UserProfileType', name?: string | null } | null } | null, intakerUser?: { __typename?: 'UserType', _id: any, roles?: Array<string | null> | null, profile?: { __typename?: 'UserProfileType', name?: string | null } | null } | null, inflowMoment?: { __typename?: 'InflowMomentType', _id: any, inflowModule?: { __typename?: 'InflowModuleType', _id: any, checkAdviceReportNeeded?: boolean | null } | null } | null } | null> | null };


export const AdviceReportsDocument = gql`
    query adviceReports($skip: Int, $limit: Int, $inflowMomentId: MongoID) {
  adviceReports(skip: $skip, limit: $limit, byInflowMomentId: $inflowMomentId) {
    _id
    file {
      fileId
    }
    learnerUser {
      _id
      profile {
        name
      }
    }
    intakerUser {
      _id
      roles
      profile {
        name
      }
    }
    createdAt
    updatedAt
    releasedAt
    levelReading
    levelWriting
    levelListening
    levelTalking
    levelConversations
    inflowMoment {
      _id
      inflowModule {
        _id
        checkAdviceReportNeeded
      }
    }
  }
}
    `;

/**
 * __useAdviceReportsQuery__
 *
 * To run a query within a React component, call `useAdviceReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdviceReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdviceReportsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      inflowMomentId: // value for 'inflowMomentId'
 *   },
 * });
 */
export function useAdviceReportsQuery(baseOptions?: Apollo.QueryHookOptions<AdviceReportsQuery, AdviceReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdviceReportsQuery, AdviceReportsQueryVariables>(AdviceReportsDocument, options);
      }
export function useAdviceReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdviceReportsQuery, AdviceReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdviceReportsQuery, AdviceReportsQueryVariables>(AdviceReportsDocument, options);
        }
export function useAdviceReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdviceReportsQuery, AdviceReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdviceReportsQuery, AdviceReportsQueryVariables>(AdviceReportsDocument, options);
        }
export type AdviceReportsQueryHookResult = ReturnType<typeof useAdviceReportsQuery>;
export type AdviceReportsLazyQueryHookResult = ReturnType<typeof useAdviceReportsLazyQuery>;
export type AdviceReportsSuspenseQueryHookResult = ReturnType<typeof useAdviceReportsSuspenseQuery>;
export type AdviceReportsQueryResult = Apollo.QueryResult<AdviceReportsQuery, AdviceReportsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ProcessItemType": [
      "AdviceReportType",
      "FinalExamType",
      "GroupType"
    ]
  }
};
      export default result;
    