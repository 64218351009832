import './RevenueTableArticleRow.scss'

import c from 'classnames'
import * as React from 'react'

import { TableRow } from '~/components'

interface Props {
    className?: string
}

export class RevenueTableArticleRow extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <TableRow className={this.getClassName()}>{children}</TableRow>
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-RevenueTableArticleRow', className)
    }
}
