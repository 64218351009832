import React from 'react'
import { Button } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isLoading?: any
    hasCancelSaveBtns?: boolean
    submitBtnText?: string
    cancelBtnText?: string
    close?: () => void
}

export default class DrawerFooter extends React.Component<Props> {
    private bem = new BEM('DrawerFooter')

    public render() {
        const {
            className,
            close,
            isLoading,
            children,
            submitBtnText = 'Opslaan',
            cancelBtnText = 'Annuleer',
            hasCancelSaveBtns = true,
        } = this.props

        if (hasCancelSaveBtns) {
            if (!close) {
                throw new Error('You must provide `close` when you have a close/cancel button.')
            }
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {hasCancelSaveBtns && (
                    <List horizontal>
                        <ListItem right>
                            <Button onClick={close}>{cancelBtnText}</Button>
                        </ListItem>
                        <ListItem right>
                            <Button type={`submit`} isLoading={isLoading}>
                                {submitBtnText}
                            </Button>
                        </ListItem>
                    </List>
                )}
                {children}
            </div>
        )
    }
}
