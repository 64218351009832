import React from 'react'
import { get } from 'lodash'
import { Fetcher } from '~/utils'
import { translateType } from '~/shared/utils'
import emitter from '~/services/event'
import { welcomeModalSettings } from '~/services/localStorageSettings'
import {
    AddressFieldGroup,
    CheckBox,
    Field,
    FieldCollection,
    Header,
    ReadableAddress,
    ReadablePhoneNumbers,
    Spinner,
    Subtle,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { FieldGroup } from '~/components/FieldGroup'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Wrap } from '~/components/Wrap'
import { RouteComponentProps } from 'react-router'
import { User } from '~/types/User'
import { GET_CURRENT_USER_QUERY } from './graphql/currentUserQuery'

interface Props extends RouteComponentProps<{}, {}> {}

// TODO: needs proper typing
interface CurrentUserFetcherDataAnyType {
    user: User
}

export default class ExternalTeacherProfile extends React.Component<Props> {
    public isMounting: boolean
    public currentUserFetcher: Fetcher<CurrentUserFetcherDataAnyType>
    public unsubscribeFromStorageEvent: Function
    public state = {
        settingIgnoreWelcomeModal: false,
    }

    public constructor(props: Props) {
        super(props)

        this.isMounting = true
        this.currentUserFetcher = new Fetcher<CurrentUserFetcherDataAnyType>({
            query: GET_CURRENT_USER_QUERY,

            preventInitialFetch: true,
            onChange: () => {
                if (!this.isMounting) {
                    this.forceUpdate()
                }
            },
        })

        this.currentUserFetcher.fetch({}).then(data => {
            // When the localstorage welcome modal settings change we want to update the settings on
            // the profile page. Otherwise changes made in the welcome modal won't be
            // reflected here unless a page refresh occurs.
            const event = `localstorage.toptaal:ignoreWelcomeModalForUser:${get(data, 'user._id')}`
            const listener = (value: boolean) => {
                this.setState({
                    settingIgnoreWelcomeModal: value,
                })
            }
            this.unsubscribeFromStorageEvent = () => emitter.off(event, listener)
            emitter.on(event, listener)

            this.setState({
                settingIgnoreWelcomeModal: welcomeModalSettings.isIgnoredForUser(get(data, 'user')),
            })
        })
    }

    public componentDidMount() {
        this.isMounting = false
    }

    public componentWillUnmount() {
        if (this.unsubscribeFromStorageEvent) {
            this.unsubscribeFromStorageEvent()
        }
    }

    public render() {
        const { ...routeProps } = this.props
        const { data, loading } = this.currentUserFetcher
        const { user } = data

        return (
            <RouteView
                crumbLabel={user && `Welkom, ${get(user.profile, 'name')}`}
                routeProps={routeProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap>
                        {loading && <Spinner />}
                        {!loading && !user && <Subtle>Gebruiker niet gevonden</Subtle>}
                        {!loading && user && this.renderPage(user)}
                    </Wrap>
                </ContentView>
            </RouteView>
        )
    }

    private renderPage(user: User) {
        const phoneNumbers = get(user, `profile.phoneNumbers`) || []
        const { settingIgnoreWelcomeModal } = this.state

        return (
            <Wrap>
                <FieldCollection>
                    <FieldGroup title={`Persoonlijke gegevens`}>
                        <Field title={`Naam`}>{user.profile.name}</Field>
                        <Field title={`Geslacht`}>{translateType('userGender', user.profile.gender)}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Contactgegevens`}>
                        <Field title={`Telefoonnummer`}>
                            <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                        </Field>
                        <Field title={`Primair e-mailadres`}>{user.email}</Field>
                        <Field title={`Secundair e-mailadres`}>{user.profile.alternativeEmail}</Field>
                        <AddressFieldGroup user={user} />
                    </FieldGroup>
                    <FieldGroup title={`Betalingsgegevens`}>
                        <Field title="Adresgegevens als factuuradres gebruiken">
                            {get(user, 'teacher.paymentAddress') ? 'Nee' : 'Ja'}
                        </Field>
                        {user.teacher && user.teacher.paymentAddress && (
                            <Field title={`Factuuradres`}>
                                <ReadableAddress address={user.teacher.paymentAddress} showCountry={true} />
                            </Field>
                        )}
                        <Field title="IBAN">{get(user, 'teacher.paymentIban')}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Bedrijfgegevens`}>
                        <Field title={`Is freelancer`}>
                            {user.teacher && user.teacher.hasOwnCompany ? 'Ja' : 'Nee'}
                        </Field>
                        {user.teacher && user.teacher.hasOwnCompany && (
                            <>
                                <Field title={`Bedrijfsnaam`}>{get(user, 'teacher.companyName')}</Field>
                                <Field title={`KvK-nummer`}>{get(user, 'teacher.companyCocNumber')}</Field>
                                <Field title={`BTW-nummer`}>{get(user, 'teacher.companyVatNumber')}</Field>
                            </>
                        )}
                    </FieldGroup>
                    <FieldGroup title={`Overige`}>
                        <Field title={`Functie(s)`}>{this.renderUserRoles(user)}</Field>
                        <Field title={`Gecertificeerd NT2 docent?`}>
                            {get(user, 'teacher.isNT2Certified') ? 'Ja' : 'Nee'}
                        </Field>
                        <Field title={`Notities`}>{get(user, 'profile.notes')}</Field>
                        <Field title={`Status`}>{get(user, `teacher.isActive`) ? 'Actief' : 'Non-actief'}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Instellingen`}>
                        <Field title={`Welkom melding`}>
                            <CheckBox onClick={this.welcomeSettingClickHandler} checked={settingIgnoreWelcomeModal}>
                                <span>Niet meer weergeven</span>
                            </CheckBox>
                        </Field>
                    </FieldGroup>
                </FieldCollection>
            </Wrap>
        )
    }

    private renderUserRoles(user: User) {
        if (user.roles && user.roles.length > 0) {
            return user.roles.map(role => translateType('mutableRole', role)).join(', ')
        }

        return ''
    }

    private welcomeSettingClickHandler = () => {
        const user = this.currentUserFetcher.data.user
        const { settingIgnoreWelcomeModal } = this.state

        this.setState({ settingIgnoreWelcomeModal: !settingIgnoreWelcomeModal }, () => {
            if (settingIgnoreWelcomeModal) {
                welcomeModalSettings.disableForUser(user)
            } else {
                welcomeModalSettings.enableForUser(user)
            }
        })
    }
}
