import { User } from './User'
import { InflowModule } from './InflowModule'
import { Organization } from './Organization'
import { Location, Room } from './Location'
import { InflowInviteTemplate } from './InflowInviteTemplate'

export enum InflowMomentTimeslotsAddType {
    After = 'after',
    Before = 'before',
}

export enum InflowMomentInviteType {
    Manual = 'Manual',
    Email = 'Email',
}

export interface InflowMomentDateRange {
    from: Date
    to: Date
}

export interface InflowMomentsProjectedTimeslot {
    number: number
    start: Date
    end: Date
    duration: number
}

export enum InflowMomentTimeslotUserStatus {
    Present = 'Present',
    NoShow = 'NoShow',
}

export interface InflowMomentTimeslotUser {
    _id: string
    user: User
    status: InflowMomentTimeslotUserStatus
}

export interface InflowMomentTimeslot {
    _id: string
    startTime: Date
    endTime: Date
    duration: number
    timeslotUsers: InflowMomentTimeslotUser[]
}

export interface InflowMomentProjectTimeslots {
    totalPlaces: number
    timeslots: InflowMomentsProjectedTimeslot[]
}

export interface InflowMomentInvite {
    _id: string
    user: User
    date: Date
    type: InflowMomentInviteType
    inflowInviteTemplate?: InflowInviteTemplate
    invitedByUser?: User
}

export interface ConceptInflowMoment {
    _id: string
    createdAt?: Date
    createdByUser: User
    hasStarted: boolean
    isConcept: boolean
    inflowModule?: InflowModule // can be deleted
    isForOrganization: boolean
    organization?: Organization
    location: Location
    locationRoomsOrganized: boolean
    locationRoomsConversation: Room[]
    amountOfSlotsPerTimeslot: number
    locationRoomsGeneral: Room[]
    dateRange: InflowMomentDateRange
    intakerUsers: User[]
    employeeUsers: User[]
    candidateCountTotal: number
    candidateCountUsed: number
    timeslotsProjected: InflowMomentProjectTimeslots
    timeslots: InflowMomentTimeslot[]
    canHaveNewTimeslots?: boolean
    minimumTeachersNeeded?: number
    candidates: User[]
    latestInvites?: InflowMomentInvite[]
    candidatesInviteNeededCount?: number
    hasUnreleasedAdviceReports?: boolean
}

export interface InflowMoment extends ConceptInflowMoment {
    inflowModule: InflowModule // must be defined
}
