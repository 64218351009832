import { gql } from '@apollo/client'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { browserHistory } from 'react-router'

import {
    ActionBar,
    AddressFieldGroup,
    Button,
    Currency,
    EdisaData,
    Field,
    FieldCollection,
    Link,
    Placeholder,
    ReadableAddress,
    ReadableAddressCountry,
    ReadableDate,
    ReadableLanguage,
    ReadablePhoneNumbers,
    ReadablePrecisionDate,
    Spinner,
    Subtle,
} from '~/components'
import { List } from '~/components/List'
import { ValidationError } from '~/components/ValidationError'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { Wrap } from '~/components/Wrap'
import { LevelText } from '~/components/users/LevelText'
import { PdfModal } from '~/components/PdfModal'
import { translateType } from '~/shared/utils'
import { array2list, downloadFile, Fetcher, Mutator, nl2br, toast } from '~/utils'
import { AddressFieldsFragment } from '~/types/Address'
import { Translator } from '~/services/i18n'
import { IntegrationLawTypes } from '~/components/User/Learner/UserLearnerAddInvoicingForm'

export default class LearnerProfileData extends Component {
    translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })
    static propTypes = {
        learnerUserId: PropTypes.string.isRequired,
        getRouteForUserId: PropTypes.func,
        hideFieldsForGroupTeacher: PropTypes.bool,
        hideFieldsForIntakeTeacher: PropTypes.bool,
        hideFieldsForOrganizationContact: PropTypes.bool,
    }

    static defaultProps = {
        getRouteForUserId: userId => `/users/learners/${userId}/edit`,
        hideFieldsForGroupTeacher: false,
        hideFieldsForIntakeTeacher: false,
        hideFieldsForOrganizationContact: false,
    }

    constructor(props) {
        super(props)

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: props.learnerUserId,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.selectCurrentUserAsIntakerMutator = new Mutator({
            mutation: SELECT_CURRENT_USER_AS_INTAKER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    getUser() {
        const { data } = this.learnerFetcher
        return get(data, 'users[0]')
    }

    getUserUrl() {
        const user = this.getUser()
        return this.props.getRouteForUserId(user._id)
    }

    render() {
        const { loading } = this.learnerFetcher
        const user = this.getUser()

        if (loading || !user) {
            return <Spinner />
        }

        return this.renderWithUser(user)
    }

    renderWithUser(user) {
        const { t } = this.translator
        const { hideFieldsForGroupTeacher, hideFieldsForIntakeTeacher, hideFieldsForOrganizationContact } = this.props
        const learnerOrganizations = get(user.learner, 'organizations')
        const learnerAdviceModule = get(user.learner, 'adviceModule')
        const preferredLocations = get(user, 'learner.preferredLocations')
        const intakeGoals = get(user, 'learner.intakeGoals') || []
        const dateOfBirth = get(user, 'profile.dateOfBirth')
        const hasPrivatePayment = get(user, 'learner.hasPrivatePayment')
        const paymentBy = get(user, 'learner.paymentBy')
        const isIntegrationCourse = get(user, 'learner.isIntegrationCourse')
        const integrationLawType = get(user, 'learner.integrationLawType')
        const isCitizenshipEducationObliged = get(user.learner, 'isCitizenshipEducationObliged')
        const targetLevelPIP = get(user.learner, 'targetLevelPIP')
        const learningRoute = get(user.learner, 'learningRoute')
        const typeOfLearner = get(user.learner, 'typeOfLearner')
        const privatePaymentIsDUO = hasPrivatePayment && get(user, 'learner.privatePaymentIsDUO')
        const privatePaymentAddress =
            hasPrivatePayment && !privatePaymentIsDUO && get(user, 'learner.privatePaymentAddress')
        const phoneNumbers = get(user, 'profile.phoneNumbers') || []

        const hasNoCountryOfNationality = get(user, `profile.hasNoCountryOfNationality`)
        const countriesOfNationality = !hasNoCountryOfNationality ? get(user, `profile.countriesOfNationality`) : 'Geen'

        const showIntakeOtherGoal = (get(user, 'learner.intakeGoals') || []).includes('Anders ...')

        const twinfieldCustomer = get(user, 'learner.twinfieldCustomer')

        const canAccessModule =
            !hideFieldsForGroupTeacher && !hideFieldsForIntakeTeacher && !hideFieldsForOrganizationContact
        const canAccessLocation =
            !hideFieldsForGroupTeacher && !hideFieldsForIntakeTeacher && !hideFieldsForOrganizationContact
        const canAccessUsers =
            !hideFieldsForGroupTeacher && !hideFieldsForIntakeTeacher && !hideFieldsForOrganizationContact
        const canAccessOrganization =
            !hideFieldsForGroupTeacher && !hideFieldsForIntakeTeacher && !hideFieldsForOrganizationContact
        const canAccessBSN = !hideFieldsForGroupTeacher && !hideFieldsForOrganizationContact
        const bsn = get(user.profile, 'bsn')
        const bsnVerififedText = !!get(user, 'profile.bsnVerified')
            ? 'BSN gezien op identiteitsbewijs'
            : 'BSN nog niet gezien op identiteitsbewijs'
        const obligedCitizenshipEducationEndDateExtensions = get(
            user,
            'learner.obligedCitizenshipEducationEndDateExtensions'
        )
        const obligedCitizenshipEducationEndDateHasExtensions = !!obligedCitizenshipEducationEndDateExtensions

        return (
            <Wrap>
                <FieldCollection>
                    {/**
                     * Name + gender
                     */}
                    <FieldGroup title="Kandidaat">
                        <Field title="Naam">{get(user.profile, 'name')}</Field>
                        <Field title="Geslacht">{translateType('userGender', get(user.profile, 'gender'))}</Field>
                        <Field title="Type aanmelding">
                            {translateType('enrollmentType', get(user.learner, 'enrollmentType'))}
                        </Field>
                        <Field title="Datum aanmelding">
                            {user.learner.enrollmentDate && <ReadableDate date={user.learner.enrollmentDate} />}
                        </Field>
                    </FieldGroup>

                    {/**
                     * Organization details
                     */}
                    {!hideFieldsForGroupTeacher && (
                        <FieldGroup title="Organisaties">
                            {learnerOrganizations &&
                                learnerOrganizations.map(
                                    ({ organization, organizationContactUser, organizationFileNumber }, i) => (
                                        <Field title={`Organisatie #${i + 1}`} key={i}>
                                            {organization && (
                                                <React.Fragment>
                                                    <div>
                                                        <Subtle isBlockElement={true}>Naam</Subtle>
                                                        {canAccessOrganization ? (
                                                            <Link
                                                                route={`/properties/organizations/${organization._id}`}
                                                            >
                                                                {organization.name}
                                                            </Link>
                                                        ) : (
                                                            organization.name
                                                        )}
                                                    </div>
                                                    {organizationContactUser && (
                                                        <div>
                                                            <Subtle isBlockElement={true}>Contactpersoon</Subtle>
                                                            {canAccessUsers ? (
                                                                <Link
                                                                    route={`/users/organization-contacts/${organizationContactUser._id}`}
                                                                >
                                                                    {organizationContactUser.profile.name}
                                                                </Link>
                                                            ) : (
                                                                organizationContactUser.profile.name
                                                            )}
                                                        </div>
                                                    )}
                                                    {organizationFileNumber && (
                                                        <div>
                                                            <Subtle isBlockElement={true}>Dossiernummer</Subtle>
                                                            {organizationFileNumber}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </Field>
                                    )
                                )}
                        </FieldGroup>
                    )}

                    {/**
                     * Target Group - new group
                     */}
                    {!hideFieldsForGroupTeacher && (
                        <FieldGroup title={`Doelgroep`}>
                            <Field title="Type kandidaat">{translateType('typeOfLearner', typeOfLearner)}</Field>
                            {(integrationLawType === IntegrationLawTypes.law2021 ||
                                integrationLawType === IntegrationLawTypes.law2013) && (
                                <Field title={`Verplicht inburgeren`}>
                                    {get(user.learner, 'isCitizenshipEducationObliged') ? 'Ja' : 'Nee'}
                                </Field>
                            )}
                            {get(user.learner, 'isCitizenshipEducationObliged') && (
                                <Fragment>
                                    <Field title={`Start inburgeringsplicht`}>
                                        <ReadablePrecisionDate
                                            precisionDate={get(user.learner, 'obligedCitizenshipEducationStartDate')}
                                        />
                                    </Field>
                                    <Field title={`Einde inburgeringsplicht`}>
                                        <ReadableDate
                                            date={get(user.learner, 'obligedCitizenshipEducationInitialEndDate')}
                                        />
                                    </Field>
                                    {obligedCitizenshipEducationEndDateHasExtensions &&
                                        obligedCitizenshipEducationEndDateExtensions.map(
                                            this.renderObligedCitizenshipEndDateExtension
                                        )}
                                    <Field title={`Voldaan op`}>
                                        <ReadableDate
                                            date={get(user.learner, 'obligedCitizenshipEducationCompletedAt')}
                                        />
                                    </Field>
                                </Fragment>
                            )}
                            {integrationLawType === IntegrationLawTypes.law2021 && isCitizenshipEducationObliged && (
                                <>
                                    <Field title="Leerroute">{translateType('learningRoute', learningRoute)}</Field>
                                    <Field title="Doelniveau PIP">
                                        {translateType('targetLevelPIP', targetLevelPIP)}
                                    </Field>
                                </>
                            )}
                            {canAccessBSN &&
                                bsn &&
                                (integrationLawType === IntegrationLawTypes.law2021 ||
                                    integrationLawType === IntegrationLawTypes.law2013) && (
                                    <>
                                        <Field title="BSN">{bsn}</Field>
                                        <Field title="Gegevens op ID gecheckt">
                                            {get(user, 'profile.bsnVerified') ? 'Ja' : 'Nee'}
                                        </Field>
                                    </>
                                )}
                        </FieldGroup>
                    )}

                    {/**
                     * Payment Details
                     */}
                    {!hideFieldsForGroupTeacher && (
                        <FieldGroup title={`Betalingsgegevens`}>
                            <Field title="Inburgeringscursus">{isIntegrationCourse ? 'Ja' : 'Nee'}</Field>
                            {isIntegrationCourse && (
                                <Field title="Inburgeringswet">
                                    {translateType('integrationLawType', integrationLawType)}
                                </Field>
                            )}
                            <Field title="Betaling">{translateType('paymentBy', paymentBy)}</Field>

                            {hasPrivatePayment && (
                                <Fragment>
                                    <Field title="Type">{privatePaymentIsDUO ? 'DUO' : 'Zelfbetaler'}</Field>
                                    {privatePaymentIsDUO && (
                                        <Field title="Lening goedgekeurd">
                                            {get(user, 'learner.privatePaymentDUOHasApprovedFund') ? 'Ja' : 'Nee'}
                                        </Field>
                                    )}
                                    {privatePaymentIsDUO && get(user, 'learner.privatePaymentDUOHasApprovedFund') && (
                                        <Field title="Leenruimte bij aanmelding">
                                            <Currency
                                                amount={get(user, 'learner.privatePaymentDUOInitialRemainingFund') || 0}
                                            />
                                        </Field>
                                    )}
                                    {privatePaymentIsDUO && get(user, 'learner.privatePaymentDUOHasApprovedFund') && (
                                        <Field title="Resterende leenruimte">
                                            <Currency
                                                amount={get(user, 'learner.privatePaymentDUORemainingLifeFund') || 0}
                                            />
                                        </Field>
                                    )}
                                    {twinfieldCustomer && (
                                        <Field title="Twinfield debiteurnummer">
                                            {twinfieldCustomer.code} ({twinfieldCustomer.name})
                                        </Field>
                                    )}
                                    {!privatePaymentIsDUO && (
                                        <Fragment>
                                            <Field title="Factuuradres">
                                                {privatePaymentAddress ? (
                                                    <ReadableAddress address={privatePaymentAddress} showCountry />
                                                ) : (
                                                    <Placeholder>Zelfde als woonadres</Placeholder>
                                                )}
                                            </Field>
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                        </FieldGroup>
                    )}

                    {/**
                     * Contact Details
                     */}
                    <FieldGroup title="Contactgegevens">
                        <Field title="Telefoonnummer">
                            <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                        </Field>
                        <Field title="Primair e-mailadres">{user.email}</Field>
                        <Field title="Secundair e-mailadres">{get(user.profile, 'alternativeEmail')}</Field>
                        {!hideFieldsForGroupTeacher && <AddressFieldGroup user={user} />}
                    </FieldGroup>

                    {/**
                     * Personal Details
                     */}
                    <FieldGroup title="Persoonlijke gegevens">
                        <Field title="Nationaliteit(en)">
                            <ReadableAddressCountry country={countriesOfNationality} />
                        </Field>
                        <Field title="Geboortedatum">
                            {dateOfBirth && <ReadableDate date={moment(dateOfBirth).toDate()} />}
                        </Field>
                        <Field title="Geboorteplaats">{get(user.profile, 'cityOfBirth')}</Field>
                        <Field title="Geboorteland">
                            <ReadableAddressCountry country={get(user.profile, 'countryOfBirth')} />
                        </Field>
                        <Field title="Land van herkomst">
                            <ReadableAddressCountry country={get(user.profile, 'countryOfOrigin')} />
                        </Field>
                    </FieldGroup>

                    {/**
                     * Background Details
                     */}
                    <FieldGroup title={`Achtergrondinformatie`}>
                        <Field title={`In Nederland sinds`}>
                            <ReadablePrecisionDate precisionDate={get(user.learner, 'immigratedAt')} />
                        </Field>
                        <Field title={`Moedertaal(en)`}>
                            <ReadableLanguage codes={get(user.learner, 'nativeLanguages')} />
                        </Field>

                        <Field title={`Overige talen`}>
                            <ReadableLanguage codes={get(user.learner, 'otherLanguages')} />
                        </Field>
                        <Field title={`Engels als steuntaal mogelijk?`}>
                            {get(user.learner, 'englishAsSupportLanguage')}
                        </Field>

                        <Field title={`Voertaal(en) thuis`}>
                            <ReadableLanguage codes={get(user.learner, 'homeLanguages')} />
                        </Field>

                        <Field title={`Werkervaring herkomstland`}>
                            {nl2br(get(user, 'learner.workExperienceCountryOfOrigin'))}
                        </Field>
                        <Field title={`Werkervaring Nederland (verleden)`}>
                            {nl2br(get(user, 'learner.workExperienceLocal'))}
                        </Field>
                        <Field title={`Maatschappelijke status`}>
                            {translateType('learnerSocialStatus', get(user, 'learner.socialStatus'))}
                        </Field>
                        {get(user, 'learner.socialStatus') === 'OTHER' && (
                            <Field title={`Toelichting maatschappelijke status`}>
                                {nl2br(get(user, 'learner.socialStatusExtraInfo'))}
                            </Field>
                        )}

                        {get(user, 'learner.socialStatus') === 'WORKING' && (
                            <div>
                                <Field title={`Startdatum huidig werk`}>
                                    <ReadablePrecisionDate
                                        precisionDate={get(user.learner, 'currentOccupationStartDate')}
                                    />
                                </Field>
                                <Field title={`Huidig werk functie`}>
                                    {get(user.learner, 'currentOccupationFunction')}
                                </Field>
                                <Field title={`Taken/toelichting huidig werk`}>
                                    {nl2br(get(user.learner, 'currentOccupationResponsibilities'))}
                                </Field>
                                <Field title={`Voertaal(en) huidig werk`}>
                                    <ReadableLanguage codes={get(user.learner, 'currentOccupationMainLanguages')} />
                                </Field>
                                <Field title={`Nederlands lezen/schrijven op het werk`}>
                                    {get(user.learner, 'workLiteracy')}
                                </Field>
                                {get(user.learner, 'workLiteracy') === 'Ja' && (
                                    <div>
                                        <Field isIndented title={`Hoe gaat dat?`}>
                                            {get(user.learner, 'workLiteracyLevel')}
                                        </Field>
                                        <Field isIndented title={`Schriftelijke taken toelichting`}>
                                            {nl2br(get(user.learner, 'workLiteracyInfo'))}
                                        </Field>
                                    </div>
                                )}
                                <Field title={`Mondeling contact met leidingevende/collega's`}>
                                    {get(user.learner, 'currentOccupationVocalInteraction')}
                                </Field>
                                {get(user.learner, 'currentOccupationVocalInteraction') === 'Ja' && (
                                    <div>
                                        <Field isIndented title={`Hoe gaat dat?`}>
                                            {get(user.learner, 'currentOccupationVocalInteractionQuality')}
                                        </Field>
                                        <Field isIndented title={`Mondeling contact toelichting`}>
                                            {nl2br(get(user.learner, 'currentOccupationVocalInteractionInfo'))}
                                        </Field>
                                    </div>
                                )}
                            </div>
                        )}

                        <Field title={`Nederlands taalcontact`}>
                            {get(user.learner, 'familyCompositionLanguageExposure')}
                        </Field>
                    </FieldGroup>

                    {/**
                     * Education Details
                     */}
                    <FieldGroup title={`Opleiding`}>
                        <Field title={`Hoogste opleiding herkomst`}>{this.renderHighestEducationOrigin(user)}</Field>
                        <Field title={`Hoogste opleiding Nederland`}>{this.renderHighestEducationLocal(user)}</Field>
                        <Field title={`Opleidingswens`}>{get(user, 'learner.educationDesire')}</Field>
                        <Field title={`Toelichting opleiding`}>{nl2br(get(user, 'learner.educationExtraInfo'))}</Field>
                        <Field title={`Computervaardig`}>{get(user, 'learner.computerSkill')}</Field>
                        <Field title={`Al eerder NT2 gevolgd?`}>
                            {get(user, 'learner.NT2HasAttempted') ? 'Ja' : 'Nee'}
                        </Field>
                        {user.learner.NT2HasAttempted && (
                            <Fragment>
                                {(get(user, 'learner.NT2') || []).map((nt2, index) => {
                                    return (
                                        <Field title={`NT2 cursus #${index + 1}`} key={index}>
                                            {nt2.year && (
                                                <div>
                                                    <Subtle isBlockElement>Jaar</Subtle>
                                                    {nt2.year}
                                                </div>
                                            )}
                                            {nt2.duration && (
                                                <div>
                                                    <Subtle isBlockElement>Duur</Subtle>
                                                    {nt2.duration}
                                                </div>
                                            )}
                                            {nt2.level && (
                                                <div>
                                                    <Subtle isBlockElement>Niveau</Subtle>
                                                    {translateType('level', nt2.level)}
                                                </div>
                                            )}
                                            {nt2.organizationName && (
                                                <div>
                                                    <Subtle isBlockElement>Organisatie</Subtle>
                                                    {nt2.organizationName}
                                                </div>
                                            )}
                                            {nt2.courseMaterials && (
                                                <div>
                                                    <Subtle isBlockElement>Lesmateriaal</Subtle>
                                                    {nt2.courseMaterials}
                                                </div>
                                            )}
                                        </Field>
                                    )
                                })}
                                <Field title={`Toelichting eerdere NT2 cursus(sen)`}>
                                    {nl2br(get(user, 'learner.NT2Info'))}
                                </Field>
                            </Fragment>
                        )}
                    </FieldGroup>

                    {/**
                     * Additional Intake Details
                     */}
                    <FieldGroup title={`Intakeresultaten`}>
                        <Field title={`Gealfabetiseerd?`}>{get(user, 'learner.intakeLiteracy')}</Field>
                        {['Nee', 'Twijfel'].includes(get(user, 'learner.intakeLiteracy')) && (
                            <Field title={`Gealfabetiseerd in eigen taal?`}>
                                {get(user, 'learner.intakeLiteracyNativeLanguage')}
                            </Field>
                        )}
                        <Field title={`Gespreksvaardigheid niveau`}>
                            <LevelText level={get(user, 'learner.intakeGrades.levelConversations')} />
                        </Field>
                        <Field title={`Spreken niveau`}>
                            <LevelText level={get(user, 'learner.intakeGrades.levelTalking')} />
                        </Field>
                        <Field title={`Schrijven niveau`}>
                            <LevelText level={get(user, 'learner.intakeGrades.levelWriting')} />
                        </Field>
                        <Field title={`Luisteren niveau`}>
                            <LevelText level={get(user, 'learner.intakeGrades.levelListening')} />
                        </Field>
                        <Field title={`Lezen niveau`}>
                            <LevelText level={get(user, 'learner.intakeGrades.levelReading')} />
                        </Field>
                        <Field title={`Score leestoets`}>
                            {(get(user, 'learner.intakeReadingExamScore') ||
                                get(user, 'learner.intakeReadingExamScoreTotal')) &&
                                `${get(user, 'learner.intakeReadingExamScore') || '?'} goed van de ${
                                    get(user, 'learner.intakeReadingExamScoreTotal') || '?'
                                }`}
                        </Field>
                        <Field title={`Leerbaarheid score`}>
                            {get(user, 'learner.intakeGradesLearnability')
                                ? `${get(user, 'learner.intakeGradesLearnability')}${t(
                                      'Users.Common.Leerbaarheid.suffix'
                                  )}`
                                : ''}
                        </Field>
                        <Field title={`Motivatie`}>{get(user, 'learner.intakeMotivation')}</Field>
                        <Field title={`Doel taalcursus`}>{array2list(intakeGoals)}</Field>
                        {showIntakeOtherGoal && (
                            <Field title={`Anders, namelijk ...`}>{nl2br(get(user.learner, 'intakeOtherGoal'))}</Field>
                        )}
                        <Field title={`Toelichting doel taalcursus`}>
                            {nl2br(get(user.learner, 'intakeGoalsAdditionalInfo'))}
                        </Field>
                        <Field title={`Advies-lesmodule`}>
                            {learnerAdviceModule &&
                                (canAccessModule ? (
                                    <Link route={`/properties/modules/${learnerAdviceModule._id}`}>
                                        {learnerAdviceModule.name}
                                    </Link>
                                ) : (
                                    learnerAdviceModule.name
                                ))}
                            {learnerAdviceModule && !learnerAdviceModule.isActive && (
                                <ValidationError isWarning text={`Let op! Deze lesmodule is non-actief.`} />
                            )}
                        </Field>
                        <Field title={`Toelichting advies-lesmodule`}>
                            {nl2br(get(user, 'learner.adviceModuleInfo'))}
                        </Field>
                        <Field title={`Voorkeurslocatie(s)`}>
                            <List>
                                {preferredLocations &&
                                    preferredLocations.length > 0 &&
                                    preferredLocations.map(location => {
                                        if (canAccessLocation) {
                                            return (
                                                <ListItem key={location._id}>
                                                    <Link route={`/properties/locations/${location._id}`}>
                                                        {location.name}
                                                    </Link>
                                                </ListItem>
                                            )
                                        }

                                        return <ListItem key={location._id}>{location.name}</ListItem>
                                    })}
                            </List>
                        </Field>
                        <Field title={`Toelichting intakeresultaten`}>
                            {nl2br(get(user, 'learner.intakeResultsInfo'))}
                        </Field>
                    </FieldGroup>

                    {/**
                     * Other Details
                     */}
                    {!hideFieldsForGroupTeacher && (
                        <FieldGroup title={`Overige`}>
                            {!hideFieldsForOrganizationContact && (
                                <Field title={`Hoe ben je bij TopTaal gekomen?`}>
                                    {get(user, 'learner.howDoYouKnowTopTaal')}
                                </Field>
                            )}
                            {!hideFieldsForOrganizationContact && (
                                <Field title={`Notities`}>{nl2br(get(user, `profile.notes`))}</Field>
                            )}
                            <Field title={`Status`}>{get(user, `learner.isActive`) ? 'Actief' : 'Non-actief'}</Field>
                            <Field title={`Toestemming gegevensdeling met Panteia`}>
                                {get(user, `learner.sharePanteia`)}
                            </Field>
                        </FieldGroup>
                    )}

                    {/**
                     * Edisa Details
                     */}
                    {!hideFieldsForGroupTeacher && get(user, 'learner.edisa.edisaData') && (
                        <FieldGroup title={`Edisa`}>
                            {get(user.learner, 'oldFileNumber') && (
                                <Field title={`Dossiernummer vóór import`}>{user.learner.oldFileNumber}</Field>
                            )}
                            <Field title={`Gegevens`}>
                                <EdisaData dataString={user.learner.edisa.edisaData} />
                            </Field>
                        </FieldGroup>
                    )}
                </FieldCollection>
                {!hideFieldsForGroupTeacher && this.renderFooter()}
            </Wrap>
        )
    }

    renderObligedCitizenshipEndDateExtension = (obligedCitizenshipEndDateExtension, i) => {
        const { date, description, createdAt, document } = obligedCitizenshipEndDateExtension
        const createdByUserName =
            obligedCitizenshipEndDateExtension.createdByUser &&
            obligedCitizenshipEndDateExtension.createdByUser.profile.name
        const hasDocument = !!document

        return (
            <Field title={`Einde inburgeringsplicht verlenging`} key={i}>
                <div>
                    <Subtle isBlockElement={true}>Datum</Subtle>
                    {<ReadableDate date={date} />}
                </div>
                <div>
                    <Subtle isBlockElement={true}>Beschrijving</Subtle>
                    {description}
                </div>
                <div>
                    <Subtle isBlockElement={true}>Document</Subtle>
                    {hasDocument && (
                        <React.Fragment>
                            {document.isPDF && (
                                <ModalManager
                                    render={openModal => (
                                        <Button linkStyle={`default`} onClick={openModal}>
                                            {document.fileName}
                                        </Button>
                                    )}
                                    getModal={closeModal => (
                                        <PdfModal
                                            title={document.fileName}
                                            fileName={document.fileName}
                                            getFileId={() => document._id}
                                            onClose={closeModal}
                                        />
                                    )}
                                />
                            )}
                            {!document.isPDF && (
                                <Button
                                    linkStyle={`default`}
                                    onClick={() => downloadFile(document._id, document.fileName)}
                                >
                                    {document.fileName}
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                    {!hasDocument && '-'}
                </div>
                <div>
                    <Subtle isBlockElement={true}>Aangemaakt</Subtle>
                    Door {createdByUserName} op <ReadableDate date={createdAt} />
                </div>
            </Field>
        )
    }

    renderFooter() {
        return (
            <ActionBar
                fixed
                getButtons={() => {
                    return (
                        <List horizontal>
                            <ListItem right>
                                <Button type={`edit`} onClick={() => browserHistory.push(this.getUserUrl())}>
                                    Bewerken
                                </Button>
                            </ListItem>
                        </List>
                    )
                }}
            />
        )
    }

    selectCurrentUserAsIntaker = async () => {
        const { learnerUserId } = this.props
        const { refetch } = this.learnerFetcher

        const res = await this.selectCurrentUserAsIntakerMutator.mutate({
            userId: learnerUserId,
        })

        if (res && res.users_selectContextUserAsIntaker) {
            toast.success('Intaker ingesteld')
            refetch()
        }
    }

    renderHighestEducationOrigin = user => {
        const highestEducationOrigin = get(user, 'learner.highestEducationCountryOfOrigin')

        if (this.cannotHaveYearOption(highestEducationOrigin)) {
            return highestEducationOrigin
        }

        const highestEducationOriginYear = get(user, 'learner.highestEducationCountryOfOriginYear')

        if (!highestEducationOriginYear) {
            return highestEducationOrigin
        }

        return `${highestEducationOrigin} (${highestEducationOriginYear} ${
            highestEducationOriginYear > 1 ? 'jaren' : 'jaar'
        })`
    }

    renderHighestEducationLocal = user => {
        const highestEducationLocal = get(user, 'learner.highestEducationLocal')

        if (this.cannotHaveYearOption(highestEducationLocal)) {
            return highestEducationLocal
        }

        const highestEducationLocalYear = get(user, 'learner.highestEducationLocalYear')

        if (!highestEducationLocalYear) {
            return highestEducationLocal
        }

        return `${highestEducationLocal} (${highestEducationLocalYear} ${
            highestEducationLocalYear > 1 ? 'jaren' : 'jaar'
        })`
    }

    cannotHaveYearOption(value) {
        return !value || value === '(Bijna) geen'
    }
}

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            isLearner
            profile {
                initials
                name
                firstName
                surName
                surNamePrefix
                givenName
                gender
                countriesOfNationality
                hasNoCountryOfNationality
                bsn
                bsnVerified
                dateOfBirth
                countryOfBirth
                cityOfBirth
                countryOfOrigin
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                address {
                    ...AddressFieldsFragment
                }
                visitAddress {
                    ...AddressFieldsFragment
                }
                notes
            }
            learner {
                enrollmentType
                enrollmentDate
                organizations {
                    organization {
                        _id
                        name
                    }
                    organizationContactUser {
                        _id
                        profile {
                            name
                        }
                    }
                    organizationFileNumber
                }
                hasPrivatePayment
                isIntegrationCourse
                integrationLawType
                typeOfLearner
                learningRoute
                targetLevelPIP
                paymentBy
                sharePanteia
                twinfieldCustomer {
                    code
                    name
                }
                privatePaymentIsDUO
                privatePaymentDUOHasApprovedFund
                privatePaymentDUOInitialRemainingFund
                privatePaymentDUORemainingLifeFund
                privatePaymentAddress {
                    ...AddressFieldsFragment
                }
                oldFileNumber
                immigratedAt {
                    date
                    precision
                }
                isCitizenshipEducationObliged
                obligedCitizenshipEducationStartDate {
                    date
                    precision
                }
                obligedCitizenshipEducationInitialEndDate
                obligedCitizenshipEducationEndDateExtensions {
                    _id
                    date
                    description
                    document {
                        _id
                        fileName
                        type
                        createdAt
                        createdByUser {
                            _id
                            profile {
                                name
                            }
                        }
                    }
                    createdAt
                    createdByUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
                obligedCitizenshipEducationCompletedAt
                socialStatus
                socialStatusExtraInfo
                familyCompositionLanguageExposure
                nativeLanguages
                homeLanguages
                otherLanguages
                englishAsSupportLanguage
                workExperienceCountryOfOrigin
                workExperienceLocal
                workLiteracy
                workLiteracyLevel
                workLiteracyInfo
                currentOccupationMainLanguages
                currentOccupationVocalInteraction
                currentOccupationVocalInteractionQuality
                currentOccupationVocalInteractionInfo
                currentOccupationStartDate {
                    date
                    precision
                }
                currentOccupationResponsibilities
                currentOccupationFunction
                highestEducationCountryOfOrigin
                highestEducationCountryOfOriginYear
                highestEducationLocal
                highestEducationLocalYear
                educationDesire
                educationExtraInfo
                computerSkill
                NT2HasAttempted
                NT2Info
                NT2 {
                    year
                    duration
                    level
                    organizationName
                    courseMaterials
                }
                intakeLiteracy
                intakeLiteracyNativeLanguage
                intakeReadingExamScore
                intakeReadingExamScoreTotal
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                intakeGradesLearnability
                adviceModule {
                    _id
                    name
                    isActive
                }
                adviceModuleFilterByProjectId
                adviceModuleFilterByProgramId
                adviceModuleInfo
                intakeGoals
                intakeOtherGoal
                intakeGoalsAdditionalInfo
                preferredLocations {
                    _id
                    name
                }
                intakeResultsInfo
                isActive
                howDoYouKnowTopTaal
                edisa {
                    edisaData
                }
            }
        }
    }
    ${AddressFieldsFragment}
`

const SELECT_CURRENT_USER_AS_INTAKER_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_selectContextUserAsIntaker(userId: $userId) {
            _id
        }
    }
`
