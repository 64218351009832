import './StatisticTable.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Table } from '~/components/Table'

interface Props {
    className?: ClassValue
}

export class StatisticTable extends React.Component<Props> {
    private bem = new BEM('StatisticTable')

    public render() {
        const { className, children } = this.props

        return <Table className={this.bem.getClassName(className)}>{children}</Table>
    }
}
