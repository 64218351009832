import * as React from 'react'

import { Paragraph, PageTabs, PageTab, Link, Icon } from '~/components'
import { Fetcher } from '~/utils'
import { gql } from '@apollo/client'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import Header from '~/components/Header'
import { ContentView } from '~/components/ContentView'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { getCurrentUser } from '~/services/session'
import { InflowMoment } from '~/types/InflowMoments'

const GET_INFLOW_MOMENTS_QUERY = gql`
    query _($_id: MongoID) {
        inflowMoments(byId: $_id) {
            _id
            createdAt
            createdByUser {
                _id
                profile {
                    name
                }
            }
            hasStarted
            inflowModule {
                _id
                name
                examMaxTimeMinutes
                examStartIntervalMinutes
                maxStudentsPerStart
                exams {
                    exam {
                        _id
                        name
                    }
                    examId
                    abilities {
                        converse
                        listen
                        read
                        talk
                        write
                    }
                }
            }
            isForOrganization
            organization {
                _id
                name
            }
            location {
                _id
                name
                rooms {
                    _id
                    capacity
                    name
                    locationProperties {
                        _id
                        name
                    }
                }
            }
            locationRoomsOrganized
            locationRoomsConversation {
                _id
                name
                capacity
            }
            locationRoomsGeneral {
                _id
                name
                capacity
            }
            dateRange {
                from
                to
            }
            timeslots {
                _id
                duration
                endTime
                startTime
                timeslotUsers {
                    _id
                    user {
                        _id
                        profile {
                            name
                            address {
                                _id
                                nl {
                                    extraInfo {
                                        city
                                    }
                                }
                            }
                        }
                        learner {
                            organizations {
                                organization {
                                    _id
                                    name
                                }
                            }
                            adviceReports {
                                _id
                                inflowMoment {
                                    _id
                                }
                                createdAt
                                levelReading
                                levelWriting
                                levelListening
                                levelTalking
                                levelConversations
                                releasedAt
                                isManuallyReleased
                            }
                        }
                    }
                    status
                }
            }
            intakerUsers {
                _id
                profile {
                    name
                    address {
                        _id
                        nl {
                            extraInfo {
                                city
                            }
                        }
                    }
                }
                roles
            }
            employeeUsers {
                _id
                profile {
                    name
                    address {
                        _id
                        nl {
                            extraInfo {
                                city
                            }
                        }
                    }
                }
                roles
            }
            amountOfSlotsPerTimeslot
            canHaveNewTimeslots
            minimumTeachersNeeded
            candidates {
                _id
                emailForCommunication
                profile {
                    name
                }
            }
            latestInvites {
                _id
                user {
                    _id
                }
                date
                type
                inflowInviteTemplate {
                    _id
                    name
                }
                invitedByUser {
                    profile {
                        name
                    }
                }
            }
            hasUnreleasedAdviceReports
            candidatesInviteNeededCount
        }
    }
`

interface RouteParams {
    id: string
    userId?: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: JSX.Element
}

export class DefinitiveInflowMomentsDetailView extends React.Component<Props, {}> {
    private inflowMomentFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inflowMomentFetcher = new Fetcher({
            query: GET_INFLOW_MOMENTS_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate(preIterationVariables: Props) {
        const { params } = this.props

        if (preIterationVariables.params.id !== params.id) {
            this.inflowMomentFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.inflowMomentFetcher

        const inflowMoment = data && data.inflowMoments && data.inflowMoments[0]

        return (
            <RouteView
                crumbLabel={inflowMoment && inflowMoment.inflowModule.name}
                routeProps={routeProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />

                    {this.isIntakeDetailActive() ? this.renderIntakeDetailPageTabs() : this.renderDefaultPageTabs()}
                </Header>
                <ContentView>
                    {inflowMoment && React.cloneElement(children, { inflowMoment, refetch })}
                    {!loading && !inflowMoment && <Paragraph>Toetsmoment niet gevonden</Paragraph>}
                </ContentView>
            </RouteView>
        )
    }

    private isIntakeDetailActive() {
        return !!this.props.params.userId
    }

    private renderIntakeDetailPageTabs() {
        const inflowMomentId = this.props.params.id
        const userId = this.props.params.userId
        const userBaseUrl = `/inflow-moments/definitive/${inflowMomentId}/intakes/${userId}`

        return (
            <PageTabs>
                <PageTab>
                    <Link onlyActiveOnIndex route={`${userBaseUrl}/timeline`}>
                        Correspondentie
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`${userBaseUrl}/availability`}>
                        Beschikbaarheid
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`${userBaseUrl}/documents`}>
                        Documenten
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`${userBaseUrl}`}>
                        Gegevens
                    </Link>
                </PageTab>
            </PageTabs>
        )
    }

    private renderDefaultPageTabs() {
        const inflowMomentId = this.props.params.id

        const { data } = this.inflowMomentFetcher
        const inflowMoment = data && data.inflowMoments && data.inflowMoments[0]

        return (
            <PageTabs>
                <PageTab>
                    <Link onlyActiveOnIndex={true} route={`/inflow-moments/definitive/${inflowMomentId}`}>
                        Tijdslots {this.getFlagIconForTimeslotsTab(inflowMoment)}
                    </Link>
                </PageTab>
                <PageTab>
                    <Link
                        onlyActiveOnIndex={true}
                        route={`/inflow-moments/definitive/${inflowMomentId}/advice-reports`}
                    >
                        Adviesrapporten {this.getFlagIconForAdviceReportsTab(inflowMoment)}
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex={true} route={`/inflow-moments/definitive/${inflowMomentId}/detail`}>
                        Gegevens
                    </Link>
                </PageTab>
            </PageTabs>
        )
    }

    private getFlagIconForTimeslotsTab(inflowMoment: InflowMoment) {
        const currentUser = getCurrentUser()
        const userCanSentInvites = currentUser.isEmployee || currentUser.isOrganizationContact

        if (inflowMoment && !!inflowMoment.candidatesInviteNeededCount && userCanSentInvites) {
            return <Icon name={`flag-error`} color={'#e85156'} />
        }

        return null
    }

    private getFlagIconForAdviceReportsTab(inflowMoment: InflowMoment) {
        const currentUser = getCurrentUser()

        if (inflowMoment && inflowMoment.hasUnreleasedAdviceReports && currentUser.isEmployee) {
            return <Icon name={`flag-error`} color={'#e85156'} />
        }

        return null
    }
}
