import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isWrapper?: boolean
}

export default class Hint extends React.Component<Props> {
    private bem = new BEM('Hint')

    public render() {
        const { children, className, isWrapper } = this.props
        const Component = isWrapper ? 'div' : 'p'

        return <Component className={this.bem.getClassName(className)}>{children}</Component>
    }
}
