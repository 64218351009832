import c from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Bold, ReadableDate } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { formatCurrency } from '~/utils'
import { InvoiceStatus, OpenInvoiceStatus, InvoicePaymentStatus } from '~/types/Invoice'

export class InvoiceLogsList extends Component {
    static propTypes = {
        className: PropTypes.string,
        logs: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }

    render() {
        const { logs } = this.props

        return (
            <SimpleTable className={this.getClassName()}>
                {logs.map((log, i) => (
                    <SimpleTableRow key={i}>
                        <SimpleTableCell isSubtle>
                            <ReadableDate date={log.createdAt} />
                        </SimpleTableCell>
                        <SimpleTableCell>
                            <Bold>{this.getLogMessage(log)}</Bold>
                            {log.triggeredByUser && ` door ${log.triggeredByUser.profile.name}`}
                        </SimpleTableCell>
                    </SimpleTableRow>
                ))}
            </SimpleTable>
        )
    }

    getLogMessage(log) {
        switch (log.type) {
            case 'createInvoice':
                return 'Factuur aangemaakt'
            case 'changeInvoice':
                return `Bedrag veranderd van € ${formatCurrency(log.typeData.previousAmount)} naar € ${formatCurrency(
                    log.typeData.newAmount
                )}`
            case 'createCreditInvoice':
                return 'Creditnota aangevraagd'
            case 'changeInvoiceStatus':
                switch (log.typeData.newInvoiceStatus) {
                    case InvoiceStatus.Draft:
                        return 'Factuur in concept gezet'
                    case InvoiceStatus.Final:
                        return 'Factuur definitief gemaakt'
                    case InvoiceStatus.Canceled:
                        return 'Factuur geannuleerd'
                }
            case 'changeOpenInvoiceStatus':
                switch (log.typeData.newOpenInvoiceStatus) {
                    case OpenInvoiceStatus.WaitingForPayment:
                        return 'Factuur is in afwachting van betaling'
                    case OpenInvoiceStatus.NeedsSignatureForDUO:
                        return 'Handtekening benodigd voor factuur'
                    case OpenInvoiceStatus.WaitingForDUOExportBatch:
                        return 'Factuur in afwachting van DUO batch'
                    case OpenInvoiceStatus.RejectedByDUO:
                        return `Factuur afgewezen door DUO${
                            log.typeData.newOpenInvoiceStatusInfo.responseByDUO
                                ? ` "${log.typeData.newOpenInvoiceStatusInfo.responseByDUO}"`
                                : ''
                        }`
                    case OpenInvoiceStatus.WaitingForDUOApproval:
                        return 'Factuur in wachting van DUO goedkeuring'
                }
            case 'changeInvoicePaymentStatus':
                switch (log.typeData.newInvoicePaymentStatus) {
                    case InvoicePaymentStatus.Open:
                        return 'Factuur openstaand'
                    case InvoicePaymentStatus.Paid:
                        return 'Factuur betaald'
                }
            case 'sendInvoice':
                return 'Factuur verstuurd'
            case 'markInvoiceAsFullyCredited':
                return 'Factuur geheel gecrediteerd'
            case 'markInvoiceAsPartlyCredited':
                return 'Factuur deels gecrediteerd'
            case 'sendInvoiceReminder':
                switch (log.typeData.reminderCount) {
                    case 1:
                        return 'Vervaldatum verstreken, automatische herinnering verstuurd'
                    default:
                        return `${log.typeData.reminderCount}e automatische herinnering verstuurd`
                }
            case 'signatureAddedToInvoice':
                return 'Handtekening geüpload'
            case 'signatureChangedForInvoice':
                return 'Handtekening gewijzigd'
            default:
                return 'Factuur veranderd'
        }

        return ''
    }

    getClassName() {
        const { className } = this.props

        return c('tt-InvoiceLogsList', className)
    }
}
