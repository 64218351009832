import React from 'react'
import TableCellDetail from '~/components/TableCellDetail'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import Link from '~/components/Link'
import Field from '~/components/Field'
import ReadablePhoneNumbers from '~/components/ReadablePhoneNumbers'
import Fetcher from '~/utils/Fetcher'
import { gql } from '@apollo/client'
import Spinner from '~/components/Spinner'
import { User, UserLearnerOrganization } from '~/types/User'
import { get } from 'lodash'
import reactJoin from '~/shared/utils/reactJoin'
import { Group } from '~/types/Group'
import { AddressFieldsFragment } from '~/types/Address'
import { ReadableAddress } from '~/components'

interface Props {
    userId: string
}

interface State {}

const USER_EXTRA_INFO_QUERY = gql`
    query users($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            profile {
                address {
                    ...AddressFieldsFragment
                }
                phoneNumbers {
                    _id
                    internationalPhoneNumber
                    info
                    country
                }
            }
            learner {
                activeGroups: groups(filterByActive: true) {
                    _id
                    name
                }
                organizations {
                    organization {
                        _id
                        name
                    }
                }
            }
        }
    }
    ${AddressFieldsFragment}
`

export class LearnerRowExpansion extends React.PureComponent<Props, State> {
    private extraUserInfoFetcher = new Fetcher({
        query: USER_EXTRA_INFO_QUERY,
        variables: {
            filters: {
                byId: this.props.userId,
            },
        },
        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { data, loading } = this.extraUserInfoFetcher

        if (loading) {
            return <Spinner size="small" />
        }

        const user = data && data.users && data.users[0]

        if (!user) {
            return null
        }

        return this.renderUserInfo(user)
    }

    private renderUserInfo(user: User) {
        const activeGroups: Group[] = get(user, 'learner.activeGroups') || []
        const organizations: UserLearnerOrganization[] = (user && user.learner && user.learner.organizations) || []

        return (
            <TableCellDetail
                getActions={() => (
                    <List horizontal>
                        <ListItem>
                            <Link route={`/users/learners/${user._id}/edit`}>Kandidaat bewerken</Link>
                        </ListItem>
                        <ListItem>
                            <Link route={`/users/learners/${user._id}`}>Kandidaat bekijken</Link>
                        </ListItem>
                    </List>
                )}
            >
                <Field title={`Telefoonnummer`}>
                    <ReadablePhoneNumbers phoneNumbers={get(user, 'profile.phoneNumbers') || []} />
                </Field>
                <Field title={`E-mail`}>{user.email}</Field>
                <Field title={`Adres`}>
                    <ReadableAddress address={user.profile.address} />
                </Field>
                <Field title={`Actieve groep(en)`}>{reactJoin(activeGroups.map(group => group.name))}</Field>
                <Field title={`Opdrachtgever(s)`}>
                    {reactJoin(organizations.map(({ organization }) => organization!.name))}
                </Field>
            </TableCellDetail>
        )
    }
}
