import * as React from 'react'
import { gql } from '@apollo/client'
import Fetcher from '~/utils/Fetcher'
import { GroupUser } from '~/types/User'

interface Props {
    groupId: string
}

interface TGroupTeacherParticipantsDataContext {
    teacherUsers: GroupUser[]
    refetch: () => void
    loading: boolean
}

const GET_GROUP_TEACHER_PARTICIPANTS_QUERY = gql`
    query _($groupId: MongoID) {
        groups(filters: { filterById: $groupId }) {
            _id
            teacherUsers {
                _id
                role
                user {
                    _id
                    email
                    profile {
                        name
                        address {
                            _id
                            nl {
                                extraInfo {
                                    city
                                }
                            }
                        }
                        phoneNumbers {
                            _id
                            country
                            internationalPhoneNumber
                            info
                        }
                    }
                }
            }
        }
    }
`

const GroupTeacherParticipantsDataContext = React.createContext<TGroupTeacherParticipantsDataContext | undefined>(
    undefined
)

export const GroupTeacherParticipantsDataConsumer = GroupTeacherParticipantsDataContext.Consumer

export class GroupTeacherParticipantsDataProvider extends React.PureComponent<Props> {
    private participantsFetcher = new Fetcher({
        query: GET_GROUP_TEACHER_PARTICIPANTS_QUERY,
        variables: {
            groupId: this.props.groupId,
        },

        onChange: () => this.forceUpdate(),
    })

    public render() {
        return (
            <GroupTeacherParticipantsDataContext.Provider value={this.getContextValue()}>
                {this.props.children}
            </GroupTeacherParticipantsDataContext.Provider>
        )
    }

    private getContextValue = (): TGroupTeacherParticipantsDataContext => {
        const { data, loading } = this.participantsFetcher
        const group = data && data.groups && data.groups[0]
        const teacherUsers = (group && group.teacherUsers) || []

        return {
            teacherUsers: teacherUsers,
            refetch: () => {
                this.participantsFetcher.refetch()
            },
            loading: loading,
        }
    }
}
