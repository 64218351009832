import { gql } from '@apollo/client'
import React from 'react'
import { Button, CenterModal, Field, FieldCollection, FileInput, Form } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { FormFields } from '~/components/Form'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { Organization } from '~/types/Organization'
import { Fetcher, Mutator } from '~/utils'
export interface UserImportRow {
    // TODO: move to correct location
    Klantnr?: string
    Achternaam: string
    Voornaam?: string
    Tussenvoegsel?: string
    Aanmelding: string
    Geaccepteerd?: string
    Gestart?: string
    Afgerond?: string
    Straat?: string
    Huisnr?: string
    Postcode?: string
    Plaats?: string
    Email?: string
    Telefoon?: string
}
interface Props {
    closeModal: () => void
    onSuccess: () => void
}

interface DoubleUsersConfirmState {
    namesOfUsers: string[]
    formFields: FormFields
}

interface State {
    doubleUsersConfirmState?: DoubleUsersConfirmState
}

export class ImportLearnersModal extends React.Component<Props, State> {
    public state: State = {}

    private organizationFetcher: Fetcher
    private importLearnersMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.organizationFetcher = new Fetcher({
            query: GET_ORGANIZATIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.importLearnersMutator = new Mutator({
            mutation: IMPORT_LEARNERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { closeModal } = this.props
        const { data, loading: loadingOrganizations } = this.organizationFetcher
        const { errors, loading: mutationLoading } = this.importLearnersMutator

        const organizationOptions =
            data &&
            data.organizations &&
            data.organizations.map((organization: Organization) => ({
                value: organization._id,
                label: organization.name,
            }))

        return (
            <CenterModal onClose={closeModal} title={`Importeren`}>
                <Form onSubmit={(events, fields) => this.onSubmit(fields)}>
                    <FieldCollection style="modal">
                        <FieldGroup isForm>
                            <Field isLabel={true} title={`Organisatie (optioneel)`} errors={errors}>
                                {organizationOptions && (
                                    <TagPicker
                                        name="organizationId"
                                        options={organizationOptions}
                                        placeholder="Selecteer organisatie"
                                        isLoading={loadingOrganizations}
                                        multi={false}
                                    />
                                )}
                            </Field>
                            <Field isLabel={true} title={`Voorbeeld excel`} errors={errors}>
                                <Button
                                    type="button"
                                    onClick={() => window.open(`/static/files/learner-import-preview.csv`, '_blank')}
                                >
                                    Download voorbeeld excel
                                </Button>
                            </Field>
                            <Field isLabel={true} title={`Import bestand`} errors={errors}>
                                <FileInput name={`file`} isClearable={true} accept={`.csv`}>
                                    Bestand uploaden
                                </FileInput>
                                {this.state.doubleUsersConfirmState && (
                                    <CenterModal small onClose={closeModal} title={`Mogelijk dubbele cursisten`}>
                                        <FieldCollection style={`modal`}>
                                            <FieldGroup>
                                                <Field>
                                                    Op basis van bepaalde profielgegevens zijn er overeenkomsten
                                                    gevonden met bestaande kandidaten. Het gaat om de volgende namen:
                                                    &quot;{this.state.doubleUsersConfirmState.namesOfUsers.join(', ')}
                                                    &quot;. Doorgaan met importeren?
                                                </Field>
                                            </FieldGroup>
                                            <FieldCollectionFooter>
                                                <List horizontal>
                                                    <ListItem right>
                                                        <Button onClick={closeModal}>Annuleren</Button>
                                                    </ListItem>
                                                    <ListItem right>
                                                        <Button
                                                            isLoading={mutationLoading}
                                                            type={'edit'}
                                                            onClick={e => {
                                                                e.persist()

                                                                this.onSubmitConfirm()
                                                            }}
                                                        >
                                                            Doorgaan
                                                        </Button>
                                                    </ListItem>
                                                </List>
                                            </FieldCollectionFooter>
                                        </FieldCollection>
                                    </CenterModal>
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                <ListItem right={true}>
                                    <Button type={`button`} onClick={closeModal}>
                                        Annuleren
                                    </Button>
                                </ListItem>
                                <ListItem right={true}>
                                    <Button type={`submit`} isLoading={mutationLoading}>
                                        Uploaden en importeren
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Form>
            </CenterModal>
        )
    }

    private onSubmit = async (fields: FormFields) => {
        const { onSuccess } = this.props

        const data: { users_import: UserImportRow[] } = await this.importLearnersMutator.mutate({
            organizationId: fields.organizationId,
            file: fields.file,
            force: false,
        })

        if (data && data.users_import) {
            const possibleDoubleUsers = data.users_import

            if (possibleDoubleUsers.length > 0) {
                // Should show confirmation modal
                this.setState({
                    doubleUsersConfirmState: {
                        namesOfUsers: data.users_import.map(u => u.Achternaam),
                        formFields: fields,
                    },
                })
            } else {
                // Import is done
                onSuccess()
            }
        }
    }

    private onSubmitConfirm = async () => {
        const { onSuccess } = this.props
        const { doubleUsersConfirmState } = this.state

        if (!doubleUsersConfirmState) {
            throw new Error('Something went wrong')
        }

        const data: { users_import: UserImportRow[] } = await this.importLearnersMutator.mutate({
            organizationId: doubleUsersConfirmState.formFields.organizationId,
            file: doubleUsersConfirmState.formFields.file,
            force: true,
        })

        if (data && data.users_import) {
            this.setState({
                doubleUsersConfirmState: undefined,
            })

            // Import is done
            onSuccess()
        }
    }
}

const IMPORT_LEARNERS_MUTATION = gql`
    mutation _($organizationId: MongoID, $file: UploadedFile, $force: Boolean) {
        users_import(organizationId: $organizationId, file: $file, force: $force) {
            Klantnr
            Achternaam
            Aanmelding
            Geaccepteerd
            Gestart
            Afgerond
            Straat
            Huisnr
            Postcode
            Plaats
            Email
            Telefoon
        }
    }
`

const GET_ORGANIZATIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`
