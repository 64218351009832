import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { CenterModal } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { EditGroupNameForm } from '~/forms'

export default class GroupNameFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
    }

    static RELATED_ERROR_KEYS = []

    constructor(props) {
        super(props)
        bindAll(this, ['getEditModal'])
    }

    render() {
        const { group, validationErrors } = this.props

        return (
            <GroupFieldGroup title="Groepnaam" validationErrors={validationErrors} getEditModal={this.getEditModal}>
                {group.name.trim() || 'Naamloos'}
            </GroupFieldGroup>
        )
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Groep bewerken`}>
                <EditGroupNameForm
                    group={group}
                    onCancel={closeModal}
                    onSubmitSuccess={closeModal}
                    refetch={refetchGroup}
                />
            </CenterModal>
        )
    }
}
