import React from 'react'

import { TeacherIntakes } from '~/implementations'
import { RouteComponentProps } from 'react-router'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {}

export default class IntakesView extends React.Component<Props> {
    public render() {
        const {
            params: { id },
        } = this.props

        return <TeacherIntakes teacherUserId={id} />
    }
}
