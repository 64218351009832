import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import { ColleagueProfileData } from '~/components/ExternalOrganizationContactColleague/ColleagueProfileData'

interface Props {
    userId: string
    refetch?: () => void
}

export class AppOrganizationContactColleaguesDetailData extends React.Component<Props> {
    public render() {
        const { userId } = this.props

        return (
            <ContentView>
                <ColleagueProfileData userId={userId} />
            </ContentView>
        )
    }
}
