import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { InflowMomentsCandidatesSelectPage } from '~/components/InflowMoment/InflowMomentsCandidatesSelectPage'

interface Props extends RouteComponentProps<{}, {}> {}

export class CandidatesInflowMomentsMasterView extends React.Component<Props> {
    public render() {
        return <InflowMomentsCandidatesSelectPage />
    }
}
