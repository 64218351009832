import React from 'react'
import { RouteComponentProps } from 'react-router'
import { ContentView } from '~/components/ContentView'

import { InflowModule } from '~/types/InflowModule'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
    children: any
}

interface State {}

export class AppPropertiesInflowModulesInvites extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { children, inflowModule } = this.props

        return <ContentView>{inflowModule && React.cloneElement(children, { inflowModule })}</ContentView>
    }
}
