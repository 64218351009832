import './ErrorLabel.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Icon } from '..'

interface Props {
    isErrorMessage?: boolean
}

export class ErrorLabel extends React.Component<Props> {
    private bem = new BEM('ErrorLabel', () => ({
        'is-error-message': !!this.props.isErrorMessage,
    }))

    public render() {
        const { children, isErrorMessage } = this.props

        return (
            <p className={this.bem.getClassName()}>
                {isErrorMessage && <Icon name={`status_attention`} />}
                {children}
            </p>
        )
    }
}
