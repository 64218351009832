import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { UserTimeline } from '~/implementations'
import { getCurrentUser } from '~/services/session'

export default class ExternalTeacherIntakesDetailTimelineView extends Component {
    static propTypes = {
        userId: PropTypes.string,
    }

    render() {
        const { userId } = this.props
        const currentUser = getCurrentUser()

        return (
            <ContentView>
                <UserTimeline
                    userId={userId}
                    hideFieldsForGroupTeacher
                    filterTimelineEventsCreatedByTeacherUserId={currentUser._id}
                    timelineEventOwnerType={`LEARNER_USER`}
                />
            </ContentView>
        )
    }
}
