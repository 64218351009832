import './RevenueAmountTableCell.scss'

import c from 'classnames'
import * as React from 'react'

import { TableCell } from '~/components/TableCell'

interface Props {
    className?: string
    isPlaceholder?: boolean
    isYearTotal?: boolean
    isFutureMonth?: boolean
    isCurrentMonth?: boolean
}

export class RevenueAmountTableCell extends React.Component<Props, {}> {
    public render() {
        const { children, isPlaceholder } = this.props

        return <TableCell className={this.getClassName()}>{isPlaceholder ? '-' : children}</TableCell>
    }

    private getClassName() {
        const { className, isPlaceholder, isYearTotal, isFutureMonth, isCurrentMonth } = this.props

        return c(
            'tt-RevenueAmountTableCell',
            {
                'tt-RevenueAmountTableCell--is-placeholder': isPlaceholder,
                'tt-RevenueAmountTableCell--is-year-total': isYearTotal,
                'tt-RevenueAmountTableCell--is-future-month': isFutureMonth,
                'tt-RevenueAmountTableCell--is-current-month': isCurrentMonth,
            },
            className
        )
    }
}
