import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isForNavBar?: boolean
}

export default class Brand extends React.Component<Props> {
    public static defaultProps = {
        isForNavBar: false,
    }

    private bem = new BEM('Brand', () => ({
        navbar: this.props.isForNavBar,
    }))

    public render() {
        const { children, className, isForNavBar } = this.props

        let logo = `/static/images/logo-cover-2019.png`

        if (isForNavBar) {
            logo = `/static/images/logo-small-2019.svg`
        }

        return (
            <figure className={this.bem.getClassName(className)}>
                <img src={logo} alt={`TopTaal Logo`} />
                <h1>{children}</h1>
            </figure>
        )
    }
}
