import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import {
    Header,
    ActionBar,
    Search,
    Button,
    Icon,
    Form,
    FilterFieldCollection,
    Field,
    MultiInput,
    DatePicker,
    TableWrap,
    TableHeader,
    TableHeaderItem,
    TableRow,
    Subtle,
    ReadableDate,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Wrap } from '~/components/Wrap'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { SystemExportLog } from '~/types/SystemExportLog'
import { View } from '~/components/View'
import { gql } from '@apollo/client'
import { Fetcher, removeDuplicateDocuments, downloadFile } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'DESC'
const DEFAULT_SORT_BY = 'createdAt'

const GET_EXPORTS_REPORTS_QUERY = gql`
    query exports(
        $skip: Int
        $limit: Int
        $sortBy: String
        $sortDir: String
        $filterByType: ExportTypeEnum
        $filterByGeneratedStart: Date
        $filterByGeneratedEnd: Date
        $filterByDownloadedStart: Date
        $filterByDownloadedEnd: Date
        $filterByDownloadedUserId: MongoID
    ) {
        exports(
            skip: $skip
            limit: $limit
            sortBy: $sortBy
            sortDir: $sortDir
            filterByType: $filterByType
            filterByGeneratedStart: $filterByGeneratedStart
            filterByGeneratedEnd: $filterByGeneratedEnd
            filterByDownloadedStart: $filterByDownloadedStart
            filterByDownloadedEnd: $filterByDownloadedEnd
            filterByDownloadedUserId: $filterByDownloadedUserId
        ) {
            _id
            file {
                _id
                fileName
            }
            type
            createdAt
            lastDownloadedAt
            lastDownloadedByUser {
                _id
                profile {
                    name
                }
            }
        }
    }
`

interface Props extends RouteComponentProps<{}, {}> {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class SystemExportLogView extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private exportsFetcher: Fetcher
    private paginator: Paginator

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.exportsFetcher = new Fetcher({
            query: GET_EXPORTS_REPORTS_QUERY,
            // forceFetch: true,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => this.paginator.reset(),
        })
    }

    public render() {
        const { ...routeProps } = this.props
        const { loading } = this.exportsFetcher

        return (
            <RouteView crumbLabel={'Log van exports'} routeProps={routeProps}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                        <Wrap full={true}>{this.renderActionBar()}</Wrap>
                        <TableWrap>{this.renderTable()}</TableWrap>
                    </InfiniteScroll>
                </ContentView>
            </RouteView>
        )
    }

    private renderActionBar = () => {
        return (
            <ActionBar
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => {
                    return (
                        <List horizontal={true}>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`filter`} />}
                                    onClick={toggleDrawer}
                                    isActive={drawerActive}
                                >
                                    Filteropties
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Search defaultValue={''} />
                            </ListItem>
                        </List>
                    )
                }}
                getDrawer={this.renderFilters}
            />
        )
    }

    private renderFilters = () => {
        return (
            <Form ref={`filterForm`}>
                <FilterFieldCollection>
                    <Field isLabel={true} title={`Type`} style={`compact`}>
                        <TagPicker
                            name={`filterByType`}
                            placeholder={`Selecteer types`}
                            multi={true}
                            isClearable={true}
                            options={[
                                { value: 'EnrollmentCitizenship', label: 'inschrijvingen inburgering' },
                                { value: 'OutflowCitizenship', label: 'uitstroom inburgering' },
                                { value: 'TeachersCitizenship', label: 'docenten inburgering' },
                                { value: 'FinalexamAttempt', label: 'examenpogingen' },
                                { value: 'FinalexamAttempt', label: 'DUO export' },
                            ]}
                        />
                    </Field>
                    <Field isLabel={true} title={`Genereer datum`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker name={'filterByGeneratedStart'} />
                            <label>t/m</label>
                            <DatePicker name={'filterByGeneratedEnd'} />
                        </MultiInput>
                    </Field>
                    <Field isLabel={true} title={`Gedownload op`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker name={'filterByDownloadedStart'} />
                            <label>t/m</label>
                            <DatePicker name={'filterByDownloadedEnd'} />
                        </MultiInput>
                    </Field>
                    <Field isLabel={true} title={`Door`} style={`compact`}>
                        <TagPicker
                            name={`filterByCreatedByUserId`}
                            placeholder={`Selecteer een gebruiker`}
                            multi={false}
                        />
                    </Field>
                </FilterFieldCollection>
            </Form>
        )
    }

    private renderTable = () => {
        const { data, loading } = this.exportsFetcher
        const { exports } = data || []

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem width="40%">Naam</TableHeaderItem>
                        <TableHeaderItem width="25%">Type</TableHeaderItem>
                        <TableHeaderItem width="15%">Aangemaakt op</TableHeaderItem>
                        <TableHeaderItem width="20%">Laatst gedownload</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={5} isLoading={loading} />
                        </TableRow>
                    ) : exports.length > 0 ? (
                        this.renderExportRows(exports)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={5}>
                                <Subtle>Er zijn nog geen exports gemaakt.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderExportRows = (systemExportLogs: SystemExportLog[]) => {
        enum ExportType {
            EnrollmentCitizenship = 'Examenkandidaten inburgering',
            OutflowCitizenship = 'Uitstroom inburgering',
            TeachersCitizenship = 'Docenten inburgering',
            FinalexamAttempt = 'Examenpogingen inburgering',
            DUOLearnerReport = 'Inschrijvingen inburgering',
            DUOBOWContractEnrollment = 'Inschrijvingen inburgering contracten',
        }

        return systemExportLogs.map(systemExportLog => (
            <TableRow key={systemExportLog._id}>
                <TableCell>
                    {systemExportLog.file ? (
                        <Button
                            linkStyle={`default`}
                            onClick={() => downloadFile(systemExportLog.file._id, systemExportLog.file.fileName)}
                        >
                            {systemExportLog.file.fileName}
                        </Button>
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell>{ExportType[systemExportLog.type]}</TableCell>
                <TableCell>
                    <ReadableDate date={systemExportLog.createdAt} />
                </TableCell>
                <TableCell>
                    <ReadableDate date={systemExportLog.lastDownloadedAt} />
                    {systemExportLog.lastDownloadedByUser &&
                        ` door ${systemExportLog.lastDownloadedByUser.profile.name}`}
                </TableCell>
            </TableRow>
        ))
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.exportsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.exports.length === 0,
                })

                return {
                    exports: removeDuplicateDocuments([...(prevData.exports || []), ...moreData.exports]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }
}
