import * as React from 'react'
import { TableRow, Link } from '~/components'
import { TableCell } from '~/components/TableCell'
import { GroupExamStatusInput } from '../GroupExamStatusInput'
import { User } from '~/types/User'
import { getCurrentUser } from '~/services/session'
import { ExamAbilityResult, ResultStatus } from '~/types/Exam'
import { FileUploadInput } from '~/components/FileUploadInput/FileUploadInput'
import { TagPickerOption } from '~/components/TagPicker'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
// import Field from '~/components/Field'

interface Props {
    renderTableSpecificInput: () => JSX.Element
    errors: GraphQLErrors
    baseName: string
    examAbilityResult?: ExamAbilityResult
    plannedAbilityExamId: string
    learner: User
}

interface State {
    selectedStatus?: ResultStatus
}

export class GroupExamFormTableRow extends React.Component<Props, State> {
    public state: State = {
        selectedStatus:
            (this.props.examAbilityResult &&
                this.props.examAbilityResult.result &&
                this.props.examAbilityResult.result.status) ||
            ResultStatus.Participated,
    }

    public render() {
        const {
            renderTableSpecificInput,
            learner,
            baseName,
            examAbilityResult,
            plannedAbilityExamId,
            // errors,
        } = this.props

        return (
            <TableRow className={`tt-TableRow--has-inline-button`}>
                <TableCell>
                    <Link route={`/users/learners/${learner._id}`}>{learner.profile.name}</Link>
                </TableCell>
                <TableCell>
                    {/* TODO: Implement the default value of this input, when the server is ready for it */}
                    <GroupExamStatusInput
                        defaultValue={
                            (examAbilityResult && examAbilityResult.result && examAbilityResult.result.status) ||
                            ResultStatus.Participated
                        }
                        placeholder={`Selecteer`}
                        onChange={this.onChangeExamStatus}
                        name={`${baseName}.result.status`}
                    />
                </TableCell>
                <TableCell className={`tt-TableCell--has-large-input`}>
                    {examAbilityResult && (
                        <input type="hidden" name={`${baseName}._id`} value={examAbilityResult._id} />
                    )}

                    <input type="hidden" name={`${baseName}.learnerUserId`} value={learner._id} />

                    <input type="hidden" name={`${baseName}.plannedAbilityExamId`} value={plannedAbilityExamId} />

                    {this.state.selectedStatus === ResultStatus.Participated && (
                        <React.Fragment>
                            {renderTableSpecificInput()}
                            {this.userMayViewOrUploadResultFiles() && (
                                <React.Fragment>
                                    <FileUploadInput
                                        name={`${baseName}.documentFiles`}
                                        uploadFilesName={`files`}
                                        accept={`application/pdf,audio/*,image/*`}
                                        multi={true}
                                        defaultFiles={examAbilityResult && examAbilityResult.files}
                                        smallButton={true}
                                        inRow={true}
                                        showOnRowHover={true}
                                    >
                                        Toetsresultaat uploaden
                                    </FileUploadInput>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                    {/* {selectedStatus === ResultStatus.NoShow && (
                        <Input
                            name={`${baseName}.result.absenceExplanation`}
                            placeholder={`Reden voor afwezigheid`}
                            defaultValue={examAbilityResult && examAbilityResult.result ? examAbilityResult.result.absenceExplanation : ``}
                        />
                    )} */}
                </TableCell>
            </TableRow>
        )
    }

    private onChangeExamStatus = (option?: TagPickerOption) => {
        this.setState({
            selectedStatus: option ? (option.value as ResultStatus) : undefined,
        })
    }

    private userMayViewOrUploadResultFiles(): boolean {
        const currentUser = getCurrentUser()
        if (currentUser && currentUser.isEmployee) {
            return currentUser.isEmployee
        }
        return false
    }
}
