import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerAvailability } from '~/implementations'

export default class AvailabilityView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        currentUser: PropTypes.object,
        refetch: PropTypes.func,
        isViewedByTeacherOfUser: PropTypes.bool,
    }

    render() {
        const { userId } = this.props

        return (
            <ContentView>
                <LearnerAvailability userId={userId} />
            </ContentView>
        )
    }
}
