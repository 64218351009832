import React from 'react'
import { Wrap } from '~/components/Wrap'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { User, LearnerEnrollmentType, UserLearnerOrganization } from '~/types/User'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Link from '~/components/Link'
import { Paginator } from '~/utils/Paginator'
import { Fetcher, Sorter } from '~/utils'
import translateType from '~/shared/utils/translateType'
import ReadableDate from '~/components/ReadableDate'
import { Label } from '../Label/Label'
import { getCurrentUser } from '~/services/session'

interface Props {
    users: User[]
    userPaginator: Paginator
    userSorter: Sorter
    renderActionBar: () => JSX.Element
    userFetcher: Fetcher
}

export class InflowMomentsCandidatesTable extends React.Component<Props> {
    public render() {
        const { userPaginator, renderActionBar, userFetcher } = this.props
        const { loading } = userFetcher

        return (
            <React.Fragment>
                <InfiniteScroll paginator={userPaginator} preventLoad={loading}>
                    <Wrap full={true}>{renderActionBar()}</Wrap>
                    <TableWrap>
                        <TableView>{this.renderUsersTable(this.props.users)}</TableView>
                    </TableWrap>
                </InfiniteScroll>
            </React.Fragment>
        )
    }

    private renderUsersTable = (users: User[]) => {
        const { userFetcher } = this.props
        const { loading } = userFetcher
        const { userSorter } = this.props

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem sorter={userSorter} sortBy={`profile.firstName`}>
                        Naam
                    </TableHeaderItem>
                    <TableHeaderItem>Organisatie</TableHeaderItem>
                    <TableHeaderItem>Woonplaats</TableHeaderItem>
                    <TableHeaderItem>Type instroom</TableHeaderItem>
                    <TableHeaderItem sorter={userSorter} sortBy={`learner.enrollmentDate`}>
                        Aangemeld op
                    </TableHeaderItem>
                    <TableHeaderItem sorter={userSorter} sortBy={`learner.inflowMomentsNoShowStreak`}>
                        Niet verschenen
                    </TableHeaderItem>
                    <TableHeaderItem />
                </TableHeader>
                {loading ? (
                    <TableRow key={`loading-row`}>
                        <TableCell colSpan={6} isLoading={true} />
                    </TableRow>
                ) : (
                    this.renderTableRows(users)
                )}
            </Table>
        )
    }

    private renderTableRows = (users: User[]) => {
        return users.length > 0 ? (
            users.map(user => {
                const { learner } = user
                const organizationNames =
                    learner &&
                    learner.organizations &&
                    learner.organizations.map(
                        ({ organization }: UserLearnerOrganization) => organization && organization.name
                    )
                const inflowMomentsNoShowStreak = learner && learner.inflowMomentsNoShowStreak

                return (
                    <TableRow key={user._id}>
                        <TableCell>
                            <Link route={this.getLearnerDetailRoute(user)}>{user.profile.name}</Link>
                        </TableCell>
                        <TableCell>
                            {organizationNames &&
                                organizationNames.map((organizationName, index) => [
                                    index > 0 && ', ',
                                    organizationName,
                                ])}
                        </TableCell>
                        <TableCell>
                            {user.profile.address &&
                                user.profile.address.nl &&
                                user.profile.address.nl.extraInfo &&
                                user.profile.address.nl.extraInfo.city}
                        </TableCell>
                        <TableCell>{this.getEnrollmentValue(user)}</TableCell>
                        <TableCell>
                            <ReadableDate date={user.learner && user.learner.enrollmentDate} />
                        </TableCell>
                        <TableCell>
                            {!!inflowMomentsNoShowStreak && inflowMomentsNoShowStreak > 0 && (
                                <Label hasInverseColors={true}>{inflowMomentsNoShowStreak}x niet verschenen</Label>
                            )}
                        </TableCell>
                    </TableRow>
                )
            })
        ) : (
            <TableRow>
                <TableCell colSpan={6}>Geen gebruikers gevonden</TableCell>
            </TableRow>
        )
    }

    private getEnrollmentValue = (user: User) => {
        const { learner } = user

        if (!learner || !learner.enrollmentType) {
            return null
        }

        const enrollmentType =
            learner.enrollmentType === LearnerEnrollmentType.Intake
                ? LearnerEnrollmentType.Intake
                : LearnerEnrollmentType.Assessment

        return translateType('enrollmentType', enrollmentType)
    }

    private getLearnerDetailRoute = (user: User): string => {
        const currentUser = getCurrentUser()

        if (currentUser.isOrganizationContact) {
            return `/learners/${user._id}`
        }

        return `/users/learners/${user._id}`
    }
}
