import * as React from 'react'
import { browserHistory } from 'react-router'

import { FieldCollection, Field, Button, Link, ReadableAddress, ReadablePhoneNumbers } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { nl2br } from '~/utils'
import { Organization } from '~/types/Organization'
import translateType from '~/shared/utils/translateType'

interface Props {
    organization: Organization
}

export class OrganizationDataView extends React.Component<Props> {
    public render() {
        const { organization } = this.props

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title="Organisatie">
                            <Field title="Naam organisatie">{organization.name}</Field>
                            <Field title="Adres">
                                {organization.address && (
                                    <ReadableAddress address={organization.address} showCountry={false} />
                                )}
                            </Field>
                            {organization.billingAddress && (
                                <Field title="Factuuradres">
                                    <ReadableAddress address={organization.billingAddress} showCountry={false} />
                                </Field>
                            )}
                            <Field title="Project">
                                {organization.project && (
                                    <Link route={`/properties/projects/${organization.project._id}`}>
                                        {organization.project.name}
                                    </Link>
                                )}
                            </Field>
                            <Field title="Telefoonnummer">
                                {organization.phoneNumber && (
                                    <ReadablePhoneNumbers phoneNumbers={[organization.phoneNumber]} />
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup title="Betalingsgegevens">
                            <Field title="Factuur sturen naar">
                                {organization.invoiceEmailRecipients && organization.invoiceEmailRecipients.length > 0 && (
                                    <List>
                                        {organization.invoiceEmailRecipients.map((recipient, i) => (
                                            <ListItem key={i}>
                                                {recipient.email}
                                                {recipient.name ? ` (${recipient.name})` : ``}
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </Field>
                            <Field title="Bedrijfscode">{organization.organizationCode}</Field>
                            <Field title="Twinfield debiteurnummer">
                                {organization.twinfieldCustomer && (
                                    <>
                                        {organization.twinfieldCustomer.code} ({organization.twinfieldCustomer.name})
                                    </>
                                )}
                            </Field>
                            <Field title="Type BTW">
                                {organization.vatExempt
                                    ? translateType('organizationVatExempt', 'Yes')
                                    : translateType('organizationVatExempt', 'No')}
                            </Field>
                        </FieldGroup>
                        <FieldGroup title="Factuurgegevens">
                            <Field title="Extra factuurgegevens">
                                {organization.invoiceTexts && (
                                    <List>
                                        {organization.invoiceTexts.map((invoiceText, i) => (
                                            <ListItem key={i}>- {invoiceText}</ListItem>
                                        ))}
                                    </List>
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup title="Overige">
                            <Field title="Contactpersonen">
                                {this.renderOrganizationContacts(organization.contactUsers)}
                            </Field>
                            <Field title="Notities">{nl2br(organization.notes || '')}</Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button
                                        onClick={() =>
                                            browserHistory.push(`/properties/organizations/${organization._id}/edit`)
                                        }
                                        type="edit"
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    private renderOrganizationContacts(contactUsers: Organization['contactUsers']): React.ReactNode {
        if (!contactUsers) {
            return null
        }

        return (
            <ul>
                {contactUsers.map((contactUser, i) => (
                    <li key={i}>
                        <Link key={contactUser._id} route={`/users/organization-contacts/${contactUser._id}`}>
                            {contactUser.profile.name}
                        </Link>
                    </li>
                ))}
            </ul>
        )
    }
}
