import React from 'react'

import { DrawerHeader } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { LearnerProfileEditForm } from '~/implementations/LearnerProfileEditForm'
import { Mutator } from '~/utils'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    title: string
    closeModal: () => void
    candidateId: string
    refetch: (options?: any) => void
    inflowMomentId: string
    mutation: any
    adviceReportId?: string
}

interface State {}

export class AdviceReportForm extends React.Component<Props, State> {
    private adviceRapportMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.adviceRapportMutator = new Mutator({
            mutation: this.props.mutation,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { title, closeModal, candidateId, adviceReportId } = this.props
        const { loading: mutationLoading, errors } = this.adviceRapportMutator

        return (
            <ContentView>
                <LockedFieldWrapper.StatusContext.Provider value={true}>
                    <LearnerProfileEditForm
                        onSubmit={this.onSubmit}
                        learnerUserId={candidateId}
                        graphqlErrors={errors.getErrorsFromNamespace('adviceReport')}
                        forNewIntake={!adviceReportId}
                        forIntake={true}
                    >
                        {renderFormContent => (
                            <>
                                <DrawerHeader title={title} close={closeModal} />
                                <Wrap>{renderFormContent()}</Wrap>
                                <DrawerFooter close={closeModal} isLoading={mutationLoading} />
                            </>
                        )}
                    </LearnerProfileEditForm>
                </LockedFieldWrapper.StatusContext.Provider>
            </ContentView>
        )
    }

    private onSubmit = async (user: any, markAsPristine?: () => void) => {
        const { closeModal, refetch, candidateId, inflowMomentId, adviceReportId } = this.props

        const data = await this.adviceRapportMutator.mutate({
            adviceReport: {
                user,
                learnerUserId: candidateId,
                inflowMomentId: inflowMomentId,
                _id: adviceReportId,
            },
        })

        if (data) {
            if (markAsPristine) {
                markAsPristine()
            }

            refetch()
            closeModal()
        }
    }
}
