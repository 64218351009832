import React from 'react'
import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'

interface Props {}

export default class VideosList extends React.Component<Props> {
    private bem = new BEM('VideosList')

    public render() {
        const { children } = this.props

        return <List className={this.bem.getClassName()}>{children}</List>
    }
}
