import { gql } from '@apollo/client'
import { get } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, MultiInput, RadioButton } from '~/components'
import apolloClient from '~/services/apolloClient'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { ExamsAbilityFieldGroup } from '~/components/ExamsAbilityFieldGroup'
import { Exam } from '~/types/Exam'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { FieldGroup } from '~/components/FieldGroup'
import { ExamLevelInput } from '~/components/ExamLevelInput'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

const EDIT_EXAM_MUTATION = gql`
    mutation _($exam: ExamInputType!) {
        exams_edit(exam: $exam) {
            _id
            name
            type
            level
            converse {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }

            talk {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }

            write {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }

            listen {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }

            read {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
        }
    }
`

const DELETE_EXAM_MUTATION = gql`
    mutation _($_id: MongoID!) {
        exams_delete(examId: $_id) {
            _id
        }
    }
`

interface Props {
    exam: Exam
    refetch?: () => void
    params: {
        id: string
    }
}

interface State {
    typeSelectedIsAlpha: boolean | undefined
}

export default class EditView extends React.Component<Props, State> {
    public state: State = {
        typeSelectedIsAlpha: undefined,
    }

    private examMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { exam } = props

        this.state = {
            typeSelectedIsAlpha: get(exam, 'type') === 'alpha',
        }

        this.examMutator = new Mutator({
            mutation: EDIT_EXAM_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public onSubmit = async (event: any, fields: any) => {
        const { params, refetch } = this.props

        const fieldsData = await this.examMutator.mutate({
            exam: {
                _id: params.id,
                ...transformFormFields(fields.exam, {
                    converse: {
                        fields: (v: any) => transformFormFields(v),
                    },
                    talk: {
                        fields: (v: any) => transformFormFields(v),
                    },
                    write: {
                        fields: (v: any) => transformFormFields(v),
                    },
                    listen: {
                        fields: (v: any) => transformFormFields(v),
                    },
                    read: {
                        fields: (v: any) => transformFormFields(v),
                    },
                }),
            },
        })

        if (fieldsData) {
            if (refetch) {
                refetch()
            }

            browserHistory.push(`/properties/exams/${params.id}`)
        }
    }

    public onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_EXAM_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/exams`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'exams_delete' })
            toast.error(`${message}`)
        }
    }

    public render() {
        const { exam } = this.props
        const { typeSelectedIsAlpha } = this.state
        const { errors, loading } = this.examMutator

        const examName = get(exam, 'name')
        const examType = get(exam, 'type')
        const examLevel = get(exam, 'level')
        const isExamTypeLanguage = examType === 'language'
        const isExamTypeAlpha = examType === 'alpha'

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={'Toets details'}>
                                <Field isLabel title={'Naam'} errors={errors}>
                                    <Input
                                        name={`exam.name`}
                                        type={`text`}
                                        placeholder={`Functienaam`}
                                        defaultValue={examName}
                                    />
                                </Field>
                                <Field title={'Soort'} errors={errors}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`exam.type`}
                                            value={'language'}
                                            defaultChecked={isExamTypeLanguage}
                                            onClick={() => this.setState({ typeSelectedIsAlpha: false })}
                                        >
                                            Taaltoets
                                        </RadioButton>
                                        <RadioButton
                                            name={`exam.type`}
                                            value={'alpha'}
                                            defaultChecked={isExamTypeAlpha}
                                            onClick={() => this.setState({ typeSelectedIsAlpha: true })}
                                        >
                                            Alfatoets
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                                {!typeSelectedIsAlpha && (
                                    <FieldGroup isInsetGroup={true}>
                                        <Field isLabel title={`Niveau`} errors={errors}>
                                            <ExamLevelInput
                                                name={`exam.level`}
                                                placeholder={`Welk niveau heeft de toets?`}
                                                defaultValue={examLevel}
                                            />
                                        </Field>
                                    </FieldGroup>
                                )}
                            </FieldGroup>
                            {!typeSelectedIsAlpha && (
                                <React.Fragment>
                                    <ExamsAbilityFieldGroup
                                        abilityName={`converse`}
                                        defaultAbility={get(exam, 'converse')}
                                        baseName={`exam.converse`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                    <ExamsAbilityFieldGroup
                                        abilityName={`talk`}
                                        defaultAbility={get(exam, 'talk')}
                                        baseName={`exam.talk`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                    <ExamsAbilityFieldGroup
                                        abilityName={`write`}
                                        defaultAbility={get(exam, 'write')}
                                        baseName={`exam.write`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                    <ExamsAbilityFieldGroup
                                        abilityName={`listen`}
                                        defaultAbility={get(exam, 'listen')}
                                        baseName={`exam.listen`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                    <ExamsAbilityFieldGroup
                                        abilityName={`read`}
                                        defaultAbility={get(exam, 'read')}
                                        baseName={`exam.read`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                </React.Fragment>
                            )}
                            {typeSelectedIsAlpha && (
                                <React.Fragment>
                                    <ExamsAbilityFieldGroup
                                        abilityName={`write`}
                                        defaultAbility={get(exam, 'write')}
                                        baseName={`exam.write`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                    <ExamsAbilityFieldGroup
                                        abilityName={`read`}
                                        defaultAbility={get(exam, 'read')}
                                        baseName={`exam.read`}
                                        typeSelectedIsAlpha={typeSelectedIsAlpha}
                                        errors={errors}
                                    />
                                </React.Fragment>
                            )}
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button onClick={() => browserHistory.push(`/properties/exams/${exam._id}`)}>
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }
}
