import c from 'classnames'
import * as React from 'react'

type SimpleTableRowType = 'header'

interface Props {
    className?: string
    isDoubleTableRow?: boolean
    type?: SimpleTableRowType
}

export class SimpleTableRow extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <tr className={this.getClassName()}>{children}</tr>
    }

    private getClassName() {
        const { className, isDoubleTableRow, type } = this.props

        return c(
            'tt-SimpleTableRow',
            {
                'tt-SimpleTableRow--is-double-table-row': isDoubleTableRow,
                'tt-SimpleTableRow--header': type === 'header',
            },
            className
        )
    }
}
