import * as React from 'react'
import c from 'classnames'

interface Props {
    children?: any
    right?: boolean
    className?: string
}

export class ListItem extends React.PureComponent<Props, {}> {
    public render() {
        const { children } = this.props

        return <li className={this.getClassName()}>{children}</li>
    }

    private getClassName = () => {
        const { right, className: extraClassNames } = this.props
        return c('tt-ListItem', extraClassNames, {
            'tt-ListItem--right': right,
        })
    }
}
