import * as React from 'react'

import { LearnerLevel, LearnerLevelWithAlpha } from '~/types/LearnerLevel'
import { translateType } from '~/shared/utils'

interface Props {
    level: LearnerLevelWithAlpha | LearnerLevel
}

export class LevelText extends React.Component<Props> {
    public render() {
        const { level } = this.props

        if (!level) {
            return null
        }

        return translateType('level', level)
    }
}
