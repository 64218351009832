import React, { Component } from 'react'
import c from 'classnames'

import Tooltip from 'rc-tooltip'

export enum Placement {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
    leftTop = 'leftTop',
    rightTop = 'rightTop',
    topLeft = 'topLeft',
    topRight = 'topRight',
    leftBottom = 'leftBottom',
    rightBottom = 'rightBottom',
    bottomLeft = 'bottomLeft',
    bottomRight = 'bottomRight',
}

enum TriggerTypes {
    click = 'click',
    hover = 'hover',
    focus = 'focus',
}

interface Props {
    className?: string
    text?: string | JSX.Element
    titleText?: string
    showTitle?: boolean
    children?: any
    placement?: Placement
    isLargeExplanation?: boolean
    enableDelay?: boolean
    triggerType?: TriggerTypes[]
}

export default class ToolTip extends Component<Props> {
    public static defaultProps = {
        showTitle: true,
        titleText: '',
    }

    public render() {
        const { text, isLargeExplanation, enableDelay, titleText, children, showTitle, triggerType } = this.props
        const title = showTitle ? titleText : ''

        if (!text) {
            return children
        }

        return (
            <span className={this.getClassName()} title={isLargeExplanation ? '' : title}>
                <Tooltip
                    placement={this.getPlacement()}
                    trigger={triggerType || ['hover', 'focus']}
                    overlay={this.renderOverlay()}
                    align={{
                        offset: this.getOffset(),
                    }}
                    mouseEnterDelay={enableDelay ? 0.5 : 0}
                >
                    <span>{children}</span>
                </Tooltip>
            </span>
        )
    }

    private getPlacement() {
        return this.props.placement || Placement.top
    }

    private getOffset() {
        switch (this.getPlacement()) {
            case 'top':
                return [-3, -6]
            case 'bottom':
                return [-3, 6]
            case 'left':
                return [-6, -3]
            case 'right':
                return [6, -3]
            case 'leftTop':
                return [-6, -12]
            case 'rightTop':
                return [6, -12]
            case 'topLeft':
                return [-24, -6]
            case 'topRight':
                return [24, -6]
            case 'leftBottom':
                return [-6, 6]
            case 'rightBottom':
                return [6, 6]
            case 'bottomLeft':
                return [-24, 6]
            case 'bottomRight':
                return [24, 6]
            default:
                return [-3, -6]
        }
    }

    private renderOverlay() {
        return <span className="tt-ToolTipOverlay">{this.props.text}</span>
    }

    private getClassName() {
        const { className, isLargeExplanation } = this.props

        return c(
            'tt-ToolTip',
            {
                'tt-ToolTip--is-large-explanation': isLargeExplanation,
            },
            className
        )
    }
}
