import React from 'react'
import { InflowMomentFieldGroup, ValidationErrorType } from './InflowMomentFieldGroup'
import { InflowMoment } from '~/types/InflowMoments'
import { CenterModal, Field } from '~/components'
import moment from 'moment'
import { InflowDateForm } from '../Forms/InflowDateForm'
import { capitalize } from 'lodash'
import { Mutator } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { ValidationError } from '~/components/ValidationError'

interface Props {
    validationErrors?: ValidationErrorType[]
    inflowMoment: InflowMoment
    refetch: () => void
    errors: string[]
    hideRemove?: boolean
}

interface State {}

export class InflowMomentDateRangeGroup extends React.Component<Props, State> {
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { validationErrors, inflowMoment, errors } = this.props

        const date = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        return (
            <InflowMomentFieldGroup
                title={`Datum`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                inflowMoment={inflowMoment}
                onRemove={this.onRemove}
                removeMessage={'Je staat op het punt de datum uit dit toetsmoment te verwijderen. Weet je het zeker?'}
            >
                <Field>
                    {capitalize(date.format('ddd DD MMMM YYYY (H:mm'))} - {endDate.format('H:mm')})
                    {errors.includes('from-date-should-be-before-to-date') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`De starttijd van het toetsmoment moet voor de eindtijd liggen.`}
                        />
                    )}
                    {errors.includes('total-time-is-less-than-exam-time') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`De totale tijd is korter dan de toetstijd van de module.`}
                        />
                    )}
                </Field>
            </InflowMomentFieldGroup>
        )
    }

    private getEditModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Toetsmoment bewerken`}>
                <InflowDateForm onClose={closeModal} inflowMoment={inflowMoment} refetch={refetch} />
            </CenterModal>
        )
    }

    private onRemove = () => {
        const { refetch, inflowMoment } = this.props

        this.inflowMomentMutator
            .mutate({
                inflowMoment: {
                    _id: inflowMoment._id,
                    nulls: ['dateRange'],
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                }
            })
    }
}
