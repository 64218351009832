import { gql } from '@apollo/client'
import * as React from 'react'
import { browserHistory, Route } from 'react-router'

import { ActionBar, Button } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { PageQuitConfirm } from '~/components/PageQuitConfirm'
import { Wrap } from '~/components/Wrap'
import { Mutator, toast } from '~/utils'
import { LearnerProfileEditForm } from './LearnerProfileEditForm'

interface Props {
    learnerUserId: string
    refetch?: (options?: any) => void
    getReturnRouteForUser?: (userId: string) => string
    hideFieldsForGroupTeacher?: boolean
    hideFieldsForIntakeTeacher?: boolean
    hideFieldsForOrganizationContacts?: boolean
    router: any
    route: Route
}

export class LearnerProfileEdit extends React.Component<Props> {
    public static defaultProps = {
        getReturnRouteForUser: (userId: string) => `/users/learners/${userId}`,
        hideFieldsForGroupTeacher: false,
        hideFieldsForIntakeTeacher: false,
        hideFieldsForOrganizationContacts: false,
    }

    public userCanLeavePage = true

    private userMutator: Mutator
    private userReturnUrl?: string

    constructor(props: Props) {
        super(props)

        const { learnerUserId } = props

        ;(async () => {
            const { getReturnRouteForUser } = this.props

            if (getReturnRouteForUser) {
                this.userReturnUrl = getReturnRouteForUser(learnerUserId)
            }
        })()

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public onSubmit = async (user: any, markAsPristine?: () => void) => {
        const { getReturnRouteForUser, refetch } = this.props

        const { userReturnUrl } = this

        if (getReturnRouteForUser) {
            this.userReturnUrl = getReturnRouteForUser(user._id)
        }

        const data = await this.userMutator.mutate({
            user,
        })

        if (data) {
            if (refetch) {
                refetch()
            }

            toast.success('Kandidaat succesvol opgeslagen')

            if (markAsPristine) {
                markAsPristine()
            }

            if (userReturnUrl) {
                browserHistory.push(userReturnUrl)
            }
        }
    }

    public onFormChange = (markAsDirty: () => void) => {
        markAsDirty()
    }

    public render() {
        const { route, router } = this.props

        return (
            <PageQuitConfirm
                renderComponent={(markAsDirty, markAsPristine) => this.renderForm(markAsDirty, markAsPristine)}
                route={route}
                router={router}
            />
        )
    }

    public renderForm(markAsDirty: () => void, markAsPristine: () => void) {
        const {
            hideFieldsForGroupTeacher,
            hideFieldsForIntakeTeacher,
            hideFieldsForOrganizationContacts,
            learnerUserId,
        } = this.props
        const { errors } = this.userMutator

        return (
            <Wrap>
                <LearnerProfileEditForm
                    learnerUserId={learnerUserId}
                    onSubmit={this.onSubmit}
                    markAsDirty={markAsDirty}
                    markAsPristine={markAsPristine}
                    hideFieldsForGroupTeacher={hideFieldsForGroupTeacher}
                    hideFieldsForIntakeTeacher={hideFieldsForIntakeTeacher}
                    hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                    graphqlErrors={errors}
                >
                    {renderFormContent => (
                        <>
                            {renderFormContent()}
                            {this.renderFooter()}
                        </>
                    )}
                </LearnerProfileEditForm>
            </Wrap>
        )
    }

    public renderFooter() {
        const { hideFieldsForOrganizationContacts, learnerUserId } = this.props
        const { loading: mutationLoading } = this.userMutator

        return (
            <ActionBar
                fixed={true}
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem right={true}>
                            <Button type="submit" isLoading={mutationLoading}>
                                Opslaan
                            </Button>
                        </ListItem>
                        {!hideFieldsForOrganizationContacts && (
                            <ListItem right={true}>
                                <Button
                                    type="submit"
                                    styleOverride="secondary-submit"
                                    isLoading={mutationLoading}
                                    shouldNotPreventDefaultOnClick
                                    onClick={() => {
                                        this.userReturnUrl = `/users/learners/${learnerUserId}/availability`
                                    }}
                                >
                                    Opslaan en naar beschikbaarheid
                                </Button>
                            </ListItem>
                        )}
                        <ListItem right={true}>
                            <Button onClick={() => this.userReturnUrl && browserHistory.push(this.userReturnUrl)}>
                                Annuleren
                            </Button>
                        </ListItem>
                    </List>
                )}
            />
        )
    }
}

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`
