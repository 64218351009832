import React from 'react'
import { ValidationErrorType, InflowMomentFieldGroup } from './InflowMomentFieldGroup'
import { InflowMoment } from '~/types/InflowMoments'
import { CenterModal, Subtle, Link, Field } from '~/components'
import { getTimeFromMinutes } from '~/utils/getTimeFromMinutes'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { InflowMomentInflowModuleForm } from '../Forms/InflowMomentInflowModuleForm'
import { ValidationError } from '~/components/ValidationError'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Translator } from '~/services/i18n'
import { WithSubtle } from '~/components/WithSubtle'

interface Props {
    validationErrors?: ValidationErrorType[]
    inflowMoment: InflowMoment
    refetch: () => void
    errors: string[]
}

interface State {}

export class InflowMomentInflowModuleGroup extends React.Component<Props, State> {
    private translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { validationErrors, inflowMoment, errors } = this.props
        const { inflowModule } = inflowMoment
        const { t } = this.translator

        return (
            <InflowMomentFieldGroup
                title={`Instroommodule`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                inflowMoment={inflowMoment}
                seperateFields={true}
            >
                <Field>
                    <SimpleTable>
                        <SimpleTableRow>
                            <SimpleTableCell>Module</SimpleTableCell>
                            <SimpleTableCell>
                                {inflowModule ? (
                                    <Link route={`/properties/inflow/${inflowModule._id}`}>{inflowModule.name}</Link>
                                ) : (
                                    'Module is verwijderd'
                                )}
                            </SimpleTableCell>
                        </SimpleTableRow>
                        {inflowMoment.organization && (
                            <SimpleTableRow>
                                <SimpleTableCell>Organisatie</SimpleTableCell>
                                <SimpleTableCell>
                                    <Link route={`/properties/organizations/${inflowMoment.organization._id}`}>
                                        {inflowMoment.organization.name}
                                    </Link>
                                </SimpleTableCell>
                            </SimpleTableRow>
                        )}
                    </SimpleTable>
                    <Subtle>{this.renderInflowModuleInformation()}</Subtle>
                    {errors.includes('organization-is-required-when-moment-is-for-organization') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`Organisatie moet worden opgegeven wanneer het toetsmoment is aangemaakt voor een organisatie.`}
                        />
                    )}
                    {errors.includes('inflow-module-not-valid') && (
                        <ValidationError
                            isWarning
                            // tslint:disable-next-line:max-line-length
                            text={`Niet alle velden van de instroommodule zijn correct ingevuld.`}
                        />
                    )}
                </Field>
                {inflowModule && inflowModule.exams && (
                    <Field>
                        <Subtle>Toetsen</Subtle>
                        <List>
                            {inflowModule.exams.map(exam => {
                                if (!exam.exam) {
                                    return <></>
                                }

                                const possibleAbilities = ['converse', 'read', 'write', 'listen', 'talk']
                                const selectedAbilities = []

                                for (const ability in exam.abilities) {
                                    if (possibleAbilities.includes(ability) && exam.abilities[ability]) {
                                        selectedAbilities.push(t(`Properties.exam.abilities.${ability}`))
                                    }
                                }

                                return (
                                    <ListItem key={exam.examId}>
                                        <WithSubtle text={selectedAbilities.join(', ')}>
                                            <Link to={`/properties/exams/${exam.examId}`}>{exam.exam.name}</Link>
                                        </WithSubtle>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Field>
                )}
            </InflowMomentFieldGroup>
        )
    }

    private getEditModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Instroommodule bewerken`}>
                <InflowMomentInflowModuleForm inflowMoment={inflowMoment} onClose={closeModal} refetch={refetch} />
            </CenterModal>
        )
    }

    private renderInflowModuleInformation = () => {
        const { inflowMoment } = this.props
        const { inflowModule } = inflowMoment

        if (!inflowModule) {
            return null
        }

        return [
            `${getTimeFromMinutes(inflowModule.examMaxTimeMinutes || 0)} toetstijd`,
            `${inflowModule.maxStudentsPerStart || 0} kandidaten per startmoment per toetsdocent`,
            `${getTimeFromMinutes(inflowModule.examStartIntervalMinutes || 0)} interval`,
        ].join(' / ')
    }
}
