import './VariableMultiFieldBlock.scss'
import * as React from 'react'

import { Button, Icon } from '~/components'

interface Props {
    addButtonLabel?: string
    className?: string
    onAdd?: () => void
    isDisabled?: boolean
}

export class VariableMultiFieldBlockAddButton extends React.Component<Props> {
    public static defaultProps = {
        addButtonLabel: 'Toevoegen',
    }

    public render() {
        const { addButtonLabel, className, onAdd, isDisabled } = this.props
        return (
            <Button
                className={className}
                type={`button`}
                linkStyle={`default`}
                onClick={onAdd}
                isDisabled={isDisabled}
                leftIcon={<Icon name={`plus-bold`} />}
            >
                {addButtonLabel}
            </Button>
        )
    }
}
