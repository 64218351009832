import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{}, {}> {}

export default class ExternalOrganizationContactColleaguesView extends React.Component<Props> {
    public render() {
        const { children, ...routeProps } = this.props

        return (
            <BreadCrumbProvider>
                <RouteView crumbLabel={"Collega's"} routeProps={routeProps}>
                    {children}
                </RouteView>
            </BreadCrumbProvider>
        )
    }
}
