import c from 'classnames'
import { gql } from '@apollo/client'
import { debounce, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Emphasis, Icon, Input, Spinner } from '~/components'
import { Fetcher, parseGraphQLError } from '~/utils'

export class ProjectArticleCodeInput extends Component {
    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        defaultValue: PropTypes.string,
        errorKey: PropTypes.string,
        hidePossibleErrors: PropTypes.bool,
    }

    state = {
        isLoading: false,
        error: null,
        article: null,
    }

    constructor(props) {
        super(props)

        this.lookupTwinfieldArticleByCodeFetcher = new Fetcher({
            query: LOOKUP_TWINFIELD_ARTICLE_BY_CODE_QUERY,
            preventInitialFetch: true,
            preventErrorToast: true,
        })

        this.debouncedLookupTwinfieldArticle = debounce(this.lookupTwinfieldArticle, 1000)
    }

    lookupTwinfieldArticle = async code => {
        this.setState({ isLoading: true })

        try {
            const result = await this.lookupTwinfieldArticleByCodeFetcher.fetch({
                twinfieldArticleCode: code,
            })

            if (result) {
                const { twinfield_article } = result

                this.setState({ article: twinfield_article, error: null })
            }
        } catch (error) {
            const graphQLError = get(error, 'graphQLErrors[0]')
            const namespace = (get(graphQLError, 'path') || []).join('.')
            const { message } = parseGraphQLError(error, { namespace })

            this.setState({ article: null, error: message })
        }

        this.setState({ isLoading: false })
    }

    onChangeTwinfieldArticleCode = event => {
        const { value: code } = event.currentTarget

        this.setState({ article: null, error: null })

        if (code) {
            this.debouncedLookupTwinfieldArticle(code)
        }
    }

    render() {
        const { isLoading, error, article } = this.state
        const { name, defaultValue, errorKey, hidePossibleErrors } = this.props

        return (
            <div className={this.getClassName()}>
                <Input
                    type={`text`}
                    name={name}
                    errorKey={errorKey}
                    defaultValue={defaultValue}
                    onChange={this.onChangeTwinfieldArticleCode}
                    hasError={!hidePossibleErrors && !!error}
                />
                {isLoading && <Spinner size="small" />}
                {!hidePossibleErrors && error && <Emphasis warning>{error}</Emphasis>}
                {!!article && <Icon name={`checkmark`} />}
            </div>
        )
    }

    getClassName() {
        const { className } = this.props

        return c('tt-ProjectArticleCodeInput', className)
    }
}

const LOOKUP_TWINFIELD_ARTICLE_BY_CODE_QUERY = gql`
    query _($twinfieldArticleCode: String!) {
        twinfield_article(twinfieldArticleCode: $twinfieldArticleCode) {
            name
            shortName
            KPL
            PNL
        }
    }
`
