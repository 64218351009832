export interface Process {
    type: processType
    date: Date
    data: any
    first?: boolean
}

export enum processType {
    Group = 'Group',
    FinalExam = 'FinalExam',
    InflowMoment = 'InflowMoment',
    SignUps = 'SignUps',
}
