import * as React from 'react'
import c from 'classnames'

import './Strikethrough.scss'

interface Props {}

export class Strikethrough extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <strong className={this.getClassName()}>{children}</strong>
    }

    private getClassName() {
        return c('tt-Strikethrough')
    }
}
