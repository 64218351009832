import { gql } from '@apollo/client'
import { get } from 'lodash'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { View } from '~/components/View'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { FlagIcon } from '~/components/InvoiceStatusIndicator/icons'
import { Fetcher } from '~/utils'
import { RouteComponentProps } from 'react-router'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export default class DetailView extends Component<Props> {
    private learnerFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
            transformData: data => ({
                user: get(data, 'users[0]'),
            }),
        })
    }

    public componentDidUpdate({ params: prevParams }: Props) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.learnerFetcher.refetch({
                filters: {
                    byId: params.id,
                },
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { id: userId } = routeProps.params
        const {
            data: { user },
            loading,
            refetch,
        } = this.learnerFetcher

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {user && !this.isProcessHoursActive() && this.renderDefaultPageTabs()}
                </Header>
                <View>{user && cloneElement(children, { userId, refetch })}</View>
            </RouteView>
        )
    }

    private isProcessHoursActive = () => {
        const { location } = this.props

        const pathname = location.pathname

        return pathname.includes('hours')
    }

    private renderDefaultPageTabs = () => {
        const { ...routeProps } = this.props
        const { id: userId } = routeProps.params
        const {
            data: { user },
        } = this.learnerFetcher

        return (
            <PageTabs>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}/timeline`}>
                        Correspondentie
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}/process`}>
                        Traject
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}/availability`}>
                        Beschikbaarheid
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}/documents`}>
                        Documenten
                    </Link>
                </PageTab>
                <PageTab>
                    <Link route={`/users/learners/${userId}/progress`}>Resultaten</Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}/invoicing`}>
                        Facturatie {user.hasExpiredInvoices && <FlagIcon />}
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex route={`/users/learners/${userId}`}>
                        Gegevens
                    </Link>
                </PageTab>
            </PageTabs>
        )
    }
}

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            hasExpiredInvoices
            hasInvoicingEnabled
            learner {
                hasPrivatePayment
            }
            profile {
                name
            }
        }
    }
`
