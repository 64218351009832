import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class FieldSeparator extends React.Component<Props> {
    private bem = new BEM('FieldSeparator')

    public render() {
        return <div className={this.bem.getClassName()} />
    }
}
