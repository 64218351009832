import * as React from 'react'
import { browserHistory } from 'react-router'
import { fetchCurrentUser } from '~/services/session'
import { CenterView } from '~/components/CenterView'

interface Props {}

export default class CoverView extends React.Component<Props> {
    constructor(props: Props) {
        super(props)

        fetchCurrentUser({
            preventRedirectWhenUnauthenticated: true,
        }).then(user => {
            if (user) {
                browserHistory.replace('/')
            }
        })
    }

    public render() {
        const { children } = this.props

        return (
            <CenterView className={`tt-CoverView`} small={true}>
                {children}
            </CenterView>
        )
    }
}
