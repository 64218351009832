const { ENVIRONMENT = {} } = window

// TODO: remove the default values for AWS/Cognito
export default {
    development: ENVIRONMENT.NAME === 'development',
    production: ENVIRONMENT.NAME === 'production',
    googleAnalyticsKey: ENVIRONMENT.GOOGLE_ANALYTICS_KEY,
    serverBase: ENVIRONMENT.SERVER_BASE || '/',
    sentryDsn: ENVIRONMENT.SENRTY_DSN || '',
    sentryEnvironment: ENVIRONMENT.SENRTY_ENVIRONMENT || '',
    AWS_COGNITO_REGION: ENVIRONMENT.AWS_COGNITO_REGION || 'eu-central-1',
    AWS_COGNITO_POOL_ID: ENVIRONMENT.AWS_COGNITO_POOL_ID || 'eu-central-1_YcOcuJr2r',
    AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID:
        ENVIRONMENT.AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID || '1gla9gjfls7744makjglmomebk',
    AWS_COGNITO_DOMAIN: ENVIRONMENT.AWS_COGNITO_DOMAIN || 'toptaal-production.auth.eu-central-1.amazoncognito.com',
    AWS_COGNITO_REDIRECT_SIGNIN: ENVIRONMENT.AWS_COGNITO_REDIRECT_SIGNIN,
    AWS_COGNITO_REDIRECT_SIGNOUT: ENVIRONMENT.AWS_COGNITO_REDIRECT_SIGNOUT,
}
