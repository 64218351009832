export enum ExamLevel {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    _1F = '_1F', // Both name and value start with underscores because they can't start with a number
    _2F = '_2F',
    _3F = '_3F',
    _4F = '_4F',
}
