import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import { InflowMomentsSlotsTable } from '~/components/InflowMoment/InflowMomentsSlotsTable'
import { RouteComponentProps } from 'react-router'
import { InflowMoment } from '~/types/InflowMoments'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowMoment: InflowMoment
    refetch: (options?: { silent: boolean }) => void
}

export class DefinitiveInflowMomentsDetailSlotsDetailView extends React.Component<Props> {
    public render() {
        const { inflowMoment, refetch } = this.props

        return (
            <ContentView>
                <InflowMomentsSlotsTable
                    inflowMoment={inflowMoment}
                    onSubmitSuccess={() => refetch({ silent: true })}
                    refetch={refetch}
                />
            </ContentView>
        )
    }
}
