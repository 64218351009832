function fallbackCopyTextToClipboard(text: string, callback?: (success: boolean, error?: any) => void) {
    const textArea = document.createElement('textarea')
    textArea.value = text

    // hide the area
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    textArea.style.opacity = '0'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand('copy')
        if (callback) {
            callback(successful)
        }
    } catch (err) {
        if (callback) {
            callback(false, err)
        }
    }

    document.body.removeChild(textArea)
}

export function copyTextToClipboard(text: string, callback?: (success: boolean, error?: any) => void) {
    const nav: any = navigator

    if (!nav.clipboard) {
        fallbackCopyTextToClipboard(text, callback)
        return
    }
    nav.clipboard.writeText(text).then(
        () => {
            if (callback) {
                callback(true)
            }
        },
        (err: any) => {
            if (callback) {
                callback(false, err)
            }
        }
    )
}
