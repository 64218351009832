import * as React from 'react'
import { Form, FieldCollection, Field, Select, Input, Button } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { translateType } from '~/shared/utils'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { LessonUser } from '~/types/User'
import Option from '~/components/Option'
import { FormFields } from '~/components/Form'
import transformFormFields from '~/utils/transformFormFields'
import { Lesson, LessonReasonOfAbsence, LessonAttendance } from '~/types/Lesson'
import Mutator from '~/utils/Mutator'
import { gql } from '@apollo/client'
import { toast } from '~/utils'

const CHANGE_ATTENCANCE_MUTATION = gql`
    mutation _($lessonUser: LessonUserInputType!) {
        lessons_setPresence(lessonUser: $lessonUser) {
            _id
        }
    }
`

interface Props {
    lessonUser: LessonUser
    learnerUserId: string
    lesson: Lesson
    onCancel?: () => void
    onSubmitSuccess?: () => void
}

interface State {
    attendanceType: string | null
}

export class LearnerChangeAttendanceForm extends React.Component<Props> {
    public state: State = {
        attendanceType: this.props.lessonUser.attendance,
    }
    private attendanceMutator: Mutator
    private attendanceOptions = Object.values(LessonAttendance)

    private absentOptions = [
        LessonReasonOfAbsence.Other_Integrtion_Activity,
        LessonReasonOfAbsence.Sick,
        LessonReasonOfAbsence.Work_Study,
        LessonReasonOfAbsence.Vacation,
        LessonReasonOfAbsence.Private,
        LessonReasonOfAbsence.Transport_Issues,
        LessonReasonOfAbsence.Exam,
        LessonReasonOfAbsence.Other,
    ]

    constructor(props: Props) {
        super(props)

        this.attendanceMutator = new Mutator({
            mutation: CHANGE_ATTENCANCE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, lessonUser } = this.props
        const { attendanceType } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field title={`Presentie`}>
                            <Select
                                name={`attendance`}
                                defaultValue={lessonUser.attendance}
                                required={true}
                                onChange={(value: any) => this.setState({ attendanceType: value.currentTarget.value })}
                            >
                                <option value={``} selected={!lessonUser.attendance}>
                                    Presentie selecteren
                                </option>
                                {this.attendanceOptions.map((key, i) => (
                                    <Option value={key} key={i}>
                                        {translateType('lessonAttenance', key)}
                                    </Option>
                                ))}
                            </Select>
                        </Field>

                        {attendanceType !== null && (
                            <FieldGroup isInsetGroup={true} isForm={true}>
                                {attendanceType !== LessonAttendance.Present && (
                                    <Field title={`Reden`}>
                                        <Select
                                            name={`reasonOfAbsence`}
                                            defaultValue={lessonUser.reasonOfAbsence}
                                            required={true}
                                        >
                                            <option value={``} selected={!lessonUser.reasonOfAbsence}>
                                                Reden afwezigheid selecteren
                                            </option>
                                            {this.absentOptions.map((key, i) => (
                                                <Option value={key.toUpperCase()} key={i}>
                                                    {translateType('lessonReasonOfAbsence', key)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Field>
                                )}

                                <Field title={'Notitie'}>
                                    <Input
                                        name={`attendanceNote`}
                                        defaultValue={lessonUser.attendanceNote}
                                        placeholder={
                                            attendanceType === LessonAttendance.AbsentWithReason
                                                ? `Bericht van afmelding of notitie`
                                                : `Ruimte voor notitie`
                                        }
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={false}>
                                <Button linkStyle={'danger'} onClick={this.onDelete}>
                                    Presentie verwijderen
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`}>Opslaan</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onDelete = async () => {
        const { learnerUserId, lesson, onSubmitSuccess } = this.props
        try {
            const response = await this.attendanceMutator.mutate({
                lessonUser: {
                    lessonId: lesson._id,
                    learnerUserId,
                    nulls: ['attendance', 'reasonOfAbsence', 'attendanceNote'],
                },
            })

            if (response) {
                toast.success('Wijzigingen opgeslagen')

                if (onSubmitSuccess) {
                    onSubmitSuccess()
                }
            }
        } catch (error) {
            toast.error('Er is iets misgegaan!')
        }
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { learnerUserId, lesson, onSubmitSuccess } = this.props

        try {
            const response = await this.attendanceMutator.mutate({
                lessonUser: {
                    lessonId: lesson._id,
                    learnerUserId,
                    ...transformFormFields(fields),
                },
            })

            if (response) {
                toast.success('Wijzigingen opgeslagen')

                if (onSubmitSuccess) {
                    onSubmitSuccess()
                }
            }
        } catch (error) {
            toast.error('Er is iets misgegaan!')
        }
    }
}
