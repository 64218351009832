import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerProfileEdit } from '~/implementations/LearnerProfileEdit'

export default class EditView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        currentUser: PropTypes.object,
        refetch: PropTypes.func,
        isViewedByTeacherOfUser: PropTypes.bool,
        route: PropTypes.object,
        router: PropTypes.object,
    }

    render() {
        const { userId, refetch, route, router } = this.props

        return (
            <ContentView hasFixedActionBar>
                <LearnerProfileEdit learnerUserId={userId} refetch={refetch} route={route} router={router} />
            </ContentView>
        )
    }
}
