import * as React from 'react'
import { MultiInput, RadioButton } from '~/components'

interface Props {
    baseName: string
    isPassedDefaultSelected?: boolean
}

export class GroupExamResultMultiInput extends React.Component<Props, {}> {
    public render() {
        const { baseName, isPassedDefaultSelected } = this.props

        return (
            <MultiInput type={`radio`}>
                <RadioButton
                    name={`${baseName}.passedNotPassed`}
                    value={true}
                    defaultChecked={isPassedDefaultSelected === true}
                >
                    Voldoende
                </RadioButton>
                <RadioButton
                    name={`${baseName}.passedNotPassed`}
                    value={false}
                    defaultChecked={isPassedDefaultSelected === false}
                >
                    Onvoldoende
                </RadioButton>
            </MultiInput>
        )
    }
}
