import './AttendanceTableHeader.scss'

import { BEM } from '~/services/BEMService'
import * as React from 'react'

import { TableHeader } from '~/components'

interface Props {
    className?: string
    fixed?: boolean
    hidden?: boolean
}

export class AttendanceTableHeader extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceTableHeader', () => ({
        fixed: this.props.fixed,
        hidden: this.props.hidden,
    }))

    public render() {
        const { children } = this.props

        return <TableHeader className={this.bem.getClassName()}>{children}</TableHeader>
    }
}
