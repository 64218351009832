import * as React from 'react'

import { Header, PageTabs, PageTab, Link } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { View } from '~/components/View'
import { ConceptGroupsTable } from '~/implementations'
import { getCurrentUser } from '~/services/session'

export default class MasterView extends React.Component {
    public render() {
        return (
            <View>
                <Header>
                    <BreadCrumbs />
                    {this.isUserAllowedToView() && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/groups/concept`}>
                                    Concept
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/groups`}>
                                    Definitief
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                <ContentView>{this.isUserAllowedToView() && <ConceptGroupsTable />}</ContentView>
            </View>
        )
    }

    private isUserAllowedToView(): boolean {
        const currentUser = getCurrentUser()

        if (!currentUser) {
            return false
        }

        return currentUser.isEmployee
    }
}
