import React from 'react'
import { Spinner as Spin } from 'spin.js'
// import Spin from 'spin'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    color?: string
    size?: string
    overlay?: boolean
    instant?: boolean
    label?: string
}

interface State {
    show: boolean
}

export default class Spinner extends React.Component<Props, State> {
    public static defaultProps = {
        instant: false,
    }

    public state: State = {
        show: true,
    }

    private timer: any = null
    private node = React.createRef<HTMLSpanElement>()
    private bem = new BEM('Spinner', () => ({
        tiny: this.props.size === 'tiny',
        small: this.props.size === 'small',
        large: this.props.size === 'large',
        'is-overlay': this.props.overlay,
        'is-hidden': !this.state.show,
        'has-label': !!this.props.label,
    }))

    public render() {
        const { className, label } = this.props

        return (
            <span className={this.bem.getClassName(className)}>
                <span className={this.bem.getElement('spinner')} ref={this.node} />
                {label && <span className={this.bem.getElement('label')}>{label}</span>}
            </span>
        )
    }

    public componentWillUnmount() {
        clearTimeout(this.timer)
        this.timer = null
    }

    public componentDidMount() {
        const { size, overlay, color } = this.props

        const options = {
            lines: 13, // The number of lines to draw
            length: 8, // The length of each line
            width: 2, // The line thickness
            radius: 8, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#000', // #rgb or #rrggbb
            speed: 1.2, // Rounds per second
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: '', // The CSS class to assign to the spinner
            zIndex: 0, // The z-index (defaults to 2000000000)
            top: '50%', // Top position relative to parent in px
            left: '50%', // Left position relative to parent in px
            animation: 'spinner-line-fade-quick',
        }

        if (overlay) {
            options.color = '#fff'
        }

        if (color) {
            options.color = color
        }

        switch (size) {
            case 'tiny':
                options.length = 3
                options.width = 1
                options.radius = 3
                break
            case 'small':
                options.length = 4
                options.width = 1
                options.radius = 4
                break
            case 'large':
                options.length = 8
                options.width = 3
                options.radius = 12
                break
            default:
                options.length = 3
                options.width = 1
                options.radius = 3
                break
        }

        if (this.node) {
            new Spin(options).spin(this.node.current as HTMLSpanElement)
        }

        this.timer = setTimeout(() => {
            this.setState({
                show: true,
            })
        }, 250)
    }
}
