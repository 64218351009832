import './GroupLearnerParticipantsTableRowExpansion.scss'
import React from 'react'
import TableCellDetail from '~/components/TableCellDetail'
import { LearnerGroupParticipationHistory } from '~/components/Group/Participants/Misc/LearnerGroupParticipationHistory/LearnerGroupParticipationHistory'
import { Button } from '~/components/buttons/Button/Button'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'
import Icon from '~/components/Icon'
import { GroupUserEnrollment, GroupUserEnrollmentType } from '~/types/User'
import { GroupLearnerParticipantUndoEnrollmentModalManager } from '~/components/Group/Participants/Modals/GroupLearnerParticipantUndoEnrollmentModalManager'
import { GroupLearnerParticipantUndoDisenrollmentModalManager } from '~/components/Group/Participants/Modals/GroupLearnerParticipantUndoDisenrollmentModalManager'
import { GroupLearnerParticipantDisenrollModalManager } from '~/components/Group/Participants/Modals/GroupLearnerParticipantDisenrollModalManager'
import { GroupLearnerParticipantEnrollModalManager } from '~/components/Group/Participants/Modals/GroupLearnerParticipantEnrollModalManager'

interface Props {
    className?: string
    userId: string
    userName: string
    enrollments: GroupUserEnrollment[]
    hasLearnerFinalEnrollment: boolean
    hasAttendancesAfterLastEnrollment: boolean
    hasInvoicesAfterLastEnrollment: boolean
    groupId: string
    groupName: string
    refetchLearnerParticipants: () => void
}

export class GroupLearnerParticipantsTableRowExpansion extends React.PureComponent<Props> {
    private bem = new BEM('GroupLearnerParticipantsTableRowExpansion')

    public render() {
        const { className, userId, groupId, enrollments, hasLearnerFinalEnrollment } = this.props

        return (
            <TableCellDetail className={this.bem.getClassName(className)}>
                <LearnerGroupParticipationHistory userId={userId} groupId={groupId} enrollments={enrollments} />

                {hasLearnerFinalEnrollment
                    ? this.renderButtonsForEnrolledUser()
                    : this.renderButtonsForDisenrolledUser()}
            </TableCellDetail>
        )
    }

    private renderButtonsForEnrolledUser() {
        const {
            userId,
            userName,
            enrollments,
            hasAttendancesAfterLastEnrollment,
            hasInvoicesAfterLastEnrollment,
            groupId,
            groupName,
            refetchLearnerParticipants,
        } = this.props

        const lastEnrollmentDate = this.getLastEnrollmentDate(enrollments)

        return (
            <List forSmallButtons={true} horizontal={true}>
                <ListItem>
                    <GroupLearnerParticipantDisenrollModalManager
                        userId={userId}
                        userName={userName}
                        lastEnrollmentDate={lastEnrollmentDate}
                        groupId={groupId}
                        groupName={groupName}
                        onDisenroll={() => {
                            refetchLearnerParticipants()
                        }}
                        render={openModal => (
                            <Button small leftIcon={<Icon name={`trash-small`} />} onClick={openModal}>
                                Uitschrijven
                            </Button>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <GroupLearnerParticipantUndoEnrollmentModalManager
                        userId={userId}
                        userName={userName}
                        groupId={groupId}
                        lastEnrollmentDate={lastEnrollmentDate}
                        hasAttendancesAfterLastEnrollment={hasAttendancesAfterLastEnrollment}
                        hasInvoicesAfterLastEnrollment={hasInvoicesAfterLastEnrollment}
                        onUndoEnrollment={() => {
                            refetchLearnerParticipants()
                        }}
                        render={openModal => (
                            <Button small leftIcon={<Icon name={`reload`} />} onClick={openModal}>
                                Laatste inschrijving ongedaan maken
                            </Button>
                        )}
                    />
                </ListItem>
            </List>
        )
    }

    private renderButtonsForDisenrolledUser() {
        const { userId, userName, enrollments, groupId, groupName, refetchLearnerParticipants } = this.props

        const lastDisenrollmentDate = this.getLastDisenrollmentDate(enrollments)

        return (
            <List forSmallButtons={true} horizontal={true}>
                <ListItem>
                    <GroupLearnerParticipantEnrollModalManager
                        userId={userId}
                        userName={userName}
                        lastDisenrollmentDate={lastDisenrollmentDate}
                        groupId={groupId}
                        groupName={groupName}
                        onEnroll={() => {
                            refetchLearnerParticipants()
                        }}
                        render={openModal => (
                            <Button small leftIcon={<Icon name={`plus`} />} onClick={openModal}>
                                Opnieuw inschrijven
                            </Button>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <GroupLearnerParticipantUndoDisenrollmentModalManager
                        userId={userId}
                        userName={userName}
                        groupId={groupId}
                        lastDisenrollmentDate={lastDisenrollmentDate}
                        onUndoDisenrollment={() => {
                            refetchLearnerParticipants()
                        }}
                        render={openModal => (
                            <Button small leftIcon={<Icon name={`reload`} />} onClick={openModal}>
                                Laatste uitschrijving ongedaan maken
                            </Button>
                        )}
                    />
                </ListItem>
            </List>
        )
    }

    private getLastEnrollmentDate(enrollments: GroupUserEnrollment[]): Date {
        const sortedEnrollments = [...enrollments]
            .filter(enrollment => {
                return enrollment.type === GroupUserEnrollmentType.Enrollment
            })
            .sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime())

        const lastEnrollment = sortedEnrollments[0]

        return lastEnrollment.date!
    }

    private getLastDisenrollmentDate(enrollments: GroupUserEnrollment[]): Date {
        const sortedEnrollments = [...enrollments]
            .filter(enrollment => {
                return enrollment.type === GroupUserEnrollmentType.Disenrollment
            })
            .sort((a, b) => new Date(b.date!).getTime() - new Date(a.date!).getTime())

        const lastEnrollment = sortedEnrollments[0]

        return lastEnrollment.date!
    }
}
