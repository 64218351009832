import './TimelineItem.scss'
import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    position?: string
}

interface State {}

export class TimelineItem extends React.Component<Props, State> {
    private bem = new BEM('TimelineItem')

    public render() {
        const { children, className, position = 'regular-item' } = this.props
        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('content')}>
                    <span className={this.bem.getElement(position)} />
                    <span className={this.bem.getElement('circle')} />
                    {children}
                </div>
            </div>
        )
    }
}
