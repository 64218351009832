import { GroupUserRemovedReason } from '~/types/User'

export const sortedGroupParticipationDisenrollmentReasons = [
    GroupUserRemovedReason.EarlyDisenrollmentNotSatisfiedWithTopTaal,
    GroupUserRemovedReason.EarlyDisenrollmentMovedHouse,
    GroupUserRemovedReason.EarlyDisenrollmentWentToOtherSchool,
    GroupUserRemovedReason.EarlyDisenrollmentUnreachable,
    GroupUserRemovedReason.EarlyDisenrollmentFoundWork,
    GroupUserRemovedReason.EarlyDisenrollmentLevelUnsuitable,
    GroupUserRemovedReason.EarlyDisenrollmentUnavailable,
    GroupUserRemovedReason.EarlyDisenrollmentMedical,
    GroupUserRemovedReason.EarlyDisenrollmentMotivation,
    GroupUserRemovedReason.EarlyDisenrollmentLeavingEmployer,
    GroupUserRemovedReason.EarlyDisenrollmentOtherPersonalReasons,
    GroupUserRemovedReason.ReturnBeforeStart,
    GroupUserRemovedReason.AlmostSuccessfull,
    GroupUserRemovedReason.OnHold,
    GroupUserRemovedReason.ExemptionRoute,
    GroupUserRemovedReason.Other,
]
