import { gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'

import {
    Button,
    CenterModal,
    Icon,
    Link,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { Wrap } from '~/components/Wrap'
import { RoomsForm } from '~/forms'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { RouteComponentProps } from 'react-router'
import { Location, Room } from '~/types/Location'
import { FormFields } from '~/components/Form'

const GET_ROOMS_QUERY = gql`
    query _($_id: MongoID) {
        locations(byId: $_id) {
            _id
            rooms {
                _id
                name
                capacity
                locationProperties {
                    _id
                    name
                }
            }
        }
    }
`

const CREATE_ROOM_MUTATION = gql`
    mutation _($room: RoomInputType!) {
        rooms_create(room: $room) {
            _id
            name
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    locationObject: Location
    refetch?: (options?: any) => void
}

export default class RoomsView extends React.Component<Props> {
    private roomsFetcher: Fetcher
    private roomCreateMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.roomsFetcher = new Fetcher({
            query: GET_ROOMS_QUERY,
            variables: { _id: props.locationObject._id },

            onChange: () => this.forceUpdate(),
        })

        this.roomCreateMutator = new Mutator({
            mutation: CREATE_ROOM_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { loading, data } = this.roomsFetcher
        const { errors, loading: mutateLoading } = this.roomCreateMutator
        const rooms = get(data, 'locations[0].rooms')

        return (
            <ContentView>
                <TableWrap>
                    <TableView>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem width="35%">Naam</TableHeaderItem>
                                <TableHeaderItem>Capaciteit</TableHeaderItem>
                                <TableHeaderItem>Kenmerken</TableHeaderItem>
                            </TableHeader>
                            {loading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={3} isLoading={true} />
                                </TableRow>
                            ) : rooms.length > 0 ? (
                                this.renderRoomRows(rooms)
                            ) : (
                                <TableRow key={`emptyresult`}>
                                    <TableCell colSpan={3}>
                                        <Subtle>Er zijn geen lesruimtes gevonden.</Subtle>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableView>
                </TableWrap>
                <Wrap full={true}>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={`button`} leftIcon={<Icon name={`plus`} />}>
                                Lesruimte toevoegen
                            </Button>
                        )}
                        getModal={closeModal => (
                            <CenterModal onClose={closeModal} title={`Lesruimte toevoegen`}>
                                <RoomsForm
                                    onSubmit={(event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
                                        this.onCreateSubmit(event, fields, closeModal)
                                    }}
                                    closeModal={closeModal}
                                    errors={errors}
                                    loading={mutateLoading}
                                />
                            </CenterModal>
                        )}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private renderRoomRows = (rooms: Room[]) => {
        const { locationObject } = this.props

        return rooms.map(room => {
            const { locationProperties } = room

            return (
                <TableRow key={room._id}>
                    <TableCell>
                        <Link route={`/properties/locations/${locationObject._id}/rooms/${room._id}`}>{room.name}</Link>
                    </TableCell>
                    <TableCell>{room.capacity}</TableCell>
                    <TableCell>{locationProperties && locationProperties.map(p => p.name).join(', ')}</TableCell>
                </TableRow>
            )
        })
    }

    private onCreateSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
        fields: FormFields,
        closeRoomModal: () => void
    ) => {
        const { locationObject } = this.props

        const data = await this.roomCreateMutator.mutate({
            room: {
                locationId: locationObject._id,
                ...transformFormFields(fields.room, {
                    locationPropertyIds: transformLib.split(),
                }),
            },
        })

        if (data) {
            closeRoomModal()
            this.roomsFetcher.refetch()
        }
    }
}
