import * as React from 'react'
const moment = require('moment')
import c from 'classnames'

import Input, { InputProps } from './Input'
import { Icon } from '.'

interface Props extends InputProps {}

export default class DatePicker extends React.Component<Props> {
    public render() {
        const {
            className: extraClassNames,
            hasError,
            defaultValue,
            value,
            min,
            max,
            hasIcon,
            ...restProps
        } = this.props

        const className = c('tt-DatePicker', extraClassNames, {
            'tt-DatePicker--has-error': hasError,
            'tt-DatePicker--has-icon': hasIcon,
        })

        return (
            <div className={className}>
                {hasIcon && <Icon name={`calendar`} />}
                <Input
                    {...restProps}
                    type="date"
                    value={value && this.getFormattedValue(value)}
                    defaultValue={defaultValue && this.getFormattedValue(defaultValue)}
                    min={min && this.getFormattedValue(min)}
                    max={max && this.getFormattedValue(max)}
                />
            </div>
        )
    }

    private getFormattedValue(value: Date | string | number | string[] | boolean) {
        // Detect if value is already correctly formatted
        if (moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value) {
            return value
        }

        /**
         * This works for JS Dates, ISO Strings
         * and moment objects as date input
         */
        return moment(value).format('YYYY-MM-DD')
    }
}
