import * as React from 'react'

import { TagPicker, TagPickerProps } from '~/components/TagPicker'
import { ExamLevel } from '~/types/ExamLevel'
import translateType from '~/shared/utils/translateType'

interface Props extends TagPickerProps {}

export class ExamLevelInput extends React.Component<Props> {
    public render() {
        const { ...restProps } = this.props

        return (
            <TagPicker
                multi={false}
                options={Object.values(ExamLevel).map(level => ({
                    label: translateType('level', level),
                    value: level,
                }))}
                {...restProps}
            />
        )
    }
}
