import { gql } from '@apollo/client'
import * as React from 'react'

import { Button, Field, FieldCollection, Form, FileInput } from '~/components'
import { Mutator, toast } from '~/utils'
import { Invoice } from '~/types/Invoice'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    noConfirm: boolean
    invoice: Invoice
    onCancel: () => void
    onSubmitSuccess: () => void
}

interface State {}

const UPLOAD_SIGNATURE_MUTATION = gql`
    mutation _($invoiceId: MongoID!, $signatureFile: UploadedFile!) {
        invoices_uploadSignatureForDuo(invoiceId: $invoiceId, signatureFile: $signatureFile) {
            _id
        }
    }
`

export class UploadSignatureForm extends React.Component<Props, State> {
    private uploadSignatureMutator: Mutator

    constructor(props: Props) {
        super(props)

        console.log('props', props)
        this.uploadSignatureMutator = new Mutator({
            mutation: UPLOAD_SIGNATURE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, noConfirm } = this.props
        const { loading, errors } = this.uploadSignatureMutator

        // Store the form reference so we can pass the fields
        // to the endGroup method.
        let formRef: any

        return (
            <Form ref={ref => (formRef = ref)}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Getekende factuur`} errors={errors}>
                            <FileInput name={`signatureFile`}>Bladeren</FileInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={loading}
                                    shouldPreventSubmit
                                    onClick={() => {
                                        this.onSubmit(undefined, formRef.getFormValues())
                                    }}
                                    {...(noConfirm
                                        ? {}
                                        : {
                                              confirm: {
                                                  title: 'Handtekening uploaden',
                                                  message: `Hiermee maak je de factuur definitief en wordt de
                                                factuur klaargezet voor de financiële afdeling.`,
                                                  execute: {
                                                      buttonType: 'submit',
                                                      title: 'Bevestigen',
                                                  },
                                              },
                                          })}
                                >
                                    Uploaden
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { invoice, onSubmitSuccess } = this.props

        console.log('fields', fields.signatureFile)
        const data = await this.uploadSignatureMutator.mutate({
            invoiceId: invoice._id,
            signatureFile: fields.signatureFile,
        })

        if (data && data.invoices_uploadSignatureForDuo) {
            toast.success(`De factuur staat klaar voor verzending.`)
            onSubmitSuccess()
        }
    }
}
