import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { gql } from '@apollo/client'

import { RouteParams as ProjectDetailRouteParams } from '../../../Detail'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Fetcher } from '~/utils'
import { Project } from '~/types/Project'
import { ProjectInvoicingSetting } from '~/types/ProjectInvoicingSetting'

export interface RouteParams extends ProjectDetailRouteParams {
    projectInvoicingSettingId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    refetchProject: () => void
    project: Project
}

export default class AppPropertiesProjectsDetailInvoicingSettingsDetail extends React.Component<Props> {
    private invoicingSettingFetcher = new Fetcher({
        query: GET_PROJECT_INVOICE_SETTING_QUERY,
        variables: {
            projectId: this.props.params.id,
            projectInvoicingSettingId: this.props.params.projectInvoicingSettingId,
        },

        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { children, project, refetchProject, ...routeProps } = this.props
        const { loading, refetch } = this.invoicingSettingFetcher

        const projectInvoicingSetting = this.getProjectInvoicingSettingFromFetcher()

        return (
            <RouteView
                crumbLabel={projectInvoicingSetting && projectInvoicingSetting.name}
                routeProps={routeProps}
                isLoading={loading}
            >
                {projectInvoicingSetting &&
                    React.cloneElement(React.Children.only(children) as any, {
                        project,
                        projectInvoicingSetting,
                        refetchProject,
                        refetchInvoicingSetting: refetch,
                    })}
            </RouteView>
        )
    }

    private getProjectInvoicingSettingFromFetcher(): ProjectInvoicingSetting | undefined {
        const { data } = this.invoicingSettingFetcher

        const project: Project = data && data.projects && data.projects[0]

        return project && project.projectInvoicingSettings && project.projectInvoicingSettings[0]
    }
}

const GET_PROJECT_INVOICE_SETTING_QUERY = gql`
    query _($projectId: MongoID, $projectInvoicingSettingId: MongoID) {
        projects(byId: $projectId) {
            _id
            projectInvoicingSettings(byId: $projectInvoicingSettingId) {
                _id
                name
                invoiceGranularity
                programs {
                    _id
                    name
                }
                priceType
                fixedPriceSettings {
                    amount
                }
                variablePriceSettings {
                    variableBy
                    amountRanges {
                        to
                        amount
                    }
                }
                invoiceTiming
                periodicalTimingSettings {
                    period
                    chargeForLessonAttendanceType
                    chargeForLessonReasonOfAbsences
                }
                variableTimingSettings {
                    chargeForLessonsType
                    periods {
                        percentageOfPassedLessons
                        percentageOfPrice
                        conditionSettings {
                            condition
                            isChecked
                            description
                        }
                    }
                }
            }
        }
    }
`
