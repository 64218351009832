import { gql } from '@apollo/client'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DrawerFooter from '~/components/DrawerFooter'

import { Calendar, DrawerHeader, FieldCollection, Form, Paragraph, Subtle } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { Mutator } from '~/utils'

export default class EditGroupLessonDaysForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
        onFormCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        const initialEvents = this.createInitialEvents()

        this.state = {
            lessonDates: initialEvents,
        }

        this.editGroupMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = async () => {
        const { onSubmitSuccess, group } = this.props

        if (!group) {
            return
        }

        const generalWeekLessonDates = this.state.lessonDates
            .sort((a, b) => a.start.unix() - b.start.unix())
            .map(event => event.start.toDate())

        const data = await this.editGroupMutator.mutate({
            group: {
                _id: group._id,
                generalWeekLessonDates,
            },
        })

        if (data) {
            onSubmitSuccess()
        }
    }

    onCancel = () => {
        if (this.props.onFormCancel) {
            this.props.onFormCancel()
        }
    }

    onSelectSlot = (start, end, _, { calendar }) => {
        const { group } = this.props
        const module = get(group, 'module') || {}
        const lessonDuration = module.lessonDuration || 60

        // Unselect because we want to programatically add the event via renderEvent.
        calendar.unselect()

        if (module && this.state.lessonDates.length >= module.amountOfWeeklyLessons) {
            return
        }

        start.minute(0)

        end = start.clone().add(lessonDuration, 'minutes')

        const event = {
            title: '',
            start,
            end,
            editable: true,
            type: 'LESSON_DATE',
        }

        // Todo: Should maybe check if the events overlaps any other events

        const [{ _id }] = calendar.renderEvent(event)

        this.setState({
            lessonDates: [
                ...this.state.lessonDates,
                {
                    ...event,
                    _id,
                },
            ],
        })
    }

    onClickEvent = ({ _id }, _, { calendar }) => {
        calendar.removeEvents(_id)
        this.setState({
            lessonDates: [...this.state.lessonDates.filter(({ _id: eventId }) => eventId !== _id)],
        })
    }

    onMoveEvent = ({ _id, start, end }) => {
        const event = this.state.lessonDates.find(({ _id: eventId }) => eventId === _id)

        if (!event) {
            return
        }

        this.setState({
            lessonDates: [
                ...this.state.lessonDates.filter(({ _id: eventId }) => eventId !== _id),
                {
                    ...event,
                    start,
                    end,
                    _id,
                },
            ],
        })
    }

    createInitialEvents() {
        const { group } = this.props
        const module = get(group, 'module') || {}
        const generalWeekLessonDates = get(group, 'generalWeekLessonDates') || []
        const lessonDuration = module.lessonDuration || 60

        return generalWeekLessonDates.map((date, index) => ({
            _id: `_tt${index + 1}`,
            title: '',
            start: moment(date),
            end: moment(date).add(lessonDuration, 'minutes'),
            editable: true,
            type: 'LESSON_DATE',
        }))
    }

    render() {
        const { loading: isMutationLoading } = this.editGroupMutator

        const { group } = this.props
        const module = get(group, 'module')
        const maxAmountOfLessons = get(module, 'amountOfWeeklyLessons')
        const { lessonDates } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <DrawerHeader title={`Lesdagen toevoegen`} close={this.onCancel} />
                    <ContentView>
                        <Wrap full>
                            <Paragraph>
                                <Subtle>
                                    Lesdag {lessonDates.length}/{maxAmountOfLessons}
                                </Subtle>
                            </Paragraph>
                            <Calendar
                                // TODO: Fix this issue and re-enable the no-string-refs rule
                                // eslint-disable-next-line react/no-string-refs
                                ref="Calendar"
                                defaultView="agendaWeek"
                                disableViewChange
                                isGeneral
                                minHours={8}
                                maxHours={22}
                                allowEditEvents={true}
                                allowEditEventStart={true} // overwrite specific edits
                                allowEditEventDuration={false} // overwrite specific edits
                                disablePlaceholder={true}
                                height="auto"
                                selectSlotType="LESSON_DATE"
                                allowCreateEvents
                                events={lessonDates}
                                snapDuration={'00:05'}
                                onSelectSlot={this.onSelectSlot}
                                onClickEvent={this.onClickEvent}
                                onMoveEvent={this.onMoveEvent}
                            />
                        </Wrap>
                    </ContentView>
                </FieldCollection>
                <DrawerFooter close={this.onCancel} isLoading={isMutationLoading} />
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`
