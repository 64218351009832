import './HistoryLog.scss'
import * as React from 'react'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import ReadableDate from '~/components/ReadableDate'
import { BEM } from '~/services/BEMService'
import ToolTip, { Placement } from '~/components/ToolTip'

export interface HistoryLogItem {
    actionNode: React.ReactNode
    actorName?: string
    date: Date
}

interface Props {
    historyLogItems: HistoryLogItem[]
    className?: string
}

export class HistoryLog extends React.PureComponent<Props> {
    private bem = new BEM('HistoryLog')

    public render() {
        const { historyLogItems, className } = this.props

        return (
            <SimpleTable className={this.bem.getClassName(className)}>
                {historyLogItems.map((historyLogItem, index) => {
                    return <React.Fragment key={index}>{this.renderHistoryLogItem(historyLogItem)}</React.Fragment>
                })}
            </SimpleTable>
        )
    }

    private renderHistoryLogItem(historyLogItem: HistoryLogItem): React.ReactNode {
        return (
            <SimpleTableRow>
                <SimpleTableCell isSubtle>
                    <ToolTip
                        text={historyLogItem.actorName && `door ${historyLogItem.actorName}`}
                        placement={Placement.topRight}
                    >
                        <ReadableDate date={historyLogItem.date} format={`DD-MM-YYYY`} />
                    </ToolTip>
                </SimpleTableCell>
                <SimpleTableCell>{historyLogItem.actionNode}</SimpleTableCell>
            </SimpleTableRow>
        )
    }
}
