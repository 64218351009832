import React from 'react'
import { RouteComponentProps, browserHistory } from 'react-router'

import { InflowMoment } from '~/types/InflowMoments'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { FieldCollection, Field, Button, Icon } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { InflowMomentAddButtons } from '~/components/InflowMoment/InflowMomentAddButtons'
import FieldGroups from '~/components/InflowMoment/FieldGroups'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Mutator } from '~/utils'
import {
    DELETE_INFLOW_MOMENT_MUTATION,
    MAKE_INFLOW_MOMENT_FINAL_MUTATION,
} from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { InflowMomentError } from '..'
import { TimeSlotsTable } from '~/components/InflowMoment/TimeSlotsTable'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowMoment: InflowMoment
    refetch: (options?: { silent: boolean }) => void
    inflowMomentIsValid: boolean
    inflowMomentErrors: InflowMomentError[]
}

export enum ConceptInflowMomentKeys {
    InflowModule = 'InflowModule',
    Location = 'Location',
    DateRange = 'DateRange',
    IntakerUsers = 'IntakerUsers',
    EmployeeUsers = 'EmployeeUsers',
}

export class AppPropertiesInflowMomentsConceptsDetailData extends React.Component<Props, {}> {
    private deleteInflowMomentMutator: Mutator
    private makeInflowMomentFinalMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.deleteInflowMomentMutator = new Mutator({
            mutation: DELETE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.makeInflowMomentFinalMutator = new Mutator({
            mutation: MAKE_INFLOW_MOMENT_FINAL_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { inflowMoment, refetch } = this.props

        const { setKeys, unsetKeys } = Object.values(ConceptInflowMomentKeys).reduce(
            (obj, key) => {
                if (this.isKeySet(key)) {
                    obj.setKeys.push(key)
                } else {
                    obj.unsetKeys.push(key)
                }

                return obj
            },
            { setKeys: [], unsetKeys: [] } as {
                setKeys: ConceptInflowMomentKeys[]
                unsetKeys: ConceptInflowMomentKeys[]
            }
        )

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        {this.renderFormFieldGroups(setKeys)}

                        {inflowMoment && inflowMoment.timeslotsProjected && (
                            <FieldGroup title=" ">
                                <Field>
                                    <TimeSlotsTable
                                        projectedTimeslots={inflowMoment.timeslotsProjected}
                                        dateRange={inflowMoment.dateRange}
                                        employees={inflowMoment.employeeUsers}
                                    />
                                </Field>
                            </FieldGroup>
                        )}

                        {inflowMoment && unsetKeys.length > 0 && (
                            <FieldGroup title={' '}>
                                <Field>
                                    <InflowMomentAddButtons
                                        inflowMoment={inflowMoment}
                                        refetchInflowMoment={refetch}
                                        showButtonsOfKeys={unsetKeys}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldCollection>
                </Wrap>
                {this.renderFooter()}
            </ContentView>
        )
    }

    private isKeySet = (key: string) => {
        const { inflowMoment } = this.props

        if (!inflowMoment) {
            return false
        }

        return {
            InflowModule: true,
            Location: !!inflowMoment.location,
            DateRange: !!inflowMoment.dateRange,
            EmployeeUsers: inflowMoment.employeeUsers && inflowMoment.employeeUsers.length > 0,
            IntakerUsers: inflowMoment.intakerUsers && inflowMoment.intakerUsers.length > 0,
        }[key]
    }

    private renderFormFieldGroups(setKeys: ConceptInflowMomentKeys[]) {
        const { inflowMoment, refetch, inflowMomentErrors } = this.props

        return setKeys
            .map(key => ({ key, FieldGroupComponent: FieldGroups[key] }))
            .map(({ key, FieldGroupComponent }) => (
                <FieldGroupComponent
                    key={key}
                    inflowMoment={inflowMoment}
                    refetch={refetch}
                    errors={inflowMomentErrors.map(error => error.key)}
                    // validationErrors={this.getValidationErrorsForKey(key)}
                />
            ))
    }

    private renderFooter = () => {
        const { inflowMomentIsValid } = this.props

        return (
            <ActionBar
                fixed={true}
                isWide={true}
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                linkStyle={`danger`}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                                onClick={this.onDelete}
                            >
                                Verwijderen
                            </Button>
                        </ListItem>
                        <ListItem right={true}>
                            <Button
                                onClick={this.onMakeFinal}
                                // isDisabled={!inflowMomentIsValid}
                                isDisabled={!inflowMomentIsValid}
                                leftIcon={<Icon name={`flag`} />}
                                confirm={{
                                    title: 'Toetsmoment definitief maken',
                                    // tslint:disable-next-line:max-line-length
                                    message: `Je staat op het punt om het toetsmoment definitief te maken. Weet je zeker dat alles goed is ingevuld?`,
                                    execute: {
                                        buttonType: 'submit',
                                        title: 'Bevestigen',
                                        buttonCountdown: 6,
                                    },
                                }}
                            >
                                Toetsmoment definitief maken
                            </Button>
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private onDelete = async () => {
        const { refetch } = this.props
        const { id } = this.props.params

        const data = await this.deleteInflowMomentMutator.mutate({
            inflowMomentId: id,
        })

        if (data) {
            refetch({ silent: true })
            browserHistory.push('/inflow-moments/concept')
        }
    }

    private onMakeFinal = async () => {
        // const { refetch } = this.props
        const { id } = this.props.params

        const data = await this.makeInflowMomentFinalMutator.mutate({
            inflowMomentId: id,
        })

        if (data) {
            // refetch({ silent: true })
            browserHistory.push(`/inflow-moments/definitive/${id}`)
        }
    }
}
