import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { ReadableDate, Link, Hint } from '~/components'

export class GroupParticipantName extends Component {
    static propTypes = {
        groupUser: PropTypes.shape({
            user: PropTypes.object.isRequired,
            role: PropTypes.string.isRequired,
            isNowEnrolled: PropTypes.bool,
            hasLearnerFutureEnrollment: PropTypes.bool,
            hasLearnerFinalEnrollment: PropTypes.bool,
            enrollments: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    type: PropTypes.string.isRequired,
                    date: PropTypes.string.isRequired,
                    createdByUser: PropTypes.object.isRequired,
                }).isRequired
            ),
        }).isRequired,
        linkToGroupId: PropTypes.string,
        disableLink: PropTypes.bool,
    }

    getUserRoute() {
        const { linkToGroupId } = this.props
        const { user, role } = this.props.groupUser

        const path = linkToGroupId ? `/groups/${linkToGroupId}` : `/users`

        switch (role) {
            case 'LEARNER':
                return `${path}/learners/${user._id}`
            case 'TEACHER':
                return `${path}/teachers/${user._id}`
            case 'EMPLOYEE':
                return `${path}/employees/${user._id}`
        }
    }

    getUserName() {
        return get(this.props.groupUser.user, 'profile.name')
    }

    isRemovedFromGroup() {
        if (this.props.groupUser.role === 'LEARNER') {
            const { isNowEnrolled, hasLearnerFinalEnrollment } = this.props.groupUser

            return !isNowEnrolled && !hasLearnerFinalEnrollment
        }

        return false
    }

    hasLearnerFutureEnrollment() {
        if (this.props.groupUser.role === 'LEARNER') {
            const { hasLearnerFutureEnrollment } = this.props.groupUser

            return hasLearnerFutureEnrollment
        }

        return false
    }

    render() {
        const { disableLink, groupUser } = this.props
        const { role } = groupUser

        return (
            <div>
                {disableLink && this.getUserName()}
                {!disableLink && (
                    <Link
                        route={this.getUserRoute()}
                        hasLineThrough={this.isRemovedFromGroup() && !this.hasLearnerFutureEnrollment()}
                    >
                        {this.getUserName()}
                    </Link>
                )}
                {role === 'LEARNER' && this.renderLearnerHint()}
            </div>
        )
    }

    renderLearnerHint() {
        const { hasLearnerFinalEnrollment, isNowEnrolled, enrollments } = this.props.groupUser
        const firstEnrollment = enrollments[0]
        const lastEnrollment = enrollments[enrollments.length - 1]

        if (!firstEnrollment || !lastEnrollment) {
            /**
             * Something is wrong
             */
            console.log('Something is wrong. No enrollments found.', enrollments)
            return null
        }

        if (firstEnrollment.type === 'Disenrollment') {
            console.log('Something is wrong, enrollments start with a Disenrollment', enrollments)
        }

        const isFirstEnrollmentAfterNow = moment(firstEnrollment.date).isAfter()

        if (isFirstEnrollmentAfterNow) {
            return (
                <Hint>
                    Cursus zal starten op <ReadableDate showTime date={new Date(firstEnrollment.date)} />
                </Hint>
            )
        }

        if (!isNowEnrolled) {
            const firstEnrollmentAfterNow = enrollments.find(enrollment => {
                return enrollment.type === 'Enrollment' && moment(enrollment.date).isAfter()
            })

            const lastDisenrollmentBeforeNow = [...enrollments].reverse().find(enrollment => {
                return enrollment.type === 'Disenrollment' && moment(enrollment.date).isSameOrBefore()
            })

            if (firstEnrollmentAfterNow) {
                return (
                    <Hint>
                        Cursus zal herstarten op <ReadableDate showTime date={new Date(firstEnrollmentAfterNow.date)} />
                    </Hint>
                )
            }

            if (lastDisenrollmentBeforeNow) {
                return (
                    <Hint>
                        Cursus is beëindigd op{' '}
                        <ReadableDate showTime date={new Date(lastDisenrollmentBeforeNow.date)} /> door{' '}
                        {get(lastDisenrollmentBeforeNow.createdByUser, 'profile.name') || '-'}.
                    </Hint>
                )
            }

            /**
             * Something is wrong.
             * How can the user not be enrolled at this moment,
             * while `firstEnrollmentAfterNow` and `lastDisenrollmentBeforeNow`
             * are both undefined
             */
            console.log(
                'Something is wrong, no `firstEnrollmentAfterNow` or `lastDisenrollmentBeforeNow` while user is not enrolled now',
                enrollments
            )
            return null
        }

        if (!hasLearnerFinalEnrollment) {
            return (
                <Hint>
                    Cursus zal eindigen op <ReadableDate showTime date={new Date(lastEnrollment.date)} />
                </Hint>
            )
        }

        return (
            <Hint>
                Is gestart op <ReadableDate showTime date={new Date(firstEnrollment.date)} />
            </Hint>
        )
    }
}
