import * as React from 'react'
import { ExportsCard } from '~/components/Exports/ExportsCard'
import { Button, Icon, CenterModal } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { ExamAttemptsExportForm } from '~/components/Exports/Forms/ExamAttemptsExportForm'
import { downloadFile } from '~/utils'

interface Props {
    lastDate?: Date
    refreshDates: () => void
}

export class ExamAttemptsCard extends React.Component<Props> {
    public render() {
        return (
            <ExportsCard
                title={`Examenpogingen inburgering voor Blik op Werk`}
                date={this.props.lastDate}
                description={`Lijst van examenpogingen van inburgeringsplichtige kandidaten in een te selecteren kalenderjaar.`}
            >
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} leftIcon={<Icon name={`download`} />}>
                            Download export
                        </Button>
                    )}
                    getModal={closeModal => (
                        <CenterModal onClose={closeModal} title={`Examenpogingen inburgering voor Blik op Werk`}>
                            <ExamAttemptsExportForm
                                onClose={closeModal}
                                onSubmitSuccess={(fileId, fileName) => {
                                    downloadFile(fileId, fileName)
                                    closeModal()
                                    this.props.refreshDates()
                                }}
                            />
                        </CenterModal>
                    )}
                />
            </ExportsCard>
        )
    }
}
