import env from '~/services/env'
// todo: change to env variables
let awsmobile: any = {
    aws_project_region: env.AWS_COGNITO_REGION,
    aws_cognito_region: env.AWS_COGNITO_REGION,
    aws_user_pools_id: env.AWS_COGNITO_POOL_ID,
    aws_user_pools_web_client_id: env.AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
        domain: env.AWS_COGNITO_DOMAIN,
        scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: env.AWS_COGNITO_REDIRECT_SIGNIN,
        redirectSignOut: env.AWS_COGNITO_REDIRECT_SIGNOUT,
        responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
}
if (location.hostname.includes('lifely')) {
    awsmobile = {
        ...awsmobile,
        cookieStorage: {
            domain: location.hostname,
            secure: !location.hostname.includes('localhost'),
            path: '/',
            expires: 365,
        },
    }
}
export default awsmobile
