import { gql } from '@apollo/client'
import { get } from 'lodash'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

const GET_EXAM_QUERY = gql`
    query _($_id: MongoID) {
        exams(byId: $_id) {
            _id
            name
            type
            level
            converse {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
            talk {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
            write {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
            listen {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
            read {
                typeOfRating
                minimumScoreToPass
                maximumScore
                documentFiles {
                    _id
                    fileName
                }
            }
        }
    }
`

interface Props extends RouteComponentProps<{ id: string }, {}> {
    children: any
}

export default class DetailView extends React.Component<Props, {}> {
    private examFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.examFetcher = new Fetcher({
            query: GET_EXAM_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate(preIterationVariables: any) {
        const { params } = this.props

        if (preIterationVariables.params.id !== params.id) {
            this.examFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.examFetcher
        const exam = get(data, 'exams[0]')

        return (
            <RouteView crumbLabel={exam && exam.name} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                {exam && React.cloneElement(children, { exam, refetch })}
            </RouteView>
        )
    }
}
