import * as React from 'react'
import { TagPicker, TagPickerProps } from '../TagPicker'

interface Props extends TagPickerProps {}

export class GroupExamStatusInput extends React.Component<Props> {
    private EXAM_STATUSES = [
        {
            value: 'participated',
            label: 'Toets gemaakt',
        },
        {
            value: 'exempt',
            label: 'Vrijgesteld',
        },
        {
            value: 'no_show',
            label: 'Niet verschenen',
        },
    ]

    public render() {
        const { ...restProps } = this.props

        return <TagPicker {...restProps} multi={false} options={this.EXAM_STATUSES} />
    }
}
