import c from 'classnames'
import * as React from 'react'

import { View } from '~/components/View'

interface Props {
    needsPaddingForTagPicker?: boolean
    className?: string
}

export class HorizontalScrollView extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <View className={this.getClassName()}>{children}</View>
    }

    private getClassName() {
        const { className, needsPaddingForTagPicker } = this.props

        return c(
            'tt-HorizontalScrollView',
            {
                'tt-HorizontalScrollView--needs-padding-for-tagpicker': !!needsPaddingForTagPicker,
            },
            className
        )
    }
}
