import * as React from 'react'
import c from 'classnames'

// tslint:disable-next-line
type MultiInputType =
    | 'name'
    | 'phone'
    | 'address'
    | 'precision-date'
    | 'date-time'
    | 'frequency'
    | 'radio'
    | 'checkbox'
    | 'fullWidth'
    | 'inline'
    | 'select-range'
    | 'half'
    | 'two-third'
    | 'email-name'

interface Props {
    className?: string
    fullWidth?: boolean
    isVertical?: boolean
    type?: MultiInputType
}

export default class MultiInput extends React.Component<Props> {
    public static defaultProps = {
        isVertical: false,
    }

    public render() {
        const { children } = this.props

        const wrappedChildren = React.Children.map(children, (child, index) => {
            if (!child) {
                return null
            }

            return (
                <div className={`tt-MultiInput__item`} key={index}>
                    {child}
                </div>
            )
        })

        return <div className={this.getClassName()}>{wrappedChildren}</div>
    }

    private getClassName() {
        const { className, type, fullWidth, isVertical } = this.props

        return c(
            'tt-MultiInput',
            {
                'tt-MultiInput-name': type === 'name',
                'tt-MultiInput-email-name': type === 'email-name',
                'tt-MultiInput-phone': type === 'phone',
                'tt-MultiInput-address': type === 'address',
                'tt-MultiInput--precision-date': type === 'precision-date',
                'tt-MultiInput--date-time': type === 'date-time',
                'tt-MultiInput--frequency': type === 'frequency',
                'tt-MultiInput-radio': type === 'radio',
                'tt-MultiInput-checkbox': type === 'checkbox',
                'tt-MultiInput-inline': type === 'inline',
                'tt-MultiInput-select-range': type === 'select-range',
                'tt-MultiInput--half': type === 'half',
                'tt-MultiInput--two-third': type === 'two-third',
                'tt-MultiInput--full-width': fullWidth,
                'tt-MultiInput--is-horizontal': !isVertical,
            },
            className
        )
    }
}
