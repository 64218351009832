import { parseGraphQLError } from '~/utils'

export function parseNestedGraphQLError(error: string) {
    return parseGraphQLError({
        graphQLErrors: [
            {
                message: JSON.stringify(error),
            },
        ],
    }).message
}
