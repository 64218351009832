import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, FileInput, Form } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator, transformFormFields } from '~/utils'

export default class CreateOrganizationDocumentForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
        organizationId: PropTypes.string,
    }

    constructor(props) {
        super(props)

        this.documentMutator = new Mutator({
            mutation: CREATE_DOCUMENTS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.documentMutator
            .mutate({
                organizationId: this.props.organizationId,
                documents: fields.documents.map(doc => ({
                    ...transformFormFields(doc),
                })),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    render() {
        const { errors, loading } = this.documentMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Bestand`} errors={errors}>
                            <FileInput name={`documents[0].file`}>Bladeren</FileInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button isLoading={loading} type={`submit`}>
                                    Uploaden
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_DOCUMENTS_MUTATION = gql`
    mutation _($organizationId: MongoID!, $documents: [OrganizationDocumentInputType]) {
        organizations_addDocuments(organizationId: $organizationId, documents: $documents) {
            _id
        }
    }
`
