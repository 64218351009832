import moment from 'moment'
import React from 'react'
import { Icon } from '~/components'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    date: Date | string
    className?: ClassValue
    warning?: Date
}

export default class ReadableDateFromNow extends React.Component<Props> {
    private bem = new BEM('ReadableDateFromNow', () => ({
        'has-warning': this.hasWarning(),
    }))

    public render() {
        const { date, className } = this.props

        if (!moment(date).isValid()) {
            return <span />
        }

        return (
            <span className={this.bem.getClassName(className)}>
                {this.hasWarning() && <Icon name={`status_attention`} />}
                {moment(date).fromNow()} ({moment(date).format('DD-MM-YYYY')})
            </span>
        )
    }

    private hasWarning = () => {
        const { warning, date } = this.props

        return moment(date).isBefore(moment(warning))
    }
}
