import * as React from 'react'
import { Link } from '~/components'
// import { ModalManager } from '~/components/ModalManager'
// import { UserLearnerAddInvoicingForm } from '~/components/User/Learner/UserLearnerAddInvoicingForm'
import { User } from '~/types/User'
import { EmptyView } from '~/views/App/Empty/EmptyView'

interface Props {
    user: User
    refetch: (options?: { silent?: boolean }) => void
}

export class InvoicingDisabledView extends React.Component<Props> {
    public render() {
        const { user } = this.props

        return (
            <EmptyView
                title={`Debiteurnummer en/of leenbedrag onbekend`}
                type={`add`}
                renderAction={() => (
                    <Link route={`/users/learners/${user._id}`}>Gegevens toevoegen</Link>
                    // <ModalManager
                    //     render={openModal => (
                    //         <Button
                    //             linkStyle={`default`}
                    //             onClick={openModal}
                    //             small={true}
                    //             leftIcon={<Icon name={`plus`} />}
                    //         >
                    //             Gegevens toevoegen
                    //         </Button>
                    //     )}
                    //     getModal={closeModal => (
                    //         <CenterModal title={`Factuurgegevens toevoegen`} onClose={closeModal}>
                    //             <UserLearnerAddInvoicingForm
                    //                 user={user}
                    //                 onCancel={closeModal}
                    //                 onSubmitSuccess={() => {
                    //                     closeModal()
                    //                     refetch({ silent: true })
                    //                 }}
                    //             />
                    //         </CenterModal>
                    //     )}
                    // />
                )}
            />
        )
    }
}
