import React, { Fragment } from 'react'
import {
    TableWrap,
    TableRow,
    Link,
    ReadableDate,
    TableHeader,
    TableHeaderItem,
    Subtle,
    Button,
    Icon,
} from '~/components'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import { gql } from '@apollo/client'
import { Fetcher, Mutator, toast } from '~/utils'
import { TableCell } from '~/components/TableCell'
import { User } from '~/types/User'
import { translateType } from '~/shared/utils'
import { ContractModal } from '~/components/Contracts/ContractModal'
import { Contract } from '~/types/Contracts'
import { LearnerContractsExpansionContent } from '~/components/users/Learner/LearnerContractsExpansionContent'
import { Strikethrough } from '~/components/Strikethrough/Strikethrough'
import { ContractSignatureModal } from '~/components/Contracts/ContractSignatureModal'
import { MakeFinalIcon, SignatureIcon } from '~/components/Contracts/ContractStatus/icons'

interface Props {
    userId: string
}

const CONTRACT_MAKE_FINAL_MUTATION = gql`
    mutation _($contractId: MongoID!) {
        contracts_makeFinal(contractId: $contractId) {
            _id
        }
    }
`

const USER_LEARNER_CONTRACTS_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            learner {
                hoursPresent
                contracts {
                    _id
                    type
                    contractNumber
                    user {
                        _id
                        profile {
                            name
                        }
                    }
                    dateFrom
                    dateTo
                    isSigned
                    isFinal
                    contract {
                        fileName
                        fileId
                    }
                    terminatedAt
                    signedContract {
                        fileName
                        fileId
                    }
                    terminatedAt
                    terminatedReason
                    logs {
                        _id
                        type
                        createdAt
                        createdByUser {
                            _id
                            profile {
                                name
                            }
                        }
                        typeData {
                            group {
                                _id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

const CONTRACTS_REFRESH_FOR_USER = gql`
    mutation _($userId: MongoID!) {
        contracts_refreshForUser(userId: $userId)
    }
`

export class LearnerContractsTable extends React.Component<Props> {
    private userLearnerContractsFetcher: Fetcher
    private contractMakeFinalMutator: Mutator
    private refreshContractsMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.userLearnerContractsFetcher = new Fetcher({
            query: USER_LEARNER_CONTRACTS_QUERY,
            variables: {
                filters: {
                    byId: this.props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
        })

        this.contractMakeFinalMutator = new Mutator({
            mutation: CONTRACT_MAKE_FINAL_MUTATION,
            reactComponentToUpdate: this,
        })

        this.refreshContractsMutator = new Mutator({
            mutation: CONTRACTS_REFRESH_FOR_USER,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { loading: isLoading } = this.userLearnerContractsFetcher

        return (
            <TableWrap>
                <TableView>
                    <Table hasAutoLayout={true}>
                        <TableHeader>
                            <TableHeaderItem>Contractnummer</TableHeaderItem>
                            <TableHeaderItem>Contracttype</TableHeaderItem>
                            <TableHeaderItem>Contractdatum</TableHeaderItem>
                            <TableHeaderItem>Vervaldatum</TableHeaderItem>
                            <TableHeaderItem />
                            {/* Signature button */}
                            <TableHeaderItem />
                            {/* Expansion button */}
                        </TableHeader>

                        <TableRow>
                            <TableCell colSpan={7}>{this.renderSyncContractsButton()}</TableCell>
                        </TableRow>

                        {isLoading ? (
                            <TableRow key={`loading`}>
                                <TableCell colSpan={7} isLoading={true} />
                            </TableRow>
                        ) : (
                            this.renderContractRows()
                        )}
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderContractRows = () => {
        const { data, refetch } = this.userLearnerContractsFetcher
        const { loading: isMakingFinal } = this.contractMakeFinalMutator
        const user = data && data.users && (data.users[0] as User)

        const userContracts = (user && user.learner && user.learner.contracts) || []

        if (!user || userContracts.length <= 0) {
            return (
                <TableRow>
                    <TableCell colSpan={7}>
                        <Subtle>Geen contracten voor deze gebruiker gevonden</Subtle>
                    </TableCell>
                </TableRow>
            )
        }

        return userContracts.map((userContract: any) => (
            <TableRow key={userContract._id} getExpansion={() => this.renderContactExpansionCells(userContract)}>
                <TableCell>
                    <ContractModal
                        render={openModal => (
                            <Link onClick={openModal}>
                                {userContract.terminatedAt ? (
                                    <Strikethrough>{userContract.contractNumber}</Strikethrough>
                                ) : (
                                    userContract.contractNumber
                                )}
                            </Link>
                        )}
                        contract={userContract}
                        refetch={refetch}
                    />
                </TableCell>
                <TableCell>{translateType('contractType', userContract.type)}</TableCell>
                <TableCell>
                    <ReadableDate format={`DD-MM-YYYY`} date={userContract.dateFrom} />
                </TableCell>
                <TableCell>
                    <ReadableDate format={`DD-MM-YYYY`} date={userContract.terminatedAt || userContract.dateTo} />
                </TableCell>
                <TableCell>
                    {!userContract.isFinal && (
                        <Button
                            type={`button`}
                            isLoading={isMakingFinal}
                            shouldPreventSubmit
                            onClick={() => {
                                this.makeFinal(userContract)
                            }}
                            small={true}
                            leftIcon={<MakeFinalIcon />}
                            confirm={{
                                title: 'Contract definitief maken',
                                message: 'Weet je zeker dat je dit contract definitief wilt maken?',
                                execute: {
                                    buttonType: 'submit',
                                    title: 'Contract definitief maken',
                                },
                            }}
                        >
                            Definitief maken
                        </Button>
                    )}
                    {userContract.isFinal && !userContract.isSigned && (
                        <ContractSignatureModal
                            render={openModal => (
                                <Button onClick={openModal} small={true} leftIcon={<SignatureIcon />}>
                                    Handtekening uploaden
                                </Button>
                            )}
                            contract={userContract}
                            onSubmitSuccess={refetch}
                        />
                    )}
                </TableCell>
            </TableRow>
        ))
    }

    private renderContactExpansionCells = (userContract: Contract) => {
        const { refetch } = this.userLearnerContractsFetcher

        return (
            <TableCell colSpan={7}>
                <LearnerContractsExpansionContent userContract={userContract} refetch={refetch} />
            </TableCell>
        )
    }

    private renderSyncContractsButton = () => {
        const { loading: isRefreshingContracts } = this.refreshContractsMutator

        return (
            <Fragment>
                <Button
                    small
                    leftIcon={<Icon name="replay" />}
                    onClick={this.refreshContracts}
                    isLoading={isRefreshingContracts}
                >
                    Ververs contracten
                </Button>
            </Fragment>
        )
    }

    private makeFinal = async (userContract: Contract) => {
        const { refetch } = this.userLearnerContractsFetcher

        try {
            await this.contractMakeFinalMutator.mutate({
                contractId: userContract._id,
            })

            if (refetch) {
                refetch({ silent: true })
            }
        } catch (error) {
            toast.error('Er is iets misgegaan!')
        }
    }

    private refreshContracts = async () => {
        const { userId } = this.props
        const { refetch: refreshContracts } = this.userLearnerContractsFetcher

        const result = await this.refreshContractsMutator.mutate({
            userId,
        })

        if (result && result.contracts_refreshForUser) {
            toast.success('Contracten zijn ververst')
            refreshContracts()
        }
    }
}
