import React from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { View } from '~/components/View'
import { RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<{}, {}> {}

export default class MasterView extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return (
            <View>
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`/users/teachers/`}>
                                Overzicht
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`/users/teachers/availability`}>
                                Beschikbaarheid
                            </Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                <View>{children}</View>
            </View>
        )
    }
}
