import React from 'react'
import { ValidationErrorType, InflowMomentFieldGroup } from './InflowMomentFieldGroup'
import { InflowMoment } from '~/types/InflowMoments'
import { CenterModal, Link, Field, Button, Icon, DrawerModal, Subtle } from '~/components'
import { InflowLocationForm } from '../Forms/InflowLocationForm'
import { WithSubtle } from '~/components/WithSubtle'
import { ModalManager } from '~/components/ModalManager'
import { InflowLocationRoomForm, InflowMomentRoomType } from '../Forms/InflowLocationRoomForm'
import { Room } from '~/types/Location'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Mutator } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'

interface Props {
    validationErrors?: ValidationErrorType[]
    inflowMoment: InflowMoment
    refetch: () => void
    errors: string[]
}

interface State {}

export class InflowMomentLocationGroup extends React.Component<Props, State> {
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { validationErrors, inflowMoment } = this.props
        const { location } = inflowMoment

        if (!location) {
            return <></>
        }

        const hasRooms = location.rooms ? location.rooms.length > 0 : false

        return (
            <InflowMomentFieldGroup
                title={`Locatie`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                inflowMoment={inflowMoment}
                seperateFields={true}
                onRemove={this.onRemove}
                removeMessage={'Je staat op het punt de locatie uit dit toetsmoment te verwijderen. Weet je het zeker?'}
            >
                <Field>
                    <Subtle>Locatie</Subtle>
                    <WithSubtle text={this.getSubtleText()}>
                        <Link route={`/properties/locations/${inflowMoment.location._id}`}>
                            {inflowMoment.location.name}
                        </Link>
                    </WithSubtle>
                </Field>
                {hasRooms && !inflowMoment.locationRoomsOrganized && (
                    <>
                        {this.renderLocationRoomsConversationFieldContent()}
                        {this.renderLocationRoomsGeneralFieldContent()}
                    </>
                )}
            </InflowMomentFieldGroup>
        )
    }

    private renderLocationRoomsConversationFieldContent = () => {
        const { inflowMoment } = this.props

        if (!inflowMoment.locationRoomsConversation) {
            return (
                <Field>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} small={true} leftIcon={<Icon name={`plus`} />}>
                                Gespreksruimte
                            </Button>
                        )}
                        getModal={closeModal => this.renderOrganizedLocationModal(closeModal)}
                    />
                </Field>
            )
        }

        return (
            <Field>
                <Subtle>Gespreksruimte</Subtle>
                {this.renderRooms(inflowMoment.locationRoomsConversation)}
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} small={true}>
                            Bewerken
                        </Button>
                    )}
                    getModal={closeModal => this.renderOrganizedLocationModal(closeModal)}
                />
            </Field>
        )
    }

    private renderLocationRoomsGeneralFieldContent = () => {
        const { inflowMoment } = this.props

        if (!inflowMoment.locationRoomsGeneral) {
            return (
                <Field>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} small={true} leftIcon={<Icon name={`plus`} />}>
                                Gemeenschappelijke ruimte
                            </Button>
                        )}
                        getModal={closeModal => this.renderGeneralLocationModal(closeModal)}
                    />
                </Field>
            )
        }

        return (
            <Field>
                <Subtle>Gemeenschappelijke ruimte</Subtle>
                {this.renderRooms(inflowMoment.locationRoomsGeneral)}
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} small={true}>
                            Bewerken
                        </Button>
                    )}
                    getModal={closeModal => this.renderGeneralLocationModal(closeModal)}
                />
            </Field>
        )
    }

    private renderRooms = (rooms: Room[]) => {
        const { inflowMoment } = this.props

        return (
            <SimpleTable>
                {rooms.map(room => (
                    <SimpleTableRow key={`${room.name}`}>
                        <SimpleTableCell>
                            <Link route={`properties/locations/${inflowMoment.location._id}/rooms/${room._id}`}>
                                {room.name}
                            </Link>
                        </SimpleTableCell>
                        <SimpleTableCell>
                            <Subtle>({room.capacity || 0} plekken)</Subtle>
                        </SimpleTableCell>
                    </SimpleTableRow>
                ))}
            </SimpleTable>
        )
    }

    private renderOrganizedLocationModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <InflowLocationRoomForm
                    inflowMoment={inflowMoment}
                    location={inflowMoment.location}
                    roomType={InflowMomentRoomType.conversation}
                    refetch={refetch}
                    closeModal={closeModal}
                    defaultRooms={inflowMoment.locationRoomsConversation || []}
                />
            </DrawerModal>
        )
    }

    private renderGeneralLocationModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <InflowLocationRoomForm
                    inflowMoment={inflowMoment}
                    location={inflowMoment.location}
                    roomType={InflowMomentRoomType.general}
                    refetch={refetch}
                    closeModal={closeModal}
                    defaultRooms={inflowMoment.locationRoomsGeneral || []}
                />
            </DrawerModal>
        )
    }

    private getEditModal = (closeModal: () => void) => {
        const { inflowMoment, refetch } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Locatie bewerken`}>
                <InflowLocationForm inflowMoment={inflowMoment} onClose={closeModal} refetch={refetch} />
            </CenterModal>
        )
    }

    private getSubtleText = () => {
        const { inflowMoment } = this.props

        if (inflowMoment.locationRoomsOrganized) {
            return `Lesruimtes zijn georganiseerd op locatie`
        }

        if (inflowMoment.location && inflowMoment.location.rooms && inflowMoment.location.rooms.length <= 0) {
            return `Locatie is ook de lesruimte`
        }

        return ``
    }

    private onRemove = () => {
        const { refetch, inflowMoment } = this.props

        this.inflowMomentMutator
            .mutate({
                inflowMoment: {
                    _id: inflowMoment._id,
                    nulls: [
                        'locationId',
                        'locationRoomsOrganized',
                        'locationRoomsConversationIds',
                        'locationRoomsGeneralIds',
                    ],
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                }
            })
    }
}
