import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'

export default class DataView extends Component {
    static propTypes = {
        locationProperty: PropTypes.object,
    }

    render() {
        const { locationProperty } = this.props

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Naam`}> {get(locationProperty, 'name')} </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button
                                        onClick={() =>
                                            browserHistory.push(
                                                `/properties/locationproperties/${locationProperty._id}/edit`
                                            )
                                        }
                                        type={`edit`}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
