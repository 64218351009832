import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator, toast } from '~/utils'

export default class UserInvitationForm extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.userInviteMutator = new Mutator({
            mutation: INVITE_USERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { user } = this.props

        this.userInviteMutator
            .mutate({
                userId: user._id,
            })
            .then(data => {
                if (data) {
                    this.props.onSuccess(fields)
                    toast.success('De uitnodiging is verstuurd.')
                }
            })
    }

    render() {
        const { user, onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>
                            Je staat op het punt om {user.profile.name} {user.invitedAt ? 'opnieuw' : 'voor het eerst'}{' '}
                            een e-mail te sturen met instructies om in te loggen in het systeem.
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`}>Verstuur uitnodiging</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const INVITE_USERS_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_sendInvite(userId: $userId) {
            _id
        }
    }
`
