import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    horizontal?: boolean
    vertical?: boolean
}

export default class Line extends React.Component<Props> {
    private bem = new BEM('Line', () => ({
        horizontal: this.props.horizontal,
        vertical: this.props.vertical,
    }))

    public render() {
        const { className } = this.props

        return <span className={this.bem.getClassName(className)} />
    }
}
