import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Fetcher } from '~/utils'

export default class DetailView extends Component {
    static propTypes = {
        children: PropTypes.any,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props)

        this.instructionVideosFetcher = new Fetcher({
            query: GET_INSTRUCTIONVIDEOS_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.instructionVideosFetcher.refetch({
                _id: params.id,
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.instructionVideosFetcher
        const instructionVideo = get(data, 'instructionVideos[0]')

        return (
            <RouteView crumbLabel={get(instructionVideo, 'name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                {instructionVideo && cloneElement(children, { instructionVideo, refetch })}
            </RouteView>
        )
    }
}

const GET_INSTRUCTIONVIDEOS_QUERY = gql`
    query _($_id: MongoID) {
        instructionVideos(byId: $_id) {
            _id
            name
            url
            targetRoles
        }
    }
`
