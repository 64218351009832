import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{}, {}> {}

export default class ConceptView extends React.Component<Props> {
    public render() {
        const { children, ...routeComponentProps } = this.props

        return (
            <RouteView crumbLabel={'Kandidaten'} routeProps={routeComponentProps} isTab={true}>
                {children}
            </RouteView>
        )
    }
}
