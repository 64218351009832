import * as React from 'react'
import { Wrap } from '~/components/Wrap'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { gql } from '@apollo/client'
import Fetcher from '~/utils/Fetcher'
import Spinner from '~/components/Spinner'
import { PhoneNumber } from '~/types/User'
import translateType from '~/shared/utils/translateType'
import ReadablePhoneNumbers from '~/components/ReadablePhoneNumbers'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { browserHistory } from 'react-router'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import { ModalManager } from '~/components/ModalManager'
import CenterModal from '~/components/CenterModal'
import UserInvitationForm from '~/forms/UserInvitationForm'

interface ExternalOrganizationColleague {
    _id: string
    email?: string
    invitedAt?: Date
    lastActivityAt?: Date
    profile: {
        name: string
        gender?: string
        phoneNumbers?: PhoneNumber[]
    }
    organizationContact?: {
        isActive?: boolean
    }
}

const GET_COLLEAGUE_QUERY = gql`
    query colleague($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            invitedAt
            lastActivityAt
            profile {
                name
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
            }
            organizationContact {
                isActive
            }
        }
    }
`

interface Props {
    userId: string
}

export class ColleagueProfileData extends React.Component<Props> {
    private colleagueFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.colleagueFetcher = new Fetcher({
            query: GET_COLLEAGUE_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { data, loading } = this.colleagueFetcher
        const user = data && data.users && data.users[0]

        if (loading || !user) {
            return <Spinner />
        }

        return this.renderWithUser(user)
    }

    private renderWithUser = (user: ExternalOrganizationColleague) => {
        const { profile, email, organizationContact } = user
        const { name, gender, phoneNumbers } = profile
        const phoneNumber = phoneNumbers && phoneNumbers[0]

        return (
            <Wrap>
                <FieldCollection>
                    <FieldGroup title={`Persoonlijke gegevens`}>
                        <Field title={`Naam`}>{name}</Field>
                        <Field title={`Geslacht`}>{translateType('userGender', gender)}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Contactgegevens`}>
                        <Field title={`E-mailadres`}>{email}</Field>
                        {user.email && !user.lastActivityAt && (
                            <Field title="&nbsp;">
                                <ModalManager
                                    render={openModal => (
                                        <Button onClick={openModal}>
                                            {!user.invitedAt && 'Toegang tot het systeem verlenen'}
                                            {user.invitedAt && 'Uitnodiging opnieuw versturen'}
                                        </Button>
                                    )}
                                    getModal={closeModal => {
                                        return (
                                            <CenterModal
                                                onClose={closeModal}
                                                title={`Toegang verlenen aan ${user.profile.name}`}
                                            >
                                                <UserInvitationForm
                                                    user={user}
                                                    onSuccess={() => {
                                                        closeModal()
                                                        this.userInvited()
                                                    }}
                                                    onCancel={closeModal}
                                                />
                                            </CenterModal>
                                        )
                                    }}
                                />
                            </Field>
                        )}
                        <Field title={`Telefoonnummer`}>
                            {phoneNumber && <ReadablePhoneNumbers phoneNumbers={[phoneNumber]} />}
                        </Field>
                        <Field title={`Status`}>
                            {organizationContact && organizationContact.isActive ? 'Actief' : 'Non-actief'}
                        </Field>
                    </FieldGroup>
                </FieldCollection>
                {this.renderFooter()}
            </Wrap>
        )
    }

    private renderFooter = () => {
        return (
            <ActionBar
                fixed={true}
                isWide={true}
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem right={true}>
                            <Button type={`edit`} onClick={() => browserHistory.push(this.getUserUrl())}>
                                Bewerken
                            </Button>
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private userInvited() {
        const { refetch } = this.colleagueFetcher

        refetch({ silent: true })
    }

    private getUser = () => {
        const { data } = this.colleagueFetcher
        return data && data.users && data.users[0]
    }

    private getUserUrl = () => {
        const user = this.getUser()

        if (user) {
            return `/colleagues/${user._id}/edit`
        }

        return '/colleagues/'
    }
}
