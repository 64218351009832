import React from 'react'

import { ClassValue } from '~/services/BEMService'
import DrawerHeader from '../DrawerHeader'
import { View } from '../View'
import TableWrap from '../TableWrap'
import { TableView } from '../TableView'
import TableHeader from '../TableHeader'
import TableHeaderItem from '../TableHeaderItem'
import { Table } from '../Table'
import { gql } from '@apollo/client'
import { Fetcher, removeDuplicateDocuments, Sorter, Mutator } from '~/utils'
import { TableCell } from '../TableCell'
import { TableRow, Subtle, ReadableDate, Bold, Button, Link } from '..'
import { FinalExamImportErrorType, FinalExamImportError, FinalExamSubmitters } from '~/types/FinalExam'
import { SimpleTable } from '../SimpleTable'
import { SimpleTableRow } from '../SimpleTableRow'
import { SimpleTableCell } from '../SimpleTableCell'
import { get } from 'lodash'
import { ButtonGroup } from '../ButtonGroup'
import { translateType } from '~/shared/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

interface Props {
    className?: ClassValue
    closeModal: () => void
}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

const GET_FINAL_EXAMS_IMPORT_ERRORS_QUERY = gql`
    query DUOFinalExamResultErrors($skip: Int, $limit: Int, $sortDir: String, $sortBy: String) {
        DUOFinalExamResultErrors(skip: $skip, limit: $limit, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            createdAt
            examDate
            type
            examPart
            examLevel
            examAttempt
            examResult
            typeData {
                firstName
                lastName
                bsn
            }
            DUOFinalExamResult {
                _id
                createdAt
                file {
                    _id
                    fileName
                }
            }
            user {
                _id
                profile {
                    name
                }
            }
        }
    }
`

const RESOLVE_FINAL_EXAM_IMPORT_ERROR = gql`
    mutation _($id: MongoID, $submitter: FinalExamSubmittersEnum) {
        DUOFinalExamResultErrors_resolve(DUOFinalExamResultErrorId: $id, submitter: $submitter)
    }
`

const START = 50
const INCREASE = 40
const DEFAULT_SORT_DIR = 'DESC'
const DEFAULT_SORT_BY = 'createdAt'

export class FinalExamsUploadErrorsTable extends React.PureComponent<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private DUOFinalExamResultErrorsFetcher: Fetcher
    private paginator: Paginator
    private sorter: Sorter
    private examNotFoundErrorMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.DUOFinalExamResultErrorsFetcher = new Fetcher({
            query: GET_FINAL_EXAMS_IMPORT_ERRORS_QUERY,
            variables: {
                limit,
                skip,
                sortBy: this.state.sortBy,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.examNotFoundErrorMutator = new Mutator({
            mutation: RESOLVE_FINAL_EXAM_IMPORT_ERROR,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { closeModal } = this.props
        const { loading, data } = this.DUOFinalExamResultErrorsFetcher

        const { DUOFinalExamResultErrors } = data || []

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <DrawerHeader title={'Foutmeldingen uit gegevensuitwisseling DUO'} close={closeModal} />
                <TableWrap>
                    <TableView>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem sorter={this.sorter} sortBy={'createdAt'} width={`250px`}>
                                    Import datum
                                </TableHeaderItem>
                                <TableHeaderItem>Kandidaat</TableHeaderItem>
                                <TableHeaderItem colSpan={2}>Melding</TableHeaderItem>
                            </TableHeader>
                            {loading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={4} isLoading={true} />
                                </TableRow>
                            ) : DUOFinalExamResultErrors && DUOFinalExamResultErrors.length > 0 ? (
                                this.renderRows(DUOFinalExamResultErrors)
                            ) : (
                                <TableRow key={`emptyresult`}>
                                    <TableCell colSpan={4}>
                                        <Subtle>Er zijn geen foutmeldingen gevonden.</Subtle>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableView>
                </TableWrap>
            </InfiniteScroll>
        )
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.DUOFinalExamResultErrorsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private renderRows = (importErrors: FinalExamImportError[]) => {
        return importErrors.map(importError => {
            return (
                <TableRow key={importError._id} getExpansion={() => this.renderImportErrorExpansionCells(importError)}>
                    <TableCell>
                        {importError.DUOFinalExamResult && importError.DUOFinalExamResult.createdAt ? (
                            <ReadableDate date={importError.DUOFinalExamResult.createdAt} />
                        ) : (
                            '-'
                        )}
                    </TableCell>
                    <TableCell>{this.getName(importError)}</TableCell>
                    <TableCell>{this.getErrorMessage(importError.type)}</TableCell>
                </TableRow>
            )
        })
    }

    private renderImportErrorExpansionCells = (importError: FinalExamImportError) => {
        const fileName = get(importError, 'DUOFinalExamResult.file.fileName')

        return (
            <TableCell colSpan={4}>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Toetsdatum</SimpleTableCell>
                        <SimpleTableCell>
                            {importError.examDate ? <ReadableDate date={importError.examDate} /> : '-'}
                        </SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Examen</SimpleTableCell>
                        <SimpleTableCell>
                            {translateType('finalExamPart', importError.examPart)} {importError.examLevel}
                        </SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Poging</SimpleTableCell>
                        <SimpleTableCell>{importError.examAttempt || '-'}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Resultaat</SimpleTableCell>
                        <SimpleTableCell>
                            {importError.examResult ? translateType('finalExamResult', importError.examResult) : '-'}
                        </SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Gegevensuitwisseling DUO</SimpleTableCell>
                        <SimpleTableCell>{fileName}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell />
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell colSpan={2}>{this.renderErrorAction(importError)}</SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </TableCell>
        )
    }

    private getErrorMessage = (importErrorType: FinalExamImportErrorType) => {
        if (importErrorType === FinalExamImportErrorType.ExamNotFound) {
            return "Examenafspraak komt alleen voor in 'Gegevensuitwisseling DUO'"
        }

        if (importErrorType === FinalExamImportErrorType.UserNotFound) {
            return "Gebruiker komt alleen voor in 'Gegevensuitwisseling DUO'"
        }

        if (importErrorType === FinalExamImportErrorType.ExternalFinalExamAlreadyExists) {
            return 'Examenafspraak komt al voor'
        }

        return 'Foutmelding niet bekend'
    }

    private getName = (importError: FinalExamImportError) => {
        if (importError.user) {
            return <Link route={`/users/learners/${importError.user._id}`}>{importError.user.profile.name}</Link>
        }

        if (importError.typeData) {
            return [importError.typeData.firstName, importError.typeData.lastName].filter(name => !!name).join(' ')
        }

        return '-'
    }

    private renderErrorAction = (importError: FinalExamImportError) => {
        if (importError.type === FinalExamImportErrorType.UserNotFound && importError.typeData) {
            return (
                <>
                    Maak een gebruiker aan met bsn: <Bold>{importError.typeData.bsn}</Bold> ({this.getName(importError)}
                    )
                </>
            )
        }

        if (importError.type === FinalExamImportErrorType.ExamNotFound) {
            return (
                <ButtonGroup smallButtons={true}>
                    <Button
                        small
                        onClick={() => this.resolveExamNotFoundError(importError._id, FinalExamSubmitters.External)}
                    >
                        Aanvraag is extern gedaan
                    </Button>
                    <Button
                        small
                        onClick={() => this.resolveExamNotFoundError(importError._id, FinalExamSubmitters.TopTaal)}
                    >
                        Aanvraag is door MijnTopTaal gedaan
                    </Button>
                </ButtonGroup>
            )
        }

        return <></>
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.DUOFinalExamResultErrorsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.DUOFinalExamResultErrors.length === 0,
                })

                return {
                    DUOFinalExamResultErrors: removeDuplicateDocuments([
                        ...(prevData.DUOFinalExamResultErrors || []),
                        ...moreData.DUOFinalExamResultErrors,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private resolveExamNotFoundError = async (errorId: string, type: FinalExamSubmitters) => {
        await this.examNotFoundErrorMutator
            .mutate({
                id: errorId,
                submitter: type,
            })
            .then(data => {
                if (data) {
                    this.DUOFinalExamResultErrorsFetcher.refetch()
                }
            })
    }
}
