import './ExportsCard.scss'
import * as React from 'react'
import c from 'classnames'
import Subtle from '~/components/Subtle'
import ReadableDate from '~/components/ReadableDate'

interface Props {
    className?: string
    title: string
    date?: Date
    description: string
}

export class ExportsCard extends React.Component<Props> {
    public render() {
        const { children, title, date, description } = this.props

        return (
            <section className={this.getClassName()}>
                <div className={`tt-ExportsCard__content`}>
                    <h1 className={`tt-ExportsCard__title`}>{title}</h1>
                    <Subtle>Laatste export: {date ? <ReadableDate date={date} /> : '-'}</Subtle>
                    {/* TODO: Add a smooth ridge when there is more content to show */}
                    <p className={`tt-ExportsCard__description`}>{description}</p>
                </div>
                <footer className={`tt-ExportsCard__footer`}>{children}</footer>
            </section>
        )
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-ExportsCard', className)
    }
}
