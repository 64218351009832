import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerProfileEdit } from '~/implementations/LearnerProfileEdit'

export class ExternalTeacherIntakesDetailEditView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        inflowMomentId: PropTypes.string,
        refetch: PropTypes.func,
        route: PropTypes.object,
        router: PropTypes.object,
    }

    render() {
        const { userId, refetch, route, router, inflowMomentId } = this.props

        return (
            <ContentView hasFixedActionBar>
                <LearnerProfileEdit
                    learnerUserId={userId}
                    refetch={refetch}
                    getReturnRouteForUser={() => `/inflow-moments/definitive/${inflowMomentId}/intakes/${userId}`}
                    hideFieldsForIntakeTeacher
                    route={route}
                    router={router}
                />
            </ContentView>
        )
    }
}
