import { User, UserRole, GroupUser } from '~/types/User'

export function getUserDetailRoute(user: User | GroupUser) {
    const roles = user && (user as User).roles

    if (!roles || roles.length <= 0) {
        return getUserDetailRouteFromRole(user)
    }

    if (roles.includes(UserRole.Teacher)) {
        return `/users/teachers/${user._id}`
    }

    if (roles.includes(UserRole.Employee)) {
        return `/users/employees/${user._id}`
    }

    if (roles.includes(UserRole.Learner)) {
        return `/users/learners/${user._id}`
    }

    return
}

function getUserDetailRouteFromRole(groupUser: GroupUser) {
    const { user } = groupUser

    if (!user || !user.roles) {
        return
    }

    const roles = user.roles

    if (roles.includes(UserRole.Teacher)) {
        return `/users/teachers/${groupUser._id}`
    }

    if (roles.includes(UserRole.Employee)) {
        return `/users/employees/${groupUser._id}`
    }

    if (roles.includes(UserRole.Learner)) {
        return `/users/learners/${groupUser._id}`
    }

    return
}
