import { RouteComponentProps } from 'react-router'

interface RouteParams {
    [key: string]: string
}

/**
 * Get the actual path from RouteComponentProps.
 * This is not always the same as location.pathname,
 * since that is only true for the deepest child route
 */
export function getPathFromRouteProps(routeProps: RouteComponentProps<any, any>): string {
    const routes = []

    for (const route of routeProps.routes) {
        routes.push(route)

        if (route === routeProps.route) {
            break
        }
    }

    const pathWithoutLeadingSlash = routes
        .filter(route => 'path' in route && route.path !== '/')
        .map(route => route.path)
        .join('/')

    return populateParamsInPath(`/${pathWithoutLeadingSlash}`, routeProps.params)
}

/**
 * Sets route params in specific path
 */
function populateParamsInPath(path: string, params: RouteParams): string {
    for (const paramKey in params) {
        if (params.hasOwnProperty(paramKey)) {
            const paramValue = params[paramKey]

            path = path.replace(`:${paramKey}`, paramValue)
        }
    }

    return path
}
