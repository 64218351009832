import './FileUploadInput.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { ExistingFile } from '~/components/FileUploadInput/ExistingFile'
import { FileUpload } from '~/components/FileUploadInput/FileUpload'
import { ExistingFileType, UploadedFile } from '~/types/File'

interface Props {
    accept?: string
    defaultFiles?: any
    multi?: boolean
    name: string
    uploadFilesName?: string
    smallButton?: boolean
    inRow?: boolean
    showOnRowHover?: boolean
}

interface State {
    existingFiles?: ExistingFileType[]
    uploadedFiles?: UploadedFile[]
}

export class FileUploadInput extends React.Component<Props, State> {
    public state: State = {
        existingFiles: undefined,
        uploadedFiles: undefined,
    }

    private bem = new BEM('FileUploadInput', () => ({
        'in-row': this.props.inRow,
    }))
    private incrementalId = 0

    constructor(props: Props) {
        super(props)

        const { defaultFiles, multi } = this.props

        this.state = {
            existingFiles: defaultFiles,
        }

        const { existingFiles } = this.state

        if (!existingFiles || existingFiles.length === 0) {
            this.state = {
                uploadedFiles: [{ hasValue: false }],
            }
        } else {
            if (!multi) {
                this.state = {
                    existingFiles: existingFiles,
                }
            } else {
                this.state = {
                    existingFiles: existingFiles,
                    uploadedFiles: [{ hasValue: false }],
                }
            }
        }
    }

    public render() {
        const { existingFiles, uploadedFiles } = this.state

        return (
            <div className={this.bem.getClassName()}>
                {existingFiles && this.renderExistingFiles(existingFiles)}
                {uploadedFiles && this.renderUploadedFiles(uploadedFiles)}
            </div>
        )
    }

    private renderExistingFiles = (existingFiles: ExistingFileType[]) => {
        const { name } = this.props

        return existingFiles.map((file, fileIndex) => (
            <ExistingFile
                file={file}
                key={file._id}
                name={`${name}.existingFileIds[${fileIndex}]`}
                onRemove={() => this.removeFileFromExistingFiles(file._id)}
            />
        ))
    }

    private renderUploadedFiles = (uploadedFiles?: UploadedFile[]) => {
        const { children, name, accept, uploadFilesName, smallButton, showOnRowHover } = this.props

        return (
            uploadedFiles &&
            uploadedFiles
                .sort((a, b) => Number(b.hasValue) - Number(a.hasValue))
                .map((file, fileIndex) => (
                    <FileUpload
                        hasValue={file.hasValue}
                        onChange={this.onChange}
                        onRemove={() => this.removeUploadedFile(file && file._id)}
                        file={file}
                        key={fileIndex}
                        name={`${name}.${uploadFilesName || 'uploadedFiles'}[${fileIndex}]`}
                        accept={accept}
                        smallButton={smallButton}
                        showOnRowHover={showOnRowHover}
                    >
                        {children}
                    </FileUpload>
                ))
        )
    }

    private removeUploadedFile = (idToRemove?: string) => {
        const { uploadedFiles } = this.state

        if (uploadedFiles) {
            this.setState({
                uploadedFiles: uploadedFiles.filter(uploadedFile => {
                    return uploadedFile._id !== idToRemove
                }),
            })
        }
    }

    private generateIncrementalId = () => {
        const id = `_${this.incrementalId++}`
        return id
    }

    private removeFileFromExistingFiles = (idToRemove: string) => {
        const { existingFiles } = this.state

        if (existingFiles) {
            this.setState({
                existingFiles: existingFiles.filter(existingFile => {
                    return existingFile._id !== idToRemove
                }),
            })
        }
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { uploadedFiles } = this.state
        const target = event.target
        const files = target && target.files && Array.from(target.files)

        const addedFiles = (uploadedFiles && [...uploadedFiles]) || []

        if (files) {
            for (const file of files) {
                addedFiles.push({
                    hasValue: true,
                    fileName: file.name,
                    _id: this.generateIncrementalId(),
                })
            }
        }

        this.setState({ uploadedFiles: addedFiles })
    }
}
