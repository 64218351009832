import { gql } from '@apollo/client'
import { find, get, omit } from 'lodash'
import * as React from 'react'

import { Button, CheckBox, DateTimePicker, Field, FieldCollection, Form, MultiInput } from '~/components'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { types } from '~/shared/utils'
import translateType from '~/shared/utils/translateType'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { TagPickerOptions, TagPickerChangeHandler } from '~/components/TagPicker'
import { LessonSpecialActivity } from '~/types/Lesson'
import { ExamAbility, ExamAbilityDictionary } from '~/types/Exam'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

const EDIT_LESSON_MUTATION = gql`
    mutation _($lesson: LessonInputType!) {
        lessons_edit(lesson: $lesson) {
            _id
        }
    }
`

const GET_DATA_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            teacherUsers(filterRemoved: false) {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }

        locations(sortBy: "name") {
            _id
            name
            rooms(sortBy: "name") {
                _id
                name
            }
        }
    }
`

interface Props {
    group: any
    lesson: any
    onSubmitSuccess: (fields: any) => void
    onCancel?: () => void
    errors?: GraphQLErrors
    loading?: boolean
}

interface State {
    selectedLocationId: string
    selectedSpecialActivity: LessonSpecialActivity | null
    selectedExamId: string | null
    selectedTeacherId: string | null
    selectedRoomId: string | null
}

export class EditLessonForm extends React.Component<Props, State> {
    public state: State = {
        selectedLocationId: this.props.lesson.location._id,
        selectedSpecialActivity: this.props.lesson.specialActivity,
        selectedTeacherId: this.props.lesson.teacherUserId,
        selectedRoomId: this.props.lesson.room ? this.props.lesson.room._id : null,
        selectedExamId: null,
    }

    private lessonMutator: Mutator
    private dataFetcher: Fetcher

    constructor(props: Props) {
        super(props)
        const { group } = props

        this.lessonMutator = new Mutator({
            mutation: EDIT_LESSON_MUTATION,
        })

        this.dataFetcher = new Fetcher({
            query: GET_DATA_QUERY,
            variables: {
                filters: {
                    filterById: group._id,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.state.selectedExamId = this.getPlannedExamId()
    }

    public render() {
        const { onCancel, lesson } = this.props
        const { selectedLocationId, selectedSpecialActivity, selectedExamId, selectedTeacherId, selectedRoomId } = this.state
        const { errors } = this.lessonMutator
        const locationOptions = this.getLocationOptions()
        const roomOptions = this.getLocationRoomOptions()
        const teacherOptions = this.getTeacherOptions()
        const specialActivityOptions = this.getSpecialActivityOptions()
        const examOptions = this.getExamOptions()

        if (!lesson) {
            return null
        }

        const isSpecialActivityLocked = lesson.hasResults

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={`Lesdatum`} errors={errors}>
                            <DateTimePicker defaultValue={get(lesson, 'date')} name="lesson.date" />
                        </Field>
                        <Field title={`Locatie`} errors={errors}>
                            {locationOptions && (
                                <TagPicker
                                    name="lesson.locationId"
                                    multi={false}
                                    options={locationOptions}
                                    onChange={this.onLocationChange}
                                    value={selectedLocationId}
                                    placeholder="Zoek een locatie"
                                />
                            )}
                        </Field>
                        <Field title={`Lesruimte`} isDisabled={!selectedLocationId} errors={errors}>
                            <TagPicker
                                name="lesson.roomId"
                                multi={false}
                                options={roomOptions}
                                value={selectedRoomId}
                                placeholder="Zoek een lesruimte"
                                onChange={this.onRoomChange}
                            />
                        </Field>
                        <Field title={`Docent`} errors={errors}>
                            {teacherOptions && (
                                <TagPicker
                                    name="lesson.teacherUserId"
                                    multi={false}
                                    options={teacherOptions}
                                    onChange={this.onChangeTeacher}
                                    placeholder={'Zoek een docent'}
                                    value={selectedTeacherId}
                                />
                            )}
                        </Field>
                        <Field title={`Bijzondere activiteit`} errors={errors}>
                            <TagPicker
                                name="lesson.specialActivity"
                                multi={false}
                                options={specialActivityOptions}
                                placeholder={'Selecteer speciale activiteit'}
                                onChange={this.onSpecialActivityChange}
                                defaultValue={get(lesson, 'specialActivity')}
                                isReadonly={isSpecialActivityLocked}
                            />
                        </Field>
                        {selectedSpecialActivity === 'exam' && (
                            <FieldGroup isInsetGroup={true}>
                                <Field title={`Toets`} errors={errors}>
                                    <TagPicker
                                        name="lesson.plannedExam.examId"
                                        multi={false}
                                        options={examOptions}
                                        placeholder={'Zoek een toets'}
                                        defaultValue={selectedExamId}
                                        onChange={this.onExamSelected}
                                        isReadonly={isSpecialActivityLocked}
                                    />
                                </Field>
                                {!!selectedExamId && this.renderAbilities(selectedExamId)}
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`}>Opslaan</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { selectedExamId } = this.state
        const {
            lesson: { _id },
        } = this.props

        const plannedExam = get(fields, 'lesson.plannedExam')
        fields = omit(fields, 'lesson.plannedExam')

        this.lessonMutator
            .mutate({
                lesson: {
                    _id,
                    ...transformFormFields(fields.lesson, {
                        date: transformLib.date(),
                        plannedAbilityExams: {
                            value: () => {
                                if (plannedExam && plannedExam.abilities) {
                                    const checkedAbilities = this.getAbilitiesFromDictionary(plannedExam.abilities)

                                    return checkedAbilities.map((examAbility: string) => ({
                                        examAbility,
                                        examId: selectedExamId,
                                    }))
                                }

                                return
                            },
                            isNull: () =>
                                !plannedExam ||
                                !plannedExam.examId ||
                                !plannedExam.abilities ||
                                plannedExam.abilities.length === 0,
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    this.props.onSubmitSuccess(fields)
                }
            })
    }

    private getTeacherOptions(): TagPickerOptions {
        const { data } = this.dataFetcher
        return (get(data, 'groups[0].teacherUsers') || []).map((teacherUser: any) => ({
            value: teacherUser.user._id,
            label: teacherUser.user.profile.name,
        }))
    }

    private getLocationOptions(): TagPickerOptions {
        const { data } = this.dataFetcher
        const { locations } = data
        if (!locations) {
            return []
        }

        return locations.map((location: any) => ({
            value: location._id,
            label: location.name,
        }))
    }

    private getLocationRoomOptions(): TagPickerOptions {
        const { selectedLocationId } = this.state
        const { data } = this.dataFetcher
        const { locations } = data
        if (!locations) {
            return []
        }

        const location: any = find(locations, { _id: selectedLocationId })
        if (!location) {
            return []
        }

        const { rooms } = location
        if (!rooms) {
            return []
        }

        return rooms.map((room: any) => ({
            value: room._id,
            label: room.name,
        }))
    }

    private getSpecialActivityOptions = (): TagPickerOptions => {
        return [
            {
                value: '',
                label: 'Geen',
            },
            ...Object.keys(types.lessonSpecialActivity).map(specialActivity => ({
                value: specialActivity,
                label: translateType('lessonSpecialActivity', specialActivity),
            })),
        ]
    }

    private getExamOptions = (): TagPickerOptions => {
        const { group } = this.props
        const exams = get(group, 'module.exams') || []

        return exams.map((exam: any) => ({
            value: get(exam, 'exam._id'),
            label: get(exam, 'exam.name'),
        }))
    }

    private onLocationChange: TagPickerChangeHandler = option => {
        if (option) {
            this.setState({
                selectedLocationId: option.value,
            })
        }
    }

    private onChangeTeacher: TagPickerChangeHandler = option => {
        if (option) {
            this.setState({
                selectedTeacherId: option.value
            })
        }
    }

    private onRoomChange: TagPickerChangeHandler = option => {
        if (option) {
            this.setState({
                selectedRoomId: option.value,
            })
        }
    }

    private onSpecialActivityChange: TagPickerChangeHandler = option => {
        this.setState({
            selectedSpecialActivity: option ? option.value : null,
        })
    }

    private onExamSelected: TagPickerChangeHandler = option => {
        this.setState({
            selectedExamId: option ? option.value : undefined,
        })
    }

    private renderAbilities = (selectedExamId: string): React.ReactNode => {
        const { errors } = this.lessonMutator
        const moduleExam = this.getModuleExamByExamId(selectedExamId)
        const plannedAbilityExams = this.getPlannedAbilityExamsByExamId(selectedExamId)
        const defaultCheckedAbilities = plannedAbilityExams.map(plannedAbilityExam => plannedAbilityExam.examAbility)

        if (!moduleExam) {
            return
        }

        const availableAbilities = this.getAbilitiesFromDictionary(moduleExam.abilities)

        return (
            <Field title={`Vaardigheden`} errors={errors}>
                <MultiInput type={`checkbox`} isVertical={true}>
                    {availableAbilities.map(availableAbility => {
                        const lesson = this.getAbilityExamLesson(selectedExamId, availableAbility)
                        const isOtherLesson = lesson && lesson._id !== this.props.lesson._id

                        return (
                            <CheckBox
                                key={availableAbility}
                                name={`lesson.plannedExam.abilities.${availableAbility}`}
                                defaultChecked={defaultCheckedAbilities.includes(availableAbility)}
                                isReadonly={isOtherLesson}
                            >
                                {translateType('examAbility', availableAbility)}
                                {isOtherLesson && <> (Wordt gegeven in les {lesson.order + 1})</>}
                            </CheckBox>
                        )
                    })}
                </MultiInput>
            </Field>
        )
    }

    private getModuleExamByExamId = (examId: string): any => {
        const { group } = this.props

        const moduleExams: any[] = get(group, 'module.exams') || []

        return moduleExams.find(moduleExam => moduleExam._id === examId)
    }

    private getAbilitiesFromDictionary = (abilityDict: ExamAbilityDictionary): ExamAbility[] => {
        return Object.values(ExamAbility).filter(ability => {
            return abilityDict[ability] === true
        })
    }

    /**
     * Get the planned examId by lesson
     */
    private getPlannedExamId = (): any => {
        const { lesson } = this.props

        const plannedAbilityExams = lesson.plannedAbilityExams || []
        const [anyPlannedAbilityExam = undefined] = plannedAbilityExams

        if (anyPlannedAbilityExam) {
            return anyPlannedAbilityExam.exam._id
        }

        return null
    }

    /**
     * Get planned ability exams for this lesson,
     * by given examId
     */
    private getPlannedAbilityExamsByExamId = (examId: string): any[] => {
        const { lesson } = this.props

        const plannedAbilityExams: any[] = lesson.plannedAbilityExams || []

        return plannedAbilityExams.filter(plannedAbilityExam => plannedAbilityExam.exam._id === examId)
    }

    /**
     * Get the lesson for any given examId and ability
     */
    private getAbilityExamLesson = (examId: string, ability: ExamAbility): any => {
        const moduleExam = this.getModuleExamByExamId(examId)
        const plannedAbilityExams: any[] = get(moduleExam, 'exam.plannedAbilityExams') || []

        const plannedAbilityExam = plannedAbilityExams.find(e => e.examAbility === ability)

        return plannedAbilityExam ? plannedAbilityExam.lesson : null
    }
}
