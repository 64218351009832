import * as React from 'react'
import { gql } from '@apollo/client'
import Icon from '~/components/Icon'
import { Button } from '~/components/buttons/Button/Button'
import Mutator from '~/utils/Mutator'
import { ExportResponse } from '~/types/ExportResponse'
import downloadFile from '~/utils/downloadFile'

interface Props {
    groupId: string
    groupName: string
}

const EXPORT_USERS_MUTATION = gql`
    mutation _($sortBy: String, $sortDir: String, $filters: UsersFilterInputType, $columns: [String!]) {
        users_createExportFile(filters: $filters, sortBy: $sortBy, sortDir: $sortDir, columns: $columns) {
            fileId
        }
    }
`

export class ExportGroupLearnerParticipantsButton extends React.Component<Props> {
    private learnerUsersExportMutator = new Mutator({
        mutation: EXPORT_USERS_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { loading } = this.learnerUsersExportMutator

        return (
            <Button leftIcon={<Icon name={`download`} />} onClick={this.exportLearnerUsers} isLoading={loading}>
                Uitgebreid overzicht
            </Button>
        )
    }

    private exportLearnerUsers = async () => {
        const { groupName } = this.props

        const response = await this.getLearnerExportResponse()

        if (response) {
            downloadFile(response.fileId, `${groupName}_kandidaten.xlsx`)
        }
    }

    private getLearnerExportResponse = async (): Promise<ExportResponse | void> => {
        const { groupId } = this.props

        const result = await this.learnerUsersExportMutator.mutate({
            filters: {
                filterByEnrolledGroupIds: [groupId],
            },
            sortBy: 'profile.surName',
            sortDir: 'ASC',
            columns: [
                'FirstName',
                'SurName',
                'Gender',
                'Email',
                'PhoneNumber',
                'DateOfBirth',
                'CityOfBirth',
                'CountryOfBirth',
                'CountryOfOrigin',
                'CountriesOfNationality',
                'Learnability',
                'HighestEducationLocal',
                'HighestEducationLocalYear',
                'HighestEducationCountryOfOrigin',
                'HighestEducationCountryOfOriginYear',
                'EnglishAsSupportLanguage',
                'Notes',
                'NativeLanguages',
                'HomeLanguages',
                'OtherLanguages',
                'PreferredLocations',
                'WriteLevel',
                'WriteDate',
                'ReadLevel',
                'ReadDate',
                'ListenLevel',
                'ListenDate',
                'TalkLevel',
                'TalkDate',
                'ConverseLevel',
                'ConverseDate',
                'FileNumbers',
                'TwinFieldCode',
            ],
        })

        if (result && result.users_createExportFile) {
            return result.users_createExportFile
        }
    }
}
