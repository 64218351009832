import React from 'react'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { BEM } from '~/services/BEMService'

interface Props {
    edisaImport?: any
}

export default class EdisaImportResult extends React.Component<Props> {
    private bem = new BEM('EdisaImportResult')

    public render() {
        const { edisaImport } = this.props

        return (
            <div className={this.bem.getClassName()}>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Regelnummer:</SimpleTableCell>
                        <SimpleTableCell>{edisaImport.lastRow.excelRowNumber}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Edisa nummer:</SimpleTableCell>
                        <SimpleTableCell>{edisaImport.lastRow.edisaNumber || '-'}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Naam kandidaat:</SimpleTableCell>
                        <SimpleTableCell>{edisaImport.lastRow.learnerName || '-'}</SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </div>
        )
    }
}
