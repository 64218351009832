import React from 'react'
import { Icon } from '~/components'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isSuccessMessage?: boolean
}

export default class Paragraph extends React.Component<Props> {
    private bem = new BEM('Paragraph', () => ({
        'is-success-messagee': this.props.isSuccessMessage,
    }))

    public render() {
        const { children, isSuccessMessage, className } = this.props

        return (
            <p className={this.bem.getClassName(className)}>
                {isSuccessMessage && (
                    <span className={this.bem.getElement('success-icon')}>
                        <Icon name="checkmark" />
                    </span>
                )}
                {children}
            </p>
        )
    }
}
