import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import { User } from '~/types/User'
import { ColleagueProfileEdit } from '~/components/ExternalOrganizationContactColleague/ColleagueProfileEdit'

interface Props {
    userId: string
    currentUser?: User
    refetch: () => void
    route?: any
    router?: any
}

export class AppOrganizationContactColleaguesDetailEdit extends React.Component<Props> {
    public render() {
        const { userId, router, route, refetch } = this.props

        return (
            <ContentView hasFixedActionBar={true}>
                <ColleagueProfileEdit userId={userId} refetch={refetch} router={router} route={route} />
            </ContentView>
        )
    }
}
