import { gql } from '@apollo/client'
import $ from 'jquery'
import { get } from 'lodash'
const moment = require('moment')
import React from 'react'

import { Calendar, CenterModal, Paragraph } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { CreateRoomUnavailabilityEventForm, EditRoomUnavailabilityEventForm } from '~/forms'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { RouteComponentProps } from 'react-router'
import { Location, Room } from '~/types/Location'
import { Moment } from 'moment'
import { FormFields } from '~/components/Form'
import { Lesson } from '~/types/Lesson'
import { InflowMoment } from '~/types/InflowMoments'

const UNAVAILABILITY_EVENTS_QUERY = gql`
    query _($locationId: MongoID, $roomId: MongoID) {
        locations(byId: $locationId) {
            _id
            rooms(byId: $roomId) {
                _id
                availabilityCalendarEvents {
                    _id
                    title
                    type
                    startDate
                    endDate
                }
                lessons {
                    _id
                    date
                    order
                    duration
                    group {
                        _id
                        isConcept
                        name
                    }
                }
                inflowMoments {
                    _id
                    isConcept
                    inflowModule {
                        _id
                        name
                    }
                    dateRange {
                        from
                        to
                    }
                }
            }
        }
    }
`

const HOLIDAY_EVENTS_QUERY = gql`
    query _ {
        calendarEvents(type: "HOLIDAY") {
            _id
            title
            type
            startDate
            endDate
        }
    }
`

const CREATE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_create(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const EDIT_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_edit(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const DELETE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEventId: MongoID!) {
        calendarEvents_delete(calendarEventId: $calendarEventId) {
            _id
        }
    }
`

interface Props extends RouteComponentProps<{}, {}> {
    locationObject: Location
    room?: Room
}

interface State {
    createEventModalActive: boolean
    createEventModalData: any
    editEventModalActive: boolean
    editEventModalData: any
}

export default class RoomsDetailAvailabilityView extends React.Component<Props, State> {
    public state: State = {
        createEventModalActive: false,
        createEventModalData: null,
        editEventModalActive: false,
        editEventModalData: null,
    }
    private createEventMutator: Mutator
    private editEventMutator: Mutator
    private deleteEventMutator: Mutator
    private calendarEventsFetcher: Fetcher
    private holidayEventsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { locationObject, room } = this.props

        this.createEventMutator = new Mutator({
            mutation: CREATE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.editEventMutator = new Mutator({
            mutation: EDIT_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteEventMutator = new Mutator({
            mutation: DELETE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.calendarEventsFetcher = new Fetcher({
            query: UNAVAILABILITY_EVENTS_QUERY,
            variables: {
                locationId: locationObject._id,
                roomId: room && room._id,
            },

            onChange: () => this.forceUpdate(),
        })

        this.holidayEventsFetcher = new Fetcher({
            query: HOLIDAY_EVENTS_QUERY,

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { createEventModalActive, editEventModalActive } = this.state
        const { loading } = this.calendarEventsFetcher

        return (
            <ContentView>
                <Wrap full={true}>
                    <Paragraph>Let op: Alleen aangeven wanneer de lesruimte niet beschikbaar is!</Paragraph>
                </Wrap>
                <Wrap full>
                    {createEventModalActive && this.renderCreateEventModal()}
                    {editEventModalActive && this.renderEditEventModal()}
                    <Calendar
                        // TODO: Fix this issue and re-enable the no-string-refs rule
                        // eslint-disable-next-line react/no-string-refs
                        ref="Calendar"
                        minHours={8}
                        maxHours={22}
                        height="auto"
                        isLoading={loading}
                        events={this.getCalendarEvents()}
                        defaultView="agendaWeek"
                        allowCreateEvents
                        allowEditEvents
                        selectSlotType="ROOM_UNAVAILABILITY"
                        onSelectSlot={this.onSelectSlot}
                        onMoveEvent={this.onMoveEvent}
                        onResizeEvent={this.onMoveEvent}
                        onClickEvent={this.onClickEvent}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private renderCreateEventModal = () => {
        const { errors, loading } = this.createEventMutator
        const {
            createEventModalData: { start, end, allDay },
        } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseCreateEventModal} title={`Locatiegebruik toevoegen`}>
                <CreateRoomUnavailabilityEventForm
                    defaultValues={{
                        allDay: allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.createEvent}
                    onCancel={this.onRequestCloseCreateEventModal}
                />
            </CenterModal>
        )
    }

    private renderEditEventModal = () => {
        const { errors, loading } = this.editEventMutator
        const {
            editEventModalData: { title, start, end, allDay },
        } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseEditEventModal} title={`Locatiegebruik aanpassen`}>
                <EditRoomUnavailabilityEventForm
                    defaultValues={{
                        allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                        title,
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.editEvent}
                    onRemove={this.removeEvent}
                    onCancel={this.onRequestCloseEditEventModal}
                />
            </CenterModal>
        )
    }

    private isTimelessMoment = (m: Moment) => {
        return Boolean(m.hours() === 0 && m.minutes() === 0 && m.seconds() === 0 && m.milliseconds() === 0)
    }

    private onSelectSlot = (start: Moment, end: Moment) => {
        const allDay = this.isTimelessMoment(start) && this.isTimelessMoment(end)

        this.setState({
            createEventModalActive: true,
            createEventModalData: { start, end, allDay },
        })
    }

    private onClickEvent = (event: any) => {
        this.setState({
            editEventModalActive: true,
            editEventModalData: event,
        })
    }

    private onMoveEvent = async (event: any) => {
        const { _id, start, end } = event

        const data = await this.editEventMutator.mutate({
            calendarEvent: {
                _id: _id,
                startDate: start.toDate(),
                endDate: end.toDate(),
            },
        })

        if (data) {
            this.calendarEventsFetcher.refetch({ silent: true })
        }
    }

    private onRequestCloseCreateEventModal = () => {
        // TODO: Fix this issue and re-enable the no-string-refs rule
        // eslint-disable-next-line react/no-string-refs
        ;($((this.refs.Calendar as any).refs.calendar) as any).fullCalendar('unselect')

        this.setState({
            createEventModalActive: false,
            createEventModalData: null,
        })
    }

    private onRequestCloseEditEventModal = () => {
        this.setState({
            editEventModalActive: false,
            editEventModalData: null,
        })
    }

    private createEvent = async (fields: FormFields) => {
        const { room } = this.props

        const data = await this.createEventMutator.mutate({
            calendarEvent: {
                type: 'ROOM_UNAVAILABILITY',
                typeData: {
                    roomId: room && room._id,
                },
                ...transformFormFields(fields, {
                    repetition: {
                        fields: (v: FormFields) => transformFormFields(v),
                    },
                }),
            },
        })

        if (data) {
            this.onRequestCloseCreateEventModal()
            this.calendarEventsFetcher.refetch()
        }
    }

    private editEvent = async (fields: FormFields) => {
        const { editEventModalData: event } = this.state

        const data = await this.editEventMutator.mutate({
            calendarEvent: {
                _id: event._id,
                ...transformFormFields(fields, {}),
            },
        })

        if (data) {
            this.onRequestCloseEditEventModal()
            this.calendarEventsFetcher.refetch()
        }
    }

    private removeEvent = async () => {
        const { editEventModalData: event } = this.state

        const data = await this.deleteEventMutator.mutate({
            calendarEventId: event._id,
        })

        if (data) {
            this.onRequestCloseEditEventModal()
            this.calendarEventsFetcher.refetch()
        }
    }

    private getCalendarEvents = () => {
        const { data } = this.calendarEventsFetcher

        // Get room availability events
        const availabilityEvents = get(data, 'locations[0].rooms[0].availabilityCalendarEvents') || []

        // Get lesson events
        const lessons = get(data, 'locations[0].rooms[0].lessons') || []
        const lessonEvents = lessons.map((lesson: Lesson) => {
            const group = lesson.group

            return {
                type: 'BACKGROUND_EVENT',
                allDay: false,
                startDate: lesson.date,
                endDate: moment(lesson.date).add('minutes', lesson.duration).toJSON(),
                title: `Les ${lesson.order + 1}: ${group ? group.name : ''}${
                    group && group.isConcept ? ' (conceptgroep)' : ''
                }`,
            }
        })

        // Get inflow events
        const inflowMoments = get(data, 'locations[0].rooms[0].inflowMoments') || []
        const inflowMomentsWithDate = inflowMoments.filter((im: InflowMoment) => {
            return im.dateRange ? !!im.dateRange.from && !!im.dateRange.to : false
        })
        const inflowMomentEvents = inflowMomentsWithDate.map((inflowMoment: InflowMoment) => {
            return {
                type: 'BACKGROUND_EVENT',
                allDay: false,
                startDate: inflowMoment.dateRange.from,
                endDate: inflowMoment.dateRange.to,
                title: `Instroom: ${inflowMoment.inflowModule.name}${inflowMoment.isConcept ? ` (concept)` : ''}`,
            }
        })

        // Get holiday events
        const { data: holidayData } = this.holidayEventsFetcher
        const holidayEvents = (get(holidayData, 'calendarEvents') || []).map((e: any) => ({
            ...e,
            type: 'BACKGROUND_EVENT',
        }))

        // Merge arrays & map for <Calendar /> component
        return [...availabilityEvents, ...lessonEvents, ...inflowMomentEvents, ...holidayEvents].map(e => ({
            ...e,
            start: e.startDate,
            end: e.endDate,
            allDay: e.allDay,
        }))
    }
}
