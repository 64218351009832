import React, { Component } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { get, remove, set } from 'lodash'
import $ from 'jquery'

import { Button, CheckBox, DateTimePicker, MultiInput, TimelineMessageInput } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { getTypeConfiguration } from '~/utils/timelineMessage'

export default class TimelineInput extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        isLoading: PropTypes.bool,
        autoUpdate: PropTypes.bool,
        defaultTimelineEvent: PropTypes.any,
        className: PropTypes.string,
        targetChoices: PropTypes.arrayOf(
            PropTypes.shape({
                keys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
                label: PropTypes.string.isRequired,
                getIsDisabled: PropTypes.function,
            })
        ),
        canSubmit: PropTypes.bool,
    }

    static defaultProps = {
        autoFocus: false,
        autoUpdate: false,
        targetChoices: [],
    }

    constructor(props) {
        super(props)

        this.state = {
            timelineEvent: this.getDefaultTimelineEvent(),
        }
    }

    messageTypes = [
        { value: 'EMAIL', label: 'Mail', ...getTypeConfiguration('EMAIL') },
        { value: 'PHONE', label: 'Telefoon', ...getTypeConfiguration('PHONE') },
        { value: 'CHAT', label: '1-op-1', ...getTypeConfiguration('CHAT') },
        { value: 'TEXT', label: 'SMS', ...getTypeConfiguration('TEXT') },
        { value: 'LETTER', label: 'Brief', ...getTypeConfiguration('LETTER') },
        { value: 'NOTE', label: 'Notitie', ...getTypeConfiguration('NOTE') },
        { value: 'HTML', label: 'HTML', ...getTypeConfiguration('HTML') },
    ]

    componentDidMount() {
        const form = get(this.refs, 'timelineMessageInput.refs.textArea.refs.textarea.form')

        if (form) {
            $(form).on('reset', () => {
                this.setState({
                    timelineEvent: this.getDefaultTimelineEvent(),
                })
            })
        }
    }

    getDefaultTimelineEvent() {
        const { defaultTimelineEvent } = this.props

        if (defaultTimelineEvent) {
            return defaultTimelineEvent
        }

        return {
            type: 'EMAIL',
            typeData: {
                body: '',
            },
            date: new Date().toJSON(),
            owner: {
                targets: [],
            },
        }
    }

    changeTimelineProperty = (path, value) => {
        const { timelineEvent } = this.state
        set(timelineEvent, path, value)
        this.forceUpdate()
    }

    onChangeMessage = (type, body) => {
        this.changeTimelineProperty('type', type)
        this.changeTimelineProperty('typeData.body', body)
    }

    onChangeDate = date => {
        this.changeTimelineProperty('date', date)
    }

    changeTargetValue = (targetChoice, checked) => {
        const targets = this.getTargets().slice()

        for (const key of targetChoice.keys) {
            if (checked) {
                if (!targets.includes(key)) {
                    targets.push(key)
                }
            } else {
                remove(targets, v => v === key)
            }
        }

        this.changeTimelineProperty('owner.targets', targets)
    }

    getClassName() {
        const { className, canSubmit } = this.props

        return c(
            'tt-TimelineInput',
            {
                'tt-TimelineInput--can-submit': canSubmit,
            },
            className
        )
    }

    render() {
        const { canSubmit, autoFocus, targetChoices, isLoading, autoUpdate } = this.props
        const { timelineEvent } = this.state

        return (
            <div className={this.getClassName()}>
                <TimelineMessageInput
                    ref="timelineMessageInput"
                    messageName={'timelineEvent.typeData.body'}
                    messageValue={timelineEvent.typeData.body}
                    types={this.messageTypes}
                    typeName={'timelineEvent.type'}
                    typeValue={timelineEvent.type}
                    onChange={this.onChangeMessage}
                    placeholder={`Voer hier het bericht in`}
                    autoFocus={autoFocus}
                />
                <div
                    className={c('tt-TimelineInput__bottom', {
                        'tt-TimelineInput__bottom--has-target-selection': targetChoices.length > 0,
                        'tt-TimelineInput__bottom--is-active': !!timelineEvent.typeData.body,
                    })}
                >
                    <List horizontal>
                        <ListItem>
                            <DateTimePicker
                                value={timelineEvent.date}
                                autoUpdate={autoUpdate}
                                onChange={this.onChangeDate}
                                name={'timelineEvent.date'}
                            />
                        </ListItem>
                        {canSubmit && (
                            <ListItem right>
                                <Button type={`submit`} styleOverride={'edit'} isLoading={isLoading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        )}
                    </List>
                    {targetChoices.length > 0 && (
                        <List horizontal className="tt-TimelineInput__targets">
                            <ListItem>
                                <MultiInput type="checkbox" isVertical>
                                    {targetChoices.map(this.renderTargetCheckbox)}
                                </MultiInput>
                            </ListItem>
                        </List>
                    )}
                    <input type="hidden" name={`timelineEvent.owner.targets`} value={this.getTargets().join(',')} />
                </div>
            </div>
        )
    }

    renderTargetCheckbox = (targetChoice, i) => {
        const { timelineEvent } = this.state

        const onChange = ({ currentTarget: { checked } }) => {
            this.changeTargetValue(targetChoice, checked)
        }

        const checked = targetChoice.keys.every(key => {
            return this.getTargets().includes(key)
        })

        return (
            <CheckBox
                key={i}
                onChange={onChange}
                checked={checked}
                isDisabled={targetChoice.getIsDisabled(timelineEvent)}
            >
                {targetChoice.label}
            </CheckBox>
        )
    }

    getTargets() {
        const { timelineEvent } = this.state

        return timelineEvent.owner.targets || []
    }
}
