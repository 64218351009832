import c from 'classnames'
import * as React from 'react'

interface Props {
    children?: any
    className?: string
    pushLeft?: boolean
}

export class FieldCollectionFooter extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return (
            <div className={this.getClassName()}>
                <footer className={`tt-FieldCollectionFooter__wrapper`}>{children}</footer>
            </div>
        )
    }

    private getClassName = () => {
        const { className, pushLeft } = this.props

        return c(
            'tt-FieldCollectionFooter',
            {
                'tt-FieldCollectionFooter--push-left': !!pushLeft,
            },
            className
        )
    }
}
