import c from 'classnames'
import * as React from 'react'

import { Button, ToolTip, Link } from '~/components'

import {
    ActivateIcon,
    AlertIcon,
    PaidIcon,
    ApprovedIcon,
    CanceledIcon,
    SentIcon,
    SignatureIcon,
    WaitIcon,
    ShutDownIcon,
} from './icons'
import { Invoice, InvoicePaymentStatus, InvoiceStatus, OpenInvoiceStatus } from '~/types/Invoice'

interface Props {
    className?: string
    ensureShowIconOnly?: boolean
    iconRoute?: string

    // When using no-invoice status 'activatable'
    isActivatableForFinalExamId?: string //
    isActivatableForGroupId?: string //
    isInvoicingEnablableForUserId?: string //
    onRequestActivate?: (id: string) => void

    // When using real invoice status
    invoice?: Invoice
    onRequestMakeFinal?: (invoiceId: string) => void
    isMakeFinalLoading?: boolean
    onRequestUploadSignature?: (invoiceId: string) => void
    isUploadSignatureLoading?: boolean
}

interface StatusIconProps {
    icon: React.ReactNode
    label: string
}

interface StatusButtonProps {
    icon: React.ReactNode
    iconLabel: string
    label: string
    onClick?: () => void
    isLoading?: boolean
}

export class InvoiceStatusIndicator extends React.Component<Props, {}> {
    public render() {
        return <div className={this.getClassName()}>{this.renderStatus()}</div>
    }

    private renderStatus(): React.ReactNode | null {
        const {
            invoice,
            isActivatableForFinalExamId,
            isActivatableForGroupId,
            isInvoicingEnablableForUserId,
            onRequestActivate,
        } = this.props

        const { StatusButton } = this

        if (isInvoicingEnablableForUserId) {
            return (
                <StatusButton
                    label="Facturatie activeren"
                    iconLabel="Activatie van facturatie vereist"
                    icon={<ShutDownIcon />}
                    onClick={() => onRequestActivate && onRequestActivate(isInvoicingEnablableForUserId)}
                />
            )
        }

        if (isActivatableForGroupId) {
            return (
                <StatusButton
                    label="Factuur activeren"
                    iconLabel="Activatie van participatiefactuur vereist"
                    icon={<ActivateIcon />}
                    onClick={() => onRequestActivate && onRequestActivate(isActivatableForGroupId)}
                />
            )
        }

        if (isActivatableForFinalExamId) {
            return (
                <StatusButton
                    label="Factuur activeren"
                    iconLabel="Activatie van examenfactuur vereist"
                    icon={<ActivateIcon />}
                    onClick={() => onRequestActivate && onRequestActivate(isActivatableForFinalExamId)}
                />
            )
        }

        if (invoice) {
            return this.renderStatusForInvoice(invoice)
        }

        return null
    }

    private renderStatusForInvoice = (invoice: Invoice): React.ReactNode => {
        if (invoice.status === InvoiceStatus.Canceled) {
            return this.renderStatusForCanceledInvoice(invoice)
        }

        if (invoice.status === InvoiceStatus.Draft) {
            return this.renderStatusForDraftInvoice(invoice)
        }

        if (invoice.status === InvoiceStatus.Final) {
            return this.renderStatusForFinalInvoice(invoice)
        }

        return null
    }

    private renderStatusForCanceledInvoice = (canceledInvoice: Invoice): React.ReactNode => {
        const { StatusIcon } = this

        if (canceledInvoice.isCredit) {
            return <StatusIcon label="Creditnota afgewezen" icon={<CanceledIcon />} />
        }

        return <StatusIcon label="Geannuleerd" icon={<CanceledIcon />} />
    }

    private renderStatusForDraftInvoice = (draftInvoice: Invoice): React.ReactNode => {
        const { StatusButton, StatusIcon } = this

        const { onRequestMakeFinal, isMakeFinalLoading } = this.props

        if (draftInvoice.isCredit) {
            return <StatusIcon label="Wacht op goedkeuring" icon={<WaitIcon />} />
        }

        if (draftInvoice.isDescendantInstallment && !draftInvoice.descendantInstallmentInvoiceHasFailedToSend) {
            return <StatusIcon label="Nog te versturen" icon={<SentIcon />} />
        }

        const makeFinalCopy = draftInvoice.isForDUO
            ? 'Definitief maken'
            : draftInvoice.installments && draftInvoice.installments > 1
            ? 'Facturen versturen'
            : 'Factuur versturen'

        return (
            <StatusButton
                label={makeFinalCopy}
                iconLabel="Nog te versturen"
                icon={<SentIcon />}
                onClick={() => onRequestMakeFinal && onRequestMakeFinal(draftInvoice._id)}
                isLoading={isMakeFinalLoading}
            />
        )
    }

    private renderStatusForFinalInvoice = (finalInvoice: Invoice): React.ReactNode => {
        const { StatusButton, StatusIcon } = this

        const { onRequestUploadSignature, isUploadSignatureLoading } = this.props

        if (finalInvoice.paymentStatus === InvoicePaymentStatus.Paid) {
            if (finalInvoice.isCredit) {
                return <StatusIcon label="Creditnota betaald" icon={<PaidIcon />} />
            }

            return <StatusIcon label="Factuur betaald" icon={<PaidIcon />} />
        }

        if (finalInvoice.isFullyCredited) {
            return <StatusIcon label="Ingetrokken middels creditatie" icon={<CanceledIcon />} />
        }

        if (finalInvoice.openStatus === OpenInvoiceStatus.WaitingForPayment) {
            if (finalInvoice.isCredit) {
                return <StatusIcon label="Creditnota goedgekeurd" icon={<ApprovedIcon />} />
            }

            if (finalInvoice.isExpired) {
                const unit = finalInvoice.daysExpired === 1 ? `dag` : `dagen`

                return (
                    <StatusIcon
                        label={`In afwachting van betaling, betaaldatum ${finalInvoice.daysExpired} ${unit} verlopen`}
                        icon={<AlertIcon />}
                    />
                )
            }

            if (finalInvoice.isForDUO) {
                return <StatusIcon label="Goedgekeurd door DUO, wacht op betaling" icon={<ApprovedIcon />} />
            }

            return <StatusIcon label="In afwachting van betaling" icon={<ApprovedIcon />} />
        }

        if (finalInvoice.openStatus === OpenInvoiceStatus.WaitingForDUOApproval) {
            return <StatusIcon label="In afwachting van DUO goedkeuring" icon={<SentIcon />} />
        }

        if (finalInvoice.openStatus === OpenInvoiceStatus.RejectedByDUO) {
            return <StatusIcon label="Afgekeurd door DUO" icon={<AlertIcon />} />
        }

        if (finalInvoice.openStatus === OpenInvoiceStatus.WaitingForDUOExportBatch) {
            return <StatusIcon label="In afwachting van DUO batch" icon={<WaitIcon />} />
        }

        if (finalInvoice.openStatus === OpenInvoiceStatus.NeedsSignatureForDUO) {
            return (
                <StatusButton
                    label="Handtekening uploaden"
                    iconLabel="Handtekening nodig"
                    icon={<SignatureIcon />}
                    onClick={() => onRequestUploadSignature && onRequestUploadSignature(finalInvoice._id)}
                    isLoading={isUploadSignatureLoading}
                />
            )
        }

        return null
    }

    private StatusButton = (buttonProps: StatusButtonProps): JSX.Element => {
        const { StatusIcon } = this

        const { ensureShowIconOnly } = this.props

        const { icon, label, iconLabel = buttonProps.label, onClick, isLoading } = buttonProps

        if (ensureShowIconOnly) {
            return <StatusIcon icon={icon} label={iconLabel} />
        }

        return (
            <Button small leftIcon={icon} onClick={onClick} isLoading={isLoading}>
                {label}
            </Button>
        )
    }

    private StatusIcon = (iconProps: StatusIconProps): JSX.Element => {
        const { icon, label: status } = iconProps

        const { invoice, iconRoute } = this.props

        const label = invoice && invoice.invoiceNumber ? `${invoice.invoiceNumber}: ${status}` : status

        return <ToolTip text={label}>{iconRoute ? <Link route={iconRoute}>{icon}</Link> : icon}</ToolTip>
    }

    private getClassName(): string {
        const { className } = this.props

        return c('tt-InvoiceStatusAction', className)
    }
}
