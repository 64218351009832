import { times } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { pdfjs, Document, Page } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = process.env.PDFJS_WORKER_SRC

import { Spinner } from '~/components'
import { getFileUrl, toast } from '~/utils'

export class PdfViewer extends Component {
    static propTypes = {
        getFileId: PropTypes.func.isRequired,
    }

    state = {
        numPages: null,
        // pageNumber: 1,
        url: null,
    }

    componentDidMount() {
        ; (async () => {
            const fileId = await this.props.getFileId()

            if (fileId) {
                const url = await getFileUrl(fileId, `bestand.pdf`)
                this.setState({ url })
            } else {
                toast.error('Kon de PDF niet openen')
            }
        })()
    }

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages })
    }

    render() {
        const { numPages, url } = this.state

        return (
            <div className="tt-PdfViewer">
                {url && (
                    <Document file={url} loading={<Spinner instant />} onLoadSuccess={this.onDocumentLoad}>
                        {times(numPages).map((number, index) => (
                            <Page key={index} pageNumber={number + 1} width={960} />
                        ))}
                    </Document>
                )}
                {!url && <Spinner instant />}
            </div>
        )
    }
}
