import React from 'react'
import { Link } from '~/components'
import { ClassValue, BEM } from '~/services/BEMService'
import { User } from '~/types/User'

interface Props {
    user?: User
    className?: ClassValue
}

export default class UserIcon extends React.Component<Props> {
    private bem = new BEM('UserIcon', () => ({
        'is-link': !!this.getProfileUrl(),
    }))

    public render() {
        const { className: extraClassNames } = this.props
        const url = this.getProfileUrl()
        const className = this.bem.getClassName(extraClassNames)

        if (url) {
            return (
                <Link route={url} className={className}>
                    {this.renderContent()}
                </Link>
            )
        }

        return <span className={className}>{this.renderContent()}</span>
    }

    private renderContent = () => {
        const { user } = this.props

        if (!user) {
            return <span />
        }

        return <span>{user.profile.nameAbbreviation}</span>
    }

    private getProfileUrl = (): string | null => {
        const { user } = this.props

        if (user) {
            if (user.isEmployee) {
                return `/users/employees/${user._id}`
            }

            if (user.isExternalTeacher) {
                return `/profile`
            }

            if (user.isOrganizationContact) {
                return `/profile`
            }
        }

        return null
    }
}
