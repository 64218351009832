import { get, groupBy, values } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Label } from '~/components/Label/Label'
import { Field, FieldCollection, Link, ReadableDate, Subtle } from '~/components'
import { ContentView } from '~/components/ContentView'
import { FieldGroup } from '~/components/FieldGroup'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Wrap } from '~/components/Wrap'

export default class GroupsView extends Component {
    static propTypes = {
        user: PropTypes.object,
    }

    getGroups() {
        const { user } = this.props
        const now = new Date()

        // Merge the groups (but remember where it came from with the `as` property)
        const mergedGroups = [
            ...(get(user, 'teacher.groups') || []).map(group => ({ ...group, as: 'TEACHER' })),
            ...(get(user, 'employee.groups') || []).map(group => ({ ...group, as: 'EMPLOYEE' })),
        ]

        // Filter the duplicates and create an `as` array for every group
        // Also add the `nextLesson` property
        const groupedGroups = values(groupBy(mergedGroups, '_id')).map(_groups => ({
            ..._groups[0],
            as: _groups.map(g => g.as),

            // Determine nextLesson property using filter and sort
            nextLesson: (_groups[0].lessons || [])
                .filter(l => moment(l.date).isAfter(now))
                .sort((a, b) => new Date(a.date) - new Date(b.date))[0],
        }))

        // Split by past/future
        return {
            pastGroups: groupedGroups.filter(g => g.dateTo && moment(g.dateTo).isBefore(now)),
            futureGroups: groupedGroups.filter(g => !g.dateTo || moment(g.dateTo).isAfter(now)),
        }
    }

    render() {
        const { pastGroups, futureGroups } = this.getGroups()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Groepen`}>
                                {futureGroups.length === 0 && (
                                    <Subtle>Geen groepen gevonden voor deze medewerker.</Subtle>
                                )}
                                {futureGroups.map((g, i) => this.renderGroupField(g, i))}
                            </Field>
                        </FieldGroup>
                        <FieldGroup>
                            <Field title={`Verlopen groepen`}>
                                {pastGroups.length === 0 && (
                                    <Subtle>Geen verlopen groepen gevonden voor deze medewerker.</Subtle>
                                )}
                                {pastGroups.map((g, i) => this.renderGroupField(g, i))}
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    renderGroupField(group, index) {
        return (
            <SimpleTable key={index}>
                <SimpleTableRow type={`header`}>
                    <SimpleTableCell colSpan={2}>
                        {group.isConcept ? (
                            <Link route={`/groups/concept/${group._id}`}>{group.name}</Link>
                        ) : (
                            <Link route={`/groups/${group._id}`}>{group.name}</Link>
                        )}
                        {group.isConcept && <Label>Concept</Label>} {this.renderAs(group.as)}
                    </SimpleTableCell>
                </SimpleTableRow>
                {group.module && (
                    <SimpleTableRow>
                        <SimpleTableCell isBold>Lesmodule</SimpleTableCell>
                        <SimpleTableCell>
                            <Link route={`/properties/modules/${group.module._id}`}>{group.module.name}</Link>
                        </SimpleTableCell>
                    </SimpleTableRow>
                )}
                <SimpleTableRow>
                    <SimpleTableCell isBold>Tijdvak</SimpleTableCell>
                    {group.dateFrom && (
                        <SimpleTableCell>
                            Week {moment(group.dateFrom).format('WW')} ({moment(group.dateFrom).format('DD-MM-YYYY')}{' '}
                            t/m {group.dateTo ? moment(group.dateTo).format('DD-MM-YYYY') : 'onbekend'})
                        </SimpleTableCell>
                    )}
                    {!group.dateFrom && <SimpleTableCell>-</SimpleTableCell>}
                </SimpleTableRow>
                <SimpleTableRow>
                    <SimpleTableCell isBold>Eerstvolgende les</SimpleTableCell>
                    {group.nextLesson && (
                        <SimpleTableCell>
                            <Link route={`/groups/${group._id}/lessons/${group.nextLesson._id}`}>
                                Les {group.nextLesson.order + 1}
                            </Link>{' '}
                            (<ReadableDate date={new Date(group.nextLesson.date)} showTime />)
                        </SimpleTableCell>
                    )}
                    {!group.nextLesson && <SimpleTableCell>-</SimpleTableCell>}
                </SimpleTableRow>
            </SimpleTable>
        )
    }

    renderAs(asses) {
        const getTranslation = as =>
            ({
                TEACHER: 'docent',
                EMPLOYEE: 'medewerker',
            }[as])

        const sumWords = words =>
            words.reduce((sentence, word, i, arr) => {
                const suffix = i < arr.length - 1 ? (i < arr.length - 2 ? ', ' : ' en ') : ''
                return (sentence += word + suffix)
            }, [])

        return <Subtle asSuffix>Als {sumWords(asses.map(getTranslation))}</Subtle>
    }
}
