import $ from 'jquery'
import React from 'react'
import { Button, Icon, Input } from '~/components'
import { BEM } from '~/services/BEMService'
import { InputProps } from './Input'

interface Props extends InputProps {
    isClearable?: boolean
    hasSmallButton?: boolean
    defaultSelectedFileName?: string
    hideFileName?: boolean
    isLoading?: boolean
}

interface State {
    fileNames: string[]
}

export default class FileInput extends React.Component<Props, State> {
    public state: State = {
        fileNames: this.props.defaultSelectedFileName ? [this.props.defaultSelectedFileName] : [],
    }

    private bem = new BEM('FileInput', ({ hasValue }: { hasValue: boolean }) => ({
        'has-value': hasValue,
    }))

    public onClick = () => {
        this.clear()
        // TODO: Fix this issue and re-enable the no-string-refs rule
        // eslint-disable-next-line react/no-string-refs
        $((this.refs.fileInput as any).refs.input).trigger('click')
    }

    public onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props

        const files: any = $(event.target)[0].files
        const fileNames = []

        for (const file of files) {
            fileNames.push(file.name)
        }

        if (fileNames.length) {
            this.setState({ fileNames })
        }

        if (onChange) {
            onChange(event)
        }
    }

    public clear = () => {
        // TODO: Fix this issue and re-enable the no-string-refs rule
        // eslint-disable-next-line react/no-string-refs
        ;(this.refs.fileInput as any).refs.input.value = ''
        this.setState({
            fileNames: [],
        })
    }

    public render() {
        const { fileNames } = this.state
        const { children, isClearable, hasSmallButton, accept, hideFileName, isLoading, ...restProps } = this.props
        const hasValue = fileNames.length > 0
        const defaultAccept =
            '.pdf,.csv,.zip,.msg,.txt,.jpg,.jpeg,.png,.m4a,.tif,.tiff,.webp,.mp4,.mp3,.bmp,.wma,.wav,.flac,.ogg,.oga,.mpeg,.aac,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.ods,.odt'
        return (
            <span className={this.bem.getClassName(undefined, { hasValue })}>
                <span className={this.bem.getElement('button')}>
                    <Button
                        leftIcon={<Icon name={`upload`} />}
                        onClick={this.onClick}
                        small={hasSmallButton}
                        isLoading={isLoading}
                    >
                        {children}
                    </Button>
                </span>

                {hasValue && !hideFileName && (
                    <span className={this.bem.getElement('filename')}>
                        {fileNames.map((fileName, i) => (
                            <React.Fragment key={i}>
                                {fileName}
                                {i !== fileNames.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                    </span>
                )}

                <Input
                    ref={`fileInput`}
                    {...restProps}
                    type={`file`}
                    accept={accept || defaultAccept}
                    onChange={this.onChange}
                />

                {hasValue && isClearable && (
                    <Button
                        className={this.bem.getElement('delete-button')}
                        type={`in-row`}
                        onClick={() => this.clear()}
                        leftIcon={<Icon name={`trash`} />}
                    />
                )}
            </span>
        )
    }
}
