import * as React from 'react'
import { Field, Button, Icon, Link, Subtle, Currency } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { ValidationError } from '~/components/ValidationError'
import { ModalManager } from '~/components/ModalManager'
import { translateGroupValidationError } from '~/utils'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Group } from '~/types/Group'
import { CourseMaterial } from '~/types/Module'

interface ValidationErrorType {
    key: string
    stringifiedInfo: string
}

interface Props {
    children: any
    getEditModal?: (closeModal: () => void) => React.ReactNode
    group?: Group
    onRemove?: () => void
    removeMessage?: string
    title: string
    validationErrors?: ValidationErrorType[]
}

export class GroupFieldGroup extends React.Component<Props> {
    public render() {
        const { children, getEditModal, group, onRemove, title, validationErrors } = this.props

        const courseMaterials = group && group.module && group.module.courseMaterials
        const isModuleFieldGroup = title === 'Lesmodule'

        return (
            <FieldGroup hasInlineEdit={true} title={title} separateFields={isModuleFieldGroup}>
                <Field>
                    {children}
                    {(validationErrors || []).map(this.renderValidationError)}
                </Field>
                {courseMaterials && isModuleFieldGroup && this.renderCourseMaterials(courseMaterials)}
                <Field inlineEdit={true}>
                    {onRemove && this.renderRemoveButton()}
                    {getEditModal && this.renderEditButton()}
                </Field>
            </FieldGroup>
        )
    }

    private renderCourseMaterials = (courseMaterials: CourseMaterial[]) => {
        return (
            <Field>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell>Lesmaterialen</SimpleTableCell>
                        <SimpleTableCell>
                            {courseMaterials.map(courseMaterial => {
                                const { _id, name, amount } = courseMaterial
                                const hasAmountValue = typeof amount === 'number'

                                return (
                                    <div key={_id}>
                                        <Link route={`/properties/coursematerials/${_id}`}>
                                            {name}
                                            <Subtle asSuffix={true}>
                                                {hasAmountValue && <Currency amount={amount} />}
                                            </Subtle>
                                        </Link>
                                    </div>
                                )
                            })}
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </Field>
        )
    }

    private renderValidationError = (validationError: ValidationErrorType, i: number) => {
        const { key, stringifiedInfo } = validationError

        let info = {}
        try {
            info = JSON.parse(stringifiedInfo)
            // TODO: We catch but then ignore any errors here. Add error logging or add comment why we ignore these errors.
            // eslint-disable-next-line no-empty
        } catch (err) {}

        return <ValidationError key={i} text={translateGroupValidationError(key, info)} />
    }

    private renderRemoveButton = () => {
        const { onRemove, removeMessage } = this.props

        return (
            <Button
                type={`in-row`}
                onClick={onRemove}
                leftIcon={<Icon name={`trash`} />}
                confirm={{
                    title: 'Verwijderen',
                    message: removeMessage || '',
                    execute: {
                        buttonType: 'danger',
                        title: 'Verwijderen',
                    },
                }}
            />
        )
    }

    private renderEditButton = () => {
        const { getEditModal } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} type={`edit`}>
                        Bewerken
                    </Button>
                )}
                getModal={closeModal => !!getEditModal && getEditModal(closeModal)}
            />
        )
    }
}
