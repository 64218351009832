import { gql } from '@apollo/client'
import React from 'react'

import { Bold, Button, Currency, Field, FieldCollection, Form, Input, CheckBox, ReadableDate } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { FormFields } from '~/components/Form'
import { Invoice, InvoicedLessonUser } from '~/types/Invoice'
import { InlineInputs } from '~/components/Core/Form/InlineInputs/InlineInputs'
import Subtle from '~/components/Subtle'

const CREATE_GROUP_PARTICIPATION_CREDIT_INVOICE_MUTATION = gql`
    mutation _(
        $userId: MongoID
        $debitInvoiceId: MongoID
        $lessonIds: [MongoID]
        $creditDescription: String
        $notes: String
    ) {
        invoices_createGroupParticipationCreditInvoice(
            userId: $userId
            debitInvoiceId: $debitInvoiceId
            lessonIds: $lessonIds
            creditDescription: $creditDescription
            notes: $notes
        ) {
            _id
        }
    }
`

interface Props {
    debitInvoice: Invoice
    userId: string
    onCancel?: () => void
    onSubmitSuccess: () => void
}

interface State {
    checkedLessonIds: string[]
}

export class CreateGroupParticipationCreditInvoiceForm extends React.Component<Props, State> {
    public state: State = {
        checkedLessonIds: [],
    }

    private createGroupParticipationCreditInvoiceMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.createGroupParticipationCreditInvoiceMutator = new Mutator({
            mutation: CREATE_GROUP_PARTICIPATION_CREDIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, debitInvoice } = this.props
        const { loading, errors } = this.createGroupParticipationCreditInvoiceMutator

        const listedLessonIds = this.getListedLessonIds(debitInvoice)

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel title={`Factuuromschrijving`} errors={errors}>
                            <Input
                                type={`text`}
                                placeholder={`Factuuromschrijving`}
                                name={`creditDescription`}
                                defaultValue={`Terugbetaling`}
                            />
                        </Field>
                        <Field isLabel title={`Interne notitie`} errors={errors}>
                            <Input type={`text`} placeholder={`Wordt alleen intern weergegeven`} name={`notes`} />
                        </Field>
                    </FieldGroup>
                    <FieldGroup isForm={true}>
                        <Field title={`Lessen`} errors={errors}>
                            <span data-errorkey={`lessonIds`} />
                            {listedLessonIds.length > 0 ? (
                                <InlineInputs>
                                    {listedLessonIds.map(lessonId => (
                                        <CheckBox
                                            onClick={this.onChangeCheckedLesson(lessonId)}
                                            key={lessonId}
                                            isDisabled={!this.isLessonCreditable(lessonId)}
                                        >
                                            {this.renderLessonLabel(lessonId)}
                                        </CheckBox>
                                    ))}
                                </InlineInputs>
                            ) : (
                                'Geen lessen gevonden'
                            )}
                        </Field>
                    </FieldGroup>
                    <FieldGroup highlight={true}>
                        <Field title={`Creditbedrag`}>
                            <Bold>
                                <Currency amount={this.getAmount()} />
                            </Bold>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Verzoek indienen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private getListedLessonIds = (debitInvoice: Invoice) => {
        if (!debitInvoice.invoicedLessonUsers) {
            return []
        }

        return debitInvoice.invoicedLessonUsers.map(invoicedLessonUser => {
            return invoicedLessonUser.lessonId!
        })
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { checkedLessonIds } = this.state
        const { onSubmitSuccess, debitInvoice, userId } = this.props

        const transformedFields = transformFormFields(fields, {
            notes: (v?: string) => v && v.trim(),
            creditDescription: (v?: string) => v && v.trim(),
        })

        const data = await this.createGroupParticipationCreditInvoiceMutator.mutate({
            userId: userId,
            debitInvoiceId: debitInvoice._id,
            lessonIds: checkedLessonIds,
            creditDescription: transformedFields.creditDescription,
            notes: transformedFields.notes,
        })

        if (data && data.invoices_createGroupParticipationCreditInvoice) {
            onSubmitSuccess()
        }
    }

    private onChangeCheckedLesson = (lessonId: string) => () => {
        const { checkedLessonIds } = this.state

        if (checkedLessonIds.includes(lessonId)) {
            // remove from state
            this.setState(prevState => ({
                checkedLessonIds: prevState.checkedLessonIds.filter(checkedLessonId => checkedLessonId !== lessonId),
            }))
        } else {
            // add to state
            this.setState(prevState => ({
                checkedLessonIds: [...prevState.checkedLessonIds, lessonId],
            }))
        }
    }

    private getAmount() {
        const { debitInvoice } = this.props
        const { checkedLessonIds } = this.state

        if (debitInvoice.creditableAmountPerLesson) {
            return checkedLessonIds.length * debitInvoice.creditableAmountPerLesson
        }

        return undefined
    }

    private isLessonCreditable(lessonId: string): boolean {
        const { debitInvoice } = this.props

        const invoicedLessonUser = this.getInvoicedLessonUserByLessonId(lessonId)
        return invoicedLessonUser.lastDebitInvoiceIdForCreditableLessonUser === debitInvoice._id
    }

    private renderLessonLabel(lessonId: string) {
        const invoicedLessonUser = this.getInvoicedLessonUserByLessonId(lessonId)
        const { lesson, lessonDate } = invoicedLessonUser

        if (!lesson) {
            // lesson is deleted

            return (
                <>
                    Verwijderde les{' '}
                    <Subtle>
                        (<ReadableDate date={lessonDate} format={`DD-MM-YYYY`} />)
                    </Subtle>
                </>
            )
        }

        return (
            <>
                Les {lesson.order + 1}{' '}
                <Subtle>
                    (<ReadableDate date={lessonDate} format={`DD-MM-YYYY`} />)
                </Subtle>
            </>
        )
    }

    private getInvoicedLessonUserByLessonId(lessonId: string): InvoicedLessonUser {
        const { debitInvoice } = this.props

        if (!debitInvoice.invoicedLessonUsers) {
            throw new Error('Could not find debitInvoice.invoicedLessonUsers array')
        }

        const invoicedLessonUser = debitInvoice.invoicedLessonUsers.find(invoicedLessonUser => {
            return invoicedLessonUser.lessonId === lessonId
        })

        if (!invoicedLessonUser) {
            throw new Error('Could not find invoicedLessonUser')
        }

        return invoicedLessonUser
    }
}
