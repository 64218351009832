import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Input, Field, MultiInput, RadioButton, DatePicker } from '~/components'

import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'

export default class CalendarEventTimeFieldGroup extends Component {
    static propTypes = {
        defaultValues: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
            allDay: PropTypes.bool,
        }),
        restrictToAllDay: PropTypes.bool,
        errors: PropTypes.object,
        enableRepetition: PropTypes.bool,
    }

    static defaultProps = {
        restrictToAllDay: false,
        enableRepetition: false,
    }

    constructor(props) {
        super(props)

        this.defaultStartDateMoment = moment(props.defaultValues.startDate)
        this.defaultEndDateMoment = moment(props.defaultValues.endDate)
        this.defaultAllDay = props.restrictToAllDay || props.defaultValues.allDay

        this.state = {
            allDay: this.defaultAllDay,
            startDateDate: this.defaultStartDateMoment.format('YYYY-MM-DD'),
            startDateTime: props.restrictToAllDay ? '00:00' : this.defaultStartDateMoment.format('HH:mm'),
            endDateDate: this.defaultEndDateMoment.format('YYYY-MM-DD'),
            endDateTime: props.restrictToAllDay ? '00:00' : this.defaultEndDateMoment.format('HH:mm'),
            repetitionFrequency: 'ONCE',
        }
    }

    onRepetitionFrequencyChange = option => {
        this.setState({
            repetitionFrequency: option && option.value,
        })
    }

    onChangeAllDay = ({ target }) => {
        const newState = {
            allDay: target.value === 'true',
        }

        if (newState.allDay) {
            newState.startDateTime = '00:00'
            newState.endDateDate = moment(this.state.startDateDate).add(1, 'day').format('YYYY-MM-DD')
            newState.endDateTime = '00:00'
        } else {
            newState.startDateTime = this.defaultAllDay ? '10:00' : this.defaultStartDateMoment.format('HH:mm')
            newState.endDateDate = this.state.startDateDate
            newState.endDateTime = this.defaultAllDay ? '11:00' : this.defaultEndDateMoment.format('HH:mm')
        }

        this.setState(newState)
    }

    onChangeStartDateDate = ({ target }) => {
        this.setState({ startDateDate: target.value })
    }

    onChangeStartDateTime = ({ target }) => {
        this.setState({ startDateTime: target.value })
    }

    onChangeEndDateDate = ({ target }) => {
        this.setState({
            endDateDate: this.state.allDay ? moment(target.value).add(1, 'days').format('YYYY-MM-DD') : target.value,
        })
    }

    onChangeEndDateTime = ({ target }) => {
        this.setState({ endDateTime: target.value })
    }

    render() {
        const { enableRepetition, errors, restrictToAllDay } = this.props
        const { allDay, startDateDate, startDateTime, endDateDate, endDateTime } = this.state

        return (
            <FieldGroup isForm>
                {!restrictToAllDay && (
                    <Field title={'Hele dag'} errors={errors}>
                        <MultiInput type={`radio`}>
                            <RadioButton value={true} checked={allDay} onChange={this.onChangeAllDay}>
                                Ja
                            </RadioButton>
                            <RadioButton value={false} checked={!allDay} onChange={this.onChangeAllDay}>
                                Nee
                            </RadioButton>
                        </MultiInput>
                    </Field>
                )}
                <Field title={'Van'} errors={errors}>
                    <MultiInput type={`date-time`}>
                        <DatePicker value={startDateDate || ''} onChange={this.onChangeStartDateDate} />
                        {!allDay && (
                            <Input type={`time`} value={startDateTime || ''} onChange={this.onChangeStartDateTime} />
                        )}
                    </MultiInput>
                    {this.renderHiddenStartDateInput()}
                </Field>
                <Field title={'Tot'} errors={errors}>
                    <MultiInput type={`date-time`}>
                        {/*
                            allDay events go from 01-xx-xxxx:00:00 to 02-xx-xxxx:00:00
                            so, we are subtracting one day here, to add it later
                        */}
                        <DatePicker
                            value={
                                allDay
                                    ? moment(endDateDate).subtract(1, 'days').format('YYYY-MM-DD')
                                    : endDateDate || ''
                            }
                            onChange={this.onChangeEndDateDate}
                            min={startDateDate}
                        />

                        {!allDay && (
                            <Input type={`time`} value={endDateTime || ''} onChange={this.onChangeEndDateTime} />
                        )}
                    </MultiInput>
                    {this.renderHiddenEndDateInput()}
                </Field>
                {enableRepetition && this.renderRepetitionFields()}
            </FieldGroup>
        )
    }

    renderHiddenStartDateInput() {
        const { startDateDate, startDateTime } = this.state
        const startMoment = moment(startDateDate)
        startMoment.hours(startDateTime.split(':')[0] || 0)
        startMoment.minutes(startDateTime.split(':')[1] || 0)

        return <Input type={`hidden`} name={`event.startDate`} value={startMoment.toJSON() || ''} />
    }

    renderHiddenEndDateInput() {
        const { endDateDate, endDateTime } = this.state
        const endMoment = moment(endDateDate)
        endMoment.hours(endDateTime.split(':')[0] || 0)
        endMoment.minutes(endDateTime.split(':')[1] || 0)

        return <Input type={`hidden`} name={`event.endDate`} value={endMoment.toJSON() || ''} />
    }

    renderRepetitionFields() {
        const { errors } = this.props
        const { repetitionFrequency } = this.state
        const shouldRepeat = repetitionFrequency && repetitionFrequency !== 'ONCE'
        const repetitionAmountInput = shouldRepeat && (
            <Input name="event.repetition.amount" type="number" placeholder="Aantal" />
        )
        const hint =
            shouldRepeat &&
            'Let op: het herhalingspatroon kan niet naderhand aangepast worden. De gecreëerde evenementen kunnen hierna alleen per stuk aangepast worden.'

        return (
            <Field title={'Kopiëren'} errors={errors} hint={hint}>
                <MultiInput type={`frequency`}>
                    <TagPicker
                        name="event.repetition.frequency"
                        multi={false}
                        options={[
                            { value: 'ONCE', label: 'Nee' },
                            { value: 'DAILY', label: 'Dagelijks' },
                            { value: 'WEEKLY', label: 'Wekelijks' },
                            { value: 'MONTHLY', label: 'Maandelijks' },
                        ]}
                        onChange={this.onRepetitionFrequencyChange}
                        value={repetitionFrequency || ''}
                        placeholder="Frequentie"
                    />
                    {repetitionAmountInput}
                </MultiInput>
            </Field>
        )
    }
}
