import { get } from 'lodash'
const moment = require('moment')
import React from 'react'

import { Label } from '~/components/Label/Label'
import { Field, FieldCollection, Link, ReadableDate, Subtle } from '~/components'
import { ContentView } from '~/components/ContentView'
import { FieldGroup } from '~/components/FieldGroup'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Wrap } from '~/components/Wrap'
import { RouteComponentProps } from 'react-router'
import { User } from '~/types/User'
import { Group } from '~/types/Group'
import { Lesson } from '~/types/Lesson'

interface Props extends RouteComponentProps<{}, {}> {
    user: User
}

interface TeacherGroup extends Group {
    nextLesson?: Lesson
}

export default class GroupsView extends React.Component<Props> {
    public render() {
        const { pastGroups, futureGroups } = this.getGroups()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Groepen`}>
                                {futureGroups.length === 0 && <Subtle>Geen groepen gevonden voor deze docent.</Subtle>}
                                {futureGroups.map((g: TeacherGroup, i: number) => this.renderGroupField(g, i))}
                            </Field>
                        </FieldGroup>
                        <FieldGroup>
                            <Field title={`Verlopen groepen`}>
                                {pastGroups.length === 0 && (
                                    <Subtle>Geen verlopen groepen gevonden voor deze docent.</Subtle>
                                )}
                                {pastGroups.map((g: TeacherGroup, i: number) => this.renderGroupField(g, i))}
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    private renderGroupField(group: TeacherGroup, index: number) {
        return (
            <SimpleTable key={index}>
                <SimpleTableRow type={`header`}>
                    <SimpleTableCell colSpan={2}>
                        {group.isConcept ? (
                            <Link route={`/groups/concept/${group._id}`}>{group.name}</Link>
                        ) : (
                            <Link route={`/groups/${group._id}`}>{group.name}</Link>
                        )}
                        {group.isConcept && <Label>Concept</Label>}
                    </SimpleTableCell>
                </SimpleTableRow>
                {group.module && (
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Lesmodule</SimpleTableCell>
                        <SimpleTableCell>
                            <Link route={`/properties/modules/${group.module._id}`}>{group.module.name}</Link>
                        </SimpleTableCell>
                    </SimpleTableRow>
                )}
                <SimpleTableRow>
                    <SimpleTableCell isBold={true}>Tijdvak</SimpleTableCell>
                    {group.dateFrom && (
                        <SimpleTableCell>
                            {/* tslint:disable-next-line:max-line-length */}
                            Week {moment(group.dateFrom).format('WW')} ({moment(group.dateFrom).format('DD-MM-YYYY')}{' '}
                            t/m {group.dateTo ? moment(group.dateTo).format('DD-MM-YYYY') : 'onbekend'})
                        </SimpleTableCell>
                    )}
                    {!group.dateFrom && <SimpleTableCell>-</SimpleTableCell>}
                </SimpleTableRow>
                <SimpleTableRow>
                    <SimpleTableCell isBold={true}>Eerstvolgende les</SimpleTableCell>
                    {group.nextLesson && (
                        <SimpleTableCell>
                            <Link route={`/groups/${group._id}/lessons/${group.nextLesson._id}`}>
                                Les {group.nextLesson.order + 1}
                            </Link>{' '}
                            (<ReadableDate date={new Date(group.nextLesson.date)} showTime={true} />)
                        </SimpleTableCell>
                    )}
                    {!group.nextLesson && <SimpleTableCell>-</SimpleTableCell>}
                </SimpleTableRow>
            </SimpleTable>
        )
    }

    private getGroups() {
        const { user } = this.props
        const now = new Date()
        const groups = get(user, 'teacher.groups') || []

        // Filter the duplicates and create an `as` array for every group
        // Also add the `nextLesson` property
        const mappedGroups = groups.map((g: Group) => ({
            ...g,

            // Determine nextLesson property using filter and sort
            nextLesson: (g.lessons || [])
                .filter(l => moment(l.date).isAfter(now))
                .sort((a, b) => (new Date(a.date) as any) - (new Date(b.date) as any))[0],
        }))

        // Split by past/future
        return {
            pastGroups: mappedGroups.filter((g: TeacherGroup) => g.dateTo && moment(g.dateTo).isBefore(now)),
            futureGroups: mappedGroups.filter((g: TeacherGroup) => !g.dateTo || moment(g.dateTo).isAfter(now)),
        }
    }
}
