import './TimeSlotsTable.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import {
    InflowMomentProjectTimeslots,
    InflowMomentDateRange,
    InflowMomentsProjectedTimeslot,
} from '~/types/InflowMoments'
import { User } from '~/types/User'
import moment from 'moment'
import { capitalize } from 'lodash'
import { List } from '../List'
import { ListItem } from '../ListItem'
import { Bold, Subtle } from '..'

interface Props {
    className?: ClassValue
    projectedTimeslots: InflowMomentProjectTimeslots
    dateRange: InflowMomentDateRange
    employees: User[]
}

interface State {}

export class TimeSlotsTable extends React.Component<Props, State> {
    private bem = new BEM('TimeSlotsTable')

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { className, projectedTimeslots, employees } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('header')}>
                    <Bold className={this.bem.getElement('title')}>{this.renderStartDate()}</Bold>
                    <Subtle className={this.bem.getElement('spots')}>
                        {projectedTimeslots.totalPlaces} beschikbare plaatsen
                    </Subtle>
                    <Subtle regularSize={true} isBlockElement={true} className={this.bem.getElement('employees')}>
                        {employees && employees.map(employee => employee.profile.name).join(', ')}
                    </Subtle>
                </div>
                <div className={this.bem.getElement('list-container')}>{this.renderList()}</div>
            </div>
        )
    }

    private renderList = () => {
        const { projectedTimeslots } = this.props

        if (projectedTimeslots.timeslots.length <= 3) {
            return <List>{projectedTimeslots.timeslots.map(slot => this.renderTimeslots(slot))}</List>
        }

        const middle = Math.ceil(projectedTimeslots.timeslots.length / 2)

        const firstHalf = projectedTimeslots.timeslots.slice(0, middle)
        const secondHalf = projectedTimeslots.timeslots.slice(middle, projectedTimeslots.timeslots.length)

        return (
            <>
                <List>{firstHalf.map(slot => this.renderTimeslots(slot))}</List>
                <List>{secondHalf.map(slot => this.renderTimeslots(slot))}</List>
            </>
        )
    }

    private renderTimeslots(slot: InflowMomentsProjectedTimeslot) {
        const time = `${moment(slot.start).format('HH:mm')} - ${moment(slot.end).format('HH:mm')}`

        return (
            <ListItem key={slot.number}>
                Tijdslot {slot.number}: {time}
            </ListItem>
        )
    }

    private renderStartDate() {
        const { dateRange } = this.props

        const startDate = moment(dateRange.from)
        const endDate = moment(dateRange.to)

        return `${capitalize(startDate.format('dddd D MMMM (HH:mm'))} - ${endDate.format('HH:mm')})`
    }
}
