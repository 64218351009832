import React, { cloneElement } from 'react'

import { ContentView } from '~/components/ContentView'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{}, {}> {
    userId: string
    children: any
}

export default class ProcessView extends React.Component<Props> {
    public render() {
        const { userId, children, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Traject'} routeProps={routeProps}>
                <ContentView>{cloneElement(children, { userId })}</ContentView>
            </RouteView>
        )
    }
}
