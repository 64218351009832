import React from 'react'
import { Button, Icon } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    title?: any
    hasCloseButton?: boolean
    close?: () => void
}

export default class DrawerHeader extends React.Component<Props> {
    private bem = new BEM('DrawerHeader')

    public render() {
        const { className, children, title, hasCloseButton = true, close } = this.props

        if (hasCloseButton) {
            if (children) {
                throw new Error('You cannot have a close button and children at the same time.')
            }

            if (!close) {
                throw new Error('You must provide `close` when you have a close button.')
            }
        }

        return (
            <div className={this.bem.getClassName(className)}>
                {title && <h3 className={this.bem.getElement('title')}>{title}</h3>}
                <div className={this.bem.getElement('content')}>
                    {hasCloseButton && (
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button
                                    className={this.bem.getElement('content-close-button')}
                                    onClick={close}
                                    leftIcon={<Icon name={`times`} />}
                                />
                            </ListItem>
                        </List>
                    )}
                    {children}
                </div>
            </div>
        )
    }
}
