import * as React from 'react'

import { PageModal, PdfBar } from '~/components'
import { PdfViewer } from '~/components/PdfViewer'

interface Props {
    title: string
    fileName: string
    getFileId: any
    onClose: any
}

export class PdfModal extends React.Component<Props, {}> {
    public render() {
        const { title, getFileId, onClose, fileName } = this.props

        return (
            <PageModal title={title} onClose={onClose} enableEasyClose>
                <PdfViewer getFileId={getFileId} />
                <PdfBar getFileId={getFileId} fileName={fileName} />
            </PageModal>
        )
    }
}
