import './ProcessItem.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Subtle, Highlight } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { ValidationError } from '~/components/ValidationError'

interface Props {
    className?: ClassValue
    rows: JSX.Element[]
    warning?: string | null
    extension?: JSX.Element | null
    type: string
    name?: string | JSX.Element
    color?: string | null
}

interface State {}

export class ProcessItem extends React.Component<Props, State> {
    private bem = new BEM('ProcessItem')

    public render() {
        const { className, type, name, rows, extension, warning } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <header className={this.bem.getElement('header')}>
                    <span className={this.bem.getElement('name')}>{name}</span>
                    <Subtle isBlockElement={true} className={this.bem.getElement('type')}>
                        {type}
                    </Subtle>
                </header>
                {warning && (
                    <ValidationError isWarning={true} text={warning} className={this.bem.getElement('warning')} />
                )}
                <Highlight className={this.bem.getElement('table-wrap')}>
                    <SimpleTable className={this.bem.getElement('table')}>{rows.map(row => row)}</SimpleTable>
                </Highlight>
                {!!extension && <div className={this.bem.getElement('extension')}>{extension}</div>}
            </div>
        )
    }
}
