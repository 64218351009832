import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    hasAutoLayout?: boolean
}

export class Table extends React.Component<Props> {
    private bem = new BEM('Table', () => ({
        'has-auto-layout': this.props.hasAutoLayout,
    }))

    public render() {
        const { children, className } = this.props

        return <table className={this.bem.getClassName(className)}>{children}</table>
    }
}
