import * as React from 'react'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import Subtle from '~/components/Subtle'
import ReadableDate from '~/components/ReadableDate'
import { FinalExamLog, FinalExam } from '~/types/FinalExam'
import { FinalExamsLogTypesMap } from '~/components/FinalExams/logs'

interface Props {
    finalExam: FinalExam
}

export class FinalExamsLogList extends React.Component<Props> {
    public render() {
        const { finalExam } = this.props
        const { logs } = finalExam

        if (!logs) {
            return null
        }

        return (
            <SimpleTable>
                {logs.map((log: FinalExamLog, i: number) => {
                    const LogComponent = FinalExamsLogTypesMap[log.type]

                    return (
                        <SimpleTableRow key={i}>
                            <SimpleTableCell shouldPreventWrap={true}>
                                <Subtle>
                                    <ReadableDate date={log.createdAt} />
                                </Subtle>
                            </SimpleTableCell>
                            <SimpleTableCell>
                                <LogComponent
                                    user={log.createdByUser}
                                    DUOFinalExamResult={log.typeData && log.typeData.duoFinalExamResult}
                                />
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )
                })}
            </SimpleTable>
        )
    }
}
