import * as React from 'react'

import { ListItem } from '~/components/ListItem'
import Link from './Link'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    route?: string
    onlyActiveOnIndex?: boolean
}

export class SubNavLink extends React.Component<Props> {
    private bem = new BEM('SubNavLink')

    public render() {
        const { children, className, onlyActiveOnIndex, route } = this.props

        return (
            <ListItem className={this.bem.getClassName(className)}>
                <Link onlyActiveOnIndex={onlyActiveOnIndex} route={route}>
                    {children}
                </Link>
            </ListItem>
        )
    }
}
