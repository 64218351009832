import * as React from 'react'
import { RouteComponentProps, browserHistory } from 'react-router'

import { InflowMoment } from '~/types/InflowMoments'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, FieldCollection, Subtle, Link, Field } from '~/components'
import { gql } from '@apollo/client'
import { Mutator } from '~/utils'
import { getCurrentUser } from '~/services/session'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { getTimeFromMinutes } from '~/utils/getTimeFromMinutes'
import { FieldGroup } from '~/components/FieldGroup'
import { WithSubtle } from '~/components/WithSubtle'
import { Room } from '~/types/Location'
import { capitalize } from 'lodash'
import moment from 'moment'
import { Translator } from '~/services/i18n'
import { InflowMomentIntakerUsersGroup } from '~/components/InflowMoment/FieldGroups/InflowMomentIntakerUsers'
import { InflowMomentEmployeeUsersGroup } from '~/components/InflowMoment/FieldGroups/InflowMomentEmployeeUsersGroup'

const DELETE_INFLOW_MOMENT_MUTATION = gql`
    mutation _($inflowMomentId: MongoID!) {
        inflowMoments_delete(inflowMomentId: $inflowMomentId) {
            _id
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowMoment: InflowMoment
    refetch: () => void
}

export class DefinitiveInflowMomentsDetailDataView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })

    private deleteInflowMomentMutator = new Mutator({
        mutation: DELETE_INFLOW_MOMENT_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const currentUser = getCurrentUser()
        const { isOrganizationContact, isExternalTeacher } = currentUser
        const canDeleteInflowMoment = !isOrganizationContact && !isExternalTeacher
        const { inflowMoment, refetch } = this.props
        const { inflowModule } = inflowMoment
        const { t } = this.translator

        const hasRooms = inflowMoment.location.rooms ? inflowMoment.location.rooms.length > 0 : false

        const date = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        const DynamicComponent = canDeleteInflowMoment ? Link : 'span'

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Instroommodule`} separateFields={true}>
                            <Field>
                                <SimpleTable>
                                    <SimpleTableRow>
                                        <SimpleTableCell>Module</SimpleTableCell>
                                        <SimpleTableCell>
                                            <DynamicComponent route={`/properties/inflow/${inflowModule._id}`}>
                                                {inflowModule.name}
                                            </DynamicComponent>
                                        </SimpleTableCell>
                                    </SimpleTableRow>
                                    {inflowMoment.organization && (
                                        <SimpleTableRow>
                                            <SimpleTableCell>Organisatie</SimpleTableCell>
                                            <SimpleTableCell>
                                                <DynamicComponent
                                                    route={`/properties/organizations/${inflowMoment.organization._id}`}
                                                >
                                                    {inflowMoment.organization.name}
                                                </DynamicComponent>
                                            </SimpleTableCell>
                                        </SimpleTableRow>
                                    )}
                                </SimpleTable>
                                <Subtle>{this.renderInflowModuleInformation()}</Subtle>
                            </Field>
                            {inflowModule.exams && (
                                <Field>
                                    <Subtle>Toetsen</Subtle>
                                    <List>
                                        {inflowModule.exams.map(exam => {
                                            if (!exam.exam) {
                                                return <></>
                                            }

                                            const possibleAbilities = ['converse', 'read', 'write', 'listen', 'talk']
                                            const selectedAbilities = []

                                            for (const ability in exam.abilities) {
                                                if (possibleAbilities.includes(ability) && exam.abilities[ability]) {
                                                    selectedAbilities.push(t(`Properties.exam.abilities.${ability}`))
                                                }
                                            }

                                            return (
                                                <ListItem key={exam.examId}>
                                                    <WithSubtle text={selectedAbilities.join(', ')}>
                                                        <DynamicComponent route={`/properties/exams/${exam.examId}`}>
                                                            {exam.exam.name}
                                                        </DynamicComponent>
                                                    </WithSubtle>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Field>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Locatie`} separateFields={true}>
                            <Field>
                                <Subtle>Locatie</Subtle>
                                <WithSubtle text={this.getLocationText()}>
                                    <DynamicComponent route={`/properties/locations/${inflowMoment.location._id}`}>
                                        {inflowMoment.location.name}
                                    </DynamicComponent>
                                </WithSubtle>
                            </Field>
                            {hasRooms && !inflowMoment.locationRoomsOrganized && (
                                <>
                                    <Field>
                                        <Subtle>Gespreksruimte</Subtle>
                                        {this.renderRooms(inflowMoment.locationRoomsConversation)}
                                    </Field>
                                    <Field>
                                        <Subtle>Gemeenschappelijke ruimte</Subtle>
                                        {this.renderRooms(inflowMoment.locationRoomsGeneral)}
                                    </Field>
                                </>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Datum`}>
                            <Field>
                                {capitalize(date.format('dddd DD MMMM YYYY (H:mm'))} - {endDate.format('H:mm')})
                            </Field>
                        </FieldGroup>
                        <InflowMomentIntakerUsersGroup
                            inflowMoment={inflowMoment}
                            refetch={refetch}
                            hideRemove={true}
                        />
                        <InflowMomentEmployeeUsersGroup
                            inflowMoment={inflowMoment}
                            refetch={refetch}
                            hideRemove={true}
                        />
                    </FieldCollection>

                    <ActionBar
                        fixed={true}
                        isWide={true}
                        getButtons={() => (
                            <List horizontal={true}>
                                {canDeleteInflowMoment && (
                                    <ListItem>
                                        <Button
                                            shouldPreventSubmit={true}
                                            linkStyle={`danger`}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                            onClick={this.onDelete}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        )}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private onDelete: React.MouseEventHandler<HTMLButtonElement> = async () => {
        const { refetch, params } = this.props

        const data = await this.deleteInflowMomentMutator.mutate({
            inflowMomentId: params.id,
        })

        if (data) {
            refetch()
            browserHistory.push(`/inflow-moments/definitive`)
        }
    }

    private renderInflowModuleInformation = () => {
        const { inflowMoment } = this.props
        const { inflowModule } = inflowMoment

        if (!inflowModule) {
            return null
        }

        return [
            `${getTimeFromMinutes(inflowModule.examMaxTimeMinutes || 0)} toetstijd`,
            `${inflowModule.maxStudentsPerStart || 0} kandidaten per startmoment per toetsdocent`,
            `${getTimeFromMinutes(inflowModule.examStartIntervalMinutes || 0)} interval`,
        ].join(' / ')
    }

    private getLocationText = () => {
        const { inflowMoment } = this.props

        if (inflowMoment.locationRoomsOrganized) {
            return `Lesruimtes zijn georganiseerd op locatie`
        }

        if (inflowMoment.location && inflowMoment.location.rooms && inflowMoment.location.rooms.length <= 0) {
            return `Locatie is ook de lesruimte`
        }

        return ``
    }

    private renderRooms = (rooms: Room[]) => {
        const { inflowMoment } = this.props
        const currentUser = getCurrentUser()
        const { isOrganizationContact, isExternalTeacher } = currentUser
        const canSeeLink = !isOrganizationContact && !isExternalTeacher

        const DynamicComponent = canSeeLink ? Link : 'span'

        if (!rooms) {
            return
        }

        return (
            <SimpleTable>
                {rooms.map(room => (
                    <SimpleTableRow key={`${room.name}`}>
                        <SimpleTableCell>
                            <DynamicComponent
                                route={`properties/locations/${inflowMoment.location._id}/rooms/${room._id}`}
                            >
                                {room.name}
                            </DynamicComponent>
                        </SimpleTableCell>
                        <SimpleTableCell>
                            <Subtle>({room.capacity || 0} plekken)</Subtle>
                        </SimpleTableCell>
                    </SimpleTableRow>
                ))}
            </SimpleTable>
        )
    }
}
