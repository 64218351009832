import $ from 'jquery'

const DEFAULT_OFFSET = 92 + 36 // header + spacing
const DEFAULT_GET_SCROLLER = () => window.document.querySelectorAll('html, body')

export default function scrollToElement(options) {
    const { element, getScroller = DEFAULT_GET_SCROLLER, offset = DEFAULT_OFFSET } = options

    const elemOffset = element.parentNode.getBoundingClientRect().top
    const scroller = $(getScroller())
    const totalOffset = scroller.scrollTop() + elemOffset

    scroller.animate(
        {
            scrollTop: totalOffset - offset,
        },
        300,
        'swing'
    )
}
