import emitter from '~/services/event'

export const storage = {
    set(key: any, value: any) {
        // When the localstorage value is changed we want to emit an event
        // telling any other components listening to the event
        // to run a listener.
        emitter.emit(`localstorage.${key}`, value)

        return window.localStorage.setItem(key, value)
    },
    get(key: any) {
        return window.localStorage.getItem(key)
    },
}

export const welcomeModalSettings = {
    isIgnoredForUser(user: any) {
        return storage.get(`toptaal:ignoreWelcomeModalForUser:${user._id}`) === 'true'
    },

    enableForUser(user: any) {
        storage.set(`toptaal:ignoreWelcomeModalForUser:${user._id}`, false)
    },

    disableForUser(user: any) {
        storage.set(`toptaal:ignoreWelcomeModalForUser:${user._id}`, true)
    },
}
