import React from 'react'
import { List } from '~/components/List'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    getActions?: () => JSX.Element
}

export default class TableCellDetail extends React.Component<Props> {
    private bem = new BEM('TableCellDetail')

    public render() {
        const { children, getActions, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <List className={this.bem.getElement('list')}>{children}</List>
                {getActions && <div className={this.bem.getElement('actions')}>{getActions()}</div>}
            </div>
        )
    }
}
