import React from 'react'
import { ContentView } from '~/components/ContentView'
import { UserTimeline } from '~/implementations'
import { User } from '~/types/User'

interface Props {
    user: User
}

export default class TimelineView extends React.Component<Props> {
    public render() {
        const { user } = this.props

        return (
            <ContentView>
                <UserTimeline userId={user._id} timelineEventOwnerType={`TEACHER_USER`} />
            </ContentView>
        )
    }
}
