import React from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'
import { UserLearnerHoursTable } from '~/components/users/UserLearnerHoursTable'

interface Props extends RouteComponentProps<{}, {}> {
    userId: string
    refetch: () => void
    children: any
}

export default class LearnerHoursView extends React.Component<Props, {}> {
    public render() {
        const { userId, refetch, children, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Urenverantwoording inburgering'} routeProps={routeProps}>
                <UserLearnerHoursTable onSubmitSuccess={refetch} userId={userId} />
            </RouteView>
        )
    }
}
