import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, CalendarEventTimeFieldGroup, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import transformFormFields from '~/utils/transformFormFields'

export default class EditHolidayEventForm extends Component {
    static propTypes = {
        defaultValues: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
            allDay: PropTypes.boolean,
            title: PropTypes.string,
        }),
        onSubmit: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        errors: PropTypes.object,
        loading: PropTypes.bool,
    }

    onSubmit = (event, fields) => {
        this.props.onSubmit({
            ...transformFormFields(fields.event),
        })
    }

    render() {
        const { onCancel, onRemove, errors, loading, defaultValues } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Titel`} errors={errors}>
                            <Input
                                name={`event.title`}
                                type={`text`}
                                placeholder={`Titel`}
                                defaultValue={defaultValues.title}
                                autoFocus={true}
                            />
                        </Field>
                    </FieldGroup>
                    <CalendarEventTimeFieldGroup
                        restrictToAllDay
                        defaultValues={{
                            startDate: defaultValues.startDate,
                            endDate: defaultValues.endDate,
                            allDay: defaultValues.allDay,
                        }}
                    />
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem>
                                <Button
                                    onClick={onRemove}
                                    linkStyle={['default', 'danger']}
                                    confirm={{
                                        title: 'Verwijderen',
                                        message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                        execute: {
                                            buttonType: 'danger',
                                            title: 'Verwijderen',
                                        },
                                    }}
                                >
                                    Verwijderen
                                </Button>
                            </ListItem>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}
