import './ResultCell.scss'

import * as React from 'react'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    title?: React.ReactNode
    subtitle?: React.ReactNode
    isNotScheduled?: boolean
}

export class ResultCell extends React.Component<Props, {}> {
    // public defaultProps = {
    //     isScheduled: true,
    // }

    private bem = new BEM('ResultCell', () => ({
        'is-not-scheduled': this.props.isNotScheduled,
    }))

    public render() {
        const { className, title, subtitle } = this.props

        return (
            <TableCell className={this.bem.getClassName(className)}>
                <span className={`tt-ResultCell__content`}>{title}</span>
                <Subtle className={`tt-ResultCell__sub`}>{subtitle}</Subtle>
            </TableCell>
        )
    }
}
