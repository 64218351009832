import React from 'react'
import { ReadableDate } from '~/components'
import { PrecisionDate } from '~/types/User'

interface Props {
    precisionDate?: PrecisionDate
}

export default class ReadablePrecisionDate extends React.Component<Props> {
    public render() {
        const { precisionDate } = this.props

        if (!precisionDate) {
            return <span />
        }

        return (
            <ReadableDate
                format={
                    precisionDate.precision === 'MONTH'
                        ? 'MMMM YYYY'
                        : precisionDate.precision === 'DAY'
                        ? 'DD MMMM YYYY'
                        : 'YYYY'
                }
                date={precisionDate.date}
            />
        )
    }
}
