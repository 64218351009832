import { gql } from '@apollo/client'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateOrganizationForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

export default class MasterView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: filters => {
                this.organizationsFetcher.refetch({
                    searchText: filters.searchText,
                })
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.organizationsFetcher = new Fetcher({
            query: GET_ORGANIZATIONS_QUERY,
            variables: { limit, skip, sortDir, sortBy, searchText: this.filter.getFilters().searchText },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.organizationsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.organizations.length === 0,
                })

                return {
                    organizations: removeDuplicateDocuments([
                        ...(prevData.organizations || []),
                        ...moreData.organizations,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.organizationsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    render() {
        const { loading, data } = this.organizationsFetcher
        const filters = this.filter.getFilters()
        const { organizations = [] } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem>
                                            <Search
                                                isLoading={loading}
                                                onSearch={this.onSearch}
                                                defaultValue={filters.searchText}
                                            />
                                        </ListItem>
                                        <ListItem right>
                                            <ModalManager
                                                render={openModal => (
                                                    <Button onClick={openModal} type={`edit`}>
                                                        Organisatie toevoegen
                                                    </Button>
                                                )}
                                                getModal={closeModal => {
                                                    return (
                                                        <CenterModal
                                                            onClose={closeModal}
                                                            title={`Organisatie toevoegen`}
                                                        >
                                                            <CreateOrganizationForm
                                                                onSubmitSuccess={closeModal}
                                                                onCancel={closeModal}
                                                            />
                                                        </CenterModal>
                                                    )
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`name`}>
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`amountOfLearners`}>
                                        Aantal kandidaten
                                    </TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={2} isLoading />
                                    </TableRow>
                                ) : organizations.length > 0 ? (
                                    this.renderOrganizationRows(organizations)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={2}>
                                            <Subtle>Er zijn geen organisaties gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderOrganizationRows(organizations) {
        return organizations.map(organization => {
            return (
                <TableRow key={organization._id}>
                    <TableCell>
                        <Link route={`/properties/organizations/${organization._id}`}>{organization.name}</Link>
                    </TableCell>
                    <TableCell>{organization.amountOfLearners}</TableCell>
                </TableRow>
            )
        })
    }
}

const GET_ORGANIZATIONS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        organizations(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            amountOfLearners
        }
    }
`
