import * as React from 'react'
import { Router, Route, Redirect, browserHistory, IndexRoute } from 'react-router'
import ReactGA from 'react-ga4'
import env from '~/services/env'

/* tslint:disable max-line-length */
/* tslint:disable no-multi-spaces */
import Root from './views/RootView'

import Cover from './views/Cover'
import CoverLogin from './views/Cover/Login'
import CoverForgot from './views/Cover/Forgot'
import CoverMessage from './views/Cover/ForgotMessage'
import CoverReset from './views/Cover/Reset'
import CoverWelcome from './views/Cover/Welcome'

import App from './views/App'
import AppGroups from './views/App/Groups'
import AppUsers from './views/App/Users'
import AppProperties from './views/App/Properties'
import AppNotFound from './views/App/NotFound'

import AppExternalUserProfile from './views/App/ExternalUserProfile'

import AppExternalTeacherAvailability from './views/App/ExternalTeacherAvailability'
// import AppExternalTeacherIntakes                    from './views/App/ExternalTeacherIntakes'
// import AppExternalTeacherIntakesDetail              from './views/App/ExternalTeacherIntakes/Detail'
// import AppExternalTeacherIntakesDetailData          from './views/App/ExternalTeacherIntakes/Detail/Data'
// import AppExternalTeacherIntakesDetailEdit          from './views/App/ExternalTeacherIntakes/Detail/Edit'
// import AppExternalTeacherIntakesDetailAvailability  from './views/App/ExternalTeacherIntakes/Detail/Availability'
// import AppExternalTeacherIntakesDetailTimeline      from './views/App/ExternalTeacherIntakes/Detail/Timeline'

import AppExternalTeacherLearnerDetail from './views/App/ExternalTeacherLearnerDetail'
import AppExternalTeacherLearnerDetailData from './views/App/ExternalTeacherLearnerDetail/Data'
import AppExternalTeacherLearnerDetailTimeline from './views/App/ExternalTeacherLearnerDetail/Timeline'
import { AppExternalTeacherLearnerDetailProgress } from './views/App/ExternalTeacherLearnerDetail/Progress'

import AppUsersLearners from './views/App/Users/Learners'
import AppUsersLearnersMaster from './views/App/Users/Learners/Master'
import AppUsersLearnersDetail from './views/App/Users/Learners/Detail'
import AppUsersLearnersDetailData from './views/App/Users/Learners/Detail/Data'
import AppUsersLearnersDetailAvailability from './views/App/Users/Learners/Detail/Availability'
import AppUsersLearnersDetailEdit from './views/App/Users/Learners/Detail/Edit'
import AppUsersLearnersDetailTimeline from './views/App/Users/Learners/Detail/Timeline'
import AppUsersLearnersDetailInvoicing from './views/App/Users/Learners/Detail/Invoicing'
import AppUsersLearnersDetailProgress from './views/App/Users/Learners/Detail/Progress'

import AppUsersTeachers from './views/App/Users/Teachers'
import AppUsersTeachersMaster from './views/App/Users/Teachers/Master'
import AppUsersTeachersMasterData from './views/App/Users/Teachers/Master/Data'
import AppUsersTeachersMasterAvailability from './views/App/Users/Teachers/Master/Availability'
import AppUsersTeachersDetail from './views/App/Users/Teachers/Detail'
import AppUsersTeachersDetailData from './views/App/Users/Teachers/Detail/Data'
import AppUsersTeachersDetailGroups from './views/App/Users/Teachers/Detail/Groups'
import AppUsersTeachersDetailIntakes from './views/App/Users/Teachers/Detail/Intakes'
import AppUsersTeachersDetailAvailability from './views/App/Users/Teachers/Detail/Availability'
import AppUsersTeachersDetailEdit from './views/App/Users/Teachers/Detail/Edit'
import AppUsersTeachersDetailTimeline from './views/App/Users/Teachers/Detail/Timeline'

import AppUsersEmployees from './views/App/Users/Employees'
import AppUsersEmployeesMaster from './views/App/Users/Employees/Master'
import AppUsersEmployeesDetail from './views/App/Users/Employees/Detail'
import AppUsersEmployeesDetailData from './views/App/Users/Employees/Detail/Data'
import AppUsersEmployeesDetailGroups from './views/App/Users/Employees/Detail/Groups'
import AppUsersEmployeesDetailEdit from './views/App/Users/Employees/Detail/Edit'

import AppUsersOrganizationContacts from './views/App/Users/OrganizationContacts'
import AppUsersOrganizationContactsMaster from './views/App/Users/OrganizationContacts/Master'
import AppUsersOrganizationContactsDetail from './views/App/Users/OrganizationContacts/Detail'
import AppUsersOrganizationContactsDetailData from './views/App/Users/OrganizationContacts/Detail/Data'
import AppUsersOrganizationContactsDetailEdit from './views/App/Users/OrganizationContacts/Detail/Edit'

import AppGroupsMaster from './views/App/Groups/Started/Master'
import AppGroupsDetail from './views/App/Groups/Started/Detail'
import AppGroupsDetailData from './views/App/Groups/Started/Detail/Data'
import { GroupsStartedDetailParticipantsView } from './views/App/Groups/Started/Detail/Participants'
import AppGroupsDetailLessons from './views/App/Groups/Started/Detail/Lessons'
import AppGroupsDetailLessonsMaster from './views/App/Groups/Started/Detail/Lessons/Master'
import AppGroupsDetailLessonsDetail from './views/App/Groups/Started/Detail/Lessons/Detail'
import AppGroupsDetailAttendance from './views/App/Groups/Started/Detail/Attendance'
import AppGroupsDetailExams from './views/App/Groups/Started/Detail/Exams'
import AppGroupsDetailDocuments from './views/App/Groups/Started/Detail/Documents'
import AppGroupsDetailNotes from './views/App/Groups/Started/Detail/Notes'

import AppGroupsConcept from './views/App/Groups/Concepts'
import AppGroupsConceptMaster from './views/App/Groups/Concepts/Master'
import AppGroupsConceptDetail from './views/App/Groups/Concepts/Detail'

import AppPropertiesOrganizations from './views/App/Properties/Organizations'
import AppPropertiesOrganizationsMaster from './views/App/Properties/Organizations/Master'
import AppPropertiesOrganizationsDetail from './views/App/Properties/Organizations/Detail'
import { OrganizationDataView as AppPropertiesOrganizationsDetailData } from './views/App/Properties/Organizations/Detail/Data'
import { OrganizationEditView as AppPropertiesOrganizationsDetailEdit } from './views/App/Properties/Organizations/Detail/Edit'
import AppPropertiesOrganizationsDetailTimeline from './views/App/Properties/Organizations/Detail/Timeline'
import AppPropertiesOrganizationsDetailDocuments from './views/App/Properties/Organizations/Detail/Documents'

import AppPropertiesProjects from './views/App/Properties/Projects'
import AppPropertiesProjectsMaster from './views/App/Properties/Projects/Master'
import AppPropertiesProjectsDetail from './views/App/Properties/Projects/Detail'
import AppPropertiesProjectsDetailData from './views/App/Properties/Projects/Detail/Data'
import AppPropertiesProjectsDetailEdit from './views/App/Properties/Projects/Detail/Edit'
import AppPropertiesProjectsDetailInvoicingSettings from './views/App/Properties/Projects/Detail/InvoicingSettings'
import AppPropertiesProjectsDetailInvoicingSettingsMaster from './views/App/Properties/Projects/Detail/InvoicingSettings/Master'
import AppPropertiesProjectsDetailInvoicingSettingsDetail from './views/App/Properties/Projects/Detail/InvoicingSettings/Detail'
import AppPropertiesProjectsDetailInvoicingSettingsDetailData from './views/App/Properties/Projects/Detail/InvoicingSettings/Detail/Data'
import AppPropertiesProjectsDetailInvoicingSettingsDetailEdit from './views/App/Properties/Projects/Detail/InvoicingSettings/Detail/Edit'

import AppPropertiesPrograms from './views/App/Properties/Programs'
import AppPropertiesProgramsMaster from './views/App/Properties/Programs/Master'
import AppPropertiesProgramsDetail from './views/App/Properties/Programs/Detail'
import AppPropertiesProgramsDetailData from './views/App/Properties/Programs/Detail/Data'
import AppPropertiesProgramsDetailEdit from './views/App/Properties/Programs/Detail/Edit'

import AppPropertiesModules from './views/App/Properties/Modules'
import AppPropertiesModulesMaster from './views/App/Properties/Modules/Master'
import AppPropertiesModulesDetail from './views/App/Properties/Modules/Detail'

import { ModuleDataView as AppPropertiesModulesDetailData } from './views/App/Properties/Modules/Detail/Data'
import { ModuleEditView as AppPropertiesModulesDetailEdit } from './views/App/Properties/Modules/Detail/Edit'

import AppPropertiesCourseMaterials from './views/App/Properties/CourseMaterials'
import AppPropertiesCourseMaterialsMaster from './views/App/Properties/CourseMaterials/Master'
import AppPropertiesCourseMaterialsDetail from './views/App/Properties/CourseMaterials/Detail'
import AppPropertiesCourseMaterialsDetailData from './views/App/Properties/CourseMaterials/Detail/Data'
import AppPropertiesCourseMaterialsDetailEdit from './views/App/Properties/CourseMaterials/Detail/Edit'

import AppPropertiesLocations from './views/App/Properties/Locations'
import AppPropertiesLocationsMaster from './views/App/Properties/Locations/Master'
import AppPropertiesLocationsDetail from './views/App/Properties/Locations/Detail'
import AppPropertiesLocationsDetailData from './views/App/Properties/Locations/Detail/Data'
import AppPropertiesLocationsDetailEdit from './views/App/Properties/Locations/Detail/Edit'
import AppPropertiesLocationsDetailRooms from './views/App/Properties/Locations/Detail/Rooms'
import AppPropertiesLocationsDetailRoomsMaster from './views/App/Properties/Locations/Detail/Rooms/Master'
import { AppPropertiesLocationsDetailRoomsDetail } from './views/App/Properties/Locations/Detail/Rooms/Detail'
import AppPropertiesLocationsDetailRoomsDetailData from './views/App/Properties/Locations/Detail/Rooms/Detail/Data'
import AppPropertiesLocationsDetailRoomsDetailAvailability from './views/App/Properties/Locations/Detail/Rooms/Detail/Availability'

import AppPropertiesExams from './views/App/Properties/Exams'
import AppPropertiesExamsMaster from './views/App/Properties/Exams/Master'
import AppPropertiesExamsDetail from './views/App/Properties/Exams/Detail'
import AppPropertiesExamsDetailData from './views/App/Properties/Exams/Detail/Data'
import AppPropertiesExamsDetailEdit from './views/App/Properties/Exams/Detail/Edit'

import AppPropertiesLocationProperties from './views/App/Properties/LocationProperties'
import AppPropertiesLocationPropertiesMaster from './views/App/Properties/LocationProperties/Master'
import AppPropertiesLocationPropertiesDetail from './views/App/Properties/LocationProperties/Detail'
import AppPropertiesLocationPropertiesDetailData from './views/App/Properties/LocationProperties/Detail/Data'
import AppPropertiesLocationPropertiesDetailEdit from './views/App/Properties/LocationProperties/Detail/Edit'

import AppStatistics from './views/App/Statistics'
import AppStatisticsEmailBounces from './views/App/Statistics/EmailBounces'

import AppExports from './views/App/Exports'
import AppExportsAll from './views/App/Exports/All'
import AppSystemExportLog from './views/App/Exports/SystemExportLog'

import AppFinancial from './views/App/Financial'
import AppFinancialLearnerInvoices from './views/App/Financial/LearnerInvoices'
import AppFinancialCreditInvoices from './views/App/Financial/CreditInvoices'
import AppFinancialRevenue from './views/App/Financial/Revenue'
import AppFinancialPrognosis from './views/App/Financial/Prognosis'
import { AppFinancialDUOBatches } from './views/App/Financial/DUObatches'
import AppFinancialDUObatchesInvoices from './views/App/Financial/DUObatches/DUOInvoices'
import AppFinancialDUObatchesResponses from './views/App/Financial/DUObatches/DUOResponses'

import AppFinalExams from './views/App/FinalExams'
import AppFinalExamsConcept from './views/App/FinalExams/Concept'
import AppFinalExamsConceptMaster from './views/App/FinalExams/Concept/Master'
import AppFinalExamsPlanned from './views/App/FinalExams/Planned'
import AppFinalExamsPlannedMaster from './views/App/FinalExams/Planned/Master'
import AppFinalExamsPlannedDetail from './views/App/FinalExams/Planned/Detail'
import AppFinalExamsPlannedDetailData from './views/App/FinalExams/Planned/Detail/Data'

import AppOrganizationContactLearners from './views/App/ExternalOrganizationContactLearners'
import AppOrganizationContactLearnersMaster from './views/App/ExternalOrganizationContactLearners/Master'
import AppOrganizationContactLearnersDetail from './views/App/ExternalOrganizationContactLearners/Detail'
import AppOrganizationContactLearnersDetailData from './views/App/ExternalOrganizationContactLearners/Detail/Data'
import AppOrganizationContactLearnersDetailTimeline from './views/App/ExternalOrganizationContactLearners/Detail/Timeline'
import AppOrganizationContactLearnersDetailEdit from './views/App/ExternalOrganizationContactLearners/Detail/Edit'
import { AppOrganizationContactLearnersDetailProgress } from './views/App/ExternalOrganizationContactLearners/Detail/Progress'

import AppOrganizationContactColleagues from './views/App/ExternalOrganizationContactColleagues'
import { AppOrganizationContactColleaguesMaster } from './views/App/ExternalOrganizationContactColleagues/Master'
import { AppOrganizationContactColleaguesDetail } from './views/App/ExternalOrganizationContactColleagues/Detail'
import { AppOrganizationContactColleaguesDetailData } from './views/App/ExternalOrganizationContactColleagues/Detail/Data'
import { AppOrganizationContactColleaguesDetailEdit } from './views/App/ExternalOrganizationContactColleagues/Detail/Edit'

import AppPropertiesHolidays from './views/App/Properties/Holidays'

import AppPropertiesInstructionVideos from './views/App/Properties/InstructionVideos'
import AppPropertiesInstructionVideosMaster from './views/App/Properties/InstructionVideos/Master'
import AppPropertiesInstructionVideosDetail from './views/App/Properties/InstructionVideos/Detail'
import AppPropertiesInstructionVideosDetailData from './views/App/Properties/InstructionVideos/Detail/Data'
import AppPropertiesInstructionVideosDetailEdit from './views/App/Properties/InstructionVideos/Detail/Edit'

import AppPropertiesInflowModules from './views/App/Properties/InflowModules'
import AppPropertiesInflowModulesMaster from './views/App/Properties/InflowModules/Master'
import AppPropertiesInflowModulesDetail from './views/App/Properties/InflowModules/Detail'
import { InflowModuleDataView as AppPropertiesInflowModulesDetailData } from './views/App/Properties/InflowModules/Detail/Data'
import { InflowModuleEditView as AppPropertiesInflowModulesDetailEdit } from './views/App/Properties/InflowModules/Detail/Edit'
import { AppPropertiesInflowModulesInvites } from './views/App/Properties/InflowModules/Detail/Invites'
import { AppPropertiesInflowModulesInvitesMaster } from './views/App/Properties/InflowModules/Detail/Invites/Master'
import { AppPropertiesInflowModulesInvitesDetail } from './views/App/Properties/InflowModules/Detail/Invites/Detail'
import { AppPropertiesInflowModulesInvitesDetailEdit } from './views/App/Properties/InflowModules/Detail/Invites/Detail/Edit'
import { AppPropertiesInflowModulesInvitesDetailData } from './views/App/Properties/InflowModules/Detail/Invites/Detail/Data'

import { DefinitiveInflowMomentsDetailIntakeDetailView } from './views/App/InflowMoments/Definitive/Detail/Slots/IntakeDetail'
import { ExternalTeacherIntakesDetailEditView } from './views/App/InflowMoments/Definitive/Detail/Slots/IntakeDetail/Edit/ExternalTeacherIntakesDetailEditView'
import { ExternalTeacherIntakesDetailDataView } from './views/App/InflowMoments/Definitive/Detail/Slots/IntakeDetail/Data/ExternalTeacherIntakesDetailDataView'
import { ExternalTeacherIntakesDetailAvailabilityView } from './views/App/InflowMoments/Definitive/Detail/Slots/IntakeDetail/Availability/ExternalTeacherIntakesDetailAvailabilityView'
import { ExternalTeacherIntakesDetailTimelineView } from './views/App/InflowMoments/Definitive/Detail/Slots/IntakeDetail/Timeline/ExternalTeacherIntakesDetailTimelineView'
import { DefinitiveInflowMomentsDetailView } from './views/App/InflowMoments/Definitive/Detail/DefinitiveInflowMomentsDetailView'
import { DefinitiveInflowMomentsMasterView } from './views/App/InflowMoments/Definitive/Master/DefinitiveInflowMomentsMasterView'
import { DefinitiveInflowMomentsView } from './views/App/InflowMoments/Definitive/DefinitiveInflowMomentsView'
import { DefinitiveInflowMomentsDetailSlotsDetailView } from './views/App/InflowMoments/Definitive/Detail/Slots/SlotsDetail/DefinitiveInflowMomentsDetailSlotsDetailView'
import { DefinitiveInflowMomentsDetailAdviceReportsView } from './views/App/InflowMoments/Definitive/Detail/AdviceReports/DefinitiveInflowMomentsDetailAdviceReportsView'
import { DefinitiveInflowMomentsDetailDataView } from './views/App/InflowMoments/Definitive/Detail/Data/DefinitiveInflowMomentsDetailDataView'
import { AppUsersCommonDetailDocuments } from './views/App/Users/Common/Detail/Documents/AppUsersCommonDetailDocuments'
import { AppPropertiesModulesInvites } from './views/App/Properties/Modules/Detail/Invites'
import { AppPropertiesModulesInvitesMaster } from './views/App/Properties/Modules/Detail/Invites/Master'
import { AppPropertiesModulesInvitesDetail } from './views/App/Properties/Modules/Detail/Invites/Detail'
import { AppPropertiesModulesInvitesDetailData } from './views/App/Properties/Modules/Detail/Invites/Detail/Data'
import { AppPropertiesModulesInvitesDetailEdit } from './views/App/Properties/Modules/Detail/Invites/Detail/Edit'

/* tslint:enable no-multi-spaces */

const isGaEnabled = env.production && env.googleAnalyticsKey
if (isGaEnabled) {
    ReactGA.initialize(env.googleAnalyticsKey)
}

function logPageView() {
    if (!isGaEnabled) {
        return
    }

    ReactGA.set({ page: window.location.pathname })
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
}

export const router = (
    <Router history={browserHistory} onUpdate={logPageView}>
        <Redirect from="/" to="/inflow-moments" />
        <Redirect from="/users" to="/users/learners" />
        <Redirect from="/final-exams" to="/final-exams/planned" />
        <Redirect from="/properties" to="/properties/organizations" />
        <Redirect from="/statistics" to="/statistics/email-bounces" />
        <Redirect from="/exports" to="/exports/all" />
        <Redirect from="/financial" to="/financial/learner-invoices" />
        <Redirect from="/financial/duo-batches" to="/financial/duo-batches/invoices" />
        <Redirect from="/inflow-moments" to="/inflow-moments/definitive" />
        <Route component={Root}>
            <Route path="/" component={Cover}>
                <Route path="login" component={CoverLogin} />
                <Route path="forgot" component={CoverForgot} />
                <Route path="forgotmessage" component={CoverMessage} />
                <Route path="reset/:token" component={CoverReset} />
                <Route path="welcome/:token" component={CoverWelcome} />
            </Route>
            <Route path="/" component={App}>
                <Route path="groups" component={AppGroups}>
                    <Route path=":groupId/learners/:learnerUserId" component={AppExternalTeacherLearnerDetail}>
                        <IndexRoute component={AppExternalTeacherLearnerDetailData} />
                        <Route path="timeline" component={AppExternalTeacherLearnerDetailTimeline} />
                        <Route path="documents" component={AppUsersCommonDetailDocuments} />
                        <Route path="progress" component={AppExternalTeacherLearnerDetailProgress} />
                    </Route>
                    <Route path="concept" component={AppGroupsConcept}>
                        <IndexRoute component={AppGroupsConceptMaster} />
                        <Route path=":id" component={AppGroupsConceptDetail} />
                    </Route>
                    <Route path=":id" component={AppGroupsDetail}>
                        <Route path="info" component={AppGroupsDetailData} />
                        <Route path="participants" component={GroupsStartedDetailParticipantsView} />
                        <Route path="lessons" component={AppGroupsDetailLessons}>
                            <IndexRoute component={AppGroupsDetailLessonsMaster} />
                            <Route path=":lessonId" component={AppGroupsDetailLessonsDetail} />
                        </Route>
                        <Route path="attendance" component={AppGroupsDetailAttendance} />
                        <Route path="exams" component={AppGroupsDetailExams} />
                        <Route path="documents" component={AppGroupsDetailDocuments} />
                        <Route path="notes" component={AppGroupsDetailNotes} />
                    </Route>
                    <IndexRoute component={AppGroupsMaster} />
                </Route>
                <Route path="final-exams" component={AppFinalExams}>
                    <Route path="candidates" component={AppFinalExamsConcept}>
                        <IndexRoute component={AppFinalExamsConceptMaster} />
                    </Route>
                    <Route path="planned" component={AppFinalExamsPlanned}>
                        <IndexRoute component={AppFinalExamsPlannedMaster} />
                        {/* <Route path='learner-export' component={AppFinalExamsPlannedDetailLearnerExport} /> */}
                        <Route path=":id" component={AppFinalExamsPlannedDetail}>
                            <IndexRoute component={AppFinalExamsPlannedDetailData} />
                        </Route>
                    </Route>
                </Route>
                {/* TODO: Fix imports + remove default exports + change index.jsx files to [classname].jsx */}
                <Route path="inflow-moments" component={require('~/views/App/InflowMoments').InflowMomentsView}>
                    <Route
                        path="candidates"
                        component={require('~/views/App/InflowMoments/Candidates').CandidatesInflowMomentsView}
                    >
                        <IndexRoute
                            component={
                                require('~/views/App/InflowMoments/Candidates/Master').CandidatesInflowMomentsMasterView
                            }
                        />
                    </Route>
                    <Route
                        path="concept"
                        component={require('~/views/App/InflowMoments/Concepts').ConceptInflowMomentsView}
                    >
                        <IndexRoute
                            component={
                                require('~/views/App/InflowMoments/Concepts/Master').AppInflowMomentsConceptsMaster
                            }
                        />
                        <Route
                            path=":id"
                            component={
                                require('~/views/App/InflowMoments/Concepts/Detail').AppInflowMomentsConceptsDetail
                            }
                        >
                            <IndexRoute
                                component={
                                    require('~/views/App/InflowMoments/Concepts/Detail/Data')
                                        .AppPropertiesInflowMomentsConceptsDetailData
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="definitive" component={DefinitiveInflowMomentsView}>
                        <IndexRoute component={DefinitiveInflowMomentsMasterView} />
                        <Route path=":id" component={DefinitiveInflowMomentsDetailView}>
                            {/* <Route path='slots' component={require('~/views/App/InflowMoments/Definitive/Detail/Slots').DefinitiveInflowMomentsDetailSlotsView}> */}
                            <Route path="intakes/:userId" component={DefinitiveInflowMomentsDetailIntakeDetailView}>
                                <IndexRoute component={ExternalTeacherIntakesDetailDataView} />
                                <Route path="edit" component={ExternalTeacherIntakesDetailEditView} />
                                <Route path="availability" component={ExternalTeacherIntakesDetailAvailabilityView} />
                                <Route path="timeline" component={ExternalTeacherIntakesDetailTimelineView} />
                                <Route path="documents" component={AppUsersCommonDetailDocuments} />
                            </Route>
                            <IndexRoute component={DefinitiveInflowMomentsDetailSlotsDetailView} />
                            {/* </Route> */}
                            <Route path="advice-reports" component={DefinitiveInflowMomentsDetailAdviceReportsView} />
                            <Route path="detail" component={DefinitiveInflowMomentsDetailDataView} />
                            {/* <IndexRoute component={require('~/views/App/InflowMoments/Definitive/Detail/Data').DefinitiveInflowMomentsDetailDataView}/> */}
                        </Route>
                    </Route>
                    <IndexRoute component={DefinitiveInflowMomentsMasterView} />
                </Route>
                <Route path="profile" component={AppExternalUserProfile} />
                <Route path="availability" component={AppExternalTeacherAvailability} />
                <Route path="users" component={AppUsers}>
                    <Route path="learners" component={AppUsersLearners}>
                        <IndexRoute component={AppUsersLearnersMaster} />
                        <Route path=":id" component={AppUsersLearnersDetail}>
                            <IndexRoute component={AppUsersLearnersDetailData} />
                            <Route path="edit" component={AppUsersLearnersDetailEdit} />
                            {/* TODO: Fix imports + remove default exports + change index.jsx files to [classname].jsx */}
                            <Route
                                path="process"
                                component={require('~/views/App/Users/Learners/Detail/Process').default}
                            >
                                <Route
                                    path={'hours'}
                                    component={
                                        require('~/views/App/Users/Learners/Detail/Process/LearnerHours').default
                                    }
                                />
                                <Route
                                    path={'contracts'}
                                    component={require('~/views/App/Users/Learners/Detail/Process/Contracts').default}
                                />
                                <IndexRoute
                                    component={require('~/views/App/Users/Learners/Detail/Process/Overview').default}
                                />
                            </Route>
                            <Route path="availability" component={AppUsersLearnersDetailAvailability} />
                            <Route path="documents" component={AppUsersCommonDetailDocuments} />
                            <Route path="timeline" component={AppUsersLearnersDetailTimeline} />
                            <Route path="invoicing" component={AppUsersLearnersDetailInvoicing} />
                            <Route path="progress" component={AppUsersLearnersDetailProgress} />
                        </Route>
                    </Route>
                    <Route path="teachers" component={AppUsersTeachers}>
                        <Route component={AppUsersTeachersMaster}>
                            <IndexRoute component={AppUsersTeachersMasterData} />
                            <Route path="availability" component={AppUsersTeachersMasterAvailability} />
                        </Route>
                        <Route path=":id" component={AppUsersTeachersDetail}>
                            <IndexRoute component={AppUsersTeachersDetailData} />
                            <Route path="edit" component={AppUsersTeachersDetailEdit} />
                            <Route path="groups" component={AppUsersTeachersDetailGroups} />
                            <Route path="intakes" component={AppUsersTeachersDetailIntakes} />
                            <Route path="availability" component={AppUsersTeachersDetailAvailability} />
                            <Route path="documents" component={AppUsersCommonDetailDocuments} />
                            <Route path="timeline" component={AppUsersTeachersDetailTimeline} />
                        </Route>
                    </Route>
                    <Route path="employees" component={AppUsersEmployees}>
                        <IndexRoute component={AppUsersEmployeesMaster} />
                        <Route path=":id" component={AppUsersEmployeesDetail}>
                            <IndexRoute component={AppUsersEmployeesDetailData} />
                            <Route path="documents" component={AppUsersCommonDetailDocuments} />
                            <Route path="edit" component={AppUsersEmployeesDetailEdit} />
                            <Route path="groups" component={AppUsersEmployeesDetailGroups} />
                        </Route>
                    </Route>
                    <Route path="organization-contacts" component={AppUsersOrganizationContacts}>
                        <IndexRoute component={AppUsersOrganizationContactsMaster} />
                        <Route path=":id" component={AppUsersOrganizationContactsDetail}>
                            <IndexRoute component={AppUsersOrganizationContactsDetailData} />
                            <Route path="edit" component={AppUsersOrganizationContactsDetailEdit} />
                        </Route>
                    </Route>
                </Route>
                <Route path="properties" component={AppProperties}>
                    <Route path="organizations" component={AppPropertiesOrganizations}>
                        <IndexRoute component={AppPropertiesOrganizationsMaster} />
                        <Route path=":id" component={AppPropertiesOrganizationsDetail}>
                            <IndexRoute component={AppPropertiesOrganizationsDetailData} />
                            <Route path="edit" component={AppPropertiesOrganizationsDetailEdit} />
                            <Route path="timeline" component={AppPropertiesOrganizationsDetailTimeline} />
                            <Route path="documents" component={AppPropertiesOrganizationsDetailDocuments} />
                        </Route>
                    </Route>
                    <Route path="projects" component={AppPropertiesProjects}>
                        <IndexRoute component={AppPropertiesProjectsMaster} />
                        <Route path=":id" component={AppPropertiesProjectsDetail}>
                            <IndexRoute component={AppPropertiesProjectsDetailData} />
                            <Route path="edit" component={AppPropertiesProjectsDetailEdit} />
                            <Route path="invoicing-settings" component={AppPropertiesProjectsDetailInvoicingSettings}>
                                <IndexRoute component={AppPropertiesProjectsDetailInvoicingSettingsMaster} />
                                <Route
                                    path=":projectInvoicingSettingId"
                                    component={AppPropertiesProjectsDetailInvoicingSettingsDetail}
                                >
                                    <IndexRoute component={AppPropertiesProjectsDetailInvoicingSettingsDetailData} />
                                    <Route
                                        path="edit"
                                        component={AppPropertiesProjectsDetailInvoicingSettingsDetailEdit}
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="programs" component={AppPropertiesPrograms}>
                        <IndexRoute component={AppPropertiesProgramsMaster} />
                        <Route path=":id" component={AppPropertiesProgramsDetail}>
                            <IndexRoute component={AppPropertiesProgramsDetailData} />
                            <Route path="edit" component={AppPropertiesProgramsDetailEdit} />
                        </Route>
                    </Route>
                    <Route path="modules" component={AppPropertiesModules}>
                        <IndexRoute component={AppPropertiesModulesMaster} />
                        <Route path=":id" component={AppPropertiesModulesDetail}>
                            <IndexRoute component={AppPropertiesModulesDetailData} />
                            <Route path="edit" component={AppPropertiesModulesDetailEdit} />
                            <Route path="invites" component={AppPropertiesModulesInvites}>
                                <IndexRoute component={AppPropertiesModulesInvitesMaster} />
                                <Route path=":inviteId" component={AppPropertiesModulesInvitesDetail}>
                                    <IndexRoute component={AppPropertiesModulesInvitesDetailData} />
                                    <Route path="edit" component={AppPropertiesModulesInvitesDetailEdit} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="coursematerials" component={AppPropertiesCourseMaterials}>
                        <IndexRoute component={AppPropertiesCourseMaterialsMaster} />
                        <Route path=":id" component={AppPropertiesCourseMaterialsDetail}>
                            <IndexRoute component={AppPropertiesCourseMaterialsDetailData} />
                            <Route path="edit" component={AppPropertiesCourseMaterialsDetailEdit} />
                        </Route>
                    </Route>
                    <Route path="locations" component={AppPropertiesLocations}>
                        <IndexRoute component={AppPropertiesLocationsMaster} />
                        <Route path=":id" component={AppPropertiesLocationsDetail}>
                            <IndexRoute component={AppPropertiesLocationsDetailData} />
                            <Route path="edit" component={AppPropertiesLocationsDetailEdit} />
                            <Route path="rooms" component={AppPropertiesLocationsDetailRooms}>
                                <IndexRoute component={AppPropertiesLocationsDetailRoomsMaster} />
                                <Route path=":roomId" component={AppPropertiesLocationsDetailRoomsDetail}>
                                    <IndexRoute component={AppPropertiesLocationsDetailRoomsDetailData} />
                                    <Route
                                        path="availability"
                                        component={AppPropertiesLocationsDetailRoomsDetailAvailability}
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="exams" component={AppPropertiesExams}>
                        <IndexRoute component={AppPropertiesExamsMaster} />
                        <Route path=":id" component={AppPropertiesExamsDetail}>
                            <IndexRoute component={AppPropertiesExamsDetailData} />
                            <Route path="edit" component={AppPropertiesExamsDetailEdit} />
                        </Route>
                    </Route>
                    <Route path="locationproperties" component={AppPropertiesLocationProperties}>
                        <IndexRoute component={AppPropertiesLocationPropertiesMaster} />
                        <Route path=":id" component={AppPropertiesLocationPropertiesDetail}>
                            <IndexRoute component={AppPropertiesLocationPropertiesDetailData} />
                            <Route path="edit" component={AppPropertiesLocationPropertiesDetailEdit} />
                        </Route>
                    </Route>
                    <Route path="holidays" component={AppPropertiesHolidays} />
                    <Route path="instructionvideos" component={AppPropertiesInstructionVideos}>
                        <IndexRoute component={AppPropertiesInstructionVideosMaster} />
                        <Route path=":id" component={AppPropertiesInstructionVideosDetail}>
                            <IndexRoute component={AppPropertiesInstructionVideosDetailData} />
                            <Route path="edit" component={AppPropertiesInstructionVideosDetailEdit} />
                        </Route>
                    </Route>
                    <Route path="inflow" component={AppPropertiesInflowModules}>
                        <IndexRoute component={AppPropertiesInflowModulesMaster} />
                        <Route path=":id" component={AppPropertiesInflowModulesDetail}>
                            <IndexRoute component={AppPropertiesInflowModulesDetailData} />
                            <Route path="edit" component={AppPropertiesInflowModulesDetailEdit} />
                            <Route path="invites" component={AppPropertiesInflowModulesInvites}>
                                <IndexRoute component={AppPropertiesInflowModulesInvitesMaster} />
                                <Route path=":inviteId" component={AppPropertiesInflowModulesInvitesDetail}>
                                    <IndexRoute component={AppPropertiesInflowModulesInvitesDetailData} />
                                    <Route path="edit" component={AppPropertiesInflowModulesInvitesDetailEdit} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="statistics" component={AppStatistics}>
                    <Route path="email-bounces" component={AppStatisticsEmailBounces} />
                </Route>
                <Route path="exports" component={AppExports}>
                    <Route path="all" component={AppExportsAll} />
                    <Route path="system-export-log" component={AppSystemExportLog} />
                </Route>
                <Route path="financial" component={AppFinancial}>
                    <Route path="learner-invoices" component={AppFinancialLearnerInvoices} />
                    <Route path="credit-invoices" component={AppFinancialCreditInvoices} />
                    <Route path="revenue" component={AppFinancialRevenue} />
                    <Route path="prognosis" component={AppFinancialPrognosis} />
                    <Route path="duo-batches" component={AppFinancialDUOBatches}>
                        <Route path="invoices" component={AppFinancialDUObatchesInvoices} />
                        <Route path="responses" component={AppFinancialDUObatchesResponses} />
                    </Route>
                </Route>
                <Route path="colleagues" component={AppOrganizationContactColleagues}>
                    <IndexRoute component={AppOrganizationContactColleaguesMaster} />
                    <Route path=":id" component={AppOrganizationContactColleaguesDetail}>
                        <IndexRoute component={AppOrganizationContactColleaguesDetailData} />
                        <Route path="edit" component={AppOrganizationContactColleaguesDetailEdit} />
                    </Route>
                </Route>
                <Route path="learners" component={AppOrganizationContactLearners}>
                    <IndexRoute component={AppOrganizationContactLearnersMaster} />
                    <Route path=":id" component={AppOrganizationContactLearnersDetail}>
                        <IndexRoute component={AppOrganizationContactLearnersDetailData} />
                        <Route path="timeline" component={AppOrganizationContactLearnersDetailTimeline} />
                        <Route path="edit" component={AppOrganizationContactLearnersDetailEdit} />
                        <Route path="documents" component={AppUsersCommonDetailDocuments} />
                        {/* TODO: Fix imports + remove default exports + change index.jsx files to [classname].jsx */}
                        <Route
                            path="process"
                            component={
                                require('~/views/App/ExternalOrganizationContactLearners/Detail/Process').default
                            }
                        >
                            <Route
                                path={'hours'}
                                component={
                                    require('~/views/App/ExternalOrganizationContactLearners/Detail/Process/LearnerHours')
                                        .default
                                }
                            />
                            <IndexRoute
                                component={
                                    require('~/views/App/ExternalOrganizationContactLearners/Detail/Process/Overview')
                                        .default
                                }
                            />
                        </Route>
                        <Route path="progress" component={AppOrganizationContactLearnersDetailProgress} />
                    </Route>
                </Route>
                <Route path="*" component={AppNotFound} />
            </Route>
        </Route>
    </Router>
)

/* tslint:enable max-line-length */
