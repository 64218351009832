import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import * as React from 'react'
import { browserHistory } from 'react-router'

import {
    ActionBar,
    Button,
    CenterModal,
    Field,
    FieldCollection,
    Form,
    Input,
    MultiInput,
    PhoneInput,
    RadioButton,
    Spinner,
    TextArea,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Mutator, toast } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { ModalManager } from '~/components/ModalManager'
import { User } from '~/types/User'
import { ChangeEmailForm } from '~/forms/ChangeEmail'
import { getCurrentUser } from '~/services/session'
import { ChangeMobileMfaForm } from '~/forms/ChangeMobileMfaForm'
import { ChangeUserStatusForm } from '~/forms/ChangeUserStatus'

interface Props {
    user?: any
    params?: any
    refetch?: (options?: any) => void
}

interface State {
    currentUser?: User
}
export default class EditView extends React.Component<Props, State> {
    static propTypes = {
        user: PropTypes.any,
        params: PropTypes.any,
        refetch: PropTypes.func,
    }

    public state: State = {
        currentUser: undefined,
    }

    private userMutator: Mutator
    private organizationsFetcher: Fetcher
    private userDeleteMutator: Mutator
    private resetCognitoTotpMfaMutator: Mutator

    private returnUrl: string

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })
        this.organizationsFetcher = new Fetcher({
            query: GET_ORGANIZATIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        const user = getCurrentUser()

        this.state = {
            currentUser: user,
        }

        this.resetCognitoTotpMfaMutator = new Mutator({
            mutation: RESET_COGNITO_TOTP_MFA_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onDelete = () => {
        const { params, refetch } = this.props
        this.userDeleteMutator
            .mutate({
                userId: params.id,
            })
            .then(() => {
                if (refetch) {
                    refetch()
                }
                browserHistory.push(`/users/organization-contacts/`)
            })
    }

    private onSubmit = (event: any, fields: any) => {
        const { params, refetch } = this.props

        this.userMutator
            .mutate({
                user: {
                    _id: params.id,
                    ...transformFormFields(fields.user, {
                        profile: {
                            fields: (v: any) =>
                                transformFormFields(v, {
                                    address: transformLib.address(),
                                    phoneNumbers: transformLib.phoneNumbers(),
                                }),
                        },
                        organizationContact: {
                            fields: (v: any) => transformFormFields(v),
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }
                    toast.success('Medewerker succesvol opgeslagen')
                    if (this.returnUrl) {
                        browserHistory.push(this.returnUrl)
                    }
                    browserHistory.push(`/users/organization-contacts/${params.id}`)
                }
            })
            .catch(err => {
                throw new Error(err)
            })
    }

    public render() {
        const { user, refetch } = this.props
        const { errors, loading: loadingMutate } = this.userMutator
        const { data: organizationsData, loading: loadingOrganizations } = this.organizationsFetcher
        const { currentUser } = this.state
        const { organizations = [] } = organizationsData
        let userMfaMobileNumber = ''
        let isMfaConfigured = 'Nee'
        let userStatus = ''
        if (user) {
            isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'
            userMfaMobileNumber = user.profile.mfaMobileNumber !== undefined ? user.profile.mfaMobileNumber : ''
            userStatus = user.organizationContact.isActive === true ? 'Actief' : 'Non-actief'
        }
        if (!user) {
            return (
                <Wrap>
                    <Spinner />
                </Wrap>
            )
        }

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={`Persoonlijke gegevens`} isForm>
                                <Field isLabel title={`Naam`} errors={errors}>
                                    <MultiInput type={`name`}>
                                        <Input
                                            name={`user.profile.firstName`}
                                            type={`text`}
                                            placeholder={`Voornaam`}
                                            defaultValue={get(user, 'profile.firstName')}
                                        />
                                        <Input
                                            name={`user.profile.initials`}
                                            type={`text`}
                                            placeholder={`Voorletters`}
                                            defaultValue={get(user, 'profile.initials')}
                                        />
                                        <Input
                                            name={`user.profile.surNamePrefix`}
                                            type={`text`}
                                            placeholder={`Tussenvoegsel`}
                                            defaultValue={get(user, 'profile.surNamePrefix')}
                                        />
                                        <Input
                                            name={`user.profile.surName`}
                                            type={`text`}
                                            placeholder={`Achternaam`}
                                            defaultValue={get(user, 'profile.surName')}
                                        />
                                    </MultiInput>
                                </Field>
                                <Field title={`Geslacht`} errors={errors}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`MALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'MALE'}
                                        >
                                            Man
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`FEMALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'FEMALE'}
                                        >
                                            Vrouw
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`OTHER`}
                                            defaultChecked={get(user, 'profile.gender') === 'OTHER'}
                                        >
                                            Anders
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            <FieldGroup title={`Contactgegevens`} isForm>
                                <Field isLabel title={`E-mailadres`} errors={errors}>
                                    <ModalManager
                                        render={openModal => <Button onClick={openModal}>Email wijzigen</Button>}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Email wijzigen`}>
                                                <ChangeEmailForm
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        if (refetch) {
                                                            refetch()
                                                        }
                                                    }}
                                                    onCancel={closeModal}
                                                    userId={user._id}
                                                    emailProps={user.email}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </Field>
                                <Field isLabel title={`Telefoonnummer`} errors={errors}>
                                    <VariableMultiInput
                                        defaultAmount={get(user, 'profile.phoneNumbers.length')}
                                        getNewInput={i => {
                                            return (
                                                <PhoneInput
                                                    key={i}
                                                    name={`user.profile.phoneNumbers[${i}]`}
                                                    defaultValue={get(user, `profile.phoneNumbers[${i}]`)}
                                                />
                                            )
                                        }}
                                        addButtonLabel={`Extra veld`}
                                    />
                                </Field>
                                <Field isLabel title={`Organisatie`} errors={errors}>
                                    <TagPicker
                                        name={`user.organizationContact.organizationId`}
                                        placeholder={`Selecteer organisatie`}
                                        defaultValue={get(user, `organizationContact.organization._id`)}
                                        multi={false}
                                        options={organizations.map((o: any) => ({ label: o.name, value: o._id }))}
                                        isLoading={loadingOrganizations}
                                    />
                                </Field>
                            </FieldGroup>
                            {currentUser && currentUser.isAdmin && user && (
                                <div>
                                    {/** this is needed to allow setting mobile MFA in case user couldn't continue with the MFA setup
                                     */}
                                    <Field isLabel title={`Geconfigureerd`}>
                                        {isMfaConfigured}
                                    </Field>
                                    <Field isLabel title={`Mobiel nummer voor MFA met SMS`}>
                                        <div>{userMfaMobileNumber}</div>
                                        <ModalManager
                                            render={openModal => <Button onClick={openModal}>Updaten</Button>}
                                            getModal={closeModal => (
                                                <CenterModal
                                                    onClose={closeModal}
                                                    title={`Mobiel nummer voor MFA invoeren`}
                                                >
                                                    <ChangeMobileMfaForm
                                                        onSubmitSuccess={() => {
                                                            closeModal()
                                                            if (refetch) {
                                                                refetch()
                                                            }
                                                        }}
                                                        onCancel={closeModal}
                                                        userId={user._id}
                                                        mfaMobileNumberProps={userMfaMobileNumber}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </Field>
                                    {user.profile.isMfaConfigured && user.profile.isMfaConfigured === true && (
                                        <Field isLabel title={`Reset MFA`}>
                                            <Button onClick={this.resetCognitoTotpMfa}>Reset</Button>
                                        </Field>
                                    )}
                                </div>
                            )}
                            <FieldGroup title={`Overige`} isForm>
                                <Field isLabel title={`Notities`} errors={errors}>
                                    <TextArea name={`user.profile.notes`} defaultValue={get(user, `profile.notes`)} />
                                </Field>
                                {currentUser && currentUser.isAdmin && (
                                    <Field title={`Status`} errors={errors}>
                                        <div>{userStatus}</div>
                                        <ModalManager
                                            render={openModal => <Button onClick={openModal}>Status wijzigen</Button>}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Status wijzigen`}>
                                                    <ChangeUserStatusForm
                                                        onSubmitSuccess={() => {
                                                            closeModal()
                                                            if (refetch) {
                                                                refetch()
                                                            }
                                                        }}
                                                        onCancel={closeModal}
                                                        userId={user._id}
                                                        userStatus={user.organizationContact.isActive}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </Field>
                                )}
                            </FieldGroup>
                        </FieldCollection>
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem>
                                            <Button linkStyle={['default', 'danger']} onClick={e => this.onDelete()}>
                                                Verwijderen
                                            </Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button type={`submit`} isLoading={loadingMutate}>
                                                Opslaan
                                            </Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button
                                                onClick={() =>
                                                    browserHistory.push(`/users/organization-contacts/${user._id}`)
                                                }
                                            >
                                                Annuleren
                                            </Button>
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private resetCognitoTotpMfa = () => {
        const { params, refetch, user } = this.props
        this.resetCognitoTotpMfaMutator
            .mutate({
                userId: user._id,
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }
                    toast.success('MFA reset is successful')
                    if (this.returnUrl) {
                        browserHistory.push(this.returnUrl)
                    }
                    browserHistory.push(`/users/employees/${params.id}`)
                }
            })
    }
}

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`

const GET_ORGANIZATIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`

const RESET_COGNITO_TOTP_MFA_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_resetCognitoTotpMfa(userId: $userId) {
            _id
        }
    }
`
