import './RevenueTable.scss'

import c from 'classnames'
import * as React from 'react'

import { Table } from '~/components/Table'

interface Props {
    className?: string
}

export class RevenueTable extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return (
            <Table hasAutoLayout={true} className={this.getClassName()}>
                {children}
            </Table>
        )
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-RevenueTable', className)
    }
}
