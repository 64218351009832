import React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    warning?: boolean
}

export default class Emphasis extends React.Component<Props> {
    private bem = new BEM('Emphasis', () => ({
        warning: this.props.warning,
    }))

    public render() {
        const { children } = this.props

        return <em className={this.bem.getClassName()}>{children}</em>
    }
}
