import './RevenueTableHeaderMonthItem.scss'

import c from 'classnames'
import * as React from 'react'

import { TableHeaderItem } from '~/components'

interface Props {
    className?: string
    isYearTotal?: boolean
    isSpacer?: boolean
}

export class RevenueTableHeaderMonthItem extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <TableHeaderItem className={this.getClassName()}>{children}</TableHeaderItem>
    }

    private getClassName() {
        const { className, isYearTotal, isSpacer } = this.props

        return c(
            'tt-RevenueTableHeaderMonthItem',
            {
                'tt-RevenueTableHeaderMonthItem--is-year-total': isYearTotal,
                'tt-RevenueTableHeaderMonthItem--is-spacer': isSpacer,
            },
            className
        )
    }
}
