import c from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Input from './Input'

export default class RadioButton extends Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isReadonly: PropTypes.bool,
        hasError: PropTypes.bool,
    }

    render() {
        const { children, className, hasError, ...inputProps } = this.props

        return (
            <label className={this.getClassName()}>
                <Input {...inputProps} type={`radio`} />
                <span className={`tt-RadioButton__box`}>
                    <span className={`tt-RadioButton__box__circle`}></span>
                </span>
                <span className={`tt-RadioButton__text`}>{children}</span>
            </label>
        )
    }

    getClassName() {
        const { className, isDisabled, isReadonly, hasError } = this.props

        return c(
            'tt-RadioButton',
            {
                'tt-RadioButton--is-disabled': isDisabled,
                'tt-RadioButton--is-readonly': isReadonly,
                'tt-RadioButton--has-error': hasError,
            },
            className
        )
    }
}
