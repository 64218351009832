export enum AbilityExamPassedType {
    Passed = 'PASSED',
    NearlyPassed = 'NEARLY_PASSED',
    NotPassed = 'NOT_PASSED',
}

/**
 * Get the passed-type for score-type ability-exam result
 */
export function getPassedTypeForScore(minimumScore: number, resultScore: number): AbilityExamPassedType {
    if (resultScore >= minimumScore) {
        return AbilityExamPassedType.Passed
    }

    const nearPassThreshold = Math.floor(minimumScore * 0.9)

    if (resultScore >= nearPassThreshold) {
        return AbilityExamPassedType.NearlyPassed
    }

    return AbilityExamPassedType.NotPassed
}
