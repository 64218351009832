import GroupNameFieldGroup from './GroupNameFieldGroup'
import GroupModuleFieldGroup from './GroupModuleFieldGroup'
import GroupLearnersFieldGroup from './GroupLearnersFieldGroup'
import GroupLessonDatesFieldGroup from './GroupLessonDatesFieldGroup'
import GroupStartWeekDateFieldGroup from './GroupStartWeekDateFieldGroup'
import GroupLocationFieldGroup from './GroupLocationFieldGroup'
import GroupTeachersFieldGroup from './GroupTeachersFieldGroup'
import GroupEmployeesFieldGroup from './GroupEmployeesFieldGroup'
import GroupLessonTeachersFieldGroup from './GroupLessonTeachersFieldGroup'
import GroupDateBlockersFieldGroup from './GroupDateBlockersFieldGroup'

export default {
    Name: GroupNameFieldGroup,
    Module: GroupModuleFieldGroup,
    Learners: GroupLearnersFieldGroup,
    LessonDates: GroupLessonDatesFieldGroup,
    StartWeekDate: GroupStartWeekDateFieldGroup,
    Location: GroupLocationFieldGroup,
    Teachers: GroupTeachersFieldGroup,
    Employees: GroupEmployeesFieldGroup,
    LessonTeachers: GroupLessonTeachersFieldGroup,
    DateBlockers: GroupDateBlockersFieldGroup,
}
