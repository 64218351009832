import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class CoverPageTitle extends React.Component<Props> {
    private bem = new BEM('CoverPageTitle')

    public render() {
        const { children, className } = this.props

        return <h2 className={this.bem.getClassName(className)}>{children}</h2>
    }
}
