import $ from 'jquery'

const scrollListeners = []
const scrollBottomListeners = []
const $window = $(window)
const $document = $(document)

$window.on('scroll', () => {
    const scrollTop = $window.scrollTop()
    const windowHeight = $window.height()
    const documentHeight = $document.height()
    const offset = 0

    const bottomReached = !!(scrollTop + windowHeight >= documentHeight - offset)

    const eventObj = {
        scrollTop,
        bottomReached,
    }

    scrollListeners.forEach(cb => cb({ event: 'all', ...eventObj }))

    if (bottomReached) scrollBottomListeners.forEach(cb => cb({ event: 'bottom', ...eventObj }))
})

function addScrollListener(event, cb) {
    if (event === 'all') {
        scrollListeners.push(cb)
    } else if (event === 'bottom') {
        scrollBottomListeners.push(cb)
    }
}

function removeScrollListener(event, cb) {
    if (event === 'all') {
        const index = scrollListeners.indexOf(cb)
        scrollListeners.splice(index, 1)
    } else if (event === 'bottom') {
        const index = scrollBottomListeners.indexOf(cb)
        scrollBottomListeners.splice(index, 1)
    }
}

export { addScrollListener, removeScrollListener }
