import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    insetTable?: boolean
    containsDropdowns?: boolean
}

export default class TableWrap extends React.Component<Props> {
    private bem = new BEM('TableWrap', () => ({
        'inset-table': this.props.insetTable,
        'contains-dropdowns': this.props.containsDropdowns,
    }))

    public render() {
        const { children, className } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
