import c from 'classnames'
import * as React from 'react'

interface Props {
    className?: string
    asSuffix?: boolean
    isBlockElement?: boolean
    regularSize?: boolean
}

export default class Subtle extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <span className={this.getClassName()}>{children}</span>
    }

    private getClassName() {
        const { asSuffix, isBlockElement, className, regularSize } = this.props

        return c(
            'tt-Subtle',
            {
                'tt-Subtle--as-suffix': asSuffix,
                'tt-Subtle--is-block-element': isBlockElement,
                'tt-Subtle--is-regular-size': regularSize,
            },
            className
        )
    }
}
