import * as React from 'react'

type ReplaceBreadCrumbFn = (newBreadCrumb: BreadCrumb) => void
type RemoveBreadCrumbFn = () => void

export interface AddedBreadCrumbResult {
    breadCrumb: BreadCrumb
    replaceBreadCrumb: ReplaceBreadCrumbFn
    removeBreadCrumb: RemoveBreadCrumbFn
}

type AddBreadCrumbFn = (breadCrumb: BreadCrumb) => AddedBreadCrumbResult

export interface BreadCrumb {
    label: React.ReactNode
    labelString: string
    path: string
    showOnlyInLeadingCrumb: boolean
    isLoading: boolean
}

export interface TBreadCrumbContext {
    breadCrumbs: BreadCrumb[]
    addBreadCrumb: AddBreadCrumbFn
}

interface State {
    breadCrumbs: BreadCrumb[]
}

export const BreadCrumbContext = React.createContext<TBreadCrumbContext | undefined>(undefined)

export class BreadCrumbProvider extends React.PureComponent<{}, State> {
    public state: State = {
        breadCrumbs: [],
    }

    public render() {
        return (
            <BreadCrumbContext.Provider value={this.getBreadCrumbContext()}>
                {this.props.children}
            </BreadCrumbContext.Provider>
        )
    }

    /**
     * Composes a new breadcrumb context with a reference
     * to the state and functions to add and remove a breadcrumb
     * from the state.
     */
    private getBreadCrumbContext = (): TBreadCrumbContext => {
        return {
            breadCrumbs: this.state.breadCrumbs,
            addBreadCrumb: breadCrumb => {
                let _breadCrumb: BreadCrumb = Object.assign({}, breadCrumb)

                this.addBreadCrumb(breadCrumb)

                return {
                    breadCrumb,
                    replaceBreadCrumb: breadCrumb => {
                        const _replaceWithBreadCrumb: BreadCrumb = Object.assign({}, breadCrumb)

                        this.replaceWithBreadCrumb(breadCrumb, _replaceWithBreadCrumb)

                        _breadCrumb = _replaceWithBreadCrumb
                    },
                    removeBreadCrumb: () => {
                        this.removeBreadCrumb(_breadCrumb)
                    },
                }
            },
        }
    }

    /**
     * Add a given breadcrumb to the state
     */
    private addBreadCrumb(newBreadCrumb: BreadCrumb): void {
        this.setState(prevState => {
            const breadCrumbs = prevState.breadCrumbs.slice()

            breadCrumbs.push(newBreadCrumb)

            return { breadCrumbs }
        })
    }

    /**
     * Replace the breadcrumb with another.
     * If no replacing breadcrumb is provided,
     * just remove the breadcrumb.
     */
    private replaceWithBreadCrumb = (breadCrumb: BreadCrumb, newBreadCrumb?: BreadCrumb): void => {
        if (breadCrumb === newBreadCrumb) {
            return
        }

        this.setState(prevState => {
            const breadCrumbs = prevState.breadCrumbs.slice()

            const index = breadCrumbs.findIndex(crumb => breadCrumb.path === crumb.path)

            if (newBreadCrumb) {
                // replace
                breadCrumbs.splice(index, 1, newBreadCrumb)
            } else {
                // only remove
                breadCrumbs.splice(index, 1)
            }

            return { breadCrumbs }
        })
    }

    /**
     * Removes a given breadcrumb from the state
     */
    private removeBreadCrumb = (breadCrumb: BreadCrumb): void => {
        this.replaceWithBreadCrumb(breadCrumb)
    }
}
