import { get, isNumber } from 'lodash'
import moment from 'moment'
import { RouteComponentProps } from 'react-router'
import React, { Component, Fragment } from 'react'

import {
    ActionBar,
    Button,
    CheckBox,
    Currency,
    DatePicker,
    Emphasis,
    Field,
    FilterFieldCollection,
    Icon,
    InvoiceStatusIndicator,
    Link,
    MultiInput,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Header,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Label } from '~/components/Label/Label'
import { InvoiceLink, InvoiceRowExpansionContent } from '~/implementations'
import { SendInvoiceProvider } from '~/implementations/SendInvoiceProvider'
import { UploadInvoiceSignatureProvider } from '~/implementations/UploadInvoiceSignatureProvider'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter, Mutator, downloadFile, toast } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { translateType } from '~/shared/utils'
import { InvoiceType, InvoiceStatus, InvoiceFilterStatus, Invoice } from '~/types/Invoice'
import { UserRole, User } from '~/types/User'
import { Project } from '~/types/Project'
import { Group } from '~/types/Group'
import { Location } from '~/types/Location'
import { INVOICES_QUERY } from './graphql/invoicesQuery'
import { GET_FILTER_SUGGESTIONS_QUERY } from './graphql/filterSuggestionsQuery'
import { CREATE_INVOICES_EXPORT_FILE_MUTATION } from '~/views/App/Financial/LearnerInvoices/graphql/createInvoiceExportMutation'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'createdAt'
const DEFAULT_SORT_DIR = 'DESC'

interface Props extends RouteComponentProps<{}, {}> {}

interface LearnerInvoicesFiltersType {
    byTextSearch: string
    byCredit: boolean | null
    byFilterStatus: string
    byInvoiceDateFrom: Date
    byInvoiceDateTo: Date
    byInvoiceSentAtFrom: Date
    byInvoiceSentAtTo: Date
    byExpiredAtDateFrom: Date
    byExpiredAtDateTo: Date
    byPrivatePaymentType: string[]
    byInvoiceTypes: string[]
    byProjectIds: string[]
    byGroupIds: string[]
    byLocationIds: string[]
    filterByGroupEmployeeUserIds: string[]
    filterIsDebetInvoice: boolean
    filterIsCreditInvoice: boolean
    filterIsPayerDuo: boolean
    filterIsPayerSelf: boolean
}

type InvoiceFetcherDataAnyType = any
type InvoiceFetcherFetchOptionsAnyType = any

export default class LearnerInvoicesView extends Component<Props> {
    public sorter: Sorter
    public baseFilters = {
        byIsDescendant: false,
    }
    public filter: Filter<LearnerInvoicesFiltersType>
    public invoicesFetcher: Fetcher
    public paginator: Paginator
    public filterSuggestionsFetcher: Fetcher

    public state = {
        take: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private createExportMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { take, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter<LearnerInvoicesFiltersType>({
            useHistory: true,
            allowedKeys: [
                'byTextSearch',
                'byCredit',
                'byFilterStatus',
                'byInvoiceDateFrom',
                'byInvoiceDateTo',
                'byInvoiceSentAtFrom',
                'byInvoiceSentAtTo',
                'byExpiredAtDateFrom',
                'byExpiredAtDateTo',
                'byPrivatePaymentType',
                'byInvoiceTypes',
                'byProjectIds',
                'byGroupIds',
                'filterByGroupEmployeeUserIds',
                'byLocationIds',
            ],
            onChange: filters => {
                this.invoicesFetcher.refetch({
                    filters: {
                        ...this.baseFilters,
                        ...filters,
                    },
                } as InvoiceFetcherFetchOptionsAnyType)
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsDebetInvoice', 'filterIsCreditInvoice'],
                    transform: filters => {
                        const types = []
                        if (filters.filterIsDebetInvoice) {
                            types.push(false)
                        }

                        if (filters.filterIsCreditInvoice) {
                            types.push(true)
                        }

                        return {
                            byCredit: types.length === 1 ? types[0] : null,
                        }
                    },
                    historyTriggerKeys: ['byCredit'],
                    parseFromHistory: historyFilters => {
                        return {
                            filterIsDebetInvoice: historyFilters.byCredit === false,
                            filterIsCreditInvoice: historyFilters.byCredit === true,
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterIsPayerDuo', 'filterIsPayerSelf'],
                    transform: filters => {
                        const result = []

                        if (filters.filterIsPayerDuo) {
                            result.push('duo')
                        }

                        if (filters.filterIsPayerSelf) {
                            result.push('self')
                        }

                        return {
                            byPrivatePaymentType: result,
                        }
                    },
                    historyTriggerKeys: ['byPrivatePaymentType'],
                    parseFromHistory: historyFilters => {
                        const types = historyFilters.byPrivatePaymentType || []

                        return {
                            filterIsPayerDuo: types.includes('duo'),
                            filterIsPayerSelf: types.includes('self'),
                        }
                    },
                },
            ],
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.invoicesFetcher = new Fetcher<InvoiceFetcherDataAnyType>({
            query: INVOICES_QUERY,
            variables: {
                skip,
                take,
                sortDir,
                sortBy,
                filters: {
                    ...this.baseFilters,
                    ...this.filter.getFilters(),
                },
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            variables: {
                projectManagerUsersFilters: {
                    roles: [UserRole.ProjectManager],
                },
            },
            onChange: () => this.forceUpdate(),
        })

        this.createExportMutator = new Mutator({
            mutation: CREATE_INVOICES_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public loadMore = (skip: number, take: number, callback: (res?: { finished: boolean }) => void) => {
        this.invoicesFetcher.fetchMore({
            variables: { take, skip },
            getMergedData: (prevData: InvoiceFetcherDataAnyType, moreData: InvoiceFetcherDataAnyType) => {
                callback({
                    finished: moreData.paginatedInvoices.hasNextPage === false,
                })

                return {
                    paginatedInvoices: {
                        ...prevData.paginatedInvoices,
                        nodes: removeDuplicateDocuments([
                            ...(prevData.paginatedInvoices.nodes || []),
                            ...moreData.paginatedInvoices.nodes,
                        ]),
                    },
                }
            },
            onError: () => {
                callback()
            },
        } as InvoiceFetcherFetchOptionsAnyType)
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.invoicesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as InvoiceFetcherFetchOptionsAnyType)

        this.setState({ sortDir, sortBy })
    }

    public onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('byTextSearch', searchText)
    }

    public onFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.filter.applyFromInputEvent(event)
    }

    public onFilterTagPicker = (values: string[], tagPicker: TagPicker) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    public exportInvoices = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        this.createExportMutator
            .mutate({
                filters: {
                    ...this.baseFilters,
                    ...this.filter.getFilters(),
                    byIsDescendant: null,
                },
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'invoices_createExportFile')

                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'invoices-export.xlsx')
                        } else {
                            toast.error('Geen invoices gevonden om te exporteren')
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                    }
                }
            })
    }

    public render() {
        const { ...routeProps } = this.props
        const { loading, data } = this.invoicesFetcher
        const paginatedInvoices = data && data.paginatedInvoices
        const { nodes: invoices = [] } = paginatedInvoices || {}
        const { paginator, sorter } = this

        return (
            <RouteView crumbLabel={'Kandidaatfacturen'} routeProps={routeProps}>
                <InfiniteScroll
                    paginator={paginator}
                    preventLoad={loading}
                    component={View}
                    className={`tt-LearnerInvoicingView`}
                >
                    <Header>
                        <BreadCrumbs />
                    </Header>
                    <ContentView>
                        <Wrap full>{this.renderActionBar()}</Wrap>
                        <TableWrap>
                            <TableView>
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem sorter={sorter} sortBy={`invoiceNumber`} width="35%">
                                            Factuurnummer
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={sorter} sortBy={`invoiceDate`}>
                                            Factuurdatum
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={sorter} sortBy={`expirationDate`}>
                                            Dagen verlopen
                                        </TableHeaderItem>
                                        <TableHeaderItem>Kandidaat</TableHeaderItem>
                                        <TableHeaderItem containsNumber sorter={sorter} sortBy={`amount`}>
                                            Bedrag
                                        </TableHeaderItem>
                                        <TableHeaderItem />
                                        {/* status */}
                                        <TableHeaderItem />
                                        {/* expansion button */}
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={7} isLoading />
                                        </TableRow>
                                    ) : invoices.length > 0 ? (
                                        this.renderInvoiceRows(invoices)
                                    ) : (
                                        <TableRow key={`emptyresult`}>
                                            <TableCell colSpan={7}>
                                                <Subtle>Er zijn geen facturen gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </ContentView>
                </InfiniteScroll>
            </RouteView>
        )
    }

    public renderActionBar() {
        const { data: filterData } = this.filterSuggestionsFetcher
        const projects: Project[] = filterData.projects || []
        const projectManagerUsers: User[] = filterData.projectManagerUsers || []
        const groups: Group[] = filterData.groups || []
        const locations: Location[] = filterData.locations || []

        const hasFilters = this.filter.hasFilters({ excludeKeys: ['byTextSearch'] })
        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()
        const { data, loading } = this.invoicesFetcher
        const paginatedInvoices = data && data.paginatedInvoices
        const totalCount = paginatedInvoices && paginatedInvoices.totalCount

        const projectOptions = projects.map(project => ({
            value: project._id,
            label: project.name,
        }))

        const projectManagerOptions = projectManagerUsers.map(projectManagerUser => ({
            value: projectManagerUser._id,
            label: projectManagerUser.profile.name,
        }))

        const groupOptions = groups.map(group => ({
            value: group._id,
            label: group.name,
        }))

        const locationOptions = locations.map(location => ({
            value: location._id,
            label: location.name,
        }))

        const invoiceTypeOptions = Object.keys(InvoiceType).map(invoiceType => ({
            value: invoiceType,
            label: translateType('invoiceType', invoiceType),
        }))

        const { loading: loadingExport } = this.createExportMutator

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                amountOfResults={totalCount}
                getButtons={(toggleDrawer, drawerActive) => (
                    <List horizontal>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.byTextSearch} />
                        </ListItem>
                        <ListItem right>
                            <Button
                                leftIcon={<Icon name={`download`} />}
                                onClick={this.exportInvoices}
                                isLoading={loadingExport}
                                confirm={{
                                    title: 'Exporteren',
                                    // tslint:disable-next-line:max-line-length
                                    message: `Je staat op het punt om een ZIP export te maken van ${totalCount} facturen + eventueel termijnfacturen. Weet je het zeker?`,
                                    execute: {
                                        buttonType: 'submit',
                                        title: 'Export maken',
                                    },
                                }}
                            >
                                Export van huidige selectie
                            </Button>
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field title="Type" style="compact">
                            <MultiInput type="checkbox">
                                {/* tslint:disable-next-line:max-line-length */}
                                <CheckBox
                                    name="filterIsDebetInvoice"
                                    onChange={this.onFilter}
                                    defaultChecked={customFilters.filterIsDebetInvoice}
                                >
                                    Debetfactuur
                                </CheckBox>
                                {/* tslint:disable-next-line:max-line-length */}
                                <CheckBox
                                    name="filterIsCreditInvoice"
                                    onChange={this.onFilter}
                                    defaultChecked={customFilters.filterIsCreditInvoice}
                                >
                                    Creditnota
                                </CheckBox>
                            </MultiInput>
                        </Field>
                        <Field isLabel title={`Status`} style={`compact`}>
                            <TagPicker
                                onChange={this.onFilterTagPicker}
                                name="byFilterStatus"
                                defaultValue={filters.byFilterStatus}
                                multi={false}
                                options={[
                                    { value: InvoiceFilterStatus.StatusDraft, label: 'Concept' },
                                    {
                                        value: InvoiceFilterStatus.OpenStatusNeedsSignatureForDUO,
                                        label: 'Handtekening nodig',
                                    },
                                    {
                                        value: InvoiceFilterStatus.OpenStatusWaitingForDUOExportBatch,
                                        label: 'In afwachting van DUO batch',
                                    },
                                    {
                                        value: InvoiceFilterStatus.OpenStatusWaitingForPayment,
                                        label: 'In afwachting van betaling',
                                    },
                                    {
                                        value: InvoiceFilterStatus.OpenStatusWaitingForDUOApproval,
                                        label: 'In afwachting van DUO goedkeuring',
                                    },
                                    { value: InvoiceFilterStatus.OpenStatusRejectedByDUO, label: 'Afgewezen door DUO' },
                                    { value: InvoiceFilterStatus.PaymentStatusPaid, label: 'Betaald' },
                                    { value: InvoiceFilterStatus.DebitStatusCanceled, label: 'Geannuleerd' },
                                    { value: InvoiceFilterStatus.CreditStatusCanceled, label: 'Creditnota afgewezen' },
                                ]}
                                placeholder="Selecteer een status"
                            />
                        </Field>
                        <Field title={`Factuurdatum`} style={`compact`}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byInvoiceDateFrom'}
                                    defaultValue={filters.byInvoiceDateFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byInvoiceDateTo'}
                                    defaultValue={filters.byInvoiceDateTo}
                                />
                            </MultiInput>
                        </Field>
                        <Field title={`Vervaldatum`} style={`compact`}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byExpiredAtDateFrom'}
                                    defaultValue={filters.byExpiredAtDateFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byExpiredAtDateTo'}
                                    defaultValue={filters.byExpiredAtDateTo}
                                />
                            </MultiInput>
                        </Field>
                        <Field title="Betalerstype" style="compact">
                            <MultiInput type="checkbox">
                                <CheckBox
                                    name="filterIsPayerDuo"
                                    onChange={this.onFilter}
                                    defaultChecked={customFilters.filterIsPayerDuo}
                                >
                                    DUO
                                </CheckBox>
                                <CheckBox
                                    name="filterIsPayerSelf"
                                    onChange={this.onFilter}
                                    defaultChecked={customFilters.filterIsPayerSelf}
                                >
                                    Zelfbetaler
                                </CheckBox>
                            </MultiInput>
                        </Field>
                        <Field title={`Verstuurdatum`} style={`compact`}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byInvoiceSentAtFrom'}
                                    defaultValue={filters.byInvoiceSentAtFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'byInvoiceSentAtTo'}
                                    defaultValue={filters.byInvoiceSentAtTo}
                                />
                            </MultiInput>
                        </Field>
                        <Field isLabel title={`Type factuur`} style={`compact`}>
                            {invoiceTypeOptions && (
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`byInvoiceTypes`}
                                    defaultValue={filters.byInvoiceTypes}
                                    options={invoiceTypeOptions}
                                />
                            )}
                        </Field>
                        <Field isLabel title={`Project`} style={`compact`}>
                            {projectOptions && (
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`byProjectIds`}
                                    defaultValue={filters.byProjectIds}
                                    options={projectOptions}
                                />
                            )}
                        </Field>
                        <Field isLabel title={`Projectleider`} style={`compact`}>
                            {projectManagerOptions && (
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`filterByGroupEmployeeUserIds`}
                                    defaultValue={filters.filterByGroupEmployeeUserIds}
                                    options={projectManagerOptions}
                                />
                            )}
                        </Field>
                        <Field isLabel title={`Groepen`} style={`compact`}>
                            {groupOptions && (
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`byGroupIds`}
                                    defaultValue={filters.byGroupIds}
                                    options={groupOptions}
                                />
                            )}
                        </Field>
                        <Field isLabel title={`Locaties`} style={`compact`}>
                            {locationOptions && (
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`byLocationIds`}
                                    defaultValue={filters.byLocationIds}
                                    options={locationOptions}
                                />
                            )}
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    public renderInvoiceRows(invoices: Invoice[]) {
        return invoices.map((invoice, i) => {
            const combinedInvoices = [invoice]

            if (invoice.descendantInstallmentInvoices && invoice.descendantInstallmentInvoices.length > 0) {
                for (const descendantInstallmentInvoice of invoice.descendantInstallmentInvoices) {
                    combinedInvoices.push({
                        ...descendantInstallmentInvoice,

                        // Reuse from leading invoice (is same)
                        user: invoice.user,
                        group: invoice.group,
                    })
                }
            }

            return <Fragment key={i}>{combinedInvoices.map(this.renderInvoiceRow)}</Fragment>
        })
    }

    public renderInvoiceRow = (invoice: Invoice) => {
        const { refetch: refetchInvoices } = this.invoicesFetcher

        return (
            <TableRow key={invoice._id} getExpansion={() => this.renderInvoiceExpansionCells(invoice)}>
                <TableCell>
                    {invoice.isDescendantInstallment && <Icon name={`nesting`} />}
                    <InvoiceLink invoice={invoice} />
                    {invoice.isForDUO && <Label>DUO</Label>}
                    {invoice.isCredit && <Subtle>creditnota</Subtle>}
                    {invoice.hasMultipleInstallments && isNumber(invoice.installmentIndex) && (
                        <Subtle>Termijn {invoice.installmentIndex + 1}</Subtle>
                    )}
                </TableCell>
                <TableCell>
                    <ReadableDate date={invoice.invoiceDate} />
                </TableCell>
                <TableCell>
                    {invoice.status === InvoiceStatus.Final && this.renderInvoiceAmountOfDaysExpired(invoice)}
                </TableCell>
                <TableCell>
                    <Link route={`/users/learners/${get(invoice, 'user._id')}/`}>
                        {get(invoice, 'user.profile.name')}
                    </Link>
                </TableCell>
                <TableCell containsNumber>
                    <Currency amount={invoice.isCredit && invoice.amount ? -invoice.amount : invoice.amount} />
                </TableCell>
                <TableCell className={`tt-LearnerInvoicingView__status-cell`}>
                    <UploadInvoiceSignatureProvider
                        render={(uploadSignatureByInvoiceId, isUploadingSignature) => (
                            <SendInvoiceProvider
                                render={(sendInvoiceById, isSendingInvoice) => (
                                    <InvoiceStatusIndicator
                                        invoice={invoice}
                                        onRequestMakeFinal={async () => {
                                            await sendInvoiceById(invoice._id)
                                            refetchInvoices({ silent: true })
                                        }}
                                        isMakeFinalLoading={isSendingInvoice}
                                        onRequestUploadSignature={async () => {
                                            await uploadSignatureByInvoiceId(invoice._id)
                                            refetchInvoices({ silent: true })
                                        }}
                                        isUploadSignatureLoading={isUploadingSignature}
                                    />
                                )}
                            />
                        )}
                    />
                </TableCell>
            </TableRow>
        )
    }

    public renderInvoiceAmountOfDaysExpired(invoice: Invoice) {
        if (!invoice.isExpired) {
            return null
        }

        const amountOfDaysExpired = moment().diff(invoice.expirationDate, 'days')
        const expirationDateHasPassed = amountOfDaysExpired >= 1

        if (!expirationDateHasPassed) {
            return null
        }

        const displayText = amountOfDaysExpired === 1 ? '1 dag' : `${amountOfDaysExpired} dagen`

        return <Emphasis warning>{displayText}</Emphasis>
    }

    public renderInvoiceExpansionCells(invoice: Invoice) {
        const { refetch } = this.invoicesFetcher

        return (
            <TableCell colSpan={7}>
                <InvoiceRowExpansionContent
                    userId={invoice.user && invoice.user._id}
                    userName={invoice.user && invoice.user.profile.name}
                    invoiceId={invoice._id}
                    onChange={() => {
                        refetch({ silent: true })
                    }}
                />
            </TableCell>
        )
    }
}
