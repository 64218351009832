import { gql } from '@apollo/client'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Paragraph, Spinner } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'

import { Module } from '~/types/Module'
import { Fetcher } from '~/utils'

interface RouteParams {
    id: string
    inviteId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    module: Module
    children: any
}

interface State {}

export class AppPropertiesModulesInvitesDetail extends React.Component<Props, State> {
    private inviteFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inviteFetcher = new Fetcher({
            query: GROUP_INVITE_TEMPLATE_QUERY,
            variables: { _id: props.params.inviteId, moduleId: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { children, module } = this.props
        const { loading, refetch, data } = this.inviteFetcher
        const groupInviteTemplate = data && data.groupInviteTemplates && data.groupInviteTemplates[0]

        return (
            <>
                {groupInviteTemplate && React.cloneElement(children, { module, groupInviteTemplate, refetch })}
                {loading && (
                    <ContentView>
                        <Wrap>
                            <Spinner />
                        </Wrap>
                    </ContentView>
                )}
                {!loading && !groupInviteTemplate && (
                    <ContentView>
                        <Wrap>
                            <Paragraph>Uitnodiging niet gevonden</Paragraph>
                        </Wrap>
                    </ContentView>
                )}
            </>
        )
    }
}

const GROUP_INVITE_TEMPLATE_QUERY = gql`
    query ($moduleId: MongoID, $_id: MongoID) {
        groupInviteTemplates(moduleId: $moduleId, byId: $_id) {
            _id
            module {
                _id
                name
            }
            name
            updatedAt
            attachments {
                _id
                fileName
            }
            nl {
                content
            }
            en {
                content
            }
        }
    }
`
