import c from 'classnames'
import * as React from 'react'

interface Props {
    className?: string
}

export class Article extends React.Component<Props> {
    public render() {
        const { children, className: extraClassNames } = this.props
        const className = c('tt-Article', extraClassNames, {})

        return <article className={className}>{children}</article>
    }
}
