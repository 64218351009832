import { User } from '~/types/User'
import { DUOFinalExamResult } from './DUOFinalExamResult'
import { Invoice } from '~/types/Invoice'

export enum FinalExamPart {
    KNM = 'knm',
    ONA = 'ona',
    Talk = 'talk',
    Listen = 'listen',
    Read = 'read',
    Write = 'write',
}

export enum FinalExamLevel {
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
}

export enum FinalExamResult {
    Passed = 'passed',
    Failed = 'failed',
    NoShow = 'no_show',
    Exempt = 'exempt',
}

export enum FinalExamSubmitters {
    TopTaal = 'toptaal',
    External = 'external',
}

export enum FinalExamLogType {
    ExamCreated = 'examCreated',
    InvoiceCreated = 'invoiceCreated',
    InvoiceSent = 'invoiceSent',
    InvoiceCredited = 'invoiceCredited',
    DuoDeclarationCreated = 'duoDeclarationCreated',
    DuoDeclarationRevoked = 'duoDeclarationRevoked',
    DateChanged = 'dateChanged',
    ExamCancelled = 'examCancelled',
    DateCorrected = 'dateCorrected',
    ExamPartCorrected = 'examPartCorrected',
    SubmitterChangedToExternal = 'submitterChangedToExternal',
    SubmitterChangedToToptaal = 'submitterChangedToToptaal',
}

export interface DUODeclaration {
    _id: string
    amount?: number
    description?: string
    isExternal?: boolean
    hasBeenImported?: boolean
    date?: string
}

export interface FinalExamLogTypeData {
    previousDate?: string
    invoiceId?: string
    invoice?: Invoice
    duoDeclarationId?: string
    duoDeclaration?: DUODeclaration
    previousExamPart?: FinalExamPart
    duoFinalExamResult?: DUOFinalExamResult
}

export interface FinalExamLog {
    _id: string
    type: FinalExamLogType
    typeData: FinalExamLogTypeData
    createdAt: string
    createdByUserId: string
    createdByUser: User
}

export interface FinalExamAppointmentDocument {
    _id: string
    fileName: string
    isPDF: boolean
    createdAt: string
    createdByUser: User
}

export interface FinalExam {
    _id: string
    userId: string
    user: User
    isInvoiceActivatable?: boolean
    date?: Date
    part: FinalExamPart
    level?: FinalExamLevel
    attempt?: number
    result?: FinalExamResult
    submitter: FinalExamSubmitters
    invoiceId?: string
    invoice?: any
    createdAt?: string
    createdByUserId: string
    createdByUser: User
    logs?: FinalExamLog[]
    cost?: number
    canceledAt?: string
    appointmentFile?: FinalExamAppointmentDocument
    nextAttempt: FinalExam
}

interface ExplainFinalExamScore {
    budget?: number
    hours?: number
    module?: number
    months?: number
}

export interface FinalExamNeeded {
    budgetRemaining?: number
    hoursPresent?: number
    endOfIntegrationTerm?: string
    module?: any
    moduleId?: string
    moduleLessonsLeft?: number
    score?: number
    finalExams: FinalExam[]
    explainScore: ExplainFinalExamScore[]
}

export interface FinalExamImportError {
    _id: string
    createdAt: Date
    examDate: Date
    type: FinalExamImportErrorType
    examPart: FinalExamPart
    examLevel: FinalExamLevel
    examAttempt: number
    examResult: number
    typeData?: {
        firstName: string
        lastName: string
        bsn: string
    }
    DUOFinalExamResult?: DUOFinalExamResult
    user?: {
        _id: string
        profile: {
            name: string
        }
    }
}

export enum FinalExamImportErrorType {
    UserNotFound = 'UserNotFound',
    ExamNotFound = 'ExamNotFound',
    ExternalFinalExamAlreadyExists = 'ExternalFinalExamAlreadyExists',
}
