import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    value?: any
}

export default class Option extends React.Component<Props> {
    private bem = new BEM('Option')

    public render() {
        const { children, className, value } = this.props

        return (
            <option className={this.bem.getClassName(className)} value={value}>
                {children}
            </option>
        )
    }
}
