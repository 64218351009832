import c from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Currency, Highlight } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

export class DUOFundInfo extends Component {
    static propTypes = {
        className: PropTypes.string,
        initialFund: PropTypes.number,
        remainingFund: PropTypes.number,
    }

    render() {
        return (
            <Highlight className={this.getClassName()}>
                <List horizontal>
                    <ListItem>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Leenruimte bij aanmelding</SimpleTableCell>
                                <SimpleTableCell>
                                    <Currency amount={this.props.initialFund} />
                                </SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </ListItem>
                    <ListItem>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Resterende leenruimte</SimpleTableCell>
                                <SimpleTableCell>
                                    <Currency amount={this.props.remainingFund} />
                                </SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </ListItem>
                </List>
            </Highlight>
        )
    }

    getClassName() {
        const { className } = this.props

        return c('tt-DUOFundInfo', className)
    }
}
