import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Link } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import translateType from '~/shared/utils/translateType'
import { getCurrentUser } from '~/services/session'

export default class DataView extends Component {
    static propTypes = {
        program: PropTypes.object,
    }

    render() {
        const { program } = this.props
        const modules = program.modules || []
        const user = getCurrentUser()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Naam`}> {get(program, 'name')} </Field>
                            <Field title={`Lesmodule(s)`}>
                                {modules.map((module, i, arr) => {
                                    const suffix = i < arr.length - 1 ? ', ' : ''

                                    return [
                                        <Link key={module._id} route={`/properties/modules/${module._id}`}>
                                            {module.name}
                                        </Link>,
                                        suffix,
                                    ]
                                })}
                            </Field>
                            <Field title={'Type project'}>
                                {get(program, 'projectType')
                                    ? translateType('projectType', get(program, 'projectType'))
                                    : ''}
                            </Field>
                            {get(program, 'integrationType') ? (
                                <Field title={'Type inburgering'}>
                                    {translateType('contractType', get(program, 'integrationType'))}
                                </Field>
                            ) : (
                                ''
                            )}
                        </FieldGroup>
                        {user.isAdmin && (
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/programs/${program._id}/edit`)
                                            }
                                            type={`edit`}
                                        >
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        )}
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
