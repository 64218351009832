import c from 'classnames'
import * as React from 'react'

import { Input } from '~/components'
import { translateType } from '~/shared/utils'
import { ExamLevel } from '~/types/ExamLevel'
import { LearnerLevel } from '~/types/LearnerLevel'
import { isNumber } from 'lodash'
import { getPassedTypeForScore, AbilityExamPassedType } from '~/utils/abilityExams'

interface Props {
    className?: string
    name?: string
    onChange?: (value: React.SyntheticEvent<HTMLInputElement>) => void
    placeholder?: string
    isDisabled?: boolean
    isReadonly?: boolean
    defaultValue?: number
    value?: string | number
    level?: ExamLevel
    minScoreToPass?: number
    maxScore?: number
    showLevel?: boolean
}

interface State {
    currentExamScore: number | null
}

const negativeLearnerLevelByExamLevel = {
    [ExamLevel.A1]: LearnerLevel.A1Minus,
    [ExamLevel.A2]: LearnerLevel.A2Minus,
    [ExamLevel.B1]: LearnerLevel.B1Minus,
    [ExamLevel.B2]: LearnerLevel.B2Minus,
    [ExamLevel.C1]: LearnerLevel.C1Minus,
    [ExamLevel._1F]: LearnerLevel._1FMinus,
    [ExamLevel._2F]: LearnerLevel._2FMinus,
    [ExamLevel._3F]: LearnerLevel._3FMinus,
    [ExamLevel._4F]: LearnerLevel._4FMinus,
}

export class ScoreInput extends React.Component<Props, State> {
    public state: State = {
        currentExamScore: typeof this.props.defaultValue === 'number' ? +this.props.defaultValue : null,
    }

    public render() {
        const { name, placeholder, value, defaultValue, isDisabled, isReadonly, maxScore, showLevel } = this.props
        const resultPreview = this.getResultPreview()

        return (
            <div className={this.getClassName()}>
                <Input
                    type={`number`}
                    step={0.1}
                    name={name}
                    onChange={this.onChangeScoreInput}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    isDisabled={isDisabled}
                    isReadonly={isReadonly}
                    max={maxScore}
                />

                {showLevel && <span className={`tt-ScoreInput__level`}>{resultPreview}</span>}
            </div>
        )
    }

    private getResultPreview = () => {
        const { level, minScoreToPass } = this.props
        const { currentExamScore } = this.state

        if (!isNumber(minScoreToPass) || !isNumber(currentExamScore) || !level) {
            return ''
        }

        const passedType = getPassedTypeForScore(minScoreToPass, currentExamScore)

        switch (passedType) {
            case AbilityExamPassedType.NotPassed:
                return 'Onv.'
            case AbilityExamPassedType.NearlyPassed:
                return this.getLevelTranslation(negativeLearnerLevelByExamLevel[level])
            case AbilityExamPassedType.Passed:
                return this.getLevelTranslation(level)
            default:
                return ''
        }
    }

    private getLevelTranslation = (level: ExamLevel | LearnerLevel) => {
        return translateType('level', level)
    }

    private onChangeScoreInput = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { onChange } = this.props
        const { currentTarget } = event

        this.setState({
            currentExamScore: currentTarget.value ? +currentTarget.value : null,
        })

        if (onChange) {
            onChange(event)
        }
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-ScoreInput', className)
    }
}
