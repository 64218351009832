import { gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'
import { Button, Field, FieldCollection, TextArea } from '~/components'
import { ButtonGroup } from '~/components/ButtonGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import Form, { FormFields } from '~/components/Form'
import { InviteTextViewer } from '~/components/InviteText/InviteTextViewer/InviteTextViewer'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { InflowInviteTemplate, inflowInviteTemplateTextVariables } from '~/types/InflowInviteTemplate'
import { InflowMoment } from '~/types/InflowMoments'
import { downloadFile, Fetcher } from '~/utils'

interface Props {
    onNext: (inflowInviteTemplate: InflowInviteTemplate, freeText?: string, freeTextEnglish?: string) => void
    onCancel: () => void
    inflowMoment: InflowMoment
    defaultInflowInviteTemplate?: InflowInviteTemplate
    defaultFreeText?: string
    defaultFreeTextEnglish?: string
}

interface State {
    inflowInviteTemplate?: InflowInviteTemplate
    freeText?: string
    freeTextEnglish?: string
    englishActive: boolean
}

export class InflowMomentSelectInviteForm extends React.Component<Props, State> {
    public state: State = {
        inflowInviteTemplate: this.props.defaultInflowInviteTemplate,
        englishActive: false,
    }

    private inflowInviteTemplatesFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inflowInviteTemplatesFetcher = new Fetcher({
            query: GET_INFLOW_INVITE_TEMPLATES,
            variables: {
                inflowModuleId: this.props.inflowMoment.inflowModule._id,
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { inflowInviteTemplate: selectedInflowInviteTemplate } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={'modal'}>
                    <FieldGroup>
                        <Field title={'Uitnodigingsbrief'}>
                            <TagPicker
                                placeholder={'Selecteer uitnodigingsbrief'}
                                multi={false}
                                name={'inflowInviteTemplate'}
                                onChange={this.onInflowInviteTemplateSelect}
                                options={this.getTagPickerOptions()}
                                value={selectedInflowInviteTemplate && selectedInflowInviteTemplate._id}
                            />
                        </Field>
                        {selectedInflowInviteTemplate && (
                            <Field title={'Bijlagen'}>
                                <List>
                                    {selectedInflowInviteTemplate.attachments &&
                                        selectedInflowInviteTemplate.attachments.map(file => {
                                            const { _id, fileName } = file

                                            return (
                                                <ListItem key={`file-${_id}`}>
                                                    <Button
                                                        linkStyle={`default`}
                                                        onClick={() => downloadFile(_id, fileName)}
                                                    >
                                                        {fileName}
                                                    </Button>
                                                </ListItem>
                                            )
                                        })}
                                </List>
                            </Field>
                        )}
                    </FieldGroup>
                    {this.renderFreeText()}
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right={true}>
                                <Button type="submit" isDisabled={!selectedInflowInviteTemplate}>
                                    Volgende
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private getTagPickerOptions = () => {
        const { data } = this.inflowInviteTemplatesFetcher
        const inflowInviteTemplates = get(data, 'inflowInviteTemplates', [])

        return inflowInviteTemplates.map((inflowInviteTemplate: InflowInviteTemplate) => ({
            value: inflowInviteTemplate._id,
            label: inflowInviteTemplate.name,
        }))
    }

    private renderFreeText() {
        const { inflowInviteTemplate, englishActive } = this.state
        const { defaultFreeText, defaultFreeTextEnglish } = this.props

        if (!inflowInviteTemplate) {
            return null
        }

        return (
            <FieldGroup isLastFieldInModal={true}>
                <Field title={'Brief'}>
                    <ButtonGroup joined={true}>
                        <Button
                            type="button"
                            styleOverride={englishActive ? 'looks-disabled' : undefined}
                            onClick={this.onLanguageButtonClick}
                        >
                            Nederlands
                        </Button>
                        <Button
                            type="button"
                            styleOverride={!englishActive ? 'looks-disabled' : undefined}
                            onClick={this.onLanguageButtonClick}
                        >
                            Engels
                        </Button>
                    </ButtonGroup>
                </Field>
                <Field title={'Vrije text'}>
                    <div>
                        <TextArea
                            style={englishActive ? { display: 'none' } : undefined}
                            name={'freeText.nl'}
                            defaultValue={defaultFreeText}
                        />
                        <TextArea
                            style={!englishActive ? { display: 'none' } : undefined}
                            name={'freeText.en'}
                            defaultValue={defaultFreeTextEnglish}
                        />
                    </div>
                </Field>
                <InviteTextViewer
                    hideSwitch={true}
                    showBorder={true}
                    text={this.getPreviewText()}
                    textVariables={inflowInviteTemplateTextVariables}
                />
            </FieldGroup>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onNext } = this.props
        const { inflowInviteTemplate } = this.state

        if (!inflowInviteTemplate) {
            return
        }

        onNext(inflowInviteTemplate, fields.freeText.nl, fields.freeText.en)
    }

    private onInflowInviteTemplateSelect = (selectedTemplateOption: TagPickerOption) => {
        if (!selectedTemplateOption) {
            this.setState({
                inflowInviteTemplate: undefined,
            })
            return
        }

        const { data } = this.inflowInviteTemplatesFetcher
        const inflowInviteTemplates = get(data, 'inflowInviteTemplates', [])

        const selectedTemplate = inflowInviteTemplates.find(
            (inviteTemplate: InflowInviteTemplate) => inviteTemplate._id === selectedTemplateOption.value
        )

        this.setState({
            inflowInviteTemplate: selectedTemplate,
        })
    }

    private onLanguageButtonClick = () => {
        this.setState({
            englishActive: !this.state.englishActive,
        })
    }

    private getPreviewText = () => {
        const { englishActive, inflowInviteTemplate } = this.state

        if (!inflowInviteTemplate) {
            return ''
        }

        if (englishActive) {
            return (inflowInviteTemplate.en && inflowInviteTemplate.en.content) || ''
        }

        return (inflowInviteTemplate.nl && inflowInviteTemplate.nl.content) || ''
    }
}

const GET_INFLOW_INVITE_TEMPLATES = gql`
    query ($inflowModuleId: MongoID) {
        inflowInviteTemplates(inflowModuleId: $inflowModuleId) {
            _id
            name
            attachments {
                _id
                fileName
            }
            nl {
                content
            }
            en {
                content
            }
        }
    }
`
