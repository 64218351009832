import { gql } from '@apollo/client'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Icon,
    PdfModal,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { Wrap } from '~/components/Wrap'
import { CreateUserDocumentForm } from '~/forms'
import { getCurrentUser } from '~/services/session'
import { downloadFile, Fetcher, Mutator } from '~/utils'

export class AppUsersCommonDetailDocuments extends Component {
    static propTypes = {
        userId: PropTypes.string,
        isViewedByTeacherOfUser: PropTypes.bool,
        isViewedByIntakerOfUser: PropTypes.bool,
    }

    static defaultProps = {
        isViewedByTeacherOfUser: false,
        isViewedByIntakerOfUser: false,
    }

    constructor(props) {
        super(props)
        const currentUser = getCurrentUser()
        const organizationId =
            currentUser.organizationContact &&
            currentUser.organizationContact.organization &&
            currentUser.organizationContact.organization._id

        this.documentsFetcher = new Fetcher({
            query: GET_USER_DOCUMENTS_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
        })

        if (currentUser && currentUser.isOrganizationContact && organizationId) {
            this.currentOrganizationFetcher = new Fetcher({
                query: GET_CURRENT_ORGANIZATION_QUERY,
                variables: {
                    byId: organizationId,
                },
                onChange: () => this.forceUpdate(),
            })
        }

        this.removeDocumentMutator = new Mutator({
            mutation: REMOVE_DOCUMENTS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onDocumentAdd = () => {
        this.documentsFetcher.refetch()
    }

    onRemoveDocument = doc => {
        this.removeDocumentMutator
            .mutate({
                userId: this.props.userId,
                documentIds: [doc._id],
            })
            .then(() => {
                this.documentsFetcher.refetch()
            })
    }

    render() {
        return (
            <ContentView>
                <Wrap full>{this.renderUploadManager()}</Wrap>
                <TableWrap>{this.renderDocumentsTable()}</TableWrap>
            </ContentView>
        )
    }

    renderDocumentsTable() {
        const { data, loading } = this.documentsFetcher
        const documents = get(data, 'users[0].documents') || []

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem width="35%">Naam</TableHeaderItem>
                        <TableHeaderItem>Type</TableHeaderItem>
                        <TableHeaderItem>Geüpload op</TableHeaderItem>
                        <TableHeaderItem>Door</TableHeaderItem>
                        <TableHeaderItem />
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={5} isLoading />
                        </TableRow>
                    ) : documents.length > 0 ? (
                        this.renderDocumentRows(documents)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={5}>
                                <Subtle>Er zijn geen documenten gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    renderUploadManager() {
        const { userId, isViewedByTeacherOfUser } = this.props

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal>
                        <ListItem left>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} leftIcon={<Icon name={`upload`} />}>
                                        Document uploaden
                                    </Button>
                                )}
                                getModal={closeModal => {
                                    return (
                                        <CenterModal onClose={closeModal} title={`Document uploaden`}>
                                            <CreateUserDocumentForm
                                                userId={userId}
                                                hideTypesForTeacherOfUser={isViewedByTeacherOfUser}
                                                onSubmitSuccess={() => {
                                                    closeModal()
                                                    this.onDocumentAdd()
                                                }}
                                                onCancel={closeModal}
                                            />
                                        </CenterModal>
                                    )
                                }}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    renderDocumentRows(documents) {
        return documents.map(doc => {
            return (
                <TableRow key={doc._id}>
                    <TableCell>
                        {doc.isPDF ? (
                            <ModalManager
                                render={openModal => (
                                    <Button linkStyle={`default`} onClick={openModal}>
                                        {doc.fileName}
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <PdfModal
                                        title={doc.fileName}
                                        fileName={doc.fileName}
                                        getFileId={() => doc._id}
                                        onClose={closeModal}
                                    />
                                )}
                            />
                        ) : (
                            <Button linkStyle={`default`} onClick={() => downloadFile(doc._id, doc.fileName)}>
                                {doc.fileName}
                            </Button>
                        )}
                    </TableCell>
                    <TableCell>{doc.type}</TableCell>
                    <TableCell>
                        <ReadableDate date={new Date(doc.createdAt)} />
                    </TableCell>
                    <TableCell>{get(doc.createdByUser, 'profile.name')}</TableCell>
                    <TableCell>
                        {this.documentMayBeRemovedByUser(doc) && (
                            <Button
                                className={`tt-TableRow__show-on-row-hover`}
                                type={`in-row`}
                                onClick={() => this.onRemoveDocument(doc)}
                                style={{ float: 'right' }}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                                leftIcon={<Icon name={`trash`} />}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    documentMayBeRemovedByUser(doc) {
        const { isViewedByIntakerOfUser } = this.props
        const currentUser = getCurrentUser()

        if (currentUser) {
            // Current user is employee
            if (currentUser && currentUser.isEmployee) {
                return true
            }

            if (doc.createdByUser) {
                // Current user is uploader
                if (doc.createdByUser._id === currentUser._id) {
                    return true
                }

                // Current user is intaker of user
                if (isViewedByIntakerOfUser) {
                    return true
                }

                // Current user has same organization as uploader
                if (currentUser.isOrganizationContact) {
                    if (doc.createdByUser && this.isDocumentCreatedByOrganizationContactCollegue(doc.createdByUser)) {
                        return true
                    }
                }
            }
        }

        return false
    }

    isDocumentCreatedByOrganizationContactCollegue(createdByUser) {
        const { data } = this.currentOrganizationFetcher

        const collegues =
            (data && data.organizations && data.organizations[0] && data.organizations[0].contactUsers) || []

        return !!collegues.find(collegue => {
            return collegue._id === createdByUser._id
        })
    }
}

const GET_USER_DOCUMENTS_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            documents(sortBy: "createdAt", sortDir: "DESC") {
                _id
                fileName
                isPDF
                type
                createdAt
                createdByUser {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const GET_CURRENT_ORGANIZATION_QUERY = gql`
    query _($byId: MongoID) {
        organizations(byId: $byId) {
            _id
            contactUsers {
                _id
                profile {
                    name
                }
            }
        }
    }
`

const REMOVE_DOCUMENTS_MUTATION = gql`
    mutation _($userId: MongoID!, $documentIds: [MongoID]) {
        users_removeDocuments(userId: $userId, documentIds: $documentIds) {
            _id
        }
    }
`
