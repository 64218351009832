import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { UserTimeline } from '~/implementations'

export default class TimelineView extends Component {
    static propTypes = {
        userId: PropTypes.string,
    }

    render() {
        const { userId } = this.props

        return (
            <ContentView hasFixedActionBar>
                <UserTimeline userId={userId} timelineEventOwnerType={`LEARNER_USER`} />
            </ContentView>
        )
    }
}
