import React from 'react'
import { Contract } from '~/types/Contracts'
import { FlagIcon } from '~/components/Contracts/ContractStatus/icons'
import { ContractStatus } from '~/components/Contracts/ContractStatus/ContractStatus'

interface Props {
    contracts: Contract[]
}

export class GroupParticipantContractStatuses extends React.Component<Props> {
    public render() {
        const { contracts } = this.props
        const statuses = []

        for (const contract of contracts) {
            const userId = contract.user && contract.user._id

            if (!userId) {
                continue
            }

            if (!contract.isFinal) {
                statuses.push(
                    <ContractStatus
                        contract={contract}
                        ensureShowIconOnly={true}
                        iconLink={`/users/learners/${userId}/process/contracts`}
                        key={contract._id}
                    />
                )
            } else if (!contract.isSigned) {
                statuses.push(
                    <ContractStatus
                        contract={contract}
                        ensureShowIconOnly={true}
                        iconLink={`/users/learners/${userId}/process/contracts`}
                        key={contract._id}
                    />
                )
            }
        }

        if (statuses.length === 0) {
            return null
        }

        return (
            <React.Fragment>
                <FlagIcon />
                <div className={`tt-TableCell--inline-statuses__divider`} />
                {statuses}
            </React.Fragment>
        )
    }
}
