import { getFileUrl, toast } from '~/utils'

export default async function downloadFile(fileId: string, fileName: string) {
    const url = await getFileUrl(fileId, fileName, { asAttachment: true })

    if (url) {
        window.open(url, '_blank')
    } else {
        toast.error('Kon bestand niet vinden')
    }
}
