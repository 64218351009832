import React from 'react'
import { CenterModal } from '~/components'
import { CenterModalProps } from './CenterModal'
import { BEM } from '~/services/BEMService'

interface Props extends CenterModalProps {}

export class PageModal extends React.Component<Props> {
    private bem = new BEM('PageModal')

    public render() {
        const { children, className, ...restProps } = this.props

        return (
            <CenterModal className={this.bem.getClassName(className)} {...restProps}>
                {children}
            </CenterModal>
        )
    }
}
