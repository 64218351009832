import './StatisticTableRow.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import TableRow from '~/components/TableRow'

interface Props {
    className?: ClassValue
}

export class StatisticTableRow extends React.Component<Props> {
    private bem = new BEM('StatisticTableRow')

    public render() {
        const { className, children } = this.props

        return <TableRow className={this.bem.getClassName(className)}>{children}</TableRow>
    }
}
