import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export class FieldText extends React.Component<Props> {
    private bem = new BEM('FieldText')

    public render() {
        const { children } = this.props

        return <div className={this.bem.getClassName()}>{children}</div>
    }
}
