import { gql } from '@apollo/client'
import * as React from 'react'

import { Button, Field, FieldCollection, Form } from '~/components'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { Note } from '~/types/Note'
import { FieldGroup } from '~/components/FieldGroup'
import { NoteTextArea } from '~/components/notes/NoteTextArea'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    onSubmitSuccess: () => void
    onCancel?: () => void
    removeNote: () => void
    note: Note
}

const EDIT_NOTE_MUTATION = gql`
    mutation _($note: NoteInputType!) {
        notes_edit(note: $note) {
            _id
        }
    }
`

export class EditNoteForm extends React.Component<Props, {}> {
    private editNoteMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.editNoteMutator = new Mutator({
            mutation: EDIT_NOTE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.editNoteMutator
        const { onCancel, removeNote } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style="modal">
                    <FieldGroup isForm>
                        <Field errors={errors}>
                            <NoteTextArea name="note.text" defaultValue={this.props.note.text} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem>
                                <Button onClick={removeNote} linkStyle="danger">
                                    Verwijderen
                                </Button>
                            </ListItem>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button isLoading={loading} type="submit">
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { onSubmitSuccess } = this.props

        const res = await this.editNoteMutator.mutate({
            note: {
                _id: this.props.note._id,
                ...transformFormFields(fields.note),
            },
        })

        if (res && res.notes_edit) {
            onSubmitSuccess()
        }
    }
}
