import React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Project } from '~/types/Project'

interface Props extends RouteComponentProps<{}, {}> {
    refetch: () => void
    project: Project
}

export default class AppPropertiesProjectsDetailInvoicingSettings extends React.Component<Props> {
    public render() {
        const { children, project, refetch, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Facturatie-instellingen'} routeProps={routeProps} isTab={true}>
                {React.cloneElement(React.Children.only(children as any), {
                    project,
                    refetchProject: refetch,
                })}
            </RouteView>
        )
    }
}
