import c from 'classnames'
import { gql } from '@apollo/client'
import { bindAll } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { Icon, PageView, UserMenu } from '~/components'
import { MainNav } from '~/components/MainNav'
import { MainNavItem } from '~/components/MainNavItem'
import { MainNavLink } from '~/components/MainNavLink'
import { MobileNav } from '~/components/MobileNav'
import { MobileNavLink } from '~/components/MobileNavLink'
import { View } from '~/components/View'
import { OrganizationContactAgreement } from '~/implementations'
import env from '~/services/env'
import { Translator } from '~/services/i18n'
import { getCurrentUser } from '~/services/session'
import { Fetcher, toast } from '~/utils'
import ExpandableMenu from '~/components/ExpandableMenu'
import { ErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'

const MOBILENAV_TRANSISTION_DURATION = 250

export default class AppView extends Component {
    static propTypes = {
        children: PropTypes.any,
        location: PropTypes.object,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['hideMobileNav', 'showMobileNav'])
        this.state = {
            mobileNavIsOpen: false,
            mobileNavIsHidden: true,
            user: getCurrentUser(),
        }

        this.translator = new Translator({
            namespace: 'App',
            subscribe: this,
        })

        this.infoFetcher = new Fetcher({
            query: INFO_QUERY,
            preventInitialFetch: true,
            swallowAuthorizationError: true,
        })
    }

    componentWillMount() {
        ;(async () => {
            const result = await this.infoFetcher.fetch()

            if (result && result.info && !result.info.isTwinfieldTokenSet) {
                toast.info(`Er moet worden ingelogd in Twinfield. Klik hier om de koppeling te maken.`, {
                    timeout: 10000,
                    onClick: () => {
                        window.location.href = `${env.serverBase}/api/twinfield/authorize`
                    },
                })
            }
        })()
    }

    componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    hideMobileNav() {
        this.setState({ mobileNavIsOpen: false })

        setTimeout(() => {
            this.setState({ mobileNavIsHidden: true })
        }, MOBILENAV_TRANSISTION_DURATION)
    }

    showMobileNav() {
        this.setState({
            mobileNavIsHidden: false,
        })

        setTimeout(() => {
            this.setState({ mobileNavIsOpen: true })
        }, 10)
    }

    render() {
        const {
            children,
            location: { pathname },
        } = this.props
        const { mobileNavIsHidden, mobileNavIsOpen } = this.state
        const { t } = this.translator
        const user = this.state.user
        const mobileNavClass = c({
            'tt-MobileNav--hidden': mobileNavIsHidden,
            'tt-MobileNav--is-open': mobileNavIsOpen,
        })

        const isProcessTabActive =
            pathname.startsWith('/inflow-moments') ||
            pathname.startsWith('/groups') ||
            pathname.startsWith('/final-exams')

        return (
            <View className="tt-AppView">
                <MainNav>
                    <MainNavLink className={'tt-MainNavLink--mobile-menu-button'} onClick={this.showMobileNav}>
                        <Icon name={`menu`} />
                    </MainNavLink>
                    {(user.isEmployee || user.isExternalTeacher || user.isOrganizationContact || user.isIntaker) && (
                        <MainNavLink route={`/inflow-moments`} isLinkActive={isProcessTabActive}>
                            {t('navigation.buttons.process')}
                        </MainNavLink>
                    )}
                    {user.isEmployee && <MainNavLink route={`/users`}>{t('navigation.buttons.users')}</MainNavLink>}
                    {user.isOrganizationContact && (
                        <MainNavLink route={`/learners`}>{t('navigation.buttons.learners')}</MainNavLink>
                    )}
                    {user.isEmployee && (
                        <MainNavLink route={`/financial`}>{t('navigation.buttons.financial')}</MainNavLink>
                    )}

                    {user.isOrganizationContact && (
                        <MainNavLink route={`/colleagues`}>{t('navigation.buttons.colleagues')}</MainNavLink>
                    )}

                    {user.isExternalTeacher && (
                        <MainNavLink route={`/availability`}>{t('navigation.buttons.availability')}</MainNavLink>
                    )}

                    {user.isEmployee && (
                        <ExpandableMenu
                            renderContent={toggle => (
                                <Fragment>
                                    <MainNavLink route={`/properties`} onClick={toggle}>
                                        {t('navigation.buttons.properties')}
                                    </MainNavLink>
                                    <MainNavLink route={`/exports`} onClick={toggle}>
                                        {t('navigation.buttons.exports')}
                                    </MainNavLink>
                                    <MainNavLink route={`/statistics`} onClick={toggle}>
                                        {t('navigation.buttons.statistics')}
                                    </MainNavLink>
                                </Fragment>
                            )}
                        />
                    )}
                    <MainNavItem right>
                        <UserMenu user={user} />
                    </MainNavItem>
                </MainNav>
                <MobileNav user={user} className={mobileNavClass} onClose={this.hideMobileNav}>
                    {(user.isEmployee || user.isExternalTeacher || user.isOrganizationContact || user.isIntaker) && (
                        <MobileNavLink
                            route={`/inflow-moments`}
                            onClose={this.hideMobileNav}
                            isLinkActive={isProcessTabActive}
                        >
                            {t('navigation.buttons.process')}
                        </MobileNavLink>
                    )}
                    {user.isEmployee && (
                        <MobileNavLink route={`/users`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.users')}
                        </MobileNavLink>
                    )}
                    {user.isEmployee && (
                        <MobileNavLink route={`/financial`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.financial')}
                        </MobileNavLink>
                    )}
                    {user.isEmployee && (
                        <MobileNavLink route={`/properties`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.properties')}
                        </MobileNavLink>
                    )}
                    {user.isExternalTeacher && (
                        <MobileNavLink route={`/availability`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.availability')}
                        </MobileNavLink>
                    )}
                    {user.isEmployee && (
                        <MobileNavLink route={`/exports`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.exports')}
                        </MobileNavLink>
                    )}
                    {user.isEmployee && (
                        <MobileNavLink route={`/statistics`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.statistics')}
                        </MobileNavLink>
                    )}
                    {user.isOrganizationContact && (
                        <MobileNavLink route={`/learners`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.learners')}
                        </MobileNavLink>
                    )}
                    {user.isOrganizationContact && (
                        <MobileNavLink route={`/colleagues`} onClose={this.hideMobileNav}>
                            {t('navigation.buttons.colleagues')}
                        </MobileNavLink>
                    )}
                </MobileNav>
                <PageView>
                    <ErrorBoundary routeProps={this.props}>{children}</ErrorBoundary>
                </PageView>
                <OrganizationContactAgreement />
            </View>
        )
    }
}

const INFO_QUERY = gql`
    query _ {
        info {
            isTwinfieldTokenSet
        }
    }
`
