import * as React from 'react'
import MultiInput from '~/components/MultiInput'
import CheckBox from '~/components/CheckBox'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { translateType } from '~/shared/utils'
import { ExamType, ExamAbility, Exam } from '~/types/Exam'
import { ModuleExam } from '~/types/Module'
import Field, { Props as FieldProps } from '~/components/Field'
import { FieldGroup } from '~/components/FieldGroup'

interface Props extends FieldProps {
    name?: string
    exams: Exam[]
    defaultModuleExam?: ModuleExam | null
    onChange?: (moduleExam: ModuleExam | null) => void
}

interface State {
    selectedModuleExam: ModuleExam | null
}

export class ModuleExamField extends React.Component<Props, State> {
    public state: State = {
        selectedModuleExam: this.props.defaultModuleExam || null,
    }

    public render() {
        const { exams, name, defaultModuleExam, onChange, ...restFieldProps } = this.props
        const { selectedModuleExam } = this.state

        const examsOptions = exams.map(exam => ({
            value: exam._id,
            label: exam.name,
        }))

        return (
            <>
                <Field {...restFieldProps}>
                    <TagPicker
                        name={`${name}.examId`}
                        options={examsOptions}
                        multi={false}
                        onChange={this.onExamInput}
                        placeholder="Selecteer toetsen"
                        value={selectedModuleExam ? selectedModuleExam.examId : null}
                    />
                </Field>
                {selectedModuleExam && (
                    <FieldGroup isInsetGroup={true}>
                        <Field title={'Vaardigheden'} errors={restFieldProps.errors}>
                            <span data-errorkey={`${name}.abilities`} />
                            {this.renderSkillsForModuleExam(selectedModuleExam)}
                        </Field>
                    </FieldGroup>
                )}
            </>
        )
    }

    private renderSkillsForModuleExam = (moduleExam: ModuleExam): React.ReactNode => {
        const exam = this.getExamByExamId(moduleExam.examId)

        if (!exam) {
            return
        }

        return (
            <MultiInput type="checkbox" isVertical={true}>
                {exam.type === ExamType.Language && this.renderAbilityCheckbox(ExamAbility.Converse)}
                {exam.type === ExamType.Language && this.renderAbilityCheckbox(ExamAbility.Talk)}
                {this.renderAbilityCheckbox(ExamAbility.Write)}
                {exam.type === ExamType.Language && this.renderAbilityCheckbox(ExamAbility.Listen)}
                {this.renderAbilityCheckbox(ExamAbility.Read)}
            </MultiInput>
        )
    }

    private renderAbilityCheckbox(ability: ExamAbility): React.ReactNode {
        const { name } = this.props
        const { selectedModuleExam } = this.state
        const checked = (selectedModuleExam && selectedModuleExam.abilities[ability]) || false

        return (
            <CheckBox
                name={`${name}.abilities.${ability}`}
                onClick={(event: React.SyntheticEvent<HTMLInputElement>) => this.onAbilityInput(ability, event)}
                checked={checked}
            >
                {translateType('examAbility', ability)}
            </CheckBox>
        )
    }

    /**
     * Exam change handler
     */
    private onExamInput = (option: TagPickerOption | null) => {
        const { exams } = this.props
        const { selectedModuleExam } = this.state

        // User clears the exam field
        if (!option) {
            this.onChangeSelection(null)
            return
        }

        // User selects the same exam, so do nothing.
        if (selectedModuleExam && selectedModuleExam.examId === option.value) {
            return
        }

        const newModuleExam = {
            abilities: {},
            examId: option.value,
            exam: exams.find(exam => exam._id === option.value),
        }

        // User selects a different exam
        this.onChangeSelection(newModuleExam)
    }

    /**
     * Ability change handler
     */
    private onAbilityInput = (ability: ExamAbility, event: React.SyntheticEvent<HTMLInputElement>) => {
        const { selectedModuleExam } = this.state
        const {
            currentTarget: { checked },
        } = event

        const updatedModuleExam = selectedModuleExam
            ? {
                  ...selectedModuleExam,
                  abilities: {
                      ...selectedModuleExam.abilities,
                      [ability]: checked,
                  },
              }
            : null

        this.onChangeSelection(updatedModuleExam)
    }

    /**
     * Get the exam type by module id
     *
     * @param examId
     */
    private getExamByExamId = (examId?: string): Exam | undefined => {
        const { exams } = this.props

        return exams.find(exam => {
            return exam._id === examId
        })
    }

    /**
     * Handle any input changes made by the user
     * (moduleExam and its abilities)
     */
    private onChangeSelection = (moduleExam: ModuleExam | null) => {
        const { onChange } = this.props

        if (onChange) {
            onChange(moduleExam)
        }

        this.setState({
            selectedModuleExam: moduleExam,
        })
    }
}
