import { gql } from '@apollo/client'
import { bindAll, get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, DatePicker, Field, FieldCollection, Form, MultiInput } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'

export default class EditGroupDateBlockerForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
        onFormCancel: PropTypes.func,
        type: PropTypes.string,
    }

    constructor(props) {
        super(props)

        this.editGroupMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })

        bindAll(this, ['onSubmit', 'onCancel'])
    }

    onSubmit = async (event, fields) => {
        const { onSubmitSuccess, group } = this.props

        if (!group) {
            return
        }

        const data = await this.editGroupMutator.mutate({
            group: {
                _id: group._id,
                ...transformFormFields(fields.group, {
                    dateBlockers: {
                        value: v =>
                            v.map(_v =>
                                transformFormFields(_v, {
                                    from: transformLib.date(),
                                    to: {
                                        value: v => v && moment(new Date(v)).add(1, 'day').toDate(),
                                    },
                                })
                            ),
                    },
                }),
            },
        })

        if (data) {
            onSubmitSuccess()
        }
    }

    onCancel() {
        if (this.props.onFormCancel) {
            this.props.onFormCancel()
        }
    }

    render() {
        const { loading: isMutationLoading, errors } = this.editGroupMutator
        const { group } = this.props
        const dateBlockers = group.dateBlockers || []

        return (
            <Form type={this.props.type || null} onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title="Periode(s)" errors={errors}>
                            <VariableMultiInput
                                defaultAmount={dateBlockers.length}
                                getNewInput={i => {
                                    const from = get(dateBlockers[i], 'from')
                                    const realTo = get(dateBlockers[i], 'to')
                                    const to = realTo && moment(realTo).subtract(1, 'day').toDate()

                                    return (
                                        <MultiInput type="select-range" key={i}>
                                            <DatePicker name={`group.dateBlockers[${i}].from`} defaultValue={from} />
                                            <label>t/m</label>
                                            <DatePicker name={`group.dateBlockers[${i}].to`} defaultValue={to} />
                                        </MultiInput>
                                    )
                                }}
                                addButtonLabel="Extra veld"
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={this.onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type="submit" isLoading={isMutationLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`
