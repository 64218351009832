import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, TextArea } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditGroupFreeTextInput extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        group: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        this.groupEditMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = async (_, fields) => {
        const { onSubmitSuccess, group } = this.props

        this.groupEditMutator
            .mutate({
                group: {
                    _id: group._id,
                    ...transformFormFields(fields),
                },
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                }
            })
    }

    render() {
        const { onCancel, group } = this.props
        const { loading, errors } = this.groupEditMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={'Nederlands'} errors={errors}>
                            <TextArea
                                name={'localizedExtraLearnerInformation.nl'}
                                defaultValue={get(group, 'localizedExtraLearnerInformation.nl')}
                            />
                        </Field>
                        <Field title={'Engels'} errors={errors}>
                            <TextArea
                                name={'localizedExtraLearnerInformation.en'}
                                defaultValue={get(group, 'localizedExtraLearnerInformation.en')}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`
