import * as React from 'react'

import { EmployeeLessonsTable, ExternalTeacherLessonsTable } from '~/implementations'
import { ContentView } from '~/components/ContentView'
import { getCurrentUser } from '~/services/session'

interface Props {
    group?: any
    refetch?: () => void
}

export default class LessonsView extends React.Component<Props> {
    public render() {
        const { group, refetch } = this.props
        const currentUser = getCurrentUser()

        return (
            <ContentView>
                {currentUser.isEmployee && <EmployeeLessonsTable group={group} refetch={refetch} />}
                {currentUser.isExternalTeacher && <ExternalTeacherLessonsTable group={group} />}
            </ContentView>
        )
    }
}
