import * as React from 'react'
import { DateRange } from '~/types/DateRange'
const moment = require('moment')

interface RangePropType {
    from: Date | string
    to: Date | string
}

interface Props {
    range: RangePropType | DateRange
}

export class DateBlocker extends React.Component<Props> {
    public render() {
        const {
            range: { from, to },
        } = this.props
        const SEPARATOR = 't/m'
        const FORMAT = 'L'

        const formattedFrom = moment(from).format(FORMAT)
        const formattedTo = moment(to).subtract(1, 'day').format(FORMAT)

        return (
            <span>
                {formattedFrom === formattedTo ? formattedFrom : `${formattedFrom} ${SEPARATOR} ${formattedTo}`}
            </span>
        )
    }
}
