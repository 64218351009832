import './StatisticTableCell.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { TableCell } from '~/components/TableCell'
import Paragraph from '~/components/Paragraph'

interface Props {
    className?: ClassValue
    statistic: string
    subStatistic?: string
    description: string
}

export class StatisticTableCell extends React.Component<Props> {
    private bem = new BEM('StatisticTableCell')

    public render() {
        const { className, statistic, subStatistic, description } = this.props

        return (
            <TableCell className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('content')}>
                    <h3 className={this.bem.getElement('statistic')}>
                        {statistic}
                        {subStatistic && <span className={this.bem.getElement('sub-statistic')}>{subStatistic}</span>}
                    </h3>
                    <Paragraph className={this.bem.getElement('description')}>{description}</Paragraph>
                </div>
            </TableCell>
        )
    }
}
