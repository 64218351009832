import * as React from 'react'
import { browserHistory } from 'react-router'

import { Brand, Button, Field, FieldCollection } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { View } from '~/components/View'
import { Translator } from '~/services/i18n'
import { User } from '~/types/User'

interface Props {}

interface State {
    user?: User
}

export default class ForgotMessageView extends React.Component<Props, State> {
    public state: State = {
        user: undefined,
    }

    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.translator = new Translator({
            namespace: 'Cover.ForgotMessage',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { t } = this.translator
        return (
            <View>
                <Brand>{t('brand')}</Brand>
                <FieldCollection style={`cover`}>
                    <FieldGroup>
                        <Field style={`compact`}>{t('fields.message')}</Field>
                        <Field>
                            <Button styleOverride={`edit`} onClick={this.onSubmit}>
                                {t('buttons.submit')}
                            </Button>
                        </Field>
                    </FieldGroup>
                </FieldCollection>
            </View>
        )
    }

    private onSubmit = () => {
        browserHistory.push('/login')
    }
}
