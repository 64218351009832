import * as React from 'react'
import memoize from 'memoize-one'
import { RouteComponentProps } from 'react-router'
import { View, Props as ViewProps } from '~/components/View'
import {
    BreadCrumbContext,
    TBreadCrumbContext,
    AddedBreadCrumbResult,
    BreadCrumb,
} from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { getPathFromRouteProps } from '~/utils/getPathFromRouteProps'

export interface Props extends ViewProps {
    crumbLabel: React.ReactNode
    crumbLabelString?: string
    routeProps?: RouteComponentProps<any, any>
    path?: string
    isTab?: boolean
    isLoading?: boolean
    disableCrumb?: boolean
}

export class RouteView extends React.PureComponent<Props> {
    private addedBreadCrumbResult?: AddedBreadCrumbResult

    public componentWillUnmount() {
        if (this.addedBreadCrumbResult) {
            this.addedBreadCrumbResult.removeBreadCrumb()
            this.addedBreadCrumbResult = undefined
        }
    }

    public render() {
        return <BreadCrumbContext.Consumer>{this.renderBreadCrumbChildren}</BreadCrumbContext.Consumer>
    }

    private renderBreadCrumbChildren = (breadCrumbContext?: TBreadCrumbContext) => {
        const { crumbLabel, crumbLabelString, routeProps, path, disableCrumb, ...viewProps } = this.props

        if (!breadCrumbContext) {
            throw new Error('Breadcrumbs can only be consumed from within a BreadCrumbProvider')
        }

        if (!disableCrumb) {
            const _path = this.getPath(routeProps, path)
            this.setBreadCrumb(breadCrumbContext, crumbLabel, crumbLabelString, _path)
        }

        return <View {...viewProps}>{this.props.children}</View>
    }

    private setBreadCrumb(
        breadCrumbContext: TBreadCrumbContext,
        crumbLabel: React.ReactNode,
        crumbLabelString: string | undefined,
        crumbPath: string
    ) {
        const { isTab, isLoading } = this.props
        const { addBreadCrumb } = breadCrumbContext

        const labelString = this.getLabelString(crumbLabel, crumbLabelString)

        const newBreadCrumb: BreadCrumb = {
            label: crumbLabel,
            labelString: labelString,
            path: crumbPath,
            showOnlyInLeadingCrumb: !!isTab,
            isLoading: !!isLoading,
        }

        if (this.addedBreadCrumbResult) {
            this.addedBreadCrumbResult.replaceBreadCrumb(newBreadCrumb)
        } else {
            this.addedBreadCrumbResult = addBreadCrumb(newBreadCrumb)

            /**
             * Wrap replace-function with memoized variant.
             * This prevents the replace function from being called
             * when the props hasn't changed.
             *
             * Not only a performance optimization, but also required
             * to prevent infinite rerenders
             */
            this.addedBreadCrumbResult.replaceBreadCrumb = memoize(
                this.addedBreadCrumbResult.replaceBreadCrumb,
                ([newBreadCrumb]: [BreadCrumb], [lastBreadCrumb]: [BreadCrumb]) => {
                    return (
                        !lastBreadCrumb ||
                        (lastBreadCrumb.label === newBreadCrumb.label &&
                            lastBreadCrumb.labelString === newBreadCrumb.labelString &&
                            lastBreadCrumb.path === newBreadCrumb.path &&
                            lastBreadCrumb.showOnlyInLeadingCrumb === newBreadCrumb.showOnlyInLeadingCrumb &&
                            lastBreadCrumb.isLoading === newBreadCrumb.isLoading)
                    )
                }
            )
        }
    }

    /**
     * Determine the string-version of the label
     */
    private getLabelString(crumbLabel: React.ReactNode, crumbLabelString: string | undefined): string {
        if (typeof crumbLabelString === 'string') {
            return crumbLabelString
        }

        if (typeof crumbLabel === 'string') {
            return crumbLabel
        }

        if (!crumbLabel) {
            return ''
        }

        throw new Error(
            'When proving a non-string ReactNode as crumbLabel, you must provide a crumbLabelString as well.'
        )
    }

    private getPath(routeProps: RouteComponentProps<any, any> | undefined, path: string | undefined): string {
        if (path) {
            return path
        }

        if (routeProps) {
            return getPathFromRouteProps(routeProps)
        }

        throw new Error('Either provide routeProps or provide a path')
    }
}
