import { gql } from '@apollo/client'
import React from 'react'

import { Bold, Button, Currency, Field, FieldCollection, Form, Input, FieldText } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { FormFields } from '~/components/Form'
import { Invoice } from '~/types/Invoice'
import { DefaultAmountMultiInput } from '~/components/MultiInput/DefaultAmountMultiInput'
import { unitType, UnitInput } from '~/components/UnitInput'

const CREATE_FINAL_EXAM_CREDIT_INVOICE_MUTATION = gql`
    mutation _(
        $userId: MongoID
        $debitInvoiceId: MongoID
        $amount: Float
        $alternativeAmountDescription: String
        $creditDescription: String
        $notes: String
    ) {
        invoices_createFinalExamCreditInvoice(
            userId: $userId
            debitInvoiceId: $debitInvoiceId
            amount: $amount
            alternativeAmountDescription: $alternativeAmountDescription
            creditDescription: $creditDescription
            notes: $notes
        ) {
            _id
        }
    }
`

interface Props {
    debitInvoice: Invoice
    userId: string
    onCancel?: () => void
    onSubmitSuccess: () => void
}

interface State {
    alternativeAmount: number | null
}

export class CreateFinalExamCreditInvoiceForm extends React.Component<Props, State> {
    public state: State = {
        alternativeAmount: null,
    }

    private creditInvoiceMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.creditInvoiceMutator = new Mutator({
            mutation: CREATE_FINAL_EXAM_CREDIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, debitInvoice } = this.props
        const { alternativeAmount } = this.state
        const { loading, errors } = this.creditInvoiceMutator

        const remainingCreditableAmount = debitInvoice.remainingConceptCreditableAmount || 0

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel title={`Factuuromschrijving`} errors={errors}>
                            <Input
                                type={`text`}
                                placeholder={`Factuuromschrijving`}
                                name={`creditDescription`}
                                defaultValue={`Terugbetaling`}
                            />
                        </Field>
                        <Field isLabel title={`Interne notitie`} errors={errors}>
                            <Input type={`text`} placeholder={`Wordt alleen intern weergegeven`} name={`notes`} />
                        </Field>
                    </FieldGroup>
                    <FieldGroup isForm={true}>
                        <Field title={`Maximaal te crediteren bedrag`}>
                            <FieldText>
                                <Currency
                                    amount={remainingCreditableAmount + (debitInvoice.conceptCreditedAmount || 0)}
                                />
                            </FieldText>
                        </Field>
                        <Field title={`Reeds gecrediteerd bedrag`}>
                            <FieldText>
                                <Currency amount={debitInvoice.conceptCreditedAmount} />
                            </FieldText>
                        </Field>
                        <Field title={`Te crediteren bedrag`} errors={errors}>
                            <DefaultAmountMultiInput>
                                <FieldText>
                                    <Currency amount={remainingCreditableAmount} />
                                </FieldText>
                                <UnitInput
                                    placeholder={`Alternatief bedrag`}
                                    value={alternativeAmount || ''}
                                    name={`amount`}
                                    unitType={unitType.euro}
                                    onChange={this.handleAlternativeAmountChange}
                                />
                            </DefaultAmountMultiInput>
                        </Field>
                        {this.hasAlternativeAmount() && (
                            <Field title={`Toelichting`} errors={errors} isRequired={true}>
                                <Input
                                    type={`text`}
                                    placeholder={`Toelichting op alternatief factuurbedrag`}
                                    name={`alternativeAmountDescription`}
                                />
                            </Field>
                        )}
                    </FieldGroup>
                    <FieldGroup highlight={true}>
                        <Field title={`Creditbedrag`}>
                            <Bold>
                                <Currency amount={this.getAmount()} />
                            </Bold>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Verzoek indienen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess, debitInvoice, userId } = this.props

        const variables = {
            isCredit: true,
            userId: userId,
            debitInvoiceId: debitInvoice._id,
            amount: this.getAmount(),
            ...transformFormFields(
                fields,
                {
                    notes: (v?: string) => v && v.trim(),
                    creditDescription: (v?: string) => v && v.trim(),
                },
                ['amount', 'nulls']
            ),
        }

        const data = await this.creditInvoiceMutator.mutate(variables)

        if (data && data.invoices_createFinalExamCreditInvoice) {
            onSubmitSuccess()
        }
    }

    private handleAlternativeAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(event.target.value)

        this.setState({
            alternativeAmount: isNaN(amount) ? null : amount,
        })
    }

    private hasAlternativeAmount() {
        const { alternativeAmount } = this.state

        return typeof alternativeAmount === 'number' && alternativeAmount !== this.getDefaultAmount()
    }

    private getAmount() {
        return this.hasAlternativeAmount() ? this.state.alternativeAmount : this.getDefaultAmount()
    }

    private getDefaultAmount() {
        const { debitInvoice } = this.props

        return debitInvoice.remainingConceptCreditableAmount
    }
}
