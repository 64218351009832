import * as React from 'react'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { ProgressTableAbilityItem } from '~/components/tables/ProgressTable/ProgressTableAbilityItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import ReadableDate from '~/components/ReadableDate'
import { ExamProgressCell } from '~/components/tables/ProgressTable/ExamProgressCell'
import Subtle from '~/components/Subtle'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Button } from '~/components/buttons/Button/Button'
import CenterModal from '~/components/CenterModal'
import { User, UserRole } from '~/types/User'
import { ExamDignityModalForm } from '~/forms/ExamDignityModalForm'
import { FinalExamPart, FinalExam } from '~/types/FinalExam'
import translateType from '~/shared/utils/translateType'
import { viewerHasRole } from '~/services/session'
import Link from '~/components/Link'
import { getCurrentUser } from '~/services/session'

interface Props {
    isLoading: boolean
    onSubmitSuccess?: () => void
    user?: User
}

export class UserLearnerExamProgressTable extends React.Component<Props> {
    private finalExamParts = Object.values(FinalExamPart)

    public render() {
        const { isLoading, user, onSubmitSuccess } = this.props
        const examDignityDate = user && user.learner && user.learner.examDignityDate

        return (
            <TableWrap>
                <TableView title={`Examenresultaten`}>
                    <Table>
                        {this.renderTableHeader()}
                        {isLoading ? (
                            <TableRow key={`loading`}>
                                <TableCell colSpan={this.getTotalColSpan()} isLoading={true} />
                            </TableRow>
                        ) : (
                            this.renderTableRows()
                        )}
                        <TableRow isActionRow={true} isHighlighted={true}>
                            <TableCell colSpan={this.getTotalColSpan()}>
                                <List horizontal={true}>
                                    {examDignityDate && (
                                        <>
                                            <ListItem>
                                                <Subtle>Examenwaardig vanaf</Subtle>
                                            </ListItem>
                                            <ListItem>
                                                <ReadableDate date={examDignityDate} />
                                            </ListItem>
                                        </>
                                    )}
                                    {!examDignityDate && (
                                        <>
                                            <ListItem>
                                                <Subtle>Datum examenwaardig nog niet bekend</Subtle>
                                            </ListItem>
                                        </>
                                    )}
                                    <ListItem right={true}>
                                        {getCurrentUser().isEmployee && onSubmitSuccess && (
                                            <ModalManager
                                                render={openModal => (
                                                    <Button onClick={openModal} small={true}>
                                                        {examDignityDate ? 'Bewerken' : 'Datum toevoegen'}
                                                    </Button>
                                                )}
                                                getModal={closeModal => (
                                                    <CenterModal onClose={closeModal} title={`Examenwaardigheid`}>
                                                        <ExamDignityModalForm
                                                            onSubmitSuccess={onSubmitSuccess}
                                                            onClose={closeModal}
                                                            isLoading={isLoading}
                                                            user={user}
                                                        />
                                                    </CenterModal>
                                                )}
                                            />
                                        )}
                                    </ListItem>
                                </List>
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderTableHeader = () => {
        return (
            <TableHeader>
                <TableHeaderItem>Examendatum</TableHeaderItem>
                <TableHeaderItem>Niveau</TableHeaderItem>
                <TableHeaderItem>Poging</TableHeaderItem>
                <TableHeaderItem width={`400px`} />
                {this.finalExamParts.map(part => (
                    <ProgressTableAbilityItem
                        title={translateType('finalExamPart', part)}
                        key={part}
                        biggestTitleLength={this.getBiggestTitleLength()}
                    />
                ))}
            </TableHeader>
        )
    }

    private renderTableRows = () => {
        const { user } = this.props

        const finalExamsWithResult = user && user.learner && user.learner.finalExams

        if (!finalExamsWithResult || finalExamsWithResult.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={this.getTotalColSpan()}>
                        <Subtle>Nog geen resultaten</Subtle>
                    </TableCell>
                </TableRow>
            )
        }

        return finalExamsWithResult.map(finalExam => {
            return (
                <TableRow key={finalExam._id}>
                    <TableCell>{this.renderFinalExamDate(finalExam)}</TableCell>
                    <TableCell>{finalExam.level}</TableCell>
                    <TableCell>{finalExam.attempt}</TableCell>
                    <TableCell />
                    {this.finalExamParts.map(part => (
                        <ExamProgressCell key={part} result={part === finalExam.part ? finalExam.result : undefined} />
                    ))}
                </TableRow>
            )
        })
    }

    private renderFinalExamDate = (finalExam: FinalExam) => {
        const date = <ReadableDate date={finalExam.date} />

        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={`/final-exams/planned/${finalExam._id}`}>{date}</Link>
        }

        return date
    }

    private getBiggestTitleLength = () => {
        const biggestTitleLength = Math.max(...this.finalExamParts.map(part => part.length))

        return biggestTitleLength
    }

    private getTotalColSpan = () => {
        return this.finalExamParts.length + 4
    }
}
