import './ExamProgressCell.scss'
import * as React from 'react'
import c from 'classnames'
import { TableCell } from '~/components/TableCell'
import Icon from '~/components/Icon'
import { FinalExamResult } from '~/types/FinalExam'

interface Props {
    className?: string
    result?: FinalExamResult | null
    isBlocked?: boolean
}

export class ExamProgressCell extends React.Component<Props> {
    public render() {
        return (
            <TableCell className={this.getClassName()}>
                <div className={`tt-ExamProgressCell__content`}>{this.getSymbol()}</div>
            </TableCell>
        )
    }

    private getSymbol = () => {
        const { result } = this.props

        return result === FinalExamResult.Passed ? (
            <Icon name={`check`} />
        ) : result === FinalExamResult.Failed || result === FinalExamResult.NoShow ? (
            <Icon name={`times`} />
        ) : result === FinalExamResult.Exempt ? (
            'v'
        ) : result === null ? (
            '?'
        ) : null
    }

    private getClassName = () => {
        const { className, result, isBlocked } = this.props

        return c(
            'tt-ExamProgressCell',
            {
                'tt-ExamProgressCell--no-result': result === null,
                'tt-ExamProgressCell--no-show': result === FinalExamResult.NoShow,
                'tt-ExamProgressCell--passed': result === FinalExamResult.Passed,
                'tt-ExamProgressCell--failed': result === FinalExamResult.Failed,
                'tt-ExamProgressCell--exempt': result === FinalExamResult.Exempt,
                'tt-ExamProgressCell--is-blocked': isBlocked,
            },
            className
        )
    }
}
