import React from 'react'
import { Icon } from '~/components'
import { ClassValue, BEM } from '~/services/BEMService'
import { User } from '~/types/User'

interface Props {
    user?: User
    className?: ClassValue
    renderContent?: (toggleExpandableMenu: () => void) => JSX.Element
}

interface State {
    active: boolean
}

export default class ExpandableMenu extends React.Component<Props> {
    public state: State = {
        active: false,
    }

    private bem = new BEM('ExpandableMenu', () => ({
        'is-open': this.props.user && this.state.active,
    }))

    private node: HTMLDivElement | null

    public componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false)
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    public render() {
        const { active } = this.state

        return (
            <div className={this.bem.getClassName()}>
                {this.renderToggle()}
                {active ? this.renderExpandableMenuList() : null}
            </div>
        )
    }

    private renderToggle = () => {
        const { user } = this.props

        return (
            <span onClick={() => this.toggleExpandableMenu()}>
                {user && this.getUserName()}
                {user ? <Icon name={`chevron-down-small`} /> : <Icon name={`extra`} />}
            </span>
        )
    }

    private renderExpandableMenuList = () => {
        const { renderContent } = this.props
        return (
            <div ref={node => (this.node = node)} className={this.bem.getElement('dropdownlist')}>
                <ul>{renderContent && renderContent(this.toggleExpandableMenu)}</ul>
            </div>
        )
    }

    private handleClick = (e: any) => {
        const clickedTarget = e.target.parentNode.className
        if (
            (this.node && this.node.contains(e.target)) ||
            clickedTarget === 'tt-ExpandableMenu' ||
            clickedTarget === 'tt-ExpandableMenu tt-ExpandableMenu--is-open' ||
            clickedTarget === 'tt-Icon tt-Icon--chevron-down-small' ||
            clickedTarget === 'tt-Icon tt-Icon--extra'
        ) {
            return
        }

        this.setState({
            active: false,
        })
    }

    private toggleExpandableMenu = () => {
        this.setState({
            active: !this.state.active,
        })
    }

    private getUserName = () => {
        const { user } = this.props
        const firstLetter = user && user.profile.nameAbbreviation && user.profile.nameAbbreviation.charAt(0)
        const lastName = user && user.profile.fullSurName
        const userName = `${firstLetter}. ${lastName}`

        return userName
    }
}
