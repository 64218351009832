import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CenterModal } from '../components'

export default class VideosModal extends Component {
    static propTypes = {
        children: PropTypes.any,
        onClose: PropTypes.func,
        title: PropTypes.string,
    }

    render() {
        const { children, onClose, title } = this.props

        return (
            <CenterModal onClose={onClose} title={title} className={`tt-VideosModal`}>
                {children}
            </CenterModal>
        )
    }
}
