import React from 'react'
import { ModalManager } from '../ModalManager'
import { CenterModal, Form, Button, FieldCollection, Field, FileInput } from '..'
import { Contract } from '~/types/Contracts'
import { List } from '../List'
import { ListItem } from '../ListItem'
import { FieldGroup } from '../FieldGroup'
import { FieldCollectionFooter } from '../FieldCollectionFooter'
import { gql } from '@apollo/client'
import { Mutator, toast } from '~/utils'

interface Props {
    render: (openModal: () => void) => JSX.Element
    contract: Contract
    onSubmitSuccess?: () => void
}

interface State {}

export class ContractSignatureModal extends React.Component<Props, State> {
    private uploadSignatureMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.uploadSignatureMutator = new Mutator({
            mutation: UPLOAD_SIGNATURE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { render, contract } = this.props
        const { loading, errors } = this.uploadSignatureMutator

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <CenterModal
                        onClose={closeModal}
                        title={`Handtekening ${contract.isSigned ? 'wijzigen' : 'uploaden'} voor ${
                            contract.contractNumber
                        }`}
                    >
                        <Form onSubmit={(events, fields) => this.onSubmit(fields, closeModal)}>
                            <FieldCollection style="modal">
                                <FieldGroup isForm>
                                    <Field isLabel title={`Bestand`} errors={errors}>
                                        <FileInput name={`signatureFile`} isClearable={true} accept={'application/pdf'}>
                                            Bestand uploaden
                                        </FileInput>
                                    </Field>
                                </FieldGroup>
                                <FieldCollectionFooter>
                                    <List horizontal>
                                        <ListItem right>
                                            <Button onClick={closeModal}>Annuleren</Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button isLoading={loading} type="submit">
                                                Opslaan
                                            </Button>
                                        </ListItem>
                                    </List>
                                </FieldCollectionFooter>
                            </FieldCollection>
                        </Form>
                    </CenterModal>
                )}
            />
        )
    }

    private onSubmit = async (fields: any, closeModal: () => void) => {
        const { contract, onSubmitSuccess } = this.props

        const data = await this.uploadSignatureMutator.mutate({
            contractId: contract._id,
            signatureFile: fields.signatureFile,
        })

        if (data && data.contracts_uploadSignature) {
            if (onSubmitSuccess) {
                onSubmitSuccess()
            }

            closeModal()
            toast.success(`Handtekening is geüpload`)
        }
    }
}

const UPLOAD_SIGNATURE_MUTATION = gql`
    mutation _($contractId: MongoID!, $signatureFile: UploadedFile!) {
        contracts_uploadSignature(contractId: $contractId, signatureFile: $signatureFile) {
            _id
        }
    }
`
