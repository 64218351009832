import './EmptyView.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { View } from '~/components/View'
import { Row } from '~/components/Core/Layout/Row'
import { Column } from '~/components/Core/Layout/Column'
import { Subtle } from '~/components'

export type EmptyViewType = 'add' | 'search'

interface Props {
    className?: ClassValue
    title: string
    type: EmptyViewType
    renderAction: () => JSX.Element
}

export class EmptyView extends React.Component<Props> {
    private bem = new BEM('EmptyView')

    public render() {
        const { className, type, renderAction } = this.props
        const imageUrl =
            type === 'add' ? `/static/images/emptystate-add-something.png` : `/static/images/emptystate-search.png`
        const imageAltText = type === 'add' ? `Add something to view this page` : `Search for something`

        return (
            <View className={this.bem.getClassName(className)}>
                <Row isHorizontalCentered={true}>
                    <img src={imageUrl} alt={imageAltText} className={this.bem.getElement('image')} />
                    <Column>
                        <Subtle isBlockElement={true} regularSize={true} className={this.bem.getElement('title')}>
                            Debiteurnummer en/of leenbedrag onbekend
                        </Subtle>
                        <div className={this.bem.getElement('action')}>{renderAction()}</div>
                    </Column>
                </Row>
            </View>
        )
    }
}
