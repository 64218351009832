require('classlist-polyfill')

require('core-js/fn/symbol')
require('core-js/fn/array/from')
require('core-js/fn/array/includes')
require('core-js/fn/string/includes')
require('core-js/fn/string/starts-with')
require('core-js/fn/array/find')
require('core-js/fn/array/flat-map')
require('core-js/fn/object/entries')
require('core-js/fn/object/values')
require('core-js/fn/array/flat-map')
require('core-js/fn/array/flatten')
require('core-js/fn/array/find-index')
require('core-js/es6/map')
require('core-js/es6/set')
require('core-js/shim')
require('core-js/es6/string')
require('core-js/es6/array')

require('focus-visible')
require('url-polyfill')

// Polyfill from MDN @https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill for element.closest()
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this
        if (!document.documentElement.contains(el)) return null
        do {
            if (el.matches(s)) return el
            el = el.parentElement || el.parentNode
        } while (el !== null && el.nodeType === 1)
        return null
    }
}

if (!Number.MAX_SAFE_INTEGER) {
    Number.MAX_SAFE_INTEGER = Math.pow(2, 53) - 1 // 9007199254740991
}

if (!Number.MIN_SAFE_INTEGER) {
    Number.MIN_SAFE_INTEGER = -(Math.pow(2, 53) - 1) // -9007199254740991
}
