import { gql } from '@apollo/client'
import * as React from 'react'

import {
    Bold,
    Button,
    Currency,
    Field,
    FieldCollection,
    Form,
    FieldText,
    CheckBox,
    Subtle,
    MultiInput,
    Input,
} from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerOption, TagPickerChangeHandler } from '~/components/TagPicker'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { translateType, types } from '~/shared/utils'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { Group } from '~/types/Group'
import { DefaultAmountMultiInput } from '~/components/MultiInput/DefaultAmountMultiInput'
import { CourseMaterial } from '~/types/Module'
import { UnitInput, unitType } from '~/components/UnitInput'
import { InvoiceDescription } from '~/types/Invoice'

const CREATE_DEBIT_INVOICE_MUTATION = gql`
    mutation _($invoice: InvoiceInputType!) {
        invoices_createDebit(invoice: $invoice) {
            _id
        }
    }
`

const GROUPS_QUERY = gql`
    query _($usersFilters: UsersFilterInputType!) {
        users(filters: $usersFilters) {
            _id
            learner {
                groups(filterByActive: true) {
                    _id
                    name
                    module {
                        _id
                        courseMaterials {
                            _id
                            name
                            amount
                        }
                    }
                }
            }
        }
    }
`

const INVOICE_DESCRIPTION_OPTIONS = Object.keys(types.invoiceDescription).map(value => ({
    value: value,
    label: translateType('invoiceDescription', value),
}))

interface Props {
    userId: string
    isForDUO?: boolean
    onSubmitSuccess: () => void
    onCancel?: () => void
    onRequestSend: (id: string) => void
}

interface State {
    invoiceDescriptionIsCourseMaterials: boolean
    isCreatingInvoice: boolean
    selectedGroupId?: string
    selectedCourseMaterialIds: string[]
    alternativeAmount: number
}

interface CreateInvoiceFormFields {
    invoice?: {
        description: string
        groupId: string
        amount: number
    }
}

export class CreateInvoiceForm extends React.Component<Props, State> {
    public state: State = {
        invoiceDescriptionIsCourseMaterials: false,
        isCreatingInvoice: false,
        selectedGroupId: undefined,
        selectedCourseMaterialIds: [],
        alternativeAmount: 0,
    }

    private invoiceMutator: Mutator
    private groupsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.invoiceMutator = new Mutator({
            mutation: CREATE_DEBIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.groupsFetcher = new Fetcher({
            query: GROUPS_QUERY,
            variables: {
                usersFilters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
            transformData: data => ({
                groups:
                    (data && data.users && data.users[0] && data.users[0].learner && data.users[0].learner.groups) ||
                    [],
            }),
        })
    }

    public render() {
        const { onCancel, isForDUO } = this.props
        const { isCreatingInvoice, invoiceDescriptionIsCourseMaterials } = this.state
        const { errors } = this.invoiceMutator
        const { loading: loadingGroups } = this.groupsFetcher

        // Store the form reference so we can pass the fields
        // to the endGroup method.
        let formRef: any

        return (
            <Form
                onSubmit={(event, fields) => this.onSubmit(event, fields, { shouldSendInvoice: false })}
                ref={ref => (formRef = ref)}
            >
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Factuuromschrijving`} errors={errors}>
                            <TagPicker
                                multi={false}
                                name={`invoice.description`}
                                onChange={this.onInvoiceDescriptionChange}
                                options={INVOICE_DESCRIPTION_OPTIONS}
                                required={true}
                            />
                        </Field>
                        <Field isLabel={true} title={`Gekoppeld aan groep`} errors={errors} isLoading={loadingGroups}>
                            <TagPicker
                                multi={false}
                                name={`invoice.groupId`}
                                options={this.getGroupOptions()}
                                onChange={this.onGroupIdChange}
                                required={true}
                            />
                        </Field>
                        {invoiceDescriptionIsCourseMaterials && this.renderCourseMaterials()}
                    </FieldGroup>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Bedrag`} errors={errors}>
                            <DefaultAmountMultiInput>
                                <FieldText>
                                    <Currency amount={this.getSuggestedAmount()} />
                                </FieldText>
                                <UnitInput
                                    name={`invoice.amount`}
                                    placeholder={`Alternatief factuurbedrag`}
                                    onChange={this.onAmountChange}
                                    required={true}
                                    unitType={unitType.euro}
                                />
                            </DefaultAmountMultiInput>
                            {this.hasAlternativeAmount() && (
                                <Input
                                    name={`invoice.alternativeAmountDescription`}
                                    placeholder={`Voeg een omschrijving toe`}
                                    type={`text`}
                                />
                            )}
                        </Field>
                    </FieldGroup>
                    <FieldGroup highlight={true}>
                        <Field title={`Factuurbedrag`}>
                            <Bold>
                                <Currency amount={this.getFinalAmount()} />
                            </Bold>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button
                                    type={`submit`}
                                    styleOverride={`secondary-submit`}
                                    isLoading={isCreatingInvoice}
                                >
                                    Aanmaken
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button
                                    type={`submit`}
                                    shouldPreventSubmit={true}
                                    isLoading={isCreatingInvoice}
                                    onClick={() => {
                                        this.onSubmit(undefined, formRef.getFormValues(), { shouldSendInvoice: true })
                                    }}
                                >
                                    Aanmaken en {isForDUO ? `definitief maken` : `versturen`}
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderCourseMaterials = () => {
        const { errors } = this.invoiceMutator
        const courseMaterials = this.getCourseMaterialsBySelectedGroup()

        if (!courseMaterials || !courseMaterials.length) {
            return null
        }

        return (
            <FieldGroup isInsetGroup={true}>
                <Field title={`Lesmaterialen`} errors={errors}>
                    <MultiInput type={`checkbox`} isVertical={true}>
                        {courseMaterials.map((courseMaterial, i) => {
                            const { _id, name, amount } = courseMaterial
                            const hasValidAmount = typeof amount === 'number'

                            return (
                                <CheckBox
                                    value={_id}
                                    key={_id}
                                    defaultChecked={true}
                                    name={`invoice.courseMaterialIds[${i}]`}
                                    onChange={this.onCourseMaterialCheckBoxChange}
                                >
                                    {name}
                                    <Subtle asSuffix={true}>
                                        {hasValidAmount && <Currency amount={amount} />}
                                        {!hasValidAmount && 'Onbekend'}
                                    </Subtle>
                                </CheckBox>
                            )
                        })}
                    </MultiInput>
                </Field>
            </FieldGroup>
        )
    }

    private onGroupIdChange: TagPickerChangeHandler = selectedOption => {
        const groupId = selectedOption.value
        const courseMaterials = this.getCourseMaterialsByGroupId(groupId)

        this.setState({
            selectedGroupId: groupId,
            selectedCourseMaterialIds: courseMaterials.map(courseMaterial => courseMaterial._id),
        })
    }

    private onCourseMaterialCheckBoxChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const {
            target: { value, checked },
        } = event
        const { selectedCourseMaterialIds } = this.state

        const newCourseMaterialIds = selectedCourseMaterialIds as string[]
        if (checked === false) {
            if (selectedCourseMaterialIds.includes(value)) {
                const index = selectedCourseMaterialIds.indexOf(value)
                newCourseMaterialIds.splice(index, 1)
            }
        } else {
            if (!selectedCourseMaterialIds.includes(value)) {
                newCourseMaterialIds.push(value)
            }
        }

        this.setState({
            selectedCourseMaterialIds: newCourseMaterialIds,
        })
    }

    private getSuggestedAmount = () => {
        const { selectedCourseMaterialIds } = this.state

        const courseMaterials = this.getCourseMaterialsBySelectedGroup() || []
        return courseMaterials
            .filter(courseMaterial => selectedCourseMaterialIds.includes(courseMaterial._id))
            .filter(courseMaterial => !!courseMaterial.amount)
            .reduce((prev, curr) => prev + (curr.amount as number), 0)
    }

    private onInvoiceDescriptionChange = (selectedOption: TagPickerOption) => {
        const { value } = selectedOption

        if (value === InvoiceDescription.LessonMaterials) {
            this.setState({ invoiceDescriptionIsCourseMaterials: true })
        }
    }

    private getCourseMaterialsByGroupId = (groupId: string) => {
        const { data } = this.groupsFetcher
        const groups: Group[] = data.groups || []
        const group = groups.find(group => group._id === groupId)

        return (group && group.module && group.module.courseMaterials) || []
    }

    private getCourseMaterialsBySelectedGroup = (): CourseMaterial[] => {
        const { data } = this.groupsFetcher
        const groups: Group[] = data.groups || []
        const { selectedGroupId } = this.state
        const selectedGroup = groups.find(group => group._id === selectedGroupId)

        return (selectedGroup && selectedGroup.module && selectedGroup.module.courseMaterials) || []
    }

    private getGroupOptions = () => {
        const { data } = this.groupsFetcher
        const { groups = [] } = data

        return groups.map((group: Group) => ({
            value: group._id,
            label: group.name,
        }))
    }

    private onSubmit = async (
        event: React.FormEvent<HTMLFormElement> | undefined,
        fields: CreateInvoiceFormFields,
        { shouldSendInvoice = false }
    ) => {
        const { onSubmitSuccess, userId, onRequestSend } = this.props
        const { selectedCourseMaterialIds } = this.state

        this.setState({ isCreatingInvoice: true })

        const data = await this.invoiceMutator.mutate({
            invoice: {
                userId,
                ...transformFormFields(fields.invoice, {
                    amount: {
                        value: this.getFinalAmount(),
                    },
                }),
                courseMaterialIds: selectedCourseMaterialIds,
            },
        })

        if (data && data.invoices_createDebit) {
            if (shouldSendInvoice) {
                await onRequestSend(data.invoices_createDebit._id)
            }

            onSubmitSuccess()
        }

        this.setState({ isCreatingInvoice: false })
    }

    private onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            alternativeAmount: +event.target.value,
        })
    }

    private getFinalAmount = (): number => {
        const { alternativeAmount } = this.state

        if (alternativeAmount) {
            return alternativeAmount
        }

        return this.getSuggestedAmount()
    }

    private hasAlternativeAmount = (): boolean => {
        const { alternativeAmount } = this.state

        return !!alternativeAmount && alternativeAmount !== this.getSuggestedAmount()
    }
}
