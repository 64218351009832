import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, RadioButton, MultiInput } from '~/components'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { get } from 'lodash'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { InflowModuleType } from '~/types/InflowModule'
import { gql } from '@apollo/client'
import { translateType } from '~/shared/utils'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: any
    onCancel?: () => void
}

interface State {}

const CREATE_INFLOW_MODULE_MUTATION = gql`
    mutation _($inflowModule: InflowModuleInputType!) {
        inflowModules_create(inflowModule: $inflowModule) {
            _id
            name
            type
        }
    }
`

export class CreateInflowModuleForm extends React.Component<Props, State> {
    private InflowModuleMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.InflowModuleMutator = new Mutator({
            mutation: CREATE_INFLOW_MODULE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.InflowModuleMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input
                                name={`inflowModule.name`}
                                type={`text`}
                                placeholder={`Naam instroommodule`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field title={'Type'} errors={errors}>
                            <MultiInput type="radio">
                                <RadioButton
                                    name={`inflowModule.type`}
                                    value={InflowModuleType.intake}
                                    defaultChecked={true}
                                >
                                    {translateType('inflowModuleType', InflowModuleType.intake)}
                                </RadioButton>
                                <RadioButton
                                    name={`inflowModule.type`}
                                    value={InflowModuleType.levelDetermination}
                                    onClick={() => this.setState({ inflowType: false })}
                                >
                                    {translateType('inflowModuleType', InflowModuleType.levelDetermination)}
                                </RadioButton>
                            </MultiInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onSubmitFailed, onSubmitSuccess } = this.props

        this.InflowModuleMutator.mutate({
            inflowModule: transformFormFields(fields.inflowModule, {}),
        }).then(data => {
            if (data) {
                onSubmitSuccess()
                browserHistory.push(`/properties/inflow/${get(data, 'inflowModules_create._id')}`)
            } else {
                if (onSubmitFailed) {
                    onSubmitFailed()
                }
            }
        })
    }
}
