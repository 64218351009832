import '~/polyfills'

import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './stylesheets/index.scss'
import env from '~/services/env'
import { router } from '~/router'
import { unregister as unregisterServiceWorker } from '~/services/ServiceWorker'

import 'moment/locale/nl'

/**
 * ATTENTION
 * importing this locale from fullcalendar, also means:
 * - that the moment 'nl'-locale is being imported
 * - that the current-locale setting of fullcalendar and moment are set to 'nl'
 */
import 'fullcalendar/dist/locale/nl'

Sentry.init({
    dsn: env.sentryDsn,
    environment: env.sentryEnvironment,
})

ReactDOM.render(router, document.getElementById('root') as HTMLElement)

unregisterServiceWorker()
