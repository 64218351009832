import { User } from '~/types/User'

export interface Note {
    _id: string
    text?: string
    createdAt?: Date
    createdByUser?: User
}

export enum NoteType {
    GroupNote = 'groupNote',
}

export interface NoteTypeData {
    groupId?: string
}
