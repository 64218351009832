import React from 'react'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    dataString?: string
}

export default class EdisaData extends React.Component<Props> {
    private data: any

    constructor(props: Props) {
        super(props)

        if (props.dataString) {
            this.updateParsedData(props.dataString)
        }
    }

    public componentWillUpdate(nextProps: Props) {
        if (this.props.dataString !== nextProps) {
            this.updateParsedData(nextProps.dataString)
        }
    }

    public render() {
        return (
            <List>
                {this.data &&
                    Object.keys(this.data).map(key => (
                        <ListItem key={key}>
                            {this.getPrettyKey(key)}: {this.data[key]}
                        </ListItem>
                    ))}
            </List>
        )
    }

    private updateParsedData(dataString?: string) {
        try {
            if (dataString) {
                this.data = JSON.parse(dataString)
            } else {
                this.data = {}
            }
        } catch (err) {
            this.data = {}
        }
    }

    private getPrettyKey(key: string) {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    }
}
