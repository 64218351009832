import React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    label?: string
}

export default class EnvironmentLabel extends React.Component<Props> {
    private bem = new BEM('EnvironmentLabel')

    public render() {
        const { label } = this.props

        return (
            <div className={this.bem.getClassName()}>
                <span>{label}</span>
            </div>
        )
    }
}
