import './FileUpload.scss'
import * as React from 'react'
import { Button, Icon, Input } from '~/components'
import * as $ from 'jquery'
import c from 'classnames'

interface Props {
    accept?: string
    className?: string
    file?: any
    name: string
    hasValue?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onRemove?: () => void
    smallButton?: boolean
    showOnRowHover?: boolean
}

export class FileUpload extends React.Component<Props> {
    private fileInput: any

    public render() {
        const { accept, name, children, file, hasValue, onChange, smallButton, showOnRowHover } = this.props
        const shouldRenderFileItem = hasValue && !!file
        const defaultAccept =
            '.pdf,.csv,.zip,.msg,.txt,.jpg,.jpeg,.png,.m4a,.tif,.tiff,.webp,.mp4,.mp3,.bmp,.wma,.wav,.flac,.ogg,.oga,.mpeg,.aac,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.ods,.odt'

        return (
            <div className={this.getClassName()}>
                <Button
                    leftIcon={shouldRenderFileItem ? null : <Icon name={`upload`} />}
                    onClick={this.onClick}
                    small={!shouldRenderFileItem && smallButton}
                    className={
                        shouldRenderFileItem
                            ? `tt-FileUpload__content`
                            : `${showOnRowHover ? 'tt-TableRow__show-on-row-hover ' : ''}tt-FileUpload__upload-button`
                    }
                >
                    {shouldRenderFileItem ? file.fileName : children}
                </Button>

                {shouldRenderFileItem && this.renderDeleteButton()}

                <Input
                    ref={ref => {
                        if (ref) {
                            this.fileInput = ref
                        }
                    }}
                    name={name}
                    type={`file`}
                    accept={accept || defaultAccept}
                    onChange={onChange}
                    multiple={false}
                />
            </div>
        )
    }

    private renderDeleteButton = () => {
        const { onRemove } = this.props

        if (onRemove) {
            return (
                <Button
                    className={`tt-FileUpload__remove-button`}
                    onClick={onRemove}
                    leftIcon={<Icon name={`trash`} />}
                />
            )
        }

        return null
    }

    private onClick = () => {
        const { hasValue } = this.props

        if (!hasValue) {
            $(this.fileInput.refs.input).trigger('click')
        }
    }

    private getClassName = () => {
        const { className, hasValue } = this.props

        return c(
            'tt-FileUpload',
            {
                'tt-FileUpload--has-value': hasValue,
            },
            className
        )
    }
}
