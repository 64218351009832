import * as React from 'react'
import { Sorter, Fetcher, removeDuplicateDocuments } from '~/utils'
import { gql } from '@apollo/client'
import { TableWrap, TableHeader, TableHeaderItem, TableRow, Subtle, Link, ReadableDate } from '~/components'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { get, compact } from 'lodash'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'dateTo'
const DEFAULT_SORT_DIR = 'DESC'

const GET_GROUPS_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: GroupsFilterInputType) {
        groups(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            name
            dateFrom
            dateTo
            module {
                _id
                name
            }
            location {
                _id
                name
            }
            teacherUsers(filterRemoved: false) {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

interface Props {
    currentUser: any
}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export class OrganizationContactGroupsOverview extends React.Component<Props, {}> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private groupsFetcher: Fetcher
    private paginator: Paginator

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.groupsFetcher = new Fetcher({
            query: GET_GROUPS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    filterByLearnerOrganizationIds: [props.currentUser.organizationContact.organization._id],
                    filterByConcepts: false,
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public render() {
        const { paginator } = this
        const { loading } = this.groupsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                {this.renderTable()}
            </InfiniteScroll>
        )
    }

    private renderTable() {
        const { data, loading } = this.groupsFetcher
        const { groups = [] } = data
        const { sorter } = this

        return (
            <TableWrap>
                <TableView>
                    <Table>
                        <TableHeader>
                            <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                                Naam
                            </TableHeaderItem>
                            <TableHeaderItem>Docent(en)</TableHeaderItem>
                            <TableHeaderItem>Locatie</TableHeaderItem>
                            <TableHeaderItem>Lesmodule</TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`dateFrom`}>
                                Startdatum
                            </TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`dateTo`}>
                                Einddatum
                            </TableHeaderItem>
                        </TableHeader>
                        {loading ? (
                            <TableRow key={`loading`}>
                                <TableCell colSpan={6} isLoading />
                            </TableRow>
                        ) : groups.length > 0 ? (
                            this.renderGroupRows(groups)
                        ) : (
                            <TableRow key={`emptyresult`}>
                                <TableCell colSpan={6}>
                                    <Subtle>Er zijn geen groepen gevonden.</Subtle>
                                </TableCell>
                            </TableRow>
                        )}
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderGroupRows(groups: any[]) {
        return groups.map(group => {
            return (
                <TableRow key={group._id}>
                    <TableCell>
                        <Link route={`/groups/${group._id}`}>
                            {group.name && group.name.trim() ? group.name : 'Geen naam'}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {compact((group.teacherUsers || []).map((tu: any) => get(tu, 'user.profile.name'))).join(', ')}
                    </TableCell>
                    <TableCell>{(group.location && group.location.name) || ''}</TableCell>
                    <TableCell>{group.module && group.module.name}</TableCell>
                    <TableCell>{group.dateFrom && <ReadableDate date={new Date(group.dateFrom)} />}</TableCell>
                    <TableCell>{group.dateTo && <ReadableDate date={new Date(group.dateTo)} />}</TableCell>
                </TableRow>
            )
        })
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.groupsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: any) => void) => {
        this.groupsFetcher.fetchMore({
            variables: {
                limit,
                skip,
            },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.groups.length === 0,
                })

                return {
                    groups: removeDuplicateDocuments([...(prevData.groups || []), ...moreData.groups]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }
}
