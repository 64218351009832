import './FieldWithButton.scss'
import * as React from 'react'
import c from 'classnames'

interface Props {
    className?: string
    renderButton?: () => React.ReactNode
}

export default class FieldWithButton extends React.Component<Props> {
    public render() {
        const { children, renderButton } = this.props

        return (
            <div className={c('tt-FieldWithButton')}>
                <div className={`tt-FieldWithButton__field`}>{children}</div>
                {renderButton && <div className={`tt-FieldWithButton__button`}>{renderButton()}</div>}
            </div>
        )
    }
}
