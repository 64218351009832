import { uniq, omit } from 'lodash'

/**
 * Utility to:
 * - transform the field values
 * - add a nulls array
 *
 * By default, all fields are passed through,
 * and a value is assumed to be null if the value is falsy
 * according to JavaScript.
 *
 * @example
 *

    apolloClient.mutate({
        mutation: EDIT_USER_MUTATION,
        variables: {
            user: {
                _id: params.id,
                ...transformFormFields(fields, {

                    // This is the default
                    email: {isNull: v => !v, value: fields.email, compare: v => v === true},

                    // Custom value
                    email: {value: 'some@overwrite.com'},

                    // Custom value can also be a function returning one
                    email: {value: () => 'some@overwrite.com'},

                    // Custom isNull condition (if isNull is truthy, this will prevent the value function to be called)
                    email: {isNull: true},

                    // Custom isNull condition in function
                    email: {isNull: v => v.length > 1},

                    // Comparison (to prevent the field to submitted when it hasn't been changed)
                    email: {compare: v => v === user.email},

                    // You can specify nested fields like this (fields can also be a function returning fields)
                    profile: {
                        fields: transformFormFields(fields.profile)
                    }

                    // Other fields are automatically passed through
                })
            }
        }
    })

 */

export const lib = {
    /**
     * Transform type='number' field to Int
     */
    int: () => {
        return {
            value: (v: string) => parseInt(v, 10),
            isNull: (v: number | null) => v === null || isNaN(v),
        }
    },

    /**
     * Transform field to Float
     */
    float: () => {
        return {
            value: (v: string) => parseFloat(v),
            isNull: (v: number | null) => v === null || isNaN(v),
        }
    },

    /**
     * Transform field to string and trim
     */
    string: () => {
        return {
            value: (v: any) => `${v}`.trim(),
            isNull: (v: null) => v === null || `${v}`.trim().length === 0,
        }
    },

    /**
     * Transform type='date' field to JavaScript Date
     */
    date: () => {
        return {
            value: (v: string | number | Date) => v && new Date(v),
        }
    },

    /**
     * Transform type='date' field to JavaScript Date with a precision property
     */
    preciseDate: () => {
        return {
            value: (v: { date: string | number | Date; precision: string }) =>
                v && {
                    date: v.date ? new Date(v.date) : null,
                    precision: v.precision ? v.precision.toUpperCase() : null,
                },
            isNull: (v: { date: any; precision: any }) => !v || !v.date || !v.precision,
        }
    },

    /**
     * Transform type='text' field to a Javascript Array
     */
    split: (seperator: any = null) => {
        seperator = seperator || ','

        return {
            value: (v: string) => v && v.split(seperator),
        }
    },

    /**
     * Transform address field with hardcoded country
     */
    addressWithCountryNL: () => {
        return {
            isNull: (v: { nl: any }) => !v || isNLAddressEmpty(v.nl),
            value: (v: {} | undefined) =>
                v &&
                transformFormFields(
                    {
                        ...v,
                        country: 'NL',
                        other: null,
                    },
                    {
                        nl: lib.addressNL(),
                        phoneNumbers: lib.phoneNumbers(),
                    }
                ),
        }
    },

    /**
     * Transform address field
     */
    address: () => {
        return {
            isNull: (v: { nl: any; other: any }) => !v || (isNLAddressEmpty(v.nl) && isOtherAddressEmpty(v.other)),
            value: (v: { country: any } | undefined) =>
                v &&
                transformFormFields(v, {
                    nl: lib.addressNL(),
                    other: {
                        isNull: () => v.country === 'NL',
                        value: lib.addressOther().value,
                    },
                    phoneNumbers: lib.phoneNumbers(),
                }),
        }
    },

    /**
     * Transform addressNL field
     */
    addressNL: () => {
        return {
            value: (v: {} | undefined) =>
                transformFormFields(v, {
                    zipcode: {
                        value: (value: any) => {
                            if (!value) {
                                return value
                            }

                            const match = value.match(/^([1-9][0-9]{3})\s*((?!sa|sd|ss)[a-z]{2})$/i)

                            if (!match) {
                                return value
                            }
                            // Make sure the format is "1001 XX" when sending to the server.
                            return `${match[1]} ${match[2].toUpperCase()}`
                        },
                    },
                    number: lib.int(),
                    manualExtraInfo: { value: (v: {} | undefined) => transformFormFields(v) },
                    validatedExtraInfo: { value: (v: {} | undefined) => transformFormFields(v) },
                }),
        }
    },

    addressOther: () => {
        return {
            value: (v: {} | undefined) => transformFormFields(v),
        }
    },

    /**
     * Transform phoneNumber (single) field
     */
    phoneNumber: () => {
        return {
            isNull: (v: { phoneNumber: any; country: any }) => !v.phoneNumber || !v.country,
        }
    },

    /**
     * Transform phoneNumbers (multi) field
     */
    phoneNumbers: () => {
        return {
            value: (v: any[]) =>
                v &&
                v.filter(p => {
                    return !!p.phoneNumber
                }),
        }
    },
}

// interface Spec {
//     field?: any
//     value?: any
//     fields?: any
//     isNull?: boolean | ((...args: any) => any)
//     compare?: any
// }

interface TransformOutput {
    nulls: string[]
    [key: string]: unknown
}

type SpecsObject = { [key: string]: any }
// type SpecsObject = { [key: string]: Spec | ((value: unknown) => Spec) }

export default function transformFormFields(
    fields: object = {}, // input fields, can be anything that is an object with keys
    specsObject: SpecsObject = {},
    omitFields: string[] | null = null
): TransformOutput {
    const newFields = {}
    const nulls: string[] = []

    const allKeys = uniq([...Object.keys(fields)])

    allKeys.forEach(key => {
        const spec = specsObject[key] || {}
        const field = spec.field || key

        // Determine value
        let value = 'value' in spec ? spec.value : fields[field]
        if (Array.isArray(value) && key === 'country') {
            value = value[0]
        }
        if ('fields' in spec) {
            value = spec.fields
        }
        if (typeof value === 'function' && (!('isNull' in spec) || !spec.isNull || typeof spec.isNull === 'function')) {
            value = value(fields[field])
        }

        // Determine isNull
        let isNull = 'isNull' in spec ? spec.isNull : defaultIsNullFunction
        if (typeof isNull === 'function') {
            isNull = isNull(value)
        }

        if (!('compare' in spec) || !spec.compare(value)) {
            if (isNull) {
                nulls.push(key)
                newFields[key] = null
            } else {
                newFields[key] = value
            }
        }
    })

    const output = {
        ...newFields,
        nulls,
    }

    if (omitFields) {
        return omit(output, omitFields)
    }

    return output
}

export function defaultIsNullFunction(value: any): boolean {
    console.log('defaultIsNullFunction', value)
    return typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim().length === 0)
}

const isNLAddressEmpty = (nlAddress: any): boolean => {
    const response =
        !nlAddress ||
        (!nlAddress.zipcode &&
            !nlAddress.number &&
            !nlAddress.numberAddition &&
            (!nlAddress.manualExtraInfo ||
                (!nlAddress.manualExtraInfo.street &&
                    !nlAddress.manualExtraInfo.city &&
                    !nlAddress.manualExtraInfo.municipality)) &&
            (!nlAddress.validatedExtraInfo ||
                (!nlAddress.validatedExtraInfo.street &&
                    !nlAddress.validatedExtraInfo.city &&
                    !nlAddress.validatedExtraInfo.municipality)))

    console.log('nlAddress', nlAddress)
    console.log('isNLAddressEmpty', response)
    return response
}

const isOtherAddressEmpty = (otherAddress: any): boolean => {
    return !otherAddress || (!otherAddress.addressLine1 && !otherAddress.addressLine2)
}
