import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { TagPicker } from '~/components/TagPicker'
import { getCurrentUser } from '~/services/session'
import { get, find } from 'lodash'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import Input from '~/components/Input'

interface Props {
    user: User
    errors?: GraphQLErrors
    loading: boolean
}

export class UserLearnerOrganizationContactOrganizationFieldGroup extends React.Component<Props> {
    public render() {
        const { errors, loading, user } = this.props

        const contextUser = getCurrentUser()
        const contextUserOrganization = contextUser.organizationContact.organization
        const contextUserCollegues = contextUserOrganization.contactUsers || []
        const organizations = get(user, 'learner.organizations') // as UserLearnerOrganization[]
        const selectedOrganization = find(
            organizations,
            org => org.organization && org.organization._id === contextUserOrganization._id
        )
        const [defaultOrganization] = get(user, 'learner.organizations')
        const options = contextUserCollegues.map((cu: User) => ({ value: cu._id, label: cu.profile.name }))

        return (
            <FieldGroup title={`Organisatie`} isForm={true}>
                <LockedFieldWrapper disableLock={!get(defaultOrganization, 'organizationContactUser._id')}>
                    {isLocked => (
                        <React.Fragment>
                            <Field title={`Contactpersoon`} errors={errors}>
                                {!loading && (
                                    <TagPicker
                                        name={`user.learner.organizationContactUserIdBasedOnContextOrganization`}
                                        placeholder={`Bij ${contextUserOrganization.name}`}
                                        defaultValue={get(defaultOrganization, 'organizationContactUser._id')}
                                        isDisabled={isLocked}
                                        multi={false}
                                        options={options}
                                    />
                                )}
                            </Field>
                            <Field title={`Dossiernummer`} errors={errors}>
                                <Input
                                    name={`user.learner.organizationFileNumberBasedOnContextOrganization`}
                                    type="text"
                                    isDisabled={isLocked}
                                    defaultValue={selectedOrganization && selectedOrganization.organizationFileNumber}
                                />
                            </Field>
                        </React.Fragment>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }
}
