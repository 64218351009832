import React from 'react'
import { LANGUAGES } from '~/utils/translateLanguage'

export interface LanguageOptionsData {
    match: string
}

export const languageOptions: {
    value: string
    label: React.ReactNode
    data: LanguageOptionsData
}[] = LANGUAGES.sort((a, b) => {
    if (a.dutchName < b.dutchName) {
        return -1
    }

    if (a.dutchName > b.dutchName) {
        return 1
    }

    return 0
}).map(language => ({
    value: language.code,
    label: <span>{language.dutchName}</span>,
    data: {
        match: language.dutchName,
    },
}))
