import React from 'react'
import { translateCountry, array2list } from '~/utils'
import { Address } from '~/types/User'

interface Props {
    address?: Address
    country?: string | string[]
}

export default class ReadableAddressCountry extends React.Component<Props> {
    public render() {
        const { address, country } = this.props
        let countryCode = country || (address && address.country)

        if (!countryCode) {
            return <span />
        }

        if (!Array.isArray(countryCode)) {
            countryCode = [countryCode]
        }

        if (countryCode.length > 0) {
            return array2list(
                countryCode.map(code => {
                    return `${translateCountry(code)}`.trim()
                })
            )
        }

        return <span />
    }
}
