import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindAll, capitalize } from 'lodash'
import { Mutator } from '~/utils'
import { DrawerModal } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { EditGroupLessonDaysForm } from '~/forms'

export default class GroupLessonDatesFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        groupEditMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = [
        'general-week-lesson-dates-required',
        'general-week-lesson-dates-must-match-module-settings',
    ]

    constructor(props) {
        super(props)
        bindAll(this, ['removeLessonDates', 'getEditModal'])
    }

    async removeLessonDates() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                nulls: ['generalWeekLessonDates'],
            },
        })

        refetchGroup()
    }

    render() {
        const { validationErrors } = this.props

        return (
            <GroupFieldGroup
                title="Lesdag(en)"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeLessonDates}
                removeMessage={`Je staat op het punt de lesdag(en) uit deze groep te verwijderen. Weet je het zeker?`}
            >
                {this.renderLessonDatesList()}
            </GroupFieldGroup>
        )
    }

    renderLessonDatesList() {
        const { group } = this.props
        const { generalWeekLessonDates } = group

        const dates = generalWeekLessonDates.map(d => new Date(d))

        return (
            <ul>
                {dates.map(date => (
                    <li key={date.getTime()}>{this.renderDate(date)}</li>
                ))}
            </ul>
        )
    }

    renderDate(date) {
        const { group } = this.props
        const { module } = group

        const fromMoment = moment(date)
        const toMoment = module && module.lessonDuration && moment(date).add(module.lessonDuration, 'minutes')

        return `${capitalize(fromMoment.format('dddd HH:mm'))}${toMoment ? ` - ${toMoment.format('HH:mm')}` : ``}`
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <EditGroupLessonDaysForm
                    type={`modal`}
                    onSubmitSuccess={() => {
                        closeModal()
                        refetchGroup()
                    }}
                    onFormCancel={closeModal}
                    group={group}
                />
            </DrawerModal>
        )
    }
}
