import React from 'react'

import { ClassValue } from '~/services/BEMService'
import CenterModal from '../CenterModal'
import Form from '../Form'
import Field from '../Field'
import { FieldCollection, Button, DrawerModal } from '..'
import { FieldGroup } from '../FieldGroup'
import { TagPicker } from '../TagPicker'
import { FieldCollectionFooter } from '../FieldCollectionFooter'
import { List } from '../List'
import { ListItem } from '../ListItem'
import DatePicker from '~/components/DatePicker'
import { LearnerEnrollmentType } from '~/types/User'
import { ErrorLabel } from '../ErrorLabel/ErrorLabel'
import { ModalManager } from '../ModalManager'
import { LearnersTagPicker } from '../users/Learner/LearnersTagPicker'
import { gql } from '@apollo/client'
import { Mutator } from '~/utils'
import { NewLearnerForm } from '../users/Learner/NewLearnerForm'
import { translateType } from '~/shared/utils'

const EDIT_USER_MUTATION = gql`
    mutation _($learnerUserId: MongoID!, $enrollmentType: LearnerEnrollmentTypeEnum, $enrollmentDate: Date) {
        users_updateEnrollmentData(
            learnerUserId: $learnerUserId
            enrollmentType: $enrollmentType
            enrollmentDate: $enrollmentDate
        ) {
            _id
        }
    }
`

interface Props {
    className?: ClassValue
    isStatusbar?: boolean
    userId?: string
    type?: LearnerEnrollmentType
    date?: Date
    onClose: () => void
}

interface State {
    selectedCandidate: string | undefined
    type: string | undefined
    date: any
    hasInflowMoment: boolean
    isAvailableForInflow: boolean
    inflowLocation: string
    inflowModule: string
    inflowDate: string
}

export class AddCandidateToInflowModal extends React.Component<Props, State> {
    public state: State = {
        selectedCandidate: undefined,
        type: undefined,
        date: undefined,
        hasInflowMoment: false,
        isAvailableForInflow: false,
        inflowLocation: '',
        inflowModule: '',
        inflowDate: '',
    }

    private userMutator: Mutator = new Mutator({
        mutation: EDIT_USER_MUTATION,
        reactComponentToUpdate: this,
    })

    public componentDidMount() {
        const { isStatusbar, userId } = this.props

        if (isStatusbar && userId) {
            this.setState({
                selectedCandidate: this.props.userId,
            })
        }

        if (this.props.type) {
            this.setState({
                type: this.props.type,
            })
        }

        if (this.props.date) {
            this.setState({
                date: this.props.date,
            })
        }
    }

    public render() {
        const { onClose, isStatusbar } = this.props

        return (
            <CenterModal
                onClose={onClose}
                title={isStatusbar ? 'Aanvraag instroommoment bewerken' : 'Kandidaat toevoegen'}
            >
                <ModalManager
                    render={openModal => this.renderForm(openModal)}
                    getModal={closeModal => {
                        return (
                            <DrawerModal onClose={closeModal}>
                                <NewLearnerForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                            </DrawerModal>
                        )
                    }}
                />
            </CenterModal>
        )
    }

    private renderForm = (openModal: Function) => {
        const { onClose, userId, isStatusbar } = this.props
        const {
            selectedCandidate,
            type,
            date,
            hasInflowMoment,
            isAvailableForInflow,
            inflowLocation,
            inflowModule,
            inflowDate,
        } = this.state
        const { loading: mutationLoading } = this.userMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        {!userId && (
                            <Field isLabel={true} title={'Kandidaat'}>
                                <LearnersTagPicker
                                    name={'learnersTagPicker'}
                                    onChangeHandler={this.handleCandidateChange}
                                    creatableHandler={() => openModal()}
                                />
                            </Field>
                        )}

                        {selectedCandidate && !hasInflowMoment && !isAvailableForInflow && (
                            <>
                                {userId ? (
                                    this.renderEnrollmentTypeAndDateFields()
                                ) : (
                                    <FieldGroup isInsetGroup={true}>
                                        {this.renderEnrollmentTypeAndDateFields()}
                                    </FieldGroup>
                                )}
                            </>
                        )}

                        {hasInflowMoment || isAvailableForInflow ? (
                            <Field isLabel={true} title={true}>
                                {hasInflowMoment && !isAvailableForInflow && (
                                    <ErrorLabel isErrorMessage={true}>
                                        Kandidaat zit al in instroommoment {inflowModule} op {inflowDate},{' '}
                                        {inflowLocation}
                                    </ErrorLabel>
                                )}
                                {isAvailableForInflow && (
                                    <ErrorLabel isErrorMessage={true}>
                                        Kandidaat zit al in de kandidatenlijst voor instroom
                                    </ErrorLabel>
                                )}
                            </Field>
                        ) : null}
                    </FieldGroup>

                    <FieldCollectionFooter>
                        <List horizontal>
                            {isStatusbar && (
                                <ListItem>
                                    <Button linkStyle={'danger'} isLoading={mutationLoading} onClick={this.onDelete}>
                                        Verwijderen
                                    </Button>
                                </ListItem>
                            )}
                            <ListItem right>
                                <Button onClick={onClose}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={mutationLoading}
                                    isDisabled={
                                        selectedCandidate === undefined || type === undefined || date === undefined
                                    }
                                >
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderEnrollmentTypeAndDateFields() {
        return (
            <>
                <Field isLabel={true} title={'Type aanmelding'}>
                    <TagPicker
                        name={`user.learner.enrollmentType`}
                        multi={false}
                        isClearable={true}
                        placeholder={'Selecteer een type...'}
                        defaultValue={this.props.type}
                        options={[LearnerEnrollmentType.Intake, LearnerEnrollmentType.Assessment].map(option => ({
                            value: option,
                            label: translateType('enrollmentType', option),
                        }))}
                        onChange={this.handleTypeChange}
                    />
                </Field>
                <Field isLabel={true} title={'Aangemeld op'}>
                    <DatePicker
                        defaultValue={this.props.date && this.props.date}
                        hasIcon={true}
                        name={`user.learner.enrollmentDate`}
                        onChange={this.handleDateChange}
                    />
                </Field>
            </>
        )
    }

    private onSubmit = async () => {
        const { type, date } = this.state
        await this.updateUserEnrollment({ enrollmentType: type, enrollmentDate: date })
    }

    private onDelete = async () => {
        await this.updateUserEnrollment({ enrollmentType: null, enrollmentDate: null })
    }

    private updateUserEnrollment = async (enrollment: {
        enrollmentType?: string | null
        enrollmentDate?: string | null
    }) => {
        const { selectedCandidate } = this.state
        const { onClose } = this.props
        const data = await this.userMutator.mutate({
            learnerUserId: selectedCandidate,
            ...enrollment,
        })

        if (data) {
            onClose()
        } else {
            // tslint:disable-next-line:no-console
            console.log('something went wrong')
        }

        return null
    }

    private handleCandidateChange = (value: any) => {
        if (value === null) {
            this.setState({ selectedCandidate: undefined })
        } else {
            this.setState({
                selectedCandidate: value.value,
            })
        }

        // FROM MOET NA ENROLLMENTDATE
        if (
            value === null ||
            value.hasInflowMomentsAfterEnrollmentDate === null ||
            value.inflowMoments === null ||
            !value.inflowMoments.length
        ) {
            this.setState({ hasInflowMoment: false })
        } else {
            if (value.enrollmentDate < value.inflowMoments[value.inflowMoments.length - 1].dateRange.from) {
                this.setState({
                    hasInflowMoment: value.hasInflowMomentsAfterEnrollmentDate,
                    inflowLocation: value.inflowMoments[value.inflowMoments.length - 1].location.name,
                    inflowModule: value.inflowMoments[value.inflowMoments.length - 1].inflowModule.name,
                    inflowDate: value.inflowMoments[value.inflowMoments.length - 1].dateRange.from.slice(0, 10),
                })
            }
        }

        if (value === null || value.isAvailableForInflow === null) {
            this.setState({ isAvailableForInflow: false })
        } else {
            this.setState({
                isAvailableForInflow: value.isAvailableForInflow,
            })
        }
    }

    private handleTypeChange = (value: any) => {
        if (value === null) {
            this.setState({ type: undefined })
        } else {
            this.setState({ type: value.value })
        }
    }

    private handleDateChange = (event: any) => {
        if (event === null) {
            this.setState({ date: undefined })
        } else {
            const date = new Date(event.target.value)
            this.setState({ date: date })
        }
    }
}
