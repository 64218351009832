import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { View } from '~/components/View'
import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import Fetcher from '~/utils/Fetcher'
import { gql } from '@apollo/client'

interface Props extends RouteComponentProps<{ id: string }, {}> {
    children: any
}

const GET_COLLEAGUE_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            profile {
                name
            }
        }
    }
`

export class AppOrganizationContactColleaguesDetail extends React.Component<Props> {
    private colleagueFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.colleagueFetcher = new Fetcher({
            query: GET_COLLEAGUE_QUERY,
            variables: {
                filters: {
                    byId: props.params.id,
                },
            },
            onChange: () => this.forceUpdate(),
        } as any)
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { params, router, route } = routeProps
        const { id: userId } = params
        const { loading, data, refetch } = this.colleagueFetcher
        const { users = [] } = data
        const user = users && users[0]

        return (
            <RouteView crumbLabel={user && user.profile.name} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <View>{!loading && React.cloneElement(children, { userId, refetch, route, router })}</View>
            </RouteView>
        )
    }
}
