import * as React from 'react'
import { gql } from '@apollo/client'
import moment from 'moment'
import { capitalize } from 'lodash'
import { browserHistory } from 'react-router'
import { Button, FieldCollection, Field, Link, Subtle, Paragraph, CenterModal, Currency } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { DateBlocker } from '~/components/DateBlocker'

import { EditGroupFreeTextInput, EditGroupNameForm, EditGroupLocationForm, EditGroupModuleForm } from '~/forms'
import { Mutator } from '~/utils'
import { Group } from '~/types/Group'
import { CourseMaterial } from '~/types/Module'

const DELETE_GROUP_MUTATION = gql`
    mutation _($groupId: MongoID!) {
        groups_delete(groupId: $groupId) {
            _id
        }
    }
`

interface Props {
    group: Group
    refetch: () => void
}

export class EmployeeGroupInfo extends React.Component<Props> {
    private deleteGroupMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.deleteGroupMutator = new Mutator({
            mutation: DELETE_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { group, refetch } = this.props

        const startWeekDate = group.startWeekDate
        const dateBlockers = group.dateBlockers || []
        const startDate = group.dateFrom && moment(group.dateFrom)
        const endDate = group.dateTo && moment(group.dateTo)
        const courseMaterials = group.module && group.module.courseMaterials

        return (
            <View>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup hasInlineEdit={true} title={`Groepsnaam`}>
                            <Field>{group.name}</Field>
                            <Field inlineEdit>
                                {!group.endedAt && (
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal} type={`edit`}>
                                                Bewerken
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Groep bewerken`}>
                                                <EditGroupNameForm
                                                    group={group}
                                                    onCancel={closeModal}
                                                    onSubmitSuccess={closeModal}
                                                    refetch={refetch}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup hasInlineEdit={group.isLegacyGroup} title={`Lesmodule`} separateFields={true}>
                            <Field>
                                {group.module && (
                                    <div>
                                        <SimpleTable>
                                            {group.project && (
                                                <SimpleTableRow>
                                                    <SimpleTableCell>Project</SimpleTableCell>
                                                    <SimpleTableCell>
                                                        <Link route={`/properties/projects/${group.project._id}`}>
                                                            {group.project.name}
                                                        </Link>
                                                    </SimpleTableCell>
                                                </SimpleTableRow>
                                            )}
                                            {group.program && (
                                                <SimpleTableRow>
                                                    <SimpleTableCell>Opleiding</SimpleTableCell>
                                                    <SimpleTableCell>
                                                        <Link route={`/properties/programs/${group.program._id}`}>
                                                            {group.program.name}
                                                        </Link>
                                                    </SimpleTableCell>
                                                </SimpleTableRow>
                                            )}
                                            <SimpleTableRow>
                                                <SimpleTableCell>Lesmodule</SimpleTableCell>
                                                <SimpleTableCell>
                                                    <Link route={`/properties/modules/${group.module._id}`}>
                                                        {group.module.name}
                                                    </Link>
                                                </SimpleTableCell>
                                            </SimpleTableRow>
                                        </SimpleTable>
                                        <Subtle>{this.renderModuleInformation()}</Subtle>
                                    </div>
                                )}
                            </Field>
                            {courseMaterials && this.renderCourseMaterials(courseMaterials)}
                            {group.isLegacyGroup && (
                                <Field inlineEdit={true}>
                                    {!group.endedAt && (
                                        <ModalManager
                                            render={openModal => (
                                                <Button onClick={openModal} type={`edit`}>
                                                    Bewerken
                                                </Button>
                                            )}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Lesmodule bewerken`}>
                                                    <EditGroupModuleForm
                                                        group={group}
                                                        onCancel={closeModal}
                                                        onSubmitSuccess={closeModal}
                                                        refetch={refetch}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    )}
                                </Field>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Lesdag(en)`}>
                            <Field>{this.renderLessonDatesList()}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Startweek`}>
                            {startWeekDate && (
                                <Field>
                                    {/* tslint:disable-next-line:max-line-length */}
                                    Week {moment(startWeekDate).week()}{' '}
                                    {startDate &&
                                        endDate &&
                                        `(${startDate.format('DD-MM-YYYY')} t/m ${endDate.format('DD-MM-YYYY')})`}
                                    <br />
                                    {endDate && <Subtle>Eindigt in week {endDate.week()}</Subtle>}
                                </Field>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Vakantieperiode(s)`}>
                            <Field>
                                {dateBlockers.length === 0 && 'Geen'}
                                {dateBlockers.length > 0 && (
                                    <SimpleTable>
                                        {dateBlockers.map((dateBlocker, i) => (
                                            <SimpleTableRow key={i}>
                                                <SimpleTableCell isBold={true}>Periode {i + 1}</SimpleTableCell>
                                                <SimpleTableCell>
                                                    <DateBlocker range={dateBlocker} />
                                                </SimpleTableCell>
                                            </SimpleTableRow>
                                        ))}
                                    </SimpleTable>
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup hasInlineEdit={true} title="Locatie">
                            {group.location && (
                                <Field>
                                    <Link route={`/properties/locations/${group.location._id}`}>
                                        {group.location.name}
                                    </Link>
                                </Field>
                            )}
                            <Field inlineEdit={true}>
                                {!group.endedAt && (
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal} type={`edit`}>
                                                Bewerken
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Locatie bewerken`}>
                                                <EditGroupLocationForm
                                                    hideRoomField={true}
                                                    onSubmitSuccess={closeModal}
                                                    onCancel={closeModal}
                                                    refetch={refetch}
                                                    group={group}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup hasInlineEdit={true} title="Vrij tekstveld">
                            {group.localizedExtraLearnerInformation && (
                                <Field>
                                    <Paragraph>
                                        <Subtle>Nederlands</Subtle>
                                        <br />
                                        {group.localizedExtraLearnerInformation.nl
                                            ? group.localizedExtraLearnerInformation.nl
                                            : '-'}
                                    </Paragraph>
                                    <br />
                                    <Paragraph>
                                        <Subtle>Engels</Subtle>
                                        <br />
                                        {group.localizedExtraLearnerInformation.en
                                            ? group.localizedExtraLearnerInformation.en
                                            : '-'}
                                    </Paragraph>
                                </Field>
                            )}
                            <Field inlineEdit={true}>
                                {!group.endedAt && (
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal} type={`edit`}>
                                                Bewerken
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Locatie bewerken`}>
                                                <EditGroupFreeTextInput
                                                    onSubmitSuccess={() => {
                                                        refetch()
                                                        closeModal()
                                                    }}
                                                    onCancel={closeModal}
                                                    group={group}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                {group.canBeDeleted && (
                                    <ListItem>
                                        <Button
                                            onClick={this.onDeleteClick}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Groep verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Groep verwijderen
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </View>
        )
    }

    private renderLessonDatesList = () => {
        const { group } = this.props
        const generalWeekLessonDates = group.generalWeekLessonDates || []

        const dates = generalWeekLessonDates.map(d => new Date(d))

        return (
            <ul>
                {dates.map(date => (
                    <li key={date.getTime()}>{this.renderDate(date)}</li>
                ))}
            </ul>
        )
    }

    private renderCourseMaterials = (courseMaterials: CourseMaterial[]) => {
        return (
            <Field>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell>Lesmaterialen</SimpleTableCell>
                        <SimpleTableCell>
                            {courseMaterials.map(courseMaterial => {
                                const { _id, name, amount } = courseMaterial
                                const hasAmountValue = typeof amount === 'number'

                                return (
                                    <div key={_id}>
                                        <Link route={`/properties/coursematerials/${_id}`}>
                                            {name}
                                            <Subtle asSuffix={true}>
                                                {hasAmountValue && <Currency amount={amount} />}
                                            </Subtle>
                                        </Link>
                                    </div>
                                )
                            })}
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </Field>
        )
    }

    private renderDate = (date: Date) => {
        const { group } = this.props
        const { module } = group

        const fromMoment = moment(date)
        const toMoment = module && module.lessonDuration && moment(date).add(module.lessonDuration, 'minutes')

        return `${capitalize(fromMoment.format('dddd HH:mm'))}${toMoment ? ` - ${toMoment.format('HH:mm')}` : ``}`
    }

    private renderModuleInformation = () => {
        const { group } = this.props
        const { module } = group

        if (!module) {
            return null
        }

        return [
            `${module.amountOfTotalLessons || 0} ${module.amountOfTotalLessons === 1 ? 'les' : 'lessen'}`,
            `${module.amountOfWeeklyLessons || 0} keer per week`,
            `${module.lessonDuration || 0} ${module.amountOfTotalLessons === 1 ? 'minuut' : 'minuten'}`,
        ].join(' / ')
    }

    private onDeleteClick = async () => {
        const { group } = this.props

        const res = await this.deleteGroupMutator.mutate({
            groupId: group._id,
        })

        if (res) {
            browserHistory.push('/groups')
        }
    }
}
