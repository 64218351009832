import React from 'react'
import { gql } from '@apollo/client'
import { Fetcher, Mutator, transformFormFields } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { InflowMoment } from '~/types/InflowMoments'
import { Form, FieldCollection, Button, Field, CheckBox } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Location } from '~/types/Location'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { ValidationError } from '~/components/ValidationError'

interface Props {
    onClose: () => void
    inflowMoment?: InflowMoment
    refetch: () => void
}

interface State {
    selectedLocationId: string | null
    roomsOrganized: boolean
    locationHasRooms: boolean
}

const GET_LOCATIONS_QUERY = gql`
    query _ {
        locations(sortBy: "name") {
            _id
            name
            rooms(sortBy: "name") {
                _id
                name
            }
        }
    }
`

export class InflowLocationForm extends React.Component<Props, State> {
    public state: State = {
        selectedLocationId: null,
        roomsOrganized: false,
        locationHasRooms: false,
    }

    private locationsFetcher: Fetcher
    private inflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { inflowMoment } = props

        if (inflowMoment) {
            if (inflowMoment.location) {
                this.state.selectedLocationId = inflowMoment.location._id
                this.state.locationHasRooms = this.locationHasRooms(inflowMoment.location)
            }

            this.state.roomsOrganized = !!inflowMoment.locationRoomsOrganized
        }

        this.locationsFetcher = new Fetcher({
            query: GET_LOCATIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.inflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose, inflowMoment } = this.props
        const { selectedLocationId } = this.state
        const { data } = this.locationsFetcher
        const { loading } = this.inflowMomentMutator
        const { locations = [] } = data
        const { roomsOrganized, locationHasRooms } = this.state

        const loationOptions = locations.map((location: Location) => ({ value: location._id, label: location.name }))

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={'Locatie'}>
                            <TagPicker
                                multi={false}
                                name={`inflowMoment.locationId`}
                                options={loationOptions}
                                defaultValue={inflowMoment && inflowMoment.location ? inflowMoment.location._id : null}
                                onChange={this.checkLocationRooms}
                                placeholder={'Selecteer locatie'}
                            />
                            {selectedLocationId && !locationHasRooms && (
                                <ValidationError
                                    isWarning
                                    // tslint:disable-next-line:max-line-length
                                    text={`Locatie is ook de lesruimte`}
                                />
                            )}
                        </Field>
                        {selectedLocationId && locationHasRooms && (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={'Specificaties'}>
                                    <CheckBox
                                        name={`inflowMoment.locationRoomsOrganized`}
                                        value={true}
                                        checked={roomsOrganized}
                                        onClick={this.onToggleRoomsOrganizedCheckbox}
                                    >
                                        Toetsruimtes zijn georganiseerd op locatie
                                    </CheckBox>
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onClose}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onClose, refetch, inflowMoment } = this.props

        this.inflowMomentMutator
            .mutate({
                inflowMoment: transformFormFields(
                    {
                        ...fields.inflowMoment,
                        locationRoomsOrganized: this.state.roomsOrganized,
                        _id: inflowMoment ? inflowMoment._id : undefined,
                    },
                    {}
                ),
            })
            .then(data => {
                if (data) {
                    onClose()

                    if (refetch) {
                        refetch()
                    }
                }
            })
    }

    private onToggleRoomsOrganizedCheckbox = () => {
        this.setState({
            roomsOrganized: !this.state.roomsOrganized,
        })
    }

    private checkLocationRooms = (option: TagPickerOption | null) => {
        if (!option) {
            this.setState({
                selectedLocationId: null,
                locationHasRooms: false,
            })

            return
        }

        const locationId = option.value
        const { data } = this.locationsFetcher
        const { locations = [] } = data

        const location = locations.find((location: Location) => location._id === locationId) as Location

        this.setState({
            selectedLocationId: locationId,
            locationHasRooms: this.locationHasRooms(location),
        })
    }

    private locationHasRooms = (location: Location): boolean => {
        return !!location.rooms && location.rooms.length > 0
    }
}
