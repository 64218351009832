import React from 'react'
import { browserHistory } from 'react-router'

import { Brand, Icon, Link, Version, Button } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { logOut } from '~/services/session'
import { User } from '~/types/User'
import { ClassValue, BEM } from '~/services/BEMService'
import ExpandableMenu from './ExpandableMenu'
import { ModalManager } from './ModalManager'
import { InstructionVideosModal } from '~/modals'

interface Props {
    user: User
    className?: ClassValue
}

export default class UserMenu extends React.Component<Props> {
    private bem = new BEM('UserMenu')

    public render() {
        const { user, className } = this.props
        const url = this.getProfileUrl()

        return (
            <List className={this.bem.getClassName(className)} horizontal={true}>
                {user.isEmployee /* Only employees can see the version */ && (
                    <ListItem className={this.bem.getElement('version')}>
                        <Version />
                    </ListItem>
                )}
                <ListItem>
                    <ExpandableMenu
                        user={user}
                        renderContent={toggle => (
                            <>
                                <li>
                                    <Link route={url} onClick={toggle}>
                                        Profiel
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={this.onLogoutClick}>Uitloggen</Link>
                                </li>
                            </>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <List className={this.bem.getElement('icon-list')} horizontal={true}>
                        <ListItem>
                            <ModalManager
                                render={openModal => (
                                    <Button className={this.bem.getElement('logout-button')} onClick={openModal}>
                                        <Icon name={`Instructievideo`} />
                                    </Button>
                                )}
                                getModal={closeModal => {
                                    return <InstructionVideosModal user={user} onClose={closeModal} />
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <Button className={this.bem.getElement('logout-button')} onClick={this.onLogoutClick}>
                                <Icon name={`logout`} />
                            </Button>
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    <Brand isForNavBar={true}>TopTaal</Brand>
                </ListItem>
            </List>
        )
    }

    private getProfileUrl = (): string | undefined => {
        const { user } = this.props

        if (user) {
            if (user.isEmployee) {
                return `/users/employees/${user._id}`
            }

            if (user.isExternalTeacher) {
                return `/profile`
            }

            if (user.isOrganizationContact) {
                return `/profile`
            }
        }

        return '/404'
    }

    private onLogoutClick = async () => {
        const logOutValue = await logOut()
        if (logOutValue === true) {
            browserHistory.push('/login')
        }
    }
}
