import { gql } from '@apollo/client'
import { get } from 'lodash'
import moment from 'moment'

import env from '~/services/env'
import { Fetcher } from '~/utils'

import apolloClient from './apolloClient'
import { Subscriber } from '~/services/SubscriptionService'
import Amplify from '@aws-amplify/core'

const RENEW_THRESHOLD_MINUTES = 0.75 * (env.production ? 60 : 60 * 1)

export const loginSubscriber = new Subscriber()

// Technical debt: should be a TS file
// Technical debt: should be a class

let currentUser: any = null

/**
 * Get the current user
 */
export function getCurrentUser() {
    return currentUser
}

/**
 * Whether the viewer has a given role (or one of given rolses)
 *
 * @param {UserRole | UserRole[]} roleOrRoles
 *
 * @returns {boolean}
 */
export function viewerHasRole(roleOrRoles: any) {
    const currentUser = getCurrentUser()
    const roles = (currentUser && currentUser.roles) || []

    const matchRoles = Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles]

    return matchRoles.some(r => roles.includes(r))
}

/**
 * Fetch the current user
 */
export async function fetchCurrentUser({ preventRedirectWhenUnauthenticated = false } = {}) {
    const userFetcher = new Fetcher({
        query: gql`
            query _ {
                user {
                    _id
                    email
                    roles
                    isEmployee
                    isAdmin
                    isExternalTeacher
                    isIntaker
                    isOrganizationContact
                    isFinancial
                    passwordResetRequired
                    profile {
                        name
                        nameAbbreviation
                        firstName
                        fullSurName
                        isMfaConfigured
                        cognitoUserId
                        mfaMobileNumber
                    }
                    organizationContact {
                        hasAcceptedAgreement
                        organization {
                            _id
                            name
                            contactUsers {
                                _id
                                profile {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `,
        preventInitialFetch: true,
        preventErrorToast: true,
        preventRedirectWhenUnauthenticated: preventRedirectWhenUnauthenticated,
    })

    const data = await userFetcher.fetch({})
    if (data) {
        const user = get(data, 'user') || null

        // update the currentUser for convenience
        currentUser = user

        return user
    } else {
        return null
    }
}

/**
 * Log in with email and password
 */
export async function logIn(email: string, jwtToken: string) {
    const result: any = await apolloClient.mutate({
        mutation: gql`
            mutation _($email: String, $jwtToken: String) {
                sessions_login(email: $email, jwtToken: $jwtToken) {
                    _id
                    token
                    expiresAt
                }
            }
        `,
        variables: {
            email: email,
            jwtToken: jwtToken,
        },
    })
    if (result.data && result.data.sessions_login) {
        await setSession(result.data.sessions_login)
        loginSubscriber.publish()
        return true
    } else {
        return false
    }
}

/**
 * Log in with email and password
 */
export async function logOut() {
    removeSession()
    const result: any = await apolloClient.mutate({
        mutation: gql`
            mutation logout {
                sessions_logout
            }
        `,
    })
    if (result.data && result.data.sessions_logout) {
        await apolloClient.resetStore()
        return true
    } else {
        return false
    }
}

/**
 * Forget the session
 */
export function removeSession() {
    // temp solution as somewhere remove session being called without having a session yet and causing amplify to signout
    const localStorageSessionToken = window.localStorage.getItem('session:token')
    window.localStorage.removeItem('session:token')
    window.localStorage.removeItem('session:expiresAt')
    currentUser = null
    if (localStorageSessionToken) {
        Amplify.Auth.signOut().catch(console.error)
    }

    return true
}

/**
 * Remember the session
 */
export async function setSession(session: any) {
    window.localStorage.setItem('session:token', session.token)
    window.localStorage.setItem('session:expiresAt', session.expiresAt)
    // to renew cognito jwt token in case expired
    await Amplify.Auth.currentSession()
    currentUser = fetchCurrentUser()
}

/**
 * Get the session
 */
export function getSession() {
    const nowMoment = moment()
    const token = window.localStorage.getItem('session:token')
    const expiresAt = window.localStorage.getItem('session:expiresAt')
    const expired = !expiresAt || moment(expiresAt).isBefore(nowMoment)

    /**
     * This is a temporary way to bypass login in local development.
     * This relies on the server also bypasses this token,
     * which can be done by providing this server environment variable
     * to set the id of user you want to log in with: LOCAL_DEVELOPMENT_LOGIN_BYPASS_USER_ID
     */
    if (env.development) {
        const expiresAt = new Date()
        expiresAt.setFullYear(expiresAt.getFullYear() + 1)

        return {
            token: 'mock',
            expiresAt,
        }
    }

    if (!token || expired) {
        removeSession()
        return null
    }

    return {
        token,
        expiresAt,
    }
}

/**
 * Whether the user is logged in (for sure)
 */
export async function isLoggedIn() {
    if (!getSession()) {
        return false
    }

    const userFetcher = new Fetcher({
        query: gql`
            query _ {
                user {
                    _id
                }
            }
        `,
        preventInitialFetch: true,
        preventErrorToast: true,
        preventRedirectWhenUnauthenticated: true,
    })

    const data = await userFetcher.fetch({})
    const user = get(data, 'user') || null

    return !!user
}

/**
 * Check if the session will expire soon,
 * then renew the session
 */
export async function renewIfExpirationDateIsSoon() {
    const session = getSession()
    if (!session) {
        return null
    }

    const nowMoment = moment()
    const renewAtMoment = moment(session.expiresAt).subtract(RENEW_THRESHOLD_MINUTES, 'minutes')
    const willSoonExpire = renewAtMoment.isBefore(nowMoment)

    if (willSoonExpire) {
        return renewSession()
    } else {
        return null
    }
}

/**
 * Renew the session
 */
let isCurrentlyRenewing = false
export function renewSession() {
    if (isCurrentlyRenewing) {
        return
    }

    isCurrentlyRenewing = true

    const promise = apolloClient
        .mutate({
            mutation: gql`
                mutation renew {
                    sessions_renew {
                        token
                        expiresAt
                    }
                }
            `,
        })
        .then((res: any) => res.data.sessions_renew)
        .then(
            async session => {
                await setSession(session)
                isCurrentlyRenewing = false
            },
            async error => {
                isCurrentlyRenewing = false
                await removeSession()
                console.error('Could not renew token', error)
            }
        )

    return promise
}
