import { PhoneNumberUtil } from 'google-libphonenumber'
import * as countries from './countries'

const phoneUtil = PhoneNumberUtil.getInstance()

/**
 * google-phonenumberlib seem to return "0" as country calling code for these countries,
 * while the E.123 and E.164 standards of UTI-T do have country calling codes for them.
 */
const CALLING_CODE_OVERRIDES = {
    AQ: 672,
    BV: 47,
    TF: 262,
    HM: 672,
    PN: 64,
    GS: 500,
    UM: 1,
}

export const callingCodeOptions = Object.keys(countries.getAlpha2Codes())
    .map(alpha2 => {
        let callingCode = phoneUtil.getCountryCodeForRegion(alpha2)

        if (Object.keys(CALLING_CODE_OVERRIDES).includes(alpha2)) {
            callingCode = CALLING_CODE_OVERRIDES[alpha2]
        }

        return {
            alpha2,
            callingCode,
            countryName: countries.getName(alpha2.toLowerCase(), 'nl'),
        }
    })
    .sort(function (a, b) {
        if (a.callingCode < b.callingCode) return -1
        if (a.callingCode > b.callingCode) return 1
        return 0
    })
    .map(country => {
        return {
            value: country.alpha2,
            label: `+${country.callingCode} (${country.alpha2})`,
            data: {
                match: `+${country.callingCode} ${country.countryName}`,
            },
        }
    })
