import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Link } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { translateType } from '~/shared/utils'

export default class DataView extends Component {
    static propTypes = {
        instructionVideo: PropTypes.object,
    }

    render() {
        const { instructionVideo } = this.props
        const targetRoles = get(instructionVideo, 'targetRoles') || []

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Naam`}> {get(instructionVideo, 'name')} </Field>
                        </FieldGroup>
                        <FieldGroup>
                            <Field title={`Doelgroep`}>
                                {' '}
                                {targetRoles.map(role => translateType('role', role)).join(', ')}{' '}
                            </Field>
                        </FieldGroup>
                        <FieldGroup>
                            <Field title={`YouTube URL`}>
                                <Link isNative href={get(instructionVideo, 'url')}>
                                    {get(instructionVideo, 'url')}
                                </Link>
                            </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button
                                        onClick={() =>
                                            browserHistory.push(
                                                `/properties/instructionvideos/${instructionVideo._id}/edit`
                                            )
                                        }
                                        type={`edit`}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
