import './InviteTextInput.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { ButtonGroup } from '../../ButtonGroup'
import { Button } from '../../buttons/Button/Button'
import { Icon, TextArea } from '~/components'
import { copyTextToClipboard } from '~/utils/copyToClipboard'
import { toast } from '~/utils'

interface Props {
    className?: ClassValue
    textVariables?: { label: string; value: string }[]
    defaultText?: string
    defaultTextEnglish?: string
    name?: string
    nameEnglish?: string
}

interface State {
    englishActive: boolean
    text: string
    textEnglish: string
    placeholderErrors: string[]
    placeholderErrorsEnglish: string[]
}

export class InviteTextInput extends React.Component<Props, State> {
    public state: State = {
        englishActive: false,
        text: this.props.defaultText || '',
        textEnglish: this.props.defaultTextEnglish || '',
        placeholderErrors: [],
        placeholderErrorsEnglish: [],
    }

    private bem = new BEM('InviteTextInput')

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { className, name, nameEnglish } = this.props
        const { text, textEnglish, englishActive, placeholderErrors, placeholderErrorsEnglish } = this.state

        const errors = englishActive ? placeholderErrorsEnglish : placeholderErrors

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderTextVariables()}
                <ButtonGroup className={this.bem.getElement('language-switch')} joined={true}>
                    <Button
                        type="button"
                        styleOverride={englishActive ? 'looks-disabled' : undefined}
                        onClick={this.onLanguageButtonClick}
                    >
                        Nederlands
                    </Button>
                    <Button
                        type="button"
                        styleOverride={!englishActive ? 'looks-disabled' : undefined}
                        onClick={this.onLanguageButtonClick}
                    >
                        Engels
                    </Button>
                </ButtonGroup>
                <TextArea
                    onChange={this.onTextChange}
                    className={this.bem.getElement('textarea', () => ({ 'is-hidden': englishActive }))}
                    name={name}
                    value={text}
                />
                <TextArea
                    onChange={this.onTextChange}
                    className={this.bem.getElement('textarea', () => ({ 'is-hidden': !englishActive }))}
                    name={nameEnglish}
                    value={textEnglish}
                />
                {errors && errors[0] && (
                    <div className={this.bem.getElement('errors')}>
                        Het lijkt erop dat een placeholder een typfout bevat:
                        <div>
                            {errors.map((error, i) => (
                                <p key={`${i}-placeholder-error`} className={this.bem.getElement('error')}>
                                    [{error}]
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    private onLanguageButtonClick = () => {
        this.setState({
            englishActive: !this.state.englishActive,
        })
    }

    private renderTextVariables() {
        const { textVariables } = this.props
        const { text, textEnglish, englishActive } = this.state

        const activeText = englishActive ? textEnglish : text

        if (!textVariables || !textVariables.length) {
            return null
        }

        return (
            <div className={this.bem.getElement('text-variables')}>
                {textVariables.map((variable, index) => {
                    const isActive = activeText.includes(`[${variable.value}]`)

                    return (
                        <button
                            type="button"
                            onClick={() => this.onTextVariableClick(variable)}
                            key={`${variable.value}-${index}-variable`}
                            className={this.bem.getElement('variable-button', () => ({
                                active: isActive,
                            }))}
                        >
                            {isActive && (
                                <Icon className={this.bem.getElement('variable-button-icon')} name={`success`} />
                            )}
                            {variable.label}
                        </button>
                    )
                })}
            </div>
        )
    }

    private onTextVariableClick = (variable: { label: string; value: string }) => {
        copyTextToClipboard(`[${variable.value}]`, success => this.onTextCopySuccess(variable.label, success))
    }

    private onTextCopySuccess = (name: string, success: boolean) => {
        if (success) {
            toast.success(`${name} is gekopieerd naar klembord`)
        } else {
            toast.warning(`Kopiëren niet gelukt`)
        }
    }

    private onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { englishActive } = this.state

        if (!event.target) {
            return
        }

        this.checkTextForErrors(event.target.value)

        if (englishActive) {
            this.setState({
                textEnglish: event.target.value,
            })

            return
        }

        this.setState({
            text: event.target.value,
        })
    }

    private checkTextForErrors = (text: string) => {
        const { textVariables } = this.props
        const { placeholderErrors, placeholderErrorsEnglish, englishActive } = this.state

        if (!textVariables || !textVariables[0]) {
            return
        }

        const textVariableValues = textVariables.map(variable => variable.value)
        const textKeys = text.match(/[^[\]]+(?=])/g)

        if (!textKeys) {
            this.setState({
                placeholderErrors: englishActive ? placeholderErrors : [],
                placeholderErrorsEnglish: englishActive ? [] : placeholderErrorsEnglish,
            })

            return
        }

        const errors: string[] = []

        textKeys.forEach(key => {
            const matches = textVariableValues.includes(key)

            if (!matches) {
                errors.push(key)
            }
        })

        this.setState({
            placeholderErrors: englishActive ? placeholderErrors : errors,
            placeholderErrorsEnglish: englishActive ? errors : placeholderErrorsEnglish,
        })
    }
}
