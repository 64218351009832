import { gql } from '@apollo/client'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { TableView } from '~/components/TableView'
import { CreateProgramForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { getCurrentUser } from '~/services/session'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

export default class MasterView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: filters => {
                this.programsFetcher.refetch({
                    searchText: filters.searchText,
                })
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.programsFetcher = new Fetcher({
            query: GET_PROGRAMS_QUERY,
            variables: { limit, skip, sortDir, sortBy, searchText: this.filter.getFilters().searchText },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.programsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.programs.length === 0,
                })

                return {
                    programs: removeDuplicateDocuments([...(prevData.programs || []), ...moreData.programs]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.programsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    render() {
        const { loading, data } = this.programsFetcher
        const filters = this.filter.getFilters()
        const { programs = [] } = data
        const { paginator, sorter } = this
        const user = getCurrentUser()

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem>
                                            <Search
                                                isLoading={loading}
                                                onSearch={this.onSearch}
                                                defaultValue={filters.searchText}
                                            />
                                        </ListItem>
                                        {user.isAdmin && (
                                            <ListItem right>
                                                <ModalManager
                                                    render={openModal => (
                                                        <Button onClick={openModal} type={`edit`}>
                                                            Opleiding toevoegen
                                                        </Button>
                                                    )}
                                                    getModal={closeModal => {
                                                        return (
                                                            <CenterModal
                                                                onClose={closeModal}
                                                                title={`Opleiding toevoegen`}
                                                            >
                                                                <CreateProgramForm
                                                                    onSubmitSuccess={closeModal}
                                                                    onCancel={closeModal}
                                                                />
                                                            </CenterModal>
                                                        )
                                                    }}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                )
                            }}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Lesmodules</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={2} isLoading />
                                    </TableRow>
                                ) : programs.length ? (
                                    this.renderProgramRows(programs)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={2}>
                                            <Subtle>Er zijn geen opleidingen gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderProgramRows(programs) {
        return programs.map(program => {
            const modules = program.modules || []
            return (
                <TableRow key={program._id}>
                    <TableCell>
                        <Link route={`/properties/programs/${program._id}`}>{program.name}</Link>
                    </TableCell>
                    <TableCell>
                        {modules
                            .map(m => {
                                return m.name
                            })
                            .join(', ')}
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_PROGRAMS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        programs(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            modules {
                _id
                name
            }
        }
    }
`
