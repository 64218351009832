import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { get, bindAll, capitalize } from 'lodash'
import { translateType } from '~/shared/utils'

import { ReadableDate, Icon, Button } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

import { getTypeConfiguration } from '~/utils/timelineMessage'

export default class TimelineMessage extends Component {
    static propTypes = {
        timelineEvent: PropTypes.object.isRequired,
        bodyContentToRender: PropTypes.any.isRequired,
        author: PropTypes.string,
        isEditable: PropTypes.bool,
        onEdit: PropTypes.func,
        className: PropTypes.string,
    }

    static mininumExpandableHeight = 225

    constructor(props) {
        super(props)

        bindAll(this, ['toggleBodyContent', 'onEdit'])

        this.state = {
            shouldExpand: false,
            isExpanded: false,
        }
    }

    componentDidMount() {
        if (this.contentDiv.offsetHeight >= TimelineMessage.mininumExpandableHeight) {
            this.setState({
                shouldExpand: true,
            })
        }
    }

    onEdit() {
        const { onEdit } = this.props
        if (onEdit) onEdit()
    }

    toggleBodyContent() {
        this.setState({
            isExpanded: !this.state.isExpanded,
        })
    }

    getTargetNames(timelineEvent) {
        const targets = get(timelineEvent, 'owner.targets') || []
        const names = []

        if (targets.includes('GROUP_TEACHER') && targets.includes('LEARNER_INTAKER')) {
            names.push(translateType('timelineEventTargetGroups', 'TEACHER'))
        }

        if (targets.includes('RELATED_ORGANIZATION_CONTACT')) {
            names.push(translateType('timelineEventTargetGroups', 'ORGANIZATION'))
        }

        return names
    }

    render() {
        const { shouldExpand, isExpanded } = this.state
        const { className: extraClassNames, timelineEvent, isEditable, bodyContentToRender, author } = this.props
        const { icon, color } = getTypeConfiguration(timelineEvent.type)
        const className = c('tt-TimelineMessage', extraClassNames, {})

        const bodyContentClasses = c('tt-TimelineMessage__content__body', {
            'tt-TimelineMessage__content__body--expandable': shouldExpand,
            'tt-TimelineMessage__content__body--expanded': shouldExpand && isExpanded,
        })

        const targetNames = this.getTargetNames(timelineEvent)

        return (
            <div className={className}>
                <div className={'tt-TimelineMessage__icon'} style={{ borderColor: color }}>
                    <Icon color={color} name={icon} />
                </div>
                <div className={'tt-TimelineMessage__content'}>
                    <div
                        ref={div => (this.contentDiv = div)}
                        className={bodyContentClasses}
                        onClick={shouldExpand && !isExpanded ? this.toggleBodyContent : null}
                    >
                        {shouldExpand && (
                            <div onClick={this.toggleBodyContent}>
                                <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} />
                            </div>
                        )}

                        {bodyContentToRender}
                    </div>
                    <List className={'tt-TimelineMessage__content__footer'} horizontal>
                        <ListItem>
                            {targetNames.length > 0 && (
                                <Fragment>
                                    {this.renderTarget(targetNames)}
                                    <span className={'tt-TimelineMessage__content__footer__separator'} />
                                </Fragment>
                            )}
                            <ReadableDate date={new Date(timelineEvent.date)} showTime />
                            <span className={'tt-TimelineMessage__content__footer__separator'} />
                            {author}
                        </ListItem>
                        <ListItem right className={'tt-TimelineMessage__content__footer__buttons'}>
                            {shouldExpand && (
                                <Fragment>
                                    <Button onClick={this.toggleBodyContent} linkStyle={['default']}>
                                        {isExpanded ? 'Inklappen' : 'Uitklappen'}
                                    </Button>
                                    {isEditable && (
                                        <span className={'tt-TimelineMessage__content__footer__separator'} />
                                    )}
                                </Fragment>
                            )}
                            {isEditable && (
                                <Button onClick={this.onEdit} linkStyle={['default']}>
                                    Bewerken
                                </Button>
                            )}
                        </ListItem>
                    </List>
                </div>
            </div>
        )
    }

    renderTarget(targetNames) {
        return (
            <span className={'tt-TimelineMessage__content__footer__targets'}>
                <Icon name={`eye`} />
                {capitalize(targetNames.join(', '))}
            </span>
        )
    }
}
