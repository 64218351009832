import { gql } from '@apollo/client'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Field, FieldCollection, Link, ReadablePhoneNumbers, Timeline, TimelineInfo, TimelineWrap } from '~/components'
import { Fetcher, nl2br } from '~/utils'

export default class OrganizationTimeline extends Component {
    static propTypes = {
        timelineEventOwnerType: PropTypes.string.isRequired,
        organization: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        this.timelineEventsFetcher = new Fetcher({
            query: GET_TIMELINE_EVENTS_QUERY,
            variables: {
                organizationId: props.organization._id,
            },
            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    organization: get(data, 'organizations[0]'),
                }
            },
        })
    }

    render() {
        const {
            organization: { _id: organizationId },
            timelineEventOwnerType,
        } = this.props
        const { loading, data, refetch } = this.timelineEventsFetcher

        const timelineEvents = get(data.organization, 'timelineEvents')

        return (
            <TimelineWrap>
                <Timeline
                    ownerType={timelineEventOwnerType}
                    ownerTypeDataOrganizationId={organizationId}
                    timelineEvents={timelineEvents}
                    isLoading={loading}
                    refetch={refetch}
                />

                <TimelineInfo>{this.renderOrganizationInfo()}</TimelineInfo>
            </TimelineWrap>
        )
    }

    renderOrganizationInfo() {
        const { organization } = this.props

        return (
            <FieldCollection>
                <Field isCompact title={`Telefoonnummer`}>
                    {organization.phoneNumber && <ReadablePhoneNumbers phoneNumbers={[organization.phoneNumber]} />}
                </Field>
                <Field isCompact title={`Contactpersonen`}>
                    {this.renderOrganizationContacts(organization.contactUsers)}
                </Field>
                <Field isCompact title={`Notities`}>
                    {nl2br(organization.notes || '')}
                </Field>
            </FieldCollection>
        )
    }

    renderOrganizationContacts(contactUsers) {
        return (
            <ul>
                {contactUsers.map((contactUser, i) => (
                    <li key={i}>
                        <Link key={contactUser._id} route={`/users/organization-contacts/${contactUser._id}`}>
                            {contactUser.profile.name}
                        </Link>
                    </li>
                ))}
            </ul>
        )
    }
}

const GET_TIMELINE_EVENTS_QUERY = gql`
    query _($organizationId: MongoID!) {
        organizations(byId: $organizationId) {
            _id
            timelineEvents {
                _id
                date
                type
                typeData {
                    body
                }
                owner {
                    targets
                }
                createdByUser {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`
