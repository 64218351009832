import { gql } from '@apollo/client'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import {
    ActionBar,
    Button,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Link,
    CenterModal,
    Subtle,
    ReadableDate,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { Wrap } from '~/components/Wrap'
import { CreateInflowInviteTemplateForm } from '~/forms/CreateInflowInviteTemplateForm'
import { getCurrentUser } from '~/services/session'
import { InflowInviteTemplate } from '~/types/InflowInviteTemplate'

import { InflowModule } from '~/types/InflowModule'
import { Fetcher, Sorter } from '~/utils'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
}

interface State {
    sortDir: string
    sortBy: string
}

const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

export class AppPropertiesInflowModulesInvitesMaster extends React.Component<Props, State> {
    public state: State = {
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private inflowModulesInvitesFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.onSort,
        })

        this.inflowModulesInvitesFetcher = new Fetcher({
            query: INFLOW_INVITES_TEMPLATES_QUERY,
            variables: {
                inflowModuleId: this.props.inflowModule._id,
                sortDir,
                sortBy,
            },

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { loading, data } = this.inflowModulesInvitesFetcher
        const { inflowInviteTemplates = [] } = data

        return (
            <>
                <Wrap full>{this.renderActionBar()}</Wrap>
                <TableWrap>
                    <TableView>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem sorter={this.sorter} sortBy={`name`}>
                                    Naam
                                </TableHeaderItem>
                                <TableHeaderItem>Laatste update</TableHeaderItem>
                            </TableHeader>
                            <tbody>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={2} isLoading={true} />
                                    </TableRow>
                                ) : inflowInviteTemplates.length > 0 ? (
                                    this.renderInflowInvitesRows(inflowInviteTemplates)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={2}>
                                            <Subtle>Er zijn geen uitnodigingen gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </tbody>
                        </Table>
                    </TableView>
                </TableWrap>
            </>
        )
    }

    private renderInflowInvitesRows = (inflowInvites: InflowInviteTemplate[]) => {
        return inflowInvites.map(invite => (
            <TableRow key={invite._id}>
                <TableCell>
                    <Link route={`/properties/inflow/${this.props.params.id}/invites/${invite._id}`}>
                        {invite.name}
                    </Link>
                </TableCell>
                <TableCell>
                    <ReadableDate date={invite.updatedAt} format={'DD-MM-YYYY'} /> om{' '}
                    <ReadableDate date={invite.updatedAt} format={'HH:mm'} />
                </TableCell>
            </TableRow>
        ))
    }

    private renderActionBar = () => {
        const inflowModuleId = this.props.params.id
        const user = getCurrentUser()

        if (!user.isAdmin) {
            return null
        }

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal>
                        <ListItem right>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} type={`edit`}>
                                        Uitnodigingsbrief toevoegen
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal onClose={closeModal} title={`Lesmateriaal toevoegen`}>
                                        <CreateInflowInviteTemplateForm
                                            inflowModuleId={inflowModuleId}
                                            onSubmitSuccess={closeModal}
                                            onCancel={closeModal}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private onSort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.inflowModulesInvitesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }
}

const INFLOW_INVITES_TEMPLATES_QUERY = gql`
    query ($inflowModuleId: MongoID, $sortBy: String, $sortDir: String) {
        inflowInviteTemplates(inflowModuleId: $inflowModuleId, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            inflowModule {
                _id
                name
            }
            name
            updatedAt
            attachments {
                _id
                fileName
            }
        }
    }
`
