import * as React from 'react'
import { gql } from '@apollo/client'
const moment = require('moment')
import CenterModal from '~/components/CenterModal'
import { ModalManager } from '~/components/ModalManager'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Mutator from '~/utils/Mutator'
import { toast, transformFormFields } from '~/utils'
import { GroupUserRole } from '~/types/User'
import Form from '~/components/Form'
import Paragraph from '~/components/Paragraph'
import Bold from '~/components/Bold'
import DateTimePicker from '~/components/DateTimePicker'
import { ValidationError } from '~/components/ValidationError'

interface Props {
    render: (openModal: () => void) => React.ReactNode
    userId: string
    userName: string
    lastDisenrollmentDate: Date
    groupId: string
    groupName: string
    onEnroll?: () => void
}

interface State {
    date: Date | null
}

export class GroupLearnerParticipantEnrollModalManager extends React.Component<Props, State> {
    private defaultDate = new Date()

    private enrollMutator = new Mutator({
        mutation: ENROLL_MUTATION,
        reactComponentToUpdate: this,
    })

    constructor(props: Props) {
        super(props)

        this.state = {
            date: this.defaultDate,
        } as State
    }

    public render() {
        const { render, userName, lastDisenrollmentDate, groupName } = this.props
        const { loading: isLoadingEnroll, errors } = this.enrollMutator

        const hasError = this.isDateBeforeLastDisenrollmentDate()

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <CenterModal small title={'Opnieuw inschrijven in groep'} onClose={closeModal} enableEasyClose>
                        <Form onSubmit={this.onSubmit.bind(this, closeModal)}>
                            <FieldCollection style={`modal`}>
                                <FieldGroup isForm>
                                    <Field>
                                        <Paragraph>
                                            {/* tslint:disable-next-line:max-line-length */}
                                            Je staat op het punt om <Bold>{userName}</Bold> opnieuw in te schrijven in
                                            de groep <Bold>{groupName}</Bold>.
                                        </Paragraph>
                                    </Field>
                                    <Field title={`Inschrijfdatum`} errors={errors}>
                                        <DateTimePicker
                                            name={`date`}
                                            defaultValue={this.defaultDate}
                                            onChange={this.updateDate}
                                        />
                                        {this.isDateBeforeLastDisenrollmentDate() && (
                                            <ValidationError
                                                text={`De datum moet na de uitschrijfdatum (${moment(
                                                    lastDisenrollmentDate
                                                ).format('LL')}) liggen.`}
                                            />
                                        )}
                                    </Field>
                                </FieldGroup>
                                <FieldCollectionFooter>
                                    <List horizontal>
                                        <ListItem right>
                                            <Button onClick={closeModal}>Annuleren</Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button type="submit" isLoading={isLoadingEnroll} isDisabled={hasError}>
                                                Bevestigen
                                            </Button>
                                        </ListItem>
                                    </List>
                                </FieldCollectionFooter>
                            </FieldCollection>
                        </Form>
                    </CenterModal>
                )}
            />
        )
    }

    private onSubmit = async (closeModal: () => void, event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { userId, userName, groupId, onEnroll } = this.props

        const result = await this.enrollMutator.mutate({
            ...transformFormFields(fields, {}),
            role: GroupUserRole.Learner,
            groupId,
            userId,
        })

        if (result && result.groups_enrollUser) {
            closeModal()
            toast.success(`"${userName}" is succesvol ingeschreven`)

            if (onEnroll) {
                onEnroll()
            }
        }
    }

    private updateDate = async (date: Date | null) => {
        this.setState({
            date,
        })
    }

    private isDateBeforeLastDisenrollmentDate(): boolean {
        const { date } = this.state
        const { lastDisenrollmentDate } = this.props

        if (!date) {
            return false
        }

        return date.getTime() <= new Date(lastDisenrollmentDate).getTime()
    }
}

const ENROLL_MUTATION = gql`
    mutation groups_enrollUser($groupId: MongoID, $userId: MongoID, $date: Date, $role: GroupUserRoleEnum) {
        groups_enrollUser(groupId: $groupId, userId: $userId, date: $date, role: $role) {
            _id
        }
    }
`
