import React from 'react'
import { Link as RouterLink } from 'react-router'
import { Spinner } from '~/components'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    isNative?: boolean
    route?: string
    children?: any
    type?: string
    leftIcon?: any
    rightIcon?: any
    className?: ClassValue
    onlyActiveOnIndex?: boolean
    isDisabled?: boolean
    hasLineThrough?: boolean
    href?: string
    target?: string
    onClick?: (event: React.MouseEvent) => void
    isLoading?: boolean
    isLinkActive?: boolean
    small?: boolean
    to?: string
}

export default class Link extends React.Component<Props> {
    public static defaultProps = {
        isNative: false,
    }

    private bem = new BEM('Link', () => ({
        danger: this.props.type === 'danger',
        subtle: this.props.type === 'subtle',
        empahasized: this.props.type === 'empahasized',
        'is-disabled': this.isDisabled(),
        'is-loading': this.props.isLoading,
        'has-line-through': this.props.hasLineThrough,
        'is-active': this.props.isLinkActive,
        small: this.props.small,
    }))

    public render() {
        const {
            route,
            children,
            leftIcon,
            rightIcon,
            className,
            onlyActiveOnIndex,
            isNative,
            href,
            target,
            isLoading,
        } = this.props

        let DynamicComp
        const dynamicProps: any = {}

        if (isNative) {
            DynamicComp = 'a'
            dynamicProps.href = href
        } else {
            DynamicComp = RouterLink
            dynamicProps.to = route
            dynamicProps.activeClassName = `tt-Link--is-active`
            dynamicProps.onlyActiveOnIndex = onlyActiveOnIndex
        }

        return (
            <DynamicComp
                target={target}
                className={this.bem.getClassName(className)}
                onClick={this.onClick}
                {...dynamicProps}
            >
                {leftIcon && <span className={this.bem.getElement('left-icon')}>{leftIcon}</span>}
                {children && <span className={this.bem.getElement('content')}>{children}</span>}
                {isLoading && <Spinner size={`small`} color={`#3F64A4`} />}
                {rightIcon && <span className={this.bem.getElement('right-icon')}>{rightIcon}</span>}
            </DynamicComp>
        )
    }

    private isDisabled = () => {
        return this.props.isDisabled || this.props.isLoading
    }

    private onClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        const { onClick } = this.props

        if (this.isDisabled()) {
            event.preventDefault()
        } else if (onClick) {
            onClick(event)
        }
    }
}
