import './LockedFieldWrapper.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/buttons/Button/Button'

interface Props {
    className?: string
    children: (isLocked: boolean) => React.ReactNode
    disableLock?: boolean
}

interface State {
    isUnlocked: boolean
}

export class LockedFieldWrapper extends React.Component<Props, State> {
    public static StatusContext = React.createContext<boolean>(false)

    public state: State = {
        isUnlocked: !!this.props.disableLock,
    }

    private bem = new BEM('LockedFieldWrapper')

    public render() {
        const { isUnlocked } = this.state

        return (
            <LockedFieldWrapper.StatusContext.Consumer>
                {isEnabled => {
                    const isLocked = !isUnlocked && !!isEnabled
                    return this.renderChildren(isLocked)
                }}
            </LockedFieldWrapper.StatusContext.Consumer>
        )
    }

    private renderChildren(isLocked: boolean) {
        const { children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {children(isLocked)}
                {isLocked && (
                    <Button
                        className={this.bem.getElement('unlock-button')}
                        onClick={() => this.setState({ isUnlocked: true })}
                        small={true}
                    >
                        Bewerken
                    </Button>
                )}
            </div>
        )
    }
}
