import './PlaceholderNote.scss'
import c from 'classnames'
import * as React from 'react'
import { Button, CenterModal } from '~/components'
import { NoteType, NoteTypeData } from '~/types/Note'
import { ModalManager } from '~/components/ModalManager'
import { CreateNoteForm } from '~/components/notes/CreateNoteForm'

interface Props {
    className?: string
    noteType: NoteType
    noteTypeData: NoteTypeData
    onAddNote: () => void
}

export class PlaceholderNote extends React.Component<Props, {}> {
    public render() {
        const { noteType, noteTypeData, onAddNote } = this.props

        return (
            <article className={this.getClassName()}>
                <ModalManager
                    render={openModal => <Button onClick={openModal}>Nieuwe notitie</Button>}
                    getModal={closeModal => {
                        return (
                            <CenterModal small onClose={closeModal} title={`Notitie toevoegen`}>
                                <CreateNoteForm
                                    noteType={noteType}
                                    noteTypeData={noteTypeData}
                                    onSubmitSuccess={() => {
                                        closeModal()
                                        onAddNote()
                                    }}
                                    onCancel={closeModal}
                                />
                            </CenterModal>
                        )
                    }}
                />
            </article>
        )
    }

    private getClassName(): string {
        const { className } = this.props

        return c('tt-PlaceholderNote', className)
    }
}
