import React from 'react'
import { View } from '~/components/View'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class PageView extends React.Component<Props> {
    private bem = new BEM('PageView')

    public render() {
        const { children, className } = this.props

        return <View className={this.bem.getClassName(className)}>{children}</View>
    }
}
