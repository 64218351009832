import React from 'react'

import { Form, FieldCollection, Field, MultiInput, RadioButton, Button } from '~/components'
import { gql } from '@apollo/client'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { OrganizationsTagPicker } from '~/components/Organization/OrganizationsTagPicker'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ConceptInflowMoment } from '~/types/InflowMoments'
import { Fetcher, Mutator, transformFormFields } from '~/utils'
import { InflowModule } from '~/types/InflowModule'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'

interface Props {
    onClose: () => void
    inflowMoment: ConceptInflowMoment
    refetch: (options?: { silent: boolean }) => void
}

interface State {
    forOrganization: Boolean
}

const GET_INFLOW_MODULES_QUERY = gql`
    query _ {
        inflowModules(sortBy: "name") {
            _id
            name
        }
    }
`

export class InflowMomentInflowModuleForm extends React.Component<Props, State> {
    public state: State = {
        forOrganization: this.props.inflowMoment ? this.props.inflowMoment.isForOrganization : false,
    }

    private inflowModulesFetcher: Fetcher
    private InflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowModulesFetcher = new Fetcher({
            query: GET_INFLOW_MODULES_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.InflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose, inflowMoment } = this.props
        const { forOrganization } = this.state
        const { data } = this.inflowModulesFetcher
        const { inflowModules = [] } = data

        const inflowOptions = inflowModules.map((inflowModule: InflowModule) => ({
            value: inflowModule._id,
            label: inflowModule.name,
        }))

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel={true} title={'Instroommodule'}>
                            <TagPicker
                                multi={false}
                                name={`inflowMoment.inflowModuleId`}
                                options={inflowOptions}
                                defaultValue={
                                    inflowMoment.inflowModule ? inflowMoment.inflowModule._id : 'Module is verwijderd'
                                }
                            />
                        </Field>
                        <Field isLabel={true} title={'Voor een organisatie'}>
                            <MultiInput type="radio">
                                <RadioButton
                                    name={'inflowMoment.isForOrganization'}
                                    value={true}
                                    onClick={() => this.setState({ forOrganization: true })}
                                    defaultChecked={inflowMoment.isForOrganization}
                                >
                                    Ja, namelijk;
                                </RadioButton>
                                <RadioButton
                                    name={'inflowMoment.isForOrganization'}
                                    value={false}
                                    onClick={() => this.setState({ forOrganization: false })}
                                    defaultChecked={!inflowMoment.isForOrganization}
                                >
                                    Nee
                                </RadioButton>
                            </MultiInput>
                        </Field>
                        {forOrganization && (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel={true} title={'Organisatie'}>
                                    <OrganizationsTagPicker
                                        name={'inflowMoment.organizationId'}
                                        defaultValue={inflowMoment.organization && inflowMoment.organization._id}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onClose}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`}>Toevoegen</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onClose, refetch, inflowMoment } = this.props

        const formFields = {
            ...fields.inflowMoment,
            ...(fields.inflowMoment.isForOrganization === false ? { organizationId: null } : {}),
            _id: inflowMoment._id,
        }

        this.InflowMomentMutator.mutate({
            inflowMoment: transformFormFields(formFields, {}),
        }).then(data => {
            if (data) {
                onClose()

                if (refetch) {
                    refetch({ silent: true })
                }
            }
        })
    }
}
