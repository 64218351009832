import './InlineInputs.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    children: JSX.Element[]
}

export class InlineInputs extends React.Component<Props> {
    private bem = new BEM('InlineInputs')

    public render() {
        const { className, children } = this.props

        return (
            <ul className={this.bem.getClassName(className)}>
                {children.map((child, i) => (
                    <li className={this.bem.getElement('item')} key={`InlineInputs__item-${i}`}>
                        {child}
                    </li>
                ))}
            </ul>
        )
    }
}
