import './ProgressTableAbilityItem.scss'

import * as React from 'react'
import { RotatedTableHeaderItem } from '~/components/Table/RotatedTableHeaderItem/RotatedTableHeaderItem'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    biggestTitleLength: number
    title: string
}

export class ProgressTableAbilityItem extends React.Component<Props, {}> {
    private bem = new BEM('ProgressTableAbilityItem')

    public render() {
        const { biggestTitleLength, title } = this.props

        const PIXELS_PER_LETTER = 6

        return (
            <RotatedTableHeaderItem
                className={this.bem.getClassName()}
                reservedHeight={biggestTitleLength * PIXELS_PER_LETTER}
            >
                <span className={`tt-ProgressTableAbilityItem__text`}>{title}</span>
            </RotatedTableHeaderItem>
        )
    }
}
