const types = {
    EMAIL: {
        icon: 'mail',
        color: '#ed71b3',
    },
    PHONE: {
        color: '#728aed',
        icon: 'phone',
    },
    CHAT: {
        color: '#ed7276',
        icon: 'chat',
    },
    TEXT: {
        color: '#72b4ed',
        icon: 'sms',
    },
    LETTER: {
        color: '#ab72ed',
        icon: 'pencil',
    },
    NOTE: {
        color: '#edab72',
        icon: 'note',
    },
    HTML: {
        color: '#ab72ed',
        icon: 'note',
    },
}

export function getTypeConfiguration(type: any) {
    return types[type]
}
