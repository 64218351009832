import $ from 'jquery'
import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    name: string
    className?: ClassValue
    color?: string
}

interface IconComponentProps {
    style?: {
        color: string
    }
    dangerouslySetInnerHTML?: {
        __html: string
    }
}

interface State {
    svgContent?: string | null
    loading: boolean
}

export default class Icon extends React.Component<Props, State> {
    public static cache = {}

    public state: State = {
        svgContent: null,
        loading: false,
    }

    private bem = new BEM('Icon', () => ({
        [this.props.name]: !!this.props.name,
    }))

    private _initial: boolean
    private _isUnmounted: boolean

    constructor(props: Props) {
        super(props)

        this._initial = true
        this.setSvg(props.name)
        this._initial = false
    }

    public componentWillUpdate(nextProps: Props) {
        if (nextProps.name !== this.props.name) {
            this.setSvg(nextProps.name)
        }
    }

    public componentWillUnmount() {
        this._isUnmounted = true
    }

    public render() {
        const { className, color } = this.props
        const { svgContent } = this.state

        const props: IconComponentProps = {}

        if (svgContent) {
            props.dangerouslySetInnerHTML = { __html: svgContent }
        }

        if (color) {
            props.style = { color }
        }

        return <i className={this.bem.getClassName(className)} {...props} />
    }

    private setSvg = async (name: string) => {
        try {
            const promise =
                Icon.cache[name] ||
                // TODO: Fix this issue and re-enable the '@typescript-eslint/no-empty-function' rule
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                $.get(`/static/images/icons/${name}.svg`, () => {}, 'text')

            Icon.cache[name] = promise

            const svgContent = await promise
            if (svgContent && !this._isUnmounted) {
                if (this._initial) {
                    this.state.svgContent = svgContent
                } else {
                    this.setState({ svgContent })
                }
            }
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.error(`Icon "${name}" not found or invalid!`)
        }
    }
}
