import * as React from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { translateType } from '~/shared/utils'
import { View } from '~/components/View'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { TableView } from '~/components/TableView'
import { InflowModule, InflowModuleExam } from '~/types/InflowModule'
import { CreateInflowModuleForm } from '~/forms/CreateInflowModuleForm'
import { gql } from '@apollo/client'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { BreadCrumbs } from '~/components/BreadCrumbs'

const GET_INFLOWS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        inflowModules(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            type
            exams {
                exam {
                    _id
                    name
                }
            }
        }
    }
`

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class MasterView extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private filter: Filter
    private paginator: Paginator
    private inflowModulesFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.inflowModulesFetcher.refetch({
                    searchText: filters.searchText,
                } as any)
            },
        } as any)

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.inflowModulesFetcher = new Fetcher({
            query: GET_INFLOWS_QUERY,
            variables: { limit, skip, sortDir, sortBy, searchText: this.filter.getFilters().searchText },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public loadMore = (skip: number, limit: number, callback: any) => {
        this.inflowModulesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.inflowModules.length === 0,
                })

                return {
                    inflowModules: removeDuplicateDocuments([
                        ...(prevData.inflowModules || []),
                        ...moreData.inflowModules,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.inflowModulesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    public onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    public render() {
        const { loading, data } = this.inflowModulesFetcher
        const { inflowModules } = data || []

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem>
                                            <Search isLoading={loading} onSearch={this.onSearch} />
                                        </ListItem>
                                        <ListItem right>
                                            <ModalManager
                                                render={openModal => (
                                                    <Button type={`edit`} onClick={openModal}>
                                                        Instroommodule toevoegen
                                                    </Button>
                                                )}
                                                getModal={closeModal => (
                                                    <CenterModal
                                                        onClose={closeModal}
                                                        title={`Instroommodule toevoegen`}
                                                    >
                                                        <CreateInflowModuleForm
                                                            onSubmitSuccess={() => closeModal()}
                                                            onCancel={closeModal}
                                                        />
                                                    </CenterModal>
                                                )}
                                            />
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sortBy={`name`} sorter={this.sorter}>
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem sortBy={`type`} sorter={this.sorter}>
                                        Type
                                    </TableHeaderItem>
                                    <TableHeaderItem>Af te nemen toetsen</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={3} isLoading />
                                    </TableRow>
                                ) : inflowModules && inflowModules.length > 0 ? (
                                    this.renderInflowModuleRows(inflowModules)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen instroommodules gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    public renderInflowModuleRows(inflowModules: InflowModule[]) {
        return inflowModules.map(inflowModule => {
            return (
                <TableRow key={inflowModule._id}>
                    <TableCell>
                        <Link route={`/properties/inflow/${inflowModule._id}`}>{inflowModule.name}</Link>
                    </TableCell>
                    <TableCell>{translateType('inflowModuleType', inflowModule.type)}</TableCell>
                    <TableCell>{this.renderExams(inflowModule.exams)}</TableCell>
                </TableRow>
            )
        })
    }

    public renderExams(exams?: InflowModuleExam[]) {
        if (!exams) {
            return
        }

        const examNames = exams
            .map(inflowExam => inflowExam.exam && inflowExam.exam.name)
            .filter(examName => !!examName) as string[]

        return examNames.join(', ')
    }
}
