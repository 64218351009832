import c from 'classnames'
import { gql } from '@apollo/client'
import { bindAll, get } from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CheckBox,
    Field,
    FilterFieldCollection,
    Icon,
    Link,
    MultiInput,
    Search,
    Spinner,
    TableRow,
    TableWrap,
    td,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Filter, removeDuplicateDocuments } from '~/utils'
import { ButtonGroup } from '~/components/ButtonGroup'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const moment = extendMoment(Moment)

const STATIC_TEACHER_USERS_FILTERS = {
    everyRoleIn: ['TEACHER'],
}

const LIMIT = 30
const SKIP = 30

export default class AvailabilityView extends Component {
    constructor(props) {
        super(props)

        bindAll(this, ['onFilter', 'onFilterTagPicker', 'showCurrentDay', 'skipToNextWeek', 'skipToPreviousWeek'])

        this.dateFrom = moment().startOf('week')
        this.dateTo = moment().startOf('week').add(4, 'week')

        this.paginator = new Paginator({
            start: LIMIT,
            increase: SKIP,
            onLoadMore: this.loadMore,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText', 'filterByCityNames', 'filterTeacherActive', 'everyRoleIn'],
            onChange: filters => {
                this.teachersAvailabilityFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_TEACHER_USERS_FILTERS,
                        ...filters,
                        everyRoleIn: [...STATIC_TEACHER_USERS_FILTERS.everyRoleIn, ...(filters.everyRoleIn || [])],
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsActive) activeStates.push(true)
                        if (filters.filterIsInactive) activeStates.push(false)

                        return {
                            filterTeacherActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterTeacherActive'],
                    parseFromHistory: historyFilters => {
                        if ('filterTeacherActive' in historyFilters) {
                            if (historyFilters.filterTeacherActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterOnlyIntakers'],
                    transform: filters => {
                        const everyRoleIn = []
                        if (filters.filterOnlyIntakers) everyRoleIn.push('INTAKER')

                        return {
                            everyRoleIn,
                        }
                    },
                    historyTriggerKeys: ['everyRoleIn'],
                    parseFromHistory: historyFilters => {
                        if ('everyRoleIn' in historyFilters) {
                            return {
                                filterOnlyIntakers: historyFilters.everyRoleIn.includes('INTAKER'),
                            }
                        }
                    },
                },
            ],
        })

        this.teachersAvailabilityFetcher = new Fetcher({
            query: GET_TEACHERS_AVAILABILITY_QUERY,
            onChange: () => this.forceUpdate(),
            variables: {
                limit: LIMIT,
                skip: 0,
                sortDir: 'ASC',
                sortBy: 'profile.firstName',
                filters: {
                    ...STATIC_TEACHER_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
                dateFrom: this.dateFrom.toDate(),
                dateTo: this.dateTo.toDate(),
                timezoneOffset: moment().utcOffset(),
            },
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    loadMore = (skip, limit, callback) => {
        this.teachersAvailabilityFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    skipToNextWeek() {
        this.dateFrom = this.dateFrom.add(1, 'week')
        this.dateTo = this.dateTo.add(1, 'week')

        this._refetchTeachersTable()
    }

    skipToPreviousWeek() {
        this.dateFrom = this.dateFrom.subtract(1, 'week')
        this.dateTo = this.dateTo.subtract(1, 'week')

        this._refetchTeachersTable()
    }

    showCurrentDay() {
        this.dateFrom = moment().startOf('week')
        this.dateTo = moment().startOf('week').add(4, 'week')

        this._refetchTeachersTable()
    }

    _refetchTeachersTable() {
        const filters = this.filter.getFilters()

        this.teachersAvailabilityFetcher.refetch({
            silent: true,
            filters: {
                ...STATIC_TEACHER_USERS_FILTERS,
                ...filters,
            },
            dateFrom: this.dateFrom.toDate(),
            dateTo: this.dateTo.toDate(),
        })
    }

    render() {
        const { data, loading } = this.teachersAvailabilityFetcher
        const { paginator } = this
        const teachersUsers = get(data, 'users') || []
        const { data: filterParams } = this.filterSuggestionsFetcher
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['searchText'] })
        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()
        const { addresses_usedCities } = filterParams

        if (loading) {
            return (
                <View>
                    <ContentView>
                        <Wrap full>
                            <Spinner />
                        </Wrap>
                    </ContentView>
                </View>
            )
        }

        const today = moment()
        const range = moment.range(this.dateFrom, moment(this.dateTo).subtract(1, 'millisecond'))
        const days = Array.from(range.by('day'))
        const weeks = Array.from(range.by('week'))

        return (
            <View>
                <ContentView>
                    <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                        <Wrap full>
                            <ActionBar
                                isActiveByDefault={hasFilters}
                                getButtons={(toggleDrawer, drawerActive) => {
                                    return (
                                        <List horizontal>
                                            <ListItem>
                                                <Button
                                                    leftIcon={<Icon name={`filter`} />}
                                                    onClick={() => toggleDrawer()}
                                                    isActive={drawerActive}
                                                >
                                                    Filteropties
                                                </Button>
                                            </ListItem>
                                            <ListItem>
                                                <Search
                                                    isLoading={loading}
                                                    onSearch={this.onSearch}
                                                    defaultValue={filters.searchText}
                                                />
                                            </ListItem>

                                            <ListItem right>
                                                <ButtonGroup>
                                                    <ButtonGroup smallButtons={true} joined={true}>
                                                        <Button
                                                            small
                                                            onClick={this.skipToPreviousWeek}
                                                            leftIcon={<Icon name={'caret-left'} />}
                                                        />
                                                        <Button
                                                            small
                                                            onClick={this.skipToNextWeek}
                                                            leftIcon={<Icon name={'caret-right'} />}
                                                        />
                                                    </ButtonGroup>
                                                    <Button onClick={this.showCurrentDay}>Vandaag</Button>
                                                </ButtonGroup>
                                            </ListItem>
                                        </List>
                                    )
                                }}
                                getDrawer={() => {
                                    const addressOptions =
                                        addresses_usedCities &&
                                        addresses_usedCities.map(city => ({ value: city, label: city }))

                                    return (
                                        <FilterFieldCollection>
                                            <Field isLabel title={`Plaats`} style={`compact`}>
                                                {addressOptions && (
                                                    <TagPicker
                                                        onChange={this.onFilterTagPicker}
                                                        name={`filterByCityNames`}
                                                        defaultValue={filters.filterByCityNames}
                                                        options={addressOptions}
                                                        placeholder={`Selecteer plaats`}
                                                    />
                                                )}
                                            </Field>
                                            <Field title={`Status`} style={`compact`}>
                                                <MultiInput type={`checkbox`} isVertical>
                                                    <CheckBox
                                                        name={`filterIsActive`}
                                                        defaultChecked={customFilters.filterIsActive}
                                                        onChange={this.onFilter}
                                                    >
                                                        Actief
                                                    </CheckBox>
                                                    <CheckBox
                                                        name={`filterIsInactive`}
                                                        defaultChecked={customFilters.filterIsInactive}
                                                        onChange={this.onFilter}
                                                    >
                                                        Non-actief
                                                    </CheckBox>
                                                </MultiInput>
                                            </Field>
                                            <Field title={`Rol`} style={`compact`}>
                                                <MultiInput type={`checkbox`} isVertical>
                                                    <CheckBox
                                                        name={`filterOnlyIntakers`}
                                                        defaultChecked={customFilters.filterOnlyIntakers}
                                                        onChange={this.onFilter}
                                                    >
                                                        Alleen intakers
                                                    </CheckBox>
                                                </MultiInput>
                                            </Field>
                                        </FilterFieldCollection>
                                    )
                                }}
                            />
                        </Wrap>
                        <TableWrap className="tt-TeacherAvailability">
                            <TableView>
                                <Table>
                                    {teachersUsers.map((user, index) => {
                                        const availability = get(user, 'teacher.availability') || []

                                        const rows = []
                                        if (index % 15 === 0) {
                                            rows.push(
                                                <thead key={'head-' + user._id}>
                                                    <tr className="tt-TeacherAvailability__dates-weeks">
                                                        <th
                                                            className="tt-TeacherAvailability__teacher-header-cell"
                                                            width="200px"
                                                        >
                                                            Docenten
                                                        </th>
                                                        {weeks.map(week => {
                                                            return (
                                                                <th
                                                                    key={'week-' + week.week()}
                                                                    className="tt-TeacherAvailability__header-cell"
                                                                    colSpan={7}
                                                                >{`${week.format('MMMM')} (wk ${week.week()})`}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                    <tr className="tt-TeacherAvailability__dates-days">
                                                        <th
                                                            className="tt-TeacherAvailability__header-cell"
                                                            width="200px"
                                                        >
                                                            &nbsp;
                                                        </th>
                                                        {days.map(day => {
                                                            return (
                                                                <th
                                                                    key={'day-' + day.format('YYYY-MM-DD')}
                                                                    className={`tt-TeacherAvailability__header-cell ${
                                                                        day.isSame(today, 'day')
                                                                            ? 'tt-TeacherAvailability__header-cell--current-week'
                                                                            : ''
                                                                    }`}
                                                                    title={day.format('dddd D MMMM YYYY')}
                                                                >
                                                                    {day.format('DD')}
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                            )
                                        }

                                        rows.push(
                                            <TableRow key={user._id}>
                                                <TableCell
                                                    className="tt-TeacherAvailability__teacher-cell"
                                                    title={user.profile.name}
                                                >
                                                    <Link route={`/users/teachers/${user._id}/availability`}>
                                                        {user.profile.name}
                                                    </Link>
                                                </TableCell>
                                                {/* Availability cells */}
                                                {days.map(day => {
                                                    const isMonday = day.weekday() === 0

                                                    const cellAvailability = availability.find(
                                                        ({ date }) =>
                                                            moment(date).format('YYYY-DD-MM') ===
                                                            day.format('YYYY-DD-MM')
                                                    )

                                                    if (!cellAvailability) {
                                                        return (
                                                            <td
                                                                key={
                                                                    user._id + 'availability' + day.format('YYYY-MM-DD')
                                                                }
                                                                className={c('tt-TeacherAvailability__cell', {
                                                                    'tt-TeacherAvailability__cell--isMonday':
                                                                        !!isMonday,
                                                                })}
                                                            >
                                                                <div />
                                                            </td>
                                                        )
                                                    }

                                                    const map = {
                                                        FULL: 'tt-TeacherAvailability__indicator--full-availability',
                                                        PARTIAL:
                                                            'tt-TeacherAvailability__indicator--partial-availability',
                                                        NONE: 'tt-TeacherAvailability__indicator--no-availability',
                                                    }

                                                    const morning = map[cellAvailability.availabilityMorning]
                                                    const afternoon = map[cellAvailability.availabilityAfternoon]
                                                    const evening = map[cellAvailability.availabilityEvening]

                                                    return (
                                                        <td
                                                            key={user._id + 'availability' + day.format('YYYY-MM-DD')}
                                                            className={`
                                                            tt-TeacherAvailability__cell tt-TeacherAvailability__cell--has-availability
                                                            ${isMonday ? 'tt-TeacherAvailability__cell--isMonday' : ''}
                                                        `}
                                                        >
                                                            <div>
                                                                <table>
                                                                    <tr>
                                                                        <td>
                                                                            <div
                                                                                className={`tt-TeacherAvailability__indicator ${morning}`}
                                                                                title="08:00-11:59"
                                                                            ></div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                className={`tt-TeacherAvailability__indicator ${afternoon}`}
                                                                                title="12:00-17:59"
                                                                            ></div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                className={`tt-TeacherAvailability__indicator ${evening}`}
                                                                                title="18:00-22:00"
                                                                            ></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                            </TableRow>
                                        )

                                        return rows
                                    })}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
            </View>
        )
    }
}

const GET_TEACHERS_AVAILABILITY_QUERY = gql`
    query _(
        $limit: Int
        $skip: Int
        $sortBy: String
        $sortDir: String
        $filters: UsersFilterInputType
        $dateTo: Date!
        $dateFrom: Date!
        $timezoneOffset: Int!
    ) {
        users(limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            profile {
                name
            }
            teacher {
                availability(dateTo: $dateTo, dateFrom: $dateFrom, timezoneOffset: $timezoneOffset) {
                    date
                    availabilityMorning
                    availabilityAfternoon
                    availabilityEvening
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`
