import { toast } from '~/utils'

let printIframe: any

export const printPdfByUrl = function (url: string) {
    let iframe = printIframe

    if (!printIframe) {
        iframe = printIframe = document.createElement('iframe')
        document.body.appendChild(iframe)
        iframe.style.display = 'none'
        iframe.onload = function () {
            setTimeout(function () {
                try {
                    iframe.focus()
                    iframe.contentWindow.print()
                } catch (err) {
                    toast.warning(
                        'Het is niet gelukt om het print-scherm te openen. We raden je aan om de PDF handmatig te downloaden en te printen.'
                    )
                    throw err
                }
            }, 1)
        }
    }

    iframe.src = url
}
