import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import { LearnerOrganizationsInput } from '~/components/users/Learner/LearnerOrganizationsInput'
import { get, isEmpty } from 'lodash'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { Organization } from '~/types/Organization'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'

interface Props {
    errors?: GraphQLErrors
    hasPrivatePayment?: boolean
    hideFieldsForOrganizationContacts?: boolean
    loading: boolean
    organizations?: Organization[]
    user: User
}

export class UserLearnerOrganizationFieldGroup extends React.Component<Props> {
    public render() {
        const { hideFieldsForOrganizationContacts, loading, user, errors, hasPrivatePayment, organizations } =
            this.props

        const userOrganizations = user && user.learner && user.learner.organizations

        return (
            <FieldGroup title={`Organisaties`} isForm={true}>
                <LockedFieldWrapper disableLock={isEmpty(userOrganizations)}>
                    {isLocked => (
                        <LearnerOrganizationsInput
                            defaultValues={get(user, 'learner.organizations')}
                            errors={errors}
                            hasPrivatePayment={hasPrivatePayment}
                            hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                            loading={loading}
                            organizations={organizations}
                            isDisabled={isLocked}
                        />
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }
}
