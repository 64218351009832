import * as React from 'react'
import CenterModal from '~/components/CenterModal'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import { gql } from '@apollo/client'
import { InflowMoment, InflowMomentTimeslotsAddType } from '~/types/InflowMoments'
import { Mutator } from '~/utils'
import Form from '~/components/Form'

interface Props {
    onClose: () => void
    onSubmitSuccess: () => void
    insertPosition: string
    inflowMoment: InflowMoment
    insertType: InflowMomentTimeslotsAddType
}

const ADD_INFLOW_MOMENT_TIMESLOT_MUTATION = gql`
    mutation _($inflowMomentId: MongoID, $insertType: inflowMomentTimeslotsAddType) {
        inflowMomentTimeslots_add(inflowMomentId: $inflowMomentId, insertType: $insertType) {
            _id
        }
    }
`

export class AddTimeslotModal extends React.Component<Props> {
    private inflowMomentTimeslotMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowMomentTimeslotMutator = new Mutator({
            mutation: ADD_INFLOW_MOMENT_TIMESLOT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props

        return (
            <CenterModal title={`Tijdslot toevoegen`} onClose={onClose}>
                <Form onSubmit={this.onSubmit}>
                    <FieldCollection style={`modal`}>
                        <FieldGroup>
                            <Field>Je staat op het punt om een extra tijdslot aan te maken, weet je het zeker?</Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                <ListItem right={true}>
                                    <Button onClick={onClose}>Annuleren</Button>
                                </ListItem>
                                <ListItem right={true}>
                                    <Button type={`submit`} styleOverride={`edit`}>
                                        Tijdslot aanmaken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Form>
            </CenterModal>
        )
    }

    private onSubmit = async () => {
        const { onSubmitSuccess, onClose } = this.props

        const result = await this.inflowMomentTimeslotMutator.mutate({
            inflowMomentId: this.props.inflowMoment._id,
            insertType: this.props.insertType,
        })

        if (result) {
            onSubmitSuccess()
            onClose()
        }
    }
}
