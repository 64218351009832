import * as React from 'react'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import ReadableDate from '~/components/ReadableDate'
import Subtle from '~/components/Subtle'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, PdfModal, InvoiceStatusIndicator, Emphasis } from '~/components'
import Icon from '~/components/Icon'
import ActionBar from '~/components/ActionBar'
import { ModalManager } from '~/components/ModalManager'
import CenterModal from '~/components/CenterModal'
import { FinalExamIsCancelledModalForm } from '~/forms/FinalExams/FinalExamIsCancelledModalForm'
import { FinalExamChangeDateModalForm } from '~/forms/FinalExams/FinalExamChangeDateModalForm'
import { FinalExam, FinalExamSubmitters, FinalExamAppointmentDocument } from '~/types/FinalExam'
import Link from '~/components/Link'
import { FinalExamFixSubmissionMistakeForm } from '~/forms/FinalExams/FinalExamFixSubmissionMistakeForm'
import downloadFile from '~/utils/downloadFile'
import { FinalExamsLogList } from '~/components/FinalExams/FinalExamsLogList'
import translateType from '~/shared/utils/translateType'
import { RegisterFinalExamForm } from '~/forms/RegisterFinalExamForm'
const moment = require('moment')
import InvoiceLink from '~/implementations/InvoiceLink'

interface Props {
    finalExam: FinalExam
    refetchData: (opts: any) => void
}

export class FinalExamsPlannedData extends React.Component<Props> {
    public render() {
        const { finalExam } = this.props

        return (
            <View>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Gegevens`}>
                            <Field title={`Kandidaat`}>
                                <Link route={`/users/learners/${finalExam.user._id}`} target={`_blank`}>
                                    {finalExam.user.profile.name}
                                </Link>
                            </Field>
                            <Field title={`Examenonderdeel`}>{translateType('finalExamPart', finalExam.part)}</Field>
                            <Field title={`Niveau`}>{finalExam.level || '-'}</Field>
                            <Field title={`Aanmelddatum`}>
                                {finalExam.createdAt && <ReadableDate date={finalExam.createdAt} />}
                                {!finalExam.createdAt &&
                                    finalExam.submitter === FinalExamSubmitters.External &&
                                    `Extern aangemeld`}
                            </Field>
                            <Field title={`Aangevraagd door`}>
                                {finalExam.submitter && translateType('finalExamSubmitter', finalExam.submitter)}
                            </Field>
                            <Field title={`Resultaat`}>
                                <span>
                                    {finalExam.result ? (
                                        translateType('finalExamResult', finalExam.result)
                                    ) : (
                                        <Emphasis>Nog onbekend</Emphasis>
                                    )}
                                    <Subtle asSuffix={true} regularSize={true}>
                                        Poging {finalExam.attempt} (<ReadableDate date={finalExam.date} />)
                                    </Subtle>
                                </span>
                            </Field>
                            <Field title={`Examenafspraak`}>
                                {!!finalExam.appointmentFile && this.renderAppointmentFile(finalExam.appointmentFile)}
                            </Field>
                            <Field title={`Facturatie`}>
                                {finalExam.invoice && (
                                    <List horizontal={true} extraSpacing={true}>
                                        <ListItem>
                                            <InvoiceLink invoice={finalExam.invoice} />
                                        </ListItem>
                                        <ListItem>
                                            <InvoiceStatusIndicator
                                                invoice={finalExam.invoice}
                                                ensureShowIconOnly={true}
                                            />
                                        </ListItem>
                                    </List>
                                )}
                            </Field>
                            <Field title={`Volgende poging`}>
                                {finalExam.nextAttempt ? (
                                    <Link route={`/final-exams/planned/${finalExam.nextAttempt._id}`}>
                                        {`Poging ${finalExam.nextAttempt.attempt} - `}
                                        <ReadableDate date={finalExam.nextAttempt.date} />
                                    </Link>
                                ) : (
                                    <Emphasis>Nog niet ingepland</Emphasis>
                                )}
                            </Field>
                            {this.renderLogList()}
                        </FieldGroup>
                        {this.renderFieldCollectionFooter()}
                    </FieldCollection>
                </Wrap>
            </View>
        )
    }

    private renderAppointmentFile = (document: FinalExamAppointmentDocument) => {
        if (document.isPDF) {
            return (
                <ModalManager
                    render={openModal => (
                        <Button linkStyle={`default`} onClick={openModal}>
                            {document.fileName}
                        </Button>
                    )}
                    getModal={closeModal => (
                        <PdfModal
                            title={document.fileName}
                            fileName={document.fileName}
                            getFileId={() => document._id}
                            onClose={closeModal}
                        />
                    )}
                />
            )
        } else {
            return (
                <Button linkStyle={`default`} onClick={() => downloadFile(document._id, document.fileName)}>
                    {document.fileName}
                </Button>
            )
        }
    }

    private renderLogList = () => {
        const { finalExam } = this.props
        const { logs } = finalExam

        if (!logs || !logs.length) {
            return null
        }

        return (
            <FieldGroup isInsetGroup={true} isCompact={true} singleField={true}>
                <Field title={`Statushistorie`} isBold={true}>
                    <FinalExamsLogList finalExam={finalExam} />
                </Field>
            </FieldGroup>
        )
    }

    private renderFieldCollectionFooter = () => {
        const { finalExam } = this.props

        return (
            <ActionBar
                fixed={true}
                isWide={true}
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} isDisabled={!!finalExam.result}>
                                        Herstel invoerfout
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal title={`Herstel invoerfout`} onClose={closeModal}>
                                        <FinalExamFixSubmissionMistakeForm
                                            onClose={closeModal}
                                            finalExam={finalExam}
                                            onSubmitSuccess={() => this.refreshAfterModal(closeModal)}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                        <ListItem>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} isDisabled={this.disallowRetake(finalExam)}>
                                        Herkansing aanvragen
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal title={`Herkansing aanvragen`} onClose={closeModal}>
                                        <RegisterFinalExamForm
                                            onSubmitSuccess={() => this.refreshAfterModal(closeModal)}
                                            user={finalExam.user}
                                            onClose={closeModal}
                                            submitterIsToptaal={finalExam.submitter === 'toptaal'}
                                            setPartAndLevel={`${finalExam.part}${finalExam.level || ''}`}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button
                                        isDisabled={
                                            !!finalExam.canceledAt || moment(finalExam.date).isBefore(moment(), 'day')
                                        }
                                        onClick={openModal}
                                        leftIcon={<Icon name={`upload`} />}
                                    >
                                        Datumwijziging doorgeven
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal title={`Datum verzetten`} onClose={closeModal}>
                                        <FinalExamChangeDateModalForm
                                            onClose={closeModal}
                                            finalExam={finalExam}
                                            onSubmitSuccess={() => this.refreshAfterModal(closeModal)}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button
                                        isDisabled={
                                            !!finalExam.canceledAt || moment(finalExam.date).isBefore(moment(), 'day')
                                        }
                                        onClick={openModal}
                                        leftIcon={<Icon name={`status_error`} />}
                                    >
                                        Annulering doorgeven
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal title={`Annulering doorgeven`} onClose={closeModal}>
                                        <FinalExamIsCancelledModalForm
                                            onSubmitSuccess={() => this.refreshAfterModal(closeModal)}
                                            onClose={closeModal}
                                            finalExam={finalExam}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private refreshAfterModal = (closeModal: () => void) => {
        closeModal()
        this.props.refetchData({ silent: true })
    }

    private disallowRetake = (finalExam: FinalExam): boolean => {
        return !!(finalExam.nextAttempt || moment(finalExam.date).isAfter(moment()) || !!finalExam.canceledAt)
    }
}
