import { User } from '~/types/User'
import { Group } from '~/types/Group'
import { Lesson } from './Lesson'
import { Contract } from './Contracts'

export interface SignatureFile {
    _id: string
    isPDF?: boolean
    fileName?: string
    createdAt?: Date
    createdByUser?: User
}

export enum InvoiceDescription {
    LessonMaterials = 'lessonMaterials',
}

export enum InvoiceStatus {
    Canceled = 'Canceled',
    Draft = 'Draft',
    Final = 'Final',
}

export enum OpenInvoiceStatus {
    WaitingForPayment = 'WaitingForPayment',
    NeedsSignatureForDUO = 'NeedsSignatureForDUO',
    WaitingForDUOExportBatch = 'WaitingForDUOExportBatch',
    RejectedByDUO = 'RejectedByDUO',
    WaitingForDUOApproval = 'WaitingForDUOApproval',
}

export enum InvoicePaymentStatus {
    Open = 'Open',
    Paid = 'Paid',
}

export enum InvoiceFilterStatus {
    StatusDraft = 'StatusDraft',
    OpenStatusNeedsSignatureForDUO = 'OpenStatusNeedsSignatureForDUO',
    OpenStatusWaitingForDUOExportBatch = 'OpenStatusWaitingForDUOExportBatch',
    OpenStatusWaitingForPayment = 'OpenStatusWaitingForPayment',
    OpenStatusWaitingForDUOApproval = 'OpenStatusWaitingForDUOApproval',
    OpenStatusRejectedByDUO = 'OpenStatusRejectedByDUO',
    PaymentStatusPaid = 'PaymentStatusPaid',
    DebitStatusCanceled = 'DebitStatusCanceled',
    CreditStatusCanceled = 'CreditStatusCanceled',
}

export enum InvoiceLogType {
    CreateInvoice = 'createInvoice',
    CreateCreditInvoice = 'createCreditInvoice',
    ChangeInvoice = 'changeInvoice',
    ChangeInvoiceStatus = 'changeInvoiceStatus',
    ChangeOpenInvoiceStatus = 'changeOpenInvoiceStatus',
    ChangeInvoicePaymentStatus = 'changeInvoicePaymentStatus',
    SendInvoice = 'sendInvoice',
    MarkInvoiceAsFullyCredited = 'markInvoiceAsFullyCredited',
    MarkInvoiceAsPartlyCredited = 'markInvoiceAsPartlyCredited',
    SendInvoiceReminder = 'sendInvoiceReminder',
    SignatureAddedToInvoice = 'signatureAddedToInvoice',
    SignatureChangedForInvoice = 'signatureChangedForInvoice',
}

export interface InvoiceLogTypeData {
    reminderCount?: number
    newAmount?: number
    previousAmount?: number
    newInvoiceStatus?: InvoiceStatus
    newOpenInvoiceStatus?: OpenInvoiceStatus
    newOpenInvoiceStatusInfo?: OpenInvoiceStatusInfo
    newInvoicePaymentStatus?: InvoicePaymentStatus
}

export interface OpenInvoiceStatusInfo {
    responseByDUO?: string
}

export interface InvoiceLog {
    _id: string
    type?: InvoiceLogType
    typeData?: InvoiceLogTypeData
    triggeredByUser?: User
    createdAt?: Date
}

export interface InvoicedLessonUser {
    _id: string
    group?: Group
    lessonId?: string
    lessonDate?: Date
    lastDebitInvoiceIdForCreditableLessonUser?: string
    lesson?: Lesson
    user?: User
    createdAt?: Date
}

export interface Invoice {
    _id: string
    isCredit?: boolean
    debitInvoice?: Invoice
    invoiceNumber?: string
    description?: InvoiceDescription
    installmentIndex?: number
    creditDescription?: string
    isGroupParticipationInvoice?: boolean
    isFinalExamInvoice?: boolean
    group?: Group
    user?: User
    amount?: number
    alternativeAmountDescription?: string
    installmentsTotal?: number
    conceptCreditedAmount?: number
    creditedAmount?: number
    invoiceDate?: Date
    expirationDate?: Date
    status?: InvoiceStatus
    openStatus?: OpenInvoiceStatus
    paymentStatus?: InvoicePaymentStatus
    notes?: string
    isExpired?: boolean
    daysExpired?: number
    hasSignatureForDUO?: boolean
    signatureForDuoFile?: SignatureFile
    isForDUO?: boolean
    invoiceSentAt?: Date
    logs?: InvoiceLog[]
    createdAt?: Date
    descendantInstallmentInvoices?: Invoice[]
    hasMultipleInstallments?: boolean
    installments?: number
    installmentLeadingInvoice?: Invoice
    installmentsSubTotal?: number
    isDescendantInstallment?: boolean
    isDownloadable?: boolean
    isFullyCredited?: boolean
    mayBeCredited?: boolean
    remainingConceptCreditableAmount?: number
    isEmailRequiredToSend?: boolean
    emailedAt?: Date
    mayBeCanceled?: boolean
    descendantInstallmentInvoiceHasFailedToSend?: boolean
    lessons?: Lesson[]
    contract?: Contract
    creditableAmountPerLesson?: number
    invoicedLessonUsers?: InvoicedLessonUser[]
}

export interface TwinfieldCustomer {
    code?: string
    name?: string
}

export interface DUODeclaration {
    _id: string
    amount?: number
    description?: string
    isExternal?: boolean
    hasBeenImported?: boolean
    date?: Date
}

export enum InvoiceType {
    GroupParticipation = 'GroupParticipation',
    FinalExam = 'FinalExam',
    LessonMaterials = 'LessonMaterials',
}
