import './AttendanceTableHeaderItem.scss'

import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { TableHeaderItem } from '~/components'

export interface Props {
    className?: string
    fixed?: boolean
}

export class AttendanceTableHeaderItem extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceTableHeaderItem')

    public render() {
        const { children, className } = this.props

        return <TableHeaderItem className={this.bem.getClassName(className)}>{children}</TableHeaderItem>
    }
}
