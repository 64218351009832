export const USER_DOCUMENT_TYPES = [
    {
        name: 'DUO',
    },
    {
        name: 'Curriculum Vitae',
    },
    {
        name: 'Verklaring Omtrent het Gedrag',
    },
    {
        name: 'Diploma',
    },
    {
        name: 'Intake',
        isAccessibleByTeacherOfUser: true,
    },
    {
        name: 'Toets',
        isAccessibleByTeacherOfUser: true,
    },
    {
        name: 'Portfolio',
    },
    {
        name: 'Contract',
    },
    {
        name: 'Factuur',
    },
    {
        name: 'Rapportage',
    },
    {
        name: 'Lesobservatie',
    },
    {
        name: 'Overige',
    },
]
