import './Row.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isHorizontalCentered?: boolean
    spacingBetween?: boolean
    horizontalEnd?: boolean
}

export class Row extends React.Component<Props> {
    private bem = new BEM('Row', () => ({
        'horizontal-center': this.props.isHorizontalCentered,
        'has-spacing-between': this.props.spacingBetween,
        'horizontal-end': this.props.horizontalEnd,
    }))

    public render() {
        const { className, children } = this.props

        return <div className={this.bem.getClassName(className)}>{children}</div>
    }
}
