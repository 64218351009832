import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, Field, FieldCollection, Link, ActionBar } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { WithSubtle } from '~/components/WithSubtle'
import { Translator } from '~/services/i18n'
import { InflowModule } from '~/types/InflowModule'
import { getTimeFromMinutes } from '~/utils/getTimeFromMinutes'
import { translateType } from '~/shared/utils'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
}

export class InflowModuleDataView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })

    public render() {
        const { inflowModule } = this.props
        const { t } = this.translator

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Gegevens`}>
                            <Field title={`Naam`}>{inflowModule.name}</Field>
                            <Field title={`Type instroom`}>
                                {translateType('inflowModuleType', inflowModule.type)}
                            </Field>
                        </FieldGroup>
                        <FieldGroup title={`Toets(en)`}>
                            <Field title={`Af te nemen toets(en)`}>
                                {inflowModule.exams &&
                                    inflowModule.exams.map((exam, i) => {
                                        const selectedAbilities = []
                                        const possibleAbilities = ['converse', 'read', 'write', 'listen', 'talk']

                                        for (const ability in exam.abilities) {
                                            if (possibleAbilities.includes(ability) && exam.abilities[ability]) {
                                                selectedAbilities.push(t(`Properties.exam.abilities.${ability}`))
                                            }
                                        }

                                        if (!exam.exam) {
                                            return null
                                        }

                                        return (
                                            <WithSubtle text={selectedAbilities.join(', ')} key={i}>
                                                <Link key={exam.examId} route={`/properties/exams/${exam.exam._id}`}>
                                                    {exam.exam.name}
                                                </Link>
                                            </WithSubtle>
                                        )
                                    })}
                            </Field>
                            <Field title={`Maximale toetstijd per kandidaat`}>
                                {inflowModule.examMaxTimeMinutes && getTimeFromMinutes(inflowModule.examMaxTimeMinutes)}
                            </Field>
                            <Field title={`Interval tussen startmomenten`}>
                                {inflowModule.examStartIntervalMinutes &&
                                    `${inflowModule.examStartIntervalMinutes} minuten`}
                            </Field>
                            <Field title={`Maximaal aantal kandidaten per startmoment per toetsdocent`}>
                                {inflowModule.maxStudentsPerStart}
                            </Field>
                            <Field title={`Controle op adviesrapport nodig`}>
                                {inflowModule.checkAdviceReportNeeded ? 'Ja' : 'Nee'}
                            </Field>
                        </FieldGroup>
                        <ActionBar
                            fixed={true}
                            isWide={true}
                            getButtons={() => (
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/inflow/${inflowModule._id}/edit`)
                                            }
                                            type={`edit`}
                                        >
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            )}
                        />
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
