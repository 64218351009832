import * as React from 'react'

import { browserHistory } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { CenterModal, FieldCollection, Field, Button } from './'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    renderComponent: (markAsDirty: () => void, markAsPristine: () => void) => React.ReactNode
    route: object
    router: {
        setRouteLeaveHook: (route: object, callback: (to: string) => void) => void
    }
}

interface LeaveConfirmationModal {
    modalActive: boolean
    callback: () => void
}

interface State {
    leaveConfirmationModal: LeaveConfirmationModal | any
}

export class PageQuitConfirm extends React.Component<Props, State> {
    public state: State = {
        leaveConfirmationModal: null,
    }

    private unregisterLeaveHook: any
    private userCanLeavePage: boolean
    private pageLeaveMessage: string

    constructor(props: Props) {
        super(props)

        this.userCanLeavePage = true
    }

    public markAsDirty = () => {
        this.userCanLeavePage = false
    }

    public markAsPristine = () => {
        this.userCanLeavePage = true
    }

    public componentWillMount() {
        const { route, router } = this.props

        this.pageLeaveMessage =
            'Weet je zeker dat je deze pagina wilt verlaten? Er kunnen niet-opgeslagen wijzigingen zijn.'
        this.unregisterLeaveHook = router.setRouteLeaveHook(route, this.routerWillLeave)

        window.addEventListener('beforeunload', this.registerWindowListener)
    }

    public render() {
        const { renderComponent } = this.props
        const leaveConfirmationModal = { ...this.state.leaveConfirmationModal }

        return (
            <React.Fragment>
                {renderComponent(this.markAsDirty, this.markAsPristine)}
                {leaveConfirmationModal.modalActive && (
                    <CSSTransition
                        classNames="tt-appearModal"
                        timeout={225}
                        mountOnEnter={true}
                        unmountOnExit={true}
                        in={leaveConfirmationModal.modalActive}
                    >
                        <CenterModal
                            small
                            title={`Waarschuwing`}
                            onClose={() => {
                                leaveConfirmationModal.modalActive = false
                                this.setState({ leaveConfirmationModal })
                            }}
                        >
                            <FieldCollection style={`modal`}>
                                <FieldGroup>
                                    <Field>{this.pageLeaveMessage}</Field>
                                </FieldGroup>
                                <FieldCollectionFooter>
                                    <List horizontal>
                                        <ListItem right>
                                            <Button
                                                onClick={() => {
                                                    leaveConfirmationModal.modalActive = false
                                                    this.setState({ leaveConfirmationModal })
                                                }}
                                            >
                                                Annuleren
                                            </Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button
                                                type={`danger`}
                                                onClick={() => this.state.leaveConfirmationModal.callback()}
                                            >
                                                Verlaten
                                            </Button>
                                        </ListItem>
                                    </List>
                                </FieldCollectionFooter>
                            </FieldCollection>
                        </CenterModal>
                    </CSSTransition>
                )}
            </React.Fragment>
        )
    }

    public componentWillUnmount() {
        this.unregisterLeaveHook()
        window.removeEventListener('beforeunload', this.registerWindowListener)
    }

    private registerWindowListener = (e: any) => {
        this.routerWillLeave(null, e)
    }

    private routerWillLeave = (toRoute: any, e?: any) => {
        if (e && !this.userCanLeavePage) {
            e.returnValue = this.pageLeaveMessage
            return
        } else {
            if (this.userCanLeavePage) {
                return true
            }

            this.setState({
                leaveConfirmationModal: {
                    modalActive: true,
                    callback: () => {
                        this.userCanLeavePage = true

                        if (toRoute) {
                            browserHistory.push(toRoute.pathname)
                        }
                    },
                },
            })

            return false
        }
    }
}
