import React from 'react'
import { List } from '~/components/List'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class PageTabs extends React.Component<Props> {
    private bem = new BEM('PageTabs')

    public render() {
        const { children, className } = this.props

        return (
            <nav className={this.bem.getClassName(className)}>
                <div className={this.bem.getElement('scroller')}>
                    <div className={this.bem.getElement('wrapper')}>
                        <List horizontal={true}>{children}</List>
                    </div>
                </div>
            </nav>
        )
    }
}
