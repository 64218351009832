/**
 * Translate a group validation error
 *
 * @param {String} key The error key
 * @param {Object} info Contextual information
 */
export default function translateGroupValidationError(key: string, info: any) {
    return {
        // Module
        'module-required': () => `Lesmodule is verplicht.`,
        'module-must-be-active': () => `Lesmodule is inactief.`,
        'module-lesson-duration-required': () => `De lesmodule bevat geen lesduur.`,
        'module-amount-of-total-lessons-required': () => `De lesmodule bevat geen "totaal aantal lessen".`,
        'module-amount-of-weekly-lessons-required': () => `De lesmodule bevat geen "aantal lessen per week".`,

        // LessonTeachers
        'startdate-required': () => `De startdatum is verplicht`,
        'general-week-lesson-teachers-required': () => `Sommige lesdagen missen een docent.`,
        'general-week-lesson-teachers-must-be-part-of-group': () => `Sommige docenten zit(ten) niet in deze groep.`,

        // StartWeek
        'startweek-required': () => `De startweek is verplicht`,

        // LessonDates
        'general-week-lesson-dates-required': () => `Er zijn nog geen lesdagen gekozen.`,
        'general-week-lesson-dates-must-match-module-settings': () => {
            const diff = info.shouldBe - info.is

            // If diff is positive it means there are not enough lesson dates
            // If diff is negative it means there are too much lesson dates
            if (diff > 0) {
                return diff === 1
                    ? `Er moet nog ${diff} lesdag gekozen worden.`
                    : `Er moeten nog ${diff} lesdagen gekozen worden.`
            } else {
                return diff === -1
                    ? `Er is ${-diff} lesdag te veel gekozen.`
                    : `Er zijn ${-diff} lesdagen te veel gekozen.`
            }
        },

        // Learners
        'user-learner-inactive': () => `De kandidaat "${info.userName}" is inactief.`,
        'user-learner-missing-advice-module': () => `De kandidaat "${info.userName}" mist een advies-lesmodule.`,

        // Teachers
        'user-teacher-inactive': () => `De docent "${info.userName}" is inactief.`,

        // Employees
        'user-employee-inactive': () => `De medewerker "${info.userName}" is inactief.`,

        // Location
        'location-required': () => `Locatie is verplicht.`,
    }[key]()
}
