import React from 'react'
import { User } from '~/types/User'
import { InflowMoment } from '~/types/InflowMoments'
import { Mutator, transformFormFields, Fetcher } from '~/utils'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import {
    Form,
    DrawerHeader,
    Button,
    TableWrap,
    TableHeader,
    TableHeaderItem,
    TableRow,
    Link,
    Icon,
    Subtle,
} from '~/components'
import { TableView } from '~/components/TableView'
import { ContentView } from '~/components/ContentView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { Wrap } from '~/components/Wrap'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    inflowMoment: InflowMoment
    title: string
    closeModal: () => void
    refetch: () => void
    selectedUsers: User[]
    users: User[]
    userRoleType: InflowMomentUserRoleType
    userFetcher: Fetcher<any>
    userPaginator: Paginator
    renderActionBar?: () => JSX.Element
}

interface State {
    checkedUsers: User[]
    // uncheckedUsers: User[]
}

export enum InflowMomentUserRoleType {
    intaker = 'intaker',
    employee = 'employee',
}

export class InflowUserForm extends React.Component<Props, State> {
    public state: State = {
        checkedUsers: this.props.selectedUsers,
    }

    private InflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.InflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { closeModal, title, userPaginator, userFetcher, renderActionBar } = this.props
        const { loading: mutationLoading } = this.InflowMomentMutator
        const { loading } = userFetcher
        const { checkedUsers } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={title} close={closeModal} />
                <TableWrap insetTable={true}>
                    <TableView>{this.renderUserTable(checkedUsers, true)}</TableView>
                </TableWrap>
                <ContentView>
                    <InfiniteScroll paginator={userPaginator} preventLoad={loading}>
                        <Wrap full={true}>{renderActionBar && renderActionBar()}</Wrap>
                        <TableWrap>
                            <TableView>{this.renderUserTable(this.getUncheckedUsers())}</TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter close={closeModal} isLoading={mutationLoading} />
            </Form>
        )
    }

    private addUser = (user: User) => {
        this.setState({
            checkedUsers: [...this.state.checkedUsers, user],
        })
    }

    private removeUser = (user: User) => {
        this.setState({
            checkedUsers: this.state.checkedUsers.filter(({ _id }) => _id !== user._id),
        })
    }

    private renderUserTable = (users: User[], forSelected?: boolean) => {
        const { inflowMoment, userRoleType } = this.props

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem>Naam</TableHeaderItem>
                    <TableHeaderItem forIconButton={true} />
                    <TableHeaderItem>Woonplaats</TableHeaderItem>
                </TableHeader>
                {users.map(user => (
                    <TableRow key={user._id}>
                        <TableCell>
                            <Link route={getUserDetailRoute(user)} target={`_blank`}>
                                {user.profile.name}
                            </Link>
                        </TableCell>
                        <TableCell forIconButton={true}>
                            {forSelected ? (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`minus`} />}
                                    className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                                    onClick={() => this.removeUser(user)}
                                />
                            ) : (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`plus`} />}
                                    className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                                    onClick={() => this.addUser(user)}
                                />
                            )}
                        </TableCell>
                        <TableCell>
                            {user.profile.address &&
                                user.profile.address.nl &&
                                user.profile.address.nl.extraInfo &&
                                user.profile.address.nl.extraInfo.city}
                        </TableCell>
                    </TableRow>
                ))}
                {users.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={3}>
                            {forSelected ? 'Nog geen gebruikers toegevoegd' : 'Geen gebruikers gevonden'}
                        </TableCell>
                    </TableRow>
                )}
                {forSelected &&
                    userRoleType === InflowMomentUserRoleType.intaker &&
                    inflowMoment.minimumTeachersNeeded && (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Subtle>Minimaal {inflowMoment.minimumTeachersNeeded} toetsdocenten</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
            </Table>
        )
    }

    private onSubmit = async () => {
        const { closeModal, refetch, inflowMoment, userRoleType } = this.props

        const fields = {
            _id: inflowMoment._id,
            [`${userRoleType}UserIds`]: this.state.checkedUsers.map(user => user._id),
        }

        this.InflowMomentMutator.mutate({
            inflowMoment: transformFormFields(fields, {}),
        }).then(data => {
            if (data) {
                closeModal()

                if (refetch) {
                    refetch()
                }
            }
        })
    }

    private getUncheckedUsers() {
        const { users } = this.props
        const { checkedUsers } = this.state

        const initialUserIds = checkedUsers.map(user => user._id)

        return users.filter((user: User) => initialUserIds.indexOf(user._id) < 0)
    }
}
