import * as React from 'react'
import ActionBar from '~/components/ActionBar'
import { ButtonGroup } from '~/components/ButtonGroup'
import FileInput from '~/components/FileInput'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import Link from '~/components/Link'
import ReadableDate from '~/components/ReadableDate'
import { InvoiceStatusIndicator, Button, Icon } from '~/components'
import { Label } from '~/components/Label/Label'
import { gql } from '@apollo/client'
import { TableView } from '~/components/TableView'
import TableWrap from '~/components/TableWrap'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import translateType from '~/shared/utils/translateType'
import { FinalExam, FinalExamPart, FinalExamSubmitters } from '~/types/FinalExam'
import { Fetcher, removeDuplicateDocuments, toast } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import Sorter from '~/utils/Sorter'
import Mutator from '~/utils/Mutator'
import { ModalManager } from '../ModalManager'
import { CounterIcon } from '../CounterIcon'
import { FinalExamsUploadErrorsTable } from './FinalExamsUploadErrorsTable'
import DrawerModal from '../DrawerModal'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'DESC'
const DEFAULT_SORT_BY = 'date'

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
    isLoadingDUOResultFileUpload: boolean
}

const UPLOAD_DUO_EXAM_RESULTS_MUTATION = gql`
    mutation finalExams_uploadDUOResults($resultsFile: UploadedFile) {
        finalExams_uploadDUOResults(resultsFile: $resultsFile)
    }
`

const GET_FINAL_EXAMS_QUERY = gql`
    query finalExams($skip: Int, $limit: Int, $filter: FinalExamsFilterInputType, $sortDir: String, $sortBy: String) {
        finalExams(skip: $skip, limit: $limit, filters: $filter, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            date
            part
            level
            attempt
            result
            user {
                _id
                profile {
                    name
                }
            }
            invoice {
                _id
                daysExpired
                hasSignatureForDUO
                invoiceNumber
                invoiceSentAt
                isCredit
                isDescendantInstallment
                isDownloadable
                isExpired
                isForDUO
                isForDUO
                isFullyCredited
                isGroupParticipationInvoice
                isFinalExamInvoice
                status
                openStatus
                paymentStatus
            }
            isInvoiceActivatable
            submitter
            canceledAt
        }
    }
`

const GET_FINAL_EXAMS_IMPORT_ERRORS_COUNT_QUERY = gql`
    query DUOFinalExamResultErrors {
        DUOFinalExamResultErrors_count
    }
`

export class FinalExamsPlannedTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
        isLoadingDUOResultFileUpload: false,
    }

    private finalExamsFetcher: Fetcher
    private finalExamsImportErrorsCountFetcher: Fetcher
    private paginator: Paginator
    private sorter: Sorter
    // private filter: Filter
    private examDUOResultFileUploadMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.finalExamsFetcher = new Fetcher({
            query: GET_FINAL_EXAMS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                // filters: this.filter.getFilters(),
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.finalExamsImportErrorsCountFetcher = new Fetcher({
            query: GET_FINAL_EXAMS_IMPORT_ERRORS_COUNT_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.examDUOResultFileUploadMutator = new Mutator({
            mutation: UPLOAD_DUO_EXAM_RESULTS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.finalExamsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    public render() {
        const { paginator } = this
        const { loading } = this.finalExamsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Wrap full={true}>{this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar = () => {
        const { isLoadingDUOResultFileUpload } = this.state
        const { data } = this.finalExamsImportErrorsCountFetcher

        const count = data.DUOFinalExamResultErrors_count

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem right={true}>
                            <ButtonGroup joined={true}>
                                <FileInput
                                    name={`documents[0].file`}
                                    onChange={this.onChangeDUOResultFileInput}
                                    hideFileName={true}
                                    isLoading={isLoadingDUOResultFileUpload}
                                    accept={`.csv`}
                                >
                                    DUO-resultaten importeren
                                </FileInput>
                                <ModalManager
                                    render={openModal => (
                                        <Button
                                            leftIcon={<Icon name={`status_attention`} color={`#a8aeb3`} />}
                                            rightIcon={
                                                <CounterIcon
                                                    amount={count || 0}
                                                    className={`tt-Icon--counter-icon--red-square`}
                                                />
                                            }
                                            onClick={openModal}
                                        >
                                            Foutmeldingen
                                        </Button>
                                    )}
                                    getModal={closeModal => {
                                        return (
                                            <DrawerModal onClose={closeModal}>
                                                <FinalExamsUploadErrorsTable closeModal={closeModal} />
                                            </DrawerModal>
                                        )
                                    }}
                                />
                            </ButtonGroup>
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private renderTable = () => {
        const { data, loading } = this.finalExamsFetcher
        const { finalExams } = data || []

        const totalColSpan = 7

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem sorter={this.sorter} sortBy={`date`} width={`200px`}>
                            Examendatum
                        </TableHeaderItem>
                        <TableHeaderItem>Onderdeel</TableHeaderItem>
                        <TableHeaderItem>Poging</TableHeaderItem>
                        <TableHeaderItem>Resultaat</TableHeaderItem>
                        <TableHeaderItem>Kandidaat</TableHeaderItem>
                        <TableHeaderItem>Aangemeld door</TableHeaderItem>
                        <TableHeaderItem>Factuurstatus</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading={true} />
                        </TableRow>
                    ) : finalExams.length > 0 ? (
                        this.renderFinalExamRows(finalExams)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen geplande examens gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderFinalExamRows = (finalExams: FinalExam[]) => {
        return finalExams.map(finalExam => {
            const examDate = finalExam.date
            const hasResult = !!finalExam.result
            const isCanceled = !!finalExam.canceledAt

            const examPart = finalExam.part
            const translatedExamPart = translateType('finalExamPart', examPart)
            const partIsOnaOrKnm = examPart === FinalExamPart.KNM || examPart === FinalExamPart.ONA

            return (
                <TableRow key={finalExam._id}>
                    <TableCell hasLineThrough={isCanceled}>
                        <Link route={`/final-exams/planned/${finalExam._id}`}>
                            {examDate && <ReadableDate date={finalExam.date} />}
                            {!examDate && 'Extern aangemeld'}
                            {isCanceled && <Label hasInverseColors={true}>Geannuleerd</Label>}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {translatedExamPart}
                        {` `}
                        {!partIsOnaOrKnm && finalExam.level}
                    </TableCell>
                    <TableCell>{finalExam.attempt}</TableCell>
                    <TableCell>
                        {hasResult && finalExam.result ? translateType('finalExamResult', finalExam.result) : '-'}
                        {!hasResult && '-'}
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/learners/${finalExam.user._id}`}>{finalExam.user.profile.name}</Link>
                    </TableCell>
                    <TableCell>{this.getSubmittedBy(finalExam)}</TableCell>
                    <TableCell>
                        <InvoiceStatusIndicator
                            invoice={finalExam.invoice}
                            ensureShowIconOnly={true}
                            isActivatableForFinalExamId={(finalExam.isInvoiceActivatable && finalExam._id) || undefined}
                            iconRoute={`/users/learners/${finalExam.user._id}/invoicing`}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    }

    private getSubmittedBy = (finalExam: FinalExam) => {
        const { submitter } = finalExam
        const submittedByExternal = submitter === FinalExamSubmitters.External
        const submittedByTopTaal = submitter === FinalExamSubmitters.TopTaal
        const submittedBy = submittedByExternal ? 'Extern' : submittedByTopTaal ? 'TopTaal' : null

        return submittedBy
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.finalExamsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.finalExams.length === 0,
                })

                return {
                    finalExams: removeDuplicateDocuments([...(prevData.finalExams || []), ...moreData.finalExams]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onChangeDUOResultFileInput = async (event: any) => {
        const { files } = event.currentTarget

        const [file] = files || [undefined]

        if (!file) {
            return
        }

        this.setState({
            isLoadingDUOResultFileUpload: true,
        })

        const result = await this.examDUOResultFileUploadMutator.mutate({
            resultsFile: files[0],
        })

        this.setState({
            isLoadingDUOResultFileUpload: false,
        })

        if (result && result.finalExams_uploadDUOResults) {
            toast.success('De resultaten zijn bijgewerkt')
            this.finalExamsFetcher.refetch()
            this.finalExamsImportErrorsCountFetcher.refetch()
        }
    }
}
