import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { Paragraph, Button } from '..'
import { ContentView } from '../ContentView'
import { Wrap } from '../Wrap'
import { Article } from '../Article'
import { browserHistory, RouteComponentProps } from 'react-router'

interface Props {
    routeProps: RouteComponentProps<any, any>
}

interface State {
    error?: Error
    errorInfo?: React.ErrorInfo
    eventId?: string
}

export class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {}

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })

        Sentry.withScope(scope => {
            // scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({ eventId, errorInfo })
        })
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.routeProps.location.pathname !== this.props.routeProps.location.pathname) {
            this.setState({
                error: undefined,
                errorInfo: undefined,
            })
        }
    }

    public render() {
        const { children } = this.props

        if (this.state.error) {
            return (
                <ContentView>
                    <Wrap>
                        <Article>
                            <Paragraph>Er is een probleem opgetreden met deze pagina.</Paragraph>
                            <Paragraph>
                                <Button onClick={this.goToHome}>Naar het begin</Button>
                            </Paragraph>
                        </Article>
                    </Wrap>
                </ContentView>
            )
        }

        return children
    }

    private goToHome = () => {
        browserHistory.push('/')
    }
}
