import { gql } from '@apollo/client'
import { find, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Spinner } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditGroupModuleForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
        group: PropTypes.object.isRequired,
        refetch: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedProjectId: get(props, 'group.project._id'),
            selectedProgramId: get(props, 'group.program._id'),
        }

        this.groupEditMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })

        this.dataFetcher = new Fetcher({
            query: PROJECT_MODULE_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.onProjectChange = this.onProjectChange.bind(this)
        this.onProgramChange = this.onProgramChange.bind(this)
    }

    onProjectChange(event) {
        this.setState({ selectedProjectId: event ? event.value : null })
    }

    onProgramChange(event) {
        this.setState({ selectedProgramId: event ? event.value : null })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, group } = this.props

        this.groupEditMutator
            .mutate({
                group: {
                    _id: group._id,
                    ...transformFormFields(fields.group),
                },
            })
            .then(data => {
                const { refetch } = this.props

                if (data) {
                    refetch()
                    onSubmitSuccess()
                }
            })
    }

    render() {
        const { onCancel, group } = this.props

        const { data, loading: isFetchLoading } = this.dataFetcher
        const { errors, loading: isMutationLoading } = this.groupEditMutator

        const { projects = [] } = data

        const { selectedProjectId, selectedProgramId } = this.state

        const module = get(group, 'module') || null
        const project = get(group, 'project') || null
        const program = get(group, 'program') || null

        const selectedProject = find(projects, { _id: selectedProjectId })
        const selectedProgram = selectedProject && find(selectedProject.programs, { _id: selectedProgramId })

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    {isFetchLoading ? (
                        <Spinner />
                    ) : (
                        <FieldGroup isForm>
                            <Field isLabel title={'Project'} errors={errors}>
                                <TagPicker
                                    name={`group.projectId`}
                                    placeholder={`Selecteer een project`}
                                    defaultValue={selectedProjectId ? selectedProjectId : project ? project._id : null}
                                    multi={false}
                                    onChange={this.onProjectChange}
                                    options={projects.map(p => ({ label: p.name, value: p._id }))}
                                />
                            </Field>
                            <Field isDisabled={!selectedProject} title={'Opleiding'} errors={errors}>
                                <TagPicker
                                    name={`group.programId`}
                                    placeholder={`Selecteer een opleiding`}
                                    defaultValue={selectedProgramId ? selectedProgramId : program ? program._id : null}
                                    multi={false}
                                    onChange={this.onProgramChange}
                                    options={
                                        selectedProject
                                            ? selectedProject.programs.map(p => ({ label: p.name, value: p._id }))
                                            : []
                                    }
                                />
                            </Field>
                            <Field isDisabled={!selectedProgram} title={'Lesmodule'} errors={errors}>
                                <TagPicker
                                    name={`group.moduleId`}
                                    placeholder={`Selecteer een module`}
                                    defaultValue={module ? module._id : null}
                                    multi={false}
                                    options={
                                        selectedProgram
                                            ? selectedProgram.modules.map(m => ({ label: m.name, value: m._id }))
                                            : []
                                    }
                                />
                            </Field>
                        </FieldGroup>
                    )}
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={isMutationLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`

const PROJECT_MODULE_QUERY = gql`
    query _ {
        projects(sortBy: "name") {
            _id
            name
            programs {
                _id
                name
                modules {
                    _id
                    name
                }
            }
        }
    }
`
