import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, Field, FieldCollection, ActionBar } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { InviteTextViewer } from '~/components/InviteText/InviteTextViewer/InviteTextViewer'
import { downloadFile } from '~/utils'
import { getCurrentUser } from '~/services/session'
import { Module } from '~/types/Module'
import { GroupInviteTemplate, groupInviteTemplateTextVariables } from '~/types/groupInviteTemplate'

interface RouteParams {
    id: string
    inviteId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    module: Module
    groupInviteTemplate: GroupInviteTemplate
    refetch: () => void
}

interface State {}

export class AppPropertiesModulesInvitesDetailData extends React.Component<Props, State> {
    public state: State = {}

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { module, groupInviteTemplate } = this.props
        const user = getCurrentUser()

        return (
            <Wrap>
                <FieldCollection>
                    <FieldGroup title={`Gegevens`}>
                        <Field title={'Naam uitnodigingsbrief'}>{groupInviteTemplate.name}</Field>
                    </FieldGroup>
                    <FieldGroup title={'Inhoud'}>
                        <Field title={''}>
                            <InviteTextViewer
                                textVariables={groupInviteTemplateTextVariables}
                                text={groupInviteTemplate.nl && groupInviteTemplate.nl.content}
                                textEnglish={groupInviteTemplate.en && groupInviteTemplate.en.content}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldGroup title={`Bijlagen`}>
                        <Field title={'Bestanden'}>
                            <List>
                                {groupInviteTemplate.attachments &&
                                    groupInviteTemplate.attachments.map(file => {
                                        const { _id, fileName } = file

                                        return (
                                            <ListItem key={`file-${_id}`}>
                                                <Button
                                                    linkStyle={`default`}
                                                    onClick={() => downloadFile(_id, fileName)}
                                                >
                                                    {fileName}
                                                </Button>
                                            </ListItem>
                                        )
                                    })}
                            </List>
                        </Field>
                    </FieldGroup>
                    {user.isAdmin && (
                        <ActionBar
                            getButtons={() => (
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            type={'edit'}
                                            onClick={() =>
                                                browserHistory.push(
                                                    `/properties/modules/${module._id}/invites/${groupInviteTemplate._id}/edit`
                                                )
                                            }
                                        >
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            )}
                            fixed={true}
                            isWide={true}
                        />
                    )}
                </FieldCollection>
            </Wrap>
        )
    }
}
