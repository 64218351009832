import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

export default class ProgramsView extends Component {
    static propTypes = {
        children: PropTypes.any,
    }

    render() {
        const { children, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Opleidingen'} routeProps={routeProps}>
                {children}
            </RouteView>
        )
    }
}
