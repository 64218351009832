import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentView } from '~/components/ContentView'
import { LearnerAvailability } from '~/implementations'

export class ExternalTeacherIntakesDetailAvailabilityView extends Component {
    static propTypes = {
        userId: PropTypes.string,
    }

    render() {
        const { userId } = this.props

        return (
            <ContentView>
                <LearnerAvailability userId={userId} />
            </ContentView>
        )
    }
}
