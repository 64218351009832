import React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    name?: string
    placeholder?: string
    className?: ClassValue
    hasError?: boolean
    isDisabled?: boolean
    isReadonly?: boolean
    value?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['value']
    defaultValue?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['defaultValue']
    style?: object
    autoFocus?: boolean
    errorKey?: string
}

export default class TextArea extends React.Component<Props> {
    private bem = new BEM('TextArea', () => ({
        'is-disabled': this.props.isDisabled,
        'is-readonly': this.props.isReadonly,
        'has-error': this.props.hasError,
    }))

    public render() {
        const {
            className,
            name,
            placeholder,
            value,
            style,
            isDisabled,
            isReadonly,
            defaultValue,
            autoFocus,
            onFocus,
            onBlur,
            onChange,
            errorKey: _errorKey,
        } = this.props

        const errorKey = _errorKey || name

        return (
            <textarea
                className={this.bem.getClassName(className)}
                name={name}
                placeholder={placeholder}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                style={style}
                disabled={isDisabled}
                readOnly={isReadonly}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
                // TODO: Fix this issue and re-enable the no-string-refs rule
                // eslint-disable-next-line react/no-string-refs
                ref={'textarea'}
                data-errorkey={errorKey}
            />
        )
    }
}
