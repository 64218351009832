import { get } from 'lodash'
import React, { Component } from 'react'

import { Button, CenterModal, CheckBox, Field, FieldCollection } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { welcomeModalSettings } from '~/services/localStorageSettings'
import { getCurrentUser, loginSubscriber } from '~/services/session'

export default class WelcomeModal extends Component {
    static shouldOpenWhenMounted = false

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            showWelcomeModal: false,
            isWelcomeModalIgnored: false,
        }

        loginSubscriber.subscribe(() => {
            const user = getCurrentUser()

            const isWelcomeModalIgnored = welcomeModalSettings.isIgnoredForUser(user)

            if (!isWelcomeModalIgnored && user && user.isExternalTeacher) {
                this.setState({ showWelcomeModal: true, user })
            }
        })
    }

    closeModal = () => {
        this.setState({ showWelcomeModal: false })
    }

    checkClickHandler = event => {
        if (event.target.checked) {
            welcomeModalSettings.disableForUser(this.state.user)
        } else {
            welcomeModalSettings.enableForUser(this.state.user)
        }
    }

    render() {
        const { showWelcomeModal, user } = this.state
        const userIsIntaker = (get(user, 'roles') || []).indexOf('INTAKER') > -1

        return (
            <div>
                {showWelcomeModal && (
                    <CenterModal
                        className={`tt-WelcomeModal`}
                        onClose={this.closeModal}
                        title={`Welkom op het docentenportaal van TopTaal!`}
                    >
                        <FieldCollection style={`modal`}>
                            <FieldGroup>
                                <Field>
                                    <p>
                                        Met dit systeem zijn we altijd op de hoogte van de presentie, voortgang en
                                        bijzonderheden van de kandidaten.
                                    </p>
                                </Field>

                                <Field title={`Groepen`}>
                                    <p>
                                        Bij GROEPEN vind je een overzicht van jouw groepen. Als je op de naam van een
                                        groep klikt, zie je alle lessen van die groep. Klik op een van de lessen en dan
                                        op ‘Presentie bewerken’ om de aanwezigheid in te voeren. Klik op het tabje
                                        ‘Informatie’ voor meer gegevens over de lesmodule.
                                    </p>
                                </Field>

                                <Field title={`Beschikbaarheid`}>
                                    <p>
                                        Bij BESCHIKBAARHEID kun je aangeven wanneer je tijd hebt om lessen te geven voor
                                        TopTaal. Dit werkt ongeveer net zoals een outlook-agenda. Je kunt losse items
                                        aanmaken (beschikbaarheid op één bepaalde dag of dagdeel), of door middel van de
                                        knop ‘kopiëren’ herhalingsitems aanmaken. Doe dit voor een aantal maanden
                                        vooruit. Dan weten wij altijd wanneer je beschikbaar bent en kunnen we je
                                        benaderen als we een concrete groep voor je hebben.
                                    </p>
                                </Field>

                                {userIsIntaker && (
                                    <Field title={`Intaker`}>
                                        <p>
                                            Ben je intaker? Bij INTAKES vind je alle kandidaten die je moet intaken.
                                            Klik op de naam van de kandidaat om alle gegevens en toetsresultaten in te
                                            voeren. Wil je intaker worden? Geef dit dan door aan je projectleider.
                                        </p>
                                    </Field>
                                )}
                                {userIsIntaker && (
                                    <Field title={`Correspondentie`}>
                                        <p>
                                            Onder een kandidaat vind je ook het tabje ‘Correspondentie’ waar je de
                                            voortgang van de kandidaat noteren of andere bijzonderheden gedurende de
                                            cursus. Je projectleider krijgt hiervan dan bericht.
                                        </p>
                                    </Field>
                                )}

                                <Field title={`Heb je vragen?`}>
                                    <p>Bel dan met ons op 088-8545000.</p>
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem left>
                                        <CheckBox onClick={this.checkClickHandler}>
                                            <span>Deze melding niet meer weergeven</span>
                                        </CheckBox>
                                    </ListItem>
                                    <ListItem right>
                                        <Button onClick={this.closeModal}>Sluiten</Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </CenterModal>
                )}
            </div>
        )
    }
}
