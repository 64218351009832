import React from 'react'
import { List } from '~/components/List'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class FilterFieldCollection extends React.Component<Props> {
    private bem = new BEM('FilterFieldCollection')

    public render() {
        const { children } = this.props

        return (
            <List className={this.bem.getClassName()} horizontal={true}>
                {children}
            </List>
        )
    }
}
