import React from 'react'
import { translateLanguage } from '~/utils'

interface Props {
    // For a single language
    code?: string

    // For single or multiple languages
    codes?: string[]
}

export default class ReadableLanguage extends React.Component<Props> {
    public render() {
        const { code, codes } = this.props
        const multipleCodes = codes || [code]

        if (multipleCodes.length === 0) {
            return <span />
        }

        return (
            <span>
                {multipleCodes.map((code, i, arr) => (
                    <span key={i}>
                        {code && translateLanguage(code)}
                        {i < arr.length - 1 && ', ' /* comma separator */}
                    </span>
                ))}
            </span>
        )
    }
}
