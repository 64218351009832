import { find, findIndex } from 'lodash'

const scrollListeners = {}

const NEAR_BOTTOM_OFFSET = 250

interface HandlerInterface {
    cb: any
    type: any
}

function onScroll(event: any) {
    const listenerId = event.target === document ? Symbol.for(window as any) : Symbol.for(event.target)

    const listener = scrollListeners[listenerId]

    const fromBottom = calculateScrollFromBottom(listener.scrollElement)

    listener.handlers.forEach((handler: HandlerInterface) => {
        if (handler.type === 'close-to-bottom' && fromBottom <= NEAR_BOTTOM_OFFSET) {
            handler.cb()
        } else if (handler.type === 'bottom' && fromBottom <= 0) {
            handler.cb()
        } else if (handler.type === 'all') {
            handler.cb()
        }
    })
}

function calculateScrollFromBottom(element: any) {
    if (element === window) {
        return document.documentElement.scrollHeight - document.documentElement.clientHeight - window.pageYOffset
    }

    return element.scrollHeight - element.clientHeight - element.scrollTop
}

export function addScrollListener(type: any, cb: any, $scrollElement: any) {
    const listenerId = Symbol.for($scrollElement) || Symbol($scrollElement)

    if (!scrollListeners[listenerId]) {
        scrollListeners[listenerId] = {
            scrollElement: $scrollElement,
            handlers: [],
        }
        $scrollElement.addEventListener('scroll', onScroll)
    }

    // we don't want to put the same callback handler on the stack twice
    const handlerExists = !!find(
        scrollListeners[listenerId].handlers,
        ({ cb: _cb, type: _type }) => _cb === cb && _type === type
    )
    if (handlerExists) {
        return
    }

    scrollListeners[listenerId].handlers.push({
        cb,
        type,
    })
}

export function removeScrollListener(type: any, cb: any, $scrollElement: any) {
    const listenerId = Symbol.for($scrollElement)

    if (!listenerId) {
        return
    }

    const listener = scrollListeners[listenerId]

    const indexOfCallback = findIndex(listener.handlers, ({ cb: _cb, type: _type }) => _cb === cb && _type === type)
    if (indexOfCallback === -1) {
        return
    }

    listener.handlers.splice(indexOfCallback, 1)

    if (!listener.handlers.length) {
        $scrollElement.removeEventListener('scroll', onScroll)
        delete scrollListeners[listenerId]
    }
}
