import c from 'classnames'
import * as React from 'react'
import { isReactNodeEmpty } from '~/utils/isReactNodeEmpty'

interface Props {
    className?: string
    colSpan?: number
    isBold?: boolean
    isSubtle?: boolean
    shouldPreventWrap?: boolean
    width?: string
    right?: boolean
}

export class SimpleTableCell extends React.Component<Props> {
    public render() {
        const { colSpan = 1, width } = this.props

        const style = width ? { width } : undefined

        return (
            <td className={this.getClassName()} colSpan={colSpan} style={style}>
                {this.renderChildren()}
            </td>
        )
    }

    public getClassName() {
        const { className, isBold, isSubtle, shouldPreventWrap, right } = this.props

        return c(
            'tt-SimpleTableCell',
            {
                'tt-SimpleTableCell--is-bold': isBold,
                'tt-SimpleTableCell--is-subtle': isSubtle,
                'tt-SimpleTableCell--should-prevent-wrap': shouldPreventWrap,
                'tt-SimpleTableCell--right': right,
            },
            className
        )
    }

    private renderChildren() {
        const { children } = this.props

        // This fixes the problem with empty table cells in Firefox
        return isReactNodeEmpty(children) ? <>&nbsp;</> : children
    }
}
