import { gql } from '@apollo/client'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Paragraph, Spinner } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'

import { InflowModule } from '~/types/InflowModule'
import { Fetcher } from '~/utils'

interface RouteParams {
    id: string
    inviteId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
    children: any
}

interface State {}

export class AppPropertiesInflowModulesInvitesDetail extends React.Component<Props, State> {
    private inviteFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inviteFetcher = new Fetcher({
            query: INFLOW_INVITE_TEMPLATE_QUERY,
            variables: { _id: props.params.inviteId, inflowModuleId: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { children, inflowModule } = this.props
        const { loading, refetch, data } = this.inviteFetcher
        const inflowInviteTemplate = data && data.inflowInviteTemplates && data.inflowInviteTemplates[0]

        return (
            <>
                {inflowInviteTemplate && React.cloneElement(children, { inflowModule, inflowInviteTemplate, refetch })}
                {loading && (
                    <ContentView>
                        <Wrap>
                            <Spinner />
                        </Wrap>
                    </ContentView>
                )}
                {!loading && !inflowInviteTemplate && (
                    <ContentView>
                        <Wrap>
                            <Paragraph>Uitnodiging niet gevonden</Paragraph>
                        </Wrap>
                    </ContentView>
                )}
            </>
        )
    }
}

const INFLOW_INVITE_TEMPLATE_QUERY = gql`
    query ($inflowModuleId: MongoID, $_id: MongoID) {
        inflowInviteTemplates(inflowModuleId: $inflowModuleId, byId: $_id) {
            _id
            inflowModule {
                _id
                name
            }
            name
            updatedAt
            attachments {
                _id
                fileName
            }
            nl {
                content
            }
            en {
                content
            }
        }
    }
`
