import * as React from 'react'
import { ModalManager } from '~/components/ModalManager'
import Icon from '~/components/Icon'
import DrawerModal from '~/components/DrawerModal'
import { AddTeacherGroupUserDrawerForm } from '~/forms/Groups/AddTeacherGroupUserDrawerForm'
import { Button } from '~/components/buttons/Button/Button'

interface Props {
    groupId: string
    onParticipantAdded: () => void
}

export class AddTeacherParticipantToGroupButton extends React.Component<Props> {
    public render() {
        const { groupId, onParticipantAdded } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Docent toevoegen
                    </Button>
                )}
                getModal={closeModal => (
                    <DrawerModal onClose={closeModal}>
                        <AddTeacherGroupUserDrawerForm
                            groupId={groupId}
                            onCancel={closeModal}
                            onSuccess={() => {
                                closeModal()
                                onParticipantAdded()
                            }}
                        />
                    </DrawerModal>
                )}
            />
        )
    }
}
