import './AttendanceTableRowInputSpacer.scss'
import React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
}

export const AttendanceTableRowInputSpacer: React.SFC<Props> = props => {
    const { children, className } = props
    const bem = new BEM('AttendanceTableRowInputSpacer')

    return <div className={bem.getClassName(className)}>{children}</div>
}
