import { gql } from '@apollo/client'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Bold,
    Button,
    CheckBox,
    Field,
    FilterFieldCollection,
    Icon,
    Link,
    MultiInput,
    Search,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Filter, removeDuplicateDocuments } from '~/utils'
import ConceptGroupUsersForm from './ConceptGroupUsersForm'
import { Paginator } from '~/utils/Paginator'

const START = 40
const INCREASE = 40

const STATIC_TEACHER_USERS_FILTERS = {
    roles: ['TEACHER', 'INTERNAL_TEACHER'],
    filterTeacherActive: true,
}

export default class ConceptGroupTeachersForm extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        onlyAddSingleUser: PropTypes.bool,
        onSuccess: PropTypes.func,
        onCancel: PropTypes.func,
    }

    state = {
        limit: START,
        skip: 0,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onFilter', 'onFilterTagPicker', 'renderUsersTable', 'renderActionBar'])

        const { limit, skip } = this.state

        this.filter = new Filter({
            allowedKeys: ['searchText', 'filterByCityNames', 'excludeRoles'],
            onChange: filters => {
                this.teacherUsersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_TEACHER_USERS_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsExternalTeacher', 'filterIsInternalTeacher'],
                    transform: filters => {
                        const excludeRoles = []
                        if (filters.filterIsExternalTeacher) excludeRoles.push('INTERNAL_TEACHER')
                        if (filters.filterIsInternalTeacher) excludeRoles.push('TEACHER')

                        return {
                            excludeRoles: excludeRoles.length > 0 && excludeRoles.length < 2 ? excludeRoles : null,
                        }
                    },
                    historyTriggerKeys: ['excludeRoles'],
                    parseFromHistory: historyFilters => {
                        const excludeRoles = historyFilters.excludeRoles || []

                        return {
                            filterIsExternalTeacher: excludeRoles.indexOf('INTERNAL_TEACHER'),
                            filterIsInternalTeacher: excludeRoles.indexOf('TEACHER'),
                        }
                    },
                },
            ],
        })

        this.teacherUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.teacherUsersFetcher = new Fetcher({
            query: GET_TEACHER_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_TEACHER_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
                limit,
                skip,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.teacherUsersPaginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    loadMore = (skip, limit, callback) => {
        this.teacherUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    render() {
        const { group, onSuccess, onCancel, onlyAddSingleUser = false } = this.props

        return (
            <ConceptGroupUsersForm
                group={group}
                role="TEACHER"
                usersFetcher={this.teacherUsersFetcher}
                usersPaginator={this.teacherUsersPaginator}
                title={onlyAddSingleUser ? 'Docent toevoegen' : 'Docenten toevoegen'}
                renderUsersTable={this.renderUsersTable}
                renderActionBar={this.renderActionBar}
                isSingle={onlyAddSingleUser}
                onSuccess={onSuccess}
                onCancel={onCancel}
            />
        )
    }

    renderUsersTable(users, addUser, removeUser, areUsersAdded) {
        const { loading } = this.teacherUsersFetcher
        const showSpinner = !areUsersAdded && loading

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem forIconButton></TableHeaderItem>
                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                    <TableHeaderItem>Plaats</TableHeaderItem>
                    <TableHeaderItem colSpan={2}>Type</TableHeaderItem>
                </TableHeader>

                {showSpinner && this.renderLoadingRow()}

                {!showSpinner &&
                    (users.length > 0
                        ? this.renderTeacherRows(users, addUser, removeUser, areUsersAdded)
                        : this.renderEmpyRow(areUsersAdded))}
            </Table>
        )
    }

    renderEmpyRow(areUsersAdded) {
        const placeholder = areUsersAdded
            ? 'Voeg docenten toe middels de onderstaande lijst.'
            : 'Er zijn geen docenten gevonden.'

        return (
            <TableRow key={`emptyresult`}>
                <TableCell colSpan={5}>
                    <Subtle>{placeholder}</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    renderLoadingRow() {
        return (
            <TableRow key={`loading`}>
                <TableCell colSpan={5}>
                    <Spinner instant />
                </TableCell>
            </TableRow>
        )
    }

    renderActionBar() {
        const { loading: loadingUsers } = this.teacherUsersFetcher
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities = [] } = filterParams

        return (
            <ActionBar
                getButtons={(toggleDrawer, drawerActive) => {
                    return (
                        <List horizontal>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`filter`} />}
                                    onClick={() => toggleDrawer()}
                                    isActive={drawerActive}
                                >
                                    Filteropties
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                            </ListItem>
                        </List>
                    )
                }}
                getDrawer={() => {
                    const addressOptions = addresses_usedCities.map(city => ({ value: city, label: city }))

                    return (
                        <FilterFieldCollection>
                            <Field isLabel title={`Plaats`} style={`compact`}>
                                <TagPicker
                                    onChange={this.onFilterTagPicker}
                                    name={`filterByCityNames`}
                                    options={addressOptions}
                                    placeholder={`Selecteer plaats`}
                                />
                            </Field>
                            <Field title={`Type`} style={`compact`}>
                                <MultiInput type={`checkbox`} isVertical>
                                    <CheckBox name={`filterIsExternalTeacher`} onChange={this.onFilter}>
                                        Externe docent
                                    </CheckBox>
                                    <CheckBox name={`filterIsInternalTeacher`} onChange={this.onFilter}>
                                        TopTaal docent
                                    </CheckBox>
                                </MultiInput>
                            </Field>
                        </FilterFieldCollection>
                    )
                }}
            />
        )
    }

    renderTeacherRows(teachers, addUser, removeUser, areUsersAdded) {
        return teachers.map(teacherUser => {
            const isUserAdded = areUsersAdded

            const canExpandRow = get(teacherUser, 'profile.notes')

            return (
                <TableRow
                    key={teacherUser._id}
                    hasExpandableInfo={!!canExpandRow}
                    getExpansion={() => (
                        <TableCell colSpan={5}>{get(teacherUser, 'profile.notes') || 'Geen notities'}</TableCell>
                    )}
                >
                    <TableCell forIconButton>
                        {isUserAdded && this.renderRemoveButton(teacherUser, removeUser)}
                        {!isUserAdded && this.renderAddButton(teacherUser, addUser)}
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/teachers/${teacherUser._id}`} target="_blank" type="emphasized">
                            {teacherUser.profile.name}
                        </Link>
                    </TableCell>
                    <TableCell>{get(teacherUser, 'profile.address.nl.extraInfo.city')}</TableCell>
                    <TableCell>
                        {(get(teacherUser, 'roles') || []).indexOf('TEACHER') > -1 ? 'Extern' : 'Intern'}
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderRemoveButton(user, removeUser) {
        return (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={() => removeUser(user)}
                leftIcon={<Icon name={`trash`} />}
            />
        )
    }

    renderAddButton(user, addUser) {
        const { onlyAddSingleUser } = this.props
        const confirm = onlyAddSingleUser
            ? {
                  title: 'Docent toevoegen',
                  message: (
                      <span>
                          Weet je zeker dat je <Bold>{user.profile.name}</Bold> wilt toevoegen?
                      </span>
                  ),
                  execute: {
                      buttonType: 'submit',
                      title: 'Toevoegen',
                  },
              }
            : undefined

        return (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={() => addUser(user)}
                confirm={confirm}
                leftIcon={<Icon name={`plus`} />}
            />
        )
    }
}

const GET_TEACHER_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(skip: $skip, limit: $limit, sortBy: "profile.firstName", filters: $filters) {
            _id
            roles
            profile {
                name
                notes
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`
