import { gql } from '@apollo/client'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    CheckBox,
    Field,
    FilterFieldCollection,
    Header,
    Icon,
    Link,
    MultiInput,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { View } from '~/components/View'
import { TableView } from '~/components/TableView'
import { Wrap } from '~/components/Wrap'
import { CreateEmployeeForm } from '~/forms'
import { getCurrentUser } from '~/services/session'
import { translateType, types } from '~/shared/utils'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { bindAll } from 'lodash'
import { MutableUserRoles } from '~/types/User'
import { TagPicker } from '~/components/TagPicker'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.firstName'
const DEFAULT_SORT_DIR = 'ASC'

const STATIC_EMPLOYEE_USERS_FILTERS = {
    roles: ['EMPLOYEE'],
}

export default class MasterView extends Component {
    constructor(props) {
        super(props)

        bindAll(this, ['onFilter', 'renderActionBarButtons', 'renderActionBarDrawer', 'onFilterTagPicker'])

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText', 'filterEmployeeActive', 'filterByOneOfMutableRoles'],
            onChange: filters => {
                this.usersFetcher.refetch({
                    filters: {
                        ...STATIC_EMPLOYEE_USERS_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsActive) activeStates.push(true)
                        if (filters.filterIsInactive) activeStates.push(false)

                        return {
                            filterEmployeeActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterEmployeeActive'],
                    parseFromHistory: historyFilters => {
                        if ('filterEmployeeActive' in historyFilters) {
                            if (historyFilters.filterEmployeeActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
            ],
            defaultFilterState: { filterIsActive: true },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.usersFetcher = new Fetcher({
            query: GET_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_EMPLOYEE_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
                limit: START,
                skip: 0,
                sortDir: DEFAULT_SORT_DIR,
                sortBy: DEFAULT_SORT_BY,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.usersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.usersFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    render() {
        const currentUser = getCurrentUser()
        const { loading, data } = this.usersFetcher
        const filterIsActive = this.filter.hasFilters({
            excludeKeys: ['searchText'],
            excludeKeyValuePairs: [{ key: 'filterEmployeeActive', value: true }],
        })
        const { users = [] } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            isActiveByDefault={filterIsActive}
                            getButtons={this.renderActionBarButtons}
                            getDrawer={this.renderActionBarDrawer}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`profile.firstName`}>
                                        Voornaam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`profile.surName`}>
                                        Achternaam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Functie</TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`lastActivityAt`}>
                                        Laatst ingelogd
                                    </TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={4} isLoading />
                                    </TableRow>
                                ) : users.length > 0 ? (
                                    this.renderEmployeeRows(users)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={4}>
                                            <Subtle>Er zijn geen medewerkers gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderActionBarButtons(toggleDrawer, isDrawerActive) {
        const currentUser = getCurrentUser()
        const filters = this.filter.getFilters()
        const { loading } = this.usersFetcher
        const hasFilters = this.filter.hasFilters({
            excludeKeys: ['searchText'],
        })

        return (
            <List horizontal>
                <ListItem>
                    <Button
                        leftIcon={<Icon name={`filter`} />}
                        onClick={toggleDrawer}
                        isActive={isDrawerActive}
                        hasNotification={hasFilters}
                    >
                        Filteropties
                    </Button>
                </ListItem>
                <ListItem>
                    <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.searchText} />
                </ListItem>
                {currentUser.isAdmin && (
                    <ListItem right>
                        <ModalManager
                            render={openModal => (
                                <Button onClick={openModal} type={`edit`}>
                                    Gebruiker toevoegen
                                </Button>
                            )}
                            getModal={closeModal => {
                                return (
                                    <CenterModal onClose={closeModal} title={`Gebruiker toevoegen`}>
                                        <CreateEmployeeForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                                    </CenterModal>
                                )
                            }}
                        />
                    </ListItem>
                )}
            </List>
        )
    }

    renderActionBarDrawer() {
        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Functie(s)`} style={`compact`}>
                    <TagPicker
                        name={`filterByOneOfMutableRoles`}
                        onChange={this.onFilterTagPicker}
                        defaultValue={filters.filterByOneOfMutableRoles}
                        options={Object.keys(types.mutableRole).map(value => ({
                            value,
                            label: translateType('mutableRole', value),
                        }))}
                        placeholder={`Selecteer functies`}
                    />
                </Field>
                <Field title={`Status`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterIsActive`}
                            defaultChecked={customFilters.filterIsActive}
                            onChange={this.onFilter}
                        >
                            Actief
                        </CheckBox>
                        <CheckBox
                            name={`filterIsInactive`}
                            defaultChecked={customFilters.filterIsInactive}
                            onChange={this.onFilter}
                        >
                            Non-actief
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    renderEmployeeRows(users) {
        return users.map(user => {
            return (
                <TableRow key={user._id}>
                    <TableCell>
                        <Link route={`/users/employees/${user._id}`}>{user.profile.firstName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/employees/${user._id}`}>{user.profile.fullSurName}</Link>
                    </TableCell>
                    <TableCell>
                        {(user.roles || []).map(role => translateType('mutableRole', role)).join(', ')}
                    </TableCell>
                    <TableCell>
                        {user.lastActivityAt && <ReadableDate date={new Date(user.lastActivityAt)} />}
                    </TableCell>
                </TableRow>
            )
        })
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(_, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }
}

const GET_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: UsersFilterInputType) {
        users(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            lastActivityAt
            roles(filterMutable: true)
            profile {
                firstName
                fullSurName
            }
        }
    }
`
