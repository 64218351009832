import { gql } from '@apollo/client'
import { get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    Icon,
    CenterModal,
    Header,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Search,
    PageTabs,
    PageTab,
    Link,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateDUOResponseDocumentForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { DUOResponseDetailsModal } from '~/implementations'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'createdAt'
const DEFAULT_SORT_DIR = 'DESC'

export default class DUOResponsesView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['byTextSearch'],
            onChange: filters => {
                this.DUOresponsesFetcher.refetch({
                    filters: {
                        ...this.baseFilters,
                        ...filters,
                    },
                })
            },
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.DUOresponsesFetcher = new Fetcher({
            query: DUO_RESPONSES_QUERY,
            variables: {
                skip,
                limit,
                sortDir,
                sortBy,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
        this.boundOnDocumentAdd = this.onDocumentAdd.bind(this)
    }

    onDocumentAdd() {
        this.DUOresponsesFetcher.refetch()
    }

    loadMore = (skip, limit, callback) => {
        this.DUOresponsesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.DUOResponses.length === 0,
                })

                return {
                    DUOResponses: removeDuplicateDocuments([
                        ...(prevData.DUOResponses || []),
                        ...moreData.DUOResponses,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('byTextSearch', searchText)
    }

    sort = ({ sortBy, sortDir }) => {
        this.DUOresponsesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    render() {
        const { loading, data } = this.DUOresponsesFetcher
        const filters = this.filter.getFilters()
        const { DUOResponses = [] } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll
                paginator={paginator}
                preventLoad={loading}
                component={View}
                className={`tt-LearnerInvoicingView`}
            >
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link route={`/financial/duo-batches/invoices`}>Exports genereren</Link>
                        </PageTab>
                        <PageTab>
                            <Link route={`/financial/duo-batches/responses`}>Respons upload</Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => (
                                <List horizontal>
                                    <ListItem>
                                        <Search
                                            isLoading={loading}
                                            onSearch={this.onSearch}
                                            defaultValue={filters.byTextSearch}
                                        />
                                    </ListItem>
                                    <ListItem right>
                                        <ModalManager
                                            render={openModal => (
                                                <Button onClick={openModal} leftIcon={<Icon name={`upload`} />}>
                                                    DUO Respons uploaden
                                                </Button>
                                            )}
                                            getModal={closeModal => {
                                                return (
                                                    <CenterModal onClose={closeModal} title={`DUO Respons uploaden`}>
                                                        <CreateDUOResponseDocumentForm
                                                            onSubmitSuccess={() => {
                                                                closeModal()
                                                                this.boundOnDocumentAdd()
                                                            }}
                                                            onCancel={closeModal}
                                                        />
                                                    </CenterModal>
                                                )
                                            }}
                                        />
                                    </ListItem>
                                </List>
                            )}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table hasAutoLayout={true}>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`file.filename`} width="35%">
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Melding</TableHeaderItem>
                                    <TableHeaderItem>Uploaddatum</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={3} isLoading />
                                    </TableRow>
                                ) : DUOResponses.length > 0 ? (
                                    this.renderDUOResponsesRows(DUOResponses)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen DUO responses gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderDUOResponsesRows(DUOResponses) {
        return DUOResponses.map(DUOResponse => {
            const totalCount = DUOResponse.invoicesProcessedCount + DUOResponse.invoicesNeedActionCount

            return (
                <TableRow key={DUOResponse._id}>
                    <TableCell>{get(DUOResponse, 'file.fileName')}</TableCell>
                    <TableCell>
                        <ModalManager
                            render={openModal => (
                                <Link onClick={openModal}>
                                    {`${totalCount} facturen, waarvan ${DUOResponse.invoicesNeedActionCount} facturen actie vereisen`}
                                </Link>
                            )}
                            getModal={closeModal => {
                                return (
                                    <DUOResponseDetailsModal
                                        onClose={closeModal}
                                        title={get(DUOResponse, 'file.fileName')}
                                        invoicesProcessedCount={DUOResponse.invoicesProcessedCount}
                                        invoicesNeedActionCount={DUOResponse.invoicesNeedActionCount}
                                        invoicesNeedActionResults={DUOResponse.invoicesNeedActionResults}
                                    />
                                )
                            }}
                        />
                    </TableCell>
                    <TableCell>
                        <ReadableDate date={DUOResponse.createdAt} />
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const DUO_RESPONSES_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        DUOResponses(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            createdAt
            file {
                fileName
            }
            invoicesProcessedCount
            invoicesNeedActionCount
            invoicesNeedActionResults {
                invoice {
                    _id
                    invoiceNumber
                    invoiceDate
                    isDownloadable
                    isCredit
                    amount
                    user {
                        _id
                        profile {
                            name
                        }
                    }
                }
                response
            }
        }
    }
`
