import c from 'classnames'
import * as React from 'react'

import { Icon, Spinner, ToolTip } from '~/components'
import { ListItem } from '~/components/ListItem'
import { Placement } from './ToolTip'

interface Props {
    className?: string
    isLoading?: boolean
    info?: string
}

export class Crumb extends React.Component<Props> {
    public render() {
        const { children, info, isLoading } = this.props

        return (
            <ListItem className={this.getClassName()}>
                {!isLoading && children}
                {info && (
                    <ToolTip isLargeExplanation text={info} placement={Placement.bottomLeft}>
                        <Icon name="info" color="#a8aeb3" />
                    </ToolTip>
                )}
                {isLoading && <Spinner size="tiny" color="#2D3740" />}
            </ListItem>
        )
    }

    public getClassName() {
        const { className, isLoading } = this.props

        return c(
            'tt-Crumb',
            {
                'tt-Crumb--is-loading': isLoading,
            },
            className
        )
    }
}
