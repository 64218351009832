import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Icon, DrawerModal, CenterModal } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'

import {
    EditGroupModuleForm,
    EditGroupLocationForm,
    ConceptGroupLearnersForm,
    ConceptGroupTeachersForm,
    ConceptGroupEmployeesForm,
    SelectStartWeekForm,
    EditGroupLessonDaysForm,
    EditGroupScheduleForm,
    EditGroupDateBlockerForm,
} from '~/forms'

export default class GroupAddButtons extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        showButtonsOfKeys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }

    render() {
        const { showButtonsOfKeys, group } = this.props

        return (
            <List horizontal forButtons>
                {group &&
                    showButtonsOfKeys.map(key => {
                        return <ListItem key={key}>{this[`get${key}Button`]()}</ListItem>
                    })}
            </List>
        )
    }

    getModuleButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Lesmodule
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Lesmodule toevoegen`}>
                            <EditGroupModuleForm
                                onSubmitSuccess={closeModal}
                                onCancel={closeModal}
                                refetch={refetchGroup}
                                group={group}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }

    getLearnersButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Kandidaten
                    </Button>
                )}
                getModal={closeModal => {
                    const onSuccess = () => {
                        closeModal()
                        refetchGroup()
                    }

                    const onCancel = () => {
                        closeModal()
                    }

                    return (
                        <DrawerModal onClose={closeModal}>
                            <ConceptGroupLearnersForm onSuccess={onSuccess} onCancel={onCancel} group={group} />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    getLessonDatesButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Lesdag(en)
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <EditGroupLessonDaysForm
                                onSubmitSuccess={() => {
                                    closeModal()
                                    refetchGroup()
                                }}
                                onFormCancel={closeModal}
                                group={group}
                            />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    getStartWeekDateButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Startweek
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <SelectStartWeekForm closeModal={closeModal} refetch={refetchGroup} group={group} />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    getLocationButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Locatie
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Locatie toevoegen`}>
                            <EditGroupLocationForm
                                onSubmitSuccess={closeModal}
                                onCancel={closeModal}
                                refetch={refetchGroup}
                                group={group}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }

    getTeachersButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Docent(en)
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <ConceptGroupTeachersForm
                                onSuccess={() => {
                                    closeModal()
                                    refetchGroup()
                                }}
                                onCancel={closeModal}
                                group={group}
                            />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    getEmployeesButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        TopTaal medewerker(s)
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <DrawerModal onClose={closeModal}>
                            <ConceptGroupEmployeesForm
                                onSuccess={() => {
                                    closeModal()
                                    refetchGroup()
                                }}
                                onCancel={closeModal}
                                group={group}
                            />
                        </DrawerModal>
                    )
                }}
            />
        )
    }

    getLessonTeachersButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Roostering
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Roostering toevoegen`}>
                            <EditGroupScheduleForm
                                type={`modal`}
                                onSubmitSuccess={() => {
                                    closeModal()
                                    refetchGroup()
                                }}
                                onFormCancel={closeModal}
                                group={group}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }

    getDateBlockersButton() {
        const { group, refetchGroup } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Vakantieperiode(s)
                    </Button>
                )}
                getModal={closeModal => {
                    return (
                        <CenterModal onClose={closeModal} title={`Vakantieperiode toevoegen`}>
                            <EditGroupDateBlockerForm
                                type={`modal`}
                                onSubmitSuccess={() => {
                                    closeModal()
                                    refetchGroup()
                                }}
                                onFormCancel={closeModal}
                                group={group}
                            />
                        </CenterModal>
                    )
                }}
            />
        )
    }
}
