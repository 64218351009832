import React from 'react'
import { PhoneNumber } from '~/types/User'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    phoneNumbers: PhoneNumber[]
    className?: ClassValue
}

export default class ReadablePhoneNumbers extends React.Component<Props> {
    private bem = new BEM('ReadablePhoneNumbers')

    public render() {
        const { phoneNumbers, className } = this.props

        return (
            <ul className={this.bem.getClassName(className)}>
                {phoneNumbers.map((phoneNumber, i) => (
                    <li key={i}>
                        {phoneNumber.internationalPhoneNumber} {phoneNumber.info && this.renderInfo(phoneNumber)}
                    </li>
                ))}
            </ul>
        )
    }

    private renderInfo = (phoneNumber: PhoneNumber): JSX.Element => {
        const info = phoneNumber.info && phoneNumber.info.trim()

        if (info) {
            return <span className={this.bem.getElement('info')}>{info}</span>
        }

        return <span />
    }
}
