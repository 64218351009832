import * as React from 'react'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import { gql } from '@apollo/client'
import { Fetcher, Filter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { InflowMoment } from '~/types/InflowMoments'
import Link from '~/components/Link'
import Sorter from '~/utils/Sorter'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import Field from '~/components/Field'
import DatePicker from '~/components/DatePicker'
import MultiInput from '~/components/MultiInput'
import { capitalize } from 'lodash'
const moment = require('moment')

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'DESC'
const DEFAULT_SORT_BY = 'dateRange.from'

const STATIC_INFLOW_MODULES_FILTER = {
    filterByConcepts: false,
}

const GET_INFLOW_MOMENTS_QUERY = gql`
    query inflowMoments(
        $skip: Int
        $limit: Int
        $filters: InflowMomentFilterInputType
        $sortDir: String
        $sortBy: String
    ) {
        inflowMoments(skip: $skip, limit: $limit, filters: $filters, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            organization {
                _id
                name
            }
            location {
                _id
                name
            }
            dateRange {
                from
                to
            }
            inflowModule {
                _id
                name
            }
            candidateCountUsed
            candidateCountTotal
        }
    }
`

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export class DefinitiveOrganizationInflowMomentsTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private inflowMomentsFetcher: Fetcher
    private paginator: Paginator
    private filter: Filter
    private sorter: Sorter

    constructor(props: Props) {
        super(props)
        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByInflowModule',
                'filterByLocation',
                'filterByOrganization',
                'filterByDateFrom',
                'filterByDateTo',
                'filterByCreatedBy',
                'filterByConcepts',
            ],
            onChange: (filters: any) => {
                this.inflowMomentsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_INFLOW_MODULES_FILTER,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        })

        this.inflowMomentsFetcher = new Fetcher({
            query: GET_INFLOW_MOMENTS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_INFLOW_MODULES_FILTER,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => this.paginator.reset(),
        })
    }

    public render() {
        const { loading } = this.inflowMomentsFetcher

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <Wrap full={true}>{this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar = () => {
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['filterByConcepts'] })
        const inflowMomentsFilters = this.filter.getFilters()

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field isLabel={true} title={'Datum'} style={'compact'}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateFrom'}
                                    defaultValue={inflowMomentsFilters.filterByDateFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateTo'}
                                    defaultValue={inflowMomentsFilters.filterByDateTo}
                                />
                            </MultiInput>
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private renderTable = () => {
        const { loading, data } = this.inflowMomentsFetcher
        const { inflowMoments } = data || []
        const totalColSpan = 6

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem>Instroommodule</TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`date`}>
                            Datum
                        </TableHeaderItem>
                        <TableHeaderItem>Locatie</TableHeaderItem>
                        <TableHeaderItem>Kandidaten</TableHeaderItem>
                        <TableHeaderItem>Organisatie</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading={true} />
                        </TableRow>
                    ) : inflowMoments && inflowMoments.length > 0 ? (
                        this.renderInflowMomentsRows(inflowMoments)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen instroommodules gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderInflowMomentsRows = (inflowMoments: InflowMoment[]) => {
        return inflowMoments.map(inflowMoment => {
            const { _id, inflowModule, location, organization, candidateCountTotal, candidateCountUsed } = inflowMoment

            return (
                <TableRow key={_id}>
                    <TableCell>
                        <Link route={`/inflow-moments/definitive/${_id}`}>{inflowModule.name}</Link>
                    </TableCell>
                    <TableCell>{this.renderDate(inflowMoment)}</TableCell>
                    <TableCell>{location ? location.name : '-'}</TableCell>
                    <TableCell>
                        {candidateCountTotal ? `${candidateCountUsed || 0} / ${candidateCountTotal}` : '-'}
                    </TableCell>
                    <TableCell>{organization ? organization.name : '-'}</TableCell>
                </TableRow>
            )
        })
    }

    private onFilter = (event: any) => {
        this.filter.applyFromInputEvent(event)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.inflowMomentsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.inflowMomentsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.inflowMoments.length === 0,
                })

                return {
                    inflowMoments: removeDuplicateDocuments([
                        ...(prevData.inflowMoments || []),
                        ...moreData.inflowMoments,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private renderDate(inflowMoment: InflowMoment) {
        if (!inflowMoment.dateRange || !inflowMoment.dateRange.from || !inflowMoment.dateRange.to) {
            return ''
        }

        const startDate = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        return `${capitalize(startDate.format('ddd DD MMMM YYYY HH:mm'))} - ${endDate.format('H:mm')}`
    }
}
