import React from 'react'
import { RouteComponentProps } from 'react-router'
import { browserHistory } from 'react-router'
import { isNumber } from 'lodash'

import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { FieldCollection, Field, Button, Currency, Subtle } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { RouteParams } from '~/views/App/Properties/Projects/Detail/InvoicingSettings/Detail'
import { Project } from '~/types/Project'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import {
    ProjectInvoicingSetting,
    ProjectPriceType,
    ProjectAmountRange,
    ProjectInvoiceGranularity,
    ProjectChargeForLessonAttendanceType,
    ProjectInvoiceTiming,
    ProjectVariableTimingSettingsPeriod,
} from '~/types/ProjectInvoicingSetting'
import Link from '~/components/Link'
import translateType from '~/shared/utils/translateType'
import Bold from '~/components/Bold'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { getCurrentUser } from '~/services/session'

interface Props extends RouteComponentProps<RouteParams, {}> {
    project: Project
    projectInvoicingSetting: ProjectInvoicingSetting
    refetchProject: () => void
    refetchInvoicingSetting: () => void
}

export default class InvoicingSettingsView extends React.Component<Props> {
    public render() {
        const { projectInvoicingSetting } = this.props

        const programs = projectInvoicingSetting.programs || []
        const user = getCurrentUser()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={'Gegevens'}>
                            <Field title={'Naam'}>{projectInvoicingSetting.name}</Field>

                            <Field title={`Opleiding(en)`}>
                                {programs.map((program, i, arr) => {
                                    const suffix = i < arr.length - 1 ? ', ' : ''

                                    return [
                                        <Link key={program._id} route={`/properties/programs/${program._id}`}>
                                            {program.name}
                                        </Link>,
                                        suffix,
                                    ]
                                })}
                            </Field>

                            <Field title={'Factureren op'}>
                                {projectInvoicingSetting.invoiceGranularity
                                    ? translateType(
                                          'projectInvoiceGranularity',
                                          projectInvoicingSetting.invoiceGranularity
                                      )
                                    : ''}
                            </Field>
                        </FieldGroup>

                        {projectInvoicingSetting.priceType && (
                            <FieldGroup title={'Groepskosten'}>
                                {projectInvoicingSetting.priceType === ProjectPriceType.Fixed &&
                                    this.renderFixedPriceFields()}
                                {projectInvoicingSetting.priceType === ProjectPriceType.Variable &&
                                    this.renderVariablePriceFields()}
                            </FieldGroup>
                        )}

                        {projectInvoicingSetting.invoiceTiming && (
                            <FieldGroup title={'Factuurmomenten'}>
                                <Field title={'Frequentie'}>
                                    {translateType('projectInvoiceTiming', projectInvoicingSetting.invoiceTiming)}
                                </Field>

                                {projectInvoicingSetting.invoiceTiming === ProjectInvoiceTiming.Periodical &&
                                    this.renderTimingPeriodicalFields()}
                                {projectInvoicingSetting.invoiceTiming === ProjectInvoiceTiming.Variable &&
                                    this.renderTimingVariableFields()}
                            </FieldGroup>
                        )}

                        {user.isAdmin && (
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button onClick={this.redirectToEdit} type={`edit`}>
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        )}
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    private renderFixedPriceFields() {
        const { projectInvoicingSetting } = this.props

        if (!projectInvoicingSetting.fixedPriceSettings) {
            return null
        }

        return (
            <Field title={'Vast bedrag'}>
                <Currency amount={projectInvoicingSetting.fixedPriceSettings.amount} />
            </Field>
        )
    }

    private renderVariablePriceFields() {
        const { projectInvoicingSetting } = this.props

        if (!projectInvoicingSetting.variablePriceSettings || !projectInvoicingSetting.invoiceGranularity) {
            return null
        }

        return (
            <>
                <Field title={'Variabel bedrag'}>
                    {translateType(
                        'projectInvoicingVariableBy',
                        projectInvoicingSetting.variablePriceSettings.variableBy
                    )}
                </Field>
                <Field title={'Bereik'}>
                    {projectInvoicingSetting.variablePriceSettings.amountRanges &&
                        this.renderVariablePriceAmountRanges(
                            projectInvoicingSetting.variablePriceSettings.amountRanges,
                            projectInvoicingSetting.invoiceGranularity
                        )}
                </Field>
            </>
        )
    }

    private renderVariablePriceAmountRanges(
        amountRanges: ProjectAmountRange[],
        projectInvoiceGranularity: ProjectInvoiceGranularity
    ) {
        const amountPerInvoiceGranularity = translateType('amountPerInvoiceGranularity', projectInvoiceGranularity)

        return (
            <List>
                {amountRanges.map((amountRange, i) => (
                    <ListItem key={i}>
                        Vanaf {amountRanges[i - 1] ? amountRanges[i - 1].to + 1 : 1} t/m {amountRange.to}
                        <br />
                        {typeof amountRange.amount === 'number' && (
                            <Subtle>
                                {amountPerInvoiceGranularity} <Currency amount={amountRange.amount} />
                            </Subtle>
                        )}
                        <br />
                        <br /> {/** todo: refactor to generic component */}
                    </ListItem>
                ))}
            </List>
        )
    }

    private renderTimingPeriodicalFields() {
        const { projectInvoicingSetting } = this.props

        return (
            <>
                <Field title={'Periode'}>
                    {projectInvoicingSetting.periodicalTimingSettings &&
                        translateType(
                            'projectPeriodicalTimingPeriod',
                            projectInvoicingSetting.periodicalTimingSettings.period
                        )}
                </Field>
                <Field title={'Facturabele presenties'}>
                    {projectInvoicingSetting.periodicalTimingSettings &&
                        translateType(
                            'projectInvoicingSettingsLessonAttendanceType',
                            projectInvoicingSetting.periodicalTimingSettings.chargeForLessonAttendanceType
                        )}
                </Field>
                {projectInvoicingSetting.periodicalTimingSettings &&
                    projectInvoicingSetting.periodicalTimingSettings.chargeForLessonAttendanceType &&
                    projectInvoicingSetting.periodicalTimingSettings.chargeForLessonAttendanceType !==
                        ProjectChargeForLessonAttendanceType.Present && (
                        <Field title={'Facturabele redenen'}>
                            {projectInvoicingSetting.periodicalTimingSettings &&
                                projectInvoicingSetting.periodicalTimingSettings.chargeForLessonReasonOfAbsences &&
                                projectInvoicingSetting.periodicalTimingSettings.chargeForLessonReasonOfAbsences
                                    .map((reason, i) => {
                                        return translateType('projectChargeForLessonReasonOfAbsence', reason)
                                    })
                                    .join(', ')}
                        </Field>
                    )}
            </>
        )
    }

    private renderTimingVariableFields() {
        const { projectInvoicingSetting } = this.props

        const periods =
            (projectInvoicingSetting.variableTimingSettings &&
                projectInvoicingSetting.variableTimingSettings.periods) ||
            []

        return (
            <>
                <Field title={'Omgaan met latere inschrijving kandidaat'}>
                    {projectInvoicingSetting.variableTimingSettings &&
                        translateType(
                            'projectChargeForLessonsType',
                            projectInvoicingSetting.variableTimingSettings.chargeForLessonsType
                        )}
                </Field>
                {periods.map((period, i) => (
                    <React.Fragment key={i}>{this.renderPeriodField(period, i)}</React.Fragment>
                ))}
            </>
        )
    }

    private renderPeriodField(period: ProjectVariableTimingSettingsPeriod, index: number) {
        const conditionSettings = period.conditionSettings || []
        const checkedConditionSettings = conditionSettings.filter(conditionSetting => {
            return conditionSetting.isChecked === true
        })

        return (
            <Field title={`Periode ${index + 1}`}>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell width={'176px'}>
                            <Bold>
                                {isNumber(period.percentageOfPassedLessons)
                                    ? `${period.percentageOfPassedLessons}%`
                                    : ''}
                            </Bold>{' '}
                            afgeronde lessen
                        </SimpleTableCell>
                        <SimpleTableCell>
                            <Bold>{isNumber(period.percentageOfPrice) ? `${period.percentageOfPrice}%` : ''}</Bold> van
                            het bedrag
                        </SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow>
                        <SimpleTableCell colSpan={2}>
                            <Subtle>
                                {checkedConditionSettings
                                    .map(conditionSetting => {
                                        return translateType('projectPeriodCondition', conditionSetting.condition)
                                    })
                                    .join(', ')}
                            </Subtle>
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </Field>
        )
    }

    private redirectToEdit = () => {
        const { projectInvoicingSetting, project } = this.props

        browserHistory.push(
            `/properties/projects/${project._id}/invoicing-settings/${projectInvoicingSetting._id}/edit`
        )
    }
}
