import React from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'
import { LearnerContractsTable } from '~/components/users/Learner/LearnerContractsTable'

interface Props extends RouteComponentProps<{}, {}> {
    userId: string
    refetch: (options?: { silent?: boolean }) => void
}

export default class LearnerContractsView extends React.Component<Props, {}> {
    public render() {
        const { userId, refetch, children, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Contracten'} routeProps={routeProps}>
                <LearnerContractsTable userId={userId} />
            </RouteView>
        )
    }
}
