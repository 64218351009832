import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { get } from 'lodash'

import { translateType } from '~/shared/utils'
import { ActionBar, Button, Field, FieldCollection, CenterModal, ReadablePhoneNumbers, Spinner } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { Wrap } from '~/components/Wrap'
import { UserInvitationForm } from '~/forms'

export default class DataView extends Component {
    static propTypes = {
        user: PropTypes.object,
        refetch: PropTypes.func,
    }

    userInvited() {
        const { refetch } = this.props

        refetch({ silent: true })
    }

    render() {
        const { user } = this.props
        if (!user) {
            return this.renderLoadingState()
        }

        let isMfaConfigured = 'Nee'
        isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'

        const phoneNumbers = user.profile.phoneNumbers || []

        return (
            <ContentView hasFixedActionBar>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Persoonlijke gegevens`}>
                            <Field title={`Naam`}>{user.profile.name}</Field>
                            <Field title={`Geslacht`}>{translateType('userGender', user.profile.gender)}</Field>
                        </FieldGroup>
                        <FieldGroup title={`MFA`}>
                            <Field title={`Geconfigureerd`}>{isMfaConfigured}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Contactgegevens`}>
                            <Field title={`E-mailadres`}>{user.email}</Field>
                            {user.email && !user.lastActivityAt && !user.invitedAt && (
                                // todo: think about how we could retrigger cognito
                                <Field title="&nbsp;">
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal}>{'Toegang tot het systeem verlenen'}</Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal
                                                onClose={closeModal}
                                                title={`Toegang verlenen aan ${user.profile.name}`}
                                            >
                                                <UserInvitationForm
                                                    user={user}
                                                    onSuccess={() => {
                                                        closeModal()
                                                        this.userInvited()
                                                    }}
                                                    onCancel={closeModal}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </Field>
                            )}
                            <Field title={`Telefoonnummer`}>
                                <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                            </Field>
                            <Field title={`Organisatie`}>{get(user.organizationContact, 'organization.name')}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Overige`}>
                            <Field title={`Notities`}>{user.profile.notes}</Field>
                            <Field title={`Status`}>
                                {get(user, `organizationContact.isActive`) ? 'Actief' : 'Non-actief'}
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Wrap>
                <ActionBar
                    fixed
                    getButtons={() => {
                        return (
                            <List horizontal>
                                <ListItem right>
                                    <Button
                                        type={`edit`}
                                        onClick={() =>
                                            browserHistory.push(`/users/organization-contacts/${user._id}/edit`)
                                        }
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        )
                    }}
                />
            </ContentView>
        )
    }

    renderLoadingState() {
        return (
            <ContentView>
                <Spinner />
            </ContentView>
        )
    }
}
