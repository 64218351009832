import * as React from 'react'
import { get } from 'lodash'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { getCurrentUser } from '~/services/session'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import { GroupUser } from '~/types/User'
import { GroupParticipantName } from '~/components/Group/Participants/Misc/GroupParticipantName'
import { GroupParticipantContactInfo } from '~/components/Group/Participants/Misc/GroupParticipantContactInfo'
import { RemoveTeacherOrEmployeeFromGroupButton } from '~/components/Group/Participants/Buttons/RemoveTeacherOrEmployeeFromGroupButton'
import { toast } from '~/utils'

interface Props {
    groupId: string
    groupName: string
    employeeUsers: GroupUser[]
    loadingEmployeeUsers: boolean
    refetchEmployeeUsers: () => void
}

export class GroupEmployeeParticipantsTable extends React.Component<Props> {
    public render() {
        const { employeeUsers, loadingEmployeeUsers } = this.props
        const currentUser = getCurrentUser()

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                    {currentUser.isEmployee && <TableHeaderItem>Woonplaats</TableHeaderItem>}
                    <TableHeaderItem>Contact</TableHeaderItem>
                    <TableHeaderItem />
                    {/* trash button */}
                </TableHeader>
                {loadingEmployeeUsers && this.renderLoadingTableBody()}
                {!loadingEmployeeUsers && employeeUsers.length === 0 && this.renderEmptyTableBody()}
                {!loadingEmployeeUsers && employeeUsers.length > 0 && this.renderUsersTableBody()}
            </Table>
        )
    }

    private getAmountOfColumns() {
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee) {
            return 4 // employees see the extra 'Woonplaats' column
        }

        return 3
    }

    private renderLoadingTableBody() {
        return (
            <TableRow>
                <TableCell colSpan={this.getAmountOfColumns()} isLoading={true} />
            </TableRow>
        )
    }

    private renderEmptyTableBody() {
        return (
            <TableRow>
                <TableCell colSpan={this.getAmountOfColumns()}>
                    <Subtle>Er zijn nog geen medewerkers toegevoegd.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    private renderUsersTableBody() {
        const { employeeUsers, groupId, groupName, refetchEmployeeUsers } = this.props

        const currentUser = getCurrentUser()

        return employeeUsers.map(groupUser => {
            const { user } = groupUser

            const name = user && user.profile && user.profile.name

            const city = get(user, 'profile.address.nl.extraInfo.city')
            const email = user && user.email
            const phoneNumber = user && user.profile && user.profile.phoneNumbers && user.profile.phoneNumbers[0]

            return (
                <TableRow key={groupUser._id}>
                    <TableCell>
                        <GroupParticipantName groupUser={groupUser} disableLink={currentUser.isExternalTeacher} />
                    </TableCell>
                    {currentUser.isEmployee && <TableCell>{city}</TableCell>}
                    <TableCell>
                        <GroupParticipantContactInfo email={email} phoneNumber={phoneNumber} />
                    </TableCell>
                    <TableCell>
                        <RemoveTeacherOrEmployeeFromGroupButton
                            groupId={groupId}
                            groupName={groupName}
                            userId={user!._id}
                            userName={name!}
                            onRemoved={() => {
                                refetchEmployeeUsers()
                                toast.success(`Medewerker "${name!}" succesvol verwijderd uit groep`)
                            }}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    }
}
