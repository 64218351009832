import { gql } from '@apollo/client'

export const UPDATE_INFLOW_MOMENT_MUTATION = gql`
    mutation _($inflowMoment: InflowMomentInputType!) {
        inflowMoments_edit(inflowMoment: $inflowMoment) {
            _id
        }
    }
`

export const DELETE_INFLOW_MOMENT_MUTATION = gql`
    mutation _($inflowMomentId: MongoID!) {
        inflowMoments_delete(inflowMomentId: $inflowMomentId) {
            _id
        }
    }
`

export const MAKE_INFLOW_MOMENT_FINAL_MUTATION = gql`
    mutation _($inflowMomentId: MongoID!) {
        inflowMoments_makeFinal(inflowMomentId: $inflowMomentId) {
            _id
        }
    }
`
