import c from 'classnames'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button } from '~/components'
import Icon from './Icon'
import Input from './Input'
import InputIcon from './InputIcon'
import Spinner from './Spinner'

const SEARCH_DEBOUNCE = 200

export default class Search extends Component {
    static propTypes = {
        onSearch: PropTypes.func,
        isLoading: PropTypes.bool,
        defaultValue: PropTypes.string,
        className: PropTypes.string,
        autoFocus: PropTypes.bool,
    }

    static defaultProps = {
        autoFocus: true,
    }

    constructor(props) {
        super(props)

        this.state = {
            search: props.defaultValue || '',
            hasFocus: props.autoFocus,
        }

        this.debouncedSearch = debounce(this.search, SEARCH_DEBOUNCE)
    }

    onFocus = () => {
        this.setState(() => ({
            hasFocus: true,
        }))
    }

    onBlur = () => {
        this.setState(() => ({
            hasFocus: false,
        }))
    }

    onKeyDown = event => {
        this.preventSearch = false

        if (event.keyCode === 13) {
            // on enter press
            event.preventDefault()
            event.stopPropagation()

            this.search()
            this.preventSearch = true // to cancel the possibly triggered debounced search
        } else {
            this.debouncedSearch()
        }
    }

    onChange = event => {
        const value = event.target.value

        this.setState(() => ({
            search: value,
        }))
    }

    search = () => {
        const { onSearch, isLoading } = this.props
        const { search } = this.state

        if (this.preventSearch || isLoading) return

        if (onSearch) {
            onSearch({
                searchText: search,
            })
        }
    }

    onClearClick = () => {
        this.preventSearch = false

        this.setState(
            () => ({
                search: '',
            }),
            () => {
                this.search()
                this.preventSearch = true // to cancel the possibly triggered debounced search
            }
        )
    }

    preventBlur = event => {
        event.preventDefault()
    }

    render() {
        const { className: extraClassNames, isLoading, autoFocus } = this.props
        const { search, hasFocus } = this.state
        const className = c('tt-Search', extraClassNames, {
            'tt-Search--is-loading': isLoading,
            'tt-Search--has-value': search,
            'tt-Search--has-focus': hasFocus,
        })

        return (
            <InputIcon className={className} icon={<Icon className="tt-Search__icon" name="search" />}>
                <Input
                    placeholder="Zoeken"
                    onKeyDown={this.onKeyDown}
                    name="search"
                    value={search}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    autoFocus={autoFocus}
                />

                <Spinner size="small" />

                <Button
                    className="tt-Search__clear-button"
                    onClick={this.onClearClick}
                    onMouseDown={this.preventBlur}
                    onTouchStart={this.preventBlur}
                >
                    <Icon name="times" />
                </Button>
            </InputIcon>
        )
    }
}
