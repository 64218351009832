import * as React from 'react'
import { Input } from '~/components'
import { BEM } from '~/services/BEMService'
import { InputProps } from './Input'

interface Props extends InputProps {
    alignUnitRight?: boolean
    unitType: unitType
}

export enum unitType {
    euro = '€',
    dollar = '$',
    percent = '%',
}

export class UnitInput extends React.Component<Props> {
    private bem = new BEM('UnitInput', () => ({
        'align-unit-right': this.props.alignUnitRight,
    }))

    public render() {
        const { className, onChange, unitType, alignUnitRight, ...restProps } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <span className={this.bem.getElement('icon')}>{unitType}</span>
                <Input type={`number`} step={0.01} onChange={onChange} {...restProps} />
            </div>
        )
    }
}
