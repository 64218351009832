import { gql } from '@apollo/client'
import { get } from 'lodash'
import React, { Component } from 'react'

import { Header, ReadableDate, Subtle, TableHeader, TableHeaderItem, TableRow, TableWrap } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { Fetcher, nl2br } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

export default class EmailBouncesView extends Component {
    constructor(props) {
        super(props)

        this.emailBouncesFetcher = new Fetcher({
            query: GET_EMAIL_BOUNCES_QUERY,

            onChange: () => this.forceUpdate(),
        })
    }

    render() {
        const { ...routeProps } = this.props
        const { loading, data } = this.emailBouncesFetcher
        const bounces = get(data, 'statistics.emailBounces') || []

        return (
            <RouteView crumbLabel={'E-mail bounces'} routeProps={routeProps}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem>Ontvanger</TableHeaderItem>
                                    <TableHeaderItem colSpan={2}>Gebounced op</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={3} isLoading />
                                    </TableRow>
                                ) : bounces.length > 0 ? (
                                    this.renderBouncesRows(bounces)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen e-mail bounces gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </RouteView>
        )
    }

    renderBouncesRows(bounces) {
        return bounces.map((bounce, i) => {
            const { recipient, date, details } = bounce

            return (
                <TableRow key={i} getExpansion={() => <TableCell colSpan={3}>{nl2br(details)}</TableCell>}>
                    <TableCell>{recipient}</TableCell>
                    <TableCell>
                        <ReadableDate date={date} showTime />
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_EMAIL_BOUNCES_QUERY = gql`
    query _ {
        statistics {
            emailBounces {
                recipient
                date
                details
            }
        }
    }
`
