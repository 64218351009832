import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import RadioButton from '~/components/RadioButton'
import { TagPicker } from '~/components/TagPicker'
import { translateType } from '~/shared/utils'
import { LearnerEnrollmentType, User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import DatePicker from '~/components/DatePicker'
import { getCurrentUser } from '~/services/session'

interface Props {
    hideFieldsForOrganizationContacts?: boolean
    isForIntake?: boolean
    user: User
    errors?: GraphQLErrors
    showEnrollmentTypeAndDate?: boolean
}

export class UserLearnerGeneralFieldGroup extends React.Component<Props> {
    public render() {
        const {
            errors,
            user,
            hideFieldsForOrganizationContacts,
            isForIntake,
            showEnrollmentTypeAndDate = true,
        } = this.props

        const { profile } = user
        const { firstName, initials, surName, surNamePrefix, givenName, gender } = profile

        const currentUser = getCurrentUser()

        return (
            <FieldGroup title="Kandidaat" isForm={true}>
                <LockedFieldWrapper disableLock={!firstName && !initials && !surNamePrefix && !surName}>
                    {isLocked => (
                        <Field title="Naam" errors={errors}>
                            <MultiInput type="name">
                                <Input
                                    name="user.profile.firstName"
                                    type="text"
                                    placeholder={`Voornaam`}
                                    isDisabled={isLocked}
                                    defaultValue={firstName}
                                />
                                <Input
                                    name="user.profile.initials"
                                    type="text"
                                    placeholder="Voorletters"
                                    isDisabled={isLocked}
                                    defaultValue={initials}
                                />
                                <Input
                                    name="user.profile.surNamePrefix"
                                    type="text"
                                    placeholder="Tussenvoegsel"
                                    isDisabled={isLocked}
                                    defaultValue={surNamePrefix}
                                />
                                <Input
                                    name="user.profile.surName"
                                    type="text"
                                    placeholder="Achternaam"
                                    isDisabled={isLocked}
                                    defaultValue={surName}
                                />
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                {!hideFieldsForOrganizationContacts && (
                    <React.Fragment>
                        <LockedFieldWrapper disableLock={!givenName}>
                            {isLocked => (
                                <Field title="Roepnaam">
                                    <Input
                                        name="user.profile.givenName"
                                        type="text"
                                        isDisabled={isLocked}
                                        placeholder="Roepnaam"
                                        defaultValue={givenName}
                                    />
                                </Field>
                            )}
                        </LockedFieldWrapper>
                        <LockedFieldWrapper disableLock={!gender}>
                            {isLocked => (
                                <Field title="Geslacht" errors={errors}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name="user.profile.gender"
                                            value="MALE"
                                            isDisabled={isLocked}
                                            defaultChecked={gender === 'MALE'}
                                        >
                                            Man
                                        </RadioButton>
                                        <RadioButton
                                            name="user.profile.gender"
                                            value="FEMALE"
                                            isDisabled={isLocked}
                                            defaultChecked={gender === 'FEMALE'}
                                        >
                                            Vrouw
                                        </RadioButton>
                                        <RadioButton
                                            name="user.profile.gender"
                                            value="OTHER"
                                            isDisabled={isLocked}
                                            defaultChecked={gender === 'OTHER'}
                                        >
                                            Anders
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            )}
                        </LockedFieldWrapper>
                    </React.Fragment>
                )}
                {showEnrollmentTypeAndDate && (
                    <>
                        <LockedFieldWrapper disableLock={!(user.learner && user.learner.enrollmentType)}>
                            {isLocked => (
                                <Field title={`Type aanmelding`} errors={errors}>
                                    <TagPicker
                                        name={`user.learner.enrollmentType`}
                                        multi={false}
                                        placeholder={'Selecteer een type...'}
                                        isDisabled={isLocked}
                                        defaultValue={user.learner && user.learner.enrollmentType}
                                        options={[
                                            {
                                                value: LearnerEnrollmentType.Intake,
                                                label: translateType('enrollmentType', LearnerEnrollmentType.Intake),
                                            },
                                            {
                                                value: LearnerEnrollmentType.Assessment,
                                                label: translateType(
                                                    'enrollmentType',
                                                    LearnerEnrollmentType.Assessment
                                                ),
                                            },
                                        ]}
                                    />
                                </Field>
                            )}
                        </LockedFieldWrapper>
                        {!isForIntake && currentUser.isEmployee && (
                            <Field title="Datum aanmelding" errors={errors}>
                                <DatePicker
                                    name={`user.learner.enrollmentDate`}
                                    defaultValue={user.learner && user.learner.enrollmentDate}
                                />
                            </Field>
                        )}
                    </>
                )}
            </FieldGroup>
        )
    }
}
