import { PlannedAbilityExam } from '~/types/Exam'
import { Group } from '~/types/Group'
import { User, LessonUser } from '~/types/User'
import { Location, Room } from '~/types/Location'

export enum LessonSpecialActivity {
    PortfolioDeadline = 'portfolioDeadline',
    ReportMoment = 'reportMoment',
    Exam = 'exam',
    Assessment = 'assessment',
}

export enum LessonAttendance {
    Present = 'PRESENT',
    AbsentWithReason = 'ABSENT_WITH_REASON',
    AbsentWithoutReason = 'ABSENT_WITHOUT_REASON',
}

// extended by ProjectInvoicingSetting (keep in sync!)
export enum LessonReasonOfAbsence {
    Other_Integrtion_Activity = 'OTHER_INTEGRATION_ACTIVITY',
    Exam = 'EXAM',
    Private = 'PRIVATE_REASONS',
    Transport_Issues = 'TRANSPORT_ISSUES',
    Vacation = 'VACATION',
    Work_Study = 'WORK_STUDY',
    Sick = 'SICK',
    Other = 'OTHER',
}

export interface Lesson {
    _id: string
    date: Date
    order: number
    areAllAttendancesSet: boolean
    duration?: number
    group?: Group
    teacherUserId?: string
    teacherUser?: User
    location?: Location
    room?: Room
    lessonUsers?: LessonUser[]
    hasResults?: boolean
    lessonUsersCount?: number
    specialActivity?: LessonSpecialActivity
    plannedAbilityExams?: PlannedAbilityExam[]
}
