import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Link } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { WithSubtle } from '~/components/WithSubtle'
import { Translator } from '~/services/i18n'
import { translateType } from '~/shared/utils'
import { Module } from '~/types/Module'
import { getCurrentUser } from '~/services/session'

interface Props {
    module: Module
}

export class ModuleDataView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })

    public render() {
        const { module } = this.props
        const courseMaterials = module.courseMaterials || []
        const locationProperties = module.locationProperties || []
        const { t } = this.translator
        const user = getCurrentUser()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Algemeen`}>
                            <Field title={`Naam`}>{module.name}</Field>
                            <Field title={`Alfabetisering`}>{module.isLiteracyCourse ? 'Ja' : 'Nee'}</Field>
                            {!module.isLiteracyCourse && (
                                <>
                                    <Field title={`Beginniveau`}>
                                        {module.startLevel ? translateType('level', module.startLevel) : ''}
                                    </Field>
                                    <Field title={`Eindniveau`}>
                                        {module.endLevel ? translateType('level', module.endLevel) : ''}
                                    </Field>
                                </>
                            )}
                            <Field title={`Type project`}>
                                {module.projectType ? translateType('lessonModuleProjectType', module.projectType) : ''}
                            </Field>

                            {module.isIntegrationCourse && (
                                <>
                                    <Field title={`Type inburgering`}>
                                        {module.integrationType
                                            ? translateType('lessonModuleIntegrationType', module.integrationType)
                                            : ''}
                                    </Field>
                                    {!module.isLiteracyCourse && (
                                        <Field title={`Inburgeringsexamentraining`}>
                                            {module.isFinalExamTraining ? 'Ja' : 'Nee'}
                                        </Field>
                                    )}
                                    <Field title={`Bevat eindgesprek`}>{module.hasFinalReview ? 'Ja' : 'Nee'}</Field>
                                </>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Inhoudelijk`}>
                            <Field title={`Aantal minuten per les`}>{module.lessonDuration}</Field>
                            <Field title={`Aantal lessen per week`}>{module.amountOfWeeklyLessons}</Field>
                            <Field title={`Totaal aantal lessen`}>{module.amountOfTotalLessons}</Field>
                            <Field title={`Maximale groepsgrootte`}>{module.capacity}</Field>
                            <Field title={`Lesmaterialen`}>
                                {courseMaterials.map((courseMaterial, i, arr) => {
                                    const suffix = i < arr.length - 1 ? ', ' : ''

                                    return [
                                        <Link
                                            key={courseMaterial._id}
                                            route={`/properties/coursematerials/${courseMaterial._id}`}
                                        >
                                            {courseMaterial.name}
                                        </Link>,
                                        suffix,
                                    ]
                                })}
                            </Field>
                            <Field title={`Toetsen`}>
                                {module.exams &&
                                    module.exams.map((exam, i) => {
                                        const selectedAbilities = []
                                        const possibleAbilities = ['converse', 'read', 'write', 'listen', 'talk']

                                        for (const ability in exam.abilities) {
                                            if (possibleAbilities.includes(ability) && exam.abilities[ability]) {
                                                selectedAbilities.push(t(`Properties.exam.abilities.${ability}`))
                                            }
                                        }

                                        if (!exam.exam) {
                                            return null
                                        }

                                        return (
                                            <WithSubtle text={selectedAbilities.join(', ')} key={i}>
                                                <Link key={exam._id} route={`/properties/exams/${exam.exam._id}`}>
                                                    {exam.exam.name}
                                                </Link>
                                            </WithSubtle>
                                        )
                                    })}
                            </Field>
                            <Field title={`Locatie-eisen`}>
                                {locationProperties.map((locationProperty, i, arr) => {
                                    const suffix = i < arr.length - 1 ? ', ' : ''

                                    return [
                                        <Link
                                            key={locationProperty._id}
                                            route={`/properties/locationProperties/${locationProperty._id}`}
                                        >
                                            {locationProperty.name}
                                        </Link>,
                                        suffix,
                                    ]
                                })}
                            </Field>
                            <Field title={`Inburgeringsexamenmodule`}>
                                {module.isFinalExamTraining ? 'Ja' : 'Nee'}
                            </Field>
                            <Field title={`Status`}>{module.isActive ? 'Actief' : 'Non-actief'}</Field>
                        </FieldGroup>
                        {user.isAdmin && (
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/modules/${module._id}/edit`)
                                            }
                                            type={`edit`}
                                        >
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        )}
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
