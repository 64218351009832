import { bindAll } from 'lodash'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'

interface State {
    modalIsShown: boolean
}

interface Props {
    render: (openModal: () => void) => React.ReactNode
    getModal: (closeModal: () => void) => React.ReactNode
}

export class ModalManager extends React.Component<Props, State> {
    public state: State = {
        modalIsShown: false,
    }

    constructor(props: Props) {
        super(props)
        bindAll(this, ['openModal', 'closeModal'])
    }

    public render() {
        const { render, getModal } = this.props
        const { modalIsShown } = this.state

        return (
            <>
                {render(this.openModal)}
                <CSSTransition
                    classNames="tt-appearModal"
                    timeout={225}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    in={modalIsShown}
                >
                    {getModal(this.closeModal)}
                </CSSTransition>
            </>
        )
    }

    public openModal() {
        this.setState({
            modalIsShown: true,
        })
    }

    private closeModal() {
        this.setState({
            modalIsShown: false,
        })
    }
}
