import * as React from 'react'
import { ContentView } from '~/components/ContentView'
import { RouteComponentProps } from 'react-router'
import { InflowMoment } from '~/types/InflowMoments'
import { AdviceReportsTable } from '~/components/AdviceReports/AdviceReportsTable'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowMoment: InflowMoment
    refetch: (options?: { silent: boolean }) => void
}

export class DefinitiveInflowMomentsDetailAdviceReportsView extends React.Component<Props> {
    public render() {
        const { inflowMoment, refetch } = this.props

        return (
            <ContentView>
                <AdviceReportsTable inflowMomentId={inflowMoment._id} refetchInflowMoment={refetch} />
            </ContentView>
        )
    }
}
