import { serverMessages as S } from './'

/**
 * Translate the server message to a human readable message
 */
export function parseGraphQLError(error, { namespace } = {}) {
    const graphQLErrors = error.graphQLErrors || []
    const [firstError] = graphQLErrors

    let serverMessage
    try {
        serverMessage = JSON.parse(firstError.message)
    } catch (err) {}

    if (!serverMessage || !serverMessage.name) {
        serverMessage = {
            name: 'unknown',
            info: {},
        }
    }

    const fieldWithoutIndices = serverMessage.field && serverMessage.field.replace(/\[(.*?)\]/g, '[]')

    const namespacedMessages = S[namespace] || S.generic

    const namespacedMessage = namespacedMessages[fieldWithoutIndices] || namespacedMessages.generic || S.generic

    const getMessage =
        namespacedMessage[serverMessage.name] ||
        S.generic[serverMessage.name] ||
        (fieldWithoutIndices ? S.generic.genericField : S.generic.generic)

    return {
        name: serverMessage.name,
        field: serverMessage.field,
        message: getMessage(serverMessage.info),
    }
}
