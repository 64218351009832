import React from 'react'
import $ from 'jquery'
import { BEM } from '~/services/BEMService'

const VERSION_URL = '/VERSION.txt'

interface Props {}

interface State {
    version?: string
}

export default class Version extends React.Component<Props, State> {
    public state: State = {
        version: '',
    }

    private bem = new BEM('Version')

    constructor(props: Props) {
        super(props)
        ;(async () => {
            this.setState({
                version: await $.get(VERSION_URL),
            })
        })()
    }

    public render() {
        const { version } = this.state

        return <span className={this.bem.getClassName()}>{version}</span>
    }
}
