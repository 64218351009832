import * as React from 'react'

import { HorizontalScrollView } from './HorizontalScrollView'
import { BEM } from '~/services/BEMService'

interface Props {
    buttons?: any
    className?: string
    full?: boolean
    needsPaddingForTagPicker?: boolean
    title?: any
}

export class TableView extends React.Component<Props> {
    private bem = new BEM('TableView', () => ({
        'full-width': this.props.full,
    }))

    public render() {
        const { children, buttons, title, needsPaddingForTagPicker } = this.props

        return (
            <div className={this.bem.getClassName()}>
                {(buttons || title) && (
                    <div className={this.bem.getElement('bar')}>
                        {title && <h3 className={this.bem.getElement('title')}>{title}</h3>}
                        {buttons && <div className={this.bem.getElement('buttons')}>{buttons}</div>}
                    </div>
                )}
                <HorizontalScrollView needsPaddingForTagPicker={needsPaddingForTagPicker}>
                    <div className={this.bem.getElement('table')}>{children}</div>
                </HorizontalScrollView>
            </div>
        )
    }
}
