import * as countries from './countries'

export const countryOptions = Object.keys(countries.getAlpha2Codes())
    .map(alpha2 => {
        const countryName = countries.getName(alpha2.toLowerCase(), 'nl')

        return {
            value: alpha2,
            label: countryName,
            data: {
                match: countryName,
            },
        }
    })
    .sort(function (a, b) {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
    })
