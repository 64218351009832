import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import CenterModal from './CenterModal'


interface Props {
    modalIsShown: boolean
    closeModal: () => void
    title?: string
    renderContent: () => React.ReactNode
}

export class ModalPopup extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { modalIsShown, closeModal, title, renderContent } = this.props

        return (
            <CSSTransition
                classNames="tt-appearModal"
                timeout={225}
                mountOnEnter={true}
                unmountOnExit={true}
                in={modalIsShown}
            >
                <CenterModal title={title} onClose={closeModal} enableEasyClose={true} medium={true}>
                    {renderContent()}
                </CenterModal>
            </CSSTransition>
        )
    }
}
