import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    className?: ClassValue
}

export default class TableHeader extends React.Component<Props> {
    private bem = new BEM('TableHeader')

    public render() {
        const { children, className } = this.props

        return (
            <thead className={this.bem.getClassName(className)}>
                <tr className={this.bem.getElement('row')}>{children}</tr>
            </thead>
        )
    }
}
