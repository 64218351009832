import { gql } from '@apollo/client'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Bold,
    Button,
    Icon,
    Link,
    Search,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { translateType } from '~/shared/utils'
import { Fetcher, removeDuplicateDocuments } from '~/utils'
import { Paginator } from '~/utils/Paginator'

import ConceptGroupUsersForm from './ConceptGroupUsersForm'

const START = 40
const INCREASE = 40

const STATIC_EMPLOYEE_USERS_FILTERS = {
    roles: ['EMPLOYEE'],
}

export default class ConceptGroupEmployeesForm extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        onlyAddSingleUser: PropTypes.bool,
        onSuccess: PropTypes.func,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['renderUsersTable', 'renderActionBar'])

        this.state = {
            searchText: null,
            limit: START,
            skip: 0,
        }

        const { searchText, limit, skip } = this.state

        this.employeeUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.employeeUsersFetcher = new Fetcher({
            query: GET_EMPLOYEE_USERS_QUERY,
            variables: {
                limit,
                skip,
                filters: {
                    ...STATIC_EMPLOYEE_USERS_FILTERS,
                    searchText: searchText,
                },
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.employeeUsersPaginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.employeeUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.employeeUsersFetcher.refetch({
            filters: {
                ...STATIC_EMPLOYEE_USERS_FILTERS,
                searchText: searchText,
            },
        })

        this.setState({ searchText })
    }

    render() {
        const { group, onSuccess, onCancel, onlyAddSingleUser = false } = this.props

        return (
            <ConceptGroupUsersForm
                group={group}
                role="EMPLOYEE"
                usersFetcher={this.employeeUsersFetcher}
                usersPaginator={this.employeeUsersPaginator}
                title={onlyAddSingleUser ? 'Medewerker toevoegen' : 'Medewerkers toevoegen'}
                renderUsersTable={this.renderUsersTable}
                renderActionBar={this.renderActionBar}
                isSingle={onlyAddSingleUser}
                onSuccess={onSuccess}
                onCancel={onCancel}
            />
        )
    }

    renderUsersTable(users, addUser, removeUser, areUsersAdded) {
        const { loading } = this.employeeUsersFetcher
        const showSpinner = !areUsersAdded && loading

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem forIconButton></TableHeaderItem>
                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                    <TableHeaderItem colSpan={2}>Functie</TableHeaderItem>
                </TableHeader>

                {showSpinner && this.renderLoadingRow()}

                {!showSpinner &&
                    (users.length > 0
                        ? this.renderEmployeeRows(users, addUser, removeUser, areUsersAdded)
                        : this.renderEmpyRow(areUsersAdded))}
            </Table>
        )
    }

    renderEmpyRow(areUsersAdded) {
        const placeholder = areUsersAdded
            ? 'Voeg medewerkers toe middels de onderstaande lijst.'
            : 'Er zijn geen medewerkers gevonden.'

        return (
            <TableRow key={`emptyresult`}>
                <TableCell colSpan={4}>
                    <Subtle>{placeholder}</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    renderLoadingRow() {
        return (
            <TableRow key={`loading`}>
                <TableCell colSpan={4}>
                    <Spinner instant />
                </TableCell>
            </TableRow>
        )
    }

    renderActionBar() {
        const { loading: loadingUsers } = this.employeeUsersFetcher

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal>
                        <ListItem>
                            <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    renderEmployeeRows(employees, addUser, removeUser, areUsersAdded) {
        return employees.map(employeeUser => {
            const isUserAdded = areUsersAdded
            const canRowExpand = get(employeeUser, 'profile.notes')

            return (
                <TableRow
                    key={employeeUser._id}
                    hasExpandableInfo={!!canRowExpand}
                    getExpansion={() => (
                        <TableCell colSpan={4}>{get(employeeUser, 'profile.notes') || 'Geen notities'}</TableCell>
                    )}
                >
                    <TableCell forIconButton>
                        {isUserAdded && this.renderRemoveButton(employeeUser, removeUser)}
                        {!isUserAdded && this.renderAddButton(employeeUser, addUser)}
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/employees/${employeeUser._id}`} target="_blank" type="emphasized">
                            {employeeUser.profile.name}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {(get(employeeUser, 'roles') || []).map(role => translateType('mutableRole', role)).join(', ')}
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderRemoveButton(user, removeUser) {
        return (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={() => removeUser(user)}
                leftIcon={<Icon name={`trash`} />}
            />
        )
    }

    renderAddButton(user, addUser) {
        const { onlyAddSingleUser } = this.props
        const confirm = onlyAddSingleUser
            ? {
                  title: 'Medewerker toevoegen',
                  message: (
                      <span>
                          Weet je zeker dat je <Bold>{user.profile.name}</Bold> wilt toevoegen?
                      </span>
                  ),
                  execute: {
                      buttonType: 'submit',
                      title: 'Toevoegen',
                  },
              }
            : undefined

        return (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={() => addUser(user)}
                confirm={confirm}
                leftIcon={<Icon name={`plus`} />}
            />
        )
    }
}

const GET_EMPLOYEE_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(sortBy: "profile.firstName", skip: $skip, limit: $limit, filters: $filters) {
            _id
            roles(filterMutable: true)
            profile {
                name
                notes
            }
        }
    }
`
