import React, { Component } from 'react'

import { ActionBar, Button, Icon } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { getFileUrl, printPdfByUrl } from '~/utils'

interface Props {
    getFileId: () => string
    fileName: string
    renderRightItems?: () => JSX.Element | null
    downloadText?: string
    printText?: string
}

interface State {
    isDownloadLoading: boolean
    isPrintLoading: boolean
}

export class PdfBar extends Component<Props, State> {
    public state: State = {
        isDownloadLoading: false,
        isPrintLoading: false,
    }

    public render() {
        const { isDownloadLoading, isPrintLoading } = this.state
        const { renderRightItems, downloadText, printText } = this.props

        return (
            <ActionBar
                fixed
                getButtons={() => {
                    return (
                        <List horizontal>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`download`} />}
                                    onClick={this.download}
                                    isLoading={isDownloadLoading}
                                >
                                    {downloadText || 'Downloaden'}
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`filter`} />}
                                    onClick={this.print}
                                    isLoading={isPrintLoading}
                                >
                                    {printText || 'Printen'}
                                </Button>
                            </ListItem>
                            {renderRightItems && renderRightItems()}
                        </List>
                    )
                }}
            />
        )
    }

    private download = async () => {
        this.setState({ isDownloadLoading: true })
        const fileId = await this.props.getFileId()
        const url = await getFileUrl(fileId, this.props.fileName, { asAttachment: true })
        if (url) {
            window.location.href = url
        }
        this.setState({ isDownloadLoading: false })
    }

    private print = async () => {
        this.setState({ isPrintLoading: true })
        const fileId = await this.props.getFileId()
        const url = await getFileUrl(fileId, this.props.fileName)
        printPdfByUrl(url as string)
        this.setState({ isPrintLoading: false })
    }
}
