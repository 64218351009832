import { gql } from '@apollo/client'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import { ProjectArticleCodeFields } from '~/implementations/ProjectArticleCodeFields'
import apolloClient from '~/services/apolloClient'
import { Fetcher, Mutator, parseGraphQLError, toast, Filter } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { Project, ProjectArticleCode } from '~/types/Project'
import { ContractProjectType } from '~/types/Contracts'
import { Program } from '~/types/Program'

interface Props {
    project: Project
    params: any
    refetch: () => void
}

interface State {
    projectType: ContractProjectType | null
    selectedProgramIds: string[]
    articleCodes: ProjectArticleCode[]
}

export default class EditView extends Component<Props, State> {
    public state: State = {
        projectType: null,
        selectedProgramIds: [],
        articleCodes: [],
    }

    private projectMutator: Mutator
    private programsFetcher: Fetcher
    private programsFilter: Filter

    constructor(props: Props) {
        super(props)

        this.state = {
            projectType: props.project.projectType || null,
            selectedProgramIds: (props.project.programs || []).map(program => program._id),
            articleCodes: props.project.articleCodes || [],
        }

        this.projectMutator = new Mutator({
            mutation: EDIT_PROJECT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.programsFilter = new Filter({
            allowedKeys: ['filterByProjectType'],
            onChange: (filters: any) => {
                this.programsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...filters,
                    },
                } as any)
            },
        } as any)

        this.programsFetcher = new Fetcher({
            query: GET_PROGRAMS_QUERY,
            onChange: () => this.forceUpdate(),
            variables: {
                filters: {
                    filterByProjectType: props.project.projectType,
                },
            },
        })
    }

    public render() {
        const { data, loading: loadingPrograms } = this.programsFetcher
        const { allPrograms = [] } = data
        const { project } = this.props
        const { articleCodes, projectType, selectedProgramIds } = this.state
        const { errors, loading } = this.projectMutator

        const programsOptions = (allPrograms || []).map((program: Program) => ({
            value: program._id,
            label: program.name,
        }))

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={`Gegevens`} isForm>
                                <Field isLabel title={'Naam'} errors={errors}>
                                    <Input
                                        name={`project.name`}
                                        type={`text`}
                                        placeholder={`Functienaam`}
                                        defaultValue={project.name || ''}
                                    />
                                </Field>
                                <Field title={'Type project'} errors={errors}>
                                    <TagPicker
                                        name={'project.projectType'}
                                        placeholder={`Selecteer een type`}
                                        options={[
                                            { label: 'Inburgering', value: ContractProjectType.Integration },
                                            { label: 'Organisatie', value: ContractProjectType.Organization },
                                            { label: 'Overig', value: ContractProjectType.Other },
                                        ]}
                                        multi={false}
                                        onChange={this.onProjectTypeChange}
                                        defaultValue={project.projectType}
                                    />
                                </Field>
                                <Field isLabel title={`E-mailadres projectteam`} errors={errors}>
                                    <Input
                                        type="email"
                                        name={`project.lessonInviteReplyTo`}
                                        placeholder={`E-mailadres`}
                                        defaultValue={project.lessonInviteReplyTo || ''}
                                    />
                                </Field>
                                {projectType && (
                                    <FieldGroup isInsetGroup={true}>
                                        <Field title={`Opleidingen`} errors={errors}>
                                            <TagPicker
                                                name={`project.programIds`}
                                                options={programsOptions}
                                                placeholder="Selecteer opleidingen"
                                                value={selectedProgramIds}
                                                onChange={this.onProgramChange}
                                                isLoading={loadingPrograms}
                                            />
                                        </Field>
                                    </FieldGroup>
                                )}
                            </FieldGroup>
                            <FieldGroup title={`Twinfield Artikel Codes`} isForm>
                                <ProjectArticleCodeFields
                                    articleCodes={articleCodes}
                                    errors={errors}
                                    name={`project.articleCodes`}
                                    isEditable
                                />
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() => browserHistory.push(`/properties/projects/${project._id}`)}
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onProjectTypeChange = (option: TagPickerOption) => {
        this.setState(
            {
                projectType: option ? (option.value as ContractProjectType) : null,
                selectedProgramIds: [],
            },
            () => {
                this.programsFilter.apply('filterByProjectType', option ? option.value : null)
            }
        )
    }

    private onProgramChange = (options: TagPickerOption[]) => {
        this.setState({
            selectedProgramIds: options ? options.map(option => option.value) : [],
        })
    }

    private onSubmit = (event: any, fields: any) => {
        const { params, refetch } = this.props

        const projectArguments = transformFormFields(fields.project, {
            programIds: transformLib.split(),
            articleCodes: {
                value: (articleCodesObject: any) => {
                    return Object.keys(articleCodesObject).reduce((array, paymentType) => {
                        const articleCodeByType = articleCodesObject[paymentType]

                        return [
                            ...array,
                            ...Object.keys(articleCodeByType)
                                .map(articleCodeType => ({
                                    paymentType,
                                    articleCodeType,
                                    articleCode: articleCodeByType[articleCodeType],
                                }))
                                .filter(o => !!o.articleCode),
                        ]
                    }, [])
                },
            },
        }) as any

        this.setState(
            {
                articleCodes: projectArguments.articleCodes,
            },
            async () => {
                const data = await this.projectMutator.mutate({
                    project: {
                        _id: params.id,
                        ...projectArguments,
                    },
                })

                if (data) {
                    refetch()
                    browserHistory.push(`/properties/projects/${params.id}`)
                }
            }
        )
    }

    private onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_PROJECT_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/projects`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'projects_delete' })
            toast.error(`${message}`)
        }
    }
}

const GET_PROGRAMS_QUERY = gql`
    query _($filters: ProgramsFiltersInputType) {
        allPrograms: programs(filters: $filters) {
            _id
            name
        }
    }
`

const EDIT_PROJECT_MUTATION = gql`
    mutation _($project: ProjectInputType!) {
        projects_edit(project: $project) {
            _id
            name
        }
    }
`

const DELETE_PROJECT_MUTATION = gql`
    mutation _($_id: MongoID!) {
        projects_delete(projectId: $_id) {
            _id
        }
    }
`
