import React from 'react'
import { Form, DrawerHeader, FieldCollection, Field, Input, MultiInput, PhoneInput, Bold } from '~/components'
import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { ContentView } from '~/components/ContentView'
import { FieldGroup } from '~/components/FieldGroup'
import { getCurrentUser } from '~/services/session'
import DatePicker from '~/components/DatePicker'
import { gql } from '@apollo/client'
import { Wrap } from '~/components/Wrap'
import { LearnerEnrollmentType } from '~/types/User'
import { ValidationError } from '~/components/ValidationError'
import { get } from 'lodash'
import { browserHistory } from 'react-router'
import { TagPicker } from '~/components/TagPicker'
import { FormFields } from '~/components/Form'
import DrawerFooter from '~/components/DrawerFooter'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'
import { ModalPopup } from '~/components/ModalPopup'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { Article } from '~/components/Article'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import { translateType } from '~/shared/utils'

interface Props {
    forOrganization?: boolean
    onSubmitSuccess: () => void
    onSubmitFailed?: any
    onCancel?: () => void
}
interface State {
    showModal: boolean
    errorEmail?: string
}

const CREATE_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_create(user: $user) {
            _id
        }
    }
`

export class NewLearnerForm extends React.Component<Props, State> {
    public state: State = {
        showModal: false,
        errorEmail: '',
    }
    private userMutator: Mutator

    constructor(props: Props) {
        super(props)
        this.closeModal = this.closeModal.bind(this)

        this.userMutator = new Mutator({
            mutation: CREATE_USER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel } = this.props
        const { showModal, errorEmail } = this.state
        const { loading: mutationLoading, errors } = this.userMutator

        const currentUser = getCurrentUser()

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={'Kandidaat aanmaken'} close={onCancel} />
                <ContentView>
                    <Wrap>
                        <FieldCollection errors={errors}>
                            <FieldGroup title={'Kandidaat'} isForm={true}>
                                <Field title="Naam" errors={errors}>
                                    <MultiInput type="name">
                                        <Input name="user.profile.firstName" type="text" placeholder={`Voornaam`} />
                                        <Input name="user.profile.initials" type="text" placeholder="Voorletters" />
                                        <Input
                                            name="user.profile.surNamePrefix"
                                            type="text"
                                            placeholder="Tussenvoegsel"
                                        />
                                        <Input name="user.profile.surName" type="text" placeholder="Achternaam" />
                                    </MultiInput>
                                </Field>
                                <Field title="Type aanmelding" errors={errors}>
                                    <TagPicker
                                        name={`user.learner.enrollmentType`}
                                        multi={false}
                                        placeholder={'Selecteer een type...'}
                                        options={[LearnerEnrollmentType.Intake, LearnerEnrollmentType.Assessment].map(
                                            option => ({
                                                value: option,
                                                label: translateType('enrollmentType', option),
                                            })
                                        )}
                                    />
                                </Field>
                                {currentUser.isEmployee && (
                                    <Field title="Datum aanmelding" errors={errors}>
                                        <DatePicker name={`user.learner.enrollmentDate`} defaultValue={new Date()} />
                                    </Field>
                                )}
                                {!currentUser.isEmployee && (
                                    <input
                                        type="hidden"
                                        name={`user.learner.enrollmentDate`}
                                        defaultValue={new Date().toISOString()}
                                    />
                                )}
                            </FieldGroup>
                            <FieldGroup title={'Contactgegevens'} isForm={true}>
                                {currentUser.isOrganizationContact && (
                                    <Field>
                                        <ValidationError
                                            isWarning={true}
                                            fullWidth={true}
                                            // tslint:disable-next-line:max-line-length
                                            text={`In het geval van een incompany training is het alleen toegestaan contactgegevens (telefoonnummer, e-mailadres en adres) met ons te delen na toestemming van de kandidaat zelf`}
                                        />
                                    </Field>
                                )}
                                <Field title={'Telefoonnummer'} errors={errors}>
                                    <PhoneInput name={`user.profile.phoneNumbers[0]`} />
                                </Field>
                                <Field title={'E-mailadres'} errors={errors}>
                                    <Input type="email" name="user.email" />
                                </Field>
                                <LocationFieldsInputGroup
                                    title={'Adres'}
                                    errors={errors}
                                    name={'user.profile.address'}
                                />
                            </FieldGroup>
                            {this.renderOrganizationFieldGroup(errors)}
                        </FieldCollection>
                        <ModalPopup
                            modalIsShown={showModal}
                            closeModal={this.closeModal}
                            title={
                                errorEmail
                                    ? `Het e-mailadres ${errorEmail} is reeds bekend bij TopTaal.`
                                    : `Het e-mailadres is reeds bekend bij TopTaal.`
                            }
                            renderContent={() => {
                                return (
                                    <ContentView>
                                        <Wrap>
                                            <Article>
                                                Om te voorkomen dat kandidaten dubbel in het systeem komen te staan,
                                                willen we u we u vragen contact op te nemen met uw contactpersoon
                                                TopTaal. Dan kunnen wij de juiste persoon vinden en deze handmatig aan
                                                uw organisatie koppelen. Vermeld hierbij minstens de volgende informatie
                                                van de betreffende kandidaat:
                                            </Article>
                                            <br />
                                            <List horizontal={false}>
                                                <ListItem>
                                                    <Bold> {'\u2022'} Volledige naam</Bold>
                                                </ListItem>
                                                <ListItem>
                                                    <Bold> {'\u2022'} Geboortedatum</Bold>
                                                </ListItem>
                                                <ListItem>
                                                    <Bold> {'\u2022'} E-mailadres</Bold>
                                                </ListItem>
                                            </List>
                                            <br />
                                            <br />
                                        </Wrap>
                                    </ContentView>
                                )
                            }}
                        />
                    </Wrap>
                </ContentView>
                <DrawerFooter close={onCancel} isLoading={mutationLoading} />
            </Form>
        )
    }

    private renderOrganizationFieldGroup = (errors: any) => {
        const currentUser = getCurrentUser()

        if (!currentUser.isOrganizationContact) {
            return <></>
        }

        const currentUserOrganization = currentUser.organizationContact.organization
        const currentUserColleagues = currentUserOrganization.contactUsers || []
        const options = currentUserColleagues.map((cu: any) => ({ value: cu._id, label: cu.profile.name }))

        return (
            <FieldGroup title={'Organisatie'} isForm={true}>
                <Field title={`Contactpersoon`} errors={errors}>
                    <TagPicker
                        name={`user.learner.organizationContactUserIdBasedOnContextOrganization`}
                        placeholder={`Bij ${currentUserOrganization.name}`}
                        defaultValue={currentUser._id}
                        multi={false}
                        options={options}
                    />
                </Field>
                <Field title={`Dossiernummer`} errors={errors} isLabel={true}>
                    <Input name={`user.learner.organizationFileNumberBasedOnContextOrganization`} type="text" />
                </Field>
            </FieldGroup>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        const currentUser = getCurrentUser()
        fields.user = {
            ...fields.user,
            learner: {
                ...fields.user.learner,
                isActive: true,
            },
        }

        if (currentUser.isOrganizationContact) {
            const organizationId = get(currentUser, 'organizationContact.organization._id')

            fields.user = {
                ...fields.user,
                learner: {
                    ...fields.user.learner,
                    isActive: true,
                    organizations: [{ organizationId: organizationId }],
                },
            }
        }

        const data = await this.userMutator.mutate({
            user: {
                ...transformFormFields(fields.user, {
                    profile: {
                        fields: (v: any) =>
                            transformFormFields(v, {
                                address: transformLib.address(),
                                phoneNumbers: transformLib.phoneNumbers(),
                            }),
                    },
                    learner: {
                        fields: (v: any) => transformFormFields(v),
                    },
                }),
                type: 'LEARNER',
            },
        })

        if (data) {
            if (onSubmitSuccess) {
                onSubmitSuccess()
            }
            browserHistory.push(`${currentUser.isEmployee ? '/users' : ''}/learners/${get(data, 'users_create._id')}`)
        } else {
            if (onSubmitFailed) {
                onSubmitFailed()
            } else {
                const { errors }: { errors: GraphQLErrors } = this.userMutator
                const error = errors.getErrors().find((o: any) => o.field === 'user.email')
                if (error && error.message.slice(0, 47) === 'Er bestaat al een gebruiker met dit e-mailadres') {
                    this.setState({
                        errorEmail: fields.user.email,
                    })
                    this.openModal()
                }
            }
        }
    }

    private openModal() {
        this.setState({
            showModal: true,
        })
    }

    public closeModal() {
        this.setState({
            showModal: false,
        })
    }
}
