import './PasswordInput.scss'

import * as React from 'react'
import c from 'classnames'
import Input, { InputProps } from '~/components/Input'
import { Translator } from '~/services/i18n'
import translateType from '~/shared/utils/translateType'
import zxcvbn from 'zxcvbn'

interface Props extends InputProps {
    onChange?: never
    onStrengthChange?: (score: number) => void
    onChangePassword?: (value: string | null, name?: string) => void
}

interface State {
    passwordValue?: string
    passwordInfo?: zxcvbn.ZXCVBNResult | null
}

export class PasswordInput extends React.PureComponent<Props, State> {
    private static checker: any

    public state: State = {
        passwordValue: undefined,
        passwordInfo: undefined,
    }

    private translator = new Translator({
        namespace: 'components.PasswordInput',
        subscribe: this,
    })

    public async componentDidMount() {
        const zxcvbn = await import('zxcvbn')
        PasswordInput.checker = zxcvbn.default
    }

    public render() {
        const { className, ...inputProps } = this.props

        return (
            <div className={this.getClassName()}>
                <Input
                    {...inputProps}
                    type="password"
                    className={`tt-PasswordInput__input`}
                    onChange={this.onPasswordChange}
                />
                {this.renderStrengthBar()}
                {this.renderResult()}
            </div>
        )
    }

    private renderStrengthBar() {
        const { passwordInfo } = this.state

        if (!passwordInfo) {
            return null
        }

        const scoreFactor = (passwordInfo.score + 1) / 5

        return (
            <div className={`tt-PasswordInput__strength-bar`}>
                <div
                    className={`tt-PasswordInput__strength-bar-inner`}
                    style={{ transform: `translateX(${scoreFactor * 100 - 100}%)` }}
                />
            </div>
        )
    }

    private renderResult() {
        const { passwordInfo } = this.state
        const { t } = this.translator

        if (!passwordInfo) {
            return null
        }

        const warning =
            passwordInfo.feedback.warning &&
            translateType('passwordWarnings', passwordInfo.feedback.warning.replace(/"|'/g, ''))

        if (warning) {
            return <span className={`tt-PasswordInput__warning`}>{warning}</span>
        }

        return (
            <span className={`tt-PasswordInput__strength`}>
                {t(`strengthPrefix`)}
                <span className={`tt-PasswordInput__strength-value`}>
                    {translateType('passwordStrength', passwordInfo.score)}
                </span>
            </span>
        )
    }

    private onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChangePassword, onStrengthChange, name } = this.props

        const password = event.target.value

        this.setState(
            {
                passwordValue: password || '',
                passwordInfo: !password ? undefined : PasswordInput.checker(password),
            },
            () => {
                if (onChangePassword) {
                    onChangePassword(this.state.passwordValue || null, name)
                }

                if (onStrengthChange) {
                    onStrengthChange(this.state.passwordInfo ? this.state.passwordInfo.score : 0)
                }
            }
        )
    }

    private getClassName = () => {
        const { className } = this.props
        const { passwordInfo } = this.state
        const strengthScore = passwordInfo ? passwordInfo.score : null

        return c(
            'tt-PasswordInput',
            {
                'tt-PasswordInput--strength-very-weak': strengthScore === 0,
                'tt-PasswordInput--strength-weak': strengthScore === 1,
                'tt-PasswordInput--strength-medium': strengthScore === 2,
                'tt-PasswordInput--strength-strong': strengthScore === 3,
                'tt-PasswordInput--strength-very-strong': strengthScore === 4,
            },
            className
        )
    }
}
