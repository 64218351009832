import React from 'react'
import moment from 'moment'

import { capitalize as capitalizeFunc } from 'lodash'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    date?: Date | string
    format?: string
    showTime?: boolean
    showDayOfWeek?: boolean
    hasInsertion?: boolean
    capitalize?: boolean
    asDifferenceWith?: Date
    className?: ClassValue
}

export default class ReadableDate extends React.Component<Props> {
    public static defaultProps = {
        showTime: false,
        hasInsertion: true,
    }

    private bem = new BEM('ReadableDate')

    public render() {
        const { date, capitalize, className } = this.props

        if (!date || !moment(date).isValid()) {
            return <span />
        }

        const { spanProps, dateString } = this.getDate()

        return (
            <span className={this.bem.getClassName(className)} {...spanProps}>
                {capitalize ? capitalizeFunc(dateString) : dateString}
            </span>
        )
    }

    private getMoment = () => {
        const { date } = this.props
        const utcMoment = moment(date).utc()

        if (utcMoment.format('HH:mm:ss:SSS') === '00:00:00:000') {
            return utcMoment
        }

        return moment(date)
    }

    private getFullDate = () => {
        const { hasInsertion } = this.props
        const m = this.getMoment()

        const dateFormat = this.getDateFormat()

        return m.isUtc() ? m.format(dateFormat) : m.format(`${dateFormat} ${hasInsertion ? `[om] HH:mm` : 'HH:mm'}`)
    }

    private getCompactDate = () => {
        const m = this.getMoment()
        return m.format(this.getDateFormat())
    }

    private getDateFormat = () => {
        const { showDayOfWeek } = this.props

        return showDayOfWeek ? 'ddd LL' : 'LL'
    }

    private getDate = () => {
        const { showTime, format, asDifferenceWith } = this.props

        return {
            dateString: asDifferenceWith
                ? this.getMoment().from(asDifferenceWith)
                : format
                ? this.getMoment().format(format)
                : showTime
                ? this.getFullDate()
                : this.getCompactDate(),
            spanProps: {
                title: this.getFullDate(),
            },
        }
    }
}
