import 'fullcalendar'

import c from 'classnames'
import $ from 'jquery'
import _, { isBoolean, isEqual } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import momentPropTypes from 'react-moment-proptypes'

import { Spinner } from '~/components'

export default class Calendar extends Component {
    static propTypes = {
        isGeneral: PropTypes.bool, // e.g. general week
        isLoading: PropTypes.bool,
        defaultView: PropTypes.string, // any of fullcalendar.io's views
        disableViewChange: PropTypes.bool,
        selectSlotType: PropTypes.string,
        allowCreateEvents: PropTypes.bool,
        allowEditEvents: PropTypes.bool,
        allDaySlot: PropTypes.bool,
        events: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), momentPropTypes.momentObj]),
                end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), momentPropTypes.momentObj]),
                // ... and other event properties
            })
        ),
        onSelectSlot: PropTypes.func,
        onMoveEvent: PropTypes.func,
        onResizeEvent: PropTypes.func,
        onClickEvent: PropTypes.func,
        disablePlaceholder: PropTypes.bool,
        minHours: PropTypes.number,
        maxHours: PropTypes.number,
        height: PropTypes.any,
        slotDuration: PropTypes.string,
        snapDuration: PropTypes.string,
        onAddEventButtonClick: PropTypes.func,
        allowEditEventStart: PropTypes.bool,
        allowEditEventDuration: PropTypes.bool,
    }

    static defaultProps = {
        isGeneral: false,
        isLoading: false,
        defaultView: 'agendaWeek',
        disableViewChange: false,
        allowCreateEvents: false,
        allowEditEventStart: true,
        allowEditEventDuration: true,
        disablePlaceholder: false,
        snapDuration: '00:15',
        slotDuration: '01:00',
        allowEditEvents: false,
        events: [],
        onSelectSlot: () => {},
        onMoveEvent: () => {},
        onResizeEvent: () => {},
        onClickEvent: () => {},
    }

    constructor(props) {
        super(props)

        const options = {
            locale: 'nl',
            timezone: 'local',
            select: (start, end, ...args) => {
                return props.onSelectSlot(this.convertToLocalMoment(start), this.convertToLocalMoment(end), ...args)
            },
            eventDrop: this.onEventDrop,
            eventResize: props.onResizeEvent,
            eventClick: (event, ...args) => {
                if ('editable' in event && !event.editable) {
                    return
                }

                event.start = this.convertToLocalMoment(event.start)
                if (event.end) {
                    event.end = this.convertToLocalMoment(event.end)
                }

                return props.onClickEvent(event, ...args)
            },
            height: props.height || 600,
            unselectAuto: false,
            theme: true, // disables inline styling
            slotLabelFormat: 'H:mm',
            slotDuration: props.slotDuration,
            snapDuration: props.snapDuration,
            allDaySlot: isBoolean(props.allDaySlot) ? props.allDaySlot : !props.isGeneral,
            events: this.convertEvents(props.events),
            views: {
                month: {
                    columnFormat: 'dd',
                },
                agendaWeek: {
                    columnFormat: 'dd D',
                },
            },
            defaultView: props.defaultView,
            eventStartEditable: props.allowEditEventStart,
            eventDurationEditable: props.allowEditEventDuration,
        }

        this.standardEvents = []

        /**
         * Header configuration
         */
        options.header = {
            left: '',
            center: '',
            right: '',
        }

        if (!props.isGeneral) {
            options.header.left = 'prev,next today'
            options.header.center = 'title'

            if (!props.disableViewChange) {
                options.header.right = 'month,agendaWeek,agendaDay,listWeek'
            } else {
                options.header.center = ''
                options.header.right = 'title'
            }
        }

        /**
         * Configuration for general mode
         */
        if (props.isGeneral) {
            options.views.agendaWeek.columnFormat = 'dd'
            options.defaultDate = moment('2001-01-01')
        }

        /**
         * Configuration for time restriction (minHours and/or maxHours)
         *
         */
        if (props.minHours || props.maxHours) {
            const minHours = props.minHours || 0
            const maxHours = props.maxHours || 24

            const constraint = {
                start: `${minHours}:00`,
                end: `${maxHours}:00`,
                dow: [0, 1, 2, 3, 4, 5, 6],
            }

            options.eventConstraint = constraint
            options.selectConstraint = constraint

            options.minTime = `${minHours}:00:00`
            options.maxTime = `${maxHours}:00:00`
        }

        /**
         * Allow user to create new events
         */
        if (props.allowCreateEvents) {
            options.selectable = true
            options.selectHelper = true
        }

        /**
         * Allow user to edit existing events
         */
        if (props.allowEditEvents) {
            options.editable = true
        }

        /**
         * Concatenate standardEvents (events that are created here, apart from props.events)
         */
        options.events = options.events.concat(this.standardEvents)

        /**
         * If we are on mobile touch device show only the listweek view
         */
        if (!(window.innerWidth > 1024) && Calendar.touchEnabled) {
            options.customButtons = {
                add: {
                    text: 'Toevoegen',
                    click: this.addEvent,
                },
            }

            options.header.right = 'add'
            options.height = 'auto'

            if (!props.isGeneral) {
                options.header.center = 'title'
            }

            options.defaultView = 'listWeek'
        }

        // This needs to be at the end to overwrite the value in the
        // Allow create events option
        options.selectHelper = !props.disablePlaceholder

        /**
         * Default fullcalendar options
         */
        this.fullCalendarOptions = options
    }

    convertEvents(events) {
        return events.map(e => {
            const editable = e.type === this.props.selectSlotType

            const allDay =
                moment(e.start).format('HH:mm:ss') === '00:00:00' && moment(e.end).format('HH:mm:ss') === '00:00:00'

            return {
                ...e,
                className: (e.type && `tt-Calendar__event--${e.type}`) || '',
                editable: editable,
                startEditable: editable,
                durationEditable: editable,
                allDay: allDay,
            }
        })
    }

    /**
     * Ensure a moment to is a local moment;
     * this is needed for when adding a allDay date since allDay dates are always UTC even
     * when initializing the calendar with timezone: 'local'
     * see issue: https://github.com/fullcalendar/fullcalendar/issues/2477
     *
     * @param {moment} m
     */
    convertToLocalMoment(m) {
        return moment(m.format())
    }

    addEvent = () => {
        if (!this.calendar) {
            return
        }

        const event = {
            title: '',
            start: moment(),
        }

        this.calendar.fullCalendar('addEventSource', [event])

        if (this.props.onAddEventButtonClick) {
            this.props.onAddEventButtonClick(event)
        }
    }

    onEventDrop = event => {
        const { onMoveEvent } = this.props

        const start = event.start
        let end = event.end

        // Allday event
        if (event.allDay) {
            // Fix `start` and `end` fields
            start.hours(0)
            start.minutes(0)
            start.seconds(0)
            start.milliseconds(0)
            end = moment(start).add(1, 'day')
        } else if (!event.end) {
            // Fix `end` fields
            end = moment(start)
            end.add(2, 'hours')
        }

        onMoveEvent({
            ...event,
            start: this.convertToLocalMoment(start),
            end: this.convertToLocalMoment(end),
        })
    }

    componentDidUpdate(prevProps) {
        if (!this.calendar) {
            return
        }

        if (!isArrayEqual(prevProps.events, this.props.events)) {
            this.calendar.fullCalendar('removeEvents')

            this.calendar.fullCalendar(
                'addEventSource',
                this.convertEvents(this.props.events).concat(this.standardEvents)
            )
        }
    }

    mountFullCalendar = el => {
        setTimeout(() => {
            this.calendar = $(el).fullCalendar(this.fullCalendarOptions)
        })
    }

    render() {
        const { selectSlotType, isLoading, isGeneral } = this.props

        return (
            <div
                className={c(`tt-Calendar`, {
                    'tt-Calendar--is-loading': isLoading,
                    'tt-Calendar--is-general': isGeneral,
                    'tt-Calendar--is-touch': !(window.innerWidth > 1024) && Calendar.touchEnabled,
                    [`tt-Calendar--default-event--${selectSlotType}`]: selectSlotType,
                })}
            >
                <div ref={this.mountFullCalendar} />
                <Spinner />
            </div>
        )
    }
}

/**
 * Deep array comparison helper
 *
 * (Currently used to determine whether the props.events have changed
 * in componentDidUpdate)
 *
 * @param {Array} x
 * @param {Array} y
 * @returns {Boolean} whether x is deeply equal to y
 */
function isArrayEqual(x, y) {
    return Boolean(x.length === y.length && _(x).differenceWith(y, isEqual).isEmpty())
}
