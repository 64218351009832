import * as React from 'react'

import { getCurrentUser } from '~/services/session'
import { View } from '~/components/View'
import Header from '~/components/Header'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { FinalExamsConceptTable } from '~/components/FinalExams/FinalExamsConceptTable'
import { PageTabs, PageTab, Link } from '~/components'
import { Translator } from '~/services/i18n'

interface Props {}

export default class MasterView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App.FinalExams',
        subscribe: this,
    })

    public render() {
        const { t } = this.translator

        return (
            <View>
                <Header>
                    <BreadCrumbs />
                    {this.isUserAllowedToView() && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/final-exams/candidates`}>
                                    {t('navigation.buttons.concept')}
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/final-exams/planned`}>
                                    {t('navigation.buttons.planned')}
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                <ContentView>{this.isUserAllowedToView() && <FinalExamsConceptTable />}</ContentView>
            </View>
        )
    }

    private isUserAllowedToView(): boolean {
        const currentUser = getCurrentUser()

        if (!currentUser) {
            return false
        }

        return currentUser.isEmployee || currentUser.isExternalTeacher || currentUser.isOrganizationContact
    }
}
