import { gql } from '@apollo/client'
import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { CourseMaterial } from '~/types/Module'
import { UnitInput, unitType } from '~/components/UnitInput'

const EDIT_COURSEMATERIAL_MUTATION = gql`
    mutation _($courseMaterial: CourseMaterialInputType!) {
        courseMaterials_edit(courseMaterial: $courseMaterial) {
            _id
            name
        }
    }
`

const DELETE_COURSEMATERIAL_MUTATION = gql`
    mutation _($_id: MongoID!) {
        courseMaterials_delete(courseMaterialId: $_id) {
            _id
        }
    }
`

interface EditCourseMaterialFields {
    courseMaterial: {
        name: string
        amount: number
    }
}

interface Params {
    id: string
}

interface Props extends RouteComponentProps<Params, {}> {
    refetch: () => void
    courseMaterial?: CourseMaterial
}

export default class EditView extends React.Component<Props> {
    private courseMaterialMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.courseMaterialMutator = new Mutator({
            mutation: EDIT_COURSEMATERIAL_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { courseMaterial } = this.props
        const { errors, loading } = this.courseMaterialMutator

        if (!courseMaterial) {
            return null
        }

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup>
                                <Field isLabel={true} title={'Naam'} errors={errors}>
                                    <Input
                                        name={`courseMaterial.name`}
                                        type={`text`}
                                        placeholder={`Naam`}
                                        defaultValue={courseMaterial.name}
                                    />
                                </Field>
                                <Field isLabel={true} title={'Bedrag'} errors={errors}>
                                    <UnitInput
                                        name={`courseMaterial.amount`}
                                        placeholder={`Bedrag`}
                                        defaultValue={courseMaterial.amount}
                                        unitType={unitType.euro}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal={true}>
                                    <ListItem right={true}>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/courseMaterials/${courseMaterial._id}`)
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right={true}>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: EditCourseMaterialFields) => {
        const { params, refetch } = this.props

        const data = await this.courseMaterialMutator.mutate({
            courseMaterial: {
                _id: params.id,
                ...transformFormFields(fields.courseMaterial),
            },
        })

        if (data) {
            refetch()
            browserHistory.push(`/properties/courseMaterials/${params.id}`)
        }
    }

    private onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_COURSEMATERIAL_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/courseMaterials`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'courseMaterials_delete' })
            toast.error(`${message}`)
        }
    }
}
