import { gql } from '@apollo/client'
import React, { cloneElement } from 'react'
import { RouteComponentProps } from 'react-router'
import { get } from 'lodash'

import { Header, PageTabs, PageTab, Link } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { ContractProjectType } from '~/types/Contracts'

export interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {}

export default class DetailView extends React.Component<Props> {
    private projectFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.projectFetcher = new Fetcher({
            query: GET_PROJECT_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate({ params: prevParams }: Props) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.projectFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.projectFetcher
        const project = data && data.projects && data.projects[0]
        const orphanPrograms = (project && project.orphanPrograms) || []
        const projectType = project && project.projectType

        return (
            <RouteView crumbLabel={get(project, 'name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {projectType === ContractProjectType.Organization && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/properties/projects/${routeProps.params.id}`}>
                                    {'Gegevens'}
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link route={`/properties/projects/${routeProps.params.id}/invoicing-settings`}>
                                    {'Facturatie-instellingen'}
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                {project && cloneElement(React.Children.only(children as any), { project, orphanPrograms, refetch })}
            </RouteView>
        )
    }
}

const GET_PROJECT_QUERY = gql`
    query _($_id: MongoID) {
        projects(byId: $_id) {
            _id
            name
            lessonInviteReplyTo
            articleCodes {
                paymentType
                articleCodeType
                articleCode
            }
            programs {
                _id
                name
            }
            orphanPrograms: programs(filterByAvailableForNewInvoicingSetting: true) {
                _id
                name
            }
            projectType
        }
    }
`
