import * as React from 'react'
import { Link } from '~/components'
import { ListItem } from '~/components/ListItem'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    onClick?: () => void
    isLinkActive?: boolean
    right?: boolean
    route?: string
}

export class MainNavLink extends React.Component<Props> {
    private bem = new BEM('MainNavLink', () => ({
        right: this.props.right,
    }))

    public render() {
        const { children, className, onClick, right, route, isLinkActive } = this.props

        return (
            <ListItem className={this.bem.getClassName(className)} right={right}>
                <Link route={route} onClick={onClick} isLinkActive={isLinkActive}>
                    {children}
                </Link>
            </ListItem>
        )
    }
}
