import * as React from 'react'
import { getCurrentUser } from '~/services/session'
import { EmployeeGroupInfo } from '~/implementations/EmployeeGroupInfo'
import { ExternalUserGroupInfo } from '~/implementations/ExternalUserGroupInfo'
import { ContentView } from '~/components/ContentView'
import { Group } from '~/types/Group'

interface Props {
    group: Group
    refetch: () => void
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { group, refetch } = this.props
        const currentUser = getCurrentUser()

        return (
            <ContentView>
                {currentUser.isEmployee && <EmployeeGroupInfo group={group} refetch={refetch} />}
                {(currentUser.isExternalTeacher || currentUser.isOrganizationContact) && (
                    <ExternalUserGroupInfo group={group} />
                )}
            </ContentView>
        )
    }
}
