import { InflowModule } from './InflowModule'

export const inflowInviteTemplateTextVariables = [
    {
        label: 'Vrij tekstveld',
        value: 'VrijTekstveld',
    },
    {
        label: 'Datum versturen uitnodiging',
        value: 'DatumVersturenUitnodiging',
    },
    {
        label: 'Volledige naam kandidaat',
        value: 'VolledigeNaamKandidaat',
    },
    {
        label: 'Volledige adres kandidaat',
        value: 'VolledigeAdresKandidaat',
    },
    {
        label: 'Telefoonnummer kandidaat',
        value: 'TelefoonnummerKandidaat',
    },
    {
        label: 'E-mailadres kandidaat',
        value: 'E-mailadresKandidaat',
    },
    {
        label: 'Organisatie gekoppeld aan de kandidaat',
        value: 'OrganisatieGekoppeldAanDekandidaat',
    },
    {
        label: 'Type instroom',
        value: 'TypeInstroom',
    },
    {
        label: 'Datum instroommoment',
        value: 'DatumInstroommoment',
    },
    {
        label: 'Aanvangsttijd instroommoment',
        value: 'AanvangsttijdInstroommoment',
    },
    {
        label: 'Eindtijd instroommoment',
        value: 'EindtijdInstroommoment',
    },
    {
        label: 'Aanvangsttijd tijdslot instroommoment',
        value: 'AanvangsttijdTijdslotInstroommoment',
    },
    {
        label: 'Maximale toetstijd per kandidaat',
        value: 'MaximaleToetstijdPerKandidaat',
    },
    {
        label: 'Naam toetslocatie',
        value: 'NaamToetslocatie',
    },
    {
        label: 'Volledig adres toetslocatie',
        value: 'VolledigAdresToetslocatie',
    },
    {
        label: 'Lesruimte toetslocatie',
        value: 'LesruimteToetslocatie',
    },
    {
        label: 'Namen en e-mailadressen toetsmedewerkers',
        value: 'NaamEnE-mailadresToetsmedewerkers',
    },
]

export interface InflowInviteTemplate {
    _id: string
    inflowModule: InflowModule
    name: string
    updatedAt: Date
    nl?: InflowInviteTemplateText
    en?: InflowInviteTemplateText
    attachments?: InflowInviteTemplateFile[]
}

export interface InflowInviteTemplateText {
    content: string
}

export interface InflowInviteTemplateFile {
    _id: string
    fileName: string
}
