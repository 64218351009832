import './ValueActionField.scss'
import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { ListItem } from '~/components/ListItem'

interface Props {
    className?: ClassValue
}

export class ValueActionField extends React.Component<Props> {
    private bem = new BEM('ValueActionField')

    public render() {
        const { className, children } = this.props

        return <ListItem className={this.bem.getClassName(className)}>{children}</ListItem>
    }
}
