import * as React from 'react'
import { Icon } from '~/components'

export const SentIcon = () => <Icon name={'status_light'} color={'#2490e6'} />

export const PaidIcon = () => <Icon name={'status_done'} color={'#2cc472'} />

export const ApprovedIcon = () => <Icon name={'status_done'} color={'#2490e6'} />

export const CanceledIcon = () => <Icon name={'status_error'} color={'#a8aeb3'} />

export const ActivateIcon = () => <Icon name={'status_plus'} color={'#e87b51'} />

export const SignatureIcon = () => <Icon name={'status_pencil'} color={'#a8aeb3'} />

export const AlertIcon = () => <Icon name={'status_attention'} color={'#e85156'} />

export const WaitIcon = () => <Icon name={'status_wait'} color={'#2490e6'} />

export const FlagIcon = () => <Icon name={`flag-error`} color={'#e85156'} />

export const WarningFlagIcon = () => <Icon name={`flag-error`} color={'#e1e3e6'} />

export const ShutDownIcon = () => <Icon name={`status_down`} color={`#314E93`} />
