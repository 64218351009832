import * as React from 'react'
import { gql } from '@apollo/client'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import Bold from '~/components/Bold'
import Mutator from '~/utils/Mutator'

interface Props {
    userId: string
    userName: string
    groupId: string
    groupName: string
    onRemoved?: () => void
}

const REMOVE_PARTICIPANT_FROM_GROUP = gql`
    mutation _($groupId: MongoID!, $userId: MongoID!) {
        groups_undoEnrollment(groupId: $groupId, userId: $userId) {
            _id
        }
    }
`

export class RemoveTeacherOrEmployeeFromGroupButton extends React.Component<Props> {
    private removeParticipantFromGroupMutator = new Mutator({
        mutation: REMOVE_PARTICIPANT_FROM_GROUP,
        reactComponentToUpdate: this,
    })

    public render() {
        const { userName, groupName } = this.props

        return (
            <Button
                className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                type={`in-row`}
                style={{ float: 'right' }}
                leftIcon={<Icon name={`trash`} />}
                onClick={this.removeParticipantFromGroup}
                confirm={{
                    title: 'Verwijderen',
                    // tslint:disable-next-line:max-line-length
                    message: (
                        <span>
                            Je staat op het punt om <Bold>{userName}</Bold> te verwijderen uit de groep{' '}
                            <Bold>{groupName}</Bold>. Weet je het zeker?
                        </span>
                    ),
                    execute: {
                        buttonType: 'danger',
                        title: 'Verwijderen',
                    },
                }}
            />
        )
    }

    private removeParticipantFromGroup = async () => {
        const { groupId, userId, onRemoved } = this.props

        const res = await this.removeParticipantFromGroupMutator.mutate({
            groupId,
            userId,
        })

        if (res && res.groups_undoEnrollment && onRemoved) {
            onRemoved()
        }
    }
}
