import * as React from 'react'

import { CenterModal, Link, Subtle } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { EditGroupModuleForm } from '~/forms'
import { Mutator } from '~/utils'

import { GroupFieldGroup } from '../GroupFieldGroup'
import { Group } from '~/types/Group'

interface Props {
    group: Group
    refetchGroup: () => void
    validationErrors?: any[]
    groupEditMutator: Mutator
}

export default class GroupModuleFieldGroup extends React.Component<Props> {
    public static RELATED_ERROR_KEYS = [
        'module-required',
        'module-lesson-duration-required',
        'module-must-be-active',
        'module-amount-of-total-lessons-required',
        'module-amount-of-weekly-lessons-required',
    ]

    public async removeModule() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                moduleId: null,
                programId: null,
                projectId: null,
            },
        })

        refetchGroup()
    }

    public render() {
        const { group, validationErrors } = this.props
        const moduleName = group.module && group.module.name
        const moduleId = group.module && group.module._id

        return (
            <GroupFieldGroup
                title={`Lesmodule`}
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                group={group}
                onRemove={this.removeModule}
                removeMessage={`Je staat op het punt om lesmodule "${moduleName}" te verwijderen uit deze groep. Weet je het zeker?`}
            >
                <SimpleTable>
                    {group.project && (
                        <SimpleTableRow>
                            <SimpleTableCell>Project</SimpleTableCell>
                            <SimpleTableCell>
                                <Link route={`/properties/projects/${group.project._id}`}>{group.project.name}</Link>
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    {group.program && (
                        <SimpleTableRow>
                            <SimpleTableCell>Opleiding</SimpleTableCell>
                            <SimpleTableCell>
                                <Link route={`/properties/programs/${group.program._id}`}>{group.program.name}</Link>
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    <SimpleTableRow>
                        <SimpleTableCell>Lesmodule</SimpleTableCell>
                        <SimpleTableCell>
                            <Link route={`/properties/modules/${moduleId}`}>{moduleName}</Link>
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
                <Subtle>{this.renderModuleInformation()}</Subtle>
            </GroupFieldGroup>
        )
    }

    private renderModuleInformation = () => {
        const { group } = this.props
        const { module } = group

        if (!module) {
            return null
        }

        return [
            `${module.amountOfTotalLessons || 0} ${module.amountOfTotalLessons === 1 ? 'les' : 'lessen'}`,
            `${module.amountOfWeeklyLessons || 0} keer per week`,
            `${module.lessonDuration || 0} ${module.amountOfTotalLessons === 1 ? 'minuut' : 'minuten'}`,
        ].join(' / ')
    }

    private getEditModal = (closeModal: () => void) => {
        const { group, refetchGroup } = this.props

        return (
            <CenterModal onClose={closeModal} title={`Lesmodule bewerken`}>
                <EditGroupModuleForm
                    group={group}
                    onCancel={closeModal}
                    onSubmitSuccess={closeModal}
                    refetch={refetchGroup}
                />
            </CenterModal>
        )
    }
}
