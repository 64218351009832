import React, { Component } from 'react'
import { getCurrentUser } from '~/services/session'
import { ContentView } from '~/components/ContentView'
import ExternalTeacherProfile from './ExternalTeacherProfile'
import ExternalOrganizationContactProfile from './ExternalOrganizationContactProfile'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

export default class ExternalUserProfile extends Component {
    render() {
        const { ...routeProps } = this.props
        const currentUser = getCurrentUser()

        return (
            <ContentView>
                <BreadCrumbProvider>
                    {currentUser && currentUser.isExternalTeacher && <ExternalTeacherProfile {...routeProps} />}
                    {currentUser && currentUser.isOrganizationContact && (
                        <ExternalOrganizationContactProfile {...routeProps} />
                    )}
                </BreadCrumbProvider>
            </ContentView>
        )
    }
}
