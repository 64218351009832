import * as React from 'react'

import { List } from '~/components/List'

interface Props {}

export class AnchorIndexList extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <List className={`tt-AnchorIndexList`}>{children}</List>
    }
}
