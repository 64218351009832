import React from 'react'

import { ContentView } from '~/components/ContentView'
import { UserLearnerHoursTable } from '~/components/users/UserLearnerHoursTable'

interface Props {
    userId: string
}

export default class LearnerHoursView extends React.Component<Props> {
    public render() {
        const { userId } = this.props

        return (
            <ContentView>
                <UserLearnerHoursTable
                    // onSubmitSuccess={refetch}
                    userId={userId}
                />
            </ContentView>
        )
    }
}
