import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'

import { Mutator } from '~/utils'
import { CenterModal } from '~/components'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { EditGroupDateBlockerForm } from '~/forms'
import { DateBlocker } from '~/components/DateBlocker'

export default class GroupDateBlockersFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        groupEditMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = []

    constructor(props) {
        super(props)
        bindAll(this, ['removeDateBlockers', 'renderEditModal'])
    }

    async removeDateBlockers() {
        const { group, refetchGroup, groupEditMutator } = this.props

        await groupEditMutator.mutate({
            group: {
                _id: group._id,
                nulls: ['dateBlockers'],
            },
        })

        refetchGroup()
    }

    render() {
        const { validationErrors } = this.props

        return (
            <GroupFieldGroup
                title="Vakantieperiode(s)"
                validationErrors={validationErrors}
                getEditModal={this.renderEditModal}
                onRemove={this.removeDateBlockers}
                removeMessage="Je staat op het punt om alle vakantieperiodes uit deze groep te verwijderen. Weet je het zeker?"
            >
                {this.renderDateBlockersList()}
            </GroupFieldGroup>
        )
    }

    renderDateBlockersList() {
        const { group } = this.props
        const { dateBlockers } = group

        return (
            <SimpleTable>
                {dateBlockers.map((dateBlocker, i) => {
                    const n = i + 1

                    return (
                        <SimpleTableRow key={i}>
                            <SimpleTableCell isBold>Periode {n}</SimpleTableCell>
                            <SimpleTableCell>
                                <DateBlocker range={dateBlocker} />
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )
                })}
            </SimpleTable>
        )
    }

    renderEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <CenterModal onClose={closeModal} title="Vakantieperiode(s) bewerken">
                <EditGroupDateBlockerForm
                    type="modal"
                    onSubmitSuccess={() => {
                        closeModal()
                        refetchGroup()
                    }}
                    onFormCancel={closeModal}
                    group={group}
                />
            </CenterModal>
        )
    }
}
