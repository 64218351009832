import './ExistingFile.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import { ModalManager } from '~/components/ModalManager'
import { PdfModal } from '~/components'
import downloadFile from '~/utils/downloadFile'
import { ExistingFileType } from '~/types/File'
import ToolTip from '~/components/ToolTip'

interface Props {
    file: ExistingFileType
    onRemove: () => void
    name: string
}

export class ExistingFile extends React.Component<Props> {
    private bem = new BEM('ExistingFile')

    public render() {
        const { file, name, onRemove } = this.props

        return (
            <div className={this.bem.getClassName()}>
                {this.renderItemContent(file)}
                <input type={`hidden`} name={name} value={file._id} />
                <Button
                    className={this.bem.getElement('remove-button')}
                    onClick={onRemove}
                    leftIcon={<Icon name={`trash`} />}
                />
            </div>
        )
    }

    private renderItemContent = (file: ExistingFileType) => {
        if (file.isPDF) {
            return (
                <ModalManager
                    render={openModal => (
                        <Button className={`tt-ExistingFile__item`} onClick={openModal}>
                            <ToolTip text={file.fileName} enableDelay>
                                {file.fileName}
                            </ToolTip>
                        </Button>
                    )}
                    getModal={closeModal => (
                        <PdfModal
                            title={file.fileName}
                            fileName={file.fileName}
                            getFileId={() => file._id}
                            onClose={closeModal}
                        />
                    )}
                />
            )
        }

        if (!file.isPDF) {
            return (
                <Button className={`tt-ExistingFile__item`} onClick={() => downloadFile(file._id, file.fileName)}>
                    <ToolTip text={file.fileName} enableDelay>
                        {file.fileName}
                    </ToolTip>
                </Button>
            )
        }

        return null
    }
}
