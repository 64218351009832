import './InflowMomentPreviousInviteLabel.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { InviteTextVariableLabel } from '../InviteText/InviteTextVariableLabel/InviteTextVariableLabel'
import { ReadableDate, Subtle } from '..'

interface Props {
    className?: ClassValue
    invitedBy?: string
    templateName?: string
    invitedAt: Date
    forEmail?: boolean
}

interface State {}

export class InflowMomentPreviousInviteLabel extends React.PureComponent<Props, State> {
    private bem = new BEM('inflowMomentPreviousInviteLabel')

    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { invitedAt, className, templateName, invitedBy, forEmail } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <InviteTextVariableLabel className={this.bem.getElement('label')}>
                    {templateName || `Sjabloon onbekend`}
                </InviteTextVariableLabel>
                <Subtle className={this.bem.getElement('subtle')}>
                    {forEmail ? 'Verstuurd' : 'Gedownload'} op <ReadableDate date={invitedAt} format={'DD-MM-YYYY'} />{' '}
                    om <ReadableDate date={invitedAt} showTime={true} format={'HH:mm'} />
                    {invitedBy && <> door {invitedBy}</>}
                </Subtle>
            </div>
        )
    }
}
