import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, CenterModal, Field, FieldCollection } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'

export default class OrganizationContactAgreementModal extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        onAgree: PropTypes.func,
    }

    render() {
        const { onAgree, onClose } = this.props

        return (
            <CenterModal
                onClose={onClose}
                title={`Welkom op het organisatieportaal van TopTaal!`}
                forIframe
                maskBackground
            >
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>
                            <iframe
                                src="/static/files/Algemene-voorwaarden-MijnTopTaal-voor-organisaties.pdf"
                                style={{
                                    width: '100%',
                                    minHeight: 500,
                                    border: '5px solid #eee',
                                    overflowY: 'scroll',
                                }}
                            />
                        </Field>
                    </FieldGroup>
                    {onAgree && (
                        <FieldCollectionFooter>
                            <List horizontal>
                                <ListItem right>
                                    <Button type={`submit`} onClick={onAgree}>
                                        Akkoord met de voorwaarden
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    )}
                </FieldCollection>
            </CenterModal>
        )
    }
}
