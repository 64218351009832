import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { ResultCell } from '~/components/tables/ProgressTable/ResultCell'
import { ExamRatingType, ExamType, ResultResult, Ability, ResultFile } from '~/types/Exam'
import { ExamLevel } from '~/types/ExamLevel'
import { LearnerLevel } from '~/types/LearnerLevel'
import { ExamDownloader } from '~/components/ExamDownloader'
import { ModalManager } from '~/components/ModalManager'
import { Button } from '~/components/buttons/Button/Button'
import CenterModal from '~/components/CenterModal'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import translateType from '~/shared/utils/translateType'
import { isBoolean, isNumber } from 'lodash'
import { getPassedTypeForScore, AbilityExamPassedType } from '~/utils/abilityExams'

interface Props {
    className?: string
    examAbility: Ability
    examType: ExamType
    examLevel?: ExamLevel
    files: ResultFile[]
    result: ResultResult
}

interface ResultToRender {
    main: any
    subtitle?: string
}

export class AbilityExamResultCell extends React.Component<Props, {}> {
    private bem = new BEM('AbilityExamResultCell')

    public render() {
        const { className } = this.props
        const { main, subtitle } = this.getResultToRender()

        return (
            <ResultCell
                title={this.renderMain(main)}
                subtitle={subtitle}
                className={this.bem.getClassName(className)}
            />
        )
    }

    /**
     * Render the main content
     */
    private renderMain(main: any): any {
        const { files } = this.props

        if (files.length > 1) {
            return (
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} linkStyle={`default`}>
                            {main}
                        </Button>
                    )}
                    getModal={closeModal => (
                        <CenterModal small title={'Examenresultaten'} onClose={closeModal}>
                            <FieldCollection style={`modal`}>
                                <FieldGroup>
                                    {files.map((file, i) => (
                                        <Field key={i}>{this.renderExamDownloader(file.fileName, file)}</Field>
                                    ))}
                                </FieldGroup>
                            </FieldCollection>
                        </CenterModal>
                    )}
                />
            )
        } else if (files.length > 0) {
            const [firstFile] = files
            return this.renderExamDownloader(main, firstFile)
        }

        return main
    }

    /**
     * Render the exam downloader
     */
    private renderExamDownloader(children: any, file: ResultFile): any {
        return (
            <ExamDownloader fileId={file._id} fileName={file.fileName} mimeType={file.mimeType}>
                {children}
            </ExamDownloader>
        )
    }

    /**
     * Determine type of rating,
     * call specific render function
     */
    private getResultToRender(): ResultToRender {
        const { examAbility } = this.props

        switch (examAbility.typeOfRating) {
            case ExamRatingType.Level:
                return this.renderLevelResult()
            case ExamRatingType.PassedNotPassed:
                return this.renderPassedNotPassedResult()
            case ExamRatingType.Score:
                return this.renderScoreResult()
            default:
                return { main: '' }
        }
    }

    /**
     * When type of rating = Level
     */
    private renderLevelResult(): ResultToRender {
        const { result } = this.props

        return {
            main: this.renderLevelText(result.level || null),
        }
    }

    /**
     * When type of rating = PassedNotPassed
     */
    private renderPassedNotPassedResult(): ResultToRender {
        const { result } = this.props

        return {
            main:
                isBoolean(result.passedNotPassed) &&
                this.renderPassedNotPassedText(
                    result.passedNotPassed ? AbilityExamPassedType.Passed : AbilityExamPassedType.NotPassed
                ),
        }
    }

    /**
     * When type of rating = Score
     */
    private renderScoreResult(): ResultToRender {
        const { examAbility, examType, result } = this.props

        const scoreText = examAbility.maximumScore ? `${result.score}/${examAbility.maximumScore}` : `${result.score}`

        const passedType = this.getPassedTypeForScore(examAbility, result)

        switch (examType) {
            case ExamType.Alpha:
                return this.renderScoreAlphaResult(scoreText, passedType)
            case ExamType.Language:
                return this.renderScoreLanguageResult(scoreText, passedType)
            default:
                return { main: '' }
        }
    }

    /**
     * When type of rating = Score
     * and exam type = Alpha
     */
    private renderScoreAlphaResult(scoreText: string, passedType?: AbilityExamPassedType): ResultToRender {
        return {
            main: scoreText,
            subtitle: passedType ? this.renderPassedNotPassedText(passedType) : '',
        }
    }

    /**
     * When type of rating = Score
     * and exam type = Language
     */
    private renderScoreLanguageResult(scoreText: string, passedType?: AbilityExamPassedType): ResultToRender {
        const { examLevel } = this.props

        let resultText = '?'

        if (passedType) {
            if (passedType === AbilityExamPassedType.NotPassed) {
                resultText = this.renderPassedNotPassedText(AbilityExamPassedType.NotPassed)
            } else {
                resultText = this.renderLevelText(this.renderResultLevelForScoreBasedExam(examLevel!, passedType))
            }
        }

        return {
            main: resultText,
            subtitle: scoreText,
        }
    }

    /**
     * Render text for passed not passed
     */
    private renderPassedNotPassedText(passedType: AbilityExamPassedType): string {
        if (passedType === AbilityExamPassedType.Passed) {
            return 'Vol.'
        }

        return 'Onv.'
    }

    /**
     * Render text for level
     */
    private renderLevelText(level: LearnerLevel | null): string {
        return level && translateType('level', level)
    }

    /**
     * Get the passed-type for score-type ability-exam result
     */
    private getPassedTypeForScore(ability: Ability, result: ResultResult): AbilityExamPassedType | undefined {
        if (!isNumber(ability.minimumScoreToPass) || !isNumber(result.score)) {
            return undefined
        }

        return getPassedTypeForScore(ability.minimumScoreToPass, result.score)
    }

    /**
     * Render the result level for score-exams
     * When passed: result.level = exam.level
     * When not passed: result.level = minus-variant of result.level
     */
    private renderResultLevelForScoreBasedExam(
        examLevel: ExamLevel,
        passedType: AbilityExamPassedType
    ): LearnerLevel | null {
        // If nearly passed, return exam level minus variant (C1 -> C1-)
        if (passedType === AbilityExamPassedType.NearlyPassed) {
            switch (examLevel) {
                case ExamLevel.A1:
                    return LearnerLevel.A1Minus
                case ExamLevel.A2:
                    return LearnerLevel.A2Minus
                case ExamLevel.B1:
                    return LearnerLevel.B1Minus
                case ExamLevel.B2:
                    return LearnerLevel.B2Minus
                case ExamLevel.C1:
                    return LearnerLevel.C1Minus
                case ExamLevel._1F:
                    return LearnerLevel._1FMinus
                case ExamLevel._2F:
                    return LearnerLevel._2FMinus
                case ExamLevel._3F:
                    return LearnerLevel._3FMinus
                case ExamLevel._4F:
                    return LearnerLevel._4FMinus

                default:
                    return null
            }
        }

        // If passed, return exam level (C1 -> C1)
        return LearnerLevel[examLevel]
    }
}
