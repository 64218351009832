import './AttendanceRotatedTableHeaderItem.scss'

import * as React from 'react'
import { BEM } from '~/services/BEMService'
import {
    RotatedTableHeaderItem,
    Props as RotatedTableHeaderItemProps,
} from '~/components/Table/RotatedTableHeaderItem/RotatedTableHeaderItem'

interface Props extends RotatedTableHeaderItemProps {
    className?: string
}

export class AttendanceRotatedTableHeaderItem extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceRotatedTableHeaderItem')

    public render() {
        const { children, className, ...restProps } = this.props

        return (
            <RotatedTableHeaderItem className={this.bem.getClassName(className)} {...restProps}>
                {children}
            </RotatedTableHeaderItem>
        )
    }
}
