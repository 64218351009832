import { gql } from '@apollo/client'
import { get } from 'lodash'
import React from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { RouteComponentProps } from 'react-router'
import { AddressFieldsFragment } from '~/types/Address'

const GET_LOCATION_QUERY = gql`
    query _($_id: MongoID, $roomId: MongoID) {
        locations(byId: $_id) {
            _id
            name
            address {
                ...AddressFieldsFragment
            }
            organizationName
            contactPersonName
            rentalDateFrom
            rentalDateTo
            rooms(byId: $roomId) {
                _id
                name
                capacity
                locationProperties {
                    _id
                    name
                }
            }
        }
    }
    ${AddressFieldsFragment}
`

interface RouteParams {
    id: string
    roomId: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export default class DetailView extends React.Component<Props> {
    private locationFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.locationFetcher = new Fetcher({
            query: GET_LOCATION_QUERY,
            variables: {
                _id: props.params.id,
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate({ params: prevParams }: Props) {
        const { params } = this.props
        const locationHasChanged = prevParams.id !== params.id
        const roomHasChanged = prevParams.roomId !== params.roomId

        if (locationHasChanged || roomHasChanged) {
            this.locationFetcher.refetch({
                _id: params.id,
                roomId: params.roomId,
            } as any)
        }
    }

    public render() {
        const { children, ...routeComponentProps } = this.props
        const { data, loading, refetch } = this.locationFetcher
        const locationObject = get(data, 'locations[0]')

        return (
            <RouteView crumbLabel={get(locationObject, 'name')} routeProps={routeComponentProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {this.isRoomDetailActive() ? this.renderRoomDetailPageTabs() : this.renderDefaultPageTabs()}
                </Header>
                <View>{locationObject && React.cloneElement(children, { locationObject, refetch })}</View>
            </RouteView>
        )
    }

    private isRoomDetailActive = () => {
        return !!this.props.params.roomId
    }

    private renderRoomDetailPageTabs = () => {
        return (
            <PageTabs>
                <PageTab>
                    <Link
                        onlyActiveOnIndex={true}
                        route={`/properties/locations/${this.props.params.id}/rooms/${this.props.params.roomId}/availability`}
                    >
                        Beschikbaarheid
                    </Link>
                </PageTab>
                <PageTab>
                    <Link
                        onlyActiveOnIndex={true}
                        route={`/properties/locations/${this.props.params.id}/rooms/${this.props.params.roomId}`}
                    >
                        Eigenschappen
                    </Link>
                </PageTab>
            </PageTabs>
        )
    }

    private renderDefaultPageTabs = () => {
        return (
            <PageTabs>
                <PageTab>
                    <Link onlyActiveOnIndex={true} route={`/properties/locations/${this.props.params.id}/rooms`}>
                        Lesruimtes
                    </Link>
                </PageTab>
                <PageTab>
                    <Link onlyActiveOnIndex={true} route={`/properties/locations/${this.props.params.id}`}>
                        Gegevens
                    </Link>
                </PageTab>
            </PageTabs>
        )
    }
}
