import { gql } from '@apollo/client'
import * as React from 'react'

import { CenterModal } from '~/components'
import { UploadSignatureForm } from '~/forms/UploadSignatureForm'
import { toast, Fetcher } from '~/utils'
import { Invoice } from '~/types/Invoice'

interface Props {
    noConfirm?: boolean
    render: (uploadSignature: (invoiceId: string) => void, isUploadingSignature?: boolean) => React.ReactNode | null
}

interface UploadSignatureResponse {
    isCanceled?: boolean
}

interface ModalConfig {
    forInvoice: Invoice
    callback: (response: UploadSignatureResponse) => void
}

interface State {
    isUploadingSignature: boolean
    activeUploadSignatureModal?: ModalConfig
}

const INVOICE_QUERY = gql`
    query _($filters: InvoicesFilterInputType!) {
        invoices(filters: $filters) {
            _id
            invoiceNumber
        }
    }
`

export class UploadInvoiceSignatureProvider extends React.Component<Props, State> {
    public state: State = {
        isUploadingSignature: false,
        activeUploadSignatureModal: undefined,
    }

    private invoicesFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.invoicesFetcher = new Fetcher({
            query: INVOICE_QUERY,
            preventInitialFetch: true,
        })
    }

    public render() {
        const { render } = this.props
        const { isUploadingSignature } = this.state

        return (
            <>
                {render(this.uploadSignatureByInvoiceId, isUploadingSignature)}
                {this.renderUploadSignatureModal()}
            </>
        )
    }

    private closeUploadSignatureModal = (response: UploadSignatureResponse) => {
        const { activeUploadSignatureModal } = this.state

        if (activeUploadSignatureModal) {
            activeUploadSignatureModal.callback(response)

            this.setState({
                activeUploadSignatureModal: undefined,
            })
        }
    }

    private renderUploadSignatureModal(): React.ReactNode | void {
        const { activeUploadSignatureModal } = this.state
        const { noConfirm } = this.props

        if (activeUploadSignatureModal) {
            const { forInvoice } = activeUploadSignatureModal

            return (
                <CenterModal
                    onClose={() => this.closeUploadSignatureModal({ isCanceled: true })}
                    title={`Handtekening uploaden voor ${forInvoice.invoiceNumber}`}
                >
                    <UploadSignatureForm
                        noConfirm={!!noConfirm}
                        invoice={forInvoice}
                        onSubmitSuccess={() => this.closeUploadSignatureModal({ isCanceled: false })}
                        onCancel={() => this.closeUploadSignatureModal({ isCanceled: true })}
                    />
                </CenterModal>
            )
        }
    }

    private uploadSignatureByInvoiceId = async (invoiceId: string) => {
        this.setState({ isUploadingSignature: true })

        const data = await this.invoicesFetcher.fetch({
            filters: {
                byIds: [invoiceId],
            },
        })

        const [invoice = undefined] = data.invoices || []

        if (!invoice) {
            toast.error('Kan factuur niet verzenden.')
            // tslint:disable-next-line:no-console
            console.error(`Could not request invoice by id "${invoiceId}"`)
            this.setState({ isUploadingSignature: false })
            return
        }

        await this.uploadSignature(invoice)

        this.setState({ isUploadingSignature: false })
    }

    private async uploadSignature(invoice: Invoice): Promise<UploadSignatureResponse> {
        return new Promise(resolve => {
            this.openUpdateEmailForInvoiceModal(invoice, resolve)
        })
    }

    private openUpdateEmailForInvoiceModal(invoice: Invoice, callback: (response: UploadSignatureResponse) => void) {
        this.setState({
            activeUploadSignatureModal: {
                forInvoice: invoice,
                callback: callback,
            },
        })
    }
}
