import * as React from 'react'
import { Spinner } from '~/components'
import { addScrollListener, removeScrollListener } from '~/utils/scrollListener'
import { Paginator } from '~/utils/Paginator'

interface Props {
    paginator: Paginator
    scrollElement: HTMLElement | Window
    isLoadingMore?: boolean
    preventLoad?: boolean
}

export class InfiniteScrollContent extends React.Component<Props> {
    public componentDidMount() {
        addScrollListener('close-to-bottom', this.loadMore, this.props.scrollElement)
    }

    public componentWillUnmount() {
        removeScrollListener('close-to-bottom', this.loadMore, this.props.scrollElement)
    }

    public render() {
        const { children, isLoadingMore } = this.props

        return (
            <>
                {children}
                {isLoadingMore && <Spinner className={'tt-Spinner--infinite-scroll-spinner'} />}
            </>
        )
    }

    private loadMore = () => {
        const { paginator, isLoadingMore, preventLoad } = this.props

        if (isLoadingMore || preventLoad) {
            return
        }

        paginator.loadMore()
    }
}
