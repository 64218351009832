import * as React from 'react'

import { ListItem } from '~/components/ListItem'

interface Props {}

export class AnchorIndexListItem extends React.Component<Props> {
    public render() {
        const { children } = this.props

        return <ListItem className={`tt-AnchorIndexListItem`}>{children}</ListItem>
    }
}
