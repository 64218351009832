import c from 'classnames'
import { gql } from '@apollo/client'
import { bindAll, compact, get, times } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CheckBox,
    Field,
    FilterFieldCollection,
    Icon,
    Link,
    MultiInput,
    Search,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { TableCell } from '~/components/TableCell'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import GroupLearnersSelectionTable, {
    GroupLearnersSelectionTableRow,
} from '~/implementations/GroupLearnersSelectionTable'
import { Fetcher, Filter, removeDuplicateDocuments } from '~/utils'
import translateType from '~/shared/utils/translateType'

import ConceptGroupUsersForm from './ConceptGroupUsersForm'
import { Paginator } from '~/utils/Paginator'

const START = 40
const INCREASE = 40
const STATIC_LEARNER_USER_FILTERS = {
    roles: ['LEARNER'],
    filterLearnerActive: true,
}

export default class ConceptGroupLearnersForm extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        onCancel: PropTypes.func,
    }

    state = {
        limit: START,
        skip: 0,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onFilter', 'onFilterTagPicker', 'renderUsersTable', 'renderActionBar', 'renderActionBarDrawer'])

        const { limit, skip } = this.state

        this.filter = new Filter({
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterByAdviceModuleIds',
                'filterByPreferredLocationIds',
                'filterLearnerStatuses',
                'filterByLearnabiltyMin',
                'filterByLearnabiltyMax',
                'filterByLearnabiltyMax',
                'filterByOrganizationIds',
                'filterByGroupIds',
            ],
            onChange: filters => {
                this.learnerUsersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_LEARNER_USER_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsRegistered', 'filterIsUnassigned', 'filterIsAssigned'],
                    transform: filters => {
                        const statuses = []

                        if (filters.filterIsRegistered) statuses.push('REGISTERED')
                        if (filters.filterIsUnassigned) statuses.push('UNASSIGNED')
                        if (filters.filterIsAssigned) statuses.push('ASSIGNED')

                        return {
                            filterLearnerStatuses: statuses.length > 0 && statuses.length < 3 ? statuses : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerStatuses'],
                    parseFromHistory: historyFilters => {
                        const statuses = historyFilters.filterLearnerStatuses || []

                        return {
                            filterIsRegistered: statuses.indexOf('REGISTERED'),
                            filterIsUnassigned: statuses.indexOf('UNASSIGNED'),
                            filterIsAssigned: statuses.indexOf('ASSIGNED'),
                        }
                    },
                },
            ],
        })

        this.learnerUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.learnerUsersFetcher = new Fetcher({
            query: GET_LEARNER_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_LEARNER_USER_FILTERS,
                    ...this.filter.getFilters(),
                },
                limit,
                skip,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.learnerUsersPaginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    loadMore = (skip, limit, callback) => {
        this.learnerUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    render() {
        const { group, onSuccess, onCancel } = this.props

        return (
            <ConceptGroupUsersForm
                group={group}
                role="LEARNER"
                usersFetcher={this.learnerUsersFetcher}
                usersPaginator={this.learnerUsersPaginator}
                title={'Kandidaten toevoegen'}
                renderUsersTable={this.renderUsersTable}
                renderActionBar={this.renderActionBar}
                onSuccess={onSuccess}
                onCancel={onCancel}
            />
        )
    }

    renderUsersTable(users, addUser, removeUser, areUsersAdded) {
        const { loading } = this.learnerUsersFetcher
        const showSpinner = !areUsersAdded && loading

        return (
            <GroupLearnersSelectionTable>
                <TableHeader>
                    <TableHeaderItem forIconButton></TableHeaderItem>
                    <TableHeaderItem>Naam</TableHeaderItem>
                    <TableHeaderItem>Woonplaats</TableHeaderItem>
                    <TableHeaderItem>Advies lesmodule</TableHeaderItem>
                    <TableHeaderItem>Leerbaarheid</TableHeaderItem>
                    <TableHeaderItem>Voorkeurslocatie</TableHeaderItem>
                    <TableHeaderItem className="tt-GroupLearnersSelectionTableRow__levels">
                        <span>Gespr.</span>
                        <span>Spr.</span>
                        <span>Schr.</span>
                        <span>Lui.</span>
                        <span>Lez.</span>
                    </TableHeaderItem>
                    <TableHeaderItem></TableHeaderItem>
                </TableHeader>

                {showSpinner && this.renderLoadingRow()}

                {!showSpinner &&
                    (users.length > 0
                        ? this.renderLearnerRows(users, addUser, removeUser, areUsersAdded)
                        : this.renderEmpyRow(areUsersAdded))}
            </GroupLearnersSelectionTable>
        )
    }

    renderEmpyRow(areUsersAdded) {
        const placeholder = areUsersAdded
            ? 'Voeg kandidaten toe middels de onderstaande lijst.'
            : 'Er zijn geen kandidaten gevonden.'

        return (
            <GroupLearnersSelectionTableRow key={`emptyresult`}>
                <TableCell colSpan={8}>
                    <Subtle>{placeholder}</Subtle>
                </TableCell>
            </GroupLearnersSelectionTableRow>
        )
    }

    renderLoadingRow() {
        return (
            <GroupLearnersSelectionTableRow key={`loading`}>
                <TableCell colSpan={8}>
                    <Spinner instant />
                </TableCell>
            </GroupLearnersSelectionTableRow>
        )
    }

    renderActionBar() {
        const { loading: loadingUsers } = this.learnerUsersFetcher

        return (
            <ActionBar
                getButtons={(toggleDrawer, drawerActive) => {
                    return (
                        <List horizontal>
                            <ListItem>
                                <Button
                                    leftIcon={<Icon name={`filter`} />}
                                    onClick={() => toggleDrawer()}
                                    isActive={drawerActive}
                                >
                                    Filteropties
                                </Button>
                            </ListItem>
                            <ListItem>
                                <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                            </ListItem>
                        </List>
                    )
                }}
                getDrawer={this.renderActionBarDrawer}
            />
        )
    }

    renderActionBarDrawer() {
        const { data: filterParams } = this.filterSuggestionsFetcher
        const {
            addresses_usedCities = [],
            modules = [],
            locations = [],
            organizations = [],
            groups = [],
        } = filterParams

        const addressOptions = addresses_usedCities.map(city => ({ value: city, label: city }))
        const moduleOptions = modules.map(({ name, _id }) => ({ value: _id, label: name }))
        const locationOptions = locations.map(({ name, _id }) => ({ value: _id, label: name }))
        const scoreOptions = times(26, n => ({ value: n, label: n }))
        const organizationOptions = organizations.map(organization => ({
            value: organization._id,
            label: organization.name,
        }))
        const groupOptions = groups.map(group => ({ value: group._id, label: group.name }))

        const filters = this.filter.getFilters()

        return (
            <FilterFieldCollection>
                <Field title="Woonplaats" style="compact">
                    <TagPicker onChange={this.onFilterTagPicker} name="filterByCityNames" options={addressOptions} />
                </Field>
                <Field title="Advies lesmodule" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByAdviceModuleIds"
                        options={moduleOptions}
                    />
                </Field>
                <Field title="Voorkeurslocatie" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByPreferredLocationIds"
                        options={locationOptions}
                    />
                </Field>
                <Field title="Organisatie" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByOrganizationIds"
                        defaultValue={filters.filterByOrganizationIds}
                        options={organizationOptions}
                        placeholder="Selecteer organisatie"
                    />
                </Field>
                <Field title="Groepen" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByGroupIds"
                        defaultValue={filters.filterByGroupIds}
                        options={groupOptions}
                        placeholder="Selecteer groep"
                    />
                </Field>
                <Field title="Leerbaarheid" style="compact">
                    <MultiInput type="select-range">
                        <TagPicker
                            multi={false}
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnabiltyMin"
                            options={scoreOptions}
                        />
                        <label>tot</label>
                        <TagPicker
                            multi={false}
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnabiltyMax"
                            options={scoreOptions}
                        />
                    </MultiInput>
                </Field>
                <Field title="Status" style="compact">
                    <MultiInput type="checkbox">
                        <CheckBox name="filterIsAssigned" onChange={this.onFilter}>
                            Ingedeeld
                        </CheckBox>
                        <CheckBox name="filterIsUnassigned" onChange={this.onFilter}>
                            Niet ingedeeld
                        </CheckBox>
                        <CheckBox name="filterIsRegistered" onChange={this.onFilter}>
                            Aangemeld
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    renderLearnerRows(learners, addUser, removeUser, areUsersAdded) {
        return learners.map(learnerUser => {
            const isUserAdded = areUsersAdded

            const canExpandRow = get(learnerUser, 'profile.notes') || get(learnerUser, 'learner.adviceModuleInfo')

            const preferredLocations = get(learnerUser, 'learner.preferredLocations')

            const levels = [
                get(learnerUser, 'learner.latestAbilityLevels.converse'),
                get(learnerUser, 'learner.latestAbilityLevels.talk'),
                get(learnerUser, 'learner.latestAbilityLevels.write'),
                get(learnerUser, 'learner.latestAbilityLevels.listen'),
                get(learnerUser, 'learner.latestAbilityLevels.read'),
            ].map(l => l.level)

            return (
                <GroupLearnersSelectionTableRow
                    key={learnerUser._id}
                    hasExpandableInfo={!!canExpandRow}
                    getExpansion={() => (
                        <TableCell colSpan={8}>
                            <SimpleTable>
                                <SimpleTableRow>
                                    <SimpleTableCell isBold>Notities:</SimpleTableCell>
                                    <SimpleTableCell>
                                        {get(learnerUser, 'profile.notes') || 'Geen notities'}
                                    </SimpleTableCell>
                                </SimpleTableRow>
                                <SimpleTableRow>
                                    <SimpleTableCell isBold>Toelichting advies-lesmodule:</SimpleTableCell>
                                    <SimpleTableCell>
                                        {get(learnerUser, 'learner.adviceModuleInfo') || 'Geen toelichting'}
                                    </SimpleTableCell>
                                </SimpleTableRow>
                                <SimpleTableRow>
                                    <SimpleTableCell isBold>Engels als steuntaal:</SimpleTableCell>
                                    <SimpleTableCell>
                                        {get(learnerUser, 'learner.englishAsSupportLanguage')}
                                    </SimpleTableCell>
                                </SimpleTableRow>
                            </SimpleTable>
                        </TableCell>
                    )}
                >
                    <TableCell forIconButton>
                        {isUserAdded && this.renderRemoveButton(learnerUser, removeUser)}
                        {!isUserAdded && this.renderAddButton(learnerUser, addUser)}
                    </TableCell>
                    <TableCell style={{ maxWidth: '30ch' }}>
                        <Link route={`/users/learners/${learnerUser._id}`} target="_blank" type="emphasized">
                            {learnerUser.profile.name}
                        </Link>
                    </TableCell>
                    <TableCell>{get(learnerUser, 'profile.address.nl.extraInfo.city')}</TableCell>
                    <TableCell style={{ maxWidth: '30ch' }}>{get(learnerUser, 'learner.adviceModule.name')}</TableCell>
                    <TableCell>{get(learnerUser, 'learner.intakeGradesLearnability')}</TableCell>
                    <TableCell style={{ maxWidth: '30ch' }}>
                        {compact(preferredLocations || [])
                            .map(pl => get(pl, 'name'))
                            .join(', ')}
                    </TableCell>
                    <TableCell className="tt-GroupLearnersSelectionTableRow__levels">
                        {compact(levels).length > 0 &&
                            levels.map((level, i) => (
                                <span
                                    key={i}
                                    className={c({
                                        'tt-GroupLearnersSelectionTableRow__levels--is-empty': !level,
                                    })}
                                >
                                    {translateType('level', level) || '--'}
                                </span>
                            ))}
                    </TableCell>
                </GroupLearnersSelectionTableRow>
            )
        })
    }

    renderRemoveButton(user, removeUser) {
        return (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={() => removeUser(user)}
                leftIcon={<Icon name={`trash`} />}
            />
        )
    }

    renderAddButton(user, addUser) {
        const renderButton = openModal => (
            <Button
                className={`tt-TableRow__button__icon`}
                type={`in-row`}
                onClick={openModal}
                leftIcon={<Icon name={`plus`} />}
            />
        )

        return renderButton(() => addUser(user))
    }
}

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities

        modules(sortBy: "name") {
            _id
            name
        }

        locations(sortBy: "name") {
            _id
            name
        }

        organizations(sortBy: "name") {
            _id
            name
        }

        groups(sortBy: "name") {
            _id
            name
        }
    }
`

const GET_LEARNER_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(limit: $limit, skip: $skip, sortBy: "profile.firstName", filters: $filters) {
            _id
            profile {
                name
                notes
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            learner {
                intakeGradesLearnability
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                latestAbilityLevels {
                    converse {
                        ...abilityLevelFields
                    }
                    talk {
                        ...abilityLevelFields
                    }
                    write {
                        ...abilityLevelFields
                    }
                    listen {
                        ...abilityLevelFields
                    }
                    read {
                        ...abilityLevelFields
                    }
                }
                englishAsSupportLanguage
                adviceModule {
                    _id
                    name
                }
                adviceModuleInfo
                preferredLocations {
                    _id
                    name
                }
            }
        }
    }

    fragment abilityLevelFields on AbilityLevelType {
        level
        accomplishedAt
    }
`
