import { Program } from '~/types/Program'

export enum ProjectInvoiceGranularity {
    Group = 'Group',
    Learner = 'Learner',
}

export enum ProjectPriceType {
    Fixed = 'Fixed',
    Variable = 'Variable',
}

export enum ProjectVariablePriceVariableBy {
    Hour = 'Hour',
    Learner = 'Learner',
}

export enum ProjectInvoiceTiming {
    Periodical = 'Periodical',
    Variable = 'Variable',
}

export enum ProjectPeriodicalTimingPeriod {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
}

export enum ProjectChargeForLessonAttendanceType {
    Any = 'Any',
    Present = 'Present',
    PresentAndAbsent = 'PresentAndAbsent',
}

export enum ProjectChargeForLessonsType {
    OnlyEnrolledLessons = 'OnlyEnrolledLessons',
    AllLessons = 'AllLessons',
}

export interface ProjectAmountRange {
    to: number
    amount?: number
}

export interface ProjectFixedPriceSettings {
    amount?: number
}

export interface ProjectVariablePriceSettings {
    variableBy?: ProjectVariablePriceVariableBy
    amountRanges?: ProjectAmountRange[]
}

export interface ProjectPeriodicalTimingSettings {
    period: ProjectPeriodicalTimingPeriod
    chargeForLessonAttendanceType: ProjectChargeForLessonAttendanceType
    chargeForLessonReasonOfAbsences: ProjectChargeForLessonReasonOfAbsence[]
}

// this is an extension of enum Lesson.LessonReasonOfAbsence ('None' is added) (keep in sync!)
export enum ProjectChargeForLessonReasonOfAbsence {
    Other_Integrtion_Activity = 'OTHER_INTEGRATION_ACTIVITY',
    Exam = 'EXAM',
    Private = 'PRIVATE_REASONS',
    Transport_Issues = 'TRANSPORT_ISSUES',
    Vacation = 'VACATION',
    Work_Study = 'WORK_STUDY',
    Sick = 'SICK',
    Other = 'OTHER',
    None = 'NONE',
}

export interface ProjectVariableTimingSettings {
    chargeForLessonsType: ProjectChargeForLessonsType
    periods: ProjectVariableTimingSettingsPeriod[]
}

export interface ProjectInvoicingSetting {
    _id: string
    name?: string
    programs?: Program[]
    invoiceGranularity?: ProjectInvoiceGranularity
    priceType?: ProjectPriceType
    invoiceTiming?: ProjectInvoiceTiming

    fixedPriceSettings?: ProjectFixedPriceSettings
    variablePriceSettings?: ProjectVariablePriceSettings

    periodicalTimingSettings?: ProjectPeriodicalTimingSettings
    variableTimingSettings?: ProjectVariableTimingSettings
}

export interface ProjectVariableTimingSettingsPeriod {
    percentageOfPassedLessons: number
    percentageOfPrice: number
    conditionSettings: ProjectPeriodConditionSetting[]
}

export interface ProjectPeriodConditionSetting {
    condition: ProjectPeriodCondition
    isChecked: boolean
    description: string
}

export enum ProjectPeriodCondition {
    // Eindbevestiging ontvangen van gemeente
    ReceivedConfirmationOfMunicipality = 'ReceivedConfirmationOfMunicipality',

    // Percentage presentatie
    AttendancePercentage = 'AttendancePercentage',

    // Deelname examen
    ParticipationInExam = 'ParticipationInExam',

    // Examen behaald
    PassedExam = 'PassedExam',

    // Moment van uitschrijven
    DateOfDisenrollment = 'DateOfDisenrollment',

    // Anders, namelijk...
    Other = 'Other',
}
