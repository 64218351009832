import * as React from 'react'
import { gql } from '@apollo/client'

import { ContentView } from '~/components/ContentView'
import Fetcher from '~/utils/Fetcher'
import { UserLearnerProgressTable } from '~/components/users/UserLearnerProgressTable'
import { UserLearnerExamProgressTable } from '~/components/users/UserLearnerExamProgressTable'

const USER_QUERY = gql`
    query _($filters: UsersFilterInputType, $finalExamFilters: UserLearnerFinalExamsFilterInputType) {
        users(filters: $filters) {
            _id
            profile {
                name
            }
            results {
                _id
                plannedAbilityExam {
                    _id
                    group {
                        _id
                        name
                        module {
                            _id
                            name
                        }
                        lessons {
                            _id
                        }
                    }
                    lesson {
                        _id
                        date
                        order
                        plannedAbilityExams {
                            _id
                            examId
                        }
                    }
                }
                exam {
                    _id
                    type
                    name
                    level
                    converse {
                        ...learnerProgressViewExamAbilityFields
                    }
                    talk {
                        ...learnerProgressViewExamAbilityFields
                    }
                    write {
                        ...learnerProgressViewExamAbilityFields
                    }
                    listen {
                        ...learnerProgressViewExamAbilityFields
                    }
                    read {
                        ...learnerProgressViewExamAbilityFields
                    }
                }
                examAbility
                result {
                    passedNotPassed
                    score
                    level
                    status
                    absenceExplanation
                }
                files {
                    _id
                    fileName
                }
            }
            learner {
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                intakeGradesLearnability
                intakeDate
                examDignityDate
                finalExams(filters: $finalExamFilters, sortBy: "date", sortDir: "DESC") {
                    _id
                    date
                    part
                    level
                    attempt
                    result
                }
                inflowMoments {
                    _id
                    dateRange {
                        from
                        to
                    }
                }
                adviceReports {
                    _id
                    createdAt
                    inflowMoment {
                        _id
                        dateRange {
                            from
                            to
                        }
                    }
                    levelReading
                    levelWriting
                    levelListening
                    levelTalking
                    levelConversations
                    file {
                        fileId
                        fileName
                    }
                }
            }
        }
    }

    fragment learnerProgressViewExamAbilityFields on ExamAbilityType {
        typeOfRating
        minimumScoreToPass
        maximumScore
    }
`

interface Props {
    userId: string
}

export class AppExternalTeacherLearnerDetailProgress extends React.Component<Props> {
    private userFetcher = new Fetcher({
        query: USER_QUERY,
        variables: {
            filters: {
                byId: this.props.userId,
            },
        },

        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { data, loading, refetch } = this.userFetcher
        const user = data && data.users && data.users[0]

        return (
            <ContentView>
                <UserLearnerExamProgressTable isLoading={loading} user={user} />
                <UserLearnerProgressTable isLoading={loading} user={user} refetch={refetch} />
            </ContentView>
        )
    }
}
