import './ContractStatus.scss'

import * as React from 'react'
import { BEM, ClassValue } from '~/services/BEMService'
import { Contract } from '~/types/Contracts'
import { SignatureIcon, SentIcon } from '~/components/InvoiceStatusIndicator/icons'
import { Button } from '~/components/buttons/Button/Button'
import ToolTip from '~/components/ToolTip'
import Link from '~/components/Link'

interface Props {
    className?: ClassValue
    contract: Contract
    ensureShowIconOnly?: boolean
    iconLink?: string
}

interface StatusButtonProps {
    icon: React.ReactNode
    iconLabel: string
    label: string
    onClick?: () => void
    isLoading?: boolean
    isDisabled?: boolean
}

interface StatusIconProps {
    icon: React.ReactNode
    label: string
}

export class ContractStatus extends React.Component<Props> {
    private bem = new BEM('ContractStatus')

    public render() {
        const { className } = this.props

        return <div className={this.bem.getClassName(className)}>{this.renderStatus() || null}</div>
    }

    private renderStatus(): React.ReactNode | void {
        const { contract } = this.props

        const { StatusButton } = this

        if (!contract.isFinal) {
            return <StatusButton label="Definitief maken" iconLabel="Definitief maken" icon={<SentIcon />} />
        }

        if (!contract.isSigned) {
            return (
                <StatusButton
                    label="Handtekening uploaden"
                    iconLabel="Handtekening uploaden"
                    icon={<SignatureIcon />}
                />
            )
        }

        return <span>{contract._id}</span>
    }

    private StatusButton = (buttonProps: StatusButtonProps): JSX.Element => {
        const { StatusIcon } = this

        const { ensureShowIconOnly } = this.props

        const { icon, label, iconLabel = buttonProps.label, onClick, isLoading, isDisabled } = buttonProps

        if (ensureShowIconOnly) {
            return <StatusIcon icon={icon} label={iconLabel} />
        }

        return (
            <Button small={true} leftIcon={icon} onClick={onClick} isLoading={isLoading} isDisabled={isDisabled}>
                {label}
            </Button>
        )
    }

    private StatusIcon = (iconProps: StatusIconProps): JSX.Element => {
        const { icon, label: status } = iconProps

        const { contract, iconLink } = this.props

        const label = contract && contract.contractNumber ? `${contract.contractNumber}: ${status}` : status

        return <ToolTip text={label}>{iconLink ? <Link route={iconLink}>{icon}</Link> : icon}</ToolTip>
    }
}
