import { Group } from '~/types/Group'
import { Lesson } from '~/types/Lesson'
import { ExamLevel } from '~/types/ExamLevel'
import { LearnerLevel } from '~/types/LearnerLevel'

export enum ExamType {
    Language = 'language',
    Alpha = 'alpha',
}

export enum ExamRatingType {
    PassedNotPassed = 'passedNotPassed',
    Score = 'score',
    Level = 'level',
}

export interface ExamUploadedDocumentFile {
    _id: string
    fileName: string
}

export interface Ability {
    typeOfRating: ExamRatingType
    minimumScoreToPass?: number
    maximumScore?: number
    documentFiles?: ExamUploadedDocumentFile[]
}

export enum ExamAbility {
    Converse = 'converse',
    Read = 'read',
    Write = 'write',
    Listen = 'listen',
    Talk = 'talk',
}

export enum ResultStatus {
    NoShow = 'no_show',
    Exempt = 'exempt',
    Participated = 'participated',
}

export type ExamAbilityDictionary = {
    [Key in ExamAbility]?: boolean
}

export interface ExamAbilityResult {
    _id: string
    plannedAbilityExamId?: string
    plannedAbilityExam?: PlannedAbilityExam
    learnerUserId?: string
    examAbility?: ExamAbility
    examId?: string
    exam?: Exam
    result?: ResultResult
    files?: ResultFile[]
}

export interface ResultFile {
    _id: string
    fileName: string
    mimeType: string
}

export interface ResultResult {
    passedNotPassed?: boolean
    score?: number
    level?: LearnerLevel
    absenceExplanation: string
    status: ResultStatus
}

export interface Exam {
    _id: string
    name: string
    type: ExamType
    level?: ExamLevel
    converse: Ability
    talk: Ability
    write: Ability
    listen: Ability
    read: Ability
}

export interface PlannedAbilityExam {
    _id: string
    examId?: string
    exam?: Exam
    lesson?: Lesson
    group?: Group
    examAbility: ExamAbility
    results?: ExamAbilityResult[]
    resultsCompleted: boolean
}

export interface ResultInput {
    _id: string
    plannedAbilityExamId: string
    learnerUserId: string
    result: ResultResult
    documentFiles: ResultDocumentInput[]
}

export interface ResultDocumentInput {
    existingFileIds: string[]
    files: File[]
}
