import {
    ExamCreatedLog,
    InvoiceCreatedLog,
    InvoiceSentLog,
    InvoiceCreditedLog,
    DuoDeclarationCreatedLog,
    DuoDeclarationRevokedLog,
    DateChangedLog,
    ExamCancelledLog,
    DateCorrectedLog,
    ExamPartCorrectedLog,
    SubmitterChangedToExternalLog,
    SubmitterChangedToToptaalLog,
    CreatedByDUOFinalExamResultImport,
    ResultUpdatedFromDUOFinalExamResultImport,
    UncanceledByDUOFinalExamResultImport,
    CreatedAsExemptByDUOFinalExamResultImport,
} from '~/components/FinalExams/logs/FinalExamLogTypes'

export const FinalExamsLogTypesMap = {
    examCreated: ExamCreatedLog,
    invoiceCreated: InvoiceCreatedLog,
    invoiceSent: InvoiceSentLog,
    invoiceCredited: InvoiceCreditedLog,
    duoDeclarationCreated: DuoDeclarationCreatedLog,
    duoDeclarationRevoked: DuoDeclarationRevokedLog,
    dateChanged: DateChangedLog,
    examCancelled: ExamCancelledLog,
    dateCorrected: DateCorrectedLog,
    examPartCorrected: ExamPartCorrectedLog,
    submitterChangedToExternal: SubmitterChangedToExternalLog,
    submitterChangedToToptaal: SubmitterChangedToToptaalLog,
    createdByDUOFinalExamResultImport: CreatedByDUOFinalExamResultImport,
    resultUpdatedFromDUOFinalExamResultImport: ResultUpdatedFromDUOFinalExamResultImport,
    uncanceledByDUOFinalExamResultImport: UncanceledByDUOFinalExamResultImport,
    createdAsExemptByDUOFinalExamResultImport: CreatedAsExemptByDUOFinalExamResultImport,
}
