import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import { Mutator } from '~/utils'
import { Link, DrawerModal } from '~/components'
import { GroupFieldGroup } from '../GroupFieldGroup'
import { ConceptGroupTeachersForm } from '~/forms'

export default class GroupTeachersFieldGroup extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        refetchGroup: PropTypes.func.isRequired,
        validationErrors: PropTypes.array,
        removeUsersMutator: PropTypes.instanceOf(Mutator).isRequired,
    }

    static RELATED_ERROR_KEYS = ['user-teacher-role-mismatch', 'user-teacher-required', 'user-teacher-inactive']

    constructor(props) {
        super(props)
        bindAll(this, ['removeTeachers', 'getEditModal'])
    }

    async removeTeachers() {
        const { group, refetchGroup, removeUsersMutator } = this.props
        const { teacherUsers } = group

        const res = await removeUsersMutator.mutate({
            groupId: group._id,
            groupUsersToRemove: (teacherUsers || []).map(teacherUser => ({
                userId: teacherUser.user._id,
                role: 'TEACHER',
            })),
        })

        if (res) {
            refetchGroup()
        }
    }

    render() {
        const { validationErrors } = this.props

        return (
            <GroupFieldGroup
                title="Docent(en)"
                validationErrors={validationErrors}
                getEditModal={this.getEditModal}
                onRemove={this.removeTeachers}
                removeMessage={`Je staat op het punt om alle docenten uit deze groep te verwijderen. Weet je het zeker?`}
            >
                {this.renderTeachersList()}
            </GroupFieldGroup>
        )
    }

    renderTeachersList() {
        const { group } = this.props
        const { teacherUsers } = group

        return (
            <ul>
                {teacherUsers.map(teacherUser => (
                    <li key={teacherUser.user._id}>
                        <Link route={`/users/teachers/` + teacherUser.user._id}>{teacherUser.user.profile.name}</Link>
                    </li>
                ))}
            </ul>
        )
    }

    getEditModal(closeModal) {
        const { group, refetchGroup } = this.props

        return (
            <DrawerModal onClose={closeModal}>
                <ConceptGroupTeachersForm
                    onSuccess={() => {
                        closeModal()
                        refetchGroup()
                    }}
                    onCancel={closeModal}
                    group={group}
                />
            </DrawerModal>
        )
    }
}
