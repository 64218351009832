import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import * as sanitizeHtml from 'sanitize-html'

import { nl2br } from '~/utils'
import { TimelineMessage } from '~/components'

export class UserTimelineMessage extends Component {
    static propTypes = {
        timelineEvent: PropTypes.object.isRequired,
        isEditable: PropTypes.bool,
        onEdit: PropTypes.func,
        className: PropTypes.string,
    }

    render() {
        const { timelineEvent, isEditable, onEdit } = this.props

        const body = get(timelineEvent.typeData, 'body')
        const sanitizedBody = sanitizeHtml(body, {
            allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
        })

        const bodyContentToRender =
            timelineEvent.type === 'HTML' ? (
                <div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
            ) : (
                <div>{nl2br(body)}</div>
            )

        const author = get(timelineEvent.createdByUser, 'profile.name')

        if (bodyContentToRender) {
            return (
                <TimelineMessage
                    bodyContentToRender={bodyContentToRender}
                    timelineEvent={timelineEvent}
                    isEditable={isEditable}
                    onEdit={onEdit}
                    author={author}
                />
            )
        }
    }
}
