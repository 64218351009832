import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import RadioButton from '~/components/RadioButton'
import TextArea from '~/components/TextArea'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { LearnerLevelWithAlphaInput } from '~/components/LearnerLevelWithAlphaInput'
import { get, times, isEmpty, isNull } from 'lodash'
import moment from 'moment'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import { VariableMultiFieldBlock } from '~/components/VariableMultiFieldBlock/VariableMultiFieldBlock'
import { FieldBlock } from '~/components/FieldBlock'

interface Props {
    user: User
    errors?: GraphQLErrors
    forNewIntake?: boolean
}

interface State {
    showNT2HasAttempted: boolean
    showHighestEducationOrginYear: boolean
    showHighestEducationLocalYear: boolean
}

export class UserLearnerEducationFieldGroup extends React.Component<Props, State> {
    public state: State = {
        showNT2HasAttempted: !!get(this.props.user, 'learner.NT2HasAttempted'),
        showHighestEducationOrginYear: this.showYearOption(
            get(this.props.user, 'learner.highestEducationCountryOfOrigin')
        ),
        showHighestEducationLocalYear: this.showYearOption(get(this.props.user, 'learner.highestEducationLocal')),
    }

    public render() {
        const { user, errors, forNewIntake } = this.props
        const { showNT2HasAttempted } = this.state

        const yearFrom = 1900
        const yearTo = moment().year() + 20

        return (
            <FieldGroup title={`Opleiding`} isForm={true}>
                <LockedFieldWrapper disableLock={!get(user, 'learner.highestEducationCountryOfOrigin')}>
                    {isLocked => (
                        <>
                            <Field isLabel={true} title={`Hoogste opleiding herkomst`} errors={errors}>
                                <TagPicker
                                    name={`user.learner.highestEducationCountryOfOrigin`}
                                    multi={false}
                                    isDisabled={isLocked}
                                    options={[
                                        '(Bijna) geen',
                                        'Basisonderwijs',
                                        'Voortgezet onderwijs',
                                        'Beroepsonderwijs (MBO)',
                                        'Hoger Beroepsonderwijs (HBO)',
                                        'Universiteit (WO)',
                                    ].map(key => ({ label: key, value: key }))}
                                    placeholder={'Selecteer'}
                                    defaultValue={get(user, `learner.highestEducationCountryOfOrigin`)}
                                    onChange={this.onHighestEducationCountryOfOriginChange}
                                />
                            </Field>
                            {this.state.showHighestEducationOrginYear && (
                                <FieldGroup isInsetGroup={true}>
                                    <Field isLabel={true} title={`Aantal jaar`} errors={errors}>
                                        <Input
                                            name={`user.learner.highestEducationCountryOfOriginYear`}
                                            type="number"
                                            isDisabled={isLocked}
                                            defaultValue={get(user, 'learner.highestEducationCountryOfOriginYear')}
                                        />
                                    </Field>
                                </FieldGroup>
                            )}
                        </>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, `learner.highestEducationLocal`)}>
                    {isLocked => (
                        <>
                            <Field isLabel={true} title={`Hoogste opleiding Nederland`} errors={errors}>
                                <TagPicker
                                    name={`user.learner.highestEducationLocal`}
                                    multi={false}
                                    isDisabled={isLocked}
                                    options={[
                                        '(Bijna) geen',
                                        'Basisonderwijs',
                                        'Voortgezet onderwijs',
                                        'Beroepsonderwijs (MBO)',
                                        'Hoger Beroepsonderwijs (HBO)',
                                        'Universiteit (WO)',
                                    ].map(key => ({ label: key, value: key }))}
                                    placeholder={'Selecteer'}
                                    defaultValue={get(user, `learner.highestEducationLocal`)}
                                    onChange={this.onHighestEducationLocalChange}
                                />
                            </Field>
                            {this.state.showHighestEducationLocalYear && (
                                <FieldGroup isInsetGroup={true}>
                                    <Field isLabel={true} title={`Aantal jaar`} errors={errors}>
                                        <Input
                                            name={`user.learner.highestEducationLocalYear`}
                                            type="number"
                                            isDisabled={isLocked}
                                            defaultValue={get(user, 'learner.highestEducationLocalYear')}
                                        />
                                    </Field>
                                </FieldGroup>
                            )}
                        </>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake ? true : !get(user, `learner.educationDesire`)}>
                    {isLocked => (
                        <Field isLabel={true} title={`Opleidingswens`} errors={errors}>
                            <TagPicker
                                name={`user.learner.educationDesire`}
                                multi={false}
                                isDisabled={isLocked}
                                options={['Geen', 'MBO 1/2', 'MBO 3', 'MBO 4', 'HBO', 'Universiteit'].map(key => ({
                                    label: key,
                                    value: key,
                                }))}
                                placeholder={'Selecteer'}
                                defaultValue={forNewIntake || get(user, `learner.educationDesire`)}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={forNewIntake || !get(user, 'learner.educationExtraInfo')}>
                    {isLocked => (
                        <Field isLabel={true} title={`Toelichting opleiding`} errors={errors}>
                            <TextArea
                                name={`user.learner.educationExtraInfo`}
                                defaultValue={forNewIntake ? undefined : get(user, `learner.educationExtraInfo`)}
                                isDisabled={isLocked}
                            />
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={!get(user, 'learner.computerSkill')}>
                    {isLocked => (
                        <Field title={`Computervaardig`} errors={errors}>
                            <MultiInput type="radio">
                                {['Ja', 'Een beetje', 'Nee'].map((skill, i) => (
                                    <RadioButton
                                        key={i}
                                        name={`user.learner.computerSkill`}
                                        value={skill}
                                        isDisabled={isLocked}
                                        defaultChecked={get(user, 'learner.computerSkill') === skill}
                                    >
                                        {skill}
                                    </RadioButton>
                                ))}
                            </MultiInput>
                        </Field>
                    )}
                </LockedFieldWrapper>
                <LockedFieldWrapper disableLock={this.isNT2GroupLockDisabled(user)}>
                    {isLocked => (
                        <React.Fragment>
                            <Field title={`Al eerder NT2 gevolgd?`} errors={errors}>
                                <MultiInput type="radio">
                                    <RadioButton
                                        name={`user.learner.NT2HasAttempted`}
                                        isDisabled={isLocked}
                                        value={true}
                                        defaultChecked={!!get(user, 'learner.NT2HasAttempted')}
                                        onClick={() => this.setState({ showNT2HasAttempted: true })}
                                    >
                                        Ja
                                    </RadioButton>
                                    <RadioButton
                                        name={`user.learner.NT2HasAttempted`}
                                        value={false}
                                        isDisabled={isLocked}
                                        defaultChecked={!get(user, 'learner.NT2HasAttempted')}
                                        onClick={() => this.setState({ showNT2HasAttempted: false })}
                                    >
                                        Nee
                                    </RadioButton>
                                </MultiInput>
                            </Field>
                            {showNT2HasAttempted && (
                                <React.Fragment>
                                    <VariableMultiFieldBlock
                                        isInset={false}
                                        baseAmount={(get(user, `learner.NT2`) || []).length || 1}
                                        isDisabled={isLocked}
                                        renderFieldGroup={(index, remove, initialIndex) => (
                                            <FieldBlock
                                                title={`NT2 cursus ${index + 1}`}
                                                onRemove={remove}
                                                isForm={true}
                                            >
                                                <Field title={`In welk jaar?`} errors={errors}>
                                                    <TagPicker
                                                        name={`user.learner.NT2[${index}].year`}
                                                        defaultValue={get(user, `learner.NT2[${initialIndex}].year`)}
                                                        placeholder={`Selecteer een jaar`}
                                                        multi={false}
                                                        isDisabled={isLocked}
                                                        options={times(yearTo + 1 - yearFrom, index => ({
                                                            label: yearFrom + index,
                                                            value: yearFrom + index,
                                                        }))}
                                                    />
                                                </Field>
                                                <Field title={`Hoe lang?`} errors={errors}>
                                                    <TagPicker
                                                        name={`user.learner.NT2[${index}].duration`}
                                                        placeholder={`Selecteer een tijd`}
                                                        defaultValue={get(
                                                            user,
                                                            `learner.NT2[${initialIndex}].duration`
                                                        )}
                                                        multi={false}
                                                        isDisabled={isLocked}
                                                        options={[
                                                            'Minder dan 3 maanden',
                                                            '3 maanden',
                                                            '6 maanden',
                                                            '9 maanden',
                                                            '1 jaar',
                                                            '1,5 jaar',
                                                            '2 jaar',
                                                            '3 jaar',
                                                            '4 jaar',
                                                            '5 jaar',
                                                            '6 jaar',
                                                            '7 jaar',
                                                            '8 jaar',
                                                            '9 jaar',
                                                            '10 jaar',
                                                        ].map(d => ({ label: d, value: d }))}
                                                    />
                                                </Field>
                                                <Field title={`Welk niveau?`} errors={errors}>
                                                    <LearnerLevelWithAlphaInput
                                                        name={`user.learner.NT2[${index}].level`}
                                                        placeholder={`Selecteer een niveau`}
                                                        isDisabled={isLocked}
                                                        defaultValue={get(user, `learner.NT2[${initialIndex}].level`)}
                                                    />
                                                </Field>
                                                <Field
                                                    title={`Bij welke organisatie heb je de NT2 cursus gedaan?`}
                                                    errors={errors}
                                                    key={`${index}-3`}
                                                >
                                                    <Input
                                                        name={`user.learner.NT2[${index}].organizationName`}
                                                        placeholder={`Organisatie naam`}
                                                        isDisabled={isLocked}
                                                        defaultValue={get(
                                                            user,
                                                            `learner.NT2[${initialIndex}].organizationName`
                                                        )}
                                                    />
                                                </Field>
                                                <Field title={`Welk lesmateriaal heb je gebruikt?`} errors={errors}>
                                                    <Input
                                                        name={`user.learner.NT2[${index}].courseMaterials`}
                                                        placeholder={`Lesmateriaal(en)`}
                                                        isDisabled={isLocked}
                                                        defaultValue={get(
                                                            user,
                                                            `learner.NT2[${initialIndex}].courseMaterials`
                                                        )}
                                                    />
                                                </Field>
                                            </FieldBlock>
                                        )}
                                    />
                                    <LockedFieldWrapper disableLock={!get(user, `learner.NT2Info`)}>
                                        {isLocked => (
                                            <FieldGroup isInsetGroup={true}>
                                                <Field
                                                    isLabel={true}
                                                    title={`Toelichting eerdere NT2 cursus(sen)`}
                                                    errors={errors}
                                                >
                                                    <TextArea
                                                        name={`user.learner.NT2Info`}
                                                        isDisabled={isLocked}
                                                        defaultValue={get(user, `learner.NT2Info`)}
                                                    />
                                                </Field>
                                            </FieldGroup>
                                        )}
                                    </LockedFieldWrapper>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </LockedFieldWrapper>
            </FieldGroup>
        )
    }

    private isNT2GroupLockDisabled(user: User) {
        const { learner } = user

        return (
            (!isNull(get(learner, 'NT2')) && isEmpty(get(learner, 'NT2'))) ||
            (isNull(get(learner, 'NT2[0].courseMaterials')) &&
                isNull(get(learner, 'NT2[0].duration')) &&
                isNull(get(learner, 'NT2[0].level')) &&
                isNull(get(learner, 'NT2[0].organizationName')) &&
                isNull(get(learner, 'NT2[0].year')))
        )
    }

    private onHighestEducationCountryOfOriginChange = (option: TagPickerOption) => {
        const value = option ? option.value : null

        this.setState({
            showHighestEducationOrginYear: this.showYearOption(value),
        })
    }

    private onHighestEducationLocalChange = (option: TagPickerOption) => {
        const value = option ? option.value : null

        this.setState({
            showHighestEducationLocalYear: this.showYearOption(value),
        })
    }

    private showYearOption(value?: string | null) {
        return !!value && value !== '(Bijna) geen'
    }
}
