import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Input, Option, Select } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import transformFormFields from '~/utils/transformFormFields'

export default class CreateLearnerAvailabilityEventForm extends Component {
    static propTypes = {
        defaultValues: PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date),
        }),
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        errors: PropTypes.instanceOf(GraphQLErrors),
        loading: PropTypes.bool,
    }

    onSubmit = (event, fields) => {
        const transformGeneralWeekDay = time => {
            return { value: moment(`2001-01-0${fields.dow}T${time}`).toDate() }
        }

        this.props.onSubmit({
            ...transformFormFields(
                fields,
                {
                    startDate: transformGeneralWeekDay(fields.startDateTime),
                    endDate: transformGeneralWeekDay(fields.endDateTime),
                },
                ['dow', 'startDateTime', 'endDateTime']
            ),
        })
    }

    render() {
        const { onCancel, errors, loading, defaultValues } = this.props
        const startDateMoment = moment(defaultValues.startDate)
        const endDateMoment = moment(defaultValues.endDate)

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Titel`} errors={errors}>
                            <Input name={`title`} type={`text`} placeholder={`Titel`} autoFocus={true} />
                        </Field>
                    </FieldGroup>
                    <FieldGroup isForm>
                        <Field isLabel title={`Dag`} errors={errors}>
                            <Select name={`dow`} defaultValue={startDateMoment.date()}>
                                <Option value={1}>Maandag</Option>
                                <Option value={2}>Dinsdag</Option>
                                <Option value={3}>Woendag</Option>
                                <Option value={4}>Donderdag</Option>
                                <Option value={5}>Vrijdag</Option>
                                <Option value={6}>Zaterdag</Option>
                                <Option value={7}>Zondag</Option>
                            </Select>
                        </Field>
                        <Field isLabel title={`Van (tijd)`} errors={errors}>
                            <Input
                                type={`time`}
                                name={`startDateTime`}
                                defaultValue={startDateMoment.format('HH:mm')}
                            />
                        </Field>
                        <Field isLabel title={`Tot (tijd)`} errors={errors}>
                            <Input type={`time`} name={`endDateTime`} defaultValue={endDateMoment.format('HH:mm')} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}
