import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Currency } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { CourseMaterial } from '~/types/Module'

interface Props {
    courseMaterial: CourseMaterial
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { courseMaterial } = this.props
        const hasAmount = typeof courseMaterial.amount === 'number'

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Naam`}>{courseMaterial.name}</Field>
                            <Field title={`Bedrag`}>{hasAmount && <Currency amount={courseMaterial.amount} />}</Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                <ListItem right={true}>
                                    <Button
                                        onClick={() =>
                                            browserHistory.push(
                                                `/properties/courseMaterials/${courseMaterial._id}/edit`
                                            )
                                        }
                                        type={`edit`}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
