import './StatisticWrap.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Wrap } from '~/components/Wrap'

interface Props {
    className?: string
    title?: string
    renderAction?: () => JSX.Element
}

export class StatisticWrap extends React.Component<Props> {
    private bem = new BEM('StatisticWrap')

    public render() {
        const { className, children, title, renderAction } = this.props

        return (
            <Wrap full={true} className={this.bem.getClassName(className)}>
                {!!title && (
                    <div className={this.bem.getElement('bar')}>
                        {title && <h3 className={this.bem.getElement('title')}>{title}</h3>}
                        {renderAction && <div className={this.bem.getElement('action')}>{renderAction()}</div>}
                    </div>
                )}
                {children}
            </Wrap>
        )
    }
}
