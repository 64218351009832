import * as React from 'react'

import { Form, TableWrap, TableHeaderItem, TableHeader, DrawerHeader, FieldCollection, Highlight } from '~/components'
import { GroupExamsModalTable } from '~/components/tables/GroupExamsModalTable/GroupExamsModalTable'
import { ScoreInput } from '~/components/ScoreInput'
import { LessonUser } from '~/types/User'
import { ResultInput, PlannedAbilityExam, Ability, ExamType } from '~/types/Exam'
import { Mutator } from '~/utils'
import { gql } from '@apollo/client'
import transformFormFields from '~/utils/transformFormFields'
import { TableView } from '~/components/TableView'

import { ContentView } from '~/components/ContentView'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { GroupExamFormTableRow } from '~/components/Group/Exams/GroupExamFormTableRow'
import DrawerFooter from '~/components/DrawerFooter'

interface FormFields {
    results: ResultInput[]
}

type FormSubmit<Fields> = (event: React.SyntheticEvent<HTMLFormElement>, fields: Fields) => void

interface Props {
    onSubmitSuccess?: () => void
    onCancel?: () => void
    plannedAbilityExam: PlannedAbilityExam
    examAbilityDetails: Ability
}

const RESULT_MUTATION = gql`
    mutation _($results: [ResultInputType]) {
        result_enterMultiple(results: $results)
    }
`

export class GroupExamScoreForm extends React.Component<Props, {}> {
    private examAbilityMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.examAbilityMutator = new Mutator({
            mutation: RESULT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, plannedAbilityExam, examAbilityDetails } = this.props
        const { loading } = this.examAbilityMutator
        const examName = plannedAbilityExam.exam && plannedAbilityExam.exam.name
        const canShowHighlight =
            typeof examAbilityDetails.minimumScoreToPass === 'number' &&
            typeof examAbilityDetails.maximumScore === 'number'

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <DrawerHeader
                        title={`${examName} (${this.getReadableAbility(plannedAbilityExam.examAbility)})`}
                        close={onCancel}
                    />
                    <ContentView>
                        {canShowHighlight && (
                            <Highlight>
                                <SimpleTable>
                                    <SimpleTableRow>
                                        <SimpleTableCell isBold>Voldoende vanaf</SimpleTableCell>
                                        <SimpleTableCell>
                                            {examAbilityDetails.minimumScoreToPass}/{examAbilityDetails.maximumScore}
                                        </SimpleTableCell>
                                    </SimpleTableRow>
                                </SimpleTable>
                            </Highlight>
                        )}
                        <TableWrap>
                            <TableView needsPaddingForTagPicker={true}>
                                <GroupExamsModalTable type={`score`}>
                                    <TableHeader>
                                        <TableHeaderItem style={{ width: '27%' }}>Kandidaat</TableHeaderItem>
                                        <TableHeaderItem style={{ width: '250px' }}>Status</TableHeaderItem>
                                        <TableHeaderItem>Score</TableHeaderItem>
                                    </TableHeader>
                                    {this.renderTableRows()}
                                </GroupExamsModalTable>
                            </TableView>
                        </TableWrap>
                    </ContentView>
                </FieldCollection>
                <DrawerFooter close={onCancel} isLoading={loading} />
            </Form>
        )
    }

    private renderTableRows = () => {
        const { plannedAbilityExam, examAbilityDetails } = this.props
        const { errors } = this.examAbilityMutator
        const exam = plannedAbilityExam.exam
        const examLevel = exam && exam.level
        const examType = exam && exam.type
        const plannedAbilityExamId = plannedAbilityExam._id
        const lessonUsers = plannedAbilityExam.lesson && plannedAbilityExam.lesson.lessonUsers

        if (!lessonUsers) {
            return null
        }

        return lessonUsers.map((lessonUser: LessonUser, i: number) => {
            const examAbilityResult = this.getCorrespondingResultForUserId(lessonUser.learnerUserId)
            const defaultValueForScoreInput =
                examAbilityResult && examAbilityResult.result && examAbilityResult.result.score
            const learner = lessonUser.learnerUser
            const inputBaseName = `results[${i}]`

            return (
                <GroupExamFormTableRow
                    key={lessonUser._id}
                    errors={errors}
                    baseName={inputBaseName}
                    renderTableSpecificInput={() => (
                        <ScoreInput
                            name={`${inputBaseName}.result.score`}
                            defaultValue={defaultValueForScoreInput}
                            level={examLevel}
                            minScoreToPass={examAbilityDetails.minimumScoreToPass}
                            maxScore={examAbilityDetails.maximumScore}
                            showLevel={examType !== ExamType.Alpha}
                        />
                    )}
                    learner={learner}
                    examAbilityResult={examAbilityResult}
                    plannedAbilityExamId={plannedAbilityExamId}
                />
            )
        })
    }

    private getReadableAbility = (ability: string) => {
        if (ability === 'converse') {
            return 'Gespreksvaardigheid'
        } else if (ability === 'talk') {
            return 'Spreken'
        } else if (ability === 'write') {
            return 'Schrijven'
        } else if (ability === 'listen') {
            return 'Luisteren'
        } else if (ability === 'read') {
            return 'Lezen'
        }

        return null
    }

    private getCorrespondingResultForUserId = (userId: string) => {
        const { plannedAbilityExam } = this.props
        const defaultValues = plannedAbilityExam.results
        const results = defaultValues && defaultValues.filter(result => result.learnerUserId === userId)

        return results && results[0]
    }

    private onSubmit: FormSubmit<FormFields> = async (event, fields) => {
        event.preventDefault()
        const { onSubmitSuccess } = this.props

        const data = await this.examAbilityMutator.mutate({
            results: fields.results.map(result => {
                return transformFormFields(result, {
                    result: { value: (v: any) => transformFormFields(v) },
                })
            }),
        })

        const mutationSuccess = data && data.result_enterMultiple

        if (mutationSuccess && onSubmitSuccess) {
            onSubmitSuccess()
        }
    }
}
