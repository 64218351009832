import c from 'classnames'
import * as React from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Notification, TNotification, TNotificationType } from '~/components/Notifications/Notification'

interface Props {
    className?: string
}

interface NotificationOptions {
    timeout?: number
    onClick?: (notification: TNotification) => void
}

interface State {
    notifications: TNotification[]
}

export class NotificationManager extends React.Component<Props, State> {
    public static info = (message: string, opts?: NotificationOptions) => {
        NotificationManager.addNotification('info', message, opts)
    }

    public static success = (message: string, opts?: NotificationOptions) => {
        NotificationManager.addNotification('success', message, opts)
    }

    public static warning = (message: string, opts?: NotificationOptions) => {
        NotificationManager.addNotification('warning', message, opts)
    }

    public static error = (message: string, opts?: NotificationOptions) => {
        NotificationManager.addNotification('error', message, opts)
    }

    private static addNotification: (type: TNotificationType, message: string, opts?: NotificationOptions) => void

    private DEFAULT_TIMEOUT_BY_TYPE = {
        success: 1500,
        error: 8000,
        warning: 4000,
        info: 3000,
    }

    private timeouts: number[]
    private el?: HTMLElement | null
    private root?: HTMLElement | null

    constructor(props: Props) {
        super(props)

        this.state = {
            notifications: [],
        }

        this.timeouts = []
    }

    public componentDidMount() {
        this.root = document.getElementById('notification-root')

        if (this.root && this.el) {
            this.root.appendChild(this.el)
        }
    }

    public componentWillUnmount() {
        this.timeouts.forEach(timeout => window.clearTimeout(timeout))

        if (this.root && this.el) {
            this.root.removeChild(this.el)
        }
    }

    public componentWillMount() {
        this.el = document.createElement('div')
        NotificationManager.addNotification = this.addNotification
    }

    public render() {
        if (this.el) {
            this.el.className = this.getClassName()
        }

        return this.el && createPortal(this.renderNotification(), this.el)
    }

    public renderNotification() {
        const { notifications } = this.state

        return (
            <TransitionGroup>
                {notifications.map(notification => (
                    <CSSTransition
                        key={notification.id}
                        classNames={`tt-Notification-`}
                        timeout={500}
                        onEntered={() => this.onNotificationEnter(notification)}
                    >
                        <Notification type={notification.type} onClick={notification.clickHandler}>
                            {notification.message}
                        </Notification>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        )
    }

    public addNotification = (type: TNotificationType, message: string, opts: NotificationOptions = {}) => {
        const id = this.makeId()

        const notification: TNotification = {
            id,
            dismiss: () => {
                this.removeNotification(id)
            },
            type,
            message,
            timeout: opts.timeout || this.DEFAULT_TIMEOUT_BY_TYPE[type],
            clickHandler: () => {
                if (opts.onClick) {
                    opts.onClick(notification)
                }
            },
        }

        this.setState((state: State) => ({
            notifications: [...state.notifications, notification],
        }))
    }

    public removeNotification(idToRemove: string) {
        this.setState((state: State) => {
            return {
                notifications: state.notifications.filter(({ id }) => id !== idToRemove),
            }
        })
    }

    public onNotificationEnter(notification: TNotification) {
        this.timeouts.push(
            window.setTimeout(() => {
                notification.dismiss()
            }, notification.timeout)
        )
    }

    public makeId() {
        let text = ''
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        return text
    }

    public getClassName() {
        const { className } = this.props

        return c('tt-NotificationManager', className)
    }
}
