import c from 'classnames'
import * as React from 'react'

import { View } from '~/components/View'
import { ScrollElementContext } from '~/views/RootView'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScrollContent } from '~/components/Core/InfiniteScroll/InfiniteScrollContent'

interface Props {
    paginator: Paginator
    preventLoad?: boolean
    className?: string
    component?: any
}

interface State {
    isLoadingMore: boolean
}

export class InfiniteScroll extends React.Component<Props, State> {
    public state: State = {
        isLoadingMore: false,
    }

    constructor(props: Props) {
        super(props)

        props.paginator.onLoadMoreStart = () => {
            this.setState({ isLoadingMore: true })
        }

        props.paginator.onLoadMoreEnd = () => {
            this.setState({ isLoadingMore: false })
        }
    }

    public render() {
        const { children, component: Comp = View, paginator, preventLoad } = this.props
        const { isLoadingMore } = this.state

        return (
            <Comp className={this.getClassName()}>
                <ScrollElementContext.Consumer>
                    {scrollElement =>
                        scrollElement && (
                            <InfiniteScrollContent
                                paginator={paginator}
                                scrollElement={scrollElement}
                                isLoadingMore={isLoadingMore}
                                preventLoad={preventLoad}
                            >
                                {children}
                            </InfiniteScrollContent>
                        )
                    }
                </ScrollElementContext.Consumer>
            </Comp>
        )
    }

    private getClassName() {
        const { className } = this.props
        const { isLoadingMore } = this.state

        return c(
            'tt-InfiniteScroll',
            {
                'tt-InfiniteScroll--is-loading': isLoadingMore,
            },
            className
        )
    }
}
