import * as React from 'react'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
}

export class SimpleTable extends React.Component<Props> {
    private bem = new BEM('SimpleTable')

    public render() {
        const { children, className } = this.props

        return (
            <table className={this.bem.getClassName(className)}>
                <tbody>{children}</tbody>
            </table>
        )
    }
}
