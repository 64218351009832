import { gql } from '@apollo/client'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, MultiInput } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { getCurrentUser } from '~/services/session'

export default class CreateOrganizationContactForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.any,
        isForCurrentUserHisOrganization: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        if (!this.props.isForCurrentUserHisOrganization) {
            this.organizationsFetcher = new Fetcher({
                query: GET_ORGANIZATIONS_QUERY,
                onChange: () => {
                    this.forceUpdate()
                },
            })
        }

        this.userMutator = new Mutator({
            mutation: CREATE_USER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, onSubmitFailed, isForCurrentUserHisOrganization } = this.props

        this.userMutator
            .mutate({
                user: {
                    type: 'ORGANIZATION_CONTACT',
                    ...transformFormFields(fields.user, {
                        profile: {
                            fields: v => transformFormFields(v, {}),
                        },
                        organizationContact: {
                            fields: v =>
                                transformFormFields(v, {
                                    organizationId: {
                                        value: value => {
                                            if (isForCurrentUserHisOrganization) {
                                                return this.getCurrentUserHisOrganizationId()
                                            }

                                            return value
                                        },
                                        isNull: v => (isForCurrentUserHisOrganization ? false : !v),
                                    },
                                    isActive: { value: () => true },
                                }),
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    const _id = get(data, 'users_create._id')

                    if (_id) {
                        const user = getCurrentUser()

                        if (user.isAdmin || user.isEmployee) {
                            browserHistory.push(`/users/organization-contacts/${_id}`)
                        }

                        if (user.isOrganizationContact) {
                            browserHistory.push(`/colleagues/${_id}`)
                        }
                    }
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    render() {
        const { errors, loading } = this.userMutator
        const { onCancel, isForCurrentUserHisOrganization } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`E-mailadres`} errors={errors}>
                            <Input
                                name={`user.email`}
                                type={`email`}
                                placeholder={`john@example.com`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field title={`Naam`} errors={errors}>
                            <MultiInput type={`name`}>
                                <Input name={`user.profile.firstName`} type={`text`} placeholder={`Voornaam`} />
                                <Input name={`user.profile.initials`} type={`text`} placeholder={`Voorletters`} />
                                <Input
                                    name={`user.profile.surNamePrefix`}
                                    type={`text`}
                                    placeholder={`Tussenvoegsel`}
                                />
                                <Input name={`user.profile.surName`} type={`text`} placeholder={`Achternaam`} />
                            </MultiInput>
                        </Field>
                        {!isForCurrentUserHisOrganization && this.renderOrganizationField()}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    renderOrganizationField() {
        const { errors } = this.userMutator
        const { data, loading: loadingOrganizations } = this.organizationsFetcher
        const { organizations = [] } = data

        return (
            <Field isLabel title={`Organisatie`} errors={errors}>
                {!loadingOrganizations && (
                    <TagPicker
                        name={`user.organizationContact.organizationId`}
                        placeholder={`Selecteer organisatie`}
                        multi={false}
                        options={organizations.map(o => ({ label: o.name, value: o._id }))}
                        isLoading={loadingOrganizations}
                    />
                )}
            </Field>
        )
    }

    getCurrentUserHisOrganizationId() {
        const currentUser = getCurrentUser()

        return (
            currentUser &&
            currentUser.organizationContact &&
            currentUser.organizationContact.organization &&
            currentUser.organizationContact.organization._id
        )
    }
}

const CREATE_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_create(user: $user) {
            _id
        }
    }
`

const GET_ORGANIZATIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`
