import React from 'react'
import { Form, DrawerHeader, Button, TableWrap, TableHeaderItem, TableHeader, TableRow, Link, Icon } from '~/components'
import { Mutator, transformFormFields } from '~/utils'
import { InflowMoment } from '~/types/InflowMoments'
import { UPDATE_INFLOW_MOMENT_MUTATION } from '~/views/App/InflowMoments/Concepts/Detail/Data/queries'
import { ContentView } from '~/components/ContentView'
import { TableView } from '~/components/TableView'
import { Location, Room } from '~/types/Location'
import { TableCell } from '~/components/TableCell'
import { Table } from '~/components/Table'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    inflowMoment: InflowMoment
    location: Location
    roomType: InflowMomentRoomType
    closeModal: () => void
    refetch: () => void
    defaultRooms: Room[]
}

interface State {
    checkedRooms: Room[]
    uncheckedRooms: Room[]
}

export enum InflowMomentRoomType {
    conversation = 'locationRoomsConversation',
    general = 'locationRoomsGeneral',
}

export class InflowLocationRoomForm extends React.Component<Props, State> {
    public state: State = {
        checkedRooms: [],
        uncheckedRooms: [],
    }

    private InflowMomentMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.InflowMomentMutator = new Mutator({
            mutation: UPDATE_INFLOW_MOMENT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public componentDidMount() {
        const { rooms = [] } = this.props.location
        const { defaultRooms } = this.props

        const defaultRoomIds = defaultRooms.map(room => room._id)

        const uncheckedRooms = rooms.filter(room => defaultRoomIds.indexOf(room._id) < 0)

        this.setState({
            checkedRooms: defaultRooms,
            uncheckedRooms,
        })
    }

    public render() {
        const { closeModal } = this.props
        const { checkedRooms, uncheckedRooms } = this.state
        const { loading: mutationLoading } = this.InflowMomentMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={this.getTitle()} close={closeModal} />
                <TableWrap insetTable>
                    <TableView>{this.renderRoomTable(checkedRooms, true)}</TableView>
                </TableWrap>
                <ContentView>
                    {/* TBA: Add filters when rooms become searchable */}
                    <TableWrap>
                        <TableView>{this.renderRoomTable(uncheckedRooms)}</TableView>
                    </TableWrap>
                </ContentView>
                <DrawerFooter close={closeModal} isLoading={mutationLoading} />
            </Form>
        )
    }

    private renderRoomTable = (rooms: Room[], isChecked?: boolean) => {
        const { location } = this.props

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem>Lesruimte</TableHeaderItem>
                    <TableHeaderItem forIconButton={true} />
                    <TableHeaderItem>Capaciteit</TableHeaderItem>
                    <TableHeaderItem>Kenmerken</TableHeaderItem>
                </TableHeader>
                {rooms.map(room => (
                    <TableRow key={room._id}>
                        <TableCell>
                            <Link route={`/properties/locations/${location._id}/rooms/${room._id}`}>{room.name}</Link>
                        </TableCell>
                        <TableCell forIconButton={true}>
                            {isChecked ? (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`minus`} />}
                                    className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                                    onClick={() => this.removeRoom(room)}
                                />
                            ) : (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`plus`} />}
                                    className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                                    onClick={() => this.addRoom(room)}
                                />
                            )}
                        </TableCell>
                        <TableCell>{room.capacity}</TableCell>
                        <TableCell>
                            {room.locationProperties
                                ? room.locationProperties.map(property => property.name).join(', ')
                                : '-'}
                        </TableCell>
                    </TableRow>
                ))}
                {rooms.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={4}>
                            {isChecked ? 'Nog geen lesruimtes toegevoegd' : 'Geen lesruimtes gevonden'}
                        </TableCell>
                    </TableRow>
                )}
            </Table>
        )
    }

    private getTitle = () => {
        const { roomType } = this.props

        if (roomType === InflowMomentRoomType.conversation) {
            return 'Gespreksruimte toevoegen'
        }

        if (roomType === InflowMomentRoomType.general) {
            return 'Gemeenschappelijke ruimte toevoegen'
        }

        return ''
    }

    private addRoom = (room: Room) => {
        this.setState({
            checkedRooms: [...this.state.checkedRooms, room],
            uncheckedRooms: this.state.uncheckedRooms.filter(({ _id }) => _id !== room._id),
        })
    }

    private removeRoom = (room: Room) => {
        this.setState({
            uncheckedRooms: [...this.state.uncheckedRooms, room],
            checkedRooms: this.state.checkedRooms.filter(({ _id }) => _id !== room._id),
        })
    }

    private onSubmit = async () => {
        const { closeModal, refetch, inflowMoment, roomType } = this.props

        const fields = {
            _id: inflowMoment._id,
            [`${roomType}Ids`]: this.state.checkedRooms.map(room => room._id),
        }

        this.InflowMomentMutator.mutate({
            inflowMoment: transformFormFields(fields, {}),
        }).then(data => {
            if (data) {
                closeModal()

                if (refetch) {
                    refetch()
                }
            }
        })
    }
}
