import c from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createPortal } from 'react-dom'
import { ScrollElementContext } from '~/views/RootView'

const modalRoot = document.getElementById('modal-root')

export default class Modal extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
        onClose: PropTypes.func.isRequired,
        enableEasyClose: PropTypes.bool,
    }

    modalRef = React.createRef(null)

    constructor(props) {
        super(props)

        this.el = document.createElement('div')
        this.el.className = `tt-ModalContainer`
    }

    componentDidMount() {
        modalRoot.appendChild(this.el)

        document.body.style.overflow = 'hidden'

        this.previousActiveElement = document.activeElement

        if (this.closeButton) {
            this.closeButton.focus()
        }

        if (this.props.enableEasyClose) {
            document.addEventListener('keydown', this.handleKeyup)
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = ''

        modalRoot.removeChild(this.el)

        if (this.previousActiveElement) {
            this.previousActiveElement.focus()
        }

        if (this.props.enableEasyClose) {
            document.removeEventListener('keydown', this.handleKeyup)
        }
    }

    render() {
        return createPortal(this.renderModal(), this.el)
    }

    renderModal() {
        const { children } = this.props

        return (
            <div ref={this.modalRef} className={this.getClassNames()}>
                <ScrollElementContext.Provider value={this.modalRef.current}>{children}</ScrollElementContext.Provider>
            </div>
        )
    }

    handleContainerClick = event => {
        const target = event.target

        if (target.closest('.tt-Modal')) {
            return
        }

        this.props.onClose()
    }

    handleKeyup = event => {
        // 27 = ESC
        if (event.keyCode !== 27) {
            return
        }

        this.props.onClose()
    }

    getClassNames() {
        const { className } = this.props

        return c(
            'tt-Modal',
            {
                // Modifier classes
            },
            className
        )
    }
}
