import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'

interface Props {
    children?: any
    className?: string
    title?: string
    id?: string
    isForm?: boolean
    isWithoutBorder?: boolean
    onRemove?: () => void
}

export class FieldBlock extends React.Component<Props, {}> {
    private bem = new BEM('FieldBlock', () => ({
        'is-form': this.props.isForm,
        'is-without-border': this.props.isWithoutBorder,
    }))

    public render() {
        const { children, className } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {this.renderTitle()}
                <div className={this.bem.getElement('list')}>{children}</div>
            </div>
        )
    }

    private renderTitle = () => {
        const { title, onRemove } = this.props

        return (
            <div className={this.bem.getElement('header')}>
                <span className={this.bem.getElement('title')}>{title}</span>
                {onRemove && this.renderRemoveButton()}
            </div>
        )
    }

    private renderRemoveButton = () => {
        const { onRemove } = this.props

        return (
            <Button
                className={this.bem.getElement('remove-button')}
                small={true}
                leftIcon={<Icon name={`trash`} />}
                onClick={onRemove}
                type={`button`}
            />
        )
    }
}
