import { gql } from '@apollo/client'

// TODO: Correct filters
export const CREATE_INVOICES_EXPORT_FILE_MUTATION = gql`
    mutation _($filters: InvoicesFilterInputType) {
        invoices_createExportFile(filters: $filters) {
            fileId
        }
    }
`
