// tslint:disable:max-line-length
import * as React from 'react'
import Bold from '~/components/Bold'
import { User } from '~/types/User'
import { DUOFinalExamResult } from '~/types/DUOFinalExamResult'

interface FinalExamLogProps {
    user: User
    DUOFinalExamResult?: DUOFinalExamResult
}

export const ExamCreatedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Examen is <Bold>aangemaakt</Bold> door {user.profile.name}
        </span>
    )
}

export const InvoiceCreatedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Factuur is <Bold>aangemaakt</Bold> door {user.profile.name}
        </span>
    )
}

export const InvoiceSentLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Factuur is <Bold>verstuurd</Bold> door {user.profile.name}
        </span>
    )
}

export const InvoiceCreditedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Factuur is <Bold>gecrediteerd</Bold> door {user.profile.name}
        </span>
    )
}

export const DuoDeclarationCreatedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            DUO declaratie is <Bold>aangemaakt</Bold> door {user.profile.name}
        </span>
    )
}

export const DuoDeclarationRevokedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            DUO declaratie is <Bold>ingetrokken</Bold> door {user.profile.name}
        </span>
    )
}

export const DateChangedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Datum is <Bold>verzet</Bold> door {user.profile.name}
        </span>
    )
}

export const ExamCancelledLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Examen is <Bold>geannuleerd</Bold> door {user.profile.name}
        </span>
    )
}

export const DateCorrectedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Datum is <Bold>gecorrigeerd</Bold> door {user.profile.name}
        </span>
    )
}

export const ExamPartCorrectedLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Examenonderdeel is <Bold>gecorrigeerd</Bold> door {user.profile.name}
        </span>
    )
}

export const SubmitterChangedToExternalLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Aanvrager &quot;TopTaal&quot; <Bold>omgezet</Bold> naar &quot;Extern&quot; door {user.profile.name}
        </span>
    )
}

export const SubmitterChangedToToptaalLog: React.SFC<FinalExamLogProps> = ({ user }) => {
    return (
        <span>
            Aanvrager &quot;Extern&quot; <Bold>omgezet</Bold> naar &quot;TopTaal&quot; door {user.profile.name}
        </span>
    )
}

export const CreatedByDUOFinalExamResultImport: React.SFC<FinalExamLogProps> = ({ DUOFinalExamResult }) => {
    return (
        <span>
            {/* tslint:disable-next-line:max-line-length */}
            Examen is in retrospect <Bold>aangemaakt</Bold> door DUO gegevensuitwisseling:{' '}
            {DUOFinalExamResult ? DUOFinalExamResult.file.fileName : '-'}
        </span>
    )
}

export const CreatedAsExemptByDUOFinalExamResultImport: React.SFC<FinalExamLogProps> = ({ DUOFinalExamResult }) => {
    return (
        <span>
            Examen is als vrijgesteld <Bold>aangemaakt</Bold> door DUO gegevensuitwisseling:{' '}
            {DUOFinalExamResult ? DUOFinalExamResult.file.fileName : '-'}
        </span>
    )
}

export const ResultUpdatedFromDUOFinalExamResultImport: React.SFC<FinalExamLogProps> = ({ DUOFinalExamResult }) => {
    return (
        <span>
            Resultaat is <Bold>geüpdatet</Bold> door DUO gegevensuitwisseling:{' '}
            {DUOFinalExamResult ? DUOFinalExamResult.file.fileName : '-'}
        </span>
    )
}

export const UncanceledByDUOFinalExamResultImport: React.SFC<FinalExamLogProps> = ({ DUOFinalExamResult }) => {
    return (
        <span>
            Annulering is <Bold>ingetrokken</Bold> door DUO gegevensuitwisseling:{' '}
            {DUOFinalExamResult ? DUOFinalExamResult.file.fileName : '-'}
        </span>
    )
}
