import './RevenueTableHeader.scss'

import c from 'classnames'
import * as React from 'react'

import { TableHeader } from '~/components'

interface Props {
    className?: string
}

export class RevenueTableHeader extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <TableHeader className={this.getClassName()}>{children}</TableHeader>
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-RevenueTableHeader', className)
    }
}
