import * as React from 'react'

import { SubNav } from '~/components/SubNav'
import { SubNavLink } from '~/components/SubNavLink'
import { Translator } from '~/services/i18n'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { View } from '~/components/View'

interface Props {}

export default class UsersView extends React.Component<Props> {
    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.translator = new Translator({
            namespace: 'App.Users',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { t } = this.translator
        const { children } = this.props

        return (
            <View>
                <SubNav>
                    <SubNavLink route={`/users/learners`}>{t('navigation.buttons.learners')}</SubNavLink>
                    <SubNavLink route={`/users/teachers`}>{t('navigation.buttons.teachers')}</SubNavLink>
                    <SubNavLink route={`/users/employees`}>{t('navigation.buttons.employees')}</SubNavLink>
                    <SubNavLink route={`/users/organization-contacts`}>
                        {t('navigation.buttons.organization-contacts')}
                    </SubNavLink>
                </SubNav>
                <BreadCrumbProvider>{children}</BreadCrumbProvider>
            </View>
        )
    }
}
