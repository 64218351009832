import React from 'react'
import Input, { InputProps } from './Input'
import { isString } from 'lodash'

interface Props extends InputProps {}

interface State {
    currentInput: string
}

export default class InputUrl extends React.Component<Props, State> {
    public state: State = {
        currentInput: (isString(this.props.defaultValue) && this.props.defaultValue) || '',
    }

    public render() {
        const { defaultValue, ...restValues } = this.props

        return (
            <Input
                {...restValues}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => this.onBlur(event)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onChange(event)}
                value={this.state.currentInput || ''}
                type={`url`}
            />
        )
    }

    private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { onBlur } = this.props

        let updatedValue = ''

        if (!this.state.currentInput.includes('www')) {
            updatedValue = `www.${this.state.currentInput}`
        }

        if (!this.state.currentInput.includes('http')) {
            updatedValue = `http://${updatedValue || this.state.currentInput}`
        }

        this.setState({ currentInput: updatedValue })

        if (onBlur) {
            onBlur(event)
        }
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props

        this.setState({ currentInput: event.target.value })

        if (onChange) {
            onChange(event)
        }
    }
}
