import c from 'classnames'
import * as React from 'react'

interface Props {
    full?: boolean
    className?: string
    isInset?: boolean
}

export class Wrap extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <div className={this.getClassName()}>{children}</div>
    }

    private getClassName() {
        const { className, full, isInset } = this.props

        return c(
            'tt-Wrap',
            {
                'tt-Wrap--full': full,
                'tt-Wrap--is-inset': isInset,
            },
            className
        )
    }
}
