import * as React from 'react'

import { ContentView } from '~/components/ContentView'
import { NotesList } from '~/components/notes/NotesList'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { NoteType } from '~/types/Note'

interface Group {
    _id: string
}

interface Props {
    group: Group
    refetch: (opts?: { silent: boolean }) => void
}

export default class GroupNotesView extends React.Component<Props, {}> {
    public render() {
        const { refetch: refetchParent, group } = this.props

        return (
            <ContentView>
                <TableWrap>
                    <TableView>
                        {group && (
                            <NotesList
                                noteType={NoteType.GroupNote}
                                noteTypeData={{
                                    groupId: group._id,
                                }}
                                onAddNote={() => {
                                    refetchParent({ silent: true })
                                }}
                                onRemoveNote={() => {
                                    refetchParent({ silent: true })
                                }}
                            />
                        )}
                    </TableView>
                </TableWrap>
            </ContentView>
        )
    }
}
