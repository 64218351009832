import './AttendanceUserName.scss'

import * as React from 'react'

import { Link } from '~/components'
import { User } from '~/types/User'
import { BEM } from '~/services/BEMService'
import { Group } from '~/types/Group'
import { getCurrentUser } from '~/services/session'

interface Props {
    className?: string
    group: Group
    user: User
    isRemoved: boolean
}

export class AttendanceUserName extends React.Component<Props, {}> {
    private bem = new BEM('AttendanceUserName', () => ({
        removed: this.props.isRemoved,
    }))

    public render() {
        const { user, className } = this.props

        const userPath = this.getUserPath()

        return (
            <Link className={this.bem.getClassName(className)} route={userPath}>
                {user.profile.name}
            </Link>
        )
    }

    private getUserPath(): string {
        const { user, group } = this.props
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee) {
            return `/users/learners/${user._id}`
        }

        if (currentUser.isOrganizationContact) {
            return `/learners/${user._id}`
        }

        if (currentUser.isExternalTeacher) {
            return `/groups/${group._id}/learners/${user._id}`
        }

        return ''
    }
}
