import c from 'classnames'
import * as React from 'react'

interface Props {
    amount?: any
    className?: string
}

export class CounterIcon extends React.Component<Props> {
    public render() {
        const { amount, ...restProps } = this.props

        return (
            <i className={this.getClassName()} {...restProps}>
                {amount}
            </i>
        )
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-Icon', 'tt-Icon--counter-icon', className)
    }
}
