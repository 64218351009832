import './HeaderActionList.scss'

import c from 'classnames'
import * as React from 'react'

import { List } from '~/components/List'

interface Props {
    className?: string
}

export class HeaderActionList extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <List className={this.getClassName()}>{children}</List>
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-HeaderActionList', className)
    }
}
