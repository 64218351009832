import * as React from 'react'
import { gql } from '@apollo/client'
import Fetcher from '~/utils/Fetcher'
import { GroupUser } from '~/types/User'

interface Props {
    groupId: string
}

interface TGroupLearnerParticipantsDataContext {
    learnerUsers: GroupUser[]
    refetch: () => void
    loading: boolean
}

const GET_GROUP_LEARNER_PARTICIPANTS_QUERY = gql`
    query _($groupId: MongoID) {
        groups(filters: { filterById: $groupId }) {
            _id
            endedAt
            learnerUsers {
                _id
                role
                isNowEnrolled
                hasLearnerFutureEnrollment
                hasLearnerFinalEnrollment
                enrollments {
                    _id
                    type
                    date
                    removedReason
                    removedInfo
                    createdAt
                    createdByUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
                isGroupParticipationInvoiceActivatable
                hasSignedContractForEveryLessonInGroup
                hasAttendancesAfterLastEnrollment
                hasInvoicesAfterLastEnrollment
                user {
                    _id
                    email
                    hasInvoicingEnabled
                    profile {
                        name
                        address {
                            _id
                            nl {
                                extraInfo {
                                    city
                                }
                            }
                        }
                        phoneNumbers {
                            _id
                            country
                            internationalPhoneNumber
                            info
                        }
                    }
                    learner {
                        invoices(filters: { byGroupIds: [$groupId] }) {
                            ...invoiceFields
                            isGroupParticipationInvoice
                            isFinalExamInvoice
                            descendantInstallmentInvoices {
                                ...invoiceFields
                                descendantInstallmentInvoiceHasFailedToSend
                            }
                        }
                        contracts(filterByUnsignedForGroupId: $groupId) {
                            _id
                            contractNumber
                            isSigned
                            isFinal
                            user {
                                _id
                            }
                        }
                        hasPrivatePayment
                    }
                }
            }
        }
    }

    fragment invoiceFields on InvoiceType {
        _id
        status
        openStatus
        paymentStatus
        isForDUO
        hasSignatureForDUO
        isCredit
        isExpired
        daysExpired
        invoiceNumber
        invoiceDate
        isDescendantInstallment
        isFullyCredited
        invoiceSentAt
    }
`

const GroupLearnerParticipantsDataContext = React.createContext<TGroupLearnerParticipantsDataContext | undefined>(
    undefined
)

export const GroupLearnerParticipantsDataConsumer = GroupLearnerParticipantsDataContext.Consumer

export class GroupLearnerParticipantsDataProvider extends React.PureComponent<Props> {
    private static sortGroupUsers = (a: any, b: any) => {
        if (!a.user || !b.user) {
            return 0
        }

        const aIsRemoved = !a.isNowEnrolled && !a.hasLearnerFinalEnrollment
        const bIsRemoved = !b.isNowEnrolled && !b.hasLearnerFinalEnrollment

        if (aIsRemoved && !bIsRemoved) {
            return 1
        }

        if (!aIsRemoved && bIsRemoved) {
            return -1
        }

        if (!aIsRemoved === !bIsRemoved) {
            if (a.user.profile.name < b.user.profile.name) {
                return -1
            }

            if (a.user.profile.name > b.user.profile.name) {
                return 1
            }
        }

        return 0
    }

    private participantsFetcher = new Fetcher({
        query: GET_GROUP_LEARNER_PARTICIPANTS_QUERY,
        variables: {
            groupId: this.props.groupId,
        },

        onChange: () => this.forceUpdate(),
        transformData: (data: any) => {
            return {
                ...data,
                groups: [
                    {
                        ...data.groups[0],
                        learnerUsers: [...(data.groups[0].learnerUsers || [])].sort(
                            GroupLearnerParticipantsDataProvider.sortGroupUsers
                        ),
                    },
                ],
            }
        },
    })

    public render() {
        return (
            <GroupLearnerParticipantsDataContext.Provider value={this.getContextValue()}>
                {this.props.children}
            </GroupLearnerParticipantsDataContext.Provider>
        )
    }

    private getContextValue = (): TGroupLearnerParticipantsDataContext => {
        const { data, loading } = this.participantsFetcher
        const group = data && data.groups && data.groups[0]
        const learnerUsers = (group && group.learnerUsers) || []

        return {
            learnerUsers: learnerUsers,
            refetch: () => {
                this.participantsFetcher.refetch()
            },
            loading: loading,
        }
    }
}
