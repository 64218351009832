import * as React from 'react'

import './Label.scss'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    hasInverseColors?: boolean
}

export class Label extends React.Component<Props, {}> {
    private bem = new BEM('Label', () => ({
        'has-inverse-colors': this.props.hasInverseColors,
    }))

    public render() {
        const { children, className } = this.props

        return <span className={this.bem.getClassName(className)}>{children}</span>
    }
}
