import React from 'react'
import { gql } from '@apollo/client'

import DrawerHeader from '~/components/DrawerHeader'
import { List } from '~/components/List'
import { ContentView } from '~/components/ContentView'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { TableCell } from '~/components/TableCell'
import Icon from '~/components/Icon'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import Search from '~/components/Search'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import { TagPicker } from '~/components/TagPicker'
import Field from '~/components/Field'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import Fetcher from '~/utils/Fetcher'
import Filter from '~/utils/Filter'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import Spinner from '~/components/Spinner'
import Subtle from '~/components/Subtle'
import { User, UserRole } from '~/types/User'
import Link from '~/components/Link'
import MultiInput from '~/components/MultiInput'
import CheckBox from '~/components/CheckBox'
import TableRow from '~/components/TableRow'
import { Table } from '~/components/Table'
import { toast } from '~/utils'
import Mutator from '~/utils/Mutator'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    groupId: string
    onCancel: () => void
    onSuccess: () => void
}

interface State {
    limit: number
    skip: number
}

const START = 40
const INCREASE = 40
const STATIC_TEACHER_USERS_FILTERS = {
    roles: ['TEACHER', 'INTERNAL_TEACHER'],
    filterTeacherActive: true,
}

export class AddTeacherGroupUserDrawerForm extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
    }

    private teacherUsersPaginator: Paginator
    private teacherUsersFetcher: Fetcher
    private teacherUsersFilter: Filter
    private filterSuggestionsFetcher: Fetcher

    private addTeacherToGroupMutator = new Mutator({
        mutation: ADD_TEACHER_TO_GROUP,
        reactComponentToUpdate: this,
    })

    public constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.teacherUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.teacherUsersFilter = new Filter({
            useHistory: false,
            allowedKeys: ['searchText', 'filterByCityNames', 'excludeRoles'],
            onChange: (filters: any) => {
                this.teacherUsersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_TEACHER_USERS_FILTERS,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsExternalTeacher', 'filterIsInternalTeacher'],
                    transform: (filters: any) => {
                        const excludeRoles = []
                        if (filters.filterIsExternalTeacher) {
                            excludeRoles.push('INTERNAL_TEACHER')
                        }
                        if (filters.filterIsInternalTeacher) {
                            excludeRoles.push('TEACHER')
                        }

                        return {
                            excludeRoles: excludeRoles.length > 0 && excludeRoles.length < 2 ? excludeRoles : null,
                        }
                    },
                    historyTriggerKeys: ['excludeRoles'],
                    parseFromHistory: (historyFilters: any) => {
                        const excludeRoles = historyFilters.excludeRoles || []

                        return {
                            filterIsExternalTeacher: excludeRoles.indexOf('INTERNAL_TEACHER'),
                            filterIsInternalTeacher: excludeRoles.indexOf('TEACHER'),
                        }
                    },
                },
            ],
        })

        this.teacherUsersFetcher = new Fetcher({
            query: GET_TEACHER_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_TEACHER_USERS_FILTERS,
                    ...this.teacherUsersFilter.getFilters(),
                },
                limit,
                skip,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.teacherUsersPaginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { onCancel } = this.props
        const { loading, data } = this.teacherUsersFetcher
        const users = data.users || []

        return (
            <React.Fragment>
                <DrawerHeader title={'Docent toevoegen'} close={onCancel} />
                <ContentView>
                    <InfiniteScroll paginator={this.teacherUsersPaginator} preventLoad={loading}>
                        <Wrap full>
                            <ActionBar
                                getButtons={(toggleDrawer, drawerActive) => {
                                    return (
                                        <List horizontal>
                                            <ListItem>
                                                <Button
                                                    leftIcon={<Icon name={`filter`} />}
                                                    onClick={() => toggleDrawer()}
                                                    isActive={drawerActive}
                                                >
                                                    Filteropties
                                                </Button>
                                            </ListItem>
                                            <ListItem>
                                                <Search isLoading={loading} onSearch={this.onSearch} />
                                            </ListItem>
                                        </List>
                                    )
                                }}
                                getDrawer={this.renderActionBarDrawer}
                            />
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem forIconButton={true} />
                                        <TableHeaderItem width="35%">Naam</TableHeaderItem>
                                        <TableHeaderItem>Woonplaats</TableHeaderItem>
                                        <TableHeaderItem>Type</TableHeaderItem>
                                        <TableHeaderItem />
                                    </TableHeader>
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Spinner instant />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!loading &&
                                        (users.length > 0 ? (
                                            users.map((user: any) => (
                                                <React.Fragment key={user._id}>
                                                    {this.renderTeacherRow(user)}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Subtle>Er zijn geen docenten gevonden.</Subtle>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter hasCancelSaveBtns={false}>
                    <List horizontal>
                        <ListItem right>
                            <Button onClick={onCancel}>Annuleer</Button>
                        </ListItem>
                    </List>
                </DrawerFooter>
            </React.Fragment>
        )
    }

    private renderActionBarDrawer = () => {
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities = [] } = filterParams

        const addressOptions = addresses_usedCities.map((city: any) => ({ value: city, label: city }))

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Plaats`} style={`compact`}>
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name={`filterByCityNames`}
                        options={addressOptions}
                        placeholder={`Selecteer plaats`}
                    />
                </Field>
                <Field title={`Type`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox name={`filterIsExternalTeacher`} onChange={this.onFilter}>
                            Externe docent
                        </CheckBox>
                        <CheckBox name={`filterIsInternalTeacher`} onChange={this.onFilter}>
                            TopTaal docent
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    private renderTeacherRow = (user: User) => {
        const canExpandRow = !!user.profile.notes

        return (
            <TableRow
                hasExpandableInfo={canExpandRow}
                getExpansion={() => (
                    <TableCell colSpan={5}>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Notities:</SimpleTableCell>
                                <SimpleTableCell>{user.profile.notes || '-'}</SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </TableCell>
                )}
            >
                <TableCell forIconButton>
                    <Button
                        type={`in-row`}
                        leftIcon={<Icon name={`plus`} />}
                        onClick={() => this.addTeacherToGroup(user)}
                    />
                </TableCell>
                <TableCell>
                    <Link route={`/users/teachers/${user._id}`} target="_blank" type="emphasized">
                        {user.profile.name}
                    </Link>
                </TableCell>
                <TableCell>
                    {user.profile.address &&
                        user.profile.address.nl &&
                        user.profile.address.nl.extraInfo &&
                        user.profile.address.nl.extraInfo.city}
                </TableCell>
                <TableCell>{(user.roles || []).indexOf(UserRole.Teacher) > -1 ? 'Extern' : 'Intern'}</TableCell>
            </TableRow>
        )
    }

    private loadMore = (skip: number, limit: number, callback: (options?: { finished: boolean }) => void) => {
        this.teacherUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: any) => {
        this.teacherUsersFilter.apply('searchText', searchText)
    }

    private onFilter = (event: any) => {
        this.teacherUsersFilter.applyFromInputEvent(event)
    }

    private onFilterTagPicker = (values: any, tagPicker: any) => {
        this.teacherUsersFilter.applyFromTagPicker(tagPicker)
    }

    private addTeacherToGroup = async (user: User) => {
        const { groupId, onSuccess } = this.props

        const res = await this.addTeacherToGroupMutator.mutate({
            groupId,
            userId: user._id,
            role: 'TEACHER',
            date: new Date(),
        })

        if (res && res.groups_enrollUser) {
            onSuccess()
            toast.success(`Docent "${user.profile.name}" succesvol toegevoegd aan groep`)
        }
    }
}

const GET_TEACHER_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(limit: $limit, skip: $skip, sortBy: "profile.firstName", filters: $filters) {
            _id
            roles
            profile {
                name
                notes
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`

const ADD_TEACHER_TO_GROUP = gql`
    mutation _($groupId: MongoID!, $userId: MongoID!, $role: GroupUserRoleEnum!, $date: Date!) {
        groups_enrollUser(groupId: $groupId, userId: $userId, role: $role, date: $date) {
            _id
        }
    }
`
