interface PaginatorLoadMoreReturnType {
    finished?: boolean
}

export type PaginatorLoadMoreFn = (
    skip: number,
    limit: number,
    callback: (loadMoreCallbackData?: PaginatorLoadMoreReturnType) => void
) => void

type PaginatorLoadMorePromiseFn = (skip: number, limit: number) => Promise<PaginatorLoadMoreReturnType | void>

export interface PaginatorOptions {
    start: number
    increase: number

    // Callback-based or Promise-based load-more handler
    onLoadMore: PaginatorLoadMoreFn | PaginatorLoadMorePromiseFn
}

export class Paginator {
    public onLoadMoreStart?: () => void
    public onLoadMoreEnd?: () => void

    private count: number
    private finished: boolean

    constructor(private options: PaginatorOptions) {
        this.reset()
    }

    public reset() {
        this.count = this.options.start
        this.finished = false
    }

    public async loadMore() {
        if (this.finished) {
            return
        }

        if (this.onLoadMoreStart) {
            this.onLoadMoreStart()
        }

        const result = await this.onLoadMore(this.count, this.options.increase)

        if (result && result.finished) {
            this.finished = true
        }

        this.count += this.options.increase

        if (this.onLoadMoreEnd) {
            this.onLoadMoreEnd()
        }
    }

    private onLoadMore: PaginatorLoadMorePromiseFn = async (skip, limit) => {
        const isLoadMoreFunctionCallbackBased = this.options.onLoadMore.length === 3

        if (isLoadMoreFunctionCallbackBased) {
            const loadMore = this.options.onLoadMore as PaginatorLoadMoreFn

            return new Promise(resolve => {
                loadMore(skip, limit, (loadMoreCallbackData?: PaginatorLoadMoreReturnType) => {
                    resolve(loadMoreCallbackData)
                })
            })
        }

        const loadMore = this.options.onLoadMore as PaginatorLoadMorePromiseFn

        return await loadMore(skip, limit)
    }
}
