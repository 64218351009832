import React from 'react'
import { formatCurrency } from '~/utils'

interface Props {
    amount?: number | null
}

export const Currency = (props: Props) => {
    const amount = props.amount || 0
    const isNegative = amount < 0
    const formattedPositive = formatCurrency(Math.abs(amount))

    return <span>{isNegative ? `- ${formattedPositive}` : formattedPositive}</span>
}
