import { User } from './User'
import { Group } from '~/types/Group'

export interface Contract {
    _id: string
    contractNumber: string
    type: ContractType
    user?: User
    dateFrom?: Date
    dateTo?: Date
    isSigned?: boolean
    isFinal?: boolean
    contract: {
        fileId: string
        fileName: string
    }
    signedContract: {
        fileId: string
        fileName: string
    }
    terminatedAt?: Date
    terminatedReason?: string
    logs: LearnerContractLog[]
}

export enum ContractType {
    Standard = 'Standard',
    ONA = 'ONA',
    Alfa = 'Alfa',
}

export enum ContractProjectType {
    Integration = 'Integration',
    Organization = 'Organization',
    Other = 'Other',
}

export enum LearnerContractLogType {
    ContractCreated = 'contractCreated',
    ContractSigned = 'contractSigned',
    ContractTerminated = 'contractTerminated',
    ContractSignatureChanged = 'contractSignatureChanged',
}

export interface LearnerContractLog {
    _id: string
    createdAt: Date
    type: LearnerContractLogType
    typeData: {
        group?: Group
    }
    createdByUser?: User
}
