import React from 'react'
import Input, { InputProps } from './Input'
import Icon from './Icon'
import { BEM } from '~/services/BEMService'

interface Props extends InputProps {
    isHorizontal?: boolean
}

export default class CheckBox extends React.Component<Props> {
    private bem = new BEM('CheckBox', () => ({
        'is-disabled': this.props.isDisabled,
        'is-readonly': this.props.isReadonly,
        'has-error': this.props.hasError,
        'is-horizontal': this.props.isHorizontal,
    }))

    public render() {
        const { className, children, isDisabled, isReadonly, hasError, isHorizontal, ...inputProps } = this.props

        return (
            <label className={this.bem.getClassName(className)}>
                <Input
                    {...inputProps}
                    isDisabled={isDisabled}
                    isReadonly={isReadonly}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => this.onClick(event)}
                    type={`checkbox`}
                />
                <span className={this.bem.getElement('box')}>
                    <Icon name="checkmark" className={this.bem.getElement('box-check')} />
                </span>
                <span className={this.bem.getElement('text')}>{children}</span>
            </label>
        )
    }

    private onClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const { onClick } = this.props

        if (onClick) {
            onClick(event)
        }
    }
}
