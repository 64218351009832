import React from 'react'

import { ModalManager } from '../ModalManager'
import { PageModal } from '../PageModal'
import { PdfViewer, Button, CenterModal } from '..'
import { Contract } from '~/types/Contracts'
import { PdfBar } from '../PdfBar'
import { ListItem } from '../ListItem'
import { ContractSignatureModal } from './ContractSignatureModal'
import { LearnerEndContractForm } from '../users/Learner/LearnerEndContractForm'
const moment = require('moment')

interface Props {
    render: (openModal: () => void) => JSX.Element
    contract: Contract
    refetch?: () => void
    forSigned?: boolean
}

interface State {}

export class ContractModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    public render() {
        const { render, contract } = this.props

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <PageModal title={contract.contractNumber} onClose={closeModal} enableEasyClose>
                        <PdfViewer getFileId={this.getFileId} />
                        <PdfBar
                            getFileId={this.getFileId}
                            fileName={this.getFileName()}
                            downloadText="Download contract"
                            printText="Print contract"
                            renderRightItems={() => this.renderContractButtons(closeModal)}
                        />
                    </PageModal>
                )}
            />
        )
    }

    private getFileId = () => {
        const { contract, forSigned } = this.props

        if (forSigned) {
            return contract.signedContract.fileId || contract.contract.fileId
        }

        return contract.contract.fileId
    }

    private getFileName = () => {
        const { contract, forSigned } = this.props

        if (forSigned) {
            return contract.signedContract.fileName || contract.contract.fileName
        }

        return contract.contract.fileName
    }

    private renderContractButtons = (closeModal: () => void) => {
        const { contract, refetch } = this.props
        const contractHasEnded = !!contract.terminatedAt || moment().isAfter(moment(contract.dateTo))
        const { isSigned } = contract
        if (!contract || contractHasEnded) {
            return null
        }

        return (
            <>
                {!contractHasEnded && isSigned && (
                    <ListItem right={true}>
                        <ModalManager
                            render={openModal => <Button onClick={openModal}>Contract beëindigen</Button>}
                            getModal={closeModal => (
                                <CenterModal onClose={closeModal} title={`Contract beëindigen`}>
                                    <LearnerEndContractForm onClose={closeModal} userContract={contract} />
                                </CenterModal>
                            )}
                        />
                    </ListItem>
                )}
                <ListItem right={true}>
                    <ContractSignatureModal
                        render={openModal => {
                            return (
                                <Button onClick={openModal}>
                                    Handtekening {contract.isSigned ? 'wijzigen' : 'uploaden'}
                                </Button>
                            )
                        }}
                        contract={contract}
                        onSubmitSuccess={refetch}
                    />
                </ListItem>
            </>
        )
    }
}
