import * as React from 'react'
import c from 'classnames'

import { Spinner } from '~/components'
import { isReactNodeEmpty } from '~/utils/isReactNodeEmpty'

export interface TableCellProps {
    onClick?: any
    isHighlighted?: boolean
    className?: string
    isHeader?: boolean
    hasLineThrough?: boolean
    hasInlineState?: boolean
    hasInlineIcon?: boolean
    hasMultiInput?: boolean
    hasOverflow?: boolean
    forIconButton?: boolean
    colSpan?: number
    rowSpan?: number
    width?: string
    title?: string
    isLoading?: boolean
    isSticky?: boolean
    isSmall?: boolean
    hideWhenSmicky?: boolean
    containsNumber?: boolean
    forMultiRow?: boolean
    style?: any
}

export class TableCell extends React.Component<TableCellProps> {
    public render() {
        const { isHeader, colSpan, rowSpan, width, title, isLoading, style } = this.props

        const cellProps = {
            className: this.getClassName(),
            colSpan,
            rowSpan,
            width,
            title,
            style,
        }

        const Tag = isHeader ? 'th' : 'td'

        return (
            <Tag {...cellProps} onClick={this.onClick}>
                {isLoading ? <Spinner /> : this.renderChildren()}
            </Tag>
        )
    }

    private onClick = (event: any) => {
        const { onClick } = this.props
        if (onClick) {
            onClick(event)
        }
    }

    private getClassName(): string {
        const {
            className,
            rowSpan,
            hasLineThrough,
            forIconButton,
            hasInlineState,
            hasInlineIcon,
            hasMultiInput,
            hasOverflow,
            isLoading,
            containsNumber,
            isHighlighted,
            forMultiRow,
            isSticky,
            isSmall,
            hideWhenSmicky,
        } = this.props

        return c(
            'tt-TableCell',
            {
                'tt-TableCell--for-icon-button': forIconButton,
                'tt-TableCell--has-rowspan': !!rowSpan,
                'tt-TableCell--has-line-through': hasLineThrough,
                'tt-TableCell--has-inline-state': hasInlineState,
                'tt-TableCell--has-inline-icon': hasInlineIcon,
                'tt-TableCell--has-overflow': hasOverflow,
                'tt-TableCell--has-multi-input': hasMultiInput,
                'tt-TableCell--is-sticky': isSticky,
                'tt-TableCell--is-small': isSmall,
                'tt-TableCell--is-loading': isLoading,
                'tt-TableCell--contains-number': containsNumber,
                'tt-TableCell--is-highlighted': isHighlighted,
                'tt-TableCell--for-multi-row': forMultiRow,
                'tt-TableCell--is-smicky': hideWhenSmicky,
            },
            className
        )
    }

    private renderChildren() {
        const { children } = this.props

        // This fixes the problem with empty table cells in Firefox
        return isReactNodeEmpty(children) ? <>&nbsp;</> : children
    }
}
