import './RotatedTableHeaderItem.scss'

import * as React from 'react'

import { TableHeaderItem } from '~/components'
import { BEM } from '~/services/BEMService'

export interface Props {
    className?: string
    reservedHeight?: number
}

export class RotatedTableHeaderItem extends React.Component<Props, {}> {
    private bem = new BEM('RotatedTableHeaderItem')

    public render() {
        const { children, className, reservedHeight } = this.props

        return (
            <TableHeaderItem className={this.bem.getClassName(className)} style={{ paddingTop: reservedHeight }}>
                <span className={this.bem.getElement('rotator')}>{children}</span>
            </TableHeaderItem>
        )
    }
}
