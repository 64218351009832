import { InflowMomentInflowModuleGroup } from './InflowMomentInflowModuleGroup'
import { InflowMomentLocationGroup } from './InflowMomentLocationGroup'
import { InflowMomentDateRangeGroup } from './InflowMomentDateRange'
import { InflowMomentEmployeeUsersGroup } from './InflowMomentEmployeeUsersGroup'
import { InflowMomentIntakerUsersGroup } from './InflowMomentIntakerUsers'

export default {
    InflowModule: InflowMomentInflowModuleGroup,
    Location: InflowMomentLocationGroup,
    DateRange: InflowMomentDateRangeGroup,
    EmployeeUsers: InflowMomentEmployeeUsersGroup,
    IntakerUsers: InflowMomentIntakerUsersGroup,
}
