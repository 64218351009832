import './NotesList.scss'
import c from 'classnames'
import * as React from 'react'
import { Fetcher } from '~/utils'
import { gql } from '@apollo/client'
import Spinner from '~/components/Spinner'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { NoteComponent } from '~/components/notes/Note'
import { NoteType, NoteTypeData, Note } from '~/types/Note'
import { PlaceholderNote } from '~/components/notes/PlaceholderNote'

const GET_NOTES_QUERY = gql`
    query notes($sortBy: String, $sortDir: String, $filters: NotesFilterInputType!) {
        notes(sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            text
            createdAt
            createdByUser {
                _id
                profile {
                    name
                }
            }
        }
    }
`

interface Props {
    className?: string
    noteType: NoteType
    noteTypeData: NoteTypeData
    onAddNote: () => void
    onRemoveNote: () => void
}

export class NotesList extends React.Component<Props, {}> {
    private notesFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.notesFetcher = new Fetcher({
            query: GET_NOTES_QUERY,
            variables: {
                filters: {
                    filterByType: props.noteType,
                    filterByTypeData: props.noteTypeData,
                },
                sortDir: 'DESC',
                sortBy: 'createdAt',
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { noteType, noteTypeData, onAddNote } = this.props
        const { data, loading } = this.notesFetcher

        if (loading) {
            return <Spinner />
        }

        const notes = data.notes || []

        return (
            <List className={this.getClassName()}>
                <ListItem>
                    <PlaceholderNote
                        noteType={noteType}
                        noteTypeData={noteTypeData}
                        onAddNote={() => {
                            onAddNote()
                            this.notesFetcher.refetch()
                        }}
                    />
                </ListItem>

                {notes.length > 0 && this.renderNotesListItems(notes)}
            </List>
        )
    }

    private renderNotesListItems(notes: Note[]) {
        const { onRemoveNote } = this.props

        return notes.map(note => {
            return (
                <ListItem key={note._id}>
                    <NoteComponent
                        note={note}
                        onEditNote={() => {
                            this.notesFetcher.refetch()
                        }}
                        onRemoveNote={() => {
                            onRemoveNote()
                            this.notesFetcher.refetch()
                        }}
                    />
                </ListItem>
            )
        })
    }

    private getClassName(): string {
        const { className } = this.props

        return c('tt-NotesList', className)
    }
}
