import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Location } from '~/types/Location'

interface Props extends RouteComponentProps<{}, {}> {
    children: any
    locationObject: Location
    refetch: () => void
}

export default class RoomsView extends React.Component<Props, {}> {
    public render() {
        const { children, locationObject, refetch, ...routeProps } = this.props

        return (
            <RouteView crumbLabel={'Lesruimtes'} routeProps={routeProps} isTab={true}>
                {children && React.cloneElement(children, { locationObject, refetch })}
            </RouteView>
        )
    }
}
