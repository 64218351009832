import * as React from 'react'
import { Invoice, InvoiceStatus, InvoicePaymentStatus } from '~/types/Invoice'
import { InvoiceStatusIndicator } from '../InvoiceStatusIndicator/InvoiceStatusIndicator'
import { Subtle } from '..'
import { GroupUser } from '~/types/User'
import { FlagIcon } from '~/components/Contracts/ContractStatus/icons'

interface Props {
    groupUser: GroupUser
    groupId: string
}

export class GroupParticipantInvoiceStatuses extends React.Component<Props> {
    public render() {
        const { groupUser } = this.props
        const hasPrivatePayment = groupUser.user && groupUser.user.learner && groupUser.user.learner.hasPrivatePayment

        if (!hasPrivatePayment) {
            return this.renderOrganizationPaymentStatus()
        }

        return this.renderPrivatePaymentStatuses()
    }

    private renderOrganizationPaymentStatus() {
        return <Subtle>Betaling via organisatie</Subtle>
    }

    private renderPrivatePaymentStatuses() {
        const { groupUser, groupId } = this.props
        const userId = groupUser.user && groupUser.user._id
        const hasInvoicingEnabled = groupUser.user && groupUser.user && groupUser.user.hasInvoicingEnabled

        if (!userId) {
            return null
        }

        if (!hasInvoicingEnabled) {
            return this.renderWithFlag(
                <InvoiceStatusIndicator
                    ensureShowIconOnly={true}
                    iconRoute={`/users/learners/${userId}`}
                    isInvoicingEnablableForUserId={userId}
                />
            )
        }

        const invoicesThatRequireAttention = this.getInvoicesThatRequireAttention()

        const statusIcons = [...this.renderStatusIconsForInvoices(invoicesThatRequireAttention, userId)]

        if (groupUser.isGroupParticipationInvoiceActivatable) {
            statusIcons.unshift(
                <InvoiceStatusIndicator
                    key={`invoice-status-activatable`}
                    isActivatableForGroupId={groupId}
                    ensureShowIconOnly={true}
                    iconRoute={`/users/learners/${userId}/invoicing`}
                />
            )
        }

        if (statusIcons.length === 0) {
            return null
        }

        return this.renderWithFlag(statusIcons)
    }

    private getInvoicesThatRequireAttention() {
        const { groupUser } = this.props
        const invoices = (groupUser.user && groupUser.user.learner && groupUser.user.learner.invoices) || []

        return invoices.filter(invoice => {
            return (
                invoice.status === InvoiceStatus.Draft ||
                (invoice.status === InvoiceStatus.Final &&
                    invoice.paymentStatus === InvoicePaymentStatus.Open &&
                    !invoice.isFullyCredited)
            )
        })
    }

    private renderWithFlag(content: JSX.Element | JSX.Element[]) {
        return (
            <React.Fragment>
                <FlagIcon />
                <div className={`tt-TableCell--inline-statuses__divider`} />
                {content}
            </React.Fragment>
        )
    }

    private renderStatusIconsForInvoices(invoices: Invoice[], userId: string) {
        return invoices.map((invoice, i) => (
            <InvoiceStatusIndicator
                key={`invoice-status-${i}`}
                invoice={invoice}
                ensureShowIconOnly={true}
                iconRoute={`/users/learners/${userId}/invoicing`}
            />
        ))
    }
}
