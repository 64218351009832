import $ from 'jquery'
import React from 'react'

import { Spinner } from '~/components'
import { BEM } from '~/services/BEMService'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import { GraphQLErrors } from '~/utils/GraphQLErrors'

export interface Props {
    children?: any
    className?: string
    errors?: GraphQLErrors
    hint?: any
    inlineEdit?: boolean
    isBold?: boolean
    isCompact?: boolean
    onRemove?: () => void
    isDisabled?: boolean
    isIndented?: boolean
    isLabel?: boolean
    isLoading?: boolean
    isRequired?: boolean
    style?: string | string[]
    title?: any
    imageUrl?: string
    isDark?: boolean
    hasGreyBackground?: boolean
}

interface State {
    errorMessage?: string
}

export default class Field extends React.Component<Props, State> {
    public state: State = {
        errorMessage: '',
    }

    private bem = new BEM('Field', () => ({
        'has-error': !!this.state.errorMessage,
        'inline-edit': this.props.inlineEdit,
        'is-bold': this.props.isBold,
        'is-compact': this.hasStyle('compact'),
        'is-disabled': this.props.isDisabled,
        'is-removable': !!this.props.onRemove,
        'is-indented': this.props.isIndented,
        'is-loading': this.props.isLoading,
        'no-title': !this.props.title && !this.props.imageUrl,
        'is-dark': this.props.isDark,
        'has-grey-background': this.props.hasGreyBackground,
        light: this.hasStyle('light'),
    }))

    private contentElement = React.createRef<HTMLDivElement>()

    public componentDidMount() {
        const { errors } = this.props

        if (errors) {
            this.updateErrorMessage(errors)
        }
    }

    public componentWillUpdate(nextProps: Props) {
        const errorsHaveChanged = nextProps.errors && this.props.errors !== nextProps.errors

        if (errorsHaveChanged) {
            this.updateErrorMessage(nextProps.errors)
        }
    }

    public render() {
        const { className } = this.props
        const WrapperComponent = this.getWrapperComponent()

        return (
            <div className={this.bem.getClassName(className)}>
                <WrapperComponent className={this.bem.getElement('wrapper')}>
                    {this.renderTitle()}
                    {this.renderImage()}
                    {this.renderContent()}
                    {this.renderRemoveButton()}
                </WrapperComponent>
            </div>
        )
    }

    public renderTitle() {
        const { title, isRequired } = this.props

        return (
            title && (
                <span className={this.bem.getElement('title')}>
                    {title}
                    {isRequired && ' *'}
                </span>
            )
        )
    }

    public renderImage() {
        const { imageUrl } = this.props
        return (
            imageUrl && (
                <div className={this.bem.getElement('image')}>
                    <img src={imageUrl}></img>
                </div>
            )
        )
    }

    private renderContent = () => {
        const { errorMessage } = this.state
        const { children, hint, isLoading } = this.props

        return (
            <div className={this.bem.getElement('content')} ref={this.contentElement}>
                {children}
                {errorMessage && <div className={this.bem.getElement('error')}>{errorMessage}</div>}
                {hint && <div className={this.bem.getElement('hint')}>{hint}</div>}
                {isLoading && <Spinner size="small" />}
            </div>
        )
    }

    private renderRemoveButton = () => {
        const { onRemove } = this.props

        if (!onRemove) {
            return null
        }

        return (
            <Button
                className={this.bem.getElement('remove-button')}
                leftIcon={<Icon name={`trash`} />}
                onClick={() => onRemove()}
                type={`button`}
            />
        )
    }

    private hasStyle = (type: string) => {
        const { style } = this.props

        if (!style) {
            return false
        }

        if (Array.isArray(style)) {
            return style.includes(type)
        }

        return style === type
    }

    private getWrapperComponent = () => {
        const { isLabel } = this.props

        if (isLabel) {
            return 'label'
        }

        return 'div'
    }

    private updateErrorMessage = (mutationErrors?: any) => {
        if (!mutationErrors || !this.contentElement.current) {
            return
        }

        const fields = $(this.contentElement.current.outerHTML)
            .find('[data-errorkey]')
            .toArray()
            .map(el => $(el).data('errorkey'))

        const errorsForFields = fields
            .map(field => mutationErrors.consumeErrorsForField(field))
            .reduce((arr, errors) => [...arr, ...errors], [])

        const error = errorsForFields[0]
        // TODO: Fix this issue and re-enable the 'react/no-direct-mutation-state' rule
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.errorMessage = (error && error.message) || null
    }
}
