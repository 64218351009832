import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Link } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { ProjectArticleCodeFields } from '~/implementations/ProjectArticleCodeFields'
import { translateType } from '~/shared/utils'
import { ContractProjectType } from '~/types/Contracts'
import { ValidationError } from '~/components/ValidationError'
import { getCurrentUser } from '~/services/session'

export default class DataView extends Component {
    static propTypes = {
        project: PropTypes.object.isRequired,
        refetch: PropTypes.func.isRequired,
        orphanPrograms: PropTypes.array.isRequired,
    }

    render() {
        const { project, orphanPrograms } = this.props
        const programs = project.programs || []
        const projectType = get(project, 'projectType')
        const user = getCurrentUser()

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Gegevens`}>
                            <Field title={`Naam`}> {get(project, 'name')} </Field>
                            <Field title={`Opleiding(en)`}>
                                {programs.map((program, i, arr) => {
                                    const suffix = i < arr.length - 1 ? ', ' : ''

                                    return [
                                        <Link key={program._id} route={`/properties/programs/${program._id}`}>
                                            {program.name}
                                        </Link>,
                                        suffix,
                                    ]
                                })}
                                {orphanPrograms &&
                                    orphanPrograms.length > 0 &&
                                    projectType === ContractProjectType.Organization && (
                                        <ValidationError
                                            isWarning
                                            text={`Voor de volgende opleiding(en) zijn nog geen facturatie-instellingen gemaakt: ${orphanPrograms
                                                .map(orphanProgram => orphanProgram.name)
                                                .join(', ')}`}
                                        />
                                    )}
                            </Field>
                            <Field title={'Type project'}>
                                {projectType ? translateType('projectType', projectType) : ''}
                            </Field>
                            <Field title={`E-mailadres projectteam`}>{get(project, 'lessonInviteReplyTo')}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Twinfield Artikel Codes`}>
                            <ProjectArticleCodeFields articleCodes={get(project, 'articleCodes') || []} />
                        </FieldGroup>
                        {user.isAdmin && (
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/projects/${project._id}/edit`)
                                            }
                                            type={`edit`}
                                        >
                                            Bewerken
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        )}
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
