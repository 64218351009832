import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'

interface Props {
    isHeavy?: boolean
    className?: ClassValue
}

export default class Bold extends React.Component<Props> {
    private bem = new BEM('Bold', () => ({
        'is-heavy': this.props.isHeavy,
    }))

    public render() {
        const { children, className } = this.props

        return <strong className={this.bem.getClassName(className)}>{children}</strong>
    }
}
