import c from 'classnames'
import * as React from 'react'

export interface Props {
    className?: string
}

export class View extends React.PureComponent<Props, {}> {
    public render() {
        const { children } = this.props

        return <div className={this.getClassName()}>{children}</div>
    }

    private getClassName() {
        return c('tt-View', this.props.className)
    }
}
