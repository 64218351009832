import * as React from 'react'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import Hint from '~/components/Hint'
import Input from '~/components/Input'
import Spinner from '~/components/Spinner'
import CheckBox from '~/components/CheckBox'
import { get } from 'lodash'
import Link from '~/components/Link'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { Organization } from '~/types/Organization'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import RadioButton from '~/components/RadioButton'
import { translateType } from '~/shared/utils'

interface Props {
    errors?: GraphQLErrors
    organization: Organization
    twinfieldCustomerCode?: string
    requestNewTwinfieldCustomerCode?: boolean
    lookupTwinfieldCustomerLoading?: boolean
    lookupTwinfieldCustomerExists?: boolean
    lookupTwinfieldCustomerName?: string
    onChangeRequestNewTwinfieldCustomerCode: (checked: boolean) => void
    onChangeTwinfieldCustomerCode: (customerCode: string) => void
}

export class PaymentOrganizationDetailsFields extends React.Component<Props> {
    public render() {
        const {
            errors,
            organization,
            requestNewTwinfieldCustomerCode,
            lookupTwinfieldCustomerLoading,
            lookupTwinfieldCustomerExists,
            lookupTwinfieldCustomerName,
            twinfieldCustomerCode,
        } = this.props

        const canTwinfieldCustomerCodeChange = !!get(organization, 'canTwinfieldCustomerCodeChange')

        return (
            <>
                <Field title={'Factuur sturen naar'} errors={errors}>
                    <VariableMultiInput
                        defaultAmount={get(organization, `invoiceEmailRecipients.length`)}
                        getNewInput={i => (
                            <MultiInput type={`email-name`}>
                                <Input
                                    key={i}
                                    name={`organization.invoiceEmailRecipients[${i}].email`}
                                    defaultValue={get(organization, `invoiceEmailRecipients[${i}].email`)}
                                    type={`email`}
                                    placeholder={`john@example.com`}
                                />
                                <Input
                                    key={i}
                                    name={`organization.invoiceEmailRecipients[${i}].name`}
                                    type={`email-name`}
                                    placeholder={`Naam (Optioneel)`}
                                    defaultValue={get(organization, `invoiceEmailRecipients[${i}].name`)}
                                />
                            </MultiInput>
                        )}
                        addButtonLabel="Extra ontvanger toevoegen"
                    />
                </Field>
                <Field isLabel title={`Bedrijfscode`} errors={errors}>
                    <Input
                        name={`organization.organizationCode`}
                        type={`text`}
                        placeholder={``}
                        defaultValue={get(organization, `organizationCode`)}
                    />
                </Field>
                <Field title="Debiteurnummer" errors={errors}>
                    <Input
                        name="organization.twinfieldCustomerCode"
                        type="text"
                        value={!requestNewTwinfieldCustomerCode ? twinfieldCustomerCode || '' : ''}
                        onChange={this.onChangeTwinfieldCustomerCode}
                        placeholder={
                            requestNewTwinfieldCustomerCode
                                ? `Debiteurnummer wordt automatisch gegenereerd`
                                : `Debiteurnummer`
                        }
                        isReadonly={requestNewTwinfieldCustomerCode || !canTwinfieldCustomerCodeChange}
                    />
                    {!requestNewTwinfieldCustomerCode && this.hasTwinfieldCustomerCode() && (
                        <React.Fragment>
                            {lookupTwinfieldCustomerLoading && <Spinner size="small" label="Aan het verifiëren..." />}
                            {!lookupTwinfieldCustomerLoading && (
                                <React.Fragment>
                                    {lookupTwinfieldCustomerExists && (
                                        <Hint>Naam in Twinfield: {lookupTwinfieldCustomerName}</Hint>
                                    )}
                                    {!lookupTwinfieldCustomerExists && (
                                        <Hint>
                                            Debiteur niet gevonden,{` `}
                                            <Link onClick={this.setRequestNewTwinfieldCustomerCode}>
                                                nieuwe debiteur aanmaken
                                            </Link>
                                            .
                                        </Hint>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </Field>
                {canTwinfieldCustomerCodeChange && (
                    <Field title="&nbsp;" errors={errors}>
                        <React.Fragment>
                            <CheckBox
                                name="organization.requestNewTwinfieldCustomerCode"
                                onChange={this.onChangeRequestNewTwinfieldCustomerCode}
                                checked={requestNewTwinfieldCustomerCode || false}
                                isReadonly={!canTwinfieldCustomerCodeChange}
                                isHorizontal={true}
                            >
                                Deze organisatie is nieuw bij TopTaal en heeft nog geen factuurgeschiedenis.{` `}
                                Maak een debiteurennummer aan in Twinfield.
                            </CheckBox>
                            {requestNewTwinfieldCustomerCode && (
                                <Hint>
                                    Er wordt een debiteur in Twinfield aangemaakt zodra je op Opslaan klikt.{` `}
                                    Het debiteurnummer zal automatisch worden ingevuld.
                                </Hint>
                            )}
                        </React.Fragment>
                    </Field>
                )}
                {!canTwinfieldCustomerCodeChange && (
                    <Field title="&nbsp;">
                        <Hint>
                            Dit veld kan niet meer worden aangepast,{` `}
                            omdat er al facturen zijn verzonden op basis van dit debiteurnummer.
                        </Hint>
                    </Field>
                )}
                <Field title="Type BTW" errors={errors}>
                    <MultiInput type="radio">
                        <RadioButton
                            name="organization.vatExempt"
                            value={false}
                            defaultChecked={organization.vatExempt !== true}
                        >
                            {translateType('organizationVatExempt', 'No')}
                        </RadioButton>
                        <RadioButton
                            name="organization.vatExempt"
                            value={true}
                            defaultChecked={organization.vatExempt === true}
                        >
                            {translateType('organizationVatExempt', 'Yes')}
                        </RadioButton>
                    </MultiInput>
                </Field>
            </>
        )
    }

    private hasTwinfieldCustomerCode() {
        const { twinfieldCustomerCode } = this.props

        return !!(twinfieldCustomerCode || '').trim()
    }

    private setRequestNewTwinfieldCustomerCode = () => {
        this.props.onChangeRequestNewTwinfieldCustomerCode(true)
    }

    private onChangeRequestNewTwinfieldCustomerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.currentTarget

        this.props.onChangeRequestNewTwinfieldCustomerCode(!!checked)
    }

    private onChangeTwinfieldCustomerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget

        this.props.onChangeTwinfieldCustomerCode(value)
    }
}
