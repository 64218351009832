import * as React from 'react'
import c from 'classnames'
import Subtle from '~/components/Subtle'

interface Props {
    text: string
    className?: string
}

export class WithSubtle extends React.Component<Props, {}> {
    public render() {
        const { children, text } = this.props

        return (
            <div className={this.getClassName()}>
                {children}
                <Subtle className="tt-WithSubtle__subtle">{text}</Subtle>
            </div>
        )
    }

    private getClassName = () => {
        const { className } = this.props
        return c('tt-WithSubtle', className)
    }
}
