import { Module } from './Module'

export const groupInviteTemplateTextVariables = [
    {
        label: 'Vrij tekstveld',
        value: 'VrijTekstveld',
    },
    {
        label: 'Datum versturen uitnodiging',
        value: 'DatumVersturenUitnodiging',
    },
    {
        label: 'Volledige naam kandidaat',
        value: 'VolledigeNaamKandidaat',
    },
    {
        label: 'Volledige adres kandidaat',
        value: 'VolledigeAdresKandidaat',
    },
    {
        label: 'Telefoonnummer kandidaat',
        value: 'TelefoonnummerKandidaat',
    },
    {
        label: 'E-mailadres kandidaat',
        value: 'E-mailadresKandidaat',
    },
    {
        label: 'Naam project',
        value: 'ProjectNaam',
    },
    {
        label: 'Adres leslocatie',
        value: 'AdresLeslocatie',
    },
    {
        label: 'Naam opleiding',
        value: 'OpleidingNaam',
    },
    {
        label: 'Naam module',
        value: 'ModuleNaam',
    },
    {
        label: 'Begin niveau module',
        value: 'BeginNiveauModule',
    },
    {
        label: 'Eind niveau module',
        value: 'EindNiveauModule',
    },
    {
        label: 'Naam les locaties',
        value: 'NaamLesLocaties',
    },
    {
        label: 'Datum eerste les',
        value: 'DatumEersteLes',
    },
    {
        label: 'Datum laatste les',
        value: 'DatumLaatsteLes',
    },
    {
        label: 'Rooster',
        value: 'Rooster', // 🐓
    },
    {
        label: 'Naam docenten',
        value: 'NaamDocenten',
    },
    {
        label: 'Naam projectleiders',
        value: 'NaamProjectLeiders',
    },
    {
        label: 'Naam en e-mailadres projectmedewerkers',
        value: 'NaamEnE-mailadresProjectMedewerkers',
    },
    {
        label: 'Naam en Telefoon projectmedewerkers',
        value: 'NaamEnTelefoonProjectMedewerkers',
    },
    {
        label: 'Telefoonnummers projectmedewerkers',
        value: 'TelefoonProjectMedewerkers',
    },
]

export interface GroupInviteTemplate {
    _id: string
    module: Module
    name: string
    updatedAt: Date
    nl?: GroupInviteTemplateText
    en?: GroupInviteTemplateText
    attachments?: GroupInviteTemplateFile[]
}

export interface GroupInviteTemplateText {
    content: string
}

export interface GroupInviteTemplateFile {
    _id: string
    fileName: string
}
