import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindAll } from 'lodash'
import c from 'classnames'

import { Input } from '~/components'

import { TagPicker } from '~/components/TagPicker'

export default class PrefixedInput extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['tel']).isRequired,
        prefixName: PropTypes.string.isRequired,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
        isReadonly: PropTypes.bool,
        hasError: PropTypes.bool,
        prefixDefaultValue: PropTypes.any,
        prefixPlaceholder: PropTypes.string,
        prefixDefaultSelectionOnInputChange: PropTypes.any,
        prefixOptions: PropTypes.array,
    }

    state = {
        focus: false,
        inputHasValue: false,
        prefixHasValue: false,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onChangePrefix', 'onChange', 'onFocus', 'onBlur'])
    }

    onFocus() {
        this.setState({ focus: true })
    }

    onBlur() {
        this.setState({ focus: false })
    }

    onChange(event) {
        this.setState({
            inputHasValue: !!event.currentTarget.value,
        })
    }

    onChangePrefix(option) {
        this.setState({
            prefixHasValue: !!option,
        })
    }

    render() {
        const {
            className: extraClassNames,
            type,
            prefixName,
            isDisabled,
            isReadonly,
            hasError,
            prefixDefaultValue,
            prefixPlaceholder,
            prefixDefaultSelectionOnInputChange,
            prefixOptions,
            ...restProps
        } = this.props

        const { focus, prefixHasValue, inputHasValue } = this.state
        const className = c('tt-PrefixedInput', extraClassNames, {
            'tt-PrefixedInput-tel': type === 'tel',
            'tt-PrefixedInput--has-focus': focus,
            'tt-PrefixedInput--is-disabled': isDisabled,
            'tt-PrefixedInput--is-readonly': isReadonly,
            'tt-PrefixedInput--has-error': hasError,
        })

        const inputProps = { ...restProps }
        inputProps.className = `tt-PrefixedInput__input`

        const tagPickerProps = {}
        if (!prefixDefaultValue && inputHasValue && !prefixHasValue && prefixDefaultSelectionOnInputChange) {
            tagPickerProps.value = prefixDefaultSelectionOnInputChange
        }

        return (
            <div className={className}>
                <div className={`tt-PrefixedInput__prefix`}>
                    <TagPicker
                        name={prefixName}
                        multi={false}
                        options={prefixOptions}
                        placeholder={prefixPlaceholder}
                        onChange={this.onChangePrefix}
                        hasError={hasError}
                        isDisabled={isDisabled}
                        isReadonly={isReadonly}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        isClearable={true}
                        defaultValue={prefixDefaultValue}
                        {...tagPickerProps}
                    />
                </div>

                <Input
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    isDisabled={isDisabled}
                    {...inputProps}
                />
            </div>
        )
    }
}
