import React from 'react'
import { ListItem } from '~/components/ListItem'
import { BEM } from '~/services/BEMService'

interface Props {
    title?: string
    url?: string
    anchorId?: string
}

export default class VideosListItem extends React.Component<Props> {
    private bem = new BEM('VideosListItem')

    public render() {
        const { title, url, anchorId } = this.props

        return (
            <ListItem className={this.bem.getClassName()}>
                <h3 id={anchorId}>{title}</h3>
                <iframe
                    src={url}
                    style={{
                        width: '100%',
                        minHeight: 400,
                    }}
                    allowFullScreen={true}
                    allow={`encrypted-media; autoplay; accelerometer; gyroscope; picture-in-picture`}
                />
            </ListItem>
        )
    }
}
