import jquery from 'jquery'
import React from 'react'
import { CheckBox, Icon } from '~/components'
import { TableCell } from '~/components/TableCell'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props {
    className?: ClassValue
    isHighlighted?: boolean
    isActionRow?: boolean
    isStatisticRow?: boolean

    // When using row as checkbox
    rowName?: string
    rowValue?: any
    rowLabel?: any

    // Expansion properties
    getExpansion?: () => JSX.Element
    hasExpandableInfo?: boolean
    isAlwaysExpanded?: boolean
    isExpansionSeamless?: boolean
}

interface State {
    isExpanded: boolean
    checkboxEnabled: boolean
}

export default class TableRow extends React.Component<Props, State> {
    public state: State = {
        isExpanded: false,
        checkboxEnabled: false,
    }

    private bem = new BEM('TableRow', () => ({
        'is-highlighted': this.props.isHighlighted,
        'is-action-row': this.props.isActionRow,
        'is-expandable': this.props.hasExpandableInfo !== false && this.isExpandable(),
        'is-expanded': this.isExpanded(),
        'is-seamless': this.props.isExpansionSeamless,
        'is-statistic-row': this.props.isStatisticRow,
    }))

    constructor(props: Props) {
        super(props)

        if (props.rowValue && props.getExpansion) {
            throw new Error(`Both 'rowValue' and 'getExpansion' can't be defined at once, please remove one`)
        }
    }

    public render() {
        const { children, rowValue, className, hasExpandableInfo } = this.props

        return (
            <>
                <tr className={this.bem.getClassName(className)} onClick={this.handleClick}>
                    {rowValue && this.renderCheckboxCell()}
                    {children}
                    {hasExpandableInfo !== false ? (
                        this.isExpandable() ? (
                            this.renderExpansionButton()
                        ) : null
                    ) : (
                        <TableCell>&nbsp;</TableCell>
                    )}
                </tr>

                {this.isExpanded() && this.renderExpansion()}
            </>
        )
    }

    private renderCheckboxCell = () => {
        const { rowValue, rowLabel, rowName } = this.props
        const { checkboxEnabled } = this.state

        return (
            <TableCell className={this.bem.getElement('checkbox')}>
                <CheckBox onClick={this.checkClickHandler} value={rowValue} name={rowName} checked={checkboxEnabled}>
                    {rowLabel}
                </CheckBox>
            </TableCell>
        )
    }

    private renderExpansionButton = () => {
        const { hasExpandableInfo } = this.props

        return (
            <TableCell className={this.bem.getElement('button')} forIconButton={true}>
                <Icon
                    className={this.bem.getElement('button-expansion-icon')}
                    name={hasExpandableInfo ? 'status_attention' : this.isExpanded() ? 'chevron-up' : 'chevron-down'}
                />
            </TableCell>
        )
    }

    private renderExpansion = () => {
        const { getExpansion } = this.props

        return <tr className={this.bem.getElement('expansion')}>{getExpansion && getExpansion()}</tr>
    }

    private checkClickHandler = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault()
        return false
    }

    private handleClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        if (!jquery.contains(event.currentTarget, event.target as HTMLElement)) {
            return
        }

        const { rowValue, isAlwaysExpanded, hasExpandableInfo } = this.props
        const { isExpanded, checkboxEnabled } = this.state

        const isExpandable = hasExpandableInfo !== false && this.isExpandable()

        if (!isExpandable) {
            return
        }

        if (rowValue) {
            this.setState({ checkboxEnabled: !checkboxEnabled })
        } else if (!isAlwaysExpanded) {
            this.setState({ isExpanded: !isExpanded })
        }
    }

    private isExpandable = () => {
        const { getExpansion, isExpansionSeamless } = this.props
        return !!(getExpansion && !isExpansionSeamless)
    }

    private isExpanded = () => {
        const { getExpansion, isAlwaysExpanded } = this.props
        const { isExpanded } = this.state

        return getExpansion && (isAlwaysExpanded || isExpanded)
    }
}
