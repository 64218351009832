import React from 'react'
import { Lesson } from '~/types/Lesson'
import { TableRow, ReadableDate, Link, Button, CenterModal, Bold } from '~/components'
import { TableCell } from '~/components/TableCell'
import { Label } from '~/components/Label/Label'
import { translateType } from '~/shared/utils'
import { ModalManager } from '~/components/ModalManager'
import { LearnerChangeAttendanceForm } from '../LearnerChangeAttendanceForm'
import { Strikethrough } from '~/components/Strikethrough/Strikethrough'
import { getCurrentUser } from '~/services/session'
import { User } from '~/types/User'
import { get } from 'lodash'

interface Props {
    baseSize: number
    isForConceptGroup?: boolean
    learnerUserId: string
    lessons: Lesson[]
    refetch?: (options?: { silent?: boolean }) => void
}

interface State {
    chunkSize: number
}

export class ChunkedProcessLessonRows extends React.Component<Props, State> {
    public state: State = {
        chunkSize: this.props.baseSize,
    }

    public render() {
        const { lessons, baseSize, learnerUserId, isForConceptGroup, refetch } = this.props
        const { chunkSize } = this.state
        const isChunkSizeBaseSize = chunkSize === baseSize
        const user = getCurrentUser() as User

        return (
            <React.Fragment>
                {lessons.slice(0, chunkSize).map(lesson => {
                    const hasEnrollmentInLesson = !!(lesson && lesson.lessonUsers && lesson.lessonUsers[0])
                    const hasLessonUserAttendance =
                        lesson.lessonUsers && lesson.lessonUsers[0] && lesson.lessonUsers[0].attendance
                    const modificationText = hasLessonUserAttendance ? 'Presentie bewerken' : 'Presentie invoeren'

                    const lessonText = <Bold>Les {lesson.order + 1}</Bold>

                    const lessonLink = (
                        <Link route={`/groups/${lesson.group && lesson.group._id}/lessons/${lesson._id}`}>
                            {lessonText}
                        </Link>
                    )

                    const lessonLabel = user && user.isOrganizationContact ? lessonText : lessonLink

                    return (
                        <TableRow key={lesson._id}>
                            <TableCell width="15%">
                                {hasEnrollmentInLesson ? lessonLabel : <Strikethrough>{lessonLabel}</Strikethrough>}

                                {lesson.specialActivity && (
                                    <Label>{translateType('lessonSpecialActivity', lesson.specialActivity)}</Label>
                                )}
                            </TableCell>
                            <TableCell width={'15%'}>
                                {hasEnrollmentInLesson ? this.getLessonAttendance(lesson) : <>Niet ingeschreven</>}
                            </TableCell>
                            <TableCell width={'15%'}>{lesson.teacherUser && lesson.teacherUser.profile.name}</TableCell>
                            <TableCell>{lesson.date && <ReadableDate date={lesson.date} showTime={true} />}</TableCell>
                            {/* <TableCell>{lesson.location && lesson.location.name}</TableCell> */}
                            <TableCell>
                                {lesson.location && !lesson.room && get(lesson.location, 'name')}
                                {lesson.room && `${lesson.room.name} te ${get(lesson.room.location, 'name')}`}
                            </TableCell>
                            <TableCell>
                                {!isForConceptGroup &&
                                    hasEnrollmentInLesson &&
                                    (user.isAdmin || user.isEmployee || user.isExternalTeacher) && (
                                        <ModalManager
                                            render={openModal => (
                                                <Button
                                                    className={`tt-TableRow__show-on-row-hover`}
                                                    type={`in-row-large`}
                                                    onClick={openModal}
                                                    style={{ float: 'right' }}
                                                >
                                                    {modificationText}
                                                </Button>
                                            )}
                                            getModal={closeModal => (
                                                <CenterModal
                                                    onClose={closeModal}
                                                    title={modificationText}
                                                    enableEasyClose={true}
                                                >
                                                    <LearnerChangeAttendanceForm
                                                        onCancel={closeModal}
                                                        onSubmitSuccess={() => {
                                                            if (refetch) {
                                                                refetch({ silent: true })
                                                            }

                                                            closeModal()
                                                        }}
                                                        learnerUserId={learnerUserId}
                                                        lessonUser={lesson!.lessonUsers![0]}
                                                        lesson={lesson}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    )}
                            </TableCell>
                        </TableRow>
                    )
                })}
                <TableRow isHighlighted={true} isStatisticRow={true}>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                        <Button linkStyle={`default`} onClick={this.toggleRows}>
                            {isChunkSizeBaseSize ? `Bekijk alle lessen (${lessons.length})` : `Inklappen`}
                        </Button>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    private toggleRows = () => {
        const { baseSize, lessons } = this.props
        const { chunkSize } = this.state
        const isChunkSizeBaseSize = chunkSize === baseSize
        const nextChunkSize = isChunkSizeBaseSize ? lessons.length : baseSize

        this.setState({ chunkSize: nextChunkSize })
    }

    private getLessonAttendance = (lesson: Lesson) => {
        return lesson.lessonUsers && lesson.lessonUsers[0] && lesson.lessonUsers[0].attendance
            ? translateType('lessonAttenance', lesson.lessonUsers[0].attendance)
            : '-'
    }
}
