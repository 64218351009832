import './VariableMultiField.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { FieldGroup } from '~/components/FieldGroup'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import Field from '~/components/Field'

interface VariableMultiFieldChildProps<TData> {
    index: number
    key: number
    name?: string
    remove?: () => void
    defaultValue?: TData
    totalLength: number
}

interface Props<TData> {
    addButtonLabel?: string
    defaultValues?: TData[]
    children: (childProperties: VariableMultiFieldChildProps<TData>) => JSX.Element
    className?: string
    isDisabled?: boolean
    limit?: number
    name?: string
}

interface State {
    inputKeys: number[]
}

export class VariableMultiField<TData> extends React.Component<Props<TData>, State> {
    private bem = new BEM('VariableMultiField')
    private lastAssignedKey: number

    constructor(props: Props<TData>) {
        super(props)

        this.state = {
            inputKeys: this.hasDefaultValues(this.props.defaultValues)
                ? this.props.defaultValues.map((_, index) => index)
                : [0],
        }

        this.lastAssignedKey = this.state.inputKeys[this.state.inputKeys.length - 1]
    }

    public render() {
        const { className } = this.props

        return (
            <FieldGroup className={this.bem.getClassName(className)}>
                {this.renderInputs()}
                {this.renderAddButton()}
            </FieldGroup>
        )
    }

    private renderInputs = () => {
        const { children, name, defaultValues } = this.props
        const { inputKeys } = this.state

        return inputKeys.map((key, index, array) => {
            const canBeRemoved = index > 0 || array.length > 1

            return (
                <React.Fragment key={key}>
                    {children({
                        index,
                        key,
                        remove: canBeRemoved ? () => this.onRemove(key) : undefined,
                        name: name && `${name}[${index}]`,
                        totalLength: array.length,
                        defaultValue: defaultValues && defaultValues[key],
                    })}
                </React.Fragment>
            )
        })
    }

    private renderAddButton = () => {
        const { addButtonLabel } = this.props

        return (
            <Field title={` `}>
                <Button
                    className={this.bem.getElement('add-button')}
                    isDisabled={this.isAddDisabled()}
                    type={`button`}
                    linkStyle={`default`}
                    onClick={this.onAdd}
                    leftIcon={<Icon name={`plus-bold`} />}
                >
                    {addButtonLabel || 'Toevoegen'}
                </Button>
            </Field>
        )
    }

    private isAddDisabled = () => {
        const { isDisabled, limit } = this.props
        const { inputKeys } = this.state

        return isDisabled ? isDisabled : limit ? inputKeys.length === limit : undefined
    }

    private onAdd: React.MouseEventHandler<HTMLButtonElement> = () => {
        this.setState(prevState => ({
            inputKeys: [...prevState.inputKeys, ++this.lastAssignedKey],
        }))
    }

    private onRemove = (key: number) => {
        this.setState(prevState => {
            const newInputs = [...prevState.inputKeys]
            newInputs.splice(prevState.inputKeys.indexOf(key), 1)

            return { inputKeys: newInputs }
        })
    }

    private hasDefaultValues = (defaultValues?: TData[]): defaultValues is TData[] => {
        return !!defaultValues && defaultValues.length > 0
    }
}
