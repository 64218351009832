import * as React from 'react'
import { gql } from '@apollo/client'
import CenterModal from '~/components/CenterModal'
import { ModalManager } from '~/components/ModalManager'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Mutator from '~/utils/Mutator'
import { toast } from '~/utils'
import ReadableDate from '~/components/ReadableDate'
import Bold from '~/components/Bold'
import Paragraph from '~/components/Paragraph'

interface Props {
    render: (openModal: () => void) => React.ReactNode
    userId: string
    groupId: string
    userName: string
    lastDisenrollmentDate: Date
    onUndoDisenrollment?: () => void
}

export class GroupLearnerParticipantUndoDisenrollmentModalManager extends React.Component<Props> {
    private undoDisenrollmentMutator = new Mutator({
        mutation: UNDO_DISENROLLMENT_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { render, userName, lastDisenrollmentDate } = this.props
        const { loading: isLoadingUndoDisenrollment } = this.undoDisenrollmentMutator

        return (
            <ModalManager
                render={render}
                getModal={closeModal => (
                    <CenterModal
                        small
                        title={'Laatste inschrijving ongedaan maken'}
                        onClose={closeModal}
                        enableEasyClose
                    >
                        <FieldCollection style={`modal`}>
                            <FieldGroup>
                                <Field>
                                    <Paragraph>
                                        Je staat op het punt om de laatste uitschrijving (
                                        <ReadableDate date={lastDisenrollmentDate} />) van <Bold>{userName}</Bold>{' '}
                                        ongedaan te maken.
                                    </Paragraph>
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button onClick={closeModal}>Annuleren</Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button
                                            type="submit"
                                            onClick={event => this.undoDisenrollment(event, closeModal)}
                                            isLoading={isLoadingUndoDisenrollment}
                                        >
                                            Bevestigen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </CenterModal>
                )}
            />
        )
    }

    private undoDisenrollment = async (event: React.FormEvent<HTMLButtonElement>, closeModal: () => void) => {
        const { userId, userName, groupId, onUndoDisenrollment } = this.props

        const result = await this.undoDisenrollmentMutator.mutate({
            userId: userId,
            groupId: groupId,
        })

        if (result && result.groups_undoDisenrollment) {
            closeModal()
            toast.success(`Laatste uitschrijving van "${userName}" is succesvol ongedaan gemaakt`)

            if (onUndoDisenrollment) {
                onUndoDisenrollment()
            }
        }
    }
}

const UNDO_DISENROLLMENT_MUTATION = gql`
    mutation groups_undoDisenrollment($groupId: MongoID, $userId: MongoID) {
        groups_undoDisenrollment(groupId: $groupId, userId: $userId) {
            _id
        }
    }
`
