import * as React from 'react'

import { Link } from '~/components'
import { ListItem } from '~/components/ListItem'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    right?: boolean
    route?: string
    onClose?: () => void
}

export class MobileNavLink extends React.Component<Props> {
    private bem = new BEM('MobileNavLink')

    public render() {
        const { children, right, route, className, onClose } = this.props

        return (
            <ListItem className={this.bem.getClassName(className)} right={right}>
                <Link route={route} onClick={onClose}>
                    {children}
                </Link>
            </ListItem>
        )
    }
}
