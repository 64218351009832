import * as React from 'react'
import flattenChildren from 'react-flatten-children'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import { BEM } from '~/services/BEMService'

interface Props {
    className?: string
    smallButtons?: boolean
    joined?: boolean
}

export class ButtonGroup extends React.Component<Props> {
    private bem = new BEM('ButtonGroup', () => ({
        'small-buttons': this.props.smallButtons,
        'is-joined': !!this.props.joined,
        'is-separated': !this.props.joined,
    }))

    public render() {
        const { children, className } = this.props

        return (
            <List className={this.bem.getClassName(className)}>
                {flattenChildren(children).map((child: React.ReactChild, i: number) => (
                    <ListItem key={i}>{child}</ListItem>
                ))}
            </List>
        )
    }
}
