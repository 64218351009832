import React from 'react'
import { ClassValue, BEM } from '~/services/BEMService'
import Icon from './Icon'

interface Props {
    className?: ClassValue
    icon?: Icon
}

export default class InputIcon extends React.Component<Props> {
    private bem = new BEM('InputIcon')

    public render() {
        const { children, className, icon } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                {children}
                <span className={this.bem.getElement('icon')}>{icon}</span>
            </div>
        )
    }
}
