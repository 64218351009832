import './HeaderAction.scss'

import c from 'classnames'
import * as React from 'react'
import { ListItem } from '~/components/ListItem'

interface Props {
    className?: string
    label?: string
}

export class HeaderAction extends React.Component<Props, {}> {
    public render() {
        const { children, label } = this.props

        return (
            <ListItem className={this.getClassName()}>
                {label && <span className="tt-HeaderAction__label">{label}: </span>}
                {children}
            </ListItem>
        )
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-HeaderAction', className)
    }
}
