import './ContractTableCell.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { Contract } from '~/types/Contracts'
import { TableCell } from '../TableCell'
import { Bold, Subtle, Link, ReadableDate, Button } from '..'
import { ContractModal } from './ContractModal'
import { ContractSignatureModal } from '~/components/Contracts/ContractSignatureModal'
import { SignatureIcon, MakeFinalIcon } from '~/components/Contracts/ContractStatus/icons'
import { translateType } from '~/shared/utils'
import { Mutator } from '~/utils'
import { gql } from '@apollo/client'

interface Props {
    className?: ClassValue
    contract: Contract
    refetch?: () => void
}

interface State {}

const CONTRACT_MAKE_FINAL_MUTATION = gql`
    mutation _($contractId: MongoID!) {
        contracts_makeFinal(contractId: $contractId) {
            _id
        }
    }
`

export class ContractTableCell extends React.Component<Props, State> {
    private bem = new BEM('ContractTableCell')

    private contractMakeFinalMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.contractMakeFinalMutator = new Mutator({
            mutation: CONTRACT_MAKE_FINAL_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { contract, className, refetch } = this.props

        return (
            <TableCell className={this.bem.getClassName(className)}>
                <Bold>{contract.isFinal ? 'Lopend contract voor' : 'Nieuw contract voor'}</Bold>
                {contract.type && (
                    <Subtle regularSize={true} className={this.bem.getElement('program')}>
                        {translateType('contractType', contract.type)}
                    </Subtle>
                )}
                {(contract.signedContract || contract.contract) && (
                    <ContractModal
                        forSigned={contract.isSigned}
                        contract={contract}
                        render={openModal => (
                            <Link onClick={openModal} className={this.bem.getElement('pdf-link')}>
                                {contract.contractNumber || contract._id}
                            </Link>
                        )}
                        refetch={refetch}
                    />
                )}
                {contract.isSigned ? this.renderDateRange() : this.renderSignButton()}
            </TableCell>
        )
    }

    private renderDateRange() {
        const { contract } = this.props

        return (
            <Subtle className={this.bem.getElement('date-range')}>
                <span>
                    <ReadableDate
                        date={contract.dateFrom}
                        format={'DD-MM-YYYY'}
                        className={this.bem.getElement('date')}
                    />
                    {' t/m '}
                    <ReadableDate
                        date={contract.dateTo}
                        format={'DD-MM-YYYY'}
                        className={this.bem.getElement('date')}
                    />
                </span>
            </Subtle>
        )
    }

    private renderSignButton() {
        const { contract, refetch } = this.props

        if (!contract.isFinal) {
            return (
                <div className={this.bem.getElement('signature-button')}>
                    <Button
                        type={`button`}
                        shouldPreventSubmit
                        small={true}
                        leftIcon={<MakeFinalIcon />}
                        onClick={() => {
                            this.makeFinal()
                        }}
                        confirm={{
                            title: 'Contract definitief maken',
                            message: 'Weet je zeker dat je dit contract definitief wilt maken?',
                            execute: {
                                buttonType: 'submit',
                                title: 'Contract definitief maken',
                            },
                        }}
                    >
                        Definitief maken
                    </Button>
                </div>
            )
        }

        return (
            <div className={this.bem.getElement('signature-button')}>
                <ContractSignatureModal
                    render={openModal => {
                        return (
                            <Button onClick={openModal} small={true} leftIcon={<SignatureIcon />}>
                                Handtekening uploaden
                            </Button>
                        )
                    }}
                    contract={contract}
                    onSubmitSuccess={refetch}
                />
            </div>
        )
    }

    private makeFinal = async () => {
        const { refetch, contract } = this.props

        await this.contractMakeFinalMutator.mutate({
            contractId: contract._id,
        })

        if (refetch) {
            refetch()
        }
    }
}
