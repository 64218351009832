import './LearnerGroupParticipationHistory.scss'
import * as React from 'react'
import { HistoryLog, HistoryLogItem } from '~/components/HistoryLog/HistoryLog'
import { LearnerGroupParticipationHistoryDisenrollReason } from '~/components/Group/Participants/Misc/LearnerGroupParticipationHistoryDisenrollReason/LearnerGroupParticipationHistoryDisenrollReason'
import { BEM } from '~/services/BEMService'
import { GroupUserEnrollment, GroupUserEnrollmentType } from '~/types/User'
import ReadableDate from '~/components/ReadableDate'

interface Props {
    className?: string
    userId: string
    groupId: string
    enrollments: GroupUserEnrollment[]
}

export class LearnerGroupParticipationHistory extends React.Component<Props> {
    private bem = new BEM('LearnerGroupParticipationHistory')

    public render() {
        const { className } = this.props

        return <HistoryLog className={this.bem.getClassName(className)} historyLogItems={this.getHistoryLogItems()} />
    }

    private getHistoryLogItems(): HistoryLogItem[] {
        const { enrollments } = this.props

        const sortedEnrollments = [...enrollments].sort(
            (a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime()
        )

        const firstEnrollment = sortedEnrollments[0]

        return enrollments.map(enrollment => {
            const isFirstEnrollment = enrollment._id === firstEnrollment._id
            return this.getHistoryLogItemForEnrollment(enrollment, isFirstEnrollment)
        })
    }

    private getHistoryLogItemForEnrollment(
        enrollment: GroupUserEnrollment,
        isFirstEnrollment: boolean
    ): HistoryLogItem {
        if (enrollment.type === GroupUserEnrollmentType.Enrollment) {
            return {
                date: enrollment.createdAt!,
                actorName: enrollment.createdByUser && enrollment.createdByUser.profile.name,
                actionNode: (
                    <>
                        Kandidaat start
                        {isFirstEnrollment ? ' ' : ' opnieuw '}
                        per <ReadableDate date={enrollment.date} />
                    </>
                ),
            }
        }

        if (enrollment.type === GroupUserEnrollmentType.Disenrollment) {
            return {
                date: enrollment.createdAt!,
                actorName: enrollment.createdByUser && enrollment.createdByUser.profile.name,
                actionNode: (
                    <>
                        Kandidaat stopt per <ReadableDate date={enrollment.date} />
                        {enrollment.removedReason && (
                            <LearnerGroupParticipationHistoryDisenrollReason
                                reason={enrollment.removedReason}
                                info={enrollment.removedInfo}
                            />
                        )}
                    </>
                ),
            }
        }

        throw new Error('Could not determine history log item')
    }
}
