export function getTimeFromMinutes(minutes: number) {
    if (minutes < 0) {
        throw new RangeError('Number must be 0 or higher')
    }

    if (minutes === 0) {
        return `0 minuten`
    }

    const h = minutes / 60 || null
    const m = minutes % 60 || null

    const hoursInText = !h ? null : Math.floor(h) > 0 ? `${Math.floor(h)} uur` : null

    const minutesInText = !m ? null : `${m} ${m === 1 ? 'minuut' : 'minuten'}`

    if (hoursInText && minutesInText) {
        return `${hoursInText} en ${minutesInText}`
    }

    if (hoursInText && !minutesInText) {
        return hoursInText
    }

    if (!hoursInText && minutesInText) {
        return minutesInText
    }

    return ''
}
