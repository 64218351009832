import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ContentView } from '~/components/ContentView'
import { OrganizationTimeline } from '~/implementations'

export default class TimelineView extends Component {
    static propTypes = {
        organization: PropTypes.object,
    }

    render() {
        const { organization } = this.props

        return (
            <ContentView>
                <OrganizationTimeline organization={organization} timelineEventOwnerType={`ORGANIZATION`} />
            </ContentView>
        )
    }
}
