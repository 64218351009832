import { Lesson } from '~/types/Lesson'
import { Project } from '~/types/Project'
import { Module } from '~/types/Module'
import { Room, Location } from '~/types/Location'
import { User, GroupUser } from '~/types/User'
import { DateRange } from '~/types/DateRange'
import { Program } from '~/types/Program'
import { Note } from '~/types/Note'
import { GroupInviteTemplate } from './groupInviteTemplate'

export interface GroupTimetableLesson {
    generalWeekLessonDateIndex?: number
    weekIndex?: number
    lesson?: Lesson
}

export enum GroupInviteType {
    Manual = 'Manual',
    Email = 'Email',
}

export interface LocalizedExtraLearnerInformation {
    nl?: string
    en?: string
}

export interface GroupDocument {
    _id: string
    fileName?: string
    isPDF?: boolean
    createdAt?: Date
    createdByUser?: User
}
export interface GroupInvite {
    _id: string
    user: User
    date: Date
    type: GroupInviteType
    groupInviteTemplate?: GroupInviteTemplate
    invitedByUser?: User
}

export interface Group {
    _id: string
    name?: string
    isConcept?: boolean
    isLegacyGroup?: boolean
    endedAt?: Date
    hasParticipantsWithExpiredInvoices?: boolean
    hasParticipantsWithInvoicingDisabled?: boolean
    hasParticipantsWithInactiveInvoices?: boolean
    hasParticipantsThatRequireSignatureOnContract?: boolean
    hasParticipantsThatRequireSignatureOnInvoice?: boolean
    hasParticipantsWithInvoicesThatAreDeniedByDUO?: boolean
    isEndAllowed?: boolean
    project?: Project
    program?: Program
    module?: Module
    location?: Location
    rooms?: Room[]
    dateFrom?: Date
    dateTo?: Date
    startWeekDate?: Date
    generalWeekLessonDates?: Date[]
    generalWeekLessonRooms?: Room[]
    generalWeekLessonTeacherUsers?: User[]
    startDateIndex?: number
    learnerUsers?: GroupUser[]
    amountOfUnsentLessonInvites?: number
    learnerUsersCount?: number
    teacherUsers?: GroupUser[]
    employeeUsers?: GroupUser[]
    timetableLessons?: GroupTimetableLesson[]
    lessons?: Lesson[]
    localizedExtraLearnerInformation?: LocalizedExtraLearnerInformation
    dateBlockers?: DateRange[]
    canBeDeleted?: boolean
    documents?: GroupDocument[]
    notes?: Note[]
    notesCount?: number
    hasExamWhichRequiresAction?: boolean
    latestInvites?: GroupInvite[]
}
