import React from 'react'
import Fetcher from '~/utils/Fetcher'
import { Paginator } from '~/utils/Paginator'
import Filter from '~/utils/Filter'
import { gql } from '@apollo/client'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import Search from '~/components/Search'
import { Organization } from '~/types/Organization'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import Field from '~/components/Field'
import { TagPicker } from '~/components/TagPicker'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { InflowMomentsCandidatesForm } from '~/components/InflowMoment/InflowMomentsCandidatesForm'
import Sorter from '~/utils/Sorter'
import MultiInput from '~/components/MultiInput'
import CheckBox from '~/components/CheckBox'
import DatePicker from '~/components/DatePicker'
import { ModalManager } from '../ModalManager'
import { AddCandidateToInflowModal } from './AddCandidateToInflowModal'
import { getCurrentUser } from '~/services/session'
import { InflowMomentsCandidatesTable } from '~/components/InflowMoment/InflowMomentsCandidatesTable'
import { LearnerEnrollmentType } from '~/types/User'
import { translateType } from '~/shared/utils'

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string | null
    sortBy: string | null
}

const GET_USERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int, $sortDir: String, $sortBy: String) {
        users(filters: $filters, limit: $limit, skip: $skip, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            roles
            profile {
                name
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            learner {
                organizations {
                    organization {
                        _id
                        name
                    }
                    organizationFileNumber
                }
                enrollmentType
                enrollmentDate
                inflowMomentsNoShowStreak
                isInflowOnHold
            }
        }
    }
`

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'learner.inflowMomentsNoShowStreak'
const DEFAULT_SORT_DIR = 'DESC'

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }

        addresses_usedCities
    }
`

export class InflowMomentsCandidatesSelectPage extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortBy: DEFAULT_SORT_BY,
        sortDir: DEFAULT_SORT_DIR,
    }

    private userFetcher: Fetcher
    private paginator: Paginator
    private filter: Filter
    private filterSuggestionsFetcher: Fetcher
    private sorter: Sorter
    private STATIC_CANDIDATE_USERS_FILTERS = {
        roles: ['LEARNER'],
        filterByIsAvailableForInflow: true,
        filterByHasNoInflowMomentsAfterEnrollmentDate: true,

        filterByEnrollmentTypeIntake: true,
        filterByEnrollmentTypeAssessment: true,

        // todo: make sorting work
        // why is this in filters?
        // inflowMomentCandidateTableSort: true,
    }

    constructor(props: Props) {
        super(props)
        const { limit, skip, sortBy, sortDir } = this.state

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: false,
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterByOrganizationIds',
                'filterByEnrollmentDateFrom',
                'filterByEnrollmentDateTo',
                'filterByEnrollmentTypeIntake',
                'filterByEnrollmentTypeAssessment',
                'filterByHasNoInflowMomentsAfterEnrollmentDate',
            ],
            onChange: (filters: any) => {
                const mergedFilters = {
                    ...this.STATIC_CANDIDATE_USERS_FILTERS,
                    ...filters,
                }

                // 'false', in this case, means remove the filter
                if (filters.filterByHasNoInflowMomentsAfterEnrollmentDate === false) {
                    delete mergedFilters['filterByHasNoInflowMomentsAfterEnrollmentDate']
                }

                this.userFetcher.refetch({
                    silent: true,
                    filters: mergedFilters,
                } as any)
            },
        })

        this.userFetcher = new Fetcher({
            query: GET_USERS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...this.STATIC_CANDIDATE_USERS_FILTERS,
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => this.paginator.reset(),
        })
    }

    public render() {
        const { data } = this.userFetcher
        const { users = [] } = data
        const currentUser = getCurrentUser()

        if (currentUser.isOrganizationContact) {
            return (
                <InflowMomentsCandidatesTable
                    userSorter={this.sorter}
                    users={users}
                    renderActionBar={this.renderActionBar}
                    userPaginator={this.paginator}
                    userFetcher={this.userFetcher}
                />
            )
        }

        return (
            <InflowMomentsCandidatesForm
                userSorter={this.sorter}
                users={users}
                renderActionBar={this.renderActionBar}
                userPaginator={this.paginator}
                userFetcher={this.userFetcher}
            />
        )
    }

    private renderActionBar = () => {
        const { loading: loadingUsers } = this.userFetcher
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities = [], organizations = [] } = filterParams
        const addressOptions = addresses_usedCities.map((city: string) => ({ value: city, label: city }))
        const organizationOptions = organizations.map(({ _id, name }: Organization) => ({ value: _id, label: name }))
        const currentUser = getCurrentUser()

        return (
            <ActionBar
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal>
                        <ListItem>
                            <Button leftIcon={<Icon name={`filter`} />} onClick={toggleDrawer} isActive={drawerActive}>
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                        </ListItem>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                                        Kandidaat toevoegen
                                    </Button>
                                )}
                                getModal={closeModal => <AddCandidateToInflowModal onClose={closeModal} />}
                            />
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        {!currentUser.isOrganizationContact && (
                            <Field isLabel={true} title={`Organisatie`} style={`compact`}>
                                <TagPicker
                                    name={`filterByOrganizationIds`}
                                    onChange={this.onFilterTagPicker}
                                    multi={true}
                                    options={organizationOptions}
                                />
                            </Field>
                        )}
                        <Field isLabel={true} title={`Woonplaats`} style={`compact`}>
                            <TagPicker
                                onChange={this.onFilterTagPicker}
                                name="filterByCityNames"
                                options={addressOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={`Type instroom`} style={`compact`}>
                            <MultiInput type="checkbox">
                                <CheckBox
                                    name="filterByEnrollmentTypeIntake"
                                    onChange={this.onFilter}
                                    defaultChecked={true}
                                >
                                    {translateType('enrollmentType', LearnerEnrollmentType.Intake)}
                                </CheckBox>
                                <CheckBox
                                    name="filterByEnrollmentTypeAssessment"
                                    onChange={this.onFilter}
                                    defaultChecked={true}
                                >
                                    {translateType('enrollmentType', LearnerEnrollmentType.Assessment)}
                                </CheckBox>
                            </MultiInput>
                        </Field>
                        <Field isLabel={true} title={'Aangemeld op'} style={'compact'}>
                            <MultiInput type={`select-range`}>
                                <DatePicker onChange={this.onFilter} name={'filterByEnrollmentDateFrom'} />
                                <label>t/m</label>
                                <DatePicker onChange={this.onFilter} name={'filterByEnrollmentDateTo'} />
                            </MultiInput>
                        </Field>
                        <Field title="Status" style="compact">
                            <MultiInput type="checkbox">
                                <CheckBox
                                    name="filterByHasNoInflowMomentsAfterEnrollmentDate"
                                    onChange={this.onFilter}
                                    defaultChecked={true}
                                >
                                    Verberg reeds ingeplande kandidaten
                                </CheckBox>
                            </MultiInput>
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.userFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    private onFilterTagPicker = (values: any[], tagPicker: any) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    private onFilter = (event: any) => {
        this.filter.applyFromInputEvent(event)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.userFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }
}
