import './Button.scss'

import * as React from 'react'
import c from 'classnames'
import { get, isNumber, isArray, isString } from 'lodash'

import { CenterModal, Field, FieldCollection, Spinner } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

type NativeButtonType = 'button' | 'submit'
export type ButtonType = NativeButtonType | 'edit' | 'danger' | 'in-row' | 'in-row-large'
type StyleOverride = ButtonType | 'secondary-submit' | 'secondary-danger' | 'looks-disabled' | 'secondary-edit'
type LinkStyle = 'danger' | 'subtle' | 'default'

interface PrivateClickHandlerParameters {
    confirmed?: boolean
}

interface ConfirmButton {
    buttonType?: ButtonType
    buttonCountdown?: number
    title?: string
}

export interface Confirm {
    title: string
    message?: any
    execute?: ConfirmButton
}

export interface Props {
    onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void
    countdown?: number
    leftIcon?: any
    rightIcon?: any
    style?: React.CSSProperties
    className?: string
    isLoading?: boolean
    isDisabled?: boolean
    isActive?: boolean
    onMouseDown?: () => React.SyntheticEvent<HTMLButtonElement>
    onTouchStart?: () => React.SyntheticEvent<HTMLButtonElement>
    confirm?: Confirm
    linkStyle?: LinkStyle | LinkStyle[]
    type?: ButtonType
    styleOverride?: StyleOverride
    shouldPreventSubmit?: boolean
    shouldNotPreventDefaultOnClick?: boolean
    small?: boolean
    form?: string
    hasNotification?: boolean
}

interface State {
    countdownSeconds: number
    countdownActive?: boolean
    confirmActive?: boolean
}

export class Button extends React.Component<Props, State> {
    public state: State = {
        countdownSeconds: 0,
        countdownActive: false,
        confirmActive: false,
    }

    private countdownInterval?: number

    constructor(props: Props) {
        super(props)

        if (isNumber(props.countdown) && props.countdown > 0) {
            this.state.countdownSeconds = props.countdown
            this.state.countdownActive = true

            this.countdownInterval = window.setInterval(() => {
                const newSeconds = this.state.countdownSeconds - 1
                if (newSeconds <= 0 && this.countdownInterval) {
                    clearTimeout(this.countdownInterval)
                }

                this.setState({
                    countdownSeconds: newSeconds,
                    countdownActive: newSeconds > 0,
                })
            }, 1000)
        }
    }

    public componentWillUnmount() {
        if (this.countdownInterval) {
            clearTimeout(this.countdownInterval)
        }
    }

    public render() {
        const {
            children,
            leftIcon,
            rightIcon,
            style,
            isLoading,
            shouldPreventSubmit = false,
            isDisabled,
            onMouseDown,
            onTouchStart,
            form,
        } = this.props
        const { confirmActive, countdownActive, countdownSeconds } = this.state

        const className = this.getClassName()
        const buttonType = this.getButtonType()

        return (
            <button
                className={className}
                type={shouldPreventSubmit ? 'button' : buttonType}
                onClick={this.onClick}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
                disabled={isDisabled}
                style={style}
                form={form}
            >
                {confirmActive && this.renderConfirmationModal()}
                {leftIcon && <span className={this.getChildClassName(`left-icon`)}>{leftIcon}</span>}
                {children && <span className={this.getChildClassName(`content`)}>{children}</span>}
                {countdownActive && ` (${countdownSeconds})`}
                {rightIcon && <span className={this.getChildClassName(`right-icon`)}>{rightIcon}</span>}

                {isLoading && <Spinner color={this.getSpinnerColor()} size="small" />}
            </button>
        )
    }

    private onClick = (
        event: React.SyntheticEvent<HTMLButtonElement>,
        { confirmed }: PrivateClickHandlerParameters = {}
    ) => {
        event.stopPropagation()

        const { onClick, isDisabled, confirm, shouldNotPreventDefaultOnClick } = this.props
        const { confirmActive } = this.state

        if (isDisabled || (onClick && !shouldNotPreventDefaultOnClick)) {
            event.preventDefault()
        }

        if (onClick && (!confirm || (confirm && confirmed))) {
            onClick(event)
        }

        if (confirm && !confirmed && !confirmActive) {
            this.setState({ confirmActive: true })
        }
    }

    private getLinkStyles(): LinkStyle[] {
        const { linkStyle } = this.props

        if (isArray(linkStyle)) {
            return linkStyle
        }

        if (isString(linkStyle)) {
            return [linkStyle]
        }

        return []
    }

    private getClassName() {
        const {
            className: extraClassName,
            type,
            styleOverride,
            isDisabled,
            isActive,
            isLoading = false,
            small,
            hasNotification,
        } = this.props
        const { countdownActive } = this.state
        const classType = styleOverride ? styleOverride : type
        const linkStyles = this.getLinkStyles()

        if (linkStyles && linkStyles.length > 0) {
            return c('tt-Link', extraClassName, {
                'tt-Link--danger': linkStyles.indexOf('danger') > -1,
                'tt-Link--subtle': linkStyles.indexOf('subtle') > -1,
                'tt-Link--is-disabled': isDisabled || countdownActive,
                'tt-Link--is-loading': isLoading,
            })
        }

        return c('tt-Button', extraClassName, {
            'tt-Button-edit': classType === 'edit',
            'tt-Button-submit': classType === 'submit',
            'tt-Button--secondary-submit': classType === 'secondary-submit',
            'tt-Button--secondary-edit': classType === 'secondary-edit',
            'tt-Button--secondary-danger': classType === 'secondary-danger',
            'tt-Button-danger': classType === 'danger',
            'tt-Button-in-row': classType === 'in-row',
            'tt-Button-in-row-large': classType === 'in-row-large',
            'tt-Button--is-disabled': isDisabled || countdownActive,
            'tt-Button--is-active': isActive,
            'tt-Button--is-loading': isLoading,
            'tt-Button--is-small': small,
            'tt-Button--looks-disabled': classType === 'looks-disabled',
            'tt-Button--has-notification': hasNotification,
        })
    }

    private getClassType() {
        const { type, styleOverride } = this.props

        return styleOverride ? styleOverride : type
    }

    private getSpinnerColor() {
        if (this.getButtonType() === 'button') {
            return '#355FBF'
        }

        const classType = this.getClassType()

        if (classType === 'secondary-submit') {
            return '#239C5B'
        }

        if (classType === 'secondary-danger') {
            return '#E85156'
        }

        return '#FFFFFF'
    }

    private getChildClassName(name: string): string {
        const linkStyles = this.getLinkStyles()

        if (linkStyles && linkStyles.length > 0) {
            return `tt-Link__${name}`
        }

        return `tt-Button__${name}`
    }

    private getButtonType(): NativeButtonType {
        const { type } = this.props

        return type === `submit` ? `submit` : `button`
    }

    private renderConfirmationModal(): any {
        const { confirm, isLoading = false, form } = this.props
        const title = get(confirm, 'title', 'Waarschuwing')
        const message = get(confirm, 'message', 'Weet je het zeker?')
        const confirmButtonType = get(confirm, 'execute.buttonType', 'edit')
        const confirmButtonCountdown = get(confirm, 'execute.buttonCountdown')
        const confirmButtonTitle = get(confirm, 'execute.title', 'Ok')

        return (
            <CenterModal small title={title} onClose={() => this.setState({ confirmActive: false })}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>{message}</Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={() => this.setState({ confirmActive: false })}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    isLoading={isLoading}
                                    type={confirmButtonType}
                                    countdown={confirmButtonCountdown}
                                    onClick={e => {
                                        e.persist()

                                        this.setState(
                                            {
                                                confirmActive: false,
                                            },
                                            () => {
                                                this.onClick(e, { confirmed: true })
                                            }
                                        )
                                    }}
                                    form={form}
                                >
                                    {confirmButtonTitle}
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </CenterModal>
        )
    }
}
