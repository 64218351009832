import * as React from 'react'
import { Translator } from '~/services/i18n'
import { View } from '~/components/View'
import { SubNav } from '~/components/SubNav'
import { SubNavLink } from '~/components/SubNavLink'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'

interface Props {}

export default class ExportsView extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App.Exports',
        subscribe: this,
    })

    public render() {
        const { t } = this.translator
        const { children } = this.props

        return (
            <View>
                <SubNav>
                    <SubNavLink route={`/exports/all`}>{t('navigation.buttons.exports')}</SubNavLink>
                    <SubNavLink route={`/exports/system-export-log`}>
                        {t('navigation.buttons.systemExportLog')}
                    </SubNavLink>
                </SubNav>
                <BreadCrumbProvider>{children}</BreadCrumbProvider>
            </View>
        )
    }
}
