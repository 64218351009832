import * as React from 'react'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import { get, isEmpty } from 'lodash'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { LockedFieldWrapper } from '~/components/Core/Field/LockedField/LockedFieldWrapper'
import Input from '~/components/Input'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import translateType from '~/shared/utils/translateType'
import { UserLearningRouteTypesEnum, UserTargetLevelTypesEnum, UserTypeOfLearnerTypesEnum } from '~/generated/graphql'
import { DatePicker, PrecisionDateInput } from '~/components'
import { ObligedCitizenshipEducationEndDatesInput } from '~/components/users/ObligedCitizenshipEducationEndDatesInput'

interface Props {
    errors?: GraphQLErrors
    user: User
    isCitizenshipEducationObliged?: boolean
    onChangeCitizenshipEducationObliged: (value: boolean) => void
    onAddObligedCitizenshipEducationEndDateExtension: (index: number, document: any) => void
    onEditObligedCitizenshipEducationEndDateExtension: (index: number, document: any) => void
    onRemoveObligedCitizenshipEducationEndDateExtension: (index: number) => void
    onChangeBSNVerifiedCheckbox: (value: boolean) => void
    bsnVerified?: boolean
    typeOfLearner?: UserTypeOfLearnerTypesEnum
    onChangeTypeOfLearner: (value?: TagPickerOption) => void
    learningRoute?: UserLearningRouteTypesEnum
    onChangeLearningRoute: (value?: TagPickerOption) => void
    targetLevelPIP?: UserTargetLevelTypesEnum
    onChangeTargetLevel: (value: UserTargetLevelTypesEnum) => void
}

export class UserLearnerTargetGroup extends React.Component<Props> {
    public render() {
        const {
            errors,
            user,
            isCitizenshipEducationObliged,
            onAddObligedCitizenshipEducationEndDateExtension,
            onEditObligedCitizenshipEducationEndDateExtension,
            onRemoveObligedCitizenshipEducationEndDateExtension,
            onChangeCitizenshipEducationObliged,
            onChangeBSNVerifiedCheckbox,
            bsnVerified,
            typeOfLearner,
            onChangeTypeOfLearner,
            learningRoute,
            onChangeLearningRoute,
            targetLevelPIP,
            onChangeTargetLevel,
        } = this.props

        return (
            <FieldGroup title={'Doelgroep'} isForm={true}>
                <React.Fragment>
                    <LockedFieldWrapper disableLock={this.areTargetGroupFieldsEmpty()}>
                        {isLocked => (
                            <>
                                <Field title="Type kandidaat" errors={errors}>
                                    <TagPicker
                                        name={`user.learner.typeOfLearner`}
                                        multi={false}
                                        options={Object.values(UserTypeOfLearnerTypesEnum).map(value => ({
                                            label: translateType('typeOfLearner', value),
                                            value: value,
                                        }))}
                                        placeholder={'Selecteer'}
                                        defaultValue={typeOfLearner}
                                        onChange={onChangeTypeOfLearner}
                                        isDisabled={isLocked}
                                    />
                                </Field>

                                {(typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2013 ||
                                    typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2021) && (
                                    <Field title={`Verplicht inburgeren`} errors={errors}>
                                        <MultiInput type="radio">
                                            <RadioButton
                                                name={`user.learner.isCitizenshipEducationObliged`}
                                                value={true}
                                                defaultChecked={isCitizenshipEducationObliged}
                                                onChange={() => onChangeCitizenshipEducationObliged(true)}
                                                isDisabled={isLocked}
                                            >
                                                Ja
                                            </RadioButton>
                                            <RadioButton
                                                name={`user.learner.isCitizenshipEducationObliged`}
                                                value={false}
                                                defaultChecked={!isCitizenshipEducationObliged}
                                                onChange={() => onChangeCitizenshipEducationObliged(false)}
                                                isDisabled={isLocked}
                                            >
                                                Nee
                                            </RadioButton>
                                        </MultiInput>
                                    </Field>
                                )}

                                {isCitizenshipEducationObliged && (
                                    <FieldGroup isInsetGroup={true}>
                                        <React.Fragment>
                                            <Field isLabel={true} title={`Start inburgeringsplicht`} errors={errors}>
                                                <PrecisionDateInput
                                                    name={`user.learner.obligedCitizenshipEducationStartDate`}
                                                    defaultValue={get(
                                                        user,
                                                        'learner.obligedCitizenshipEducationStartDate'
                                                    )}
                                                    isDisabled={isLocked}
                                                    fromYear={2000}
                                                />
                                            </Field>
                                            <Field title={`Einde inburgeringsplicht`} errors={errors}>
                                                <ObligedCitizenshipEducationEndDatesInput
                                                    name={`user.learner.obligedCitizenshipEducationEndDateExtensions`}
                                                    onAddObligedCitizenshipEducationEndDateExtension={
                                                        onAddObligedCitizenshipEducationEndDateExtension
                                                    }
                                                    onEditObligedCitizenshipEducationEndDateExtension={
                                                        onEditObligedCitizenshipEducationEndDateExtension
                                                    }
                                                    onRemoveObligedCitizenshipEducationEndDateExtension={
                                                        onRemoveObligedCitizenshipEducationEndDateExtension
                                                    }
                                                    defaultExtensionValue={get(
                                                        user,
                                                        'learner.obligedCitizenshipEducationEndDateExtensions'
                                                    )}
                                                    defaultEndDateValue={get(
                                                        user,
                                                        'learner.obligedCitizenshipEducationInitialEndDate'
                                                    )}
                                                    isDisabled={isLocked}
                                                />
                                            </Field>
                                            <Field isLabel={true} title={`Voldaan op`} errors={errors}>
                                                <DatePicker
                                                    name={`user.learner.obligedCitizenshipEducationCompletedAt`}
                                                    defaultValue={get(
                                                        user,
                                                        'learner.obligedCitizenshipEducationCompletedAt'
                                                    )}
                                                    isReadonly={get(
                                                        user,
                                                        'learner.obligedCitizenshipEducationCompletedByImport'
                                                    )}
                                                    isDisabled={isLocked}
                                                />
                                            </Field>
                                        </React.Fragment>
                                    </FieldGroup>
                                )}

                                {isCitizenshipEducationObliged &&
                                    typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2021 && (
                                        <Field title={`Leerroute`} errors={errors}>
                                            <TagPicker
                                                name={`user.learner.learningRoute`}
                                                multi={false}
                                                options={Object.values(UserLearningRouteTypesEnum).map(value => ({
                                                    label: translateType('learningRoute', value),
                                                    value: value,
                                                }))}
                                                placeholder={'Selecteer'}
                                                defaultValue={learningRoute}
                                                onChange={onChangeLearningRoute}
                                                isDisabled={isLocked}
                                            />
                                        </Field>
                                    )}

                                {isCitizenshipEducationObliged &&
                                    typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2021 && (
                                        <Field title={`Doelniveau PIP`} errors={errors}>
                                            <MultiInput type="radio">
                                                <RadioButton
                                                    name={`user.learner.targetLevelPIP`}
                                                    value={UserTargetLevelTypesEnum.A2}
                                                    defaultChecked={targetLevelPIP === UserTargetLevelTypesEnum.A2}
                                                    onChange={() => onChangeTargetLevel(UserTargetLevelTypesEnum.A2)}
                                                    isDisabled={isLocked}
                                                >
                                                    A2
                                                </RadioButton>
                                                <RadioButton
                                                    name={`user.learner.targetLevelPIP`}
                                                    value={UserTargetLevelTypesEnum.B1}
                                                    defaultChecked={targetLevelPIP === UserTargetLevelTypesEnum.B1}
                                                    onChange={() => onChangeTargetLevel(UserTargetLevelTypesEnum.B1)}
                                                    isDisabled={isLocked}
                                                >
                                                    B1
                                                </RadioButton>
                                                <RadioButton
                                                    name={`user.learner.targetLevelPIP`}
                                                    value={UserTargetLevelTypesEnum.B2}
                                                    defaultChecked={targetLevelPIP === UserTargetLevelTypesEnum.B2}
                                                    onChange={() => onChangeTargetLevel(UserTargetLevelTypesEnum.B2)}
                                                    isDisabled={isLocked}
                                                >
                                                    B2
                                                </RadioButton>
                                            </MultiInput>
                                        </Field>
                                    )}

                                {isCitizenshipEducationObliged &&
                                    (typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2013 ||
                                        typeOfLearner === UserTypeOfLearnerTypesEnum.Integration2021) && (
                                        <>
                                            <Field isLabel={true} title={`BSN`} errors={errors}>
                                                <Input
                                                    name={`user.profile.bsn`}
                                                    type="text"
                                                    isDisabled={isLocked}
                                                    defaultValue={get(user, 'profile.bsn')}
                                                />
                                            </Field>
                                            <Field isLabel={true} title={`Gegevens op ID gecheckt`} errors={errors}>
                                                <MultiInput type="radio">
                                                    <RadioButton
                                                        name="user.profile.bsnVerified"
                                                        value={true}
                                                        isDisabled={isLocked}
                                                        checked={bsnVerified === true}
                                                        onClick={() => onChangeBSNVerifiedCheckbox(true)}
                                                    >
                                                        Ja
                                                    </RadioButton>
                                                    <RadioButton
                                                        name="user.profile.bsnVerified"
                                                        value={false}
                                                        isDisabled={isLocked}
                                                        checked={bsnVerified !== true}
                                                        onClick={() => onChangeBSNVerifiedCheckbox(false)}
                                                    >
                                                        Nee
                                                    </RadioButton>
                                                </MultiInput>
                                            </Field>
                                        </>
                                    )}
                            </>
                        )}
                    </LockedFieldWrapper>
                </React.Fragment>
            </FieldGroup>
        )
    }

    private areTargetGroupFieldsEmpty() {
        const { user, typeOfLearner } = this.props

        return (
            !typeOfLearner &&
            typeof get(user, 'learner.isCitizenshipEducationObliged') !== 'boolean' &&
            !get(user, 'learner.obligedCitizenshipEducationStartDate') &&
            !get(user, 'learner.obligedCitizenshipEducationCompletedAt') &&
            isEmpty(get(user, 'learner.obligedCitizenshipEducationEndDateExtensions')) &&
            !get(user, 'learner.obligedCitizenshipEducationInitialEndDate') &&
            !get(user, 'learner.learningRoute') &&
            !get(user, 'learner.targetLevelPIP') &&
            !get(user, 'profile.bsn')
        )
    }
}
