import { gql } from '@apollo/client'
import * as React from 'react'

import { CenterModal } from '~/components'
import { SendInvoiceWithMissingEmailForm } from '~/forms/SendInvoiceWithMissingEmailForm'
import { downloadFile, Fetcher, Mutator, toast } from '~/utils'
import { Invoice } from '~/types/Invoice'

interface Props {
    render: (sendInvoice: (invoiceId: string) => void, isSendingInvoice?: boolean) => React.ReactNode | null
}

interface UpdateEmailResponse {
    isCanceled?: boolean
    confirmManualSend?: boolean
}

interface ModalConfig {
    forInvoice: Invoice
    callback: (response: UpdateEmailResponse) => void
}

interface State {
    isSendingInvoice: boolean
    activeUpdateEmailModal?: ModalConfig
}

const INVOICE_QUERY = gql`
    query _($filters: InvoicesFilterInputType!) {
        invoices(filters: $filters) {
            _id
            invoiceNumber
            isEmailRequiredToSend
            user {
                _id
                emailForCommunication
                profile {
                    name
                }
            }
        }
    }
`

const SEND_INVOICE_MUTATION = gql`
    mutation _($invoiceId: MongoID!, $invoiceWillBeManuallySent: Boolean) {
        invoices_makeFinal(invoiceId: $invoiceId, invoiceWillBeManuallySent: $invoiceWillBeManuallySent) {
            _id
            invoiceNumber
            isCredit
            isDownloadable
            status
            openStatus
            paymentStatus
            emailedAt
            descendantInstallmentInvoices {
                _id
                invoiceNumber
                isDownloadable
            }
        }
    }
`

const CREATE_INVOICE_FILE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_generatePdf(invoiceId: $invoiceId) {
            fileId
        }
    }
`

export class SendInvoiceProvider extends React.Component<Props, State> {
    public state: State = {
        isSendingInvoice: false,
        activeUpdateEmailModal: undefined,
    }

    private invoicesFetcher: Fetcher
    private sendInvoiceMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.invoicesFetcher = new Fetcher({
            query: INVOICE_QUERY,
            preventInitialFetch: true,
        })

        this.sendInvoiceMutator = new Mutator({
            mutation: SEND_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { render } = this.props
        const { isSendingInvoice } = this.state

        return (
            <>
                {render(this.sendInvoiceById, isSendingInvoice)}
                {this.renderSendInvoiceWithMissingEmailModal()}
            </>
        )
    }

    private sendInvoiceById = async (invoiceId: string) => {
        this.setState({ isSendingInvoice: true })

        const data = await this.invoicesFetcher.fetch({
            filters: {
                byIds: [invoiceId],
            },
        })

        const [invoice = undefined] = data.invoices || []

        if (!invoice) {
            toast.error('Kan factuur niet verzenden.')
            // tslint:disable-next-line:no-console
            console.error(`Could not request invoice by id "${invoiceId}"`)
            this.setState({ isSendingInvoice: false })
            return
        }

        let cancelSend
        let manualSend

        if (!invoice.user.emailForCommunication && invoice.isEmailRequiredToSend) {
            const { isCanceled, confirmManualSend } = await this.updateEmailForInvoice(invoice)

            cancelSend = isCanceled
            manualSend = confirmManualSend
        }

        if (!cancelSend) {
            await this.send(invoice._id, manualSend)
        }

        this.setState({ isSendingInvoice: false })
    }

    private async updateEmailForInvoice(invoice: Invoice): Promise<UpdateEmailResponse> {
        return new Promise(resolve => {
            this.openUpdateEmailForInvoiceModal(invoice, resolve)
        })
    }

    private openUpdateEmailForInvoiceModal(invoice: Invoice, callback: (response: UpdateEmailResponse) => void) {
        this.setState({
            activeUpdateEmailModal: {
                forInvoice: invoice,
                callback: callback,
            },
        })
    }

    private closeUpdateEmailModal = (response: UpdateEmailResponse) => {
        const { activeUpdateEmailModal } = this.state

        if (activeUpdateEmailModal) {
            activeUpdateEmailModal.callback(response)

            this.setState({
                activeUpdateEmailModal: undefined,
            })
        }
    }

    private send = async (invoiceId: string, manualSend?: boolean) => {
        const data = await this.sendInvoiceMutator.mutate({
            invoiceId: invoiceId,
            invoiceWillBeManuallySent: manualSend,
        })

        const invoice = data && data.invoices_makeFinal
        if (data && invoice) {
            const subject = invoice.isCredit ? 'creditnota' : 'factuur'

            if (invoice.emailedAt) {
                // In case the invoice is just emailed to the customer
                toast.success(`De ${subject} is succesvol verzonden.`)
            } else {
                // In case the invoice must be downloaded
                if (manualSend && invoice.isDownloadable) {
                    const { descendantInstallmentInvoices } = data.invoices_makeFinal
                    const invoices = [invoice, ...descendantInstallmentInvoices]

                    if (invoices.length === 1) {
                        toast.success(`De ${subject} is succesvol verzonden. De download wordt nu gestart.`)
                    } else {
                        toast.success(`De ${subject} is succesvol verzonden. De downloads worden nu gestart.`)
                    }

                    for (const invoice of invoices) {
                        await this.downloadInvoice(invoice)
                    }
                } else {
                    // In case the invoice is not (and should not be) sent to the customer
                    toast.success(`De ${subject} is succesvol verwerkt.`)
                }
            }
        }
    }

    private async downloadInvoice(invoice: Invoice) {
        const createInvoiceFileMutator = new Mutator({
            mutation: CREATE_INVOICE_FILE_MUTATION,
            reactComponentToUpdate: this,
        })

        const data = await createInvoiceFileMutator.mutate({
            invoiceId: invoice._id,
        })

        if (data && data.invoices_generatePdf) {
            const { fileId } = data.invoices_generatePdf
            const subject = invoice.isCredit ? 'creditnota' : 'factuur'

            downloadFile(fileId, `${invoice.invoiceNumber}-${subject}.pdf`)
        }
    }

    private renderSendInvoiceWithMissingEmailModal(): React.ReactNode | void {
        const { activeUpdateEmailModal } = this.state

        if (activeUpdateEmailModal) {
            const { forInvoice } = activeUpdateEmailModal
            const forInvoiceName = forInvoice && forInvoice.user && forInvoice.user.profile.name

            return (
                <CenterModal
                    onClose={() => this.closeUpdateEmailModal({ isCanceled: true })}
                    title={`Factuur versturen voor ${forInvoiceName}`}
                >
                    <SendInvoiceWithMissingEmailForm
                        invoice={forInvoice}
                        onCancel={() => this.closeUpdateEmailModal({ isCanceled: true })}
                        onSubmitSuccess={(response: UpdateEmailResponse) => this.closeUpdateEmailModal(response)}
                    />
                </CenterModal>
            )
        }
    }
}
