import React from 'react'
import { Button, Icon } from '~/components'
import { TableCell, TableCellProps } from '~/components/TableCell'
import { Sorter } from '~/utils'
import c from 'classnames'
import { BEM, ClassValue } from '~/services/BEMService'

interface Props extends TableCellProps {
    sorter?: Sorter
    children?: any
    className?: ClassValue | any
    sortBy?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    colSpan?: number
    rowSpan?: number
    style?: React.CSSProperties
    containsNumber?: boolean
    noPadding?: boolean
    renderCheckBox?: () => JSX.Element
    isSticky?: boolean
    isSmall?: boolean
}

interface State {
    sortDir?: string
    isActive: boolean
}

export default class TableHeaderItem extends React.Component<Props, State> {
    private bem = new BEM('TableHeaderItem', () => ({
        'contains-number': this.props.containsNumber,
    }))

    constructor(props: Props) {
        super(props)

        let defaultSortDir = undefined

        if (props.sorter) {
            props.sorter.onSortEnd(this.onSortEnd)
            defaultSortDir = props.sorter.getDefaultOrder(props.sortBy)
        }

        this.state = {
            sortDir: defaultSortDir,
            isActive: false,
        }
    }

    public componentWillUnmount() {
        const { sorter } = this.props

        if (sorter) {
            sorter.offSortEnd(this.onSortEnd)
        }
    }

    public render() {
        const { children, containsNumber, sorter, className, isSmall, isSticky, ...restCellProps } = this.props

        const cellProps = {
            ...restCellProps,
            className: this.bem.getClassName(className),
        }

        return (
            <TableCell {...cellProps} isHeader={true} isSticky={isSticky} isSmall={isSmall}>
                {sorter ? this.renderButtonWith(this.renderChildren(children)) : this.renderChildren(children)}
            </TableCell>
        )
    }

    public renderButtonWith(children?: any) {
        const btnClassName = c('tt-TableHeaderItem__button', {
            'tt-TableHeaderItem__button--sorting': this.isActive(),
        })

        return (
            <Button
                className={btnClassName}
                linkStyle={`default`}
                rightIcon={this.getSortDirIcon()}
                onClick={this.onClick}
            >
                {children}
            </Button>
        )
    }

    private renderChildren = (children?: any) => {
        const { renderCheckBox } = this.props

        if (renderCheckBox) {
            return (
                <React.Fragment>
                    <div className={this.bem.getElement('toggle')}>
                        {renderCheckBox()}
                        {children}
                    </div>
                </React.Fragment>
            )
        }

        return children
    }

    private onSortEnd = ({ sortBy, sortDir }: { sortBy: string; sortDir?: string }) => {
        const { props } = this
        if (sortBy === props.sortBy) {
            this.setState({ sortDir })
        } else {
            this.setState({ sortDir: undefined })
        }
    }

    private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { onClick, sorter, sortBy } = this.props

        if (onClick) {
            onClick(event)
        }

        if (sorter) {
            sorter.sort(sortBy)
        }

        this.setState({
            isActive: true,
        })
    }

    private getSortDirIcon = () => {
        const { sortDir } = this.state

        return sortDir === 'DESC' ? <Icon name={`caret-up`} /> : <Icon name={`caret-down`} />
    }

    private isActive = () => {
        const { sorter, sortBy } = this.props
        const { isActive } = this.state

        return isActive && sorter && sorter.getCurrentSortBy() === sortBy
    }
}
