import React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'
const moment = require('moment')
import { get, compact } from 'lodash'
import { FieldCollection, Field, Button, ReadableAddress, ReadablePhoneNumbers } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { Location } from '~/types/Location'

interface Props extends RouteComponentProps<{}, {}> {
    locationObject: Location
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { locationObject } = this.props

        const phoneNumbers = get(locationObject, `address.phoneNumbers`) || []
        const rentalDateFrom = get(locationObject, 'rentalDateFrom')
        const rentalDateTo = get(locationObject, 'rentalDateTo')
        const address = get(locationObject, 'address')

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Locatienaam`}>{locationObject.name}</Field>
                            <Field title={`Adres`}>
                                {address && <ReadableAddress address={address} showCountry={false} />}
                            </Field>
                            <Field title={`Telefoonnummer`}>
                                <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                            </Field>
                            <Field title={`Naam bedrijf`}>{locationObject.organizationName}</Field>
                            <Field title={`Naam contactpersoon`}>{locationObject.contactPersonName}</Field>
                            <Field title={`Huurdatum`}>
                                {compact([
                                    rentalDateFrom && moment(rentalDateFrom).format('DD-MM-YYYY'),
                                    rentalDateTo && moment(rentalDateTo).format('DD-MM-YYYY'),
                                ]).join(' t/m ')}
                            </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                <ListItem right={true}>
                                    <Button
                                        onClick={() =>
                                            browserHistory.push(`/properties/locations/${locationObject._id}/edit`)
                                        }
                                        type={`edit`}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }
}
