import * as React from 'react'
import c from 'classnames'

interface Props {
    children?: any
    horizontal?: boolean
    stretch?: boolean
    forButtons?: boolean
    forSmallButtons?: boolean
    className?: string
    extraSpacing?: boolean
}

export class List extends React.Component<Props, {}> {
    public render() {
        const { children } = this.props

        return <ul className={this.getClassName()}>{children}</ul>
    }

    private getClassName = () => {
        const {
            className: extraClassNames,
            horizontal,
            forButtons,
            forSmallButtons,
            stretch,
            extraSpacing,
        } = this.props

        return c('tt-List', extraClassNames, {
            'tt-List--horizontal': horizontal,
            'tt-List--stretch': stretch,
            'tt-List--for-buttons': forButtons,
            'tt-List--for-small-buttons': forSmallButtons,
            'tt-List--has-extra-spacing': extraSpacing,
        })
    }
}
