export enum LiterateModuleLevel {
    A0 = 'A0',
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    _1F = '_1F', // Both name and value start with underscores because they can't start with a number
    _2F = '_2F',
    _3F = '_3F',
    _4F = '_4F',
}

export enum LearnerLevelWithAlpha {
    Alpha = 'Alpha',
    A0 = 'A0',
    A1Minus = 'A1Minus',
    A1 = 'A1',
    A2Minus = 'A2Minus',
    A2 = 'A2',
    B1Minus = 'B1Minus',
    B1 = 'B1',
    B2Minus = 'B2Minus',
    B2 = 'B2',
    C1Minus = 'C1Minus',
    C1 = 'C1',
    Inflow = 'Instroom',
    _1FMinus = '_1FMinus',
    _1F = '_1F', // Both name and value start with underscores because they can't start with a number
    _2FMinus = '_2FMinus',
    _2F = '_2F',
    _3FMinus = '_3FMinus',
    _3F = '_3F',
    _4FMinus = '_4FMinus',
    _4F = '_4F',
}

export enum LearnerLevel {
    A0 = 'A0',
    A1Minus = 'A1Minus',
    A1 = 'A1',
    A2Minus = 'A2Minus',
    A2 = 'A2',
    B1Minus = 'B1Minus',
    B1 = 'B1',
    B2Minus = 'B2Minus',
    B2 = 'B2',
    C1Minus = 'C1Minus',
    C1 = 'C1',
    Inflow = 'Instroom',
    _1FMinus = '_1FMinus',
    _1F = '_1F', // Both name and value start with underscores because they can't start with a number
    _2FMinus = '_2FMinus',
    _2F = '_2F',
    _3FMinus = '_3FMinus',
    _3F = '_3F',
    _4FMinus = '_4FMinus',
    _4F = '_4F',
}
