import * as React from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'

import { Field, FieldCollection, Link, Subtle, Currency } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { DateBlocker } from '~/components/DateBlocker'
import { Group } from '~/types/Group'
import { CourseMaterial } from '~/types/Module'

interface Props {
    group: Group
}

export class ExternalUserGroupInfo extends React.Component<Props> {
    public render() {
        const { group } = this.props

        const startWeekDate = group.startWeekDate
        const dateBlockers = group.dateBlockers || []
        const startDate = group.dateFrom && moment(group.dateFrom)
        const endDate = group.dateTo && moment(group.dateTo)
        const courseMaterials = group.module && group.module.courseMaterials

        return (
            <View>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Groepsnaam`}>
                            <Field>{group.name}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Lesmodule`} separateFields={true}>
                            <Field>
                                {group.module && (
                                    <div>
                                        <SimpleTable>
                                            <SimpleTableRow>
                                                <SimpleTableCell>Lesmodule</SimpleTableCell>
                                                <SimpleTableCell>{group.module.name}</SimpleTableCell>
                                            </SimpleTableRow>
                                        </SimpleTable>
                                        <Subtle>{this.renderModuleInformation()}</Subtle>
                                    </div>
                                )}
                            </Field>
                            {courseMaterials && this.renderCourseMaterials(courseMaterials)}
                        </FieldGroup>
                        <FieldGroup title={`Lesdag(en)`}>
                            <Field>{this.renderLessonDatesList()}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Startweek`}>
                            {startWeekDate && (
                                <Field>
                                    {/* tslint:disable-next-line:max-line-length */}
                                    Week {moment(startWeekDate).week()}{' '}
                                    {startDate &&
                                        endDate &&
                                        `(${startDate.format('DD-MM-YYYY')} t/m ${endDate.format('DD-MM-YYYY')})`}
                                    <br />
                                    {endDate && <Subtle>Eindigt in week {endDate.week()}</Subtle>}
                                </Field>
                            )}
                        </FieldGroup>
                        <FieldGroup title={`Vakantieperiode(s)`}>
                            <Field>
                                {dateBlockers.length === 0 && 'Geen'}
                                {dateBlockers.length > 0 && (
                                    <SimpleTable>
                                        {dateBlockers.map((dateBlocker, i) => (
                                            <SimpleTableRow key={i}>
                                                <SimpleTableCell isBold={true}>Periode {i + 1}</SimpleTableCell>
                                                <SimpleTableCell>
                                                    <DateBlocker range={dateBlocker} />
                                                </SimpleTableCell>
                                            </SimpleTableRow>
                                        ))}
                                    </SimpleTable>
                                )}
                            </Field>
                        </FieldGroup>
                        <FieldGroup title={`Locatie`}>
                            <Field>{group.location ? group.location.name : 'Geen'}</Field>
                        </FieldGroup>
                    </FieldCollection>
                </Wrap>
            </View>
        )
    }

    private renderModuleInformation = () => {
        const { group } = this.props
        const { module } = group

        if (!module) {
            return null
        }

        return [
            `${module.amountOfTotalLessons || 0} ${module.amountOfTotalLessons === 1 ? 'les' : 'lessen'}`,
            `${module.amountOfWeeklyLessons || 0} keer per week`,
            `${module.lessonDuration || 0} ${module.amountOfTotalLessons === 1 ? 'minuut' : 'minuten'}`,
        ].join(' / ')
    }

    private renderCourseMaterials = (courseMaterials: CourseMaterial[]) => {
        return (
            <Field>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell>Lesmaterialen</SimpleTableCell>
                        <SimpleTableCell>
                            {courseMaterials.map(courseMaterial => {
                                const { _id, name, amount } = courseMaterial
                                const hasAmountValue = typeof amount === 'number'

                                return (
                                    <div key={_id}>
                                        <Link route={`/properties/coursematerials/${_id}`}>
                                            {name}
                                            <Subtle asSuffix={true}>
                                                {hasAmountValue && <Currency amount={amount} />}
                                            </Subtle>
                                        </Link>
                                    </div>
                                )
                            })}
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
            </Field>
        )
    }

    private renderLessonDatesList = () => {
        const { group } = this.props
        const generalWeekLessonDates = group.generalWeekLessonDates || []

        const dates = generalWeekLessonDates.map(d => new Date(d))

        return (
            <ul>
                {dates.map(date => (
                    <li key={date.getTime()}>{this.renderDate(date)}</li>
                ))}
            </ul>
        )
    }

    private renderDate = (date: Date) => {
        const { group } = this.props
        const { module } = group

        const fromMoment = moment(date)
        const toMoment = module && module.lessonDuration && moment(date).add(module.lessonDuration, 'minutes')

        return `${capitalize(fromMoment.format('dddd HH:mm'))}${toMoment ? ` - ${toMoment.format('HH:mm')}` : ``}`
    }
}
