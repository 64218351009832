import * as React from 'react'

import { SubNav } from '~/components/SubNav'
import { SubNavLink } from '~/components/SubNavLink'
import { View } from '~/components/View'
import { Translator } from '~/services/i18n'
import { BreadCrumbProvider } from '~/components/Chrome/Navigation/BreadCrumbProvider/BreadCrumbProvider'
import { getCurrentUser } from '~/services/session'

interface Props {}

export default class FinancialView extends React.Component<Props> {
    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.translator = new Translator({
            namespace: 'App.Financial',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { t } = this.translator
        const { children } = this.props
        const currentUser = getCurrentUser()

        return (
            <View>
                <SubNav>
                    <SubNavLink route={`/financial/learner-invoices`}>
                        {t('navigation.buttons.learner-invoices')}
                    </SubNavLink>
                    {currentUser.isFinancial && (
                        <>
                            <SubNavLink route={`/financial/duo-batches`}>
                                {t('navigation.buttons.duo-batches')}
                            </SubNavLink>
                            <SubNavLink route={`/financial/credit-invoices`}>
                                {t('navigation.buttons.credit-invoices')}
                            </SubNavLink>
                            <SubNavLink route={`/financial/revenue`}>{t('navigation.buttons.revenue')}</SubNavLink>
                            <SubNavLink route={`/financial/prognosis`}>{t('navigation.buttons.prognosis')}</SubNavLink>
                        </>
                    )}
                </SubNav>
                <BreadCrumbProvider>{children}</BreadCrumbProvider>
            </View>
        )
    }
}
